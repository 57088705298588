import React from "react";
import { connect } from "react-redux";
import { getQueryParams } from "../../../lib/qm_cs_lib";
import { getGuiSettings } from "../../../store/reducers/guiSettings";

import "./ContinueButton.css";

const refreshPage = () => {
    window.location.reload();
};

/**
 * @param {String} url
 */
const redirectTo = url => {
    if (url) {
        // simulates clicking a hyperlink.
        // the current page stays in history and would be reachable with the browser's back button
        window.location.href = url;
    }
};

/**
 * Injects the common task-ui query params.
 * Only query params that aren't already present in the redirect url are injected.
 * The current query params are used as source for the injection.
 * Injectable template params are:
 * - {task_endpoint}
 * - {vendor_id}
 * - {vendor_user_id}
 * - {project_id}
 * - {project_node_id}
 * The redirect url must have proper formatting: https://example.org?task_endpoint=abc.com&{vendor_id}&{vendor_user_id}
 * --> In the example above task_endpoint wouldn't be altered, but vendor_id and vendor_user_id would be injected.
 * --> The redirect url also has correct formatting.
 * @param {String} urlWithQueryParamTemplates
 */
export const replaceQueryParamTemplates = urlWithQueryParamTemplates => {
    const currentQueryParams = getQueryParams();
    const redirectQueryParams = new URLSearchParams(
        decodeURI(urlWithQueryParamTemplates)
    );
    let url = urlWithQueryParamTemplates;

    // this means that the url isn't formatted properly. don't do anything here.
    if (!url.includes("?")) {
        return urlWithQueryParamTemplates;
    }

    for (const paramName of [
        "task_endpoint",
        "vendor_id",
        "vendor_user_id",
        "project_id",
        "project_node_id"
    ]) {
        if (
            !redirectQueryParams.has(paramName) &&
            currentQueryParams.has(paramName)
        ) {
            url = url.replace(
                `{${paramName}}`,
                `${paramName}=${currentQueryParams.get(paramName)}`
            );
        }
    }

    return url;
};

/**
 * @param {Object} props
 * @param {Object} props.guiSettings
 */
export let ContinueButton = ({ guiSettings }) => {
    let onContinue = () => refreshPage();
    if (guiSettings?.continue_url) {
        const url = replaceQueryParamTemplates(guiSettings.continue_url);
        onContinue = () => redirectTo(url);
    }

    return (
        <button
            id="continue_button"
            className="continue-button"
            type="button"
            onClick={onContinue}
        >
            {" "}
            <span>Continue</span>{" "}
        </button>
    );
};

ContinueButton = connect(state => ({
    guiSettings: getGuiSettings(state)
}))(ContinueButton);

export default ContinueButton;
