import React from "react";
import { connect } from "react-redux";
import { formatUTCTimeStringWithLocale } from "../../lib/qm_cs_lib";
import { getTaskFetchResult } from "../../store/reducers/taskFetchRequest";

export let UserIsBlockedErrorPage = props => {
    const formattedBlockedUntil = formatUTCTimeStringWithLocale(
        props.taskFetchResult.blocked_until
    );

    return (
        <div>
            <h1>ERROR: You are blocked until: {formattedBlockedUntil}</h1>
            {props.taskFetchResult.blocking_reason ? (
                <div style={{ fontSize: "2.5rem", marginTop: "2.5rem" }}>
                    Reason: {props.taskFetchResult.blocking_reason}
                </div>
            ) : null}
        </div>
    );
};

UserIsBlockedErrorPage = connect(state => ({
    taskFetchResult: getTaskFetchResult(state)
}))(UserIsBlockedErrorPage);

export default UserIsBlockedErrorPage;
