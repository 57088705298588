import React from "react";
import { Spinner } from "react-bootstrap";

export const LoadingAnimation = ({ animation, variant }) => {
    return (
        <div id="loading_animation">
            <Spinner
                animation={animation ? animation : "border"}
                variant={variant ? variant : ""}
            />
        </div>
    );
};

export default LoadingAnimation;
