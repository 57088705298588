import MockData from "../MockData/MockData";
import MockApiClient from "../MockData/MockApiClient";
import BaseGuiType from "../BaseGuiType";
import { WIDGETS } from "../../guiFactory";
import {
    covidLungImagesObjects,
    makeDummyImageList
} from "../sharedMockTaskInputs";
import { ActionButtonGroup } from "./ActionButtonGroup";
import { Question } from "./Question";
import PixelBrushCanvas from "./PixelBrushCanvas";
import ResourceLoaderService from "../ResourceLoaderService";

export class PixelBrush extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return WIDGETS.PIXEL_BRUSH;
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const taskInputs = covidLungImagesObjects.slice(0, 7);
        // very wide image
        taskInputs[0].image_url =
            "https://broccolitest.s3.eu-central-1.amazonaws.com/only_road/DS-JP_708_20180202_120254_f000365_fc00488223_4d87dc.png_edges.png";
        taskInputs[0].alt_image_url = makeDummyImageList(
            1162,
            447,
            1,
            "F00",
            "FFF",
            "png",
            "alternative image"
        )[0];
        // very tall image
        taskInputs[1].image_url =
            "https://autocranedevdata.blob.core.windows.net/qm-2020-10-30-minicrane/left_trolley_cam/2020-10-30T11_51_14_579Z.jpg?st=2020-10-29T15%3A53%3A11Z&se=2021-10-30T15%3A53%3A11Z&sp=r&sv=2019-07-07&sr=b&sig=k3W/BMyXSiLWWqa73yPnMisMVa6hoDxq5iFcwW3WR3o%3D";
        // custom wide and tall image
        taskInputs[3].image_url = makeDummyImageList(2000, 3000, 1)[0];
        const mockApiClient = new MockApiClient(
            {
                // instructions: [
                //     `<p>You will see CT-scans of the lung.<br>Circle the contour of the lobes (as shown below)</p>
                //     <img src="https://i.ibb.co/8mnf2Rq/Bildschirmfoto-2020-10-20-um-23-58-39.png" />
                //     <p>Circle the lobes by clicking and dragging the mouse</p>`,
                //     `<p>Select eraser if you need to delete markings.</p>
                //     <p>Change marker/eraser size with the sliders.</p>
                //     <p>When you are finished, click on 'Save' to move on to the next scan.</p>`
                // ],
                question: { text: "Circle the contour of the lung's lobes" },
                brush_color: "rgba(200, 200, 0, 0.035)",
                initial_marker_radius: 40,
                initial_eraser_size: 50,
                reference_image_url:
                    "https://broccolitest.s3.eu-central-1.amazonaws.com/only_road/DS-JP_708_20180208_121128_f000238_fc00315984_4d87dc.png_edges.png"
            },
            WIDGETS.PIXEL_BRUSH,
            taskInputs
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeImageLoaderService(["image_url"])
        );
    }

    /**
     * @override
     */
    getHeading() {
        return Question;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return PixelBrushCanvas;
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return ActionButtonGroup;
    }

    /**
     * @override
     */
    getInitialCurrentTaskOutput() {
        return {
            ...super.makeTaskOutputForCurrentTask(),
            encoded_pixel_data: ""
        };
    }

    /**
     * @inheritdoc
     */
    makeTaskOutputForCurrentTask(overrides) {
        let taskOutput = super.makeTaskOutputForCurrentTask(overrides);
        taskOutput = { ...this.getInitialCurrentTaskOutput(), ...taskOutput };
        return taskOutput;
    }
}

export default PixelBrush;
