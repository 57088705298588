import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Slider from "rc-slider";

import { ActionButtonNextOrSubmit } from "../../../components/ActionButton/ActionButton";
import BBox3DModel from "../../../lib/DataModels/BBox3DModel";
import { OrientationAdjustmentCameraView } from "./OrientationAdjustmentCameraView";
import { Pose3D } from "../../../lib/NRL/Pose3D";
import { TimelineSlider } from "../TimelineSlider";
import {
    AnnotationReviewDialog,
    REVIEW_DIALOG_TYPE
} from "../AnnotationReviewDialog";
import { FinishConfirmDialog } from "../FinishConfirmDialog";

import "../CoconutCanvasScene.css";
import "./OrientationAdjustmentInCameraViewsWidget.css";

const DEFAULT_GUI_SETTINGS = {
    questionText: "Adjust the rotation to fit the image on the left",
    referenceImageUrl: null,
    coordinateSystemLineWidth: 2.0,
    sliderRangeRotation: [-Math.PI, Math.PI],
    sliderStepRotation: 0.01,
    zoomLevel: 1.5,
    disabledCoordinateAxes: [],
    disabledCoordinateAxisColor: "#333",
    worldCoordinates: false
};

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const OrientationAdjustmentInCameraViewsWidget = ({
    taskInput,
    guiSettings,
    resourceCache,
    currentTaskOutput,
    setCurrentTaskOutput,
    taskUIContext,
    currentTaskIdx
}) => {
    const [initialCurrentTaskOutput, setInitialCurrentTaskOutput] = useState(
        taskUIContext.getCurrentGuiObject().getInitialCurrentTaskOutput()
    );
    // The BBox3D data in a representation that's usable by the UI
    // See how TiledBoundingBox3D::getInitialCurrentTaskOutput() deals with the input data for more details.
    const [boxUI, setBoxUI] = useState(
        initialCurrentTaskOutput.box.makeUITypes()
    );
    const [rotationDeltas, setRotationDeltas] = useState([0, 0, 0]);

    const [zoomEnabled, setZoomEnabled] = useState(false);

    // eslint-disable-next-line
    const [timestamps, setTimestamps] = useState(
        Object.keys(taskInput.timestamps).sort((a, b) => Number(a) - Number(b))
    );
    // idx in the timestamp selection
    const [timestampIdx, setTimestampIdx] = useState(0);
    const [trackablePose, setTrackablePose] = useState(null);

    // states to manage the review of annotations
    const [isReviewDialogOpen, setIsReviewDialogOpen] = useState(false);
    const [isReviewDone, setIsReviewDone] = useState(false);
    const [isReviewStarted, setIsReviewStarted] = useState(false);
    const onOpenReviewDialog = () => {
        setIsReviewDialogOpen(true);
    };
    const onCloseReviewDialog = () => {
        setIsReviewDialogOpen(false);
    };
    const onStartReviewPhase = () => {
        setIsReviewStarted(true);
        setTimestampIdx(0);
    };

    // state for finish confirm dialog --> a confirm will trigger a submission
    const [isFinishConfirmDialogOpen, setIsFinishConfirmDialogOpen] = useState(
        false
    );
    const onCloseFinishConfirmDialog = isFinishConfirmed => {
        if (isFinishConfirmed) {
            taskUIContext.pushTaskOutput(currentTaskOutput);
            taskUIContext.dispatchNextTaskOrSubmit();
        } else {
        }
        setIsFinishConfirmDialogOpen(false);
    };

    // default gui settings. this syntax ensures that defaults are picked
    const {
        question: { text: questionText = DEFAULT_GUI_SETTINGS.questionText } = {
            text: DEFAULT_GUI_SETTINGS.questionText
        },
        reference_image_url: referenceImageUrl = DEFAULT_GUI_SETTINGS.referenceImageUrl,
        coordinate_system_line_width: coordinateSystemLineWidth = DEFAULT_GUI_SETTINGS.coordinateSystemLineWidth,
        zoom_level: zoomLevel = DEFAULT_GUI_SETTINGS.zoomLevel,
        slider_ranges: {
            rotation: sliderRangeRotation = DEFAULT_GUI_SETTINGS.sliderRangeRotation
        } = {
            rotation: DEFAULT_GUI_SETTINGS.sliderRangeRotation
        },
        slider_steps: {
            rotation: sliderStepRotation = DEFAULT_GUI_SETTINGS.sliderStepRotation
        } = {
            rotation: DEFAULT_GUI_SETTINGS.sliderStepRotation
        },
        disabled_coordinate_axes: disabledCoordinateAxes = DEFAULT_GUI_SETTINGS.disabledCoordinateAxes,
        disabled_coordinate_axis_color: disabledCoordinateAxisColor = DEFAULT_GUI_SETTINGS.disabledCoordinateAxisColor,
        world_coordinates: worldCoordinates = DEFAULT_GUI_SETTINGS.worldCoordinates
    } = guiSettings;

    useEffect(() => {
        // reset between timestamps
        setZoomEnabled(false);

        // update trackablePose
        const timestamp = timestamps[timestampIdx];
        const timestampData = taskInput.timestamps[timestamp];
        let newTrackablePose = null;
        switch (timestampData.selected_trackable) {
            case "left":
                newTrackablePose = Pose3D.makePoseFromHtm(
                    timestampData.left_trackable_world_pose
                );
                break;
            case "right":
                newTrackablePose = Pose3D.makePoseFromHtm(
                    timestampData.right_trackable_world_pose
                );
                break;
            default:
                break;
        }
        setTrackablePose(newTrackablePose);

        // when in review phase:
        // - check whether the last timestamp has been reached
        // - if yes and output has changed: the review phase is done
        // - else: the review phase is not done
        if (
            isReviewStarted &&
            !isReviewDone &&
            timestampIdx === timestamps.length - 1 &&
            !taskUIContext
                .getCurrentGuiObject()
                .isInitialCurrentTaskOutput(currentTaskOutput)
        ) {
            setIsReviewDone(true);
        } else {
            setIsReviewDone(false);
        }

        // eslint-disable-next-line
    }, [currentTaskIdx, timestampIdx]);

    useEffect(() => {
        // handle the review is done state

        // when in review phase:
        // - check whether the last timestamp has been reached
        // - if yes and output has changed: the review phase is done
        // - else: the review phase is not done
        if (
            isReviewStarted &&
            !isReviewDone &&
            timestampIdx === timestamps.length - 1 &&
            !taskUIContext
                .getCurrentGuiObject()
                .isInitialCurrentTaskOutput(currentTaskOutput)
        ) {
            setIsReviewDone(true);
        } else {
            setIsReviewDone(false);
        }
    }, [currentTaskOutput]);

    const makeCameraView = (name, abbreviation) => {
        const timestamp = timestamps[timestampIdx];
        const timestampData = taskInput.timestamps[timestamp];
        const cameraViewParams = timestampData[abbreviation];
        const hmdWorldPose = timestampData.hmd_world_pose;

        return (
            <Col>
                <OrientationAdjustmentCameraView
                    key={cameraViewParams.image_url + "_" + name}
                    name={name}
                    hmdWorldPose={hmdWorldPose}
                    E={cameraViewParams.E}
                    K={cameraViewParams.K}
                    distortionCoeffs={cameraViewParams.distortion_coeffs}
                    image={resourceCache[cameraViewParams.image_url]}
                    boxUI={boxUI}
                    trackablePose={worldCoordinates ? null : trackablePose}
                    coordinateSystemLineWidth={coordinateSystemLineWidth}
                    withZoom={zoomEnabled}
                    zoomLevel={zoomLevel}
                    disabledCoordinateAxes={disabledCoordinateAxes}
                    disabledCoordinateAxisColor={disabledCoordinateAxisColor}
                />
            </Col>
        );
    };

    const updateTaskOutput = () => {
        setCurrentTaskOutput(
            taskUIContext.getCurrentGuiObject().makeTaskOutputForCurrentTask({
                ...currentTaskOutput,
                box: BBox3DModel.makeBBox3DModelFromBoxUI(boxUI)
            })
        );
    };

    /**
     * Create slider to adjust one of the rotations of boxUI.
     * @param {String} axis x, y or z
     */
    const makeRotationSlider = (axis, color) => {
        // same order as in boxUI.rotation
        const rotIdx = ["y", "x", "z"].indexOf(axis);
        if (rotIdx === -1) {
            return null;
        }
        let disabledAxis = false;
        if (disabledCoordinateAxes.includes(axis)) {
            disabledAxis = true;
        }
        const axisIdx = ["x", "y", "z"].indexOf(axis);
        return (
            <Slider
                className="rotation_slider"
                min={sliderRangeRotation[0]}
                max={sliderRangeRotation[1]}
                step={sliderStepRotation}
                value={rotationDeltas[axisIdx]}
                disabled={disabledAxis}
                trackStyle={{
                    backgroundColor: disabledAxis
                        ? disabledCoordinateAxisColor
                        : color
                }}
                onChange={v => {
                    const theta = v - rotationDeltas[axisIdx];

                    // the axis in box coordinates we want to rotate around
                    const u = [0, 0, 0];
                    u[axisIdx] = 1;
                    const boxPose = new Pose3D(
                        [...boxUI.position],
                        [...boxUI.rotation]
                    );
                    const rotatedBoxPose = boxPose.rotateAroundLocalVector(
                        u,
                        theta
                    );

                    setBoxUI({
                        ...boxUI,
                        rotation: [...rotatedBoxPose.rot]
                    });
                    const newDelta = [...rotationDeltas];
                    newDelta[axisIdx] = v;
                    setRotationDeltas(newDelta);
                }}
                onAfterChange={() => {
                    updateTaskOutput();
                }}
            />
        );
    };

    return (
        <>
            {/* hidden until necessary */}
            <AnnotationReviewDialog
                type={REVIEW_DIALOG_TYPE.ORIENTATION_UI}
                taskInput={taskInput}
                currentTaskOutput={currentTaskOutput}
                // only allow to open when we haven't already started the review phase
                allowOpen={!isReviewStarted}
                onClose={onCloseReviewDialog}
                onStartReviewPhase={onStartReviewPhase}
                isOpen={isReviewDialogOpen}
            />
            {/* hidden until necessary */}
            <FinishConfirmDialog
                shouldShow={isFinishConfirmDialogOpen}
                onClose={onCloseFinishConfirmDialog}
            />
            <h1 style={{ width: 0, minWidth: "100%" }}>{questionText}</h1>
            <Row>
                {taskInput.reference_image_url || referenceImageUrl ? (
                    <Col
                        md="2"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <div>
                            <p>Reference</p>
                            <img
                                src={
                                    taskInput.reference_image_url
                                        ? taskInput.reference_image_url
                                        : referenceImageUrl
                                }
                                style={{
                                    width: "100%",
                                    border: "1px solid black"
                                }}
                                title="Reference Image. Align Rotate the coordinate system to fit with the orientation in this image."
                                alt=""
                            />
                        </div>
                    </Col>
                ) : null}
                <Col>
                    <Row>
                        {makeCameraView("top_left", "tl")}
                        {makeCameraView("top_right", "tr")}
                    </Row>
                    <Row style={{ marginTop: "0.6rem" }}>
                        {makeCameraView("bottom_left", "bl")}
                        {makeCameraView("bottom_right", "br")}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row style={{ marginTop: "0.6rem" }}>
                        <Col>
                            <div className="rotation_slider_description">
                                Rotate around the{" "}
                                <span style={{ color: "#F00" }}>red line</span>
                            </div>
                            {makeRotationSlider("x", "#F00")}
                        </Col>
                        <Col>
                            <div className="rotation_slider_description">
                                Rotate around the{" "}
                                <span style={{ color: "#080" }}>
                                    green line
                                </span>
                            </div>
                            {makeRotationSlider("y", "#0F0")}
                        </Col>
                        <Col>
                            <div className="rotation_slider_description">
                                Rotate around the{" "}
                                <span style={{ color: "#00F" }}>blue line</span>
                            </div>
                            {makeRotationSlider("z", "#00F")}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginBottom: "-1rem" }}>
                <Col
                    style={{
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem"
                    }}
                >
                    <TimelineSlider
                        timestampIdx={timestampIdx}
                        setTimestampIdx={setTimestampIdx}
                        timestampsLen={timestamps.length}
                        disabled={
                            isReviewDialogOpen || isFinishConfirmDialogOpen
                        }
                        withHotKeys
                    />
                    <Row>
                        <Col style={{ textAlign: "right" }}>
                            <Button
                                className="button is-sm"
                                onClick={() => {
                                    const newBoxUI = initialCurrentTaskOutput.box.makeUITypes();
                                    setBoxUI(newBoxUI);
                                    setCurrentTaskOutput(
                                        taskUIContext
                                            .getCurrentGuiObject()
                                            .makeTaskOutputForCurrentTask({
                                                ...currentTaskOutput,
                                                box: BBox3DModel.makeBBox3DModelFromBoxUI(
                                                    newBoxUI
                                                )
                                            })
                                    );
                                    setRotationDeltas([0, 0, 0]);
                                }}
                            >
                                Reset
                            </Button>
                        </Col>
                        <Col style={{ textAlign: "left" }}>
                            <ActionButtonNextOrSubmit
                                taskUIContext={taskUIContext}
                                disabled={
                                    // button should be disabled:
                                    // - before review phase: as long as nothing was changed
                                    // - in review phase: until review phase is done
                                    isReviewStarted
                                        ? !isReviewDone
                                        : taskUIContext
                                              .getCurrentGuiObject()
                                              .isInitialCurrentTaskOutput(
                                                  currentTaskOutput
                                              )
                                }
                                actionSuffix="finish"
                                className="is-primary"
                                onClick={() => {
                                    if (isReviewStarted) {
                                        setIsFinishConfirmDialogOpen(true);
                                    } else {
                                        onOpenReviewDialog(true);
                                    }
                                }}
                            >
                                Finish
                            </ActionButtonNextOrSubmit>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default OrientationAdjustmentInCameraViewsWidget;
