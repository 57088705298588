export class BBox2DModel {
    /**
     * @param {Number} left
     * @param {Number} top
     * @param {Number} right
     * @param {Number} bottom
     */
    constructor(left, top, right, bottom) {
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
    }
}

export default BBox2DModel;
