import MockData from "../MockData/MockData";
import MockApiClient from "../MockData/MockApiClient";
import BaseGuiType from "../BaseGuiType";
import { WIDGETS } from "../../guiFactory";
import { ActionButtonGroup } from "./Components/ActionButtonGroup.jsx";
import { Question } from "./Components/Question";

import ResourceLoaderService from "../ResourceLoaderService";

import { demoInsectImageObjects } from "../sharedMockTaskInputs";
import { BBox2DWidget } from "./Components/BBox2DWidget";

export class BoundingBox2D extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return WIDGETS.BOUNDING_BOX_2D;
    }

    static makeMockData() {
        const taskInputs = demoInsectImageObjects.slice(0, 4);
        taskInputs[0].image_url =
            "https://autocranedevdata.blob.core.windows.net/qm-2020-10-30-minicrane/left_trolley_cam/2020-10-30T11_51_14_579Z.jpg?st=2020-10-29T15%3A53%3A11Z&se=2021-10-30T15%3A53%3A11Z&sp=r&sv=2019-07-07&sr=b&sig=k3W/BMyXSiLWWqa73yPnMisMVa6hoDxq5iFcwW3WR3o%3D";
        taskInputs[2].image_url =
            "https://autocranedevdata.blob.core.windows.net/qm-2020-10-30-minicrane/left_trolley_cam/2020-10-30T11_51_14_579Z.jpg?st=2020-10-29T15%3A53%3A11Z&se=2021-10-30T15%3A53%3A11Z&sp=r&sv=2019-07-07&sr=b&sig=k3W/BMyXSiLWWqa73yPnMisMVa6hoDxq5iFcwW3WR3o%3D";
        const mockApiClient = new MockApiClient(
            {
                instructions: [],
                question: {
                    text: "Match the box to the insect's head"
                },
                // side len of draggable square (px)
                draggable_side_len: 25,
                // size of bounding-box for preSelect feature (px)
                pre_select_side_len: 100
                // auto-snap feature removed for now: see ticket TDT-284
                // Should snap to edges be available in UI? Can still be toggled in the UI.
                // with_auto_snap: true,
                // range of edge-detection for snap to edges (px)
                // snapping_dist: 20,
            },
            WIDGETS.BOUNDING_BOX_2D,
            taskInputs
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeImageLoaderService(["image_url"])
        );
    }

    /**
     * @override
     */
    getHeading() {
        return Question;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return BBox2DWidget;
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return ActionButtonGroup;
    }

    /**
     * @override
     */
    getInitialCurrentTaskOutput() {
        return {
            ...super.makeTaskOutputForCurrentTask(),
            left: null,
            top: null,
            right: null,
            bottom: null
        };
    }

    /**
     * @inheritdoc
     */
    makeTaskOutputForCurrentTask(overrides) {
        let taskOutput = super.makeTaskOutputForCurrentTask(overrides);
        taskOutput = {
            ...this.getInitialCurrentTaskOutput(),
            ...taskOutput
        };
        return taskOutput;
    }
}

export default BoundingBox2D;
