import React, { createContext, useState } from "react";

/**
 *
 * @typedef {Object} LazyLoadSubscriber
 * @property {React.Component} component
 * @property {Function} onBeforeLoad
 * @property {Function} onAfterLoad
 */

/**
 *
 */
export const LazyLoadedSequence = ({ sequence, startIdx = 0 }) => {
    return (
        <LazyLoadedSequenceContext.Consumer>
            {({ sequence, setSequence, sequenceIdx }) => {
                // useEffect(() => {
                //     setSequence(sequence);
                // }, [sequence]);
                console.log(sequence, sequenceIdx);
                return <h1>{sequence}</h1>;
            }}
        </LazyLoadedSequenceContext.Consumer>
    );
};

export const withLazyLoadedSequenceContextProvider = WrappedComponent => {
    return props => {
        const [sequenceIdx, setSequenceIdx] = useState(null);
        const [sequence, setSequence] = useState([]);
        return (
            <LazyLoadedSequenceContext.Provider
                value={{ sequence, setSequence, sequenceIdx, setSequenceIdx }}
            >
                <WrappedComponent {...props} />
            </LazyLoadedSequenceContext.Provider>
        );
    };
};

export const LazyLoadedSequenceContext = createContext({
    sequence: [<div>WARNING: Missing LazyLoadedSequenceContext.Provider</div>],
    setSequence: () => {},
    sequenceIdx: 0,
    setSequenceIdx: () => {}
});

export default LazyLoadedSequence;
