import React from "react";
import { ActionButtonNextOrSubmit } from "../../components/ActionButton/ActionButton";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const ActionButtonGroup = ({ taskUIContext, currentTaskOutput }) => {
    const disabledActionButtons = taskUIContext.shouldActionButtonsBeDisabled();
    const invalidTask = () => {
        return (
            currentTaskOutput === null ||
            currentTaskOutput.polygons.length === 0 ||
            currentTaskOutput.polygons.some(
                pg => !pg.valid_selection || !pg.closed || pg.has_collisions
            )
        );
    };
    const invalidFeedback = () => {
        if (
            currentTaskOutput === null ||
            currentTaskOutput.polygons.length === 0
        ) {
            return "Please add a polygon.";
        }
        if (currentTaskOutput.polygons.some(pg => !pg.closed)) {
            return "Please close all polygons.";
        }
        if (currentTaskOutput.polygons.some(pg => !pg.valid_selection)) {
            return "Please label all polygons.";
        }
        if (currentTaskOutput.polygons.some(pg => pg.has_collisions)) {
            return "Polygons may have no collisions.";
        }
        return "";
    };
    return (
        <div className="position-relative">
            <ActionButtonNextOrSubmit
                taskUIContext={taskUIContext}
                actionSuffix="finish_task"
                disabled={disabledActionButtons || invalidTask()}
                createTaskOutput={() => currentTaskOutput}
                className="is-primary mb-4"
            >
                Submit
            </ActionButtonNextOrSubmit>
            <div
                className="invalid-feedback d-block position-absolute"
                style={{
                    bottom: "2px",
                    left: "0",
                    width: "100%"
                }}
            >
                {invalidFeedback()}
            </div>
        </div>
    );
};
