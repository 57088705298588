import React from "react";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";

import MockData from "../MockData/MockData";
import MockApiClient from "../MockData/MockApiClient";
import BaseGuiType from "../BaseGuiType";
import { WIDGETS } from "../../guiFactory";
import ResourceLoaderService from "../ResourceLoaderService";
import { makeDummyImageList } from "../sharedMockTaskInputs";
import { Question } from "./Question";
import { ActionButtonGroup } from "./ActionButtonGroup";
import MultimediaElements from "./MultimediaElements";
import { makeImageLoader } from "../../lib/DataLoader/loaders";

/**
 * @class
 * @augments BaseGuiType
 */
export class MultimediaQuestion extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return WIDGETS.MULTIMEDIA_QUESTION;
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const dummyImagesLarge = makeDummyImageList(
            1920,
            1080,
            2,
            "a00",
            "fff",
            "png",
            "1920x1080"
        );
        const dummyImagesSmall = makeDummyImageList(
            900,
            600,
            2,
            "0a0",
            "fff",
            "png",
            "900x600"
        );
        const dummyImagesTiny = makeDummyImageList(
            400,
            250,
            2,
            "00a",
            "fff",
            "png",
            "400x250"
        );
        const mockApiClient = new MockApiClient(
            {
                instructions: [],
                question: {
                    text: "This is just a tribute."
                },
                layout: "two_rows", // single_row | two_rows others are possible in the future
                interaction_settings: {
                    type: "slider", // others are possible in the future
                    label: "Set the valence:",
                    slider_settings: {
                        // [type]_settings for kind of dynamic typing of the settings
                        type: "horizontal", // discrete_marks (for the vertical slider) | horizontal
                        range: [1, 5],
                        step_size: 0.01,
                        default_value: "no_value", // use no_value to make the default value non-existent
                        marks: [
                            {
                                type: "image", // string | image
                                label: "/images/arousal_1.png", // label to be displayed. when type is image the label is interpreted as an url
                                width: 50, // only used when type is image
                                height: 50, // only used when type is image
                                at: 1, // at which slider value to show the label
                                // key: "1" // shortcut key, fully optional. if set then the mark has a tooltip with this info
                            },
                            {
                                type: "image",
                                label: "/images/arousal_2.png",
                                width: 50,
                                height: 50,
                                at: 3,
                                // key: "2"
                            },
                            {
                                type: "image",
                                label: "/images/arousal_3.png",
                                width: 50,
                                height: 50,
                                at: 5,
                                // key: "3"
                            }
                        ]
                    }
                }
            },
            MultimediaQuestion.getName(),
            [
                {
                    media_elements: [
                        {
                            type: "image", // video | image | audio
                            url: dummyImagesSmall[0],
                            label: "Dummy Image 1"
                        },
                        {
                            type: "image", // video | image | audio
                            url: dummyImagesTiny[0],
                            label: "Dummy Image 2"
                        }
                    ]
                },
                {
                    media_elements: [
                        {
                            type: "image", // video | image | audio
                            url: dummyImagesLarge[1],
                            label: "Dummy Image 3"
                        },
                        {
                            type: "video", // video | image | audio
                            url:
                                "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4",
                            label: "Dummy Video 1"
                        }
                    ]
                },
                {
                    media_elements: [
                        {
                            type: "video", // video | image | audio
                            url:
                                "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4",
                            label: "Dummy Video 2", // optional
                            max_playbacks: 3 // optional: only used when type is video
                        },
                        {
                            type: "video", // video | image | audio
                            url:
                                "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
                            label: "Dummy Video 3",
                            max_playbacks: 2
                        }
                    ]
                }
            ]
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        // preload all media element urls with type image
        this.setResourceLoaderService(
            ResourceLoaderService.makeCustomLoaderService(
                new Map([
                    [
                        "media_url_loader",
                        taskInput => {
                            const imageLoaders = [];
                            for (const mediaElement of taskInput.media_elements) {
                                if (mediaElement.type === "image") {
                                    imageLoaders.push(
                                        makeImageLoader(mediaElement.url)
                                    );
                                }
                            }
                            return imageLoaders;
                        }
                    ]
                ])
            )
        );
    }

    /**
     * @override
     * @returns {import("../BaseGuiType").LayoutCreator}
     */
    getLayoutCreator() {
        return (
            heading,
            dataVis,
            progressBar,
            actionButtonGroup,
            metaActionButtonGroup
        ) => {
            const components = [
                heading,
                dataVis,
                progressBar,
                actionButtonGroup,
                metaActionButtonGroup
            ];
            const [
                Heading,
                DataVis,
                // eslint-disable-next-line
                ProgressBar,
                ActionButtonGroup,
                MetaActionButtonGroup
            ] = this.connectAndInstantiateComponents(components);

            return (
                <Container
                    style={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: "25px",
                        maxWidth: "100vw",
                        height: "fit-content",
                        maxHeight: "100vh"
                    }}
                >
                    <Row>
                        <Col style={{ textAlign: "center" }}>{Heading}</Col>
                    </Row>
                    {DataVis}
                    <Row style={{ textAlign: "center" }}>
                        <Col>{ActionButtonGroup}</Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col>{MetaActionButtonGroup}</Col>
                    </Row>
                </Container>
            );
        };
    }

    /**
     * @override
     */
    getHeading() {
        return Question;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return MultimediaElements;
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return ActionButtonGroup;
    }

    /**
     * @override
     */
    getInitialCurrentTaskOutput() {
        const telemetryOutputNames = this.getCurrentTaskTelemetryOutputNames();
        // reduce to single object
        const telemetryOutputs = telemetryOutputNames.reduce(
            (outputs, name) => {
                outputs[name] = 0;
                return outputs;
            },
            {}
        );
        return {
            ...super.makeTaskOutputForCurrentTask(),
            answer: null,
            ...telemetryOutputs
        };
    }

    /**
     * @override
     */
    makeTaskOutputForCurrentTask(overrides) {
        const telemetryOutputNames = this.getCurrentTaskTelemetryOutputNames();
        // reduce to single object
        const telemetryOutputs = telemetryOutputNames.reduce(
            (outputs, name) => {
                outputs[name] = this.actionTelemetries.has(name)
                    ? this.actionTelemetries.get(name).cnt
                    : 0;
                return outputs;
            },
            {}
        );
        return {
            ...this.getInitialCurrentTaskOutput(),
            ...overrides,
            ...telemetryOutputs
        };
    }

    /**
     * @override
     */
    onDispatchNextTask() {
        this.cleanUpPerTaskActionTelemetries();
    }

    /**
     * @override
     */
    onDispatchPreviousTask() {
        this.cleanUpPerTaskActionTelemetries();
    }

    cleanUpPerTaskActionTelemetries() {
        const outputNames = this.getCurrentTaskTelemetryOutputNames();
        outputNames.forEach(name => this.actionTelemetries.delete(name));
    }

    /**
     * The task output can be dynamic. For each media element that's a video
     * and has a label, that output should be generated.
     * @returns {String[]}
     */
    getCurrentTaskTelemetryOutputNames() {
        const taskInput = this.taskUIContext.getCurrentTaskInput();
        const names = [];
        for (const mediaElement of taskInput.media_elements) {
            if (mediaElement.type === "video" && mediaElement.label) {
                names.push("played_" + mediaElement.label);
                names.push("ended_" + mediaElement.label);
            }
        }
        return names;
    }
}

export default MultimediaQuestion;
