import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { FormControlLabel } from "@material-ui/core";
import PropTypes from "prop-types";

// list of cant solve reasons. These are rendered
// in the modal and keys are send to taskUIContext
export const REASONS = {
    corrupt_data: {
        key: "corrupt_data",
        label: "Something looks wrong with the data"
    },
    cant_solve: {
        key: "cant_solve",
        label: "Cannot solve the problem"
    },
    inappropriate: {
        key: "inappropriate",
        label: "Inappropriate content"
    },
    else: {
        key: "else",
        label: "Something else..."
    }
};
// order to render the cant solve options
const REASON_ORDER = ["corrupt_data", "cant_solve", "inappropriate", "else"];

const CantSolveDialog = ({ open, onClose, onSend }) => {
    const onCancelClicked = () => {
        onClose();
    };

    const handleChange = e => {
        onSend(e.target.value);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                PaperProps={{
                    style: {
                        color: '#000000',
                        borderRadius: 30,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        width: '450px',
                        padding: '20px 30px 10px 30px'
                    }
                   }}
            >
                <DialogTitle id="form-dialog-title">
                    Unclear Answer?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Have you seen any inappropriate content or encountered
                        problems with our app? Please let us know!
                    </DialogContentText>
                    <RadioGroup
                        aria-label="Reason"
                        name="reason"
                        className={"reason-radio-group"}
                        onChange={handleChange}
                    >
                        {REASON_ORDER.map(i => (
                            <FormControlLabel
                                key={REASONS[i].key}
                                value={REASONS[i].key}
                                control={<Radio />}
                                label={REASONS[i].label}
                            />
                        ))}
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelClicked} color="default">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

CantSolveDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSend: PropTypes.func.isRequired
};

export default CantSolveDialog;
