import React from "react";
import { ActionButtonNextOrSubmit } from "../../../components/ActionButton/ActionButton";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const ActionButtonGroup = ({ taskUIContext, currentTaskOutput }) => {
    const disabledActionButtons = taskUIContext.shouldActionButtonsBeDisabled();
    let taskOutputDoesntExist =
        currentTaskOutput === null ||
        taskUIContext
            .getCurrentGuiObject()
            .isInitialCurrentTaskOutput(currentTaskOutput);
    return (
        <div>
            <ActionButtonNextOrSubmit
                taskUIContext={taskUIContext}
                actionSuffix="set_bounding_box"
                disabled={disabledActionButtons || taskOutputDoesntExist}
                createTaskOutput={() => currentTaskOutput}
                className="is-primary"
            >
                Set Bounding Box
            </ActionButtonNextOrSubmit>
        </div>
    );
};
