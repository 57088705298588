//refactored from https://github.com/mrdoob/three.js/blob/dev/examples/jsm/math/OBB.js
import { Matrix3, Vector3 } from "three";
import { matMultMat } from "./Pose3D";

// OBB
export class OBB {
    /**
     * @param {Vector3} center
     * @param {Vector3} halfSize
     * @param {Matrix3} rotation
     */
    constructor(
        center = new Vector3(0, 0, 0),
        halfSize = new Vector3(1, 1, 1),
        rotation = new Matrix3()
    ) {
        this.center = center;
        this.halfSize = halfSize;
        this.rotation = rotation;
    }

    /**
     *
     * @param {LinesRenderable} box
     */
    fromLinesRenderable(box) {
        this.center.set(
            box.poseWorld.pos[0],
            box.poseWorld.pos[1],
            box.poseWorld.pos[2]
        );
        this.halfSize.set(
            -box.lines[0][0][0],
            box.lines[0][0][1],
            -box.lines[0][0][2]
        );

        const rot = box.poseWorld.rot;
        // roll (x-axis)
        const rotX = [
            [1, 0, 0],
            [0, Math.cos(rot[1]), -Math.sin(rot[1])],
            [0, Math.sin(rot[1]), Math.cos(rot[1])]
        ];
        // pitch (y-axis)
        const rotY = [
            [Math.cos(rot[0]), 0, Math.sin(rot[0])],
            [0, 1, 0],
            [-Math.sin(rot[0]), 0, Math.cos(rot[0])]
        ];
        // yaw (z-axis)
        const rotZ = [
            [Math.cos(rot[2]), -Math.sin(rot[2]), 0],
            [Math.sin(rot[2]), Math.cos(rot[2]), 0],
            [0, 0, 1]
        ];
        // rotation order is: YXZ
        const rotation = matMultMat(matMultMat(rotY, rotX), rotZ);

        this.rotation.set(
            rotation[0][0],
            rotation[0][1],
            rotation[0][2],
            rotation[1][0],
            rotation[1][1],
            rotation[1][2],
            rotation[2][0],
            rotation[2][1],
            rotation[2][2]
        );
    }

    /**
     *
     * @param {CurvesRenderable} box
     *
     */
    fromCurvesRenderable(box) {
        this.center.set(
            box.poseWorld.pos[0],
            box.poseWorld.pos[1],
            box.poseWorld.pos[2]
        );
        this.halfSize.set(
            -box.curves[0][0][0],
            box.curves[0][0][1],
            -box.curves[0][0][2]
        );

        const rot = box.poseWorld.rot;
        // roll (x-axis)
        const rotX = [
            [1, 0, 0],
            [0, Math.cos(rot[1]), -Math.sin(rot[1])],
            [0, Math.sin(rot[1]), Math.cos(rot[1])]
        ];
        // pitch (y-axis)
        const rotY = [
            [Math.cos(rot[0]), 0, Math.sin(rot[0])],
            [0, 1, 0],
            [-Math.sin(rot[0]), 0, Math.cos(rot[0])]
        ];
        // yaw (z-axis)
        const rotZ = [
            [Math.cos(rot[2]), -Math.sin(rot[2]), 0],
            [Math.sin(rot[2]), Math.cos(rot[2]), 0],
            [0, 0, 1]
        ];
        // rotation order is: YXZ
        const rotation = matMultMat(matMultMat(rotY, rotX), rotZ);

        this.rotation.set(
            rotation[0][0],
            rotation[0][1],
            rotation[0][2],
            rotation[1][0],
            rotation[1][1],
            rotation[1][2],
            rotation[2][0],
            rotation[2][1],
            rotation[2][2]
        );
    }

    /**
     * @param {OBB} obb
     * @return {OBB}
     */
    copy(obb) {
        this.center.copy(obb.center);
        this.halfSize.copy(obb.halfSize);
        this.rotation.copy(obb.rotation);

        return this;
    }

    /**
     * @return {OBB}
     */
    clone() {
        return new this.constructor().copy(this);
    }

    /**
     * Reference: OBB-OBB Intersection in Real-Time Collision Detection
     * by Christer Ericson (chapter 4.4.1)
     *
     * @param {OBB} obb
     * @returns {Boolean}
     */
    intersectsOBB(obb, epsilon = Number.EPSILON) {
        let a = {
            c: null, // center
            u: [new Vector3(), new Vector3(), new Vector3()], // basis vectors
            e: [] // half width
        };

        let b = {
            c: null, // center
            u: [new Vector3(), new Vector3(), new Vector3()], // basis vectors
            e: [] // half width
        };

        let R = [[], [], []];
        let AbsR = [[], [], []];
        let t = [];

        let v1 = new Vector3();

        // prepare data structures (the code uses the same nomenclature like the reference)

        a.c = this.center;
        a.e[0] = this.halfSize.x;
        a.e[1] = this.halfSize.y;
        a.e[2] = this.halfSize.z;
        this.rotation.extractBasis(a.u[0], a.u[1], a.u[2]);

        b.c = obb.center;
        b.e[0] = obb.halfSize.x;
        b.e[1] = obb.halfSize.y;
        b.e[2] = obb.halfSize.z;
        obb.rotation.extractBasis(b.u[0], b.u[1], b.u[2]);

        // compute rotation matrix expressing b in a's coordinate frame

        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 3; j++) {
                R[i][j] = a.u[i].dot(b.u[j]);
            }
        }

        // compute translation vector

        v1.subVectors(b.c, a.c);

        // bring translation into a's coordinate frame

        t[0] = v1.dot(a.u[0]);
        t[1] = v1.dot(a.u[1]);
        t[2] = v1.dot(a.u[2]);

        // compute common subexpressions. Add in an epsilon term to
        // counteract arithmetic errors when two edges are parallel and
        // their cross product is (near) null

        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 3; j++) {
                AbsR[i][j] = Math.abs(R[i][j]) + epsilon;
            }
        }

        var ra, rb;

        // test axes L = A0, L = A1, L = A2
        for (let i = 0; i < 3; i++) {
            ra = a.e[i];
            rb =
                b.e[0] * AbsR[i][0] + b.e[1] * AbsR[i][1] + b.e[2] * AbsR[i][2];
            if (Math.abs(t[i]) > ra + rb) return false;
        }

        // test axes L = B0, L = B1, L = B2
        for (let i = 0; i < 3; i++) {
            ra =
                a.e[0] * AbsR[0][i] + a.e[1] * AbsR[1][i] + a.e[2] * AbsR[2][i];
            rb = b.e[i];
            if (
                Math.abs(t[0] * R[0][i] + t[1] * R[1][i] + t[2] * R[2][i]) >
                ra + rb
            )
                return false;
        }

        // test axis L = A0 x B0
        ra = a.e[1] * AbsR[2][0] + a.e[2] * AbsR[1][0];
        rb = b.e[1] * AbsR[0][2] + b.e[2] * AbsR[0][1];
        if (Math.abs(t[2] * R[1][0] - t[1] * R[2][0]) > ra + rb) return false;

        // test axis L = A0 x B1
        ra = a.e[1] * AbsR[2][1] + a.e[2] * AbsR[1][1];
        rb = b.e[0] * AbsR[0][2] + b.e[2] * AbsR[0][0];
        if (Math.abs(t[2] * R[1][1] - t[1] * R[2][1]) > ra + rb) return false;

        // test axis L = A0 x B2
        ra = a.e[1] * AbsR[2][2] + a.e[2] * AbsR[1][2];
        rb = b.e[0] * AbsR[0][1] + b.e[1] * AbsR[0][0];
        if (Math.abs(t[2] * R[1][2] - t[1] * R[2][2]) > ra + rb) return false;

        // test axis L = A1 x B0
        ra = a.e[0] * AbsR[2][0] + a.e[2] * AbsR[0][0];
        rb = b.e[1] * AbsR[1][2] + b.e[2] * AbsR[1][1];
        if (Math.abs(t[0] * R[2][0] - t[2] * R[0][0]) > ra + rb) return false;

        // test axis L = A1 x B1
        ra = a.e[0] * AbsR[2][1] + a.e[2] * AbsR[0][1];
        rb = b.e[0] * AbsR[1][2] + b.e[2] * AbsR[1][0];
        if (Math.abs(t[0] * R[2][1] - t[2] * R[0][1]) > ra + rb) return false;

        // test axis L = A1 x B2
        ra = a.e[0] * AbsR[2][2] + a.e[2] * AbsR[0][2];
        rb = b.e[0] * AbsR[1][1] + b.e[1] * AbsR[1][0];
        if (Math.abs(t[0] * R[2][2] - t[2] * R[0][2]) > ra + rb) return false;

        // test axis L = A2 x B0
        ra = a.e[0] * AbsR[1][0] + a.e[1] * AbsR[0][0];
        rb = b.e[1] * AbsR[2][2] + b.e[2] * AbsR[2][1];
        if (Math.abs(t[1] * R[0][0] - t[0] * R[1][0]) > ra + rb) return false;

        // test axis L = A2 x B1
        ra = a.e[0] * AbsR[1][1] + a.e[1] * AbsR[0][1];
        rb = b.e[0] * AbsR[2][2] + b.e[2] * AbsR[2][0];
        if (Math.abs(t[1] * R[0][1] - t[0] * R[1][1]) > ra + rb) return false;

        // test axis L = A2 x B2
        ra = a.e[0] * AbsR[1][2] + a.e[1] * AbsR[0][2];
        rb = b.e[0] * AbsR[2][1] + b.e[1] * AbsR[2][0];
        if (Math.abs(t[1] * R[0][2] - t[0] * R[1][2]) > ra + rb) return false;

        // since no separating axis is found, the OBBs must be intersecting
        return true;
    }

    /**
     * @param {OBB} obb
     * @return {Boolean}
     */
    equals(obb) {
        return (
            obb.center.equals(this.center) &&
            obb.halfSize.equals(this.halfSize) &&
            obb.rotation.equals(this.rotation)
        );
    }
}
