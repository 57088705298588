/**
 * A function that decodes a Promise to an arbitrary object and
 * returns the resolved resource
 * @callback decodeResource
 * @param {Promise<any>} any
 * @returns {any}
 */

/**
 * @callback decodeResponse
 * @param {Response} response
 * @returns {Object}
 */

/**
 * Returns decoded protobuf from a fetch Response
 * @param {import("google-protobuf").Message} ProtobufMessageClass message type from the compiled proto js file
 * @returns {decodeResponse}
 */
export const decodeProtobufFromResponseCreator = ProtobufMessageClass => {
    return response => {
        return response
            .arrayBuffer()
            .then(bytes => new Uint8Array(bytes))
            .then(typedBytes => {
                // deserializeBinary is a static method
                return ProtobufMessageClass.deserializeBinary(
                    typedBytes
                ).toObject();
            });
    };
};

/**
 * @param {Promise<Response>} response
 * @returns {Object|Array}
 */
export const decodeJson = response => response.json();

/**
 * @param {Promise<any>} any
 * @returns {any}
 */
export const decodeAny = any => Promise.resolve(any);
