import { makeImageLoader, Loader } from "../lib/DataLoader/loaders";

/**
 * A Function that creates a Map of loaders that are used for loading the resources of a single taskInput
 * Entries of the returned map are <url, Loader>
 * @callback LoaderMapCreator
 * @param {Object} taskInput
 * @returns {Map<String, Loader>}
 */

/**
 * A function that receives a taskInput and returns a Loader with a resource url or an array of Loaders
 * @callback TaskInputResourceLoaderCreator
 * @param {Object} taskInput
 * @returns {Loader|Loader[]} Loader for a resource url or array of Loaders for resource urls
 */

/**
 * @class
 */
export class ResourceLoaderService {
    /**
     * @param {LoaderMapCreator} loaderMapCreator
     */
    constructor(loaderMapCreator) {
        this.loaderMapCreator = loaderMapCreator;

        /**
         * List of maps of resource urls and loaders for each taskInput
         * @type {Map<String, Loader>[]}
         */
        this.taskInputLoaderMaps = null;
    }

    /**
     * A ResourceLoaderService that doesn't load anything.
     * @return {ResourceLoaderService}
     */
    static makeNoOpResourceLoaderService() {
        return ResourceLoaderService.makeCustomLoaderService(new Map());
    }

    /**
     * Returns a ResourceLoaderService with a function that creates a Map of Loaders for all taskInputs.
     * - works for a taskInput parameters that are an image url
     * @param {String[]} taskInputKeys
     * @return {ResourceLoaderService}
     */
    static makeImageLoaderService(taskInputKeys) {
        const taskInputLoaderMap = new Map();
        for (const taskInputKey of taskInputKeys) {
            taskInputLoaderMap.set(taskInputKey, makeImageLoader(null));
        }
        return ResourceLoaderService.makeCustomLoaderService(
            taskInputLoaderMap
        );
    }

    /**
     * Returns a ResourceLoaderService with a function that creates a Map of Loaders for all taskInputs.
     * - taskInputLoaderMap maps keys for resource urls in a taskInput to Loader objects.
     *   it's basically a template that's copied for every task input.
     * - if a taskInput's resource is an array, then then same loader will be created for every entry
     * - if the the value of a map entry isn't a Loader then it's treated as TaskInputResourceLoaderCreator and the key doesn't have to be an actual key in the taskInput (useful for nested resources)
     * @param {Map<String, Loader|TaskInputResourceLoaderCreator>} taskInputLoaderMap maps <resource url, Loader|TaskInputResourceLoaderCreator> for all resource urls contained in a task input
     * @return {ResourceLoaderService}
     */
    static makeCustomLoaderService(taskInputLoaderMap) {
        const loaderMapCreator = taskInput => {
            const loaderMap = new Map();
            for (const [taskInputKey, loader] of taskInputLoaderMap.entries()) {
                if (loader instanceof Loader) {
                    // use the given loader as a template for the url(s) of resource
                    ResourceLoaderService.addToLoaderMapFromLoaderTemplate(
                        loaderMap,
                        loader,
                        taskInput,
                        taskInputKey
                    );
                } else {
                    // the loader is treated as TaskInputResourceLoaderCreator
                    // use the loader(s) created by the the TaskInputResourceLoaderCreator
                    ResourceLoaderService.addToLoaderMapFromResourceLoaderCreator(
                        loaderMap,
                        loader,
                        taskInput
                    );
                }
            }
            return loaderMap;
        };
        return new ResourceLoaderService(loaderMapCreator);
    }

    /**
     * @param {Map<String, Loader>} loaderMap the target map
     * @param {Loader} loaderTemplate
     * @param {Object} taskInput
     * @param {String} taskInputKey
     */
    static addToLoaderMapFromLoaderTemplate(
        loaderMap,
        loaderTemplate,
        taskInput,
        taskInputKey
    ) {
        /**
         * @type {String|String[]}
         */
        const resource = taskInput[taskInputKey];
        if (resource === undefined) {
            throw new Error(
                `key ${taskInputKey} doesn't exist in task_input (task_input is ${typeof taskInput}).\nAvailable keys:${Object.keys(
                    taskInput
                ).reduce((str, key) => str + " " + key, "")}`
            );
        }

        const copyAndAddLoader = url => {
            const copiedLoader = new Loader(
                url,
                loaderTemplate.loader,
                loaderTemplate.decoder
            );
            loaderMap.set(url, copiedLoader);
        };

        if (Array.isArray(resource)) {
            resource.forEach(copyAndAddLoader);
        } else {
            copyAndAddLoader(resource);
        }
    }

    /**
     * @param {Map<String, Loader>} loaderMap the target map
     * @param {TaskInputResourceLoaderCreator} resourceLoaderCreator
     * @param {Object} taskInput
     */
    static addToLoaderMapFromResourceLoaderCreator(
        loaderMap,
        resourceLoaderCreator,
        taskInput
    ) {
        const loader = resourceLoaderCreator(taskInput);
        const checkAndAddLoader = v => {
            if (!(v instanceof Loader)) {
                throw new Error(
                    "TaskInputResourceLoaderCreator didn't create an instance of Loader!"
                );
            }
            if (v.url === null || v.url === undefined) {
                throw new Error(
                    "Check the TaskInputResourceLoaderCreator! It created a loader without url! It's maybe just a typo."
                );
            }
            loaderMap.set(v.url, v);
        };
        if (Array.isArray(loader)) {
            loader.forEach(checkAndAddLoader);
        } else {
            checkAndAddLoader(loader);
        }
    }

    /**
     * @param {Object[]} taskInputs
     */
    initTaskInputLoaderMaps(taskInputs) {
        if (!this.loaderMapCreator) {
            throw new Error(
                "loaderMapCreator of ResourceLoaderService not initialized, yet!"
            );
        }
        this.taskInputLoaderMaps = taskInputs.map(taskInput =>
            this.loaderMapCreator(taskInput)
        );
    }

    getTaskInputLoaderMaps() {
        if (!this.taskInputLoaderMaps) {
            throw new Error("taskInputLoaderMaps not initialized, yet!");
        }
        return this.taskInputLoaderMaps;
    }

    /**
     * Creates a single Map of Loaders that contains each taskInput's Loaders.
     * @returns {Map<String, Loader>}
     */
    getResourceLoaderMapForAllTaskInputs() {
        return new Map(
            this.getTaskInputLoaderMaps().reduce(
                (cumulated, loaderMap) => [...cumulated, ...loaderMap],
                []
            )
        );
    }
}

export default ResourceLoaderService;
