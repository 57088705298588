import { WIDGETS } from "../../guiFactory";
import BaseGuiType from "../BaseGuiType";
import MockApiClient from "../MockData/MockApiClient";
import MockData from "../MockData/MockData";
import ResourceLoaderService from "../ResourceLoaderService";
import { ActionButtonGroup } from "./ActionButtonGroup";
import AudioPlayers from "./AudioPlayers";
import { spotifyAudioPlayerRecords } from "./MockTaskInputs";
import { Question } from "./Question";

export class AudioSimilarity extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return WIDGETS.AUDIO_SIMILARITY;
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const taskInputs = spotifyAudioPlayerRecords;
        const infoSlide = `<div style="width: 1000px; height: 800px;">
        <h1 style="margin-top: 20px; margin-bottom: 40px;">Similarity of music</h1>
        <h3>Listen to both audio tracks</h3>
        <div style="font-size: 1.5rem; width: 500px; margin: auto; margin-top: 40px; text-align: left;">
            Decide by paying <b>attention</b> to the music's
            <ul style="margin-top: 10px; line-height: 1.8rem;">
                <li>genre</li>
                <li>mood</li>
                <li>singing voice</li>
                <li>timbre / sound color</li>
            </ul>
        </div>
    </div>`;
        const mockApiClient = new MockApiClient(
            {
                instructions: [
                    // for discrete_answer
                    `<div style="width: 1000px; height: 800px;">
                        <h1 style="margin-top: 20px; margin-bottom: 40px;">Similarity of music</h1>
                        <h3>Use the <span style="background-color: #de6588; padding: 4px;"><b><u>not similar</u></b></span> and <span style="background-color: #de6588; padding: 4px;"><b><u>similar</u></b></span> buttons</h3>
                        <img width="750px" style="border: 3px solid red;" src="https://qm-instructions.s3.eu-central-1.amazonaws.com/audio_similarity/discrete_answer_similar_1.png" />
                    </div>`,
                    infoSlide,
                    `<div style="width: 1000px; height: 800px;">
                        <h1 style="margin-top: 20px; margin-bottom: 40px;">Similarity of music</h1>
                        <h3>Click on "Corrupt Data" only if the audio <b>doesn't play</b>.</h3>
                        <img width="750px" style="border: 3px solid red;" src="https://qm-instructions.s3.eu-central-1.amazonaws.com/audio_similarity/discrete_answer_corrupt_data.png" />
                    </div>`
                ],
                // instructions: [
                //     // for likert_scale
                //     `<div style="width: 1000px; height: 800px;">
                //         <h1 style="margin-top: 20px; margin-bottom: 40px;">Similarity of music</h1>
                //         <h3>Rate the similarity of the audio tracks from 1 to 10.</h3>
                //         <img width="700px" style="border: 3px solid red;" src="https://qm-instructions.s3.eu-central-1.amazonaws.com/audio_similarity/likert_similar_1.png" />
                //     </div>`,
                //     infoSlide,
                //     `<div style="width: 1000px; height: 800px;">
                //         <h1 style="margin-top: 20px; margin-bottom: 40px;">Similarity of music</h1>
                //         <h3>Click on "Corrupt Data" only if the audio <b>doesn't play</b>.</h3>
                //         <img width="750px" style="border: 3px solid red;" src="https://qm-instructions.s3.eu-central-1.amazonaws.com/audio_similarity/likert_scale_corrupt_data.png" />
                //     </div>`
                // ],
                question: {
                    text: "Do the audio tracks sound similar to you?" // thumbs up/down
                    // text:
                    //     "Rate the similarity of the audio tracks from 1 to 10." // 10 point likert scale
                },
                action_button_group: "discrete_answer"
                // action_button_group: "likert_scale"
            },
            WIDGETS.AUDIO_SIMILARITY,
            taskInputs
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeNoOpResourceLoaderService()
        );
    }

    /**
     * @override
     */
    getLayoutCreator() {
        return this.getDefaultLayoutCreator({ padding: "20px" });
    }

    /**
     * @override
     */
    getHeading() {
        return Question;
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return ActionButtonGroup;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return AudioPlayers;
    }

    /**
     * @override
     */
    getInitialCurrentTaskOutput() {
        return {
            ...super.makeTaskOutputForCurrentTask(), // make sure to add corrupt_data and cant_solve
            similarity: null, // a number between 1 and 10 for likert_scale and string for discrete_answer
            record_0: {
                played_cnt: 0,
                stopped_cnt: 0,
                fully_played_cnt: 0
            },
            record_1: {
                played_cnt: 0,
                stopped_cnt: 0,
                fully_played_cnt: 0
            },
            combined_id: this.taskUIContext.getCurrentTaskInput().combined_id
        };
    }

    /**
     * @inheritdoc
     */
    makeTaskOutputForCurrentTask(overrides) {
        let taskOutput = super.makeTaskOutputForCurrentTask(overrides);
        taskOutput = {
            ...this.getInitialCurrentTaskOutput(),
            ...taskOutput
        };
        return taskOutput;
    }
}

export default AudioSimilarity;
