/**
 * A function that returns a Promise to an arbitrary object
 * @callback loadResource
 * @param {String} url
 * @returns {Promise<Object>}
 */

import { ImageCache } from "../ImageCache";
import { decodeAny, decodeJson } from "./decoders";

export class Loader {
    /**
     * @param {String} url
     * @param {loadResource} loader
     * @param {import("./decoders").decodeResource} decoder
     */
    constructor(url, loader, decoder) {
        this.url = url;
        this.loader = loader;
        this.decoder = decoder;
        this.loadStartMillis = null;
        this.loadEndMillis = null;
    }

    /**
     * @returns {Number}
     */
    calcLoadingTimeMillis() {
        if (
            this.loadStartMillis === null ||
            this.loadEndMillis === null ||
            this.loadStartMillis > this.loadEndMillis
        ) {
            console.error(
                `loadStartMillis ${this.loadStartMillis} or loadEndMillis ${this.loadEndMillis} weren't set correctly`
            );
            return -1;
        }
        return this.loadEndMillis - this.loadStartMillis;
    }

    /**
     * @returns {Promise<Object>}
     */
    load() {
        this.loadStartMillis = Date.now();
        return this.loader(this.url)
            .then(data => {
                this.loadEndMillis = Date.now();
                return data;
            })
            .catch(err => {
                this.loadEndMillis = Date.now();
                // raise new error because we won't handle that here and
                // the actual error for an image is often too bland
                throw new Error(
                    `couldn't load resource with url '${this.url}'`
                );
            });
    }

    /**
     * @returns {Object}
     */
    loadAndDecode() {
        return this.load().then(data => this.decoder(data));
    }
}

/**
 * @param {String} url
 * @returns {Promise<Response>}
 */
export const fetchLoad = url => {
    return fetch(url);
};

/**
 * @param {String} url
 * @returns {Promise<Image>}
 */
export const loadImageObject = url => {
    // TODO: move the image loading and image object creation logic from
    //       ImageCache to a proper loader class in next refactor
    const imgCache = new ImageCache();
    return imgCache.get(url);
};

/**
 * @param {String} url
 * @returns {Loader}
 */
export const makeImageLoader = url =>
    new Loader(url, loadImageObject, decodeAny);

/**
 * @param {String} url
 * @returns {Loader}
 */
export const makeJsonLoader = url => new Loader(url, fetchLoad, decodeJson);
