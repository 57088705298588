import { ActionTypes } from "../actions";
import generateReducer from "../generateReducer";

const initialResourceCache = {};

export const resourceCacheReducer = generateReducer(initialResourceCache, {
    [ActionTypes.ADD_RESOURCE_TO_CACHE]: (resourceCache, action) => {
        return { ...resourceCache, ...{ [action.url]: action.resource } };
    }
});

export const resourceLoadingErrorsReducer = generateReducer(
    initialResourceCache,
    {
        [ActionTypes.ADD_RESOURCE_LOADING_ERROR]: (
            resourceLoadingErrors,
            action
        ) => {
            return {
                ...resourceLoadingErrors,
                ...{ [action.url]: action.reason }
            };
        }
    }
);

export const getResourceCache = state => {
    return state.resourceCache;
};

export const getResourceLoadingErrors = state => {
    return state.resourceLoadingErrors;
};
