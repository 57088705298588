import React from "react";

import ThankYouView from "./containers/ThankYou/ThankYouView";
import ThankYouViewGaming from "./containers/ThankYou/ThankYouViewGaming";
import TimeoutPage from "./components/Timer/TimeoutPage";
import Instructions from "./components/Instructions/Instructions";
import InstructionsGaming from "./components/Instructions/InstructionsGaming";
import UserIsBlockedErrorPage from "./components/ErrorPages/UserBlocked";
import UserIsBlockedGamingErrorPage from "./components/ErrorPages/UserBlockedGaming";
import { LoadingAnimation } from "./components/LoadingAnimation/LoadingAnimation";

/**
 * @typedef {String} WIDGET
 */

/**
 * @enum {WIDGET}
 */
export const WIDGETS = {
    // Below are all the GUIs using the old architecture. To be ported to the new architecture.
    SPOTIFY_AUDIO_PLAYER: "spotify_audio_player",
    ZOOM_KEYPOINT_SELECTION: "zoom_keypoint_selection",
    POINTCLOUD_PROJECTION_RESULTS: "pointcloud_projection_results",
    // Cleaned up GUIs using the new architecture:
    KEYPOINT_SELECTION: "keypoint_selection",
    DISCRETE_ANSWER: "discrete_answer",
    DISCRETE_ANSWER_GAMING: "discrete_answer_gaming",
    BINARY_COMPARISON: "binary_comparison",
    IMAGE_SELECTION: "image_selection",
    POLYGON: "polygon",
    POINTCLOUD_COG: "pointcloud_cog",
    POINTCLOUD_BOUNDING_BOX_3D: "pointcloud_bounding_box_3d",
    BOUNDING_BOX_2D: "bounding_box_2d",
    BOUNDING_BOX_2D_LABELED: "bounding_box_2d_labeled",
    AUDIO_SIMILARITY: "audio_similarity",
    PIXEL_BRUSH: "pixel_brush",
    MULTIMEDIA_QUESTION: "multimedia_question",
    TILED_KEYPOINT_3D: "tiled_keypoint_3d",
    TILED_BOUNDING_BOX_3D: "tiled_bounding_box_3d",
    TILED_ORIENTATION_ADJUSTMENT_3D: "tiled_orientation_adjustment_3d",
    TILED_BOUNDING_BOX_FACE_ADJUSTMENT_3D:
        "tiled_bounding_box_face_adjustment_3d",
    // Pages that aren't actual guitypes.
    LOADING: "loading",
    GENERIC_ERROR: "generic_error",
    NO_TASKS_AVAILABLE: "no_tasks_available",
    MALFORMED_USER_IDS: "malformed_user_ids",
    USER_IS_BLOCKED: "user_is_blocked",
    USER_IS_BLOCKED_GAMING: "user_is_blocked_gaming",
    INSTRUCTIONS: "instructions",
    INSTRUCTIONS_GAMING: "instructions_gaming",
    THANK_YOU: "thank_you",
    THANK_YOU_GAMING: "thank_you_gaming",
    TIME_OUT: "timeout"
};

const WIDGETS_WITH_GUI_OBJECT = [
    WIDGETS.DISCRETE_ANSWER,
    WIDGETS.DISCRETE_ANSWER_GAMING,
    WIDGETS.KEYPOINT_SELECTION,
    WIDGETS.BINARY_COMPARISON,
    WIDGETS.POLYGON,
    WIDGETS.BOUNDING_BOX_2D,
    WIDGETS.BOUNDING_BOX_2D_LABELED,
    WIDGETS.IMAGE_SELECTION,
    WIDGETS.POLYGON,
    WIDGETS.POINTCLOUD_COG,
    WIDGETS.POINTCLOUD_BOUNDING_BOX_3D,
    WIDGETS.AUDIO_SIMILARITY,
    WIDGETS.PIXEL_BRUSH,
    WIDGETS.MULTIMEDIA_QUESTION,
    WIDGETS.TILED_KEYPOINT_3D,
    WIDGETS.TILED_BOUNDING_BOX_3D,
    WIDGETS.TILED_ORIENTATION_ADJUSTMENT_3D,
    WIDGETS.TILED_BOUNDING_BOX_FACE_ADJUSTMENT_3D
];

/**
 * Creates a modular user interface. Returns null if gui switching is not permitted.
 * @param {import("./lib/TaskUIStrategy/taskUIContext").default} taskUIContext
 * @param {String} nextGuiType
 *
 * @returns {Gui}
 */
export const guiFactory = (taskUIContext, nextGuiType) => {
    /**
     * @typedef {Object} Gui
     * @property {String} name - the name of the gui. One of WIDGETS
     * @property {React.ReactElement} container - react element that is the gui
     */
    const gui = {
        name: "",
        container: null
    };

    if (WIDGETS_WITH_GUI_OBJECT.includes(nextGuiType)) {
        const guiObject = taskUIContext.getCurrentGuiObject();
        const layoutCreator = guiObject.getLayoutCreator();
        gui.container = layoutCreator(
            guiObject.getHeading(),
            guiObject.getDataVisualization(),
            guiObject.getProgressBar(),
            guiObject.getActionButtonGroup(),
            guiObject.getMetaActionButtonGroup()
        );
    } else {
        // Old GUIs:
        switch (nextGuiType) {
            case WIDGETS.THANK_YOU:
                gui.container = <ThankYouView taskUIContext={taskUIContext} />;
                break;
            case WIDGETS.THANK_YOU_GAMING:
                gui.container = (
                    <ThankYouViewGaming taskUIContext={taskUIContext} />
                );
                break;
            case WIDGETS.TIME_OUT:
                gui.container = <TimeoutPage taskUIContext={taskUIContext} />;
                break;
            case WIDGETS.LOADING:
                // can be replaced with proper loading view if necessary
                gui.container = <LoadingAnimation />;
                break;
            case WIDGETS.INSTRUCTIONS:
                gui.container = <Instructions taskUIContext={taskUIContext} />;
                break;
            case WIDGETS.INSTRUCTIONS_GAMING:
                gui.container = (
                    <InstructionsGaming taskUIContext={taskUIContext} />
                );
                break;
            case WIDGETS.GENERIC_ERROR:
                const vendor_id = taskUIContext.queryParams.get("vendor_id");
                if (vendor_id === "redmoon" || vendor_id === "bytro") {
                    gui.container = <UserIsBlockedGamingErrorPage />;
                } else {
                    gui.container = (
                        <h1>Sorry! Something went wrong on our side.</h1>
                    );
                }
                break;
            case WIDGETS.NO_TASKS_AVAILABLE:
                gui.container = (
                    <h1>There are currently no more tasks available.</h1>
                );

                break;
            case WIDGETS.MALFORMED_USER_IDS:
                gui.container = (
                    <h1>
                        ERROR: bad values for vendor_id and or vendor_user_id.
                    </h1>
                );
                break;
            case WIDGETS.USER_IS_BLOCKED:
                gui.container = <UserIsBlockedErrorPage />;
                break;
            case WIDGETS.USER_IS_BLOCKED_GAMING:
                gui.container = <UserIsBlockedGamingErrorPage />;
                break;
            default:
                gui.container = <h1>ERROR: gui type {nextGuiType} unknown</h1>;
        }
    }

    if (gui !== null) {
        gui.name = nextGuiType;
    }

    return gui;
};

export default guiFactory;
