import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GlobalHotKeys } from "react-hotkeys";

import RequiredActionDefinition from "../RequiredActionDefinition";
import {
    ActionButtonNextOrSubmit,
    ActionButtonOpenInstructions,
    ActionButtonCorruptData,
    ActionButtonUndo,
    ActionButtonCantSolve
} from "../../components/ActionButton/ActionButton";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const ActionButtonGroup = ({
    taskUIContext,
    currentTaskOutput,
    taskSubmitEnabled,
    currentTaskIdx,
    guiSettings,
    type
}) => {
    const disabledActionButtons = taskUIContext.shouldActionButtonsBeDisabled();
    const guiObject = taskUIContext.getCurrentGuiObject();
    const taskOutputDoesntExists = currentTaskOutput
        ? guiObject.isInitialCurrentTaskOutput(currentTaskOutput)
        : true;
    const unfulFilledRequiredActions = guiObject.checkRequiredActions();

    // currentTaskOutputClean is the taskOutput we want to set.
    // currentTaskOutput may contain "valid_intersection" which is only needed to disable/enable submission.
    let currentTaskOutputClean = currentTaskOutput;
    if (currentTaskOutput !== null) {
        const {
            valid_intersection: validIntersection,
            ...rest
        } = currentTaskOutput;
        currentTaskOutputClean = rest;
        // don't add intersection constraint when not specified explicitly
        if (
            validIntersection !== undefined &&
            validIntersection !== null &&
            validIntersection === false
        ) {
            // add as unfilfilled required action which are used anyway to block submissions
            // and to get the message to the screen
            unfulFilledRequiredActions.push(
                new RequiredActionDefinition(
                    "box_intersection",
                    "box must intersect an existing one"
                )
            );
        }
    }

    const makeUnfulFilledActionsInfo = () => {
        let combinedText = guiSettings.required_actions ? "placeholder" : "";
        if (unfulFilledRequiredActions.length > 0) {
            combinedText = RequiredActionDefinition.combineNames(
                unfulFilledRequiredActions
            );
        }
        return (
            <div
                style={{
                    color: "red",
                    fontSize: "0.9rem",
                    visibility:
                        combinedText === "placeholder" ? "hidden" : "visible"
                }}
            >
                {combinedText}
            </div>
        );
    };

    const getMetaActionButtons = () => {
        // Corrupt Data Button should never be disabled when resources weren't loaded successfully!
        const disabledCorruptData = !guiObject.taskInputResourcesLoadedWithError(
            currentTaskIdx
        );

        return (
            <>
                <ActionButtonUndo
                    taskUIContext={taskUIContext}
                    disabled={disabledActionButtons}
                />
                <ActionButtonCantSolve
                    taskUIContext={taskUIContext}
                    disabled={disabledActionButtons || !taskSubmitEnabled}
                >
                    Can't Find
                </ActionButtonCantSolve>
                <ActionButtonCorruptData
                    taskUIContext={taskUIContext}
                    disabled={
                        (disabledActionButtons && disabledCorruptData) ||
                        !taskSubmitEnabled
                    }
                />
                <ActionButtonOpenInstructions
                    taskUIContext={taskUIContext}
                    disabled={disabledActionButtons || !taskSubmitEnabled}
                />
            </>
        );
    };

    const getSubmitButton = () => {
        return (
            <ActionButtonNextOrSubmit
                taskUIContext={taskUIContext}
                title="hotkey: space"
                actionSuffix="submit"
                disabled={
                    !taskSubmitEnabled ||
                    disabledActionButtons ||
                    taskOutputDoesntExists ||
                    unfulFilledRequiredActions.length > 0
                }
                createTaskOutput={() => currentTaskOutputClean}
                className="is-primary"
            >
                Submit
            </ActionButtonNextOrSubmit>
        );
    };

    return (
        <Container fluid>
            <GlobalHotKeys
                keyMap={{ SUBMIT_TASK: "space" }}
                handlers={{
                    SUBMIT_TASK: e => {
                        const nextOrSubmitButton = document.getElementById(
                            "next_or_submit_button"
                        );
                        if (nextOrSubmitButton) {
                            nextOrSubmitButton.click();
                        }
                    }
                }}
            />
            <Row>
                <Col>{makeUnfulFilledActionsInfo()}</Col>
            </Row>
            {(() => {
                if (type === "cog") {
                    return (
                        <Row>
                            <Col>{getMetaActionButtons()}</Col>
                            <Col>{getSubmitButton()}</Col>
                        </Row>
                    );
                }
                return (
                    <Row>
                        {" "}
                        <Col>{getSubmitButton()}</Col>
                        <Col>{getMetaActionButtons()}</Col>
                    </Row>
                );
            })()}
        </Container>
    );
};
