import React from "react";
import BBoxMenuItem from "./BBoxMenuItem";

export const BBoxMenu = props => {
    const dimensions = props.dimensions;
    const boundingBoxes = props.boundingBoxes;
    const borderLineWidth = props.borderLineWidth;
    const currentBoxIndex = props.currentBoxIndex;
    const disableLabels = props.disableLabels;
    const boundingboxesColor = props.boundingboxesColor;
    const currentBoxColor = props.currentBoxColor;

    const removeBoundingBox = props.removeBoundingBox;
    const selectBBoxToAdjust = props.selectBBoxToAdjust;
    const updateLabel = props.updateLabel;
    const hoverBBox = props.hoverBBox;
    const deHoverBBox = props.deHoverBBox;

    return (
        <div
            id="bbox_menu"
            style={{
                width: "200px",
                height: dimensions.height + borderLineWidth * 2,
                border: `${borderLineWidth}px black solid`,
                borderLeft: "none",
                float: "right",
                display: "inline-block",
                overflow: "auto"
            }}
        >
            {boundingBoxes.map((value, index) => (
                <BBoxMenuItem
                    key={"bbox_menu_item_" + index}
                    index={index}
                    value={value}
                    currentBoxIndex={currentBoxIndex}
                    dimensions={dimensions}
                    boundingboxesColor={boundingboxesColor}
                    currentBoxColor={currentBoxColor}
                    disableLabels={disableLabels}
                    removeBoundingBox={removeBoundingBox}
                    selectBBoxToAdjust={selectBBoxToAdjust}
                    updateLabel={updateLabel}
                    hoverBBox={hoverBBox}
                    deHoverBBox={deHoverBBox}
                />
            ))}
        </div>
    );
};

export default BBoxMenu;
