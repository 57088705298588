import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export const REVIEW_DIALOG_TYPE = {
    KEYPOINT_UI: "KEYPOINT_UI",
    ORIENTATION_UI: "ORIENTATION_UI",
    BBOX_FACE_ADJUSTMENT_UI: "BBOX_FACE_ADJUSTMENT_UI"
};

/**
 * A dialog informing the annotator to review their annotions.
 * Evaluates based on its props whether and when the dialog should be opened.
 * Confirming the dialog triggers a callback onStartReviewPhase.
 * @param {Object} props
 * @param {String} props.type
 * @param {Object} props.taskInput
 * @param {Object} props.currentTaskOutput
 * @param {() => {}} props.onStartReviewPhase
 * @param {() => {}=} props.onOpen
 * @param {() => {}=} props.onClose
 * @param {Boolean=} props.allowOpen default true. whether this dialog is allowed to open. When this is true the dialog isn't forced to open though, it's a precondition.
 * @param {Boolean=} props.isOpen default false. whether this dialog is forced to be opened from the outside
 */
export const AnnotationReviewDialog = ({
    type,
    taskInput,
    currentTaskOutput,
    onStartReviewPhase,
    onOpen = () => {},
    onClose = () => {},
    allowOpen = true,
    isOpen = false
}) => {
    const [shouldShow, setShouldShow] = useState(isOpen);

    useEffect(() => {
        switch (type) {
            case REVIEW_DIALOG_TYPE.KEYPOINT_UI:
                // allowOpen is the precondition for opening
                if (
                    allowOpen &&
                    Object.keys(currentTaskOutput.timestamps).length ===
                        Object.keys(taskInput.timestamps).length
                ) {
                    setShouldShow(true);
                    onOpen();
                } else {
                    setShouldShow(false);
                }
                break;
            // for these two cases the parent decided when to open the dialog
            case REVIEW_DIALOG_TYPE.ORIENTATION_UI:
            case REVIEW_DIALOG_TYPE.BBOX_FACE_ADJUSTMENT_UI:
                break;
            default:
                break;
        }
    }, [currentTaskOutput]);

    useEffect(() => {
        setShouldShow(isOpen);
    }, [isOpen]);

    const onConfirm = () => {
        setShouldShow(false);
        onStartReviewPhase();
        onClose();
    };

    const getActionDescription = () => {
        switch (type) {
            case REVIEW_DIALOG_TYPE.KEYPOINT_UI:
                return "Adjust the keypoint in each frame if you think it's not quite right.";
            case REVIEW_DIALOG_TYPE.ORIENTATION_UI:
                return "Adjust the orientation lines again if they don't match the reference image.";
            case REVIEW_DIALOG_TYPE.BBOX_FACE_ADJUSTMENT_UI:
                return "Adjust the dimensions of the box so it fits as good as possible in all frames.";
            default:
                break;
        }
    };

    return (
        <Dialog open={shouldShow}>
            <DialogTitle>Please review your annotations</DialogTitle>
            <DialogContent>
                <div>
                    <ul>
                        <li>You start again at the first frame.</li>
                        <li>{getActionDescription()}</li>
                        <li>
                            Click <b>Finish</b> after you've reviewed each
                            frame.
                        </li>
                    </ul>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm}>Understood</Button>
            </DialogActions>
        </Dialog>
    );
};
