import React from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

import "./SubmitIndication.css";

export const Popup = props => {
    return (
        <div className="popup">
            <div className="popup_inner">
                <h1 className="popup_text">{props.children}</h1>
                <LoadingAnimation />
            </div>
        </div>
    );
};
