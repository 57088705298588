/**
 * Json serializable params for feedback sent via email (using emailjs)
 */
export class FeedbackEmailParams {
    constructor() {
        /**
         * @type {String}
         */
        this.date = null;
        /**
         * @type {String}
         */
        this.msg = null;
        /**
         * @type {Number}
         */
        this.rating = null;
        /**
         * @type {String}
         */
        this.vendor_id = null;
        /**
         * @type {String}
         */
        this.project_id = null;
        /**
         * @type {String}
         */
        this.project_node_id = null;
        /**
         * @type {String[]}
         */
        this.task_input_ids = null;
        /**
         * @type {Number}
         */
        this.current_task_idx = null;
    }
}
export default FeedbackEmailParams;
