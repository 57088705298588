import React from "react";
import { connect } from "react-redux";
import { getTaskSubmitRequest } from "../../store/reducers/taskSubmitRequest";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { Popup } from "./Popup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./SubmitIndication.css";

export class SubmitIndication extends React.Component {
    render() {
        if (this.props.toast) {
            const Msg = (
                <div
                    id="toast_content"
                    style={{
                        width: "auto",
                        height: "auto",
                        position: "relative",
                        display: "flex"
                    }}
                >
                    <p
                        id="toast_message"
                        style={{
                            width: "80%",
                            marginTop: "5px",
                            marginBottom: "-5px"
                        }}
                    >
                        Submitting
                    </p>
                    <LoadingAnimation />
                </div>
            );

            toast(Msg, { style: { color: "black" } });

            return (
                <div>
                    {this.props.serverCommunication ? (
                        <ToastContainer
                            position="bottom-right"
                            autoClose={false}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick={false}
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable={false}
                            pauseOnHover
                            limit={1}
                            closeButton={false}
                        />
                    ) : null}
                </div>
            );
        } else {
            return (
                <div id="submitIndication">
                    {this.props.serverCommunication ? (
                        <Popup>Submitting</Popup>
                    ) : null}
                </div>
            );
        }
    }
}

const mapStateToProps = state => ({
    serverCommunication: getTaskSubmitRequest(state).pending
});

SubmitIndication = connect(mapStateToProps)(SubmitIndication);
export default SubmitIndication;
