import BaseGuiType from "../BaseGuiType";
import MockApiClient from "../MockData/MockApiClient";
import MockData from "../MockData/MockData";
import ResourceLoaderService from "../ResourceLoaderService";
import { makeImageLoader } from "../../lib/DataLoader/loaders";
import { WIDGETS } from "../../guiFactory";
import withWaitForResourcesWrapper from "../util/withWaitForResourcesWrapper";
import BoundingBoxInCameraViewsWidget from "./BoundingBoxInCameraVewsWidget";
import BBox3DModel from "../../lib/DataModels/BBox3DModel";
import { TaskInputsWithBox } from "./tiledUIMockInputs";

export class TiledBoundingBox3D extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return WIDGETS.TILED_BOUNDING_BOX_3D;
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const mockApiClient = new MockApiClient(
            {
                instructions: [],
                question: { text: "Adjust the bounding box using the sliders" },
                box_line_color: "#F00",
                box_line_width: 2.0,
                action_axis_indicator_line_color: "#F0F",
                action_axis_indicator_line_width: 2.0,
                slider_ranges: {
                    dimension: [0, 1],
                    position: [-2, 2],
                    rotation: [-Math.PI, Math.PI]
                },
                slider_steps: {
                    dimension: 0.01,
                    position: 0.01,
                    rotation: 0.01
                },
                zoom_level: 1.5,
            },
            TiledBoundingBox3D.getName(),
            TaskInputsWithBox
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeCustomLoaderService(
                new Map([
                    [
                        "tl_image_url",
                        taskInput => makeImageLoader(taskInput.tl.image_url)
                    ],
                    [
                        "bl_image_url",
                        taskInput => makeImageLoader(taskInput.bl.image_url)
                    ],
                    [
                        "br_image_url",
                        taskInput => makeImageLoader(taskInput.br.image_url)
                    ],
                    [
                        "tr_image_url",
                        taskInput => makeImageLoader(taskInput.tr.image_url)
                    ]
                ])
            )
        );
    }

    /**
     * @override
     */
    getHeading() {
        return () => null;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return withWaitForResourcesWrapper(BoundingBoxInCameraViewsWidget);
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return () => null;
    }

    /**
     * @override
     */
    getInitialCurrentTaskOutput() {
        const taskInput = this.taskUIContext.getCurrentTaskInput();
        return {
            ...super.makeTaskOutputForCurrentTask(),
            box: BBox3DModel.parseJSONObject(taskInput.box)
        };
    }

    /**
     * @override
     */
    makeTaskOutputForCurrentTask(overrides) {
        return {
            ...this.getInitialCurrentTaskOutput(),
            ...overrides
        };
    }
}

export default TiledBoundingBox3D;
