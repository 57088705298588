import React from "react";
import { ActionButtonNextOrSubmit } from "../../components/ActionButton/ActionButton";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */

export const ActionButtonGroup = ({ taskUIContext, guiSettings }) => {
    const disabledActionButtons = taskUIContext.shouldActionButtonsBeDisabled();
    return (
        <div>
            {guiSettings.buttons.map((button, idx) => {
                const buttonStyle = {
                    color: button.text_color,
                    backgroundColor: button.bg_color,
                };
                return (
                    <ActionButtonNextOrSubmit
                        key={idx}
                        id={button.answer_key}
                        taskUIContext={taskUIContext}
                        actionSuffix="set_discrete_answer"
                        disabled={disabledActionButtons}
                        createTaskOutput={() =>
                            taskUIContext
                                .getCurrentGuiObject()
                                .makeTaskOutputForCurrentTask({
                                    answer: button.answer_key
                                })
                        }
                        style={buttonStyle}
                        //if buttonStyle is undefined use default color scheme
                        className="is-primary"
                        actionKey={button.key}
                    >
                        {button.text}
                    </ActionButtonNextOrSubmit>
                );
            })}
        </div>
    );
};
