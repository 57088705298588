/**
 * A RequiredActionDefinition defines either a minimum count on how often an ActionTelemetry must have been called
 * or a custom check function that is called with the ActionTelemetry to identify whether the condition is met.
 */
export class RequiredActionDefinition {
    /**
     * @callback ActionTelemetryCheckFunction
     * @param {ActionTelemetry} name
     * @returns {Boolean}
     */

    /**
     * @param {String} key
     * @param {String} displayName
     * @param {Number=} minCnt default 1. Used by default.
     * @param {ActionTelemetryCheckFunction=} checkFunc default null. When no checkFunc is given min count is used.
     */
    constructor(key, displayName, minCnt = 1, checkFunc = null) {
        this.key = key;
        this.displayName = displayName;
        this.minCnt = minCnt;
        this.checkFunc = checkFunc;
        this.conditionMet = false;
        this.customErrorMsg = null;
    }

    /**
     * Combines names to a string. Doesn't check the condition.
     * @param {RequiredActionDefinition[]} requiredActions
     * @returns {String}
     */
    static combineNames(requiredActions) {
        if (requiredActions.length === 0) {
            return "";
        }

        return (
            "Required Actions:" +
            requiredActions.reduce(
                (combined, requiredAction, idx) =>
                    combined +
                    (idx === 0 ? " " : ", ") +
                    requiredAction.displayName,
                ""
            )
        );
    }

    /**
     * Combines error messages into an html string. Doesn't check the condition.
     * @param {RequiredActionDefinition[]} requiredActions
     * @param {String=} wrapperHtmlElement default p. With which html element each msg should be wrapped.
     * @returns {String}
     */
    static combineErrorMsgs(requiredActions, wrapperHtmlElement) {
        let msgs = "";
        for (const action of requiredActions) {
            msgs += `<${wrapperHtmlElement}>${action.getErrorMsg()}</${wrapperHtmlElement}>`;
        }
        return msgs;
    }

    /**
     * @param {import("./ActionTelemetry").ActionTelemetry} action
     */
    check(action) {
        if (action === null || action === undefined) {
            return false;
        }
        // check min count if no check function is given
        if (this.checkFunc === null) {
            return action.cnt >= this.minCnt;
        }
        this.conditionMet = this.checkFunc(action);
        return this.conditionMet;
    }

    getErrorMsg() {
        if (this.customErrorMsg) {
            return this.customErrorMsg;
        }
        return `Action required: ${this.displayName}`;
    }

    /**
     * @param {String} customErrorMsg
     * @returns {RequiredActionDefinition} returns this instance for fluency.
     */
    setCustomErrorMsg(customErrorMsg) {
        this.customErrorMsg = customErrorMsg;
        return this;
    }
}

export default RequiredActionDefinition;
