import React, { useEffect } from "react";

/**
 * So that this component can keep track of the mouse without
 * relying on react render cycles.
 */
let internalCursorPositions = new Map();

/**
 * This component keeps track of custom mouse cursor position and displays
 * a given icon in its place.
 * @param {Object} props
 * @param {String} props.id
 * @param {React.ReactNode} props.icon use an icon for this. Preferably from the react-icos lib.
 * @param {String} props.color
 * @param {Number} props.rotationRad
 * @param {Boolean} props.show whether the custom mouse cursor should be shown (hides default cursor)
 * @param {Boolean} props.useRotation whether rotation should be applied
 * @param {Boolean} props.translateToCenter whether the icon should be displayed with mouse position in its center
 */
export const CustomMouseCursor = ({
    id = "custom_cursor",
    show = false,
    icon = null,
    color = "red",
    rotationRad = 0,
    useRotation = true,
    translateToCenter = true
}) => {
    useEffect(() => {
        // hide the default cursor when the custom cursor should be shown
        document.body.style.cursor = show ? "none" : "default";

        const mouseMove = e => {
            if (!show) {
                return;
            }
            if (!internalCursorPositions.has(id)) {
                internalCursorPositions.set(id, { x: 0, y: 0 });
            }
            const mousePos = internalCursorPositions.get(id);
            mousePos.x = e.pageX;
            mousePos.y = e.pageY;

            const customCursor = document.getElementById(id);
            customCursor.style.top = `${mousePos.y}px`;
            customCursor.style.left = `${mousePos.x}px`;
        };
        document.addEventListener("mousemove", mouseMove);
        return () => document.removeEventListener("mousemove", mouseMove);
        // eslint-disable-next-line
    }, [show]);

    return (
        <div
            id={id}
            style={{
                display: show ? "block" : "none",
                position: "fixed",
                pointerEvents: "none",
                transformOrigin: `${
                    translateToCenter ? "center center" : "50% 50% 0"
                }`,
                transform: `${
                    translateToCenter ? "translate(-50%, -50%) " : ""
                }rotate(${useRotation ? rotationRad : 0}rad)`,
                color: color
            }}
        >
            {icon}
        </div>
    );
};

export default CustomMouseCursor;
