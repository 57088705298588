import React from "react";

import MockData from "../MockData/MockData";
import MockApiClient from "../MockData/MockApiClient";
import { demoInsectImageObjects } from "../../guitypes/sharedMockTaskInputs";
import BaseGuiType from "../BaseGuiType";
import { WIDGETS } from "../../guiFactory";
import { ResourceLoaderService } from "../ResourceLoaderService";
import withWaitForResourcesWrapper from "../util/withWaitForResourcesWrapper";
import { ConnectedDotProgressBar } from "../../components/DotProgressBar/DotProgressBar";
import { makeDummyImageList } from "../sharedMockTaskInputs";
import { ActionButtonGroup } from "./ActionButtonGroup";
import ImageVisualization from "./ImageVisualization";
import { Question } from "./Question";

import "./DiscreteAnswerGaming.css";

export class DiscreteAnswerGaming extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return WIDGETS.DISCRETE_ANSWER_GAMING;
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const tinyImages = makeDummyImageList(
            128,
            80,
            1,
            "c00",
            "fff",
            "png",
            "128x80"
        );
        const mediumImages = makeDummyImageList(
            640,
            480,
            1,
            "0c0",
            "fff",
            "png",
            "640x480"
        );
        const largeImages = makeDummyImageList(
            1920,
            1080,
            1,
            "00c",
            "fff",
            "png",
            "1920x1080"
        );
        const tallImages = makeDummyImageList(
            300,
            1200,
            1,
            "ccc",
            "fff",
            "png",
            "300x1200"
        );
        const wideImages = makeDummyImageList(
            2400,
            400,
            1,
            "ccc",
            "fff",
            "png",
            "2400x400"
        );
        // use 15 taskInputs for testing
        const taskInputs = [
            {
                image_url: demoInsectImageObjects[0].image_url
            },
            {
                image_url: demoInsectImageObjects[1].image_url
            },
            {
                image_url: wideImages[0]
            },
            {
                image_url: demoInsectImageObjects[2].image_url
            },
            {
                image_url: mediumImages[0]
            },
            {
                image_url: demoInsectImageObjects[3].image_url
            },
            {
                image_url: mediumImages[0]
            },
            {
                image_url: tallImages[0]
            },
            {
                image_url: tinyImages[0]
            },
            {
                image_url: tallImages[0]
            },
            {
                image_url: tinyImages[0]
            },
            {
                image_url: tallImages[0]
            },
            {
                image_url: mediumImages[0]
            },
            {
                image_url: tallImages[0]
            },
            {
                image_url: tinyImages[0]
            },
            {
                image_url: largeImages[0]
            }
        ];
        const mockApiClient = new MockApiClient(
            {
                // instructions: [
                //     "<div style='padding: 30px; box-sizing: border-box;'><div style='padding-bottom: 30px; text-align: center;'>Instructions for insect identification</div><div style='margin: 0 auto; max-width: 400px; max-height: 300px;'><img src='https://p0.pikist.com/photos/860/33/ant-queen-ant-queens-female-insect.jpg'/></div></div>",
                //     "<div style='padding: 30px; box-sizing: border-box;'><div style='padding-bottom: 30px; text-align: justify; font-size:1.2rem; max-width: 600px;'>The task is to find insects in the image. Please click the green button if you see insects in the image we present to you and click the red button if not. If you have any doubts or think there is a problem with the data, click on the questionmark between.</div><div style='margin: 0 auto; max-width: 400px; max-height: 300px;'><img src='https://live.staticflickr.com/7069/6879619433_cb442740d7_b.jpg'/></div></div>"
                // ],
                instructions: [
                    "https://qm-instructions.s3.eu-central-1.amazonaws.com/cratr/introduction_gaming_bicycle.mov"
                ],
                question: { text: "Do you see any insects in the image?" }
                // some gui_settings are ignored: buttons, image_scale, use_magnifying_tool, magnifying_tool_zoom_scale
            },
            WIDGETS.DISCRETE_ANSWER_GAMING,
            taskInputs
        );
        const mockData = new MockData(mockApiClient);
        mockData.mockQueryParams.set("vendor_id", "redmoon");
        //mockData.mockQueryParams.set("vendor_id", "bytro");
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeImageLoaderService(["image_url"])
        );
    }

    /**
     * @override
     * @returns {LayoutCreator}
     */
    getLayoutCreator() {
        return (
            heading,
            dataVis,
            progressBar,
            actionButtonGroup,
            metaActionButtonGroup
        ) => {
            const components = [
                heading,
                dataVis,
                progressBar,
                actionButtonGroup
            ];
            const instantiatedComponents = this.connectAndInstantiateComponents(
                components
            );
            return (
                <div
                    style={{
                        fontFamily: "Oswald",
                        backgroundColor: "#eee",
                        width: "100%",
                        textAlign: "center"
                    }}
                >
                    <div>{instantiatedComponents}</div>
                </div>
            );
        };
    }

    /**
     * @override
     */
    getHeading() {
        return Question;
    }

    /**
     * @override
     */
    getDataVisualization() {
        // withWaitForResourcesWrapper is a helper component that deals with waiting for resources from the ResourceLoaderService
        return withWaitForResourcesWrapper(ImageVisualization);
    }

    /**
     * @override
     */
    getProgressBar() {
        return ConnectedDotProgressBar;
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return ActionButtonGroup;
    }

    /**
     * @override
     */
    getMetaActionButtonGroup() {
        return null;
    }

    /**
     * @override
     */
    getInstructionsViewName() {
        return WIDGETS.INSTRUCTIONS_GAMING;
    }

    /**
     * @override
     */
    getThankYouViewName() {
        return WIDGETS.THANK_YOU_GAMING;
    }

    getInitialCurrentTaskOutput() {
        return {
            ...super.makeTaskOutputForCurrentTask(),
            answer: ""
        };
    }

    /**
     * @inheritdoc
     */
    makeTaskOutputForCurrentTask(overrides) {
        return {
            ...this.getInitialCurrentTaskOutput(),
            ...overrides
        };
    }
}

export default DiscreteAnswerGaming;
