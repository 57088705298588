import React, { useEffect, useRef } from "react";

import {
    tryCatchFinally,
    drawAndLoadImageOnCanvas,
    drawPreloadedImageOnCanvas
} from "../../lib/qm_cs_lib.js";

/**
 * Draws an image onto a canvas element.
 * You can either provide an image url (img_url), which will then be loaded
 * or a preloaded Image instance (preloaded_img).
 * preloaded_img will take precedence over img_url.
 * If neither is provided, the callback_canvas_draw_init is invoked
 * which allows the receiver to handle canvas drawing without
 * having CanvasImage draw an image unnecessarily.
 *
 * @param {Object} props
 * @param {Boolean} props.triggerRedraw toggle this to trigger a redraw
 * @param {Number} props.name
 * @param {String} props.img_url
 * @param {HTMLImageElement} props.preloaded_img
 * @param {Boolean} props.resize_img_to_canvas_dimensions
 * @param {String} props.className
 * @param {React.CSSProperties} props.style
 * @param {Number} props.canvas_width
 * @param {Number} props.canvas_height
 * @param {React.MouseEventHandler} props.onClick
 * @param {React.MouseEventHandler} props.onMouseMove
 * @param {React.MouseEventHandler} props.onMouseDown
 * @param {React.MouseEventHandler} props.onMouseUp
 * @param {React.MouseEventHandler} props.onMouseOut
 * @param {React.WheelEventHandler} props.onWheel
 * @param {(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, img: HTMLImageElement) => {}} props.callback_after_draw_finish
 * @param {(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, name: String) => {}} props.callback_canvas_draw
 */
const CanvasImage = props => {
    const canvasRef = useRef();
    const {
        triggerRedraw,
        name,
        img_url,
        preloaded_img,
        resize_img_to_canvas_dimensions,
        callback_canvas_draw,
        callback_after_draw_finish,
        canvas_width: width,
        canvas_height: height,
        onClick,
        onMouseMove,
        onMouseDown,
        onMouseUp,
        onMouseOut,
        onWheel,
        style
    } = props;

    // this is called after the first (react-) render of CanvasImage
    // --> will draw the image (preloaded object or from src) and then call a callback to
    //     allow further modification of the canvas after the image was drawn
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const callbackAfterImageDrawFinish = img => {
            tryCatchFinally(() => callback_after_draw_finish(canvas, ctx, img));
        };

        if (img_url !== "" && preloaded_img === null) {
            drawAndLoadImageOnCanvas(
                canvas,
                ctx,
                img_url,
                resize_img_to_canvas_dimensions,
                callbackAfterImageDrawFinish
            );
        } else if (preloaded_img !== null) {
            drawPreloadedImageOnCanvas(
                canvas,
                ctx,
                preloaded_img,
                resize_img_to_canvas_dimensions,
                callbackAfterImageDrawFinish
            );
        } else {
            tryCatchFinally(() => {
                callback_canvas_draw(canvas, ctx, name);
            });
        }
        // eslint-disable-next-line
    }, [triggerRedraw]);

    return (
        <canvas
            ref={canvasRef}
            width={width}
            height={height}
            onClick={onClick}
            onMouseMove={onMouseMove}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseOut={onMouseOut}
            onWheel={onWheel}
            className={
                props.className !== "" ? props.className : "drawing_canvas"
            }
            style={style}
        ></canvas>
    );
};

CanvasImage.defaultProps = {
    triggerRedraw: false,
    name: "",
    className: "",
    canvas_width: 200,
    canvas_height: 200,
    resize_img_to_canvas_dimensions: false,
    img_url: "",
    preloaded_img: null,
    onClick: null,
    onMouseMove: null,
    onMouseDown: null,
    onMouseUp: null,
    onMouseOut: null,
    onWheel: null,
    style: {},
    // callback for additional actions after drawing the Image
    // also returns the Image object used to drawn on canvas (contains original image width and height)
    callback_after_draw_finish: (canvas, ctx, img) => {},
    callback_canvas_draw: (canvas, ctx, name) => {}
};

export default CanvasImage;
