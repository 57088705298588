import { LinesRenderable } from "./Renderable";
import { CurvesRenderable } from "./Renderable";
// eslint-disable-next-line
import { Pose3D } from "./Pose3D";

/**
 * @param {Pose3D} pose
 * @param {Number} lineLength
 * @param {import("./Renderable").LinesRenderableRenderSettings} renderSettings
 * @returns {LinesRenderable}
 */
export const makeWireframeCube = (pose, lineLength, renderSettings) => {
    const lineLengthToCenter = lineLength / 2;
    const box = new BoxDimensions(
        lineLengthToCenter,
        lineLengthToCenter,
        lineLengthToCenter,
        lineLengthToCenter,
        lineLengthToCenter,
        lineLengthToCenter
    );
    return box.makeLinesRenderable(pose, renderSettings);
};

export const makeCurvedWireframeCube = (pose, lineLength, renderSettings) => {
    const lineLengthToCenter = lineLength / 2;
    const box = new BoxDimensions(
        lineLengthToCenter,
        lineLengthToCenter,
        lineLengthToCenter,
        lineLengthToCenter,
        lineLengthToCenter,
        lineLengthToCenter
    );
    return box.makeCurvesRenderable(pose, renderSettings);
};

export class BoxDimensions {
    static BOX_DIMENSION_NAMES = ["front", "back", "left", "right"];

    /**
     * All dimensions as positive lengths from the box center
     * - front is aligned with to the positive y-direction of the pose
     * - right is aligned to positive x-direction of the pose
     * - top is aligned to positive z-direction of the pose
     *
     * @param {Number} front
     * @param {Number} back
     * @param {Number} left
     * @param {Number} right
     * @param {Number} top
     * @param {Number} bottom
     */
    constructor(front = 1, back = 1, left = 1, right = 1, top = 1, bottom = 1) {
        this.front = front;
        this.back = back;
        this.left = left;
        this.right = right;
        this.top = top;
        this.bottom = bottom;
    }

    /**
     * @param {BoxDimensions} other
     */
    static copy(other) {
        return new BoxDimensions(
            other.front,
            other.back,
            other.left,
            other.right,
            other.top,
            other.bottom
        );
    }

    /**
     * @param {Pose3D} pose
     * @param {import("./Renderable").LinesRenderableRenderSettings} renderSettings
     * @returns {LinesRenderable}
     */
    makeLinesRenderable(pose, renderSettings) {
        return new LinesRenderable(pose, this.makeBoxLines(), renderSettings);
    }

    /**
     * @returns {Number[][][]}
     */
    makeBoxLines() {
        return BoxDimensions.makeBoxLines(this);
    }

    /**
     * @param {BoxDimensions} box
     */
    static makeBoxLines(box) {
        return [
            // front bot: left to right
            [
                [-box.left, box.front, -box.bottom],
                [box.right, box.front, -box.bottom]
            ],
            // back bot: left to right
            [
                [-box.left, -box.back, -box.bottom],
                [box.right, -box.back, -box.bottom]
            ],
            // left bot: front to back
            [
                [-box.left, box.front, -box.bottom],
                [-box.left, -box.back, -box.bottom]
            ],
            // right bot: front to back
            [
                [box.right, box.front, -box.bottom],
                [box.right, -box.back, -box.bottom]
            ],
            // front top: left to right
            [
                [-box.left, box.front, box.top],
                [box.right, box.front, box.top]
            ],
            // back top: left to right
            [
                [-box.left, -box.back, box.top],
                [box.right, -box.back, box.top]
            ],
            // left top: front to back
            [
                [-box.left, box.front, box.top],
                [-box.left, -box.back, box.top]
            ],
            // right top: front to back
            [
                [box.right, box.front, box.top],
                [box.right, -box.back, box.top]
            ],
            // connection front left: top to bottom
            [
                [-box.left, box.front, box.top],
                [-box.left, box.front, -box.bottom]
            ],
            // connection front right: top to bottom
            [
                [box.right, box.front, box.top],
                [box.right, box.front, -box.bottom]
            ],
            // connection back left: top to bottom
            [
                [-box.left, -box.back, box.top],
                [-box.left, -box.back, -box.bottom]
            ],
            // connection back right: top to bottom
            [
                [box.right, -box.back, box.top],
                [box.right, -box.back, -box.bottom]
            ]
        ];
    }

    /**
     * @param {Pose3D} pose
     * @param {import("./Renderable").LinesRenderableRenderSettings} renderSettings
     * @returns {CurvesRenderable}
     */
    makeCurvesRenderable(pose, renderSettings) {
        return new CurvesRenderable(pose, this.makeBoxCurves(), renderSettings);
    }

    /**
     * @returns {Number[][][]}
     */
    makeBoxCurves() {
        return BoxDimensions.makeBoxCurves(this);
    }

    /**
     * interpolate two symmetrical points for each box edge to use as control points for cubic bezier curve
     * corner points will be used as endpoints
     * @param {BoxDimensions} box
     */
    static makeBoxCurves(box) {
        return [
            // front bot: left to right
            [
                [-box.left, box.front, -box.bottom],
                [-0.75 * box.left, box.front, -box.bottom],
                [-0.5 * box.left, box.front, -box.bottom],
                [-0.25 * box.left, box.front, -box.bottom],
                [0, box.front, -box.bottom],
                [0.25 * box.right, box.front, -box.bottom],
                [0.5 * box.right, box.front, -box.bottom],
                [0.75 * box.right, box.front, -box.bottom],
                [box.right, box.front, -box.bottom]
            ],
            // back bot: left to right
            [
                [-box.left, -box.back, -box.bottom],
                [-0.75 * box.left, -box.back, -box.bottom],
                [-0.5 * box.left, -box.back, -box.bottom],
                [-0.25 * box.left, -box.back, -box.bottom],
                [0, -box.back, -box.bottom],
                [0.25 * box.right, -box.back, -box.bottom],
                [0.5 * box.right, -box.back, -box.bottom],
                [0.75 * box.right, -box.back, -box.bottom],
                [box.right, -box.back, -box.bottom]
            ],
            // left bot: front to back
            [
                [-box.left, box.front, -box.bottom],
                [-box.left, 0.75 * box.front, -box.bottom],
                [-box.left, 0.5 * box.front, -box.bottom],
                [-box.left, 0.25 * box.front, -box.bottom],
                [-box.left, 0, -box.bottom],
                [-box.left, -0.25 * box.back, -box.bottom],
                [-box.left, -0.5 * box.back, -box.bottom],
                [-box.left, -0.75 * box.back, -box.bottom],
                [-box.left, -box.back, -box.bottom]
            ],
            // right bot: front to back
            [
                [box.right, box.front, -box.bottom],
                [box.right, 0.75 * box.front, -box.bottom],
                [box.right, 0.5 * box.front, -box.bottom],
                [box.right, 0.25 * box.front, -box.bottom],
                [box.right, 0, -box.bottom],
                [box.right, -0.25 * box.back, -box.bottom],
                [box.right, -0.5 * box.back, -box.bottom],
                [box.right, -0.75 * box.back, -box.bottom],
                [box.right, -box.back, -box.bottom]
            ],
            // front top: left to right
            [
                [-box.left, box.front, box.top],
                [-0.75 * box.left, box.front, box.top],
                [-0.5 * box.left, box.front, box.top],
                [-0.25 * box.left, box.front, box.top],
                [0, box.front, box.top],
                [0.25 * box.right, box.front, box.top],
                [0.5 * box.right, box.front, box.top],
                [0.75 * box.right, box.front, box.top],
                [box.right, box.front, box.top]
            ],
            // back top: left to right
            [
                [-box.left, -box.back, box.top],
                [-0.75 * box.left, -box.back, box.top],
                [-0.5 * box.left, -box.back, box.top],
                [-0.25 * box.left, -box.back, box.top],
                [0, -box.back, box.top],
                [0.25 * box.right, -box.back, box.top],
                [0.5 * box.right, -box.back, box.top],
                [0.75 * box.right, -box.back, box.top],
                [box.right, -box.back, box.top]
            ],
            // left top: front to back
            [
                [-box.left, box.front, box.top],
                [-box.left, 0.75 * box.front, box.top],
                [-box.left, 0.5 * box.front, box.top],
                [-box.left, 0.25 * box.front, box.top],
                [-box.left, 0, box.top],
                [-box.left, -0.25 * box.back, box.top],
                [-box.left, -0.5 * box.back, box.top],
                [-box.left, -0.75 * box.back, box.top],
                [-box.left, -box.back, box.top]
            ],
            // right top: front to back
            [
                [box.right, box.front, box.top],
                [box.right, 0.75 * box.front, box.top],
                [box.right, 0.5 * box.front, box.top],
                [box.right, 0.25 * box.front, box.top],
                [box.right, 0, box.top],
                [box.right, -0.25 * box.back, box.top],
                [box.right, -0.5 * box.back, box.top],
                [box.right, -0.75 * box.back, box.top],
                [box.right, -box.back, box.top]
            ],
            // connection front left: top to bottom
            [
                [-box.left, box.front, box.top],
                [-box.left, box.front, 0.75 * box.top],
                [-box.left, box.front, 0.5 * box.top],
                [-box.left, box.front, 0.25 * box.top],
                [-box.left, box.front, 0],
                [-box.left, box.front, -0.25 * box.bottom],
                [-box.left, box.front, -0.5 * box.bottom],
                [-box.left, box.front, -0.75 * box.bottom],
                [-box.left, box.front, -box.bottom]
            ],
            // connection front right: top to bottom
            [
                [box.right, box.front, box.top],
                [box.right, box.front, 0.75 * box.top],
                [box.right, box.front, 0.5 * box.top],
                [box.right, box.front, 0.25 * box.top],
                [box.right, box.front, 0],
                [box.right, box.front, -0.25 * box.bottom],
                [box.right, box.front, -0.5 * box.bottom],
                [box.right, box.front, -0.75 * box.bottom],
                [box.right, box.front, -box.bottom]
            ],
            // connection back left: top to bottom
            [
                [-box.left, -box.back, box.top],
                [-box.left, -box.back, 0.75 * box.top],
                [-box.left, -box.back, 0.5 * box.top],
                [-box.left, -box.back, 0.25 * box.top],
                [-box.left, -box.back, 0],
                [-box.left, -box.back, -0.25 * box.bottom],
                [-box.left, -box.back, -0.5 * box.bottom],
                [-box.left, -box.back, -0.75 * box.bottom],
                [-box.left, -box.back, -box.bottom]
            ],
            // connection back right: top to bottom
            [
                [box.right, -box.back, box.top],
                [box.right, -box.back, 0.75 * box.top],
                [box.right, -box.back, 0.5 * box.top],
                [box.right, -box.back, 0.25 * box.top],
                [box.right, -box.back, 0],
                [box.right, -box.back, -0.25 * box.bottom],
                [box.right, -box.back, -0.5 * box.bottom],
                [box.right, -box.back, -0.75 * box.bottom],
                [box.right, -box.back, -box.bottom]
            ]
        ];
    }
}

export class CrossDimensions {
    /**
     * A 2D Cross in the x-y plane.
     * Line a and b have a right angle.
     * Line Length is for the whole line.
     * Lines intersect in their center.
     * Line intersection is the origin of the pose.
     *
     * @param {Number} aLineLen
     * @param {Number} bLineLen
     */
    constructor(aLineLen = 1, bLineLen = 1) {
        this.aLineLen = aLineLen;
        this.bLineLen = bLineLen;
    }

    /**
     * @param {Pose3D} pose
     * @param {import("./Renderable").LinesRenderableRenderSettings} renderSettings
     * @returns {LinesRenderable}
     */
    makeLinesRenderable(pose, renderSettings) {
        return new LinesRenderable(pose, this.makeCrossLines(), renderSettings);
    }

    /**
     * @returns {Number[][][]}
     */
    makeCrossLines() {
        return CrossDimensions.makeCrossLines(this);
    }

    /**
     * @param {CrossDimensions} cross
     */
    static makeCrossLines(cross) {
        const a = (Math.sqrt(2) / 2) * cross.aLineLen;
        const b = (Math.sqrt(2) / 2) * cross.bLineLen;
        return [
            [
                [-a / 2, a / 2, 0],
                [a / 2, -a / 2, 0]
            ],
            [
                [b / 2, b / 2, 0],
                [-b / 2, -b / 2, 0]
            ]
        ];
    }
}

export class ArrowDimensions {
    /**
     * A 2D Arrow in the x-y plane.
     * Made out of 3 lines.
     * The tip points in positive y-direction.
     *
     * @param {Number} centerLineLen
     * @param {Number} arrowSpanLen
     */
    constructor(centerLineLen = 1, arrowSpanLen = 1) {
        this.centerLineLen = centerLineLen;
        this.arrowSpanLen = arrowSpanLen;
    }

    /**
     * @param {Pose3D} pose
     * @param {import("./Renderable").LinesRenderableRenderSettings} renderSettings
     * @returns {LinesRenderable}
     */
    makeLinesRenderable(pose, renderSettings) {
        return new LinesRenderable(pose, this.makeArrowLines(), renderSettings);
    }

    /**
     * @returns {Number[][][]}
     */
    makeArrowLines() {
        return ArrowDimensions.makeArrowLines(this);
    }

    /**
     * @param {ArrowDimensions} arrow
     */
    static makeArrowLines(arrow) {
        const a = arrow.centerLineLen / 2;
        const b = arrow.arrowSpanLen / 2;
        return [
            [
                [0, a, 0],
                [0, -a, 0]
            ],
            [
                [0, a, 0],
                [-b, 0, 0]
            ],
            [
                [0, a, 0],
                [b, 0, 0]
            ]
        ];
    }
}

/**
 * make coordinate system with sensible defaults
 * @param {Pose3D} pose
 * @param {Number[][]=} axisLengths default: -1 to 1 for each axis. min-max for each axis: x, y, z
 * @param {import("./Renderable").LinesRenderableRenderSettings=} renderSettings default is 1px x: red, y: yellow, z: blue
 */
export const makeCoordinateSystem = (
    pose,
    axisLengths = [
        [-1, 1],
        [-1, 1],
        [-1, 1]
    ],
    renderSettings = [
        { lineWidth: 1, strokeStyle: "#F00" },
        { lineWidth: 1, strokeStyle: "#F00" },
        { lineWidth: 1, strokeStyle: "#F00" },
        { lineWidth: 1, strokeStyle: "#FF0" },
        { lineWidth: 1, strokeStyle: "#FF0" },
        { lineWidth: 1, strokeStyle: "#FF0" },
        { lineWidth: 1, strokeStyle: "#00F" },
        { lineWidth: 1, strokeStyle: "#00F" },
        { lineWidth: 1, strokeStyle: "#00F" }
    ]
) => {
    return new LinesRenderable(
        pose,
        [
            // x
            [
                [axisLengths[0][0], 0, 0],
                [axisLengths[0][1], 0, 0]
            ],
            // mark positive end parallel to z and y axes
            [
                [axisLengths[0][1], 0, -1],
                [axisLengths[0][1], 0, 1]
            ],
            [
                [axisLengths[0][1], -1, 0],
                [axisLengths[0][1], 1, 0]
            ],
            // y
            [
                [0, axisLengths[1][0], 0],
                [0, axisLengths[1][1], 0]
            ],
            // mark positive end parallel to z and x axes
            [
                [0, axisLengths[1][1], -1],
                [0, axisLengths[1][1], 1]
            ],
            [
                [-1, axisLengths[1][1], 0],
                [1, axisLengths[1][1], 0]
            ],
            // z
            [
                [0, 0, axisLengths[2][0]],
                [0, 0, axisLengths[2][1]]
            ],
            // mark positive end parallel to x and y axes
            [
                [-1, 0, axisLengths[2][1]],
                [1, 0, axisLengths[2][1]]
            ],
            [
                [0, -1, axisLengths[2][1]],
                [0, 1, axisLengths[2][1]]
            ]
        ],
        renderSettings
    );
};

/**
 * Line from a to b that consists of multiple linearly interpolated sections.
 *
 * @param {Pose3D} pose
 * @param {Number[]} from
 * @param {Number[]} to
 * @param {Number} divisions
 * @param {import("./Renderable").LinesRenderableRenderSettings} renderSettings
 * @param {Number=} additionalSteps default 0. How many steps to add to the end of the line after reaching the target pos.
 */
export const makeInterpolatedLine = (
    pose,
    from,
    to,
    divisions,
    renderSettings,
    additionalSteps = 0
) => {
    const lines = [];
    const diff = from.map((val, idx) => to[idx] - val);
    const stepLengths = diff.map(val => val / divisions);
    for (let i = 0; i < divisions - 1; i++) {
        const startSteps = stepLengths.map(val => val * i);
        const endSteps = stepLengths.map(val => val * (i + 1));
        lines.push([
            from.map((val, idx) => val + startSteps[idx]),
            from.map((val, idx) => val + endSteps[idx])
        ]);
    }

    if (additionalSteps > 0) {
        for (let i = divisions; i < divisions + additionalSteps - 1; i++) {
            const startSteps = stepLengths.map(val => val * i);
            const endSteps = stepLengths.map(val => val * (i + 1));
            lines.push([
                from.map((val, idx) => val + startSteps[idx]),
                from.map((val, idx) => val + endSteps[idx])
            ]);
        }
    }

    return new LinesRenderable(pose, lines, renderSettings);
};
