import { OBB } from "../../lib/NRL/OBB";
import { Pose3D } from "../../lib/NRL/Pose3D";
import {
    ArrowDimensions,
    BoxDimensions
} from "../../lib/NRL/RenderableFactory";
import { transformPoint2DFromDimensions } from "../../lib/qm_cs_lib";

/**
 * @typedef {Object} UIBox
 * @property {Number[]} cog
 * @property {Number} orientation
 * @property {import("../../lib/NRL/RenderableFactory").BoxDimensions} boxDims
 */

export class AnnotationContext {
    static TOPICS = {
        TEST: "TEST",
        COG: {
            RGB: "COG.RGB",
            BEV: "COG.BEV"
        },
        BOX_DIMENSIONS: {
            RGB: "BOX_DIMENSIONS.RGB",
            BEV: "BOX_DIMENSIONS.BEV"
        },
        BOX_ORIENTATION: {
            RGB: "BOX_ORIENTATION.RGB",
            BEV: "BOX_ORIENTATION.BEV"
        },
        MOUSE_POINTS_HIGHLIGHT: {
            RGB: "MOUSE_POINT_HIGHLIGHT.RGB",
            BEV: "MOUSE_POINT_HIGHLIGHT.BEV"
        }
    };

    /**
     *
     * @param {import("../../lib/NRL/RenderableFactory").BoxDimensions} initialBoxDims
     * @param {import("../../lib/NRL/RenderableFactory").BoxDimensions} minimalBoxDims
     * @param {import("../../lib/NRL/Pose3D").Pose3D} initialCogPos
     * @param {Number} initialBoxOrientation
     */
    constructor(
        initialBoxDims,
        minimalBoxDims,
        initialCogPos,
        initialBoxOrientation
    ) {
        this.initialBoxDims = initialBoxDims;
        this.minimalBoxDims = minimalBoxDims;
        this.initialCogPos = initialCogPos;
        this.initialBoxOrientation = initialBoxOrientation;

        // these will be updated in the UI
        /** @type {Number[]} */
        this.cogPos = this.initialCogPos;

        /** @type {Number} */
        this.boxOrientation = this.initialBoxOrientation;

        /** @type {import("../../lib/NRL/RenderableFactory").BoxDimensions} */
        this.boxDims = null;

        /** @type {ArrowDimensions} */
        this.arrowDims = null;

        // these won't be updated in the UI
        /** @type {import("../../lib/NRL/RenderableFactory").CrossDimensions} */
        this.crossDims = null;

        // optional
        /** @type {UIBox[]} */
        this.existingBoxes = [];

        // only used when there are existing boxes
        this.boxIntersectsAnyExistingBox = false;
    }

    /**
     * @param {import("../../lib/NRL/RenderableFactory").BoxDimensions} boxDims
     * @returns {ArrowDimensions}
     */
    static makeArrowDimsFromBoxDims = boxDims => {
        const arrowLength = (boxDims.front + boxDims.back) / 3;
        return new ArrowDimensions(arrowLength, arrowLength * 0.75);
    };

    /**
     * @param {React.MouseEvent} mouseEvent
     * @param {import("../../lib/qm_cs_lib").Dimension2D} originalCanvasDims
     * @param {import("../../lib/qm_cs_lib").Dimension2D} resizedCanvasDims
     * @returns {Number[]}
     */
    static scaleCanvasMousePos(
        mouseEvent,
        originalCanvasDims,
        resizedCanvasDims
    ) {
        const scaledMouseCanvasPos = transformPoint2DFromDimensions(
            {
                x: mouseEvent.nativeEvent.offsetX,
                y: mouseEvent.nativeEvent.offsetY
            },
            resizedCanvasDims,
            originalCanvasDims
        );
        return [scaledMouseCanvasPos.x, scaledMouseCanvasPos.y];
    }

    /**
     * @param {import("../../lib/NRL/Scene3D").Scene3D} scene
     * @param {Number} boxLineWidth
     * @param {String} strokeStyle
     */
    addExistingBoxesToScene(scene, boxLineWidth = 4, strokeStyle = "#A0A") {
        let i = 0;
        for (const b of this.existingBoxes) {
            const orientation = [0, 0, 0];
            if (isNaN(b.orientation)) {
                orientation[0] = b.orientation.y;
                orientation[1] = b.orientation.x;
                orientation[2] = b.orientation.z;
            } else {
                // only z-axis rotation for old projects, where orientation is just a number
                orientation[2] = b.orientation;
            }
            const box = b.boxDims.makeCurvesRenderable(
                new Pose3D(b.cog, orientation),
                {
                    lineWidth: boxLineWidth,
                    strokeStyle: strokeStyle
                }
            );
            scene.addObject(box, `existing_box_${i}`);
            i++;
        }
    }

    /**
     *
     * @param {Number} boxLineWidth
     * @param {String} boxColor
     * @param {Number} arrowLineWidth
     * @param {String} arrowColor
     */
    makeInitialBoxAndArrowRenderables(
        boxLineWidth,
        boxColor,
        arrowLineWidth,
        arrowColor
    ) {
        this.boxDims = BoxDimensions.copy(this.initialBoxDims);
        this.arrowDims = AnnotationContext.makeArrowDimsFromBoxDims(
            this.boxDims
        );

        const box = this.boxDims.makeCurvesRenderable(
            new Pose3D(this.initialCogPos, [0, 0, this.initialBoxOrientation]),
            {
                lineWidth: boxLineWidth,
                strokeStyle: boxColor
            }
        );
        const arrow = this.arrowDims.makeLinesRenderable(
            new Pose3D(this.initialCogPos, [0, 0, this.initialBoxOrientation]),
            {
                lineWidth: arrowLineWidth,
                strokeStyle: arrowColor
            }
        );

        return { box, arrow };
    }

    /**
     * Calculate center of box after updating dimensions.
     * Then updates box and arrow with the new cog.
     *
     * @param {import("../../lib/NRL/Renderable").CurvesRenderable} box
     * @param {import("../../lib/NRL/Renderable").LinesRenderable} arrow
     */
    centerCogInBoxDims(box, arrow) {
        // update the box center: subtract because the dimensions are
        // always positive
        const boxCenterInBoxCoords = [
            (this.boxDims.right - this.boxDims.left) / 2,
            (this.boxDims.front - this.boxDims.back) / 2,
            (this.boxDims.top - this.boxDims.bottom) / 2
        ];
        const boxCenterInWorldCoords = box.poseWorld.applyTo(
            boxCenterInBoxCoords
        );
        this.cogPos = boxCenterInWorldCoords;

        // move box and arrow to the new box center
        box.poseWorld.setPosition(boxCenterInWorldCoords);
        arrow.poseWorld.setPosition(boxCenterInWorldCoords);

        // update box dimensions because they should be relative to the box center
        const halfWidth = (this.boxDims.left + this.boxDims.right) / 2;
        const halfLength = (this.boxDims.front + this.boxDims.back) / 2;
        const halfHeight = (this.boxDims.top + this.boxDims.bottom) / 2;
        this.boxDims = new BoxDimensions(
            halfLength,
            halfLength,
            halfWidth,
            halfWidth,
            halfHeight,
            halfHeight
        );

        // update arrow dimensions because it can resize
        this.arrowDims = AnnotationContext.makeArrowDimsFromBoxDims(
            this.boxDims
        );

        // update the renderable lines
        box.init(this.boxDims.makeBoxCurves());
        arrow.init(this.arrowDims.makeArrowLines());
    }

    enforceMinimalBoxDims() {
        // make sure minimalBoxDims constraint is satisfied
        for (const dim of ["front", "back", "left", "right", "top", "bottom"]) {
            if (this.boxDims[dim] < this.minimalBoxDims[dim]) {
                this.boxDims[dim] = this.minimalBoxDims[dim];
            }
        }
    }

    /**
     * Find point idxs of projected pointcloud points on canvas that are in the specified distance
     * to the referencePos.
     * @param {import("../../lib/NRL/Renderable").PointcloudRenderable} pointcloudRenderable
     * @param {Number[]} referencePos in canvas coordinates
     * @param {Number} maxDist pixels on canvas
     * @returns {Number[][]}
     */
    static findClosePointIdxs(pointcloudRenderable, referencePos, maxDist) {
        const closePointIdxs = [];
        for (let i = 0; i < pointcloudRenderable.projectedPoints.length; i++) {
            const p = pointcloudRenderable.projectedPoints[i];
            const referencePosToPoint = [
                p[0] - referencePos[0],
                p[1] - referencePos[1]
            ];
            const dist = Math.sqrt(
                referencePosToPoint[0] * referencePosToPoint[0] +
                    referencePosToPoint[1] * referencePosToPoint[1]
            );
            if (dist <= maxDist) {
                closePointIdxs.push(i);
            }
        }
        return closePointIdxs;
    }

    /**
     * Highlight projected canvas points of a pointcloud by drawing them with the
     * same color they were already given but different opacity.
     * @param {import("../../lib/NRL/Scene3D").Scene3D} scene
     * @param {import("../../lib/NRL/Renderable").PointcloudRenderable} pointcloudRenderable
     * @param {Number[]} pointIdxs
     * @param {Number} highlightedPointRadius
     * @param {Number} highlightedPointOpacity
     */
    static highlightPointIdxs(
        scene,
        pointcloudRenderable,
        pointIdxs,
        highlightedPointRadius,
        highlightedPointOpacity
    ) {
        /** @type {CanvasRenderingContext2D} */
        const ctx = scene.renderer.ctx;
        scene.renderer.settings.bgClearFunc(ctx);
        for (const idx of pointIdxs) {
            const p = pointcloudRenderable.projectedPoints[idx];
            // use the pre-calculated color1.5 with different opacity
            ctx.fillStyle = pointcloudRenderable.mappedColors[idx].replace(
                /,\s*\d+\.?\d*\)$/,
                `, ${highlightedPointOpacity})`
            );
            ctx.beginPath();
            ctx.arc(p[0], p[1], highlightedPointRadius, 0, 2 * Math.PI);
            ctx.fill();
        }
    }

    /**
     * Fit the point radius and opacity to the current zoom level.
     * @param {import("../../lib/NRL/Renderable").PointcloudRenderable} pointcloudRenderable
     * @param {import("../../lib/NRL/Pose3D").Pose3D} cameraPoseWorld
     */
    static fitPointStyleToZoom(pointcloudRenderable, cameraPoseWorld) {
        pointcloudRenderable.renderSettings.radius = Math.max(
            1.5 * cameraPoseWorld.zoom * 0.01,
            6
        );

        for (const idx in pointcloudRenderable.projectedPoints) {
            // use the pre-calculated color with different opacity
            pointcloudRenderable.mappedColors[
                idx
            ] = pointcloudRenderable.mappedColors[idx].replace(
                /,\s*\d+\.?\d*\)$/,
                `, ${Math.min(cameraPoseWorld.zoom * 0.005, 1.0)})`
            );
        }
    }

    /**
     * Check if box intersects with any existing box.
     * If there's an intersection with any box:
     * - update the adjustable box's render settings to indicate valid intersection
     *
     * @param {import("../../lib/NRL/Scene3D").Scene3D} scene
     * @param {import("../../lib/NRL/Renderable").CurvesRenderable} boxRenderable
     * @param {Number=} boxLineWidth
     * @param {String=} boxColor
     * @param {Number=} boxIntersectionLineWidth
     * @param {String=} boxIntersectionColor
     */
    handleBoxIntersectsExistingBoxes(
        scene,
        boxRenderable,
        boxLineWidth,
        boxColor,
        boxIntersectionLineWidth,
        boxIntersectionColor
    ) {
        const boxOBB = new OBB();
        const existingOBB = new OBB();
        boxOBB.fromCurvesRenderable(boxRenderable);
        // default: no intersection
        let renderSettings = { lineWidth: boxLineWidth, strokeStyle: boxColor };
        this.boxIntersectsAnyExistingBox = false;
        for (let i = 0; i < this.existingBoxes.length; i++) {
            const existingBoxRenderable = scene.getObject(`existing_box_${i}`);
            existingOBB.fromCurvesRenderable(existingBoxRenderable);
            if (boxOBB.intersectsOBB(existingOBB)) {
                this.boxIntersectsAnyExistingBox = true;
                renderSettings = {
                    lineWidth: boxIntersectionLineWidth,
                    strokeStyle: boxIntersectionColor
                };
                break;
            }
        }
        boxRenderable.initRenderSettings(renderSettings);
    }
}
