import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Slider from "rc-slider";

import { ActionButtonNextOrSubmit } from "../../components/ActionButton/ActionButton";
import {
    BoundingBoxCameraView,
    ACTION_INDICATOR
} from "./BoundingBoxCameraView";
import { BoxDimensions } from "../../lib/NRL/RenderableFactory";
import BBox3DModel from "../../lib/DataModels/BBox3DModel";

import "./CoconutCanvasScene.css";
import "./BoundingBoxInCameraViewsWidget.css";

const DEFAULT_GUI_SETTINGS = {
    questionText: "Adjust the bounding box using the sliders",
    boxLineColor: "#F00",
    boxLineWidth: 2.0,
    actionIndicatorLineColor: "#F0F",
    actionIndicatorLineWidth: 2.0,
    sliderRangeDimension: [0, 1],
    sliderRangePosition: [-2, 2],
    sliderRangeRotation: [-Math.PI, Math.PI],
    sliderStepDimension: 0.01,
    sliderStepPosition: 0.01,
    sliderStepRotation: 0.01,
    zoomLevel: 1.5,
    reference_image_url: null
};

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const BoundingBoxInCameraViewsWidget = ({
    taskInput,
    guiSettings,
    resourceCache,
    currentTaskOutput,
    setCurrentTaskOutput,
    taskUIContext,
    currentTaskIdx
}) => {
    const [initialCurrentTaskOutput, setInitialCurrentTaskOutput] = useState(
        taskUIContext.getCurrentGuiObject().getInitialCurrentTaskOutput()
    );
    // The BBox3D data in a representation that's usable by the UI
    // See how TiledBoundingBox3D::getInitialCurrentTaskOutput() deals with the input data for more details.
    const [boxUI, setBoxUI] = useState(
        initialCurrentTaskOutput.box.makeUITypes()
    );

    // used to tell BoundingBoxCameraView which actionIndicator it should display
    const [actionIndicatorName, setActionIndicatorName] = useState(null);
    // used to handle this case:
    //  - Moving mouse over slider A while dragging slider B triggering mouseup.
    //    Without this the actionIndicator would be gone even though the mouse hovers slider A.
    const [nextActionIndicatorName, setNextActionIndicatorName] = useState(
        null
    );
    // don't change the actionIndicatorName while dragging a slider
    const [mouseDown, setMouseDown] = useState(false);
    // the actionIndicator should disappear when mouseup happens when mouse isn't over a slider
    const [mouseOverSlider, setMouseOverSlider] = useState(false);

    const [zoomEnabled, setZoomEnabled] = useState(false);

    // default gui settings. this syntax ensures that defaults are picked
    const {
        question: { text: questionText = DEFAULT_GUI_SETTINGS.questionText } = {
            text: DEFAULT_GUI_SETTINGS.questionText
        },
        box_line_color: boxLineColor = DEFAULT_GUI_SETTINGS.boxLineColor,
        box_line_width: boxLineWidth = DEFAULT_GUI_SETTINGS.boxLineWidth,
        action_axis_indicator_line_color: actionIndicatorLineColor = DEFAULT_GUI_SETTINGS.actionIndicatorLineColor,
        action_axis_indicator_line_width: actionIndicatorLineWidth = DEFAULT_GUI_SETTINGS.actionIndicatorLineWidth,
        zoom_level: zoomLevel = DEFAULT_GUI_SETTINGS.zoomLevel,
        reference_image_url: referenceImageUrl = DEFAULT_GUI_SETTINGS.reference_image_url,
        slider_ranges: {
            dimension: sliderRangeDimension = DEFAULT_GUI_SETTINGS.sliderRangeDimension,
            position: sliderRangePosition = DEFAULT_GUI_SETTINGS.sliderRangePosition,
            rotatoin: sliderRangeRotation = DEFAULT_GUI_SETTINGS.sliderRangeRotation
        } = {
            dimension: DEFAULT_GUI_SETTINGS.sliderRangeDimension,
            position: DEFAULT_GUI_SETTINGS.sliderRangePosition,
            rotatoin: DEFAULT_GUI_SETTINGS.sliderRangeRotation
        },
        slider_steps: {
            dimension: sliderStepDimension = DEFAULT_GUI_SETTINGS.sliderStepDimension,
            position: sliderStepPosition = DEFAULT_GUI_SETTINGS.sliderStepPosition,
            rotatoin: sliderStepRotation = DEFAULT_GUI_SETTINGS.sliderStepRotation
        } = {
            dimension: DEFAULT_GUI_SETTINGS.sliderStepDimension,
            position: DEFAULT_GUI_SETTINGS.sliderStepPosition,
            rotatoin: DEFAULT_GUI_SETTINGS.sliderStepRotation
        }
    } = guiSettings;

    useEffect(() => {
        // reset between tasks
        const newInitialCurrentTaskOutput = taskUIContext
            .getCurrentGuiObject()
            .getInitialCurrentTaskOutput();
        setCurrentTaskOutput(newInitialCurrentTaskOutput);
        setInitialCurrentTaskOutput(newInitialCurrentTaskOutput);
        setBoxUI(newInitialCurrentTaskOutput.box.makeUITypes());
        setZoomEnabled(false);
        // eslint-disable-next-line
    }, [currentTaskIdx]);

    // events to handle whether the action indicator should be shown
    useEffect(() => {
        if (!mouseDown) {
            return;
        }
        const onMouseUp = () => {
            setMouseDown(false);
            if (mouseOverSlider && nextActionIndicatorName) {
                setActionIndicatorName(nextActionIndicatorName);
            } else {
                setActionIndicatorName(null);
            }
        };
        document.addEventListener("mouseup", onMouseUp);
        return () => document.removeEventListener("mouseup", onMouseUp);
    }, [mouseDown, mouseOverSlider, nextActionIndicatorName]);

    const makeCameraView = (name, abbreviation) => {
        const cameraViewParams = taskInput[abbreviation];
        return (
            <Col>
                <BoundingBoxCameraView
                    key={cameraViewParams.image_url + "_" + name}
                    name={name}
                    hmdWorldPose={taskInput.hmd_world_pose}
                    E={cameraViewParams.E}
                    K={cameraViewParams.K}
                    distortionCoeffs={cameraViewParams.distortion_coeffs}
                    image={resourceCache[cameraViewParams.image_url]}
                    boxUI={boxUI}
                    boxLineColor={boxLineColor}
                    boxLineWidth={boxLineWidth}
                    actionIndicatorLineColor={actionIndicatorLineColor}
                    actionIndicatorLineWidth={actionIndicatorLineWidth}
                    actionIndicatorName={actionIndicatorName}
                    actionIndicatorLineLength={20}
                    withZoom={zoomEnabled}
                    zoomLevel={zoomLevel}
                />
            </Col>
        );
    };

    const updateTaskOutput = () => {
        setCurrentTaskOutput(
            taskUIContext.getCurrentGuiObject().makeTaskOutputForCurrentTask({
                ...currentTaskOutput,
                box: BBox3DModel.makeBBox3DModelFromBoxUI(boxUI)
            })
        );
    };

    /**
     * Wraps an rc-slider in a div with a label.
     * Also adds events to the div around the slider to handle showing actionIndicators.
     * @param {Slider} slider
     * @param {String} label
     * @param {String} sliderActionIndicatorName
     */
    const makeSliderWithLabel = (slider, label, sliderActionIndicatorName) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "right",
                    textAlign: "left"
                }}
            >
                <div
                    style={{
                        flex: "0.3",
                        lineHeight: "2.4rem",
                        fontSize: "0.9rem"
                    }}
                >
                    {label}
                </div>
                <div
                    style={{ flex: "1" }}
                    onMouseDown={() => setMouseDown(true)}
                    onMouseEnter={() => {
                        setMouseOverSlider(true);
                        setNextActionIndicatorName(sliderActionIndicatorName);
                        if (!mouseDown) {
                            setActionIndicatorName(sliderActionIndicatorName);
                        }
                    }}
                    onMouseLeave={() => {
                        setMouseOverSlider(false);
                        if (!mouseDown) {
                            setActionIndicatorName(null);
                        }
                    }}
                >
                    {slider}
                </div>
            </div>
        );
    };

    /**
     * Create slider to adjust one of the rotations of boxUI.
     * @param {String} axis x, y or z
     */
    const makeRotationSlider = axis => {
        // same order as in boxUI.rotation
        const idx = ["y", "x", "z"].indexOf(axis);
        if (idx === -1) {
            return null;
        }
        return makeSliderWithLabel(
            <Slider
                className="rotation_slider"
                min={sliderRangeRotation[0]}
                max={sliderRangeRotation[1]}
                step={sliderStepRotation}
                value={boxUI.rotation[idx]}
                onChange={v => {
                    const rotation = [...boxUI.rotation];
                    rotation[idx] = v;
                    setBoxUI({
                        ...boxUI,
                        rotation
                    });
                }}
                onAfterChange={() => {
                    updateTaskOutput();
                }}
            />,
            axis,
            ACTION_INDICATOR.ROTATION[axis.toUpperCase()]
        );
    };

    /**
     * Create slider to adjust one of the dimensions of boxUI.
     * @param {String} dimension width, length or height
     */
    const makeDimensionSlider = dimension => {
        if (
            dimension !== "width" &&
            dimension !== "length" &&
            dimension !== "height"
        ) {
            return null;
        }

        // swap height and length, because z (height) is parallel to the ground and y (length) is perpendicular.
        let usedDimension = dimension;
        if (dimension === "height") {
            usedDimension = "length";
        } else if (dimension === "length") {
            usedDimension = "height";
        }

        return makeSliderWithLabel(
            <Slider
                className="dimension_slider"
                min={sliderRangeDimension[0]}
                max={sliderRangeDimension[1]}
                step={sliderStepDimension}
                value={boxUI.dimensions[usedDimension]}
                onChange={v => {
                    const dimensions = { ...boxUI.dimensions };
                    dimensions[usedDimension] = v;
                    setBoxUI({
                        ...boxUI,
                        dimensions,
                        boxDims: new BoxDimensions(
                            dimensions.length / 2,
                            dimensions.length / 2,
                            dimensions.width / 2,
                            dimensions.width / 2,
                            dimensions.height / 2,
                            dimensions.height / 2
                        )
                    });
                }}
                onAfterChange={() => {
                    updateTaskOutput();
                }}
            />,
            dimension,
            ACTION_INDICATOR.DIMENSION[usedDimension.toUpperCase()]
        );
    };

    /**
     * Create slider to adjust position of box center along one of the global axes.
     * @param {String} axis x, y or z
     */
    const makePositionSlider = axis => {
        // same order as in boxUI.position
        const idx = ["x", "y", "z"].indexOf(axis);
        if (idx === -1) {
            return null;
        }

        return makeSliderWithLabel(
            <Slider
                className="position_slider"
                min={sliderRangePosition[0]}
                max={sliderRangePosition[1]}
                step={sliderStepPosition}
                value={boxUI.position[idx]}
                onChange={v => {
                    const position = [...boxUI.position];
                    position[idx] = v;
                    setBoxUI({
                        ...boxUI,
                        position
                    });
                }}
                onAfterChange={() => {
                    updateTaskOutput();
                }}
            />,
            axis,
            ACTION_INDICATOR.POSITION[axis.toUpperCase()]
        );
    };

    return (
        <Container fluid>
            <h1 style={{ width: 0, minWidth: "100%" }}>{questionText}</h1>
            <Row className="justify-content-md-center">
                {taskInput.reference_image_url || referenceImageUrl ? (
                    <Col
                        md="2"
                        style={{ display: "flex", alignItems: "center"}}
                    >
                        <div>
                            <p>Reference</p>
                            <img
                                id="image"
                                src={
                                    taskInput.reference_image_url
                                        ? taskInput.reference_image_url
                                        : referenceImageUrl
                                }
                                style={{
                                    width: "160px",
                                    border: "1px solid black"
                                }}
                                draggable={false}
                                title="Reference Image."
                                alt=""
                            />
                        </div>
                    </Col>
                ) : null}
                <Col md={taskInput.reference_image_url || referenceImageUrl ? "7":"9"}>
                    <Row>
                        {makeCameraView("top_left", "tl")}
                        {makeCameraView("top_right", "tr")}
                    </Row>
                    <Row>
                        {makeCameraView("bottom_left", "bl")}
                        {makeCameraView("bottom_right", "br")}
                    </Row>
                </Col>
                <Col
                    md="3"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}
                >
                    <Row style={{ height: "33%" }}>
                        <Col>
                            <Row>
                                <Col>Rotations</Col>
                            </Row>
                            <Row>
                                <Col>
                                    {makeRotationSlider("x")}
                                    {makeRotationSlider("y")}
                                    {makeRotationSlider("z")}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ height: "33%" }}>
                        <Col>
                            <Row>
                                <Col>Dimensions</Col>
                            </Row>
                            <Row>
                                <Col>
                                    {makeDimensionSlider("length")}
                                    {makeDimensionSlider("width")}
                                    {makeDimensionSlider("height")}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ height: "33%" }}>
                        <Col>
                            <Row>
                                <Col>Positions</Col>
                            </Row>
                            <Row>
                                <Col>
                                    {makePositionSlider("x")}
                                    {makePositionSlider("y")}
                                    {makePositionSlider("z")}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        {/* without 'width: min-content' the second button makes the whole container grow too big in height */}
                        <Col style={{ width: "min-content" }}>
                            <Button
                                className="button is-sm"
                                onClick={() => {
                                    const newBoxUI = initialCurrentTaskOutput.box.makeUITypes();
                                    setBoxUI(newBoxUI);
                                    setCurrentTaskOutput(
                                        taskUIContext
                                            .getCurrentGuiObject()
                                            .makeTaskOutputForCurrentTask({
                                                ...currentTaskOutput,
                                                box: BBox3DModel.makeBBox3DModelFromBoxUI(
                                                    newBoxUI
                                                )
                                            })
                                    );
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                className="button is-sm"
                                style={{
                                    backgroundColor: zoomEnabled ? "" : "silver"
                                }}
                                onClick={() => setZoomEnabled(!zoomEnabled)}
                            >
                                Toggle Zoom
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginBottom: "-1rem" }}>
                <Col
                    style={{
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem"
                    }}
                >
                    <Row>
                        <Col>
                            <ActionButtonNextOrSubmit
                                taskUIContext={taskUIContext}
                                disabled={taskUIContext
                                    .getCurrentGuiObject()
                                    .isInitialCurrentTaskOutput(
                                        currentTaskOutput
                                    )}
                                actionSuffix="set_keypoint"
                                className="is-primary"
                                createTaskOutput={() => currentTaskOutput}
                            >
                                Next
                            </ActionButtonNextOrSubmit>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default BoundingBoxInCameraViewsWidget;
