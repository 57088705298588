import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

/**
 * A dialog informing the annotator to review their annotions.
 * Evaluates based on its props whether and when the dialog should be opened.
 * Confirming the dialog triggers a callback onStartReviewPhase.
 * @param {Object} props
 * @param {Boolean} props.shouldShow
 * @param {() => {}=} props.onClose
 */
export const FinishConfirmDialog = ({ shouldShow, onClose = () => {} }) => {
    return (
        <Dialog open={shouldShow}>
            <DialogTitle>Are you sure you want to finish?</DialogTitle>
            <DialogActions>
                <Button onClick={() => onClose(true)}>Yes</Button>
                <Button onClick={() => onClose(false)}>No</Button>
            </DialogActions>
        </Dialog>
    );
};
