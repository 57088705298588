// A database of eight common tomato pest images
// https://data.mendeley.com/datasets/s62zm6djd2/1
// License: CC BY 4.0

export const tomatoPestImages = [
    // Myzus persicae
    {
        image_url1:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(1).jpg",
        image_url2:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(2).jpg",
        image_url3:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(3).jpg",
        image_url4:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(4).jpg",
        image_url5:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(5).jpg",
        image_url6:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(6).jpg",
        image_url7:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(7).jpg",
        image_url8:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(8).jpg",
        image_url9:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(9).jpg"
    },
    {
        image_url1:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(11).jpg",
        image_url2:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(12).jpg",
        image_url3:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(13).jpg",
        image_url4:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(14).jpg",
        image_url5:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(15).jpg",
        image_url6:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(16).jpg",
        image_url7:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(17).jpg",
        image_url8:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(18).jpg",
        image_url9:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(19).jpg"
    },
    {
        image_url1:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(21).jpg",
        image_url2:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(22).jpg",
        image_url3:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(23).jpg",
        image_url4:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(24).jpg",
        image_url5:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(25).jpg",
        image_url6:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(26).jpg",
        image_url7:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(27).jpg",
        image_url8:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(28).jpg",
        image_url9:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/tomato_pest/MP/MP+(29).jpg"
    }
];

export const covidImages = [
    {
        image_url1:
            "https://covidthon.blob.core.windows.net/dev/neg/left/0719ba5ffac530c6802ebf812c3e2a691f01a71d.png",

        image_url2:
            "https://covidthon.blob.core.windows.net/dev/neg/left/12dab82fd2bc2f9ab6a1dbf80ce2595030734d72.png",

        image_url3:
            "https://covidthon.blob.core.windows.net/dev/neg/left/1e7083a0b6a1c13da3a60c3a92c98f343f66dad7.png",

        image_url4:
            "https://covidthon.blob.core.windows.net/dev/neg/left/2cf2d0bb709bced72c43ca47b41561513fde386c.png",

        image_url5:
            "https://covidthon.blob.core.windows.net/dev/neg/left/3f59694f8ea2bba1bf1deb05a1fbc077e030c41b.png",

        image_url6:
            "https://covidthon.blob.core.windows.net/dev/neg/left/41d91855d99120eab21ae47e87950181cd578a05.png",

        image_url7:
            "https://covidthon.blob.core.windows.net/dev/neg/left/488957f5c45bd4031ef489b7683b77832069467e.png",

        image_url8:
            "https://covidthon.blob.core.windows.net/dev/neg/left/4dc55d2d9eb588c3e1e81ca1abbf1310b9981fd6.png"

        /*        image_url9:
            "https://covidthon.blob.core.windows.net/dev/neg/left/52de476a0c01c45a56108e6b111361f4306d09eb.png",

        image_url10:
            "https://covidthon.blob.core.windows.net/dev/neg/left/57b70f09df86f59b3663e42c539c764be4a4a502.png",

        image_url11:
            "https://covidthon.blob.core.windows.net/dev/neg/left/65306044eb32b3be53a26255def402bf19066dd3.png",

        image_url12:
            "https://covidthon.blob.core.windows.net/dev/neg/left/6c1a087308a6c1ae46795b58fbdafc8ebf9ad0c2.png",

        image_url13:
            "https://covidthon.blob.core.windows.net/dev/neg/left/6dca53602f34a131419dd3689bed9302f38e18e1.png",

        image_url14:
            "https://covidthon.blob.core.windows.net/dev/neg/left/742ba608218437dad2daf541834b473b34081069.png",

        image_url15:
            "https://covidthon.blob.core.windows.net/dev/neg/left/75ac74741363896810b4b7c2275035693f63727e.png",

        image_url16:
            "https://covidthon.blob.core.windows.net/dev/neg/left/92df17dd4f67b2ee19c592556dbce714d760ea9d.png", */
    },

    {
        image_url1:
            "https://covidthon.blob.core.windows.net/dev/neg/left/0719ba5ffac530c6802ebf812c3e2a691f01a71d.png",

        image_url2:
            "https://covidthon.blob.core.windows.net/dev/neg/left/12dab82fd2bc2f9ab6a1dbf80ce2595030734d72.png",

        image_url3:
            "https://covidthon.blob.core.windows.net/dev/neg/left/1e7083a0b6a1c13da3a60c3a92c98f343f66dad7.png",

        image_url4:
            "https://covidthon.blob.core.windows.net/dev/neg/left/2cf2d0bb709bced72c43ca47b41561513fde386c.png",

        image_url5:
            "https://covidthon.blob.core.windows.net/dev/neg/left/3f59694f8ea2bba1bf1deb05a1fbc077e030c41b.png",

        image_url6:
            "https://covidthon.blob.core.windows.net/dev/neg/left/41d91855d99120eab21ae47e87950181cd578a05.png"

        /*        image_url7:
            "https://covidthon.blob.core.windows.net/dev/neg/left/488957f5c45bd4031ef489b7683b77832069467e.png",

       image_url8:
            "https://covidthon.blob.core.windows.net/dev/neg/left/4dc55d2d9eb588c3e1e81ca1abbf1310b9981fd6.png",

        image_url9:
            "https://covidthon.blob.core.windows.net/dev/neg/left/52de476a0c01c45a56108e6b111361f4306d09eb.png",

        image_url10:
            "https://covidthon.blob.core.windows.net/dev/neg/left/57b70f09df86f59b3663e42c539c764be4a4a502.png",

        image_url11:
            "https://covidthon.blob.core.windows.net/dev/neg/left/65306044eb32b3be53a26255def402bf19066dd3.png",

        image_url12:
            "https://covidthon.blob.core.windows.net/dev/neg/left/6c1a087308a6c1ae46795b58fbdafc8ebf9ad0c2.png",

        image_url13:
            "https://covidthon.blob.core.windows.net/dev/neg/left/6dca53602f34a131419dd3689bed9302f38e18e1.png",

        image_url14:
            "https://covidthon.blob.core.windows.net/dev/neg/left/742ba608218437dad2daf541834b473b34081069.png",

        image_url15:
            "https://covidthon.blob.core.windows.net/dev/neg/left/75ac74741363896810b4b7c2275035693f63727e.png",

        image_url16:
            "https://covidthon.blob.core.windows.net/dev/neg/left/92df17dd4f67b2ee19c592556dbce714d760ea9d.png", */
    }
];
