import { JSONSerializable } from "../JSONSerializable";
// eslint-disable-next-line
import GroupAck from "./GroupAck";

/**
 * @class
 * @augments {JSONSerializable}
 */
export default class SubmitTaskGroupRequest extends JSONSerializable {
    /**
     *
     * @param {GroupAck} group_ack
     * @param {JSONSerializable[]} task_results
     */
    constructor(group_ack, task_results) {
        super();
        this.setGroupAck(group_ack).setTaskResults(task_results);
    }

    /**
     *
     * @param {GroupAck} group_ack
     * @returns {SubmitTaskGroupRequest}
     */
    setGroupAck(group_ack) {
        this.group_ack = group_ack;
        return this;
    }

    /**
     *
     * @param {JSONSerializable[]} task_results
     * @returns {SubmitTaskGroupRequest}
     */
    setTaskResults(task_results) {
        this.task_results = task_results;
        return this;
    }
}
