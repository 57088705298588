import { Container } from "react-bootstrap";

import BaseGuiType from "../../BaseGuiType";
import MockApiClient from "../../MockData/MockApiClient";
import MockData from "../../MockData/MockData";
import ResourceLoaderService from "../../ResourceLoaderService";
import { makeImageLoader } from "../../../lib/DataLoader/loaders";
import { WIDGETS } from "../../../guiFactory";
import withWaitForResourcesWrapper from "../../util/withWaitForResourcesWrapper";
import BBox3DModel from "../../../lib/DataModels/BBox3DModel";
import BoundingBoxFaceAdjustmentInCameraViewsWidget from "./BoundingBoxFaceAdjustmentCameraViewsWidget";
import { TaskInputsForSequenceBBoxFaceAdjustment } from "../tiledUIMockInputs";

export class TiledBoundingBoxFaceAdjustment3D extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return WIDGETS.TILED_BOUNDING_BOX_FACE_ADJUSTMENT_3D;
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const mockApiClient = new MockApiClient(
            {
                instructions: [],
                question: {
                    text: "Adjust the faces of the box to fit the object"
                },
                box_line_color: "#F00",
                box_line_width: 2.0,
                slider_ranges: {
                    dimension: [0, 0.2]
                },
                slider_steps: {
                    dimension: 0.001
                },
                zoom_level: 1.5
            },
            TiledBoundingBoxFaceAdjustment3D.getName(),
            TaskInputsForSequenceBBoxFaceAdjustment
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeCustomLoaderService(
                new Map([
                    [
                        "all_image_urls",
                        taskInput => {
                            let loaders = [];
                            for (const cameraViewParams of Object.values(
                                taskInput.timestamps
                            )) {
                                loaders = [
                                    ...loaders,
                                    makeImageLoader(
                                        cameraViewParams.tl.image_url
                                    ),
                                    makeImageLoader(
                                        cameraViewParams.tr.image_url
                                    ),
                                    makeImageLoader(
                                        cameraViewParams.bl.image_url
                                    ),
                                    makeImageLoader(
                                        cameraViewParams.br.image_url
                                    )
                                ];
                            }
                            return loaders;
                        }
                    ]
                ])
            )
        );
    }

    /**
     * @override
     * @returns {LayoutCreator}
     */
    getLayoutCreator() {
        return (
            heading,
            dataVis,
            progressBar,
            actionButtonGroup,
            metaActionButtonGroup
        ) => {
            const components = [
                heading,
                dataVis,
                progressBar,
                actionButtonGroup,
                metaActionButtonGroup
            ];
            const instantiatedComponents = this.connectAndInstantiateComponents(
                components
            );
            return (
                <Container
                    style={{
                        backgroundColor: "#f5f5f5",
                        textAlign: "center",
                        borderRadius: "15px"
                    }}
                >
                    {instantiatedComponents}
                </Container>
            );
        };
    }

    /**
     * @override
     */
    getHeading() {
        return () => null;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return withWaitForResourcesWrapper(
            BoundingBoxFaceAdjustmentInCameraViewsWidget
        );
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return () => null;
    }

    /**
     * @override
     */
    getMetaActionButtonGroup() {
        return () => null;
    }

    /**
     * @override
     */
    getInitialCurrentTaskOutput() {
        const taskInput = this.taskUIContext.getCurrentTaskInput();
        return {
            ...super.makeTaskOutputForCurrentTask(),
            box: BBox3DModel.parseJSONObject(taskInput.box)
        };
    }

    /**
     * @override
     */
    makeTaskOutputForCurrentTask(overrides) {
        return {
            ...this.getInitialCurrentTaskOutput(),
            ...overrides
        };
    }
}

export default TiledBoundingBoxFaceAdjustment3D;
