import { min, max } from "mathjs";

// eslint-disable-next-line
import { Pose3D } from "./Pose3D";
// eslint-disable-next-line
import { Scene3D } from "./Scene3D";
import {
    // eslint-disable-next-line
    Renderable,
    // eslint-disable-next-line
    CanvasRenderable,
    PointcloudRenderable
} from "./Renderable";
import { makeCoordinateSystem } from "./RenderableFactory";

export const RENDERERS = {
    ORTHOGRAPHIC_CANVAS: "ORTHOGRAPHIC_CANVAS",
    SPHERICAL_CANVAS: "SPHERICAL_CANVAS",
    FISHEYE_CANVAS: "FISHEYE_CANVAS"
};

export class Renderer {
    /**
     * Abstract
     * @param {Pose3D} pose
     * @param {Number[][]=} csRanges default is 0 to 5 for all axes
     * @returns {Renderable}
     */
    makeCoordinateSystemObject(pose, csRanges) {
        throw new Error("Method needs implementation");
    }

    /**
     * Abstract
     * @param {Renderable} obj
     * @param {Pose3D} cameraPoseWorld
     * @param {Boolean=} shouldClear default: false. whether or not to clear the background before rendering the object
     */
    render(obj, cameraPoseWorld, shouldClear = false) {
        throw new Error("Method needs implementation");
    }

    /**
     * Abstract
     * @param {Renderable} obj
     */
    projectObject(obj) {
        throw new Error("Method needs implementation");
    }

    /**
     * @param {Scene3D} scene
     */
    setScene(scene) {
        this.scene = scene;
    }
}

/**
 * @typedef {Object} CanvasRendererSettings
 * @property {Function=} bgClearFunc does a full ctx.clearRect as default
 */

export class CanvasRenderer extends Renderer {
    /**
     * @param {CanvasRenderingContext2D} ctx
     * @param {CanvasRendererSettings=} settings
     */
    constructor(ctx, settings = {}) {
        super();
        const { bgClearFunc = CanvasRenderer.clearCanvas } = settings;
        settings.bgClearFunc = bgClearFunc;
        this.ctx = ctx;
        this.settings = settings;
    }

    /**
     * @param {CanvasRenderingContext2D} ctx
     */
    static clearCanvas(ctx) {
        ctx.clearRect(0, 0, ctx.width, ctx.height);
    }

    clear() {
        CanvasRenderer.clearCanvas(this.ctx);
    }

    /**
     * Implements an abstract method
     * @param {Pose3D} pose
     * @param {Number[][]=} csRanges default is 0 to 5 for all axes
     * @returns {Renderable}
     */
    makeCoordinateSystemObject(
        pose,
        csRanges = [
            [0, 5],
            [0, 5],
            [0, 5]
        ]
    ) {
        return makeCoordinateSystem(pose, csRanges);
    }

    /**
     * Implements an abstract method
     * @param {CanvasRenderable} obj
     * @param {Pose3D} cameraPoseWorld
     * @param {Boolean=} shouldClear
     */
    render(obj, cameraPoseWorld, shouldClear = false) {
        if (obj !== null && obj !== undefined) {
            this.projectObject(cameraPoseWorld, obj);
            if (shouldClear) {
                this.settings.bgClearFunc(this.ctx);
            }
            obj.renderToCanvas(this.ctx);
        } else {
            console.error("bad renderable object!", obj);
        }
    }

    /**
     * Implements an abstract method
     * @param {Pose3D} cameraPoseWorld
     * @param {CanvasRenderable} obj
     */
    projectObject(cameraPoseWorld, obj) {
        if (!obj.shouldReProject) {
            return;
        }
        this.projectObjectToCanvas(cameraPoseWorld, obj);
    }

    /**
     * Abstract
     * @param {Pose3D} cameraPoseWorld
     * @param {CanvasRenderable} obj
     */
    projectObjectToCanvas(cameraPoseWorld, obj) {
        throw new Error("Method needs implementation");
    }
}

export class OrthographicCanvasRenderer extends CanvasRenderer {
    /**
     * @override
     * @param {Pose3D} cameraPoseWorld
     * @param {CanvasRenderable} obj
     */
    projectObjectToCanvas(cameraPoseWorld, obj) {
        const points = obj.getPoints();
        const projectedPoints = [];
        for (const point of points) {
            // Optimization for point clouds with world origin in x: 0, y: 0 and with the same
            // zoom as the cameraPose.
            // This works if the camera has an orthogonal view onto of the planes
            // defined by the global coordinate system axes.
            // --> Don't project points that fall outside of the viewport.
            //     Saves two Matrix-Vector multiplications per point
            if (
                obj instanceof PointcloudRenderable &&
                obj.poseWorld.zoom === cameraPoseWorld.zoom &&
                obj.poseWorld.pos[0] === 0 &&
                obj.poseWorld.pos[1] === 0 &&
                obj.poseWorld.pos[2] === 0 &&
                (Math.abs(cameraPoseWorld.pos[0] - point[0]) >
                    this.ctx.width / cameraPoseWorld.zoom ||
                    Math.abs(cameraPoseWorld.pos[1] - point[1]) >
                        this.ctx.height / cameraPoseWorld.zoom)
            ) {
                projectedPoints.push(null);
                continue;
            }

            // apply local transformation
            const pointWithLocalObjectTransformation = obj.poseWorld.applyTo(
                point
            );
            const projectedPoint = this.worldPointToCanvas(
                cameraPoseWorld,
                pointWithLocalObjectTransformation
            );
            projectedPoints.push(projectedPoint);
        }

        obj.setProjectedPoints(projectedPoints);

        // whenever the object is changed its shouldReProjectCanvasCoords
        // should be set to true
        obj.setShouldReProject(false);
    }

    /**
     * Assumes a a top-down view where z-axis points out of the screen.
     * Z value of the point will be 0.
     *
     * @param {Pose3D} cameraPoseWorld
     * @param {Number[]} point in canvas coordinates
     * @returns {Number[]} point in world coordinates
     */
    canvasPointToWorldXY(cameraPoseWorld, point) {
        const pointInCameraCoords = [
            point[0] - this.ctx.width / 2,
            -(point[1] - this.ctx.height / 2),
            0
        ];
        // transform the point out of the camera system
        return cameraPoseWorld.applyTo(pointInCameraCoords).slice(0, 3);
    }

    /**
     * Assumes a a top-down view where z-axis points out of the screen.
     * Z value of the point will be 0.
     * Transforms the point to be in the localPose system
     *
     * @param {Pose3D} cameraPoseWorld
     * @param {Pose3D} localPose
     * @param {Number[]} point in canvas coordinates
     * @returns {Number[]} point in world coordinates
     */
    canvasPointToLocalWorldXY(cameraPoseWorld, localPose, point) {
        const pointInCameraCoords = [
            point[0] - this.ctx.width / 2,
            -(point[1] - this.ctx.height / 2),
            0
        ];
        // transform the point out of the camera system
        return localPose
            .applyInverseTo(cameraPoseWorld.applyTo(pointInCameraCoords))
            .slice(0, 3);
    }

    /**
     * @param {Pose3D} cameraPoseWorld
     * @param {Number[]} point in world coordinates
     * @returns {Number[]} point in canvas coordinates
     */
    worldPointToCanvas(cameraPoseWorld, point) {
        // transform to camera system
        const pointInCameraCoords = cameraPoseWorld.applyInverseTo(point);

        // do projection to canvas
        return [
            pointInCameraCoords[0] + this.ctx.width / 2,
            -pointInCameraCoords[1] + this.ctx.height / 2
        ];

        // this is how perspective projection could be done
        // const fov = cameraPoseWorld.zoom * this.ctx.width;
        // const scale = fov / (fov + pointInCameraCoords[2]);
        // const projectedPoint = [
        //     pointInCameraCoords[0] * scale + this.ctx.width / 2,
        //     -pointInCameraCoords[1] * scale + this.ctx.height / 2
        // ];
        // return projectedPoint;
    }

    /**
     * Assumes a a top-down view where z-axis points out of the screen.
     * Centers the camera on the x and y axes so that all points are in the viewport.
     * The x and y offset are used when the points aren't wrt the (0,0) x-y origin.
     * If xRange and or yRange are already known, they can be given as well.
     *
     * @param {Number[][]} points
     * @param {Number} canvasWidth
     * @param {Number} canvasHeight
     * @param {Number=} xOffset default 0
     * @param {Number=} yOffset default 0
     * @param {Number[]=} xRange if not given the x range will be calculated: [minX, maxX]
     * @param {Number[]=} yRange if not given the y range will be calculated: [minY, maxY]
     */
    makeCameraCenterPoseForPointsXY(
        points,
        canvasWidth,
        canvasHeight,
        xOffset = 0,
        yOffset = 0,
        xRange = null,
        yRange = null
    ) {
        // makes sure that the outermost points don't lie directly on the
        // viewport's edge by virtually decreasing the canvas dimensions for the
        // calculation by some pixels
        const padding = 10;
        if (!xRange) {
            xRange = [min(points, 0)[0], max(points, 0)[0]];
        }
        if (!yRange) {
            yRange = [min(points, 0)[1], max(points, 0)[1]];
        }
        const totalXRange = Math.abs(xRange[1] - xRange[0]);
        const totalYRange = Math.abs(yRange[1] - yRange[0]);
        // at zoom 1: 1px equals 1 unit
        const zoom = Math.min(
            (canvasHeight - padding) / totalYRange,
            (canvasWidth - padding) / totalXRange
        );
        return new Pose3D(
            [
                xOffset + xRange[0] + totalXRange / 2,
                yOffset + yRange[0] + totalYRange / 2,
                0
            ],
            [0, 0, 0],
            zoom
        );
    }
}

/**
 * @typedef {Object} SphericalCanvasRendererSettings
 * @property {Number} alphaOffset
 * @property {Number} betaOffset
 * @property {Number} pxPerRadAlpha
 * @property {Number} pxPerRadBeta
 */

export class SphericalCanvasRenderer extends CanvasRenderer {
    /**
     * @type {CanvasRendererSettings & SphericalCanvasRendererSettings}
     */
    settings;

    /**
     * @override
     * @param {Pose3D} cameraPoseWorld
     * @param {CanvasRenderable} obj
     */
    projectObjectToCanvas(cameraPoseWorld, obj) {
        const points = obj.getPoints();
        const projectedPoints = [];
        for (const point of points) {
            // apply local transformation
            const pointWithLocalObjectTransformation = obj.poseWorld.applyTo(
                point
            );
            projectedPoints.push(
                this.worldPointToCanvas(
                    cameraPoseWorld,
                    pointWithLocalObjectTransformation
                )
            );
        }
        obj.setProjectedPoints(projectedPoints);

        // whenever the object is changed its shouldReProjectCanvasCoords
        // should be set to true
        obj.setShouldReProject(false);
    }

    /**
     * @param {Pose3D} cameraPoseWorld
     * @param {Number[]} point in world coordinates
     * @returns {Number[]} point in canvas coordinates
     */
    worldPointToCanvas(cameraPoseWorld, point) {
        // transform to camera system
        const pointInCameraCoords = cameraPoseWorld.applyInverseTo(point);

        const { alpha, beta } = this.getSphericalAnglesFromWorldPoint(
            pointInCameraCoords
        );
        const { sphericalParams } = this.settings;
        const u =
            (sphericalParams.alphaOffset + alpha) *
            sphericalParams.pxPerRadAlpha;
        const v =
            (sphericalParams.betaOffset - beta) * sphericalParams.pxPerRadBeta;

        if (this.settings.image) {
            const xScale =
                this.ctx.canvas.width / this.settings.image.naturalWidth;
            const yScale =
                this.ctx.canvas.height / this.settings.image.naturalHeight;
            return [u * xScale, v * yScale];
        }
        return [u, v];
    }

    /**
     * @param {Number[]} point
     */
    getSphericalAnglesFromWorldPoint(point) {
        // setting alpha=0 to the center of the pano image:
        // alpha = atan(x / y) [rad], alpha \in [-pi, pi)
        const alpha = Math.atan2(point[0], point[1]);

        // Distance on ground plane: sqrt(x^2 + y^2) [m]
        const distanceProjectedOnGroundPlane = Math.sqrt(
            point[0] * point[0] + point[1] * point[1]
        );
        // beta = atan(z/dist) [rad]
        const beta = Math.atan2(point[2], distanceProjectedOnGroundPlane);
        return { alpha, beta };
    }

    /**
     * Calculate the world coordinates of a canvas point corresponding to a reference world point.
     * Reference world point determines the distance which is necessary for correct z calculation.
     *
     * @param {Number[]} canvasPos in canvas coordinates
     * @param {Number[]} referenceWorldPos in world coordinates
     * @returns {Number[]} point in world coordinates
     */
    canvasPosToWorldPosAtReference(canvasPos, referenceWorldPos) {
        const { sphericalParams } = this.settings;
        let yScale = 1;
        let xScale = 1;
        // apply scale as defined by the image
        if (this.settings.image) {
            yScale = this.ctx.canvas.height / this.settings.image.naturalHeight;
            xScale = this.ctx.canvas.width / this.settings.image.naturalWidth;
        }

        // Distance on ground plane: sqrt(x^2 + y^2) [m]
        const dist = Math.sqrt(
            referenceWorldPos[0] * referenceWorldPos[0] +
                referenceWorldPos[1] * referenceWorldPos[1]
        );
        // alpha and beta depend on scale, offset and pixel per rad settings
        const alpha =
            canvasPos[0] / xScale / sphericalParams.pxPerRadAlpha -
            sphericalParams.alphaOffset;
        const beta = -(
            canvasPos[1] / yScale / sphericalParams.pxPerRadBeta -
            sphericalParams.betaOffset
        );
        // solve with trigonometry
        const worldPos = [
            Math.sin(alpha) * dist,
            Math.cos(alpha) * dist,
            Math.tan(beta) * dist
        ];
        return worldPos;
    }
}

/**
 * @typedef {Object} FisheyeCameraCanvasRendererSettings
 * @property {Number[]} distortionCoeffs
 * @property {Number[][]} K camera intrinsics
 */

export class FisheyeCameraCanvasRenderer extends CanvasRenderer {
    /**
     * @type {CanvasRendererSettings & FisheyeCameraCanvasRendererSettings}
     */
    settings;

    /**
     * Implementation of the detailled description of opencv fisheye camera model.
     * https://docs.opencv.org/4.5.1/db/d58/group__calib3d__fisheye.html
     *
     * @param {Number[]} pointInCameraCoords point in camera coordinate system
     * @param {Number[][]} K camera intrinsics
     * @param {Number[]} distortionCoeffs radial distortion coefficients. 4 are expected
     * @param {Number=} alpha default 0. skew coefficient
     */
    static fisheyeCameraProjectPoint(
        pointInCameraCoords,
        K,
        distortionCoeffs,
        alpha = 0
    ) {
        let distortedPoint = pointInCameraCoords;
        if (distortionCoeffs !== false) {
            const fisheyeDistortion = (k, r, theta, val) =>
                val *
                ((theta *
                    (1 +
                        k[0] * Math.pow(theta, 2) +
                        k[1] * Math.pow(theta, 4) +
                        k[2] * Math.pow(theta, 6) +
                        k[3] * Math.pow(theta, 8))) /
                    r);
            const a = pointInCameraCoords[0] / pointInCameraCoords[2];
            const b = pointInCameraCoords[1] / pointInCameraCoords[2];
            const r2 = a * a + b * b;
            const r = Math.sqrt(r2);
            const theta = Math.atan(r);
            distortedPoint = [
                fisheyeDistortion(distortionCoeffs, r, theta, a),
                fisheyeDistortion(distortionCoeffs, r, theta, b)
            ];
        }
        const projectedPoint = [
            K[0][0] * (distortedPoint[0] + alpha * distortedPoint[1]) + K[0][2],
            K[1][1] * distortedPoint[1] + K[1][2]
        ];
        return projectedPoint;
    }

    /**
     * Follows parts of the opencv.fisheye.undistortPoints implementation.
     * https://github.com/opencv/opencv/blob/fc1a15626226609babd128e043cf7c4e32f567ca/modules/calib3d/src/fisheye.cpp#L321
     *
     * @param {Number[]} pointInCameraCoords point in camera coordinate system where camera intrinsics are already undone
     * @param {Number[]} distortionCoeffs radial distortion coefficients. 4 are expected
     */
    static fisheyeCameraUndistortPoint(pointInCameraCoords, distortionCoeffs) {
        const EPS = 1e-8;

        let pw = pointInCameraCoords;
        let theta_d = Math.sqrt(pw[0] * pw[0] + pw[1] * pw[1]);

        // the current camera model is only valid up to 180 FOV
        // for larger FOV the loop below does not converge
        // clip values so we still get plausible results for super fisheye images > 180 grad
        theta_d = Math.min(Math.max(-Math.PI / 2, theta_d), Math.PI / 2);

        let converged = false;
        let theta = theta_d;
        let scale = 0.0;

        if (Math.abs(theta_d) > EPS) {
            // compensate distortion iteratively

            for (let j = 0; j < 10; j++) {
                let theta2 = theta * theta,
                    theta4 = theta2 * theta2,
                    theta6 = theta4 * theta2,
                    theta8 = theta6 * theta2;
                let k0_theta2 = distortionCoeffs[0] * theta2,
                    k1_theta4 = distortionCoeffs[1] * theta4,
                    k2_theta6 = distortionCoeffs[2] * theta6,
                    k3_theta8 = distortionCoeffs[3] * theta8;
                let theta_fix =
                    (theta *
                        (1 + k0_theta2 + k1_theta4 + k2_theta6 + k3_theta8) -
                        theta_d) /
                    (1 +
                        3 * k0_theta2 +
                        5 * k1_theta4 +
                        7 * k2_theta6 +
                        9 * k3_theta8);
                theta = theta - theta_fix;
                if (Math.abs(theta_fix) < EPS) {
                    converged = true;
                    break;
                }
            }

            scale = Math.tan(theta) / theta_d;
        } else {
            converged = true;
        }

        // theta is monotonously increasing or decreasing depending on the sign of theta
        // if theta has flipped, it might converge due to symmetry but on the opposite of the camera center
        // so we can check whether theta has changed the sign during the optimization
        const theta_flipped =
            (theta_d < 0 && theta > 0) || (theta_d > 0 && theta < 0);

        if (converged && !theta_flipped) {
            // undistorted point
            return [pw[0] * scale, pw[1] * scale];
        } else {
            return [-1000000.0, -1000000.0];
        }
    }

    /**
     * @override
     * @param {Pose3D} cameraPoseWorld
     * @param {CanvasRenderable} obj
     */
    projectObjectToCanvas(cameraPoseWorld, obj) {
        const points = obj.getPoints();
        const projectedPoints = [];
        for (const point of points) {
            // apply local transformation
            const pointWithLocalObjectTransformation = obj.poseWorld.applyTo(
                point
            );
            projectedPoints.push(
                this.worldPointToCanvas(
                    cameraPoseWorld,
                    pointWithLocalObjectTransformation
                )
            );
        }
        obj.setProjectedPoints(projectedPoints);

        // whenever the object is changed its shouldReProjectCanvasCoords
        // should be set to true
        obj.setShouldReProject(false);
    }

    /**
     * @param {Pose3D} cameraPoseWorld
     * @param {Number[]} point in world coordinates
     * @returns {Number[]} point in canvas coordinates
     */
    worldPointToCanvas(
        cameraPoseWorld,
        point,
        allowProjectBehindCamera = false
    ) {
        // transform to camera system
        const pointInCameraCoords = cameraPoseWorld.applyInverseTo(point);

        if (allowProjectBehindCamera) {
            if (pointInCameraCoords[2] === 0) {
                return null;
            }
        } else {
            // can't display point that's behind the camera
            if (pointInCameraCoords[2] <= 0) {
                return null;
            }
        }

        const { K, distortionCoeffs } = this.settings;
        return FisheyeCameraCanvasRenderer.fisheyeCameraProjectPoint(
            pointInCameraCoords,
            K,
            distortionCoeffs
        );
    }

    /**
     * @param {Pose3D} cameraPoseWorld
     * @param {Number[]} point in canvas coordinates
     * @returns {Number[]} point in world coordinates
     */
    canvasPosToWorldPos(cameraPoseWorld, point, z = 1) {
        const { K, distortionCoeffs } = this.settings;

        // undo camera intrinsics: multiplication with K_inverse
        const pointInCameraCoords = [
            (point[0] - K[0][2]) / K[0][0],
            (point[1] - K[1][2]) / K[1][1]
        ];

        // undo radial distortion
        const undistortedPoint = FisheyeCameraCanvasRenderer.fisheyeCameraUndistortPoint(
            pointInCameraCoords,
            distortionCoeffs
        );

        // set z value (adjust distance of virtual image plane from camera on which the point is projected)
        // default is z = 1 with x = x' / z and y = y' / z
        // --> multiply with z to get proper world points
        if (z !== 1) {
            undistortedPoint[0] *= z;
            undistortedPoint[1] *= z;
        }
        undistortedPoint.push(z);

        // project point from camera to world coordinates
        const pointInWorldCoords = cameraPoseWorld
            .applyTo(undistortedPoint)
            .slice(0, 3);

        return pointInWorldCoords;
    }
}
