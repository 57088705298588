import React, { useEffect, useState } from "react";

import CanvasScene from "../../lib/NRL/CanvasScene";
import { LayeredScene } from "../../lib/NRL/CanvasScene";
import { matMultMat, Pose3D } from "../../lib/NRL/Pose3D";
import { PointcloudRenderable } from "../../lib/NRL/Renderable";
import { makeInterpolatedLine } from "../../lib/NRL/RenderableFactory";
import { RENDERERS } from "../../lib/NRL/Renderer";
import { transformPoint2DFromDimensions } from "../../lib/qm_cs_lib";
import CameraViewZoom from "./CameraViewZoom";
import { KEYPOINT_STAGE } from "./KeypointStages";

/**
 *
 * @param {Object} props
 * @param {String} props.name
 * @param {Number[][]} props.E
 * @param {Number[][]} props.K
 * @param {Number[]} props.distortionCoeffs
 * @param {HTMLImageElement} props.image
 * @param {Number[][]} props.hmdWorldPose
 * @param {String} props.stage
 * @param {Number} props.keypointInitialDistanceM
 * @param {Number} props.keypointRadiusPx
 * @param {String} props.keypointColor
 * @param {String} props.keypointOutlineColor
 * @param {Number} props.keypointOutlineWidth
 * @param {Number} props.lineWidth
 * @param {String} props.lineColor
 * @param {Object} props.renderables
 * @param {() => {}} props.onResetKeypoint
 * @param {() => {}} props.onResetKeypointHover
 * @param {(cameraViewName, renderables) => {}} props.onKeypointClicked
 * @param {(cameraViewName, renderables) => {}} props.onKeypointHoverKeypointUpdate
 * @param {(cameraViewName, renderables) => {}} props.onKeypointHoverClicked
 * @param {Number} props.zoomLevel
 * @param {Boolean} props.withZoom
 * @param {Object=} props.zoomTranslation
 * @param {() => {}=} props.setZoomTranslation
 * @param {String | null} props.overriddenInitialViewClicked override which view was clicked as for initiating the keypoint (necessary for proper reset)
 */
export const CameraView = ({
    name,
    E,
    K,
    distortionCoeffs,
    image,
    hmdWorldPose,
    stage,
    keypointInitialDistanceM,
    keypointRadiusPx,
    keypointColor,
    keypointOutlineColor,
    keypointOutlineWidth,
    lineWidth,
    lineColor,
    renderables,
    onResetKeypoint,
    onResetKeypointHover,
    onKeypointClicked,
    onKeypointHoverKeypointUpdate,
    onKeypointHoverClicked,
    zoomLevel,
    withZoom,
    overriddenInitialViewClicked = null,
    zoomTranslation = null,
    setZoomTranslation = () => {}
}) => {
    const [sceneMap, setSceneMap] = useState(null);
    // eslint-disable-next-line
    const [originalCanvasDimensions, setOriginalCanvasDimensions] = useState({
        width: image.width,
        height: image.height
    });
    const [resizedCanvasDimensions, setResizedCanvasDimensions] = useState(
        null
    );
    const [initialViewClicked, setInitialViewClicked] = useState(
        overriddenInitialViewClicked
    );

    const setZoom = (scene, keypoint) => {
        let canvasBoxCenter = scene.renderer.worldPointToCanvas(
            scene.cameraPoseWorld,
            keypoint.poseWorld.pos
        );
        if (!canvasBoxCenter) {
            setZoomTranslation(null);
            return;
        }
        canvasBoxCenter = { x: canvasBoxCenter[0], y: canvasBoxCenter[1] };
        // top right camera view is rotated by 180°
        if (name === "top_right") {
            canvasBoxCenter.x =
                resizedCanvasDimensions.width - canvasBoxCenter.x;
            canvasBoxCenter.y =
                resizedCanvasDimensions.height - canvasBoxCenter.y;
        }
        const translation = calculateTranslation(canvasBoxCenter);
        setZoomTranslation(translation);
    };

    useEffect(() => {
        // remove old renderables from the scene and add new ones
        if (!sceneMap) {
            return;
        }
        const scene = sceneMap.get("point_line");
        // only get the renderables meant for this instance of CameraView
        const { keypoint, line } = renderables[name];

        if (scene.objects.has("keypoint")) {
            scene.deleteObject("keypoint");
        }
        if (scene.objects.has("line")) {
            scene.deleteObject("line");
        }
        if (keypoint) {
            scene.addObject(keypoint, "keypoint");
        }
        if (line) {
            scene.addObject(line, "line");
        }

        scene.renderer.clear();
        scene.render(true);

        // calculate zoom to keypoint (aka the box center) which is applicable when:
        // - this isn't the initial camera view that's clicked
        // - there's a keypoint renderable to zoom to
        // - there is no zoom, yet (Otherwise the zoomed area would move while hovering!)
        if (initialViewClicked !== name && keypoint && !zoomTranslation) {
            setZoom(scene, keypoint);
        }
    }, [sceneMap, renderables]);

    useEffect(() => {
        setInitialViewClicked(overriddenInitialViewClicked);
    }, [overriddenInitialViewClicked]);

    /**
     * Calculate translation coordinates for zoom on resized canvas for projectedPoint.
     */
    const calculateTranslation = projectedPoint => {
        const translation = {
            x: resizedCanvasDimensions.width / 2 - projectedPoint.x,
            y: resizedCanvasDimensions.height / 2 - projectedPoint.y
        };

        // max-translation to ensure that the translation does not push the image out of frame
        const maxXtranslation =
            ((zoomLevel - 1) / zoomLevel) * (resizedCanvasDimensions.width / 2);
        const maxYtranslation =
            ((zoomLevel - 1) / zoomLevel) *
            (resizedCanvasDimensions.height / 2);

        if (Math.abs(translation.x) > maxXtranslation) {
            translation.x = Math.sign(translation.x) * maxXtranslation;
        }
        if (Math.abs(translation.y) > maxYtranslation) {
            translation.y = Math.sign(translation.y) * maxYtranslation;
        }
        return translation;
    };

    const layeredScene = (
        <LayeredScene
            style={{
                transform: name === "top_right" ? "rotate(180deg)" : "none",
                cursor: "crosshair"
            }}
            setSceneMap={sceneMapFromLayeredScene => {
                // TODO: this throws a warning because the render func of LayeredScene calls this function
                //       and this function updates the state of CanvasScene
                setSceneMap(sceneMapFromLayeredScene);
            }}
        >
            <CanvasScene
                name="bg_image"
                width={image.width}
                height={image.height}
                renderer={RENDERERS.FISHEYE_CANVAS}
                rendererSettings={{
                    distortionCoeffs: distortionCoeffs,
                    K: K
                }}
                init={scene => {
                    /**
                     * @type {CanvasRenderingContext2D}
                     */
                    const ctx = scene.renderer.ctx;
                    setResizedCanvasDimensions({
                        width: ctx.canvas.offsetWidth,
                        height: ctx.canvas.offsetHeight
                    });
                    scene.cameraPoseWorld = Pose3D.makePoseFromHtm(
                        matMultMat(hmdWorldPose, E)
                    );

                    // draw image scaled to the canvas
                    ctx.drawImage(
                        image,
                        0,
                        0,
                        image.naturalWidth,
                        image.naturalHeight,
                        0,
                        0,
                        ctx.width,
                        ctx.height
                    );
                }}
                canvasProps={{ style: { display: "block" } }}
            />
            <CanvasScene
                name="point_line"
                width={image.width}
                height={image.height}
                renderer={RENDERERS.FISHEYE_CANVAS}
                rendererSettings={{
                    distortionCoeffs: distortionCoeffs,
                    K: K
                }}
                init={scene => {
                    scene.cameraPoseWorld = Pose3D.makePoseFromHtm(
                        matMultMat(hmdWorldPose, E)
                    );
                }}
                canvasProps={{
                    style: { display: "block" },
                    onMouseDown: e => {
                        const scene = sceneMap.get("point_line");
                        if (initialViewClicked === name) {
                            // reset to before keypoint was added with first click
                            setInitialViewClicked(null);
                            onResetKeypoint();
                        } else if (
                            stage === KEYPOINT_STAGE.UPDATE_KEYPOINT_CLICKED
                        ) {
                            // reset to keypoint hovering
                            onResetKeypointHover();
                        } else if (
                            stage === KEYPOINT_STAGE.UPDATE_KEYPOINT_HOVERING
                        ) {
                            const point = scene.getObject("keypoint");
                            const epipolarLine = scene.getObject("line");

                            // we have to re-render here because we access the projectedPoints
                            // of the keypoint here and the keypoint renderable originates from a different
                            // camera view that has different projections, but we must make sure we use
                            // the projectections for this camera view to have a proper selection.
                            scene.render(true);

                            // only handle the click if the point is visible on the canvas
                            const projectedPoint = point.projectedPoints[0];
                            if (
                                !projectedPoint ||
                                projectedPoint[0] < 0 ||
                                projectedPoint[0] > scene.renderer.ctx.width ||
                                projectedPoint[1] < 0 ||
                                projectedPoint[1] > scene.renderer.ctx.height
                            ) {
                                return;
                            }
                            onKeypointHoverClicked(name, {
                                keypoint: point,
                                line: epipolarLine
                            });
                        } else if (stage === KEYPOINT_STAGE.SET_KEYPOINT) {
                            const mouseCanvas = {
                                x: e.nativeEvent.offsetX,
                                y: e.nativeEvent.offsetY
                            };
                            let mouseCanvasPos = transformPoint2DFromDimensions(
                                mouseCanvas,
                                resizedCanvasDimensions,
                                originalCanvasDimensions
                            );
                            mouseCanvasPos = [
                                mouseCanvasPos.x,
                                mouseCanvasPos.y
                            ];
                            const mouseWorldPos = scene.renderer.canvasPosToWorldPos(
                                scene.cameraPoseWorld,
                                mouseCanvasPos,
                                keypointInitialDistanceM
                            );

                            // make keypoint and line renderables
                            const keypoint = new PointcloudRenderable(
                                new Pose3D(mouseWorldPos),
                                [[0, 0, 0]],
                                {
                                    radius: keypointRadiusPx,
                                    fillStyle: keypointColor,
                                    strokeStyle: keypointOutlineColor,
                                    lineWidth: keypointOutlineWidth
                                }
                            );
                            const epipolarLine = makeInterpolatedLine(
                                new Pose3D(),
                                scene.cameraPoseWorld.pos.slice(0, 3),
                                mouseWorldPos.slice(0, 3),
                                250,
                                {
                                    lineWidth: lineWidth,
                                    strokeStyle: lineColor
                                }
                            );

                            // give renderables to parent
                            onKeypointClicked(name, {
                                keypoint,
                                line: epipolarLine
                            });

                            // remember this view as the initial click view
                            if (!initialViewClicked) {
                                setInitialViewClicked(name);
                            }
                        }
                    },
                    onMouseMove: e => {
                        if (stage !== KEYPOINT_STAGE.UPDATE_KEYPOINT_HOVERING) {
                            return;
                        }
                        const scene = sceneMap.get("point_line");
                        const point = scene.getObject("keypoint");
                        const epipolarLine = scene.getObject("line");
                        if (!point || !epipolarLine) {
                            return;
                        }
                        // we have to re-render here because we access the projectedPoints
                        // of the epipolarLine here and the line renderable originates from a different
                        // camera view that has different projections, but we must make sure we use
                        // the projectections for this camera view to have a proper selection.
                        scene.render(true);

                        const mouseCanvas = {
                            x: e.nativeEvent.offsetX,
                            y: e.nativeEvent.offsetY
                        };
                        let mouseCanvasPos = transformPoint2DFromDimensions(
                            mouseCanvas,
                            resizedCanvasDimensions,
                            originalCanvasDimensions
                        );
                        mouseCanvasPos = [mouseCanvasPos.x, mouseCanvasPos.y];

                        // find closest projected point of interpolated epipolar line to the mouse
                        let closestPointIdx = -1;
                        let closestDist = 999999999;
                        for (
                            let i = 0;
                            i < epipolarLine.projectedPoints.length;
                            i++
                        ) {
                            const projectedPoint =
                                epipolarLine.projectedPoints[i];
                            if (projectedPoint === null) {
                                continue;
                            }
                            const diff = [
                                mouseCanvasPos[0] - projectedPoint[0],
                                mouseCanvasPos[1] - projectedPoint[1]
                            ];
                            const dist = Math.sqrt(
                                diff[0] * diff[0] + diff[1] * diff[1]
                            );
                            if (dist < closestDist) {
                                closestDist = dist;
                                closestPointIdx = i;
                            }
                        }

                        if (closestPointIdx > -1) {
                            const closestWorldPoint =
                                epipolarLine.points[closestPointIdx];
                            point.poseWorld.setPosition(closestWorldPoint);
                            scene.render(true);

                            // update the keypoint renderable for all the other views
                            onKeypointHoverKeypointUpdate(name, {
                                keypoint: point,
                                line: epipolarLine
                            });
                        }
                    }
                }}
            />
        </LayeredScene>
    );
    if (withZoom && zoomTranslation) {
        return (
            <CameraViewZoom
                zoomParameters={{
                    scale: zoomLevel,
                    translation: zoomTranslation
                }}
            >
                {layeredScene}
            </CameraViewZoom>
        );
    }
    return layeredScene;
};
export default CameraView;
