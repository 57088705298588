import React from "react";
import RectDiv from "./RectDiv";

import { IoIosResize } from "react-icons/io";
import { TiCancel } from "react-icons/ti";

export const CrosshairCursor = props => {
    const crosshairPos = props.crosshairPos;
    const crosshairWidth = props.crosshairWidth;
    const crosshairColor = props.crosshairColor;
    const dimensions = props.dimensions;
    const currentCursor = props.currentCursor;
    const disableCrosshair = props.disableCrosshair;
    const cursorIconSize = 30;

    let cursor = null;
    switch (currentCursor) {
        case "topLeft":
            cursor = (
                <IoIosResize
                    size={cursorIconSize}
                    color={"purple"}
                    style={{ transform: "scaleX(-1)" }}
                />
            );
            break;
        case "topRight":
            cursor = <IoIosResize size={cursorIconSize} color={"purple"} />;
            break;
        case "botLeft":
            cursor = <IoIosResize size={cursorIconSize} color={"purple"} />;
            break;
        case "botRight":
            cursor = (
                <IoIosResize
                    size={cursorIconSize}
                    color={"purple"}
                    style={{ transform: "scaleX(-1)" }}
                />
            );
            break;
        case "end_adjustment":
            cursor = <TiCancel size={cursorIconSize} color={"red"} />;
            break;
        default:
            cursor = null;
            break;
    }

    return crosshairPos ? (
        <div>
            <div
                style={{
                    position: "absolute",
                    top: crosshairPos.y - cursorIconSize / 2,
                    left: crosshairPos.x - cursorIconSize / 2,
                    zIndex: 5
                }}
            >
                {cursor}
            </div>
            {disableCrosshair ? null : (
                <div>
                    <RectDiv
                        id="crosshair_y"
                        topLeftPos={{
                            x: -3,
                            y: 0
                        }}
                        botRightPos={{
                            x: crosshairPos.x + crosshairWidth / 2,
                            y: dimensions.height + crosshairWidth
                        }}
                        style={{
                            border: "none",
                            borderRight: `${crosshairWidth}px solid ${crosshairColor}`,
                            opacity: 1,
                            background: "none",
                            zIndex: 2
                        }}
                    />
                    <RectDiv
                        id="crosshair_x"
                        topLeftPos={{
                            x: -crosshairWidth,
                            y: -crosshairWidth
                        }}
                        botRightPos={{
                            x: dimensions.width + crosshairWidth,
                            y: crosshairPos.y + crosshairWidth / 2
                        }}
                        style={{
                            border: "none",
                            borderBottom: `${crosshairWidth}px solid ${crosshairColor}`,
                            opacity: 1,
                            background: "none",
                            zIndex: 2
                        }}
                    />
                </div>
            )}
        </div>
    ) : null;
};

export default CrosshairCursor;
