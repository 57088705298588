import React from "react";

class Draggable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            grabbing: false,
            clickOffset: null
        };
        this.boundHandleMouseMove = this.handleMouseMove.bind(this);
        this.boundHandleMouseUp = this.handleMouseUp.bind(this);
        this.boundHandleMouseDown = this.handleMouseDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousemove", this.boundHandleMouseMove);
        document.addEventListener("mouseup", this.boundHandleMouseUp);
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.boundHandleMouseMove);
        document.removeEventListener("mouseup", this.boundHandleMouseUp);
    }

    /**
     * @param {MouseEvent} e already native event because handler is directly on DOM
     */
    handleMouseMove(e) {
        if (!this.state.grabbing) {
            return;
        }
        const { pos, onMouseMove, frameOffset, innerOffset } = this.props;
        const client = { x: e.clientX, y: e.clientY };
        const { clickOffset } = this.state;

        // transform mouse screen position (e.clientX) to
        // position in frame (also include borderWidth)
        const mouseInFrame = {
            x: client.x - frameOffset.x - frameOffset.borderWidth,
            y: client.y - frameOffset.y - frameOffset.borderWidth
        };

        // transform to proper square corner
        const newPos = {
            x: mouseInFrame.x - innerOffset.x - clickOffset.x,
            y: mouseInFrame.y - innerOffset.y - clickOffset.y
        };

        // check if new pos in range and update x,y independently from each other.
        // snaps to axis range boundary if not in range
        const { xInRange, yInRange, xBoundary, yBoundary } = this.isInRange(
            newPos
        );
        const inRangePos = { ...pos };
        if (xInRange) {
            inRangePos.x = newPos.x;
        } else {
            inRangePos.x = xBoundary;
        }
        if (yInRange) {
            inRangePos.y = newPos.y;
        } else {
            inRangePos.y = yBoundary;
        }
        onMouseMove(inRangePos);
    }

    /**
     * @param {MouseEvent} e already native event because handler is directly on DOM
     */
    handleMouseUp(e) {
        if (this.state.grabbing) {
            this.props.onDragFinished();
            this.setState({ grabbing: false });
        }
    }

    /**
     * @param {import("react").MouseEvent} e react event because handler is on react element
     */
    handleMouseDown(e) {
        this.setState({
            grabbing: true,
            clickOffset: { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }
        });
    }

    /**
     * Checks whether position is in allowed range. If it exceeds a boundary for an axis,
     * the boundary value will also be returned so the pos can be snapped to that.
     */
    isInRange(pos) {
        const { x: xr, y: yr } = this.props.posRange;
        let xBoundary = null;
        let yBoundary = null;
        let xInRange = true;
        let yInRange = true;

        // check if x outside of range
        if (pos.x < xr[0]) {
            xBoundary = xr[0];
            xInRange = false;
        }
        if (pos.x > xr[1]) {
            xBoundary = xr[1];
            xInRange = false;
        }
        // check if y outside of range
        if (pos.y < yr[0]) {
            yBoundary = yr[0];
            yInRange = false;
        }
        if (pos.y > yr[1]) {
            yBoundary = yr[1];
            yInRange = false;
        }

        return { xInRange, yInRange, xBoundary, yBoundary };
    }

    render() {
        const { grabbing } = this.state;
        const { pos, innerOffset, sideLen, style, id } = this.props;

        return (
            <div
                id={id ? id : null}
                style={{
                    position: "absolute",
                    top: pos.y + innerOffset.y,
                    left: pos.x + innerOffset.x,
                    width: sideLen,
                    height: sideLen,
                    cursor: grabbing ? "grabbing" : "grab",
                    background: "gray",
                    ...style
                }}
                onMouseDown={this.boundHandleMouseDown}
            ></div>
        );
    }
}

export default Draggable;
