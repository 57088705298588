import { useEffect } from "react";
import { useState, useLayoutEffect } from "react";

/**
 * Custom hook to get the current container-qm and viewPort dimensions (px).
 * Will update only when the key changes.
 * Detects when container-qm is resized initially and updates its state then.
 * @param {any} key should be sth. like currentTaskIdx.
 */
export const useContainerQMDimensions = key => {
    const [containerQMDimensions, setContainerQMDimensions] = useState({
        width: 0,
        height: 0
    });
    const [viewPortDimensions, setViewPortDimensions] = useState({
        width: 0,
        height: 0
    });

    useEffect(() => {
        const containerQm = document.querySelector(".container-qm");
        const resizeObserver = new ResizeObserver(entries => {
            // stop observing as soon as resizing has reached viewPort's width or height
            // but allow 10% leeway
            if (
                (containerQm.offsetHeight <= viewPortDimensions.height &&
                    containerQm.offsetHeight >=
                        viewPortDimensions.height * 0.9) ||
                (containerQm.offsetWidth <= viewPortDimensions.width &&
                    containerQm.offsetWidth >= viewPortDimensions.width * 0.9)
            ) {
                resizeObserver.unobserve(containerQm);
                return;
            }
            setContainerQMDimensions({
                width: containerQm.offsetWidth,
                height: containerQm.offsetHeight
            });
        });
        resizeObserver.observe(containerQm);
        return () => resizeObserver.unobserve(containerQm);
    }, [viewPortDimensions]);

    useLayoutEffect(() => {
        setViewPortDimensions({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }, [key]);
    return [containerQMDimensions, viewPortDimensions];
};

/**
 * Calls a custom callback with all necessary resize data on resize of container-qm.
 * @param {any} key should be sth. like currentTaskIdx.
 * @param {(containerQMDimensions: import("../lib/qm_cs_lib").Dimension2D, viewPortDimensions: import("../lib/qm_cs_lib").Dimension2D, containerQm: HTMLDivElement, resizeObserver: ResizeObserver) =>  {}} onContainerResized
 */
export const useCustomContainerQMResizedCallback = (
    key,
    onContainerResized
) => {
    useLayoutEffect(() => {
        const containerQm = document.querySelector(".container-qm");
        const resizeObserver = new ResizeObserver(entries => {
            const containerQmDimensions = {
                width: containerQm.offsetWidth,
                height: containerQm.offsetHeight
            };
            const viewPortDimensions = {
                width: window.innerWidth,
                height: window.innerHeight
            };
            onContainerResized(
                containerQmDimensions,
                viewPortDimensions,
                containerQm,
                resizeObserver
            );
        });
        onContainerResized(
            { width: 0, height: 0 },
            { width: 0, height: 0 },
            containerQm,
            resizeObserver
        );
        resizeObserver.observe(containerQm);
        return () => {
            resizeObserver.unobserve(containerQm);
        };
        // eslint-disable-next-line
    }, [key]);
};
