import React, { useCallback, useEffect, useMemo, useState } from "react";
import Form from "@rjsf/bootstrap-4";
import { createSchema } from "genson-js";
import { flatten, unflatten } from "flat";
import deepmerge from "deepmerge";
import { Badge, Button, ButtonGroup, DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import deepEqual from "deep-equal";

import ClearPageLayout from "../../guitypes/Layouts/ClearPageLayout";
import {
    copyTextToClipboard,
    deepMergeSharedKeys,
    getFormattedTodayDate,
    parseObjectFromJsonString,
    stringTruthiness,
    triggerJsonObjectDownload
} from "../../lib/qm_cs_lib";

import "./MockDataForm.css";

const MOCK_DATA_FORM_STORED_SETTINGS = "mockDataFormStoredSettings";

/**
 * @param {Object} props
 * @param {import("../../guitypes/MockData/MockData").MockData} props.mockData
 * @param {(newMockData: import("../../guitypes/MockData/MockData").MockData) =>  {}} props.onDoneChangingMockData
 * @param {URLSearchParams | Map<String | String>} props.actualQueryParams
 */
export const MockDataForm = ({
    mockData,
    onDoneChangingMockData,
    actualQueryParams
}) => {
    const [guiSettingsSchema, setGuiSettingsSchema] = useState(null);
    const [guiSettingsFormData, setGuiSettingsFormData] = useState(null);
    const [taskInputsFormData, setTaskInputsFormData] = useState(null);
    const [taskInputsSchema, setTaskInputsSchema] = useState(null);
    const [storedSettings, setStoredSettings] = useState(
        JSON.parse(localStorage.getItem(MOCK_DATA_FORM_STORED_SETTINGS))
    );
    const [dataSourceInfo, setDataSourceInfo] = useState("mock data");
    const [initialLoadDone, setInitialLoadDone] = useState(false);

    const guiType = mockData.mockApiClient.getTaskGroupResult.gui_type;

    // gui_settings and task_inputs from queryparams
    const queryParamGuiSettings = parseObjectFromJsonString(
        decodeURIComponent(actualQueryParams.get("gui_settings"))
    );
    const queryParamTaskInputs = parseObjectFromJsonString(
        decodeURIComponent(actualQueryParams.get("task_inputs"))
    );
    const autostart = stringTruthiness(
        decodeURIComponent(actualQueryParams.get("autostart"))
    );

    useEffect(() => {
        // Deep-Merge guisettings from mockdata and queryparams.
        // Queryparams as source take precedence.
        let usedGuiSettings =
            mockData.mockApiClient.getTaskGroupResult.gui_settings;
        if (
            queryParamGuiSettings &&
            typeof queryParamGuiSettings === "object"
        ) {
            usedGuiSettings = deepMergeSharedKeys(
                usedGuiSettings,
                queryParamGuiSettings
            );
        }

        // TaskInputs from queryparams override taskinputs from mockData completely.
        // TaskInputs have to be objects.
        let usedTaskInputs =
            mockData.mockApiClient.getTaskGroupResult.task_inputs;
        if (queryParamTaskInputs && Array.isArray(queryParamTaskInputs)) {
            usedTaskInputs = queryParamTaskInputs.filter(
                ti => typeof ti === "object"
            );
        }

        // gui settings schema
        setGuiSettingsFormData(usedGuiSettings);
        const gsSchema = createSchema(usedGuiSettings);
        gsSchema.title = "Gui Settings";
        if (gsSchema.properties.instructions) {
            gsSchema.properties.instructions = {
                type: "array",
                items: { type: "string" }
            };
        }
        setGuiSettingsSchema(gsSchema);

        // task input schema
        const tiSchemas = createSchema(usedTaskInputs);
        tiSchemas.title = "Task Inputs";
        setTaskInputsSchema(tiSchemas);
        setTaskInputsFormData(usedTaskInputs);
        // eslint-disable-next-line
    }, [mockData]);

    // handle changing form data
    const onChange = useCallback(data => {
        if (data.schema.title === "Gui Settings") {
            setGuiSettingsFormData(data.formData);
        } else if (data.schema.title === "Task Inputs") {
            setTaskInputsFormData(data.formData);
        }

        setDataSourceInfo("edited");
    }, []);

    const updateStoredSettings = () => {
        let newStoredSettings = storedSettings;
        if (!newStoredSettings) {
            newStoredSettings = { [guiType]: [] };
        }
        if (!newStoredSettings[guiType]) {
            newStoredSettings[guiType] = [];
        }

        // don't add data if latest data didn't change anything
        if (newStoredSettings[guiType].length > 0) {
            if (
                deepEqual(
                    guiSettingsFormData,
                    newStoredSettings[guiType][0].guiSettings
                ) &&
                deepEqual(
                    taskInputsFormData,
                    newStoredSettings[guiType][0].taskInputs
                )
            ) {
                return;
            }
        }

        // prepend newest
        newStoredSettings[guiType].unshift({
            guiSettings: guiSettingsFormData,
            taskInputs: taskInputsFormData
        });
        // remove oldest: keep 5 max
        newStoredSettings[guiType] = newStoredSettings[guiType].slice(0, 5);
        localStorage.setItem(
            MOCK_DATA_FORM_STORED_SETTINGS,
            JSON.stringify(newStoredSettings)
        );
        setStoredSettings(newStoredSettings);
    };

    const onDone = () => {
        updateStoredSettings();
        onDoneChangingMockData(guiSettingsFormData, taskInputsFormData);

        setInitialLoadDone(true);
        // update the queryparam for autostart to be false, because reloaing the mock data form would autosubmit otherwise.
        if (autostart) {
            actualQueryParams.set("autostart", false);
        }
    };

    const triggerDownloadCurrentMockData = () => {
        const today = getFormattedTodayDate();
        const filename = `mockdata_guisettings_taskinputs-${guiType}_${today}`;
        triggerJsonObjectDownload(
            {
                guiSettings: guiSettingsFormData,
                taskInputs: taskInputsFormData
            },
            filename,
            4
        );
    };

    const copyCurrentMockDataToClipboard = () => {
        let url = window.location.protocol + "//" + window.location.host;
        url += "?debug_mode&gui_type=" + guiType;
        url += "&gui_settings=" + JSON.stringify(guiSettingsFormData);
        url += "&task_inputs=" + JSON.stringify(taskInputsFormData);
        url += "&autostart=" + autostart;
        url = encodeURI(url);
        copyTextToClipboard(url);
    };

    const guiSettingsUISchema = useMemo(
        () => ({
            instructions: {
                items: {
                    "ui:widget": "textarea"
                }
            },
            ...makeUISchemaWidgetsForData(guiSettingsFormData)
        }),
        [guiSettingsFormData]
    );
    const taskInputsUISchema = useMemo(
        () => makeUISchemaWidgetsForData(taskInputsFormData),
        [taskInputsFormData]
    );

    if (!guiSettingsSchema || !taskInputsSchema) {
        return null;
    }

    // submit mock data form automatically
    if (!initialLoadDone && autostart) {
        onDone();
    }

    return (
        <ClearPageLayout>
            <div
                style={{
                    margin: "auto",
                    backgroundColor: "#EEE",
                    width: "90%",
                    padding: "1%"
                }}
            >
                <h1 style={{ marginBottom: "20px" }}>
                    Update of Gui Settings and Task Inputs
                </h1>
                <ButtonGroup style={{ marginBottom: "20px" }}>
                    <Button onClick={onDone}>
                        Start UI With These Settings
                    </Button>
                    <Button
                        style={{ marginLeft: "10px" }}
                        onClick={triggerDownloadCurrentMockData}
                    >
                        Download Current MockData
                    </Button>
                    <Button
                        style={{ marginLeft: "10px" }}
                        onClick={copyCurrentMockDataToClipboard}
                    >
                        Copy Current MockData as URL to Clipboard
                    </Button>
                    {storedSettings && storedSettings[guiType] ? (
                        <DropdownButton
                            title={`Last ${storedSettings[guiType].length} Settings`}
                            style={{ marginLeft: "10px" }}
                        >
                            {storedSettings[guiType].map((settings, idx) => (
                                <DropdownItem
                                    key={idx}
                                    onSelect={() => {
                                        setGuiSettingsFormData(
                                            settings.guiSettings
                                        );
                                        setTaskInputsFormData(
                                            settings.taskInputs
                                        );
                                        setDataSourceInfo("stored idx " + idx);
                                    }}
                                >
                                    {idx}
                                    {idx === 0 ? " (latest)" : ""}
                                </DropdownItem>
                            ))}
                        </DropdownButton>
                    ) : null}
                    <Badge>current from: {dataSourceInfo}</Badge>
                </ButtonGroup>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Form
                        schema={guiSettingsSchema}
                        formData={guiSettingsFormData}
                        uiSchema={guiSettingsUISchema}
                        onChange={onChange}
                        className="mock_data_form"
                        liveValidate
                    >
                        {/* hide submit button */}
                        <button style={{ display: "none" }}></button>
                    </Form>
                    <Form
                        schema={taskInputsSchema}
                        formData={taskInputsFormData}
                        uiSchema={taskInputsUISchema}
                        onChange={onChange}
                        className="mock_data_form"
                        liveValidate
                    >
                        {/* hide submit button */}
                        <button style={{ display: "none" }}></button>
                    </Form>
                </div>
            </div>
        </ClearPageLayout>
    );
};

/**
 * helper function for ui:widget colorpicker and ui:widget that enforces input[type=url]
 */
const makeUISchemaWidgetsForData = data => {
    if (!data) {
        return {};
    }

    const colorWidgetUISchema = makeUISchemaForArrayNestedField(
        data,
        "color",
        "ui:widget",
        "color",
        false
    );
    const uriWidgetUISchema = makeUISchemaForArrayNestedField(
        data,
        "url",
        "ui:widget",
        "uri",
        false
    );
    return deepmerge(colorWidgetUISchema, uriWidgetUISchema);
};

/**
 * Finds a nested object field by name and creates
 * an rsjf uiSchema with ui:[option] definition for that
 */
const makeUISchemaForArrayNestedField = (
    obj,
    fieldName,
    fieldOption,
    fieldOptionValue,
    fieldNameEndsWith = true
) => {
    // flatten the object, search in the generated keys, update the keys and unflatten
    const flattened = flatten(obj);
    let uiSchema = {};

    Object.keys(flattened)
        .filter(key =>
            fieldNameEndsWith
                ? key.endsWith(`.${fieldName}`)
                : key.includes(fieldName)
        )
        .forEach(
            key =>
                (uiSchema[
                    fieldNameEndsWith
                        ? // simpler to understand replace when word ends with fieldname
                          key.replace(
                              `.${fieldName}`,
                              `.items.${fieldName}.${fieldOption}`
                          )
                        : // replace when inside word
                          key.replace(
                              new RegExp("\\.\\w*" + fieldName + "\\w*$", "i"),
                              ".items$&." + fieldOption
                          )
                ] = fieldOptionValue)
        );

    uiSchema = unflatten(uiSchema);
    if (Array.isArray(obj)) {
        uiSchema = uiSchema[0];
    } else {
        Object.keys(uiSchema).forEach(key => {
            if (Array.isArray(uiSchema[key])) {
                uiSchema[key] = uiSchema[key][0];
            }
        });
    }
    return uiSchema;
};

export default MockDataForm;
