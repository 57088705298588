import React, { useEffect, useState } from "react";
import PubSub from "pubsub-js";
import { Col, Container, Row } from "react-bootstrap";

import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import {
    BoxDimensions,
    CrossDimensions
} from "../../lib/NRL/RenderableFactory";
import { PointcloudBEV } from "./PointcloudBEV";
import { PointcloudRGB } from "./PointcloudRGB";
import { AnnotationContext } from "./AnnotationContext";
import withWaitForResourcesWrapper from "../util/withWaitForResourcesWrapper";

import "./PointcloudCanvasScene.css";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
const InternalRGBAndBEVView = ({
    taskUIContext,
    currentTaskIdx,
    resourceCache,
    guiSettings,
    setCurrentTaskOutput,
    type,
    taskInput,
    setTaskSubmitEnabled
}) => {
    // data that needs to be preprocessed or comes from the protobuf file
    const [preprocessedPoints, setPreprocessedPoints] = useState([]);
    const [rgbImageBoundingBox2D, setRGBImageBoundingBox2D] = useState(null);
    const [sphericalParams, setSphericalParams] = useState(null);
    // context object to store box/cog adjustments
    const [annotationContext, setAnnotationContext] = useState(null);

    const {
        // settings for bev and many are also used in rgb
        bg_color: bgColor,
        cross_dims: crossDimsRaw,
        cog_color: cogColor,
        cog_line_width: cogLineWidth,
        transform_fit: transformFit,
        initial_zoom: initialZoom,
        point_radius: pointRadius,
        color_map: colormap,
        mouse_point_highlight_dist: mousePointHighlightDist,
        point_opacity: pointOpacity,
        highlighted_point_radius: highlightedPointRadius,
        highlighted_point_opactiy: highlightedPointOpacity,
        box_intersection_line_width: boxIntersectionLineWidth,
        box_intersection_color: boxIntersectionColor,
        existing_box_line_width: existingBoxLineWidth,
        existing_box_color: existingBoxColor,
        // rgb only settings
        rgb_mouse_point_highlight_canvas_dist: rgbMousePointHighlightCanvasDist,
        rgb_image_bbox2d_line_width: rgbImageBoundingBox2DLineWidth,
        rgb_image_bbox2d_color: rgbImageBoundingBox2DColor,
        rgb_point_radius: rgbPointRadius,
        rgb_point_opacity: rgbPointOpacity,
        rgb_highlighted_point_radius: rgbHighlightedPointRadius,
        rgb_highlighted_point_opacity: rgbHighlightedPointOpacity,
        // bbox settings for both bev and rgb
        minimal_box_dims: minimalBoxDimsRaw,
        box_line_width: boxLineWidth,
        box_color: boxColor,
        arrow_visible: arrowVisible,
        arrow_line_width: arrowLineWidth,
        arrow_color: arrowColor,
        cursor_size: cursorSize,
        cursor_color: cursorColor,
        // general settings
        max_points_cnt: maxPointsCnt
    } = guiSettings;

    const currentImage = resourceCache[taskInput.image_url];

    // initial pre-processing for every task
    useEffect(() => {
        const pcData = resourceCache[taskInput.pb_data_url];
        const pcDataPoints = pcData.pointsTableRowList;
        if (!pcDataPoints) {
            throw new Error("invalid pointcloud data");
        }

        // // only take every nth point so that we have fewer points
        let usedPoints = [];
        if (pcDataPoints.length <= maxPointsCnt) {
            usedPoints = pcDataPoints;
        } else {
            const step = Math.round(pcDataPoints.length / maxPointsCnt);
            for (let i = 0; i < pcDataPoints.length; i += step) {
                usedPoints.push(pcDataPoints[i]);
            }
        }

        // sort points for bev by z axis increasing
        const points = usedPoints.map(p => [p.x, p.y, p.z]);
        points.sort((a, b) => a[2] - b[2]);
        setPreprocessedPoints(points);
        setSphericalParams(pcData.rgbSphericalParams);
        setRGBImageBoundingBox2D({
            left: pcData.metaData.relativeBoxLeft,
            top: pcData.metaData.relativeBoxTop,
            right: pcData.metaData.relativeBoxRight,
            bottom: pcData.metaData.relativeBoxBottom
        });

        const initialCogPos = [
            taskInput.initial_cog_pos.x,
            taskInput.initial_cog_pos.y,
            taskInput.initial_cog_pos.z
        ];
        const existingBoxes = [];
        let crossDims, initialBoxDims, minimalBoxDims, initialBoxOrientation;
        if (type === "cog") {
            crossDims = new CrossDimensions(
                crossDimsRaw.a_line_len,
                crossDimsRaw.b_line_len
            ); // required when type is cog
        } else if (type === "box") {
            const initialBoxDimsRaw = taskInput.initial_box_dims;
            initialBoxOrientation = taskInput.initial_box_orientation;
            initialBoxDims = new BoxDimensions(
                initialBoxDimsRaw.front,
                initialBoxDimsRaw.back,
                initialBoxDimsRaw.left,
                initialBoxDimsRaw.right,
                initialBoxDimsRaw.top,
                initialBoxDimsRaw.bottom
            ); // required when type is box
            minimalBoxDims = new BoxDimensions(
                minimalBoxDimsRaw.front,
                minimalBoxDimsRaw.back,
                minimalBoxDimsRaw.left,
                minimalBoxDimsRaw.right,
                minimalBoxDimsRaw.top,
                minimalBoxDimsRaw.bottom
            ); // required when type is box
            // populate existing boxes
            if (Array.isArray(taskInput.boxes) && taskInput.boxes.length > 0) {
                for (const box of taskInput.boxes) {
                    existingBoxes.push({
                        cog: [box.cog.x, box.cog.y, box.cog.z],
                        orientation: box.orientation,
                        boxDims: new BoxDimensions(
                            box.box.front,
                            box.box.back,
                            box.box.left,
                            box.box.right,
                            box.box.top,
                            box.box.bottom
                        )
                    });
                }
            }
        }
        const ac = new AnnotationContext(
            initialBoxDims,
            minimalBoxDims,
            initialCogPos,
            initialBoxOrientation
        );
        ac.crossDims = crossDims;
        ac.taskIdx = currentTaskIdx;
        ac.existingBoxes = existingBoxes;
        setAnnotationContext(ac);

        PubSub.clearAllSubscriptions();

        // start a timeout to enable the taskSubmit for the current task
        // after some time
        const enableTaskSubmitTimeOut = window.setTimeout(() => {
            setTaskSubmitEnabled(true);
        }, 1000);
        return () => window.clearTimeout(enableTaskSubmitTimeOut);
        // eslint-disable-next-line
    }, [currentTaskIdx]);

    /**
     * Set the currentTaskOutput for the cog UI.
     *
     * @param {Number[][]} cogWorldPos
     */
    const setCogPos = cogWorldPos => {
        const guiObject = taskUIContext.getCurrentGuiObject();
        setCurrentTaskOutput(
            guiObject.makeTaskOutputForCurrentTask({
                cog: {
                    x: cogWorldPos[0],
                    y: cogWorldPos[1],
                    z: cogWorldPos[2]
                }
            })
        );
        guiObject.onAction("select_cog", 1);
    };

    /**
     * Set the currentTaskOutput for the box UI.
     *
     * @param {Number[]]} cogWorldPos
     * @param {Number} boxOrientation
     * @param {BoxDimensions} boxDims
     */
    const setBox = (cogWorldPos, boxOrientation, boxDims) => {
        // normalize orientation to (-PI, PI)
        const normalizedOrientation =
            boxOrientation -
            2 *
                Math.PI *
                Math.floor((boxOrientation + Math.PI) / (2 * Math.PI));
        const newCurrentTaskOutput = taskUIContext
            .getCurrentGuiObject()
            .makeTaskOutputForCurrentTask({
                cog: {
                    x: cogWorldPos[0],
                    y: cogWorldPos[1],
                    z: cogWorldPos[2]
                },
                box: {
                    front: boxDims.front,
                    back: boxDims.back,
                    left: boxDims.left,
                    right: boxDims.right,
                    top: boxDims.top,
                    bottom: boxDims.bottom
                },
                orientation: normalizedOrientation
            });
        // only add the valid_intersection flag in case we actually have possible intersections
        if (annotationContext.existingBoxes.length > 0) {
            newCurrentTaskOutput.valid_intersection =
                annotationContext.boxIntersectsAnyExistingBox;
        }
        setCurrentTaskOutput(newCurrentTaskOutput);
    };

    if (
        !preprocessedPoints ||
        !sphericalParams ||
        !currentImage ||
        !rgbImageBoundingBox2D ||
        !annotationContext ||
        annotationContext.taskIdx !== currentTaskIdx
    ) {
        return <LoadingAnimation />;
    }

    // callbacks for the children to verify that parts of the box were adjusted
    const onAdjustBoxOrientation = () => {
        taskUIContext.getCurrentGuiObject().onAction("adjust_orientation", 1);
    };
    const onAdjustBoxSides = () => {
        taskUIContext.getCurrentGuiObject().onAction("adjust_box_sides", 1);
    };
    const onAdjustBoxTopBottom = () => {
        taskUIContext
            .getCurrentGuiObject()
            .onAction("adjust_box_top_bottom", 1);
    };

    return (
        <Container fluid>
            <Row>
                <Col>
                    <PointcloudRGB
                        key={taskInput.image_url + "_rgb"}
                        annotationContext={annotationContext}
                        points={preprocessedPoints}
                        sphericalParams={sphericalParams}
                        pointRadius={rgbPointRadius}
                        pointOpacity={rgbPointOpacity}
                        image={currentImage}
                        width={
                            (1024 * currentImage.width) / currentImage.height
                        }
                        height={1024}
                        imageBoundingBox2D={rgbImageBoundingBox2D}
                        imageBoundingBox2DLineWidth={
                            rgbImageBoundingBox2DLineWidth
                        }
                        imageBoundingBox2DColor={rgbImageBoundingBox2DColor}
                        cogColor={cogColor}
                        cogLineWidth={cogLineWidth}
                        mousePointHighlightCanvasDist={
                            rgbMousePointHighlightCanvasDist
                        }
                        highlightedPointRadius={rgbHighlightedPointRadius}
                        highlightedPointOpacity={rgbHighlightedPointOpacity}
                        colormap={colormap}
                        boxLineWidth={boxLineWidth}
                        boxColor={boxColor}
                        arrowVisible={arrowVisible}
                        arrowLineWidth={arrowLineWidth}
                        arrowColor={arrowColor}
                        cursorSize={cursorSize}
                        cursorColor={cursorColor}
                        boxIntersectionLineWidth={boxIntersectionLineWidth}
                        boxIntersectionColor={boxIntersectionColor}
                        existingBoxLineWidth={existingBoxLineWidth}
                        existingBoxColor={existingBoxColor}
                        withBox={type === "box"}
                    />
                </Col>
                <Col>
                    <PointcloudBEV
                        key={taskInput.image_url + "_bev"}
                        setCogPos={setCogPos}
                        setBox={setBox}
                        onAdjustBoxSides={onAdjustBoxSides}
                        onAdjustBoxOrientation={onAdjustBoxOrientation}
                        onAdjustBoxTopBottom={onAdjustBoxTopBottom}
                        annotationContext={annotationContext}
                        points={preprocessedPoints}
                        width={1024}
                        height={1024}
                        bgColor={bgColor}
                        cogColor={cogColor}
                        cogLineWidth={cogLineWidth}
                        transformFit={transformFit}
                        initialZoom={initialZoom}
                        pointRadius={pointRadius}
                        pointOpacity={pointOpacity}
                        colormap={colormap}
                        mousePointHighlightDist={mousePointHighlightDist}
                        highlightedPointRadius={highlightedPointRadius}
                        highlightedPointOpacity={highlightedPointOpacity}
                        boxLineWidth={boxLineWidth}
                        boxColor={boxColor}
                        arrowVisible={arrowVisible}
                        arrowLineWidth={arrowLineWidth}
                        arrowColor={arrowColor}
                        cursorSize={cursorSize}
                        cursorColor={cursorColor}
                        boxIntersectionLineWidth={boxIntersectionLineWidth}
                        boxIntersectionColor={boxIntersectionColor}
                        existingBoxLineWidth={existingBoxLineWidth}
                        existingBoxColor={existingBoxColor}
                        withBox={type === "box"}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export const RGBAndBEVView = withWaitForResourcesWrapper(InternalRGBAndBEVView);
