export class ActionTelemetry {
    constructor() {
        this.cnt = 0;
    }

    incCnt() {
        this.cnt++;
    }
}

export default ActionTelemetry;
