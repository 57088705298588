import React from "react";
import PropTypes from "prop-types";

import addTaskUIProps from "../../containers/TaskUI/addTaskUIProps";

import styles from "./DotProgressBar.module.css";

const ProgressbarDot = ({ on = false }) => {
    if (on) return <div className={styles.progress_dot_on}></div>;
    return <div className={styles.progress_dot_off}></div>;
};

const ProgressbarStroke = ({ progress }) => {
    return (
        <div className={styles.progress_stroke_background}>
            <div
                style={{
                    width: `${progress}%`,
                    height: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#666"
                }}
            ></div>
        </div>
    );
};

const DotProgressBar = ({ total, current, max_dots = 15 }) => {
    if (total > max_dots) {
        return (
            <div className={styles.progressbar_container}>
                <ProgressbarStroke progress={(current / total) * 100} />
            </div>
        );
    }
    const progressDots = [];
    for (let i = 0; i < total; i++) {
        progressDots.push(false);
        if (i < current) progressDots[i] = true;
    }
    const dots = progressDots.map((state, index) => (
        <ProgressbarDot key={index} on={state} />
    ));

    return <div className={styles.progressbar_container}>{dots}</div>;
};

DotProgressBar.propTypes = {
    total: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired
};

export const ConnectedDotProgressBar = addTaskUIProps(
    /**
     * A small wrapper component that uses the appropriate taskUI props for the progressbar
     *
     * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
     */
    ({ currentTaskIdx, tasksAmount, style }) => {
        if (tasksAmount <= 1) {
            return null;
        }

        const overriddenStyle = { ...style };
        return (
            <div style={overriddenStyle}>
                <div
                    style={{
                        padding: "15px",
                        backgroundColor: "#eee"
                    }}
                >
                    <DotProgressBar
                        total={tasksAmount}
                        current={currentTaskIdx + 1}
                        max_dots={1}
                    />
                </div>
            </div>
        );
    }
);

export default DotProgressBar;
