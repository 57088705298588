import React from "react";
import { ImBlocked } from "react-icons/im";

import "./styles/UserBlockedGaming.css";

export const UserIsBlockedGamingErrorPage = props => {
    return (
        <div className="userblocked-container">
            <h1 style={{ marginTop: "20px" }}>No more Tasks</h1>
            <div
                style={{
                    fontSize: "4rem",
                    paddingTop: "20px"
                }}
            >
                <ImBlocked />
            </div>
            <div className="header">
                Sorry, there are no more tasks available for you at this time.
            </div>
            <div className="sub-header">Please try again later</div>
        </div>
    );
};

export default UserIsBlockedGamingErrorPage;
