import React from "react";
import ReactHtmlParser from "react-html-parser";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const Question = ({ guiSettings }) => {
    return (
        <div>
            <h1 id="dag_heading">
                {ReactHtmlParser(guiSettings.question.text)}
            </h1>
        </div>
    );
};
