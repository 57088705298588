import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import CameraView from "./CameraView";
import { ActionButtonNextOrSubmit } from "../../components/ActionButton/ActionButton";
import { KEYPOINT_STAGE } from "./KeypointStages";
import { withLazyLoadedSequenceContextProvider } from "../../components/LazyLoadedSequence/LazyLoadedSequence";
import { TimelineSlider } from "./TimelineSlider";
import {
    AnnotationReviewDialog,
    REVIEW_DIALOG_TYPE
} from "./AnnotationReviewDialog";
import { FinishConfirmDialog } from "./FinishConfirmDialog";
import { KeypointMetaActionButtons } from "./KeypointMetaActionButtons";

import "./CoconutCanvasScene.css";

const DEFAULT_GUI_SETTINGS = {
    questionText: "click any view to set the keypoint",
    keypointInitialDistanceM: 1.0,
    keypointColor: "rgba(0, 255, 0, 0.5)",
    keypointOutlineColor: undefined,
    keypointOutlineWidth: undefined,
    keypointRadiusPx: 8,
    lineColor: "#F00",
    lineWidth: 2,
    zoomLevel: 1
};

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export let KeypointInCameraViewsWidget = ({
    taskInput,
    guiSettings,
    resourceCache,
    currentTaskOutput,
    setCurrentTaskOutput,
    taskUIContext,
    currentTaskIdx
}) => {
    const [stage, setStage] = useState(KEYPOINT_STAGE.SET_KEYPOINT);
    const setStageSetKeypoint = () => setStage(KEYPOINT_STAGE.SET_KEYPOINT);
    // eslint-disable-next-line
    const [timestamps, setTimestamps] = useState(
        Object.keys(taskInput.timestamps).sort((a, b) => Number(a) - Number(b))
    );
    // idx in the timestamp selection
    const [timestampIdx, setTimestampIdx] = useState(0);
    // the renderables for each camera view (keypoint, line)
    const [renderables, setRenderables] = useState({
        top_left: { keypoint: null, line: null },
        top_right: { keypoint: null, line: null },
        bottom_left: { keypoint: null, line: null },
        bottom_right: { keypoint: null, line: null }
    });
    const [timestampRenderables, setTimestampRenderables] = useState({});

    // states to handle zoom translations of the camera views
    const [topLeftZoomTranslation, setTopLeftZoomTranslation] = useState(null);
    const [topRightZoomTranslation, setTopRightZoomTranslation] = useState(
        null
    );
    const [bottomLeftZoomTranslation, setBottomLeftZoomTranslation] = useState(
        null
    );
    const [
        bottomRightZoomTranslation,
        setBottomRightZoomTranslation
    ] = useState(null);
    const zoomTranslations = {
        top_left: {
            get: topLeftZoomTranslation,
            set: setTopLeftZoomTranslation
        },
        top_right: {
            get: topRightZoomTranslation,
            set: setTopRightZoomTranslation
        },
        bottom_left: {
            get: bottomLeftZoomTranslation,
            set: setBottomLeftZoomTranslation
        },
        bottom_right: {
            get: bottomRightZoomTranslation,
            set: setBottomRightZoomTranslation
        }
    };

    // states to manage the review of annotations
    const [isReviewDialogOpen, setIsReviewDialogOpen] = useState(false);
    const [isReviewDone, setIsReviewDone] = useState(false);
    const [isReviewStarted, setIsReviewStarted] = useState(false);
    const onOpenReviewDialog = () => {
        setIsReviewDialogOpen(true);
    };
    const onCloseReviewDialog = () => {
        setIsReviewDialogOpen(false);
    };
    const onStartReviewPhase = () => {
        setIsReviewStarted(true);
        setTimestampIdx(0);
    };

    // state for finish confirm dialog --> a confirm will trigger a submission
    const [isFinishConfirmDialogOpen, setIsFinishConfirmDialogOpen] = useState(
        false
    );
    const onCloseFinishConfirmDialog = isFinishConfirmed => {
        if (isFinishConfirmed) {
            taskUIContext.pushTaskOutput(currentTaskOutput);
            taskUIContext.dispatchNextTaskOrSubmit();
        } else {
        }
        setIsFinishConfirmDialogOpen(false);
    };

    // helpers for accessing current timestamp data
    const getCurrentTimestampInput = () => {
        const timestamp = timestamps[timestampIdx];
        return taskInput.timestamps[timestamp];
    };

    const getCurrentTimestampOutput = () => {
        const timestamp = timestamps[timestampIdx];
        return currentTaskOutput.timestamps[timestamp];
    };

    const getCurrentTimestampRenderables = () => {
        const timestamp = timestamps[timestampIdx];
        return timestampRenderables[timestamp];
    };

    /**
     * Checks whether every timestamp from taskInput has a proper keypoint as output.
     */
    const allTimestampsHaveProperOutput = () => {
        for (const timestamp of timestamps) {
            const output = currentTaskOutput?.timestamps[timestamp];
            const hasProperOutput = output?.box?.position !== undefined;
            if (!hasProperOutput) {
                const isNotVisible = output?.meta_data?.isNotVisible === true;
                if (!isNotVisible) {
                    return false;
                }
            }
        }
        return true;
    };

    // methods and callbacks for state management and managing the taskOutput
    const resetZoomTranslations = () => {
        setTopLeftZoomTranslation(null);
        setTopRightZoomTranslation(null);
        setBottomLeftZoomTranslation(null);
        setBottomRightZoomTranslation(null);
    };

    /**
     * Update renderables for all cameraViews and allow for filtering which cameraView should not
     * display the keypoint or line.
     * @param {Object} renderablesFromCameraView contains keypoint and line renderables
     * @param {Object=} filters optional blacklist filter
     * @param {String[]=} filters.keypointBlacklist optional array of cameraViewNames where the keypoint should not be set
     * @param {String[]=} filters.lineBlacklist optional array of cameraViewNames where the line should not be set
     */
    const updateRenderables = (
        renderablesFromCameraView,
        filters = { keypointBlacklist: [], lineBlacklist: [] }
    ) => {
        const { keypointBlacklist = [], lineBlacklist = [] } = filters;
        const newRenderables = {};
        for (const cameraViewName of Object.keys(renderables)) {
            newRenderables[cameraViewName] = { keypoint: null, line: null };
            if (!keypointBlacklist.includes(cameraViewName)) {
                newRenderables[cameraViewName].keypoint =
                    renderablesFromCameraView.keypoint;
            }
            if (!lineBlacklist.includes(cameraViewName)) {
                newRenderables[cameraViewName].line =
                    renderablesFromCameraView.line;
            }
        }
        setRenderables(newRenderables);
    };

    /**
     * Reset rall enderables for all camera views
     */
    const resetRenderables = () =>
        updateRenderables({ keypoint: null, line: null });

    /**
     * Reset to before keypoint was added with first click
     */
    const onResetKeypoint = () => {
        resetRenderables();

        // remove current timestamp output from currentTaskOutput
        const guiObject = taskUIContext.getCurrentGuiObject();
        const timestamp = timestamps[timestampIdx];
        const timestampOutputs = { ...currentTaskOutput.timestamps };
        delete timestampOutputs[timestamp];
        setCurrentTaskOutput(
            guiObject.makeTaskOutputForCurrentTask({
                timestamps: timestampOutputs
            })
        );
        resetZoomTranslations();
        setStage(KEYPOINT_STAGE.SET_KEYPOINT);
        setIsReviewDone(false);
    };

    /**
     * Reset to keypoint hovering
     */
    const onResetKeypointHover = () => {
        const output = getCurrentTimestampOutput();

        setKeypointOutputForCurrentTimestamp(
            null, // keypoint is reset
            null, // renderables aren't updated
            output.meta_data.setKeypointInitial.cameraViewName, // keep the initial click camera view name
            "" // reset the distance click camera view name
        );

        setStage(KEYPOINT_STAGE.UPDATE_KEYPOINT_HOVERING);
        setIsReviewDone(false);
    };

    const onKeypointClicked = (
        fromCameraViewName,
        renderablesFromCameraView
    ) => {
        // handle renderables for other camera views to show
        updateRenderables(renderablesFromCameraView, {
            lineBlacklist: [fromCameraViewName]
        });

        // save the fromCameraViewName as the initial click view for the current timestamp.
        // the keypoint position isn't saved, yet. It'll only be saved after the second click.
        setKeypointOutputForCurrentTimestamp(
            null,
            renderablesFromCameraView,
            fromCameraViewName
        );

        // switch to next stage
        setStage(KEYPOINT_STAGE.UPDATE_KEYPOINT_HOVERING);
    };

    /**
     * Called when the keypoint distance from camera is updated through hovering
     * in one of the views.
     */
    const onKeypointHoverKeypointUpdate = (
        fromCameraViewName,
        renderablesFromCameraView
    ) => {
        const output = getCurrentTimestampOutput();
        updateRenderables(renderablesFromCameraView, {
            lineBlacklist: [output.meta_data.setKeypointInitial.cameraViewName]
        });
    };

    const onKeypointHoverClicked = (
        cameraViewName,
        renderablesFromCameraView
    ) => {
        const output = getCurrentTimestampOutput();
        // handle renderables for other camera views to show
        updateRenderables(renderablesFromCameraView, {
            lineBlacklist: [output.meta_data.setKeypointInitial.cameraViewName]
        });

        // save as output for the current timestamp
        setKeypointOutputForCurrentTimestamp(
            renderablesFromCameraView.keypoint.poseWorld.pos,
            renderablesFromCameraView,
            output.meta_data.setKeypointInitial.cameraViewName,
            cameraViewName
        );

        // switch to keypoint clicked stage
        setStage(KEYPOINT_STAGE.UPDATE_KEYPOINT_CLICKED);
    };

    /**
     * @param {Number[]} keypointWorldCoords
     * @param {Object=} renderablesFromCameraView in order to remember the renderables when we revisit this timestamp
     * @param {String=} setKeypointInitialCameraViewName to remember in which camera view the first click happened
     * @param {String=} setKeypointDistanceCameraViewName  to remember in which camera view the second click happened
     */
    const setKeypointOutputForCurrentTimestamp = (
        keypointWorldCoords,
        renderablesFromCameraView,
        setKeypointInitialCameraViewName = "",
        setKeypointDistanceCameraViewName = ""
    ) => {
        const guiObject = taskUIContext.getCurrentGuiObject();
        const timestamp = timestamps[timestampIdx];
        const metaData = currentTaskOutput.timestamps[timestamp]?.meta_data;

        // remember the renderables for being able to switch timestamps
        if (renderablesFromCameraView) {
            setTimestampRenderables({
                ...timestampRenderables,
                [timestamp]: renderablesFromCameraView
            });
        }

        // the box can be null or an array with the actual coordinates
        let box = null;
        if (Array.isArray(keypointWorldCoords)) {
            box = {
                position: {
                    x: keypointWorldCoords[0],
                    y: keypointWorldCoords[1],
                    z: keypointWorldCoords[2]
                }
            };
        }

        setCurrentTaskOutput(
            guiObject.makeTaskOutputForCurrentTask({
                timestamps: {
                    ...currentTaskOutput.timestamps,
                    [timestamps[timestampIdx]]: {
                        box,
                        meta_data: {
                            ...metaData,
                            isNotVisible: false,
                            setKeypointInitial: {
                                cameraViewName: setKeypointInitialCameraViewName
                            },
                            setKeypointDistance: {
                                cameraViewname: setKeypointDistanceCameraViewName
                            },
                            msg: "box.position is in world coordinates."
                        }
                    }
                }
            })
        );
    };

    const toggleVisibility = () => {
        if (isCurrentObjectNotVisible()) {
            objectVisible();
        } else {
            objectNotVisible();
        }
    };

    const objectVisible = () => {
        // remove current timestamp output from currentTaskOutput
        const guiObject = taskUIContext.getCurrentGuiObject();
        const timestamp = timestamps[timestampIdx];
        const timestampOutputs = { ...currentTaskOutput.timestamps };
        delete timestampOutputs[timestamp];
        setCurrentTaskOutput(
            guiObject.makeTaskOutputForCurrentTask({
                timestamps: timestampOutputs
            })
        );
        setIsReviewDone(false);
    };

    const objectNotVisible = () => {
        const guiObject = taskUIContext.getCurrentGuiObject();
        const timestamp = timestamps[timestampIdx];
        const metaData = currentTaskOutput.timestamps[timestamp]?.meta_data;
        const currentRenderables = { ...timestampRenderables };
        const box = null;

        resetRenderables();

        if (currentRenderables?.[timestamp]) {
            delete currentRenderables[timestamp];
            setTimestampRenderables({ ...currentRenderables });
        }

        resetZoomTranslations();
        setStage(KEYPOINT_STAGE.SET_KEYPOINT);
        setIsReviewDone(false);

        // set box to null and meta_data.isNotVisible = true
        setCurrentTaskOutput(
            guiObject.makeTaskOutputForCurrentTask({
                timestamps: {
                    ...currentTaskOutput.timestamps,
                    [timestamps[timestampIdx]]: {
                        box,
                        meta_data: {
                            ...metaData,
                            isNotVisible: true,
                            setKeypointInitial: {
                                cameraViewName: ""
                            },
                            setKeypointDistance: {
                                cameraViewname: ""
                            },
                            msg: "box.position is in world coordinates."
                        }
                    }
                }
            })
        );
    };

    const isCurrentObjectNotVisible = () => {
        return (
            currentTaskOutput.timestamps[timestamps[timestampIdx]]?.meta_data
                ?.isNotVisible === true
        );
    };

    useEffect(() => {
        // reset between timestamps
        resetRenderables();
        resetZoomTranslations();
        const output = getCurrentTimestampOutput();
        const renderablesFromCameraView = getCurrentTimestampRenderables();
        // go to the set keypoint stage
        setStage(KEYPOINT_STAGE.SET_KEYPOINT);

        if (output && renderablesFromCameraView) {
            // render the keypoint and line from the timestamp output and recreate the proper UI state
            // when there are renderables for the timestamp then make sure they're shown
            updateRenderables(renderablesFromCameraView, {
                lineBlacklist: [
                    output.meta_data.setKeypointInitial.cameraViewName
                ]
            });
            // go to the keypoint hover clicked stage
            setStage(KEYPOINT_STAGE.UPDATE_KEYPOINT_CLICKED);
        }

        // when in review phase:
        // - check whether the last timestamp has been reached
        // - if yes and all keypoints are set: the review phase is done
        // - else: the review phase is not done
        if (
            isReviewStarted &&
            !isReviewDone &&
            timestampIdx === timestamps.length - 1 &&
            allTimestampsHaveProperOutput()
        ) {
            setIsReviewDone(true);
        } else {
            setIsReviewDone(false);
        }

        // eslint-disable-next-line
    }, [timestampIdx]);

    useEffect(() => {
        // handle the review is done state

        // when in review phase:
        // - check whether the last timestamp has been reached
        // - if yes and all keypoints are set: the review phase is done
        // - else: the review phase is not done
        if (
            isReviewStarted &&
            !isReviewDone &&
            timestampIdx === timestamps.length - 1 &&
            allTimestampsHaveProperOutput()
        ) {
            setIsReviewDone(true);
        } else {
            setIsReviewDone(false);
        }
    }, [currentTaskOutput]);

    const {
        question: { text: questionText = DEFAULT_GUI_SETTINGS.questionText } = {
            text: DEFAULT_GUI_SETTINGS.questionText
        },
        keypoint_initial_distance_m: keypointInitialDistanceM = DEFAULT_GUI_SETTINGS.keypointInitialDistanceM,
        keypoint_radius_px: keypointRadiusPx = DEFAULT_GUI_SETTINGS.keypointRadiusPx,
        keypoint_color: keypointColor = DEFAULT_GUI_SETTINGS.keypointColor,
        keypoint_outline_color: keypointOutlineColor = DEFAULT_GUI_SETTINGS.keypointOutlineColor,
        keypoint_outline_width: keypointOutlineWidth = DEFAULT_GUI_SETTINGS.keypointOutlineWidth,
        line_width: lineWidth = DEFAULT_GUI_SETTINGS.lineWidth,
        line_color: lineColor = DEFAULT_GUI_SETTINGS.lineColor,
        zoom_level: zoomLevel = DEFAULT_GUI_SETTINGS.zoomLevel
    } = guiSettings;

    // methods for creating react components
    const makeCameraView = (name, abbreviation) => {
        const timestampData = getCurrentTimestampInput();
        const output = getCurrentTimestampOutput();
        const cameraViewParams = timestampData[abbreviation];
        const hmdWorldPose = timestampData.hmd_world_pose;
        const initialViewClicked =
            output?.meta_data.setKeypointInitial.cameraViewName || null;

        return (
            <Col>
                <CameraView
                    key={cameraViewParams.image_url + "_" + name}
                    name={name}
                    hmdWorldPose={hmdWorldPose}
                    E={cameraViewParams.E}
                    K={cameraViewParams.K}
                    distortionCoeffs={cameraViewParams.distortion_coeffs}
                    image={resourceCache[cameraViewParams.image_url]}
                    stage={stage}
                    keypointInitialDistanceM={keypointInitialDistanceM}
                    keypointRadiusPx={keypointRadiusPx}
                    keypointColor={keypointColor}
                    keypointOutlineColor={keypointOutlineColor}
                    keypointOutlineWidth={keypointOutlineWidth}
                    lineWidth={lineWidth}
                    lineColor={lineColor}
                    renderables={renderables}
                    onResetKeypoint={onResetKeypoint}
                    onResetKeypointHover={onResetKeypointHover}
                    onKeypointClicked={onKeypointClicked}
                    onKeypointHoverKeypointUpdate={
                        onKeypointHoverKeypointUpdate
                    }
                    onKeypointHoverClicked={onKeypointHoverClicked}
                    overriddenInitialViewClicked={initialViewClicked}
                    zoomLevel={zoomLevel}
                    zoomTranslation={zoomTranslations[name].get}
                    setZoomTranslation={zoomTranslations[name].set}
                    withZoom
                />
            </Col>
        );
    };

    const getCurrentActionMessage = () => {
        const output = getCurrentTimestampOutput();
        const resetInfo = (
            <>
                or click the{" "}
                <b>{output?.meta_data.setKeypointInitial.cameraViewName}</b>{" "}
                view to reset
            </>
        );

        switch (stage) {
            case KEYPOINT_STAGE.SET_KEYPOINT:
                return questionText;
            case KEYPOINT_STAGE.UPDATE_KEYPOINT_HOVERING:
                return (
                    <>
                        hover in a view and click to fix the position of the
                        point on the line {resetInfo}
                    </>
                );
            case KEYPOINT_STAGE.UPDATE_KEYPOINT_CLICKED:
                return (
                    <>
                        go to the <b>next frame</b> and set a keypoint there or
                        click near a line to make the keypoint adjustable again{" "}
                        {resetInfo}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {/* hidden until necessary */}
            <AnnotationReviewDialog
                type={REVIEW_DIALOG_TYPE.KEYPOINT_UI}
                taskInput={taskInput}
                currentTaskOutput={currentTaskOutput}
                // only allow to open in the update keypoint clicked stage
                // and when we haven't already started the review phase
                allowOpen={
                    (stage === KEYPOINT_STAGE.UPDATE_KEYPOINT_CLICKED ||
                        isCurrentObjectNotVisible()) &&
                    !isReviewStarted
                }
                onOpen={onOpenReviewDialog}
                onClose={onCloseReviewDialog}
                onStartReviewPhase={onStartReviewPhase}
            />
            {/* hidden until necessary */}
            <FinishConfirmDialog
                shouldShow={isFinishConfirmDialogOpen}
                onClose={onCloseFinishConfirmDialog}
            />
            <Row>
                <Col>
                    <h1 style={{ width: 0, minWidth: "100%" }}>
                        {questionText}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col md={2} style={{ display: "flex", alignItems: "center" }}>
                    <div>
                        <p>Reference</p>
                        <img
                            style={{ width: "100%", border: "1px solid black" }}
                            id="image"
                            src={
                                taskInput.reference_image_url
                                    ? taskInput.reference_image_url
                                    : guiSettings["reference_image_url"]
                            }
                            draggable={false}
                            alt=""
                        />
                        <button
                            className="button"
                            style={{
                                backgroundColor: isCurrentObjectNotVisible()
                                    ? "#db5353"
                                    : "",
                                marginTop: "15px",
                                padding: "6px",
                                border: "solid #10294b 1px"
                            }}
                            onClick={() => toggleVisibility()}
                        >
                            Object not visible
                        </button>
                    </div>
                </Col>
                <Col>
                    <Row style={{ marginTop: "0.6rem" }}>
                        {makeCameraView("top_left", "tl")}
                        {makeCameraView("top_right", "tr")}
                    </Row>
                    <Row style={{ marginTop: "0.6rem" }}>
                        {makeCameraView("bottom_left", "bl")}
                        {makeCameraView("bottom_right", "br")}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col
                    style={{
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem"
                    }}
                >
                    <TimelineSlider
                        timestampIdx={timestampIdx}
                        setTimestampIdx={setTimestampIdx}
                        timestampsLen={timestamps.length}
                        disabled={
                            stage === KEYPOINT_STAGE.UPDATE_KEYPOINT_HOVERING ||
                            isReviewDialogOpen ||
                            isFinishConfirmDialogOpen
                        }
                        withHotKeys
                    />
                    <Row>
                        {" "}
                        <Col>
                            <ActionButtonNextOrSubmit
                                taskUIContext={taskUIContext}
                                disabled={!isReviewDone}
                                actionSuffix="finish"
                                className="is-primary"
                                onClick={() => {
                                    setIsFinishConfirmDialogOpen(true);
                                }}
                                createTaskOutput={() => ({})}
                            >
                                Finish
                            </ActionButtonNextOrSubmit>
                        </Col>
                    </Row>
                    <Row>
                        <KeypointMetaActionButtons
                            taskUIContext={taskUIContext}
                            currentTaskIdx={currentTaskIdx}
                        />
                    </Row>
                </Col>
            </Row>
        </>
    );
};

KeypointInCameraViewsWidget = withLazyLoadedSequenceContextProvider(
    KeypointInCameraViewsWidget
);

export default KeypointInCameraViewsWidget;
