import MockData from "../MockData/MockData";
import MockApiClient from "../MockData/MockApiClient";
import BaseGuiType from "../BaseGuiType";
import { WIDGETS } from "../../guiFactory";
import { covidImagesObjects } from "../sharedMockTaskInputs";
import { ImageVisualization } from "./ImageVisualization";
import { ActionButtonGroup } from "./ActionButtonGroup";
import { Question } from "./Question";
import { ResourceLoaderService } from "../ResourceLoaderService";

export class DiscreteAnswer extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return "discrete_answer";
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const taskInputs = covidImagesObjects.slice(0, 2);
        const mockApiClient = new MockApiClient(
            {
                instructions: [],
                buttons: [
                    {
                        text: "Answer A",
                        answer_key: "answer_a",
                        key: "a"
                        // uses default color scheme
                        // uncomment to define styling:
                        // bg_color: "black",
                        // text_color: "white"
                    },
                    {
                        text: "Answer B",
                        answer_key: "answer_b",
                        key: "b",
                        bg_color: "#f77171",
                        text_color: "#088e8e"
                    },
                    {
                        text: "Answer C",
                        answer_key: "answer_c",
                        key: "c",
                        bg_color: "#f7ab71",
                        text_color: "#08548e"
                    },
                    {
                        text: "Something Else",
                        answer_key: "something_else",
                        bg_color: "#f7e771",
                        text_color: "#088e68"
                    }
                ],
                question: { text: "Please select the correct answer." },
                image_scale: 1.0,
                use_magnifying_tool: true,
                magnifying_tool_zoom_scale: 2.5
            },
            WIDGETS.DISCRETE_ANSWER,
            taskInputs
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeImageLoaderService(["image_url"])
        );
    }

    /**
     * @override
     */
    getHeading() {
        return Question;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return ImageVisualization;
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return ActionButtonGroup;
    }

    /**
     * @inheritdoc
     */
    makeTaskOutputForCurrentTask(overrides) {
        let taskOutput = super.makeTaskOutputForCurrentTask(overrides);
        taskOutput = { answer: "", ...taskOutput }; // schema expects String
        return taskOutput;
    }
}

export default DiscreteAnswer;
