import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { GlobalHotKeys } from "react-hotkeys";
import Slider from "rc-slider";
import { FaStepBackward, FaStepForward } from "react-icons/fa";

export const TimelineSlider = ({
    timestampIdx,
    timestampsLen,
    withHotKeys,
    setTimestampIdx,
    disabled,
    children
}) => {
    const incrementTimestamp = () => {
        if (disabled) {
            return;
        }

        if (timestampIdx + 1 >= timestampsLen) {
            return;
        }
        setTimestampIdx(timestampIdx + 1);
    };
    const decrementTimestamp = () => {
        if (disabled) {
            return;
        }

        if (timestampIdx - 1 < 0) {
            return;
        }
        setTimestampIdx(timestampIdx - 1);
    };

    let globalHotKeys = null;
    const prevKeys = ["a", "ctrl"];
    const nextKeys = ["d", "space"];
    if (withHotKeys) {
        globalHotKeys = (
            <GlobalHotKeys
                allowChanges={true}
                keyMap={{
                    PREVIOUS_TIMESTAMP: prevKeys,
                    NEXT_TIMESTAMP: nextKeys
                }}
                handlers={{
                    PREVIOUS_TIMESTAMP: decrementTimestamp,
                    NEXT_TIMESTAMP: incrementTimestamp
                }}
            />
        );
    }

    return (
        <Row
            style={{
                marginBottom: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
        >
            {globalHotKeys}
            <Col
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <div style={{ margin: "0.6rem" }}>
                    Frame: {timestampIdx + 1}/{timestampsLen}
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "baseline"
                        // width: "80%"
                    }}
                >
                    <Button
                        className="button is-sm"
                        style={{ margin: "10px" }}
                        disabled={disabled}
                        onClick={decrementTimestamp}
                        title={
                            withHotKeys ? `hotkeys: any of "${prevKeys}"` : ""
                        }
                    >
                        <FaStepBackward />
                    </Button>
                    {/* <Slider
                        className="timestamp_slider"
                        style={{
                            marginLeft: "20px",
                            marginRight: "20px"
                        }}
                        disabled={disabled}
                        min={0}
                        max={timestampsLen - 1}
                        step={1}
                        value={timestampIdx}
                        onChange={v => {
                            setTimestampIdx(v);
                        }}
                    /> */}
                    {children}
                    <Button
                        className="button is-sm"
                        style={{ margin: "10px" }}
                        disabled={disabled}
                        onClick={incrementTimestamp}
                        title={
                            withHotKeys ? `hotkeys: any of "${nextKeys}"` : ""
                        }
                    >
                        <FaStepForward />
                    </Button>
                </div>
            </Col>
        </Row>
    );
};
