// eslint-disable-next-line
import { Pose3D } from "./Pose3D";
// eslint-disable-next-line
import { Renderer } from "./Renderer";
// eslint-disable-next-line
import { Renderable } from "./Renderable";

import { generateRandomString } from "../qm_cs_lib";

/**
 * @typedef {Object} Scene3DSettings
 * @property {Boolean=} withGlobalCoordinateSystem default false. Adds global coordinate system to the scene with negative and positive sides.
 * @property {Boolean=} withLocalCoordinateSystem default false. Add local coordinate system to every scene object with only positive sides.
 * @property {String=} name default: random 16 character alphanumeric string
 * @property {Number[]=} globalCoordinateSystemAxisLengths xyz axis lengths for gcs
 * @property {Number[]=} localCoordinateSystemAxisLengths xyz axis lengths for lcs
 */

export class Scene3D {
    /**
     * @param {Pose3D} cameraPoseWorld
     * @param {Renderer} renderer
     * @param {Scene3DSettings} settings
     */
    constructor(
        cameraPoseWorld,
        renderer,
        settings = {
            withGlobalCoordinateSystem: false,
            withLocalCoordinateSystem: false,
            name: ""
        }
    ) {
        // camera position + rotation in world
        this.cameraPoseWorld = cameraPoseWorld;

        /**
         * Map of all objects in the scene
         * @type {Map<String, Renderable>}
         */
        this.objects = new Map();

        /**
         * the renderer knows how to project object points
         * from world space to raster space and invokes
         * rendering for all objects
         * @type {Renderer}
         */
        this.renderer = renderer;
        this.renderer.setScene(this);

        this.settings = settings;
        this.init();
    }

    /**
     * Shouldn't be called from the outside!
     */
    init() {
        if (this.settings.withGlobalCoordinateSystem) {
            // the renderer knows how to create the global coordinate system
            // scene object
            const axisLengths = this.settings
                .globalCoordinateSystemAxisLengths || [100, 100, 100];
            const gcs = this.renderer.makeCoordinateSystemObject(new Pose3D(), [
                [-axisLengths[0] / 2, axisLengths[0] / 2],
                [-axisLengths[1] / 2, axisLengths[1] / 2],
                [-axisLengths[2] / 2, axisLengths[2] / 2]
            ]);
            this.addObject(gcs, "gcs");
        }

        if (this.settings.name === "") {
            this.settings.name = generateRandomString(16);
        }
    }

    /**
     * @param {Renderable} obj
     * @param {String=} name name with which to identify the object. can be omitted.
     */
    addObject(obj, name = "") {
        if (this.objects.has(name)) {
            console.error(
                "object with name " + name + " already exists in scene!"
            );
            return;
        }

        if (name === "" || name === undefined || name === null) {
            name = generateRandomString(16);
        }
        this.objects.set(name, obj);
        obj.setScene(this);

        // add local coordinate system
        if (
            this.settings.withLocalCoordinateSystem &&
            name !== "gcs" &&
            !name.endsWith("_lcs")
        ) {
            const axisLengths = this.settings
                .localCoordinateSystemAxisLengths || [5, 5, 5];
            const lcs = this.renderer.makeCoordinateSystemObject(
                obj.poseWorld,
                [
                    [0, axisLengths[0]],
                    [0, axisLengths[1]],
                    [0, axisLengths[2]]
                ]
            );
            this.addObject(lcs, name + "_lcs");
        }
    }

    /**
     * @param {String} name
     * @returns {Renderable}
     */
    getObject(name) {
        if (!this.objects.has(name)) {
            return null;
        }
        return this.objects.get(name);
    }

    /**
     * Discard an object reference of this scene.
     * Doesn't remove the underlying object.
     * Also removes the local coordinate system object for this object if it has one.
     * @param {String} name
     */
    deleteObject(name) {
        if (typeof name !== "string" || !this.objects.has(name)) {
            console.error(
                "bad object name value (should be string) or object with name " +
                    name +
                    " not in scene!"
            );
            return;
        }
        this.objects.delete(name);
        if (this.settings.withLocalCoordinateSystem) {
            this.objects.delete(name + "_lcs");
        }
    }

    /**
     * Discard all object references of this scene.
     * Doesn't remove the underlying objects.
     */
    deleteAllObjects() {
        this.objects = new Map();
    }

    invalidateObjectProjections() {
        for (const obj of this.objects.values()) {
            obj.setShouldReProject(true);
        }
    }

    /**
     * @param {Boolean=} forceReprojection default: false. Force reprojection of all scene objects in this render call.
     */
    render(forceReprojection = false) {
        let i = 0;
        for (const obj of this.objects.values()) {
            if (forceReprojection) {
                obj.setShouldReProject(true);
            }
            if (obj.shouldRender) {
                // project object coordinates to raster space and invoke rendering
                this.renderer.render(obj, this.cameraPoseWorld, i === 0);
            }
            i++;
        }
    }
}
