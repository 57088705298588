/**
 * Class for preloading images
 */
export class ImageCache {
    /**
     * @param {Object} options
     * @param {String} options.sortLoadedImagesOnReturn - whether images should be returned in the same order as in param imgUrls for ImageCache.init(imgUrls)
     */
    constructor(options = {}) {
        this.options = {
            sortLoadedImagesOnReturn: true,
            ...options
        };
        this.imgCache = {};
    }

    /**
     * (Re-)Initializes cache. Loads all images as Image Objects, caches them and returns Promise
     * @param {String[]} imgUrls
     * @returns {Promise<Image[]>} Array of loaded imgObjects in the same order as given by imgUrls
     */
    init(imgUrls) {
        this.imgCache = {};
        this.imgUrls = imgUrls;
        this.imgObjects = [];
        this.imgLoadErrors = [];
        this.loadCount = 0;
        return new Promise((resolve, reject) => {
            this._loadImages(resolve, reject);
        });
    }

    /**
     * @returns {Map<String, Image>}
     */
    getCacheObject() {
        return this.imgCache;
    }

    /**
     * Get single image from cache or load and cache it if it's not cached yet.
     * Always returns a Promise.
     *
     * @params {String} imgUrl
     * @returns {Promise<Image>}
     */
    get(imgUrl) {
        if (this.has(imgUrl)) {
            return new Promise().resolve(this.imgCache[imgUrl]);
        } else {
            return new Promise((resolve, reject) => {
                this._loadImage(imgUrl, resolve, reject);
            });
        }
    }

    /**
     * @param {String} imgUrl
     * @returns {boolean}
     */
    has(imgUrl) {
        return this.imgCache.hasOwnProperty(imgUrl);
    }

    _loadImages(resolve, reject) {
        for (const imgUrl of this.imgUrls) {
            const img = new Image();
            img.onload = () => {
                this.imgObjects.push(img);
                this.imgCache[img.src] = img;
                this._checkAllImagesLoaded(resolve, reject);
            };
            img.onerror = err => {
                this.imgLoadErrors.push(img);
                this._checkAllImagesLoaded(resolve, reject);
            };
            // disables dragging the image when swiping with mouse
            img.ondragstart = () => false;
            img.src = imgUrl;
        }
    }

    _loadImage(imgUrl, resolve, reject) {
        const img = new Image();
        img.onload = () => {
            if (!this.has(imgUrl)) {
                this.imgCache[imgUrl] = img;
            }
            resolve(img);
        };
        img.onerror = err => {
            reject(err);
        };
        // disables dragging the image when swiping with mouse
        img.ondragstart = () => false;
        img.src = imgUrl;
    }

    _checkAllImagesLoaded = (resolve, reject) => {
        this.loadCount++;
        if (this.loadCount === this.imgUrls.length) {
            for (const error of this.imgLoadErrors) {
                console.error("failed loading image", error.src);
            }
            if (this.imgLoadErrors.length > 0) {
                reject("ERROR: at least one image couldn't be loaded");
            } else {
                this._handleAllImagesLoadedSuccessfully(resolve);
            }
        }
    };

    _handleAllImagesLoadedSuccessfully(resolve) {
        if (this.options.sortLoadedImagesOnReturn) {
            const sortedImgObjects = [];
            for (const imgUrl of this.imgUrls) {
                sortedImgObjects.push(
                    this.imgObjects.find(imgObject => imgObject.src === imgUrl)
                );
            }
            resolve(sortedImgObjects);
        } else {
            resolve(this.imgObjects);
        }
    }
}
