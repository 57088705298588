import MockData from "../MockData/MockData";
import MockApiClient from "../MockData/MockApiClient";
import BaseGuiType from "../BaseGuiType";
import { WIDGETS } from "../../guiFactory";
import { ImageSelectionMatrix } from "./ImageSelectionMatrix";
import { ActionButtonGroup } from "./ActionButtonGroup";
import { Question } from "./Question";
import { tomatoPestImages } from "./MockTaskInputs";
import ResourceLoaderService from "../ResourceLoaderService";
import { makeImageLoader } from "../../lib/DataLoader/loaders";

export class ImageSelection extends BaseGuiType {
    static getName() {
        return "image_selection";
    }
    static makeMockData() {
        const taskInputs = tomatoPestImages;
        const mockApiClient = new MockApiClient(
            {
                instructions: [],
                question: { text: "Select images with Myzus persicae" }
            },
            WIDGETS.IMAGE_SELECTION,
            taskInputs
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeCustomLoaderService(
                new Map([
                    [
                        "each_taskinput_object_value_is_image_url",
                        taskInput =>
                            Object.values(taskInput).map(imageUrl =>
                                makeImageLoader(imageUrl)
                            )
                    ]
                ])
            )
        );
    }

    /**
     * @override
     */
    getHeading() {
        return Question;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return ImageSelectionMatrix;
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return ActionButtonGroup;
    }

    /**
     * @inheritdoc
     */
    makeTaskOutputForCurrentTask(overrides) {
        let taskOutput = super.makeTaskOutputForCurrentTask(overrides);
        taskOutput = { selection: null, ...taskOutput };
        return taskOutput;
    }
}

export default ImageSelection;
