import React from "react";
import {
    ActionButtonOpenInstructions,
    ActionButtonUndo,
    ActionButtonCantSolve,
    ActionButtonCorruptData
} from "./ActionButton";

/**
 * An ActionButtonGroup with the default meta action buttons.
 * IMPORTANT: If you override this, you should make sure that corrupt data
 *            isn't disabled when the resource loading fails!
 *
 * @typedef {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} DivProps
 *
 * @param {DivProps & import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const DefaultMetaActionButtonGroup = ({
    taskUIContext,
    currentTaskIdx,
    style,
    className
}) => {
    const overriddenStyle = { ...style };

    const disabledMetaActionButtons = taskUIContext.shouldActionButtonsBeDisabled();
    // Corrupt Data Button should never be disabled when resources weren't loaded successfully!
    const disabledCorruptData = !taskUIContext
        .getCurrentGuiObject()
        .taskInputResourcesLoadedWithError(currentTaskIdx);

    return (
        <div style={overriddenStyle} className={className}>
            <ActionButtonUndo
                taskUIContext={taskUIContext}
                disabled={disabledMetaActionButtons}
            />
            <ActionButtonCantSolve
                taskUIContext={taskUIContext}
                disabled={disabledMetaActionButtons}
            />
            <ActionButtonCorruptData
                taskUIContext={taskUIContext}
                disabled={disabledMetaActionButtons && disabledCorruptData}
            />
            <ActionButtonOpenInstructions
                taskUIContext={taskUIContext}
                disabled={disabledMetaActionButtons}
            />
        </div>
    );
};
DefaultMetaActionButtonGroup.defaultProps = {
    className: "meta_action_button_group"
};

export default DefaultMetaActionButtonGroup;
