/**
 * An array of sample polygons that is used as mock data for testing polygons as input.
 */
export const samplePolygonsFromTaskOutput = [
    {
        // triangle: no label given
        id: "triangle-001",
        points: [
            { x: 200, y: 100 },
            { x: 500, y: 100 },
            { x: 350, y: 400 }
        ]
    },
    {
        // rectangle: label given but it's not known in gui_settings, with collision due to last node
        id: "rectangle-001",
        points: [
            { x: 600, y: 100 },
            { x: 900, y: 100 },
            { x: 900, y: 350 },
            { x: 1000, y: 225 }
        ],
        label: "this_label_isnt_known_in_gui_settings_so_will_be_unknown_label"
    },
    {
        // pacman: label logs given
        id: "pacman-001",
        points: [
            {
                x: 620.8000000000001,
                y: 802.5600000000001
            },
            {
                x: 930.5600000000001,
                y: 608
            },
            {
                x: 864,
                y: 505.6
            },
            {
                x: 825.6,
                y: 469.76
            },
            {
                x: 743.6800000000001,
                y: 423.68
            },
            {
                x: 659.2,
                y: 416
            },
            {
                x: 564.48,
                y: 418.56
            },
            {
                x: 474.88,
                y: 462.08
            },
            {
                x: 382.72,
                y: 523.52
            },
            {
                x: 311.04,
                y: 602.88
            },
            {
                x: 241.92000000000002,
                y: 764.16
            },
            {
                x: 259.84000000000003,
                y: 910.08
            },
            {
                x: 305.92,
                y: 1079.04
            },
            {
                x: 421.12,
                y: 1155.84
            },
            {
                x: 561.92,
                y: 1160.96
            },
            {
                x: 689.92,
                y: 1145.6000000000001
            },
            {
                x: 823.04,
                y: 1076.48
            },
            {
                x: 925.44,
                y: 961.28
            }
        ],
        label: "logs"
    },
    {
        // ghost: label grapple given
        id: "ghost-001",
        points: [
            {
                x: 999.6800000000001,
                y: 930.5600000000001
            },
            {
                x: 999.6800000000001,
                y: 712.96
            },
            {
                x: 1020.16,
                y: 669.44
            },
            {
                x: 1061.1200000000001,
                y: 654.08
            },
            {
                x: 1145.6000000000001,
                y: 651.52
            },
            {
                x: 1181.44,
                y: 672
            },
            {
                x: 1201.92,
                y: 702.72
            },
            {
                x: 1201.92,
                y: 925.44
            },
            {
                x: 1166.08,
                y: 897.28
            },
            {
                x: 1132.8,
                y: 935.6800000000001
            },
            {
                x: 1096.96,
                y: 897.28
            },
            {
                x: 1066.24,
                y: 933.12
            },
            {
                x: 1038.08,
                y: 899.84
            }
        ],
        label: "grapple"
    }
];
