import { ActionTypes } from "../actions";
import generateReducer from "../generateReducer";

const initialTaskFetchRequest = {
    pending: false,
    result: null,
    error: null
};

export const taskFetchRequestReducer = generateReducer(
    initialTaskFetchRequest,
    {
        [ActionTypes.TASK_FETCH]: (taskFetchRequest, action) => {
            return { ...taskFetchRequest, pending: true };
        },
        [ActionTypes.TASK_FETCH_SUCCESS]: (taskFetchRequest, action) => {
            return {
                ...taskFetchRequest,
                pending: false,
                result: action.taskFetchResult
            };
        },
        [ActionTypes.TASK_FETCH_ERROR]: (taskFetchRequest, action) => {
            return { ...taskFetchRequest, pending: false, error: action.error };
        }
    }
);

export const getTaskFetchRequest = state => state.taskFetchRequest;
export const getTaskFetchResult = state => {
    return state.taskFetchRequest.result;
};
export const getTaskInputs = state => {
    const task_inputs = state.taskFetchRequest.result.task_inputs;
    if (!task_inputs || task_inputs.length === 0) {
        throw new Error("TaskInputs not set or empty");
    }
    return task_inputs;
};
export const getTaskInputAtIdx = (state, idx) => {
    const task_inputs = getTaskInputs(state);
    if (idx < 0 || idx >= task_inputs.length) {
        throw new Error("Request task_input index out of bounds");
    }
    return task_inputs[idx];
};
export const getTaskInputIds = state => {
    if (
        state.taskFetchRequest.error ||
        state.taskFetchRequest.pending ||
        !state.taskFetchRequest.result.has_tasks
    ) {
        return [];
    }
    const acks = state.taskFetchRequest.result.group_ack.ack;
    return acks.map(ack => ack.input_id);
};
