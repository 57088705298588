import React from "react";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";

/**
 * Shows a LoadingAnimation while resources of current task aren't loaded, yet.
 * Calls taskUIContext.onViewReady() to signal that the task can start now.
 * Automatically passes the currentTaskInput to the wrapped component.
 *
 * @param {React.Component} WrappedComponent
 * @returns {React.Component}
 */
export const withWaitForResourcesWrapper = WrappedComponent => {
    /**
     * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
     */
    const WaitForResourcesWrapper = props => {
        if (
            !props.taskUIContext
                .getCurrentGuiObject()
                .taskInputResourcesInCache(props.currentTaskIdx)
        ) {
            return <LoadingAnimation />;
        }
        props.taskUIContext.onViewReady();
        const taskInput = props.taskUIContext.getCurrentTaskInput();
        return <WrappedComponent {...props} taskInput={taskInput} />;
    };
    return WaitForResourcesWrapper;
};

export default withWaitForResourcesWrapper;
