import { ActionTypes } from "../actions";
import generateReducer from "../generateReducer";

export const currentTaskIdxReducer = generateReducer(0, {
    [ActionTypes.NEXT_TASK]: (currentTaskIdx, action) => currentTaskIdx + 1,
    [ActionTypes.PREVIOUS_TASK]: (currentTaskIdx, action) => currentTaskIdx - 1
});

export const getCurrentTaskIdx = state => state.currentTaskIdx;

export default currentTaskIdxReducer;
