import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getInstructions } from "../../store/reducers/guiSettings";
import ActionButton from "../ActionButton/ActionButton";
import RawHTML from "../RawHTML/RawHTML";
import { Carousel } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Instructions.css";

/**
 * if you want no instructions either omit instructions from guiSettings or:
 *   instructions: "", // no instructions
 *   instructions: [], // no instructions
 *
 * @param {Object} props
 * @param {import("../../lib/TaskUIStrategy/taskUIContext").TaskUIContext} props.taskUIContext
 * @param {String|String[]} props.instructions
 */
export let Instructions = ({ taskUIContext, instructions }) => {
    const onCloseInstructions = () => {
        taskUIContext.closeInstructions();
    };

    useEffect(() => {
        const eventName = "closeInstructions";
        document.addEventListener(eventName, onCloseInstructions);
        return () =>
            document.removeEventListener(eventName, onCloseInstructions);
        // eslint-disable-next-line
    }, []);

    if (instructions.length === 1) {
        return createRawHTMLNode(instructions[0], "instructionsContainer");
    } else {
        return createCarousel(instructions);
    }
};

const mapStateToProps = state => ({
    instructions: getInstructions(state)
});

Instructions = connect(mapStateToProps)(Instructions);

const createRawHTMLNode = (htmlString, className) => {
    return <RawHTML htmlString={htmlString} className={className} />;
};

const createCarousel = htmlStrings => {
    const slideNodes = htmlStrings.map(htmlString => (
        <RawHTML
            htmlString={htmlString}
            className={"instructionsCarouselSlide image"}
        />
    ));
    return (
        <div className="container-qm instructionsCarouselContainer">
            <Carousel interval={null}>
                {slideNodes.map((slide, idx) => (
                    <Carousel.Item key={idx}>{slide}</Carousel.Item>
                ))}
            </Carousel>

            <ActionButton
                className="button is-primary closeInstructionsButton"
                onClick={e =>
                    document.dispatchEvent(new Event("closeInstructions"))
                }
            >
                Understood {"–>"}
            </ActionButton>
        </div>
    );
};

export default Instructions;
