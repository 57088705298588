import { ActionTypes } from "../actions";
import generateReducer from "../generateReducer";

export const taskOutputsReducer = generateReducer([], {
    [ActionTypes.SET_TASK_OUTPUTS]: (taskOutputs, action) => {
        return action.taskOutputs;
    },
    [ActionTypes.PUSH_TASK_OUTPUT]: (taskOutputs, action) => {
        return [...taskOutputs, action.taskOutputs];
    },
    [ActionTypes.POP_TASK_OUTPUT]: (taskOutputs, action) => {
        const copy = [...taskOutputs];
        copy.pop();
        return copy;
    }
});

// return copied array instead of actual array
export const getTaskOutputs = state => {
    return [...state.taskOutputs];
};
