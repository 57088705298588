import { ActionTypes } from "../actions";
import generateReducer from "../generateReducer";

const initialCurrentTaskOutput = null;

/**
 * currentTaskOutput has to be reset when current taskInput changes. This doesn't happen automatically!
 */
export const currentTaskOutputReducer = generateReducer(
    initialCurrentTaskOutput,
    {
        [ActionTypes.SET_CURRENT_TASK_OUTPUT]: (currentTaskOutput, action) => {
            return action.currentTaskOutput;
        }
    }
);

export const getCurrentTaskOutput = state => state.currentTaskOutput;
