import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Slider from "rc-slider";
import { GlobalHotKeys } from "react-hotkeys";

import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";

import "rc-slider/assets/index.css";
import "./HorizontalSlider.css";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const MultimediaElements = ({
    taskUIContext,
    currentTaskIdx,
    guiSettings,
    setCurrentTaskOutput
}) => {
    const [currentAnswer, setCurrentAnswer] = useState(null);
    const [defaultAnswer, setDefaultAnswer] = useState(null);

    const { layout = "slider", interaction_settings = {} } = guiSettings;

    // init default value once
    useEffect(() => {
        if (interaction_settings.slider_settings) {
            let defaultValue =
                interaction_settings.slider_settings.default_value;
            if (defaultValue === undefined || defaultValue === "no_value") {
                setCurrentAnswer(null);
                setDefaultAnswer(null);
            } else {
                setCurrentAnswer(defaultValue);
                setDefaultAnswer(defaultValue);
            }
        }
        // eslint-disable-next-line
    }, []);

    // reset currentAnswer to the default value
    useEffect(() => {
        setCurrentAnswer(defaultAnswer);
        // eslint-disable-next-line
    }, [currentTaskIdx]);

    // update currentTaskOutput when currentAnswerChanges
    useEffect(() => {
        setCurrentTaskOutput(
            taskUIContext
                .getCurrentGuiObject()
                .makeTaskOutputForCurrentTask({ answer: currentAnswer })
        );
        // eslint-disable-next-line
    }, [currentAnswer]);

    const guiObject = taskUIContext.getCurrentGuiObject();

    if (
        !taskUIContext
            .getCurrentGuiObject()
            .taskInputResourcesInCache(currentTaskIdx)
    ) {
        return <LoadingAnimation />;
    }
    taskUIContext.onViewReady();

    const taskInput = taskUIContext.getCurrentTaskInput();

    return makeLayout(
        taskInput.media_elements,
        layout,
        interaction_settings,
        currentTaskIdx,
        currentAnswer,
        setCurrentAnswer,
        guiObject
    );
};

/**
 * @typedef {Object} MediaElement
 * @property {String} type
 * @property {String} url
 * @property {String} label
 * @property {Number} max_playbacks
 */

/**
 * @typedef {Object} InteractionSettings
 * @property {String} type
 * @property {SliderSettings} slider_settings
 * @property {String} label
 */

/**
 * @typedef {Object} SliderSettings
 * @property {String} type
 * @property {Number[]} range
 * @property {Number} step_size
 * @property {Number} default_value
 * @property {SliderMark[]} marks
 */

/**
 * @typedef {Object} SliderMark
 * @property {String} type
 * @property {String|Number} label
 * @property {Number} at
 * @property {Number} width
 * @property {Number} height
 * @property {String} key
 */

/**
 * Renders layout depending on the settings.
 *
 * @param {MediaElement[]} mediaElements
 * @param {String} layoutType
 * @param {InteractionSettings} interactionSettings
 * @param {Number} key
 * @param {String|Number} currentAnswer
 * @param {(newAnswer: String|Number) => {}} setCurrentAnswer
 * @param {import("./MultimediaQuestion").MultimediaQuestion} guiObject
 */
const makeLayout = (
    mediaElements,
    layoutType,
    interactionSettings,
    key,
    currentAnswer,
    setCurrentAnswer,
    guiObject
) => {
    if (layoutType === "single_row") {
        // bootstrap grid has 12 cols. 2 are reserved for the interaction element
        const colsPerMediaElement =
            mediaElements.length <= 10
                ? Math.floor(10 / mediaElements.length)
                : 1;
        return (
            <Row key={key}>
                {mediaElements.map((mediaElement, idx) => {
                    return (
                        <Col
                            key={idx}
                            lg={colsPerMediaElement}
                            style={{
                                // col needs a non-relative maxHeight so that dynamic content inside knows to stay inside its parent
                                maxHeight: "75vh",
                                textAlign: "center"
                            }}
                        >
                            {makeMediaElement(idx, mediaElement, guiObject)}
                            {mediaElement.label ? (
                                <Row>
                                    <Col style={{ textAlign: "center" }}>
                                        <h4>{mediaElement.label}</h4>
                                    </Col>
                                </Row>
                            ) : null}
                        </Col>
                    );
                })}
                <Col lg={2}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "95%"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                height: "90%"
                            }}
                        >
                            {makeInteractionElement(
                                interactionSettings,
                                currentAnswer,
                                setCurrentAnswer
                            )}
                        </div>
                    </div>
                    {interactionSettings.label ? (
                        <Row>
                            <Col style={{ textAlign: "center" }}>
                                <h4>{interactionSettings.label}</h4>
                            </Col>
                        </Row>
                    ) : null}
                </Col>
            </Row>
        );
    } else if (layoutType === "two_rows") {
        // bootstrap grid has 12 cols. None are reserved for the interaction element
        const colsPerMediaElement =
            mediaElements.length <= 12
                ? Math.floor(12 / mediaElements.length)
                : 1;
        return (
            <>
                <Row key={key}>
                    {mediaElements.map((mediaElement, idx) => {
                        return (
                            <Col key={idx} lg={colsPerMediaElement}>
                                {makeMediaElement(idx, mediaElement, guiObject)}
                                {mediaElement.label ? (
                                    <Row>
                                        <Col
                                            style={{
                                                textAlign: "center",
                                                marginTop: "-30px"
                                            }}
                                        >
                                            <h4>{mediaElement.label}</h4>
                                        </Col>
                                    </Row>
                                ) : null}
                            </Col>
                        );
                    })}
                </Row>
                <Row>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                        <div
                            style={{
                                width: "50%",
                                marginTop: "10px",
                                marginBottom: "80px"
                            }}
                        >
                            {interactionSettings.label ? (
                                <h4>{interactionSettings.label}</h4>
                            ) : null}
                            {makeInteractionElement(
                                interactionSettings,
                                currentAnswer,
                                setCurrentAnswer
                            )}
                        </div>
                    </Col>
                </Row>
            </>
        );
    } else {
        // TODO: support more layout types
        return null;
    }
};

/**
 * Renders a media element.
 *
 * @param {Number} idx
 * @param {MediaElement} mediaElement
 * @param {import("./MultimediaQuestion").MultimediaQuestion} guiObject
 */
const makeMediaElement = (idx, mediaElement, guiObject) => {
    switch (mediaElement.type) {
        case "image":
            const imageID = "image_" + idx;
            return (
                <>
                    <img
                        key={mediaElement.url + idx}
                        id={imageID}
                        src={mediaElement.url}
                        alt=""
                        style={{
                            maxWidth: "100%",
                            height: "auto",
                            // make sure to leave enough vertical space for the label
                            maxHeight: "95%"
                        }}
                        title={mediaElement.url}
                    />
                </>
            );
        case "video":
            const videoID = "video_" + idx;
            const playedLabel =
                "played_" + (mediaElement.label ? mediaElement.label : idx);
            const endedLabel =
                "ended_" + (mediaElement.label ? mediaElement.label : idx);
            const playbackCounterID = "playback_counter_" + idx;
            /**
             *
             * @param {import("react").SyntheticEvent} e
             */
            const onPlayCallback = e => {
                const playedCnt = guiObject.actionTelemetries.has(playedLabel)
                    ? guiObject.actionTelemetries.get(playedLabel).cnt
                    : 0;
                let canPlay = true;
                if (mediaElement.max_playbacks) {
                    if (playedCnt >= mediaElement.max_playbacks) {
                        canPlay = false;
                    } else {
                        const counter = document.getElementById(
                            playbackCounterID
                        );
                        counter.innerText = `Video plays left: ${
                            mediaElement.max_playbacks - (playedCnt + 1)
                        }`;
                    }
                }
                if (canPlay) {
                    guiObject.onAction(playedLabel);
                } else {
                    // pause playback, disable controls, set mouse cursor to not-allowed and add overlay
                    /** @type{HTMLVideoElement} */
                    const video = document.getElementById(videoID);
                    video.pause();
                    video.controls = false;
                    video.style.cursor = "not-allowed";
                }
            };
            const onEndCallback = e => {
                guiObject.onAction(endedLabel);
            };
            return (
                <div style={{ height: "65vh", textAlign: "center" }}>
                    {/*
                        we need a doubled div here in order to have a tight div around the video so that
                        the playback counter can be rendered on top
                    */}
                    <div
                        style={{
                            margin: "auto",
                            width: "fit-content",
                            maxWidth: "100%",
                            height: "95%",
                            // make sure to leave enough vertical space for the label
                            maxHeight: "95%"
                        }}
                    >
                        <video
                            key={mediaElement.url + idx}
                            id={videoID}
                            controls={true}
                            preload={"auto"}
                            style={{
                                width: "auto",
                                maxWidth: "100%",
                                height: "100%"
                            }}
                            title={mediaElement.url}
                            onPlay={onPlayCallback}
                            onEnded={onEndCallback}
                        >
                            <source src={mediaElement.url} />
                        </video>
                        {mediaElement.max_playbacks ? (
                            <div
                                className="counter_overlay"
                                style={{
                                    position: "absolute",
                                    top: "0%",
                                    left: "auto"
                                }}
                            >
                                <p
                                    id={playbackCounterID}
                                    style={{
                                        color: "red",
                                        fontSize: "15px",
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        padding: 0
                                    }}
                                >
                                    Video plays left:{" "}
                                    {mediaElement.max_playbacks}
                                </p>
                            </div>
                        ) : null}
                    </div>
                </div>
            );
        default:
            // TODO: support more media types
            return null;
    }
};

/**
 * Renders the interaction component.
 *
 * @param {InteractionSettings} interactionSettings
 * @param {String|Number} currentAnswer
 * @param {(newAnswer: String|Number) => {}} setCurrentAnswer
 */
const makeInteractionElement = (
    interactionSettings,
    currentAnswer,
    setCurrentAnswer
) => {
    if (interactionSettings.type === "slider") {
        let { slider_settings: settings } = interactionSettings;
        if (settings.type === "discrete_marks") {
            const marks = {};
            const hotkeyKeyMap = {};
            const hotkeyHandlers = {};
            // setup markers for the slider
            for (const mark of settings.marks) {
                if (mark.type === "string") {
                    marks[mark.at] = mark.label;
                } else if (mark.type === "image") {
                    const { width, height } = mark;

                    // add as hotkey
                    if (mark.key !== undefined) {
                        hotkeyKeyMap[`trigger_${mark.key}`] = mark.key;
                        hotkeyHandlers[`trigger_${mark.key}`] = () =>
                            setCurrentAnswer(mark.at);
                    }
                    marks[mark.at] = (
                        <img
                            src={mark.label}
                            alt=""
                            style={{
                                width: `${width}px`,
                                height: `${height}px`,
                                // position the image center to be at the height of the slider tick
                                position: "absolute",
                                top: -height * 0.666,
                                left: 5
                            }}
                            title={
                                mark.key !== undefined
                                    ? `hotkey: ${mark.key}`
                                    : null
                            }
                        />
                    );
                }
            }

            return (
                <>
                    <Slider
                        vertical
                        min={settings.range[0]}
                        max={settings.range[1]}
                        marks={marks}
                        step={settings.step_size}
                        value={currentAnswer}
                        handleStyle={{
                            // the large dot at selected value
                            borderColor: "#D5A",
                            height: 25,
                            width: 25,
                            marginLeft: -11, // to center horizontally on track/rail
                            backgroundColor: "#FFF"
                        }}
                        trackStyle={{ backgroundColor: "#D5A" }} // line from min to handle dot
                        railStyle={{ backgroundColor: "#999" }} // line from handle dot to max
                        dotStyle={{
                            border: "1px solid #D5A",
                            width: 12,
                            height: 12,
                            marginLeft: -6 // to center horizontally on track/rail
                        }} // small dots
                        onChange={setCurrentAnswer}
                    />
                    <GlobalHotKeys
                        keyMap={hotkeyKeyMap}
                        handlers={hotkeyHandlers}
                        allowChanges={false}
                    />
                </>
            );
        } else if (settings.type === "horizontal") {
            const marks = {};
            const hotkeyKeyMap = {};
            const hotkeyHandlers = {};
            // setup markers for the slider
            for (const mark of settings.marks) {
                if (mark.type === "string") {
                    marks[mark.at] = mark.label;
                } else if (mark.type === "image") {
                    const { width, height } = mark;

                    // add as hotkey
                    if (mark.key !== undefined) {
                        hotkeyKeyMap[`trigger_${mark.key}`] = mark.key;
                        hotkeyHandlers[`trigger_${mark.key}`] = () =>
                            setCurrentAnswer(mark.at);
                    }
                    marks[mark.at] = (
                        <img
                            className="rc_slider_mark_image"
                            src={mark.label}
                            alt=""
                            style={{
                                width: `${width}px`,
                                height: `${height}px`
                            }}
                            title={
                                mark.key !== undefined
                                    ? `hotkey: ${mark.key}`
                                    : null
                            }
                        />
                    );
                }
            }

            return (
                <>
                    <Slider
                        className="rc_slider_horizontal"
                        min={settings.range[0]}
                        max={settings.range[1]}
                        marks={marks}
                        step={settings.step_size}
                        value={currentAnswer}
                        handleStyle={{
                            // the large dot at selected value
                            borderColor: "#D5A",
                            height: 25,
                            width: 25,
                            marginTop: -11, // to center vertically on track/rail
                            backgroundColor: "#FFF",
                            cursor: "grab"
                        }}
                        trackStyle={{
                            backgroundColor: "#D5A",
                            cursor: "pointer"
                        }} // line from min to handle dot
                        railStyle={{
                            backgroundColor: "#999",
                            cursor: "pointer"
                        }} // line from handle dot to max
                        dotStyle={{
                            width: 0,
                            height: 0,
                            border: "none"
                        }} // no dots
                        onChange={setCurrentAnswer}
                    />
                    <div>
                        <img
                            src="/images/gradient_large.svg"
                            style={{
                                width: "45%",
                                position: "absolute",
                                top: "30%",
                                left: "27.5%",
                                transform: "scaleY(0.5)"
                            }}
                            alt=""
                        />
                    </div>
                    <GlobalHotKeys
                        keyMap={hotkeyKeyMap}
                        handlers={hotkeyHandlers}
                        allowChanges={false}
                    />
                </>
            );
        }
    } else {
        // TODO: support more interaction types
        return null;
    }
};

export default MultimediaElements;
