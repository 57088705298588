import {
    taskFetch,
    taskReceived,
    taskFetchError,
    setGuiSettings,
    addResourceToCache,
    taskSubmit,
    taskSubmitSuccess,
    taskSubmitError,
    setNextGuiType,
    setTasksAmount,
    setGuiType,
    nextTask,
    previousTask,
    pushTaskOutput,
    popTaskOutput,
    setTaskResult,
    addResourceLoadingError,
    setCurrentTaskOutput,
    setTaskSubmitEnabled
} from "../../store/actions";
import SubmitTaskGroupRequest from "../ApiClient/models/SubmitTaskGroupRequest";
import { TasksSDKResult } from "../TasksSdk/TasksSDKResult";
import { TasksSDKReward } from "../TasksSdk/TasksSDKReward";
import { GoliatApiClient } from "../ApiClient/GoliatApiClient";
import { checkMapHasKeys, getClientVersion, MouseTracker } from "../qm_cs_lib";
import { WIDGETS } from "../../guiFactory";
import {
    GUITYPE_REGISTRY,
    throwGuiTypeUnknownError
} from "../../guitypes/GuiTypeRegistry";
import { getCurrentTaskIdx } from "../../store/reducers/currentTaskIdx";
import {
    getTaskInputAtIdx,
    getTaskInputs,
    getTaskFetchResult
} from "../../store/reducers/taskFetchRequest";
import { getTasksAmount } from "../../store/reducers/tasksAmount";
import { getTaskOutputs } from "../../store/reducers/answers";
import { getGuiType } from "../../store/reducers/guiType";
import {
    getInstructions,
    getGuiSettings
} from "../../store/reducers/guiSettings";
import { getTaskSubmitRequest } from "../../store/reducers/taskSubmitRequest";
import { getCurrentTaskOutput } from "../../store/reducers/currentTaskOutput";
import {
    getResourceCache,
    getResourceLoadingErrors
} from "../../store/reducers/resourceCache";
import TimeKeeper from "./TimeKeeper";

export class TaskUIContext {
    /**
     * @param {Map<String, String>|URLSearchParams} queryParams
     * @param {import("redux").Store} store
     * @param {GoliatApiClient | import("../../guitypes/MockData/MockData").MockApiClient=} apiClient default null
     */
    constructor(queryParams, store, apiClient = null) {
        this.queryParams = queryParams;
        this.apiClient = apiClient;
        this.store = store;

        /**
         * TODO: do we really need that anymore? This is fucky code-style and we should move that data to appropriate places.
         *       --> this was added initially in order to easily distinguish specific settings for the different backends and guitypes
         *           For example: PointcloudResultsView also uses this feature
         *       --> is used for storing the user_id which is in turn used in handling TasksSDKResult.
         *           Add a "user" in a proper context and this can be removed
         * Any additional data we want to store, either from a guitype or the TaskUIContext itself
         * @type {Map<String, any}
         */
        this._specificVars = new Map();

        this._initialGuiType = WIDGETS.LOADING;
        // will be set to true after initialization is successful
        this._shouldFetchTasks = false;

        /**
         * @type {import("../../guitypes/BaseGuiType").BaseGuiType}
         */
        this._currentGuiObject = null;

        this._shouldShowInstructionsFirst = true;

        this._timer = new TimeKeeper();

        /**
         * @type {import("../qm_cs_lib").MouseTracker}
         */
        this._globalMouseTracker = null;
    }

    static LOADING_TIME_RESOURCE_PREFIX = "loadingTimeMillis_";

    init() {
        try {
            if (this.queryParams === null) {
                throw new Error("Query parameters are null");
            }

            const allParamsOk = this.checkRequiredQueryParams();
            if (allParamsOk) {
                if (this.apiClient === null) {
                    this.apiClient = new GoliatApiClient({
                        endpoint: this.queryParams.get("task_endpoint"),
                        vendor_id: this.queryParams.get("vendor_id"),
                        vendor_user_id: this.queryParams.get("vendor_user_id"),
                        // optional
                        project_id: this.queryParams.get("project_id"),
                        project_node_id:
                            this.queryParams.get("project_node_id"),
                        project_ids: this.queryParams.get("project_ids"),
                        project_node_ids:
                            this.queryParams.get("project_node_ids"),
                        submission_token:
                            this.queryParams.get("submission_token")
                    });
                    this.checkClientServerVersions();
                }
            } else {
                this._initialGuiType = WIDGETS.GENERIC_ERROR;
                throw new Error("Missing query parameters");
            }

            this.handleQueryParamDependentActions();
        } catch (err) {
            console.error(err);
        } finally {
            this.store.dispatch(setNextGuiType(this.getInitialGuiType()));
        }
    }

    async checkClientServerVersions() {
        this.apiClient.getVersion().then(data => {
            const clientVersion = getClientVersion();
            if (data.version !== clientVersion) {
                console.warn(
                    `Mismatch of client (${clientVersion}) and server (${data.version}) versions!`
                );
            }
        });
    }

    /**
     * @returns {Number}
     */
    getCurrentTaskIdx() {
        return getCurrentTaskIdx(this.store.getState());
    }

    /**
     * @returns {Object[]}
     */
    getTaskInputs() {
        return getTaskInputs(this.store.getState());
    }

    /**
     * @returns {Object}
     */
    getGuiSettings() {
        return getGuiSettings(this.store.getState());
    }

    /**
     * @returns {Object}
     */
    getCurrentTaskInput() {
        const state = this.store.getState();
        const idx = getCurrentTaskIdx(state);
        const currentTaskInput = getTaskInputAtIdx(state, idx);
        if (!currentTaskInput) {
            throw new Error(`Current task_input at idx ${idx} is not set`);
        }
        return currentTaskInput;
    }

    /**
     * @returns {any}
     */
    getCurrentTaskOutput() {
        return getCurrentTaskOutput(this.store.getState());
    }

    /**
     * @return {Object}
     */
    getResourceCache() {
        return getResourceCache(this.store.getState());
    }

    /**
     * @return {Object}
     */
    getResourceLoadingErrors() {
        return getResourceLoadingErrors(this.store.getState());
    }

    /**
     * @returns {Boolean} whether a PREVIOUS_TASK action was dispatched
     */
    dispatchPreviousTask() {
        const state = this.store.getState();
        const idx = getCurrentTaskIdx(state);
        if (idx - 1 < 0) {
            return false;
        }
        this.store.dispatch(previousTask());
        this.store.dispatch(
            setCurrentTaskOutput(
                this.getCurrentGuiObject().getInitialCurrentTaskOutput()
            )
        );
        // reset submit of single task enabled
        this.store.dispatch(setTaskSubmitEnabled(false));
        this.getCurrentGuiObject().onDispatchPreviousTask();
        return true;
    }

    /**
     * If there are as many task_outputs as there are task_inputs
     * - trigger submit
     * If there are less task_outputs than task_inputs
     * - dispatch switch to next task_input
     *
     * @returns {Boolean} whether a NEXT_TASK action was dispatched
     */
    dispatchNextTaskOrSubmit() {
        const state = this.store.getState();
        const taskInputs = getTaskInputs(state);
        const taskOutputs = getTaskOutputs(state);

        if (taskInputs.length === taskOutputs.length) {
            // submit
            const resultsSubmitObject =
                this.createResultsSubmitObject(taskOutputs);
            this.store.dispatch(setTaskResult(resultsSubmitObject));

            this.submitTaskResults(resultsSubmitObject)
                .then(success => {
                    // console.log("success", success);
                })
                .catch(err => {
                    console.error(err);
                });
            return false;
        }
        this.store.dispatch(nextTask());
        this.store.dispatch(
            setCurrentTaskOutput(
                this.getCurrentGuiObject().getInitialCurrentTaskOutput()
            )
        );
        // reset submit of single task enabled
        this.store.dispatch(setTaskSubmitEnabled(false));
        this.getCurrentGuiObject().onDispatchNextTask();
        return true;
    }

    /**
     * Adds a new taskOutput and adds the duration in milliseconds to it.
     * Also clears the timer for the taskInput
     *
     * @param {Object} taskOutput
     * @returns {Boolean} whether the taskOutput was pushed
     */
    pushTaskOutput(taskOutput) {
        const state = this.store.getState();
        const tasksAmount = getTasksAmount(state);
        const taskOutputs = getTaskOutputs(state);
        if (taskOutputs.length < tasksAmount) {
            const taskOutputWithDuration = {
                ...taskOutput,
                duration_ms: this.getDurationMillisForCurrentTaskAndResetTimer()
            };

            if (this._globalMouseTracker) {
                taskOutputWithDuration.mouse_data =
                    this._globalMouseTracker.getSerializableMouseTrackingData();
                this._globalMouseTracker.clear();
            }

            this.store.dispatch(pushTaskOutput(taskOutputWithDuration));
            console.log(
                "%cAdded TaskOutput",
                "background-color: green; color: white;",
                taskOutputWithDuration
            );
            return true;
        }
        return false;
    }

    /**
     * Removes the latest taskOutput and clears the timer for that taskInput and the current taskInput
     *
     * @returns {Boolean} whether a taskOutput was popped
     */
    popTaskOutput() {
        const state = this.store.getState();
        const taskOutputs = getTaskOutputs(state);
        if (taskOutputs.length === 0) {
            return false;
        }
        const taskOutput = taskOutputs[taskOutputs.length - 1];
        this.store.dispatch(popTaskOutput());
        console.log(
            "%cRemoved TaskOutput",
            "background-color: red; color: white;",
            taskOutput
        );

        const currentTaskIdx = this.getCurrentTaskIdx();
        const currentTimerName = currentTaskIdx + "";
        const previousTimerName = currentTaskIdx - 1 + "";
        this._timer.startTimes.delete(currentTimerName);
        this._timer.startTimes.delete(previousTimerName);
        this._timer.endTimes.delete(previousTimerName);
        return true;
    }

    /**
     * @returns {Boolean}
     */
    shouldFetchTasks() {
        return this._shouldFetchTasks;
    }

    /**
     * @returns {Boolean}
     */
    checkRequiredQueryParams() {
        // special case for vendor_id === bytro or redmoon:
        // - hardcode the task_endpoint if it's not set already
        // - project_id is not needed
        // - only other param that's needed is vendor_user_id
        if (!this.queryParams.has("vendor_id")) {
            return false;
        }
        if (
            (this.queryParams.get("vendor_id") === "redmoon" ||
                this.queryParams.get("vendor_id") === "bytro") &&
            this.queryParams.has("vendor_user_id")
        ) {
            if (!this.queryParams.has("task_endpoint")) {
                this.queryParams.set(
                    "task_endpoint",
                    `https://api.${this.queryParams.get(
                        "vendor_id"
                    )}.qm-annotations.com`
                );
            }
            return true;
        }

        const requiredQueryParamNames = [
            // all have to exist
            "task_endpoint",
            "vendor_id",
            "vendor_user_id"
        ];
        const exactlyOneParamGroup =
            // exactly one of these has to exist
            [
                "project_id",
                "project_ids",
                "project_node_id",
                "project_node_ids"
            ];
        const requiredParamsOk = checkMapHasKeys(
            this.queryParams,
            requiredQueryParamNames
        );

        let exactlyOneParamGroupCnt = 0;
        let exactlyOneParamGroupOk = false;
        for (const param of exactlyOneParamGroup) {
            if (this.queryParams.has(param)) {
                if (exactlyOneParamGroupCnt === 0) {
                    exactlyOneParamGroupOk = true;
                    exactlyOneParamGroupCnt++;
                } else {
                    exactlyOneParamGroupOk = false;
                }
            }
        }

        return requiredParamsOk && exactlyOneParamGroupOk;
    }

    /**
     * @returns {String}
     */
    getInitialGuiType() {
        return this._initialGuiType;
    }

    /**
     * @returns {import("../../guitypes/BaseGuiType").BaseGuiType}
     */
    getCurrentGuiObject() {
        return this._currentGuiObject;
    }

    /**
     * @throws {Error}
     */
    handleQueryParamDependentActions() {
        // when results_url and gui_type are set, then open the guitype directly
        if (
            this.queryParams.has("results_url") &&
            this.queryParams.has("gui_type")
        ) {
            this._specificVars.set(
                "results_url",
                this.queryParams.get("results_url")
            );
            const guiType = this.queryParams.get("gui_type");

            if (GUITYPE_REGISTRY.has(guiType)) {
                this._initialGuiType = guiType;
            } else {
                this._initialGuiType = WIDGETS.GENERIC_ERROR;
                throwGuiTypeUnknownError(guiType);
            }
        }

        // check whether vendor_id and vendor_user_id have sensible values
        const vendor_id = this.queryParams.get("vendor_id");
        const vendor_user_id = this.queryParams.get("vendor_user_id");
        const defaultValues = [
            "CHANGE_ME",
            "CHANGE_THIS",
            "CHANGEME",
            "CHANGETHIS"
        ];
        if (
            !vendor_id ||
            !vendor_user_id ||
            defaultValues.includes(vendor_id.toUpperCase()) ||
            defaultValues.includes(vendor_user_id.toUpperCase())
        ) {
            this._initialGuiType = WIDGETS.MALFORMED_USER_IDS;
            throw new Error("Malformed user ids");
        }

        // override showing instructions
        if (this.queryParams.has("disable_instructions")) {
            this._shouldShowInstructionsFirst = false;
        }

        this._shouldFetchTasks = true;
    }

    /**
     * This method is expected to set the questionsAmount via a redux action.
     *
     * @returns {Promise<Boolean>} resolves to success or not
     */
    fetchTasks() {
        const { dispatch } = this.store;
        dispatch(taskFetch());

        return this.apiClient
            .getTaskGroup()
            .then(data => {
                dispatch(taskReceived(data));

                const vendor_id = this.queryParams.get("vendor_id");
                // check if user is blocked
                if (data.user_is_blocked) {
                    let userBlockedViewName = WIDGETS.USER_IS_BLOCKED;
                    // TODO: there shouldn't be a hardcoded vendor_id check in here!
                    if (vendor_id === "redmoon" || vendor_id === "bytro") {
                        userBlockedViewName = WIDGETS.USER_IS_BLOCKED_GAMING;
                    }
                    dispatch(setNextGuiType(userBlockedViewName));
                    console.error("User is blocked");
                    return false;
                }

                // check if there are any task_inputs
                if (!data.has_tasks) {
                    let noTasksViewName = WIDGETS.NO_TASKS_AVAILABLE;
                    if (vendor_id === "redmoon" || vendor_id === "bytro") {
                        noTasksViewName = WIDGETS.USER_IS_BLOCKED_GAMING;
                    }
                    dispatch(setNextGuiType(noTasksViewName));

                    console.error("No more tasks available");
                    return false;
                }

                // remember user_id from goliat backend
                this._specificVars.set("user_id", data.group_ack.user);

                // add mouse tracking if configured
                if (data.gui_settings.with_mouse_tracking) {
                    try {
                        this._globalMouseTracker = new MouseTracker();
                        this._globalMouseTracker.initElementWithListeners(
                            document,
                            data.gui_settings.mouse_tracking_throttle_hz
                        );
                    } catch (err) {
                        dispatch(setNextGuiType(WIDGETS.GENERIC_ERROR));
                        throw err;
                    }
                }

                dispatch(setGuiSettings(data.gui_settings));
                dispatch(setGuiType(data.gui_type));
                dispatch(setTasksAmount(data.task_inputs.length));

                // setup guitype and preload resources
                if (!GUITYPE_REGISTRY.has(data.gui_type)) {
                    dispatch(setNextGuiType(WIDGETS.GENERIC_ERROR));
                    throwGuiTypeUnknownError(data.gui_type);
                }
                const guiTypeClass = GUITYPE_REGISTRY.get(data.gui_type);
                this._currentGuiObject = new guiTypeClass(this);

                // remove unicode BOM (byte order mark) character from any resource url with key containing "url"
                for (const taskInput of data.task_inputs) {
                    for (const key in taskInput) {
                        try {
                            // check if entry is valid URL (if BOM contained, this will throw an error)
                            new URL(taskInput[key]);
                        } catch (_) {
                            const url = taskInput[key];
                            if (
                                key.includes("url") &&
                                url !== undefined &&
                                url instanceof String &&
                                url.charCodeAt(0) === 0xfeff
                            ) {
                                taskInput[key] = url.substr(1);
                            }
                        }
                    }
                }

                this.loadResources(data.task_inputs);

                // decide to show instructions first or not
                if (
                    this.hasInstructions() &&
                    this._shouldShowInstructionsFirst
                ) {
                    const instructionsViewName =
                        this.getCurrentGuiObject().getInstructionsViewName();
                    dispatch(setNextGuiType(instructionsViewName));
                } else {
                    dispatch(setNextGuiType(data.gui_type));
                }

                // set initial currentTaskOutput
                dispatch(
                    setCurrentTaskOutput(
                        this.getCurrentGuiObject().getInitialCurrentTaskOutput()
                    )
                );
                return true;
            })
            .catch(err => {
                dispatch(taskFetchError(err));
                dispatch(setNextGuiType(WIDGETS.GENERIC_ERROR));
                throw err;
            });
    }

    /**
     * @param {Object[]} taskInputs
     */
    loadResources(taskInputs) {
        // construct resourceLoaders
        const resourceLoaderService =
            this.getCurrentGuiObject().getResourceLoaderService();
        resourceLoaderService.initTaskInputLoaderMaps(taskInputs);
        const resourceLoaders =
            resourceLoaderService.getResourceLoaderMapForAllTaskInputs();

        // execute resourceLoaders
        for (const [url, resourceLoader] of resourceLoaders.entries()) {
            resourceLoader
                .loadAndDecode()
                .then(resource => {
                    const loadingTimeMillis =
                        resourceLoader.calcLoadingTimeMillis();
                    this.store.dispatch(addResourceToCache(url, resource));
                    this.store.dispatch(
                        addResourceToCache(
                            TaskUIContext.LOADING_TIME_RESOURCE_PREFIX + url,
                            loadingTimeMillis
                        )
                    );
                })
                .catch(err => {
                    this.store.dispatch(
                        addResourceLoadingError(url, err.toString())
                    );
                    console.error(err);
                });
        }
    }

    /**
     * @param {Object[]} taskOutputs
     * @returns {SubmitTaskGroupRequest}
     */
    createResultsSubmitObject(taskOutputs) {
        const groupAck = getTaskFetchResult(this.store.getState()).group_ack;
        const resultsSubmitObject = new SubmitTaskGroupRequest(
            groupAck,
            taskOutputs
        );
        return resultsSubmitObject;
    }

    /**
     * @param {SubmitTaskGroupRequest} taskResults
     * @returns {Promise<Boolean>} resolves to success or not
     */
    submitTaskResults(taskResults) {
        const { dispatch } = this.store;
        dispatch(taskSubmit());
        return this.apiClient
            .submitTaskGroupResults(taskResults)
            .then(data => {
                dispatch(taskSubmitSuccess(data));
                const thankYouViewName =
                    this.getCurrentGuiObject().getThankYouViewName();
                dispatch(setNextGuiType(thankYouViewName));
                return true;
            })
            .catch(err => {
                dispatch(taskSubmitError(err));
                dispatch(setNextGuiType(WIDGETS.GENERIC_ERROR));
                throw err;
            });
    }

    /**
     * Open the instructions view that's configured by the guitype object.
     */
    openInstructions() {
        const instructionsViewName =
            this.getCurrentGuiObject().getInstructionsViewName();
        this.store.dispatch(setNextGuiType(instructionsViewName));
    }

    closeInstructions() {
        const currentGuiObjectGuiType = getGuiType(this.store.getState());
        this.store.dispatch(setNextGuiType(currentGuiObjectGuiType));
    }

    /**
     * @returns {Boolean}
     */
    hasInstructions() {
        const state = this.store.getState();
        if (
            !getGuiSettings(state) ||
            !getInstructions(state) ||
            getInstructions(state) === "" ||
            (Array.isArray(getInstructions(state)) &&
                getInstructions(state).length === 0)
        ) {
            return false;
        }
        return true;
    }

    /**
     * Whether an ActionButton should be disabled.
     * --> When the resources of current taskInput haven't finished loading, yet
     * --> While submit is pending
     */
    shouldActionButtonsBeDisabled() {
        const state = this.store.getState();
        const disableButtonDuringSubmit = getTaskSubmitRequest(state).pending;
        if (disableButtonDuringSubmit) {
            return true;
        }

        return !this.getCurrentGuiObject().taskInputResourcesInCache(
            this.getCurrentTaskIdx()
        );
    }

    /**
     * Anything that can be run every time the View is ready.
     * --> start measuring time for the current task input
     */
    onViewReady() {
        const timerName = this.getCurrentTaskIdx() + "";
        if (!this._timer.startTimes.has(timerName)) {
            this._timer.startFor(timerName);
        }
    }

    /**
     * Calculate duration of current task and reset timer for it
     */
    getDurationMillisForCurrentTaskAndResetTimer() {
        const currentTaskIdx = this.getCurrentTaskIdx();
        const timerName = currentTaskIdx + "";

        // special case: a task with resource load errors doesn't have a timer --> no duration
        if (
            this.getCurrentGuiObject().taskInputResourcesLoadedWithError(
                currentTaskIdx
            )
        ) {
            return 0;
        }

        const durationMillis = this._timer.endForAndMeasure(timerName);
        this._timer.startTimes.delete(timerName);
        this._timer.endTimes.delete(timerName);
        return durationMillis;
    }

    /**
     * Calculate duration of current task (read only).
     */
    getDurationMillisForCurrentTask() {
        const currentTaskIdx = this.getCurrentTaskIdx();
        const timerName = currentTaskIdx + "";

        // special case: a task with resource load errors doesn't have a timer --> no duration
        if (
            this.getCurrentGuiObject().taskInputResourcesLoadedWithError(
                currentTaskIdx
            )
        ) {
            return 0;
        }

        return this._timer.measure(timerName);
    }

    /**
     * If task-ui runs in TasksSDK context
     * - prepare return data for TasksSDK
     * - notify the WebView to be closed
     */
    handleTasksSDKResult() {
        // If task-ui runs in TasksSDK context, this structure exists:
        //
        // window.qualitymatch = {
        //     tasks: {
        //         completed: data => {
        //             console.log("done", data);
        //         }
        //     }
        // };

        // check whether tasks-sdk is being used and a TaskResult for it should be submitted
        if (
            window.qualitymatch === undefined ||
            window.qualitymatch.tasks === undefined ||
            window.qualitymatch.tasks.completed === undefined
        ) {
            return;
        }

        console.log("notifying tasks-sdk that task-ui is done now");

        const taskSdkResult = new TasksSDKResult();
        const taskSdkRewards = [];
        taskSdkRewards.push(
            new TasksSDKReward().setRewardId("SOME_REWARD_ID_0").setAmount(666)
        );
        taskSdkRewards.push(
            new TasksSDKReward().setRewardId("SOME_REWARD_ID_1").setAmount(666)
        );
        taskSdkResult
            // there's no taskId for goliat's TaskGroups!
            .setTaskId("THIS_IS_AN_OUTDATED_CONCEPT")
            .setUserId(this._specificVars.get("user_id"))
            .setRewards(taskSdkRewards);
        window.qualitymatch.tasks.completed(taskSdkResult);
    }

    /**
     * Emit a postMessage https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
     * to signify that the UI is finished in the case that the ui is embedded in an iframe.
     * That's why the window.parent is being used.
     */
    emitSubmissionDone() {
        const submissionDoneMessage = {
            type: "QM_SUBMISSION_DONE"
        };
        window.parent.postMessage(submissionDoneMessage, "*");
    }
}

export default TaskUIContext;
