import { ActionTypes } from "../actions";
import generateReducer from "../generateReducer";

const initialThankYouReached = false;

export const thankYouReachedReducer = generateReducer(initialThankYouReached, {
    [ActionTypes.THANK_YOU_REACHED]: (thankYouReached, action) => {
        return true;
    }
});

export const getThankYouReached = state => state.thankYouReached;
