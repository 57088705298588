import React from "react";

export const ResizeMarker = props => {
    return (
        <div
            style={{
                background: "#5ce32b",
                width: "10px",
                height: "10px",
                position: "absolute",
                borderRadius: "50%",
                border: "solid 1px black",
                ...props
            }}
        ></div>
    );
};

export default ResizeMarker;
