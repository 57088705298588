import React from "react";
import { useEffect } from "react";
import { configure, GlobalHotKeys } from "react-hotkeys";
import {
    Button,
    ButtonGroup,
    Dropdown,
    ToggleButton,
    ToggleButtonGroup
} from "react-bootstrap";
import classNames from "classnames";

import { LoadingAnimation } from "../../components/LoadingAnimation/LoadingAnimation.jsx";
import { euclidean, findCollisions } from "./geometry.js";
import { clip, locInSvg, locInElem, zip } from "./lib.js";
import { IoIosExpand, IoIosContract } from "react-icons/io";

import "./DrawPolygonsCanvas.css";

configure({
    ignoreRepeatedEventsWhenKeyHeldDown: false
});

export const Minus = () => (
    <svg
        width="1.2em"
        height="1.2em"
        viewBox="0 0 16 16"
        className="bi bi-dash qm_svg"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
        />
    </svg>
);

export const Plus = () => (
    <svg
        width="1.2em"
        height="1.2em"
        viewBox="0 0 16 16"
        className="bi bi-plus qm_svg"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
        />
    </svg>
);

const BrightnessSwitch = ({ value, setter }) => (
    <ButtonGroup
        id="brightness_switches"
        vertical
        style={{ width: "100%", marginTop: "1em" }}
    >
        <Button
            id="brightness_default"
            variant="outline-secondary"
            onClick={() => setter(100)}
        >
            Brightness
        </Button>
        <ButtonGroup style={{ fontWeight: "bold" }}>
            <Button id="brightness_minus" onClick={() => setter(value - 10)}>
                <Minus />
            </Button>
            <Button id="brightness_plus" onClick={() => setter(value + 10)}>
                <Plus />
            </Button>
        </ButtonGroup>
    </ButtonGroup>
);

const LabelRadios = ({ value, values, onChange }) => {
    const active = values.find(({ name, color }) => name === value);
    const activeColor = active ? active.color : "transparent";
    if (values.length > 5) {
        return (
            <Dropdown>
                <Dropdown.Toggle
                    id="polygonsLabel"
                    className="labelsDropdown"
                    style={{ borderRight: `10px solid ${activeColor}` }}
                >
                    {value}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {values.map(({ name, color }) => (
                        <Dropdown.Item
                            id={name}
                            key={name}
                            eventKey={name}
                            style={{ borderRight: `10px solid ${color}` }}
                            onSelect={onChange}
                        >
                            {name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
    return (
        <ToggleButtonGroup
            vertical
            type="radio"
            name="label"
            value={value}
            style={{ width: "100%", marginBottom: "1em" }}
            onChange={onChange}
        >
            {values.map(({ name, color }) => (
                <ToggleButton
                    variant="outline-secondary"
                    id={name}
                    key={name}
                    value={name}
                    style={{
                        borderRight: `10px solid ${color}`
                    }}
                >
                    {name}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

const ZoomableContainer = ({
    children,
    height,
    width,
    max = 2,
    onZoom = () => {},
    scale = 1,
    speed = 1
}) => {
    const ref = React.useRef(null);
    const [{ x, y }, setOrigin] = React.useState({
        x: width / 2,
        y: height / 2
    });
    const step = Math.min(height, width) / 2 / scale;

    /**
     * Move the origin in a given x and y direction.
     * @param {number} xd delta in x direction
     * @param {number} yd delta in y direction
     */
    const move = (xd, yd) => {
        const nx = clip(x + xd * step, 0, width);
        const ny = clip(y + yd * step, 0, height);
        setOrigin({ x: nx, y: ny });
        const style = {
            transform: `scale(${scale})`,
            transformOrigin: `${nx}px ${ny}px`
        };
        onZoom(scale, style);
    };

    /**
     * Tracks cursor position and sets origin when unzoomed
     * @param {MouseEvent} event
     */
    const handleMouseMove = event => {
        const { x, y } = locInElem(ref.current, event);
        if (scale === 1) setOrigin({ x, y });
    };

    /**
     * Sets the zoom level
     * @param {number} newScale the new zoom level
     */
    const zoom = newScale => {
        // Passing 'style' upwards is bad style and should be removed once the
        // mozilla fixes https://bugzilla.mozilla.org/show_bug.cgi?id=1610093
        const style = {
            transform: `scale(${newScale})`,
            transformOrigin: `${x}px ${y}px`
        };
        onZoom(newScale, style);
    };

    return (
        <div className="zoomableContainer">
            <GlobalHotKeys
                keyMap={{
                    ZOOM_IN: ["plus", "ZoomIn"],
                    ZOOM_OUT: ["-", "ZoomOut"],
                    UP: ["up"],
                    RIGHT: ["right"],
                    DOWN: ["down"],
                    LEFT: ["left"]
                }}
                handlers={{
                    ZOOM_IN: () => zoom(Math.min(scale * 1.2, max)),
                    ZOOM_OUT: () => zoom(Math.max(scale / 1.2, 1)),
                    UP: () => move(0, -1),
                    RIGHT: () => move(1, 0),
                    DOWN: () => move(0, 1),
                    LEFT: () => move(-1, 0)
                }}
                allowChanges={true}
            />
            <div ref={ref}>
                <div
                    style={{
                        width: width,
                        height: height
                    }}
                    onMouseMove={handleMouseMove}
                    onWheel={event => {
                        const change = (event.deltaY / 100) * speed;
                        zoom(clip(scale + change, 1, max));
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

const BACKGROUND_IMAGE_KEYS = {
    IMAGE_URL: "image_url",
    ALT_IMAGE_URL: "alt_image_url"
};

/**
 * Draws polygons on an image onClick.
 *
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const DrawPolygonsCanvas = ({
    guiSettings,
    resourceCache,
    taskUIContext,
    currentTaskOutput,
    currentTaskIdx,
    setCurrentTaskOutput
}) => {
    const [activePolygonIx, setActivePolygonIx] = React.useState(-1);
    const [activePointIx, setActivePointIx] = React.useState(-1);
    const [dragging, setDragging] = React.useState(null);
    const [activeLabel, setActiveLabel] = React.useState(null);
    const [brightness, setBrightness] = React.useState(100);
    const [drawOnMouseMove, setDrawOnMouseMove] = React.useState(false);
    const [latestImage, setLatestImage] = React.useState(null);
    const [previewLinePoint, setPreviewLinePoint] = React.useState(null);
    const [zoomLevel, setZoomLevel] = React.useState(1);
    const [polygonSVGHidden, setPolygonSVGHidden] = React.useState(false);
    const [backgroundImageKey, setBackgroundImageKey] = React.useState(
        BACKGROUND_IMAGE_KEYS.IMAGE_URL
    );
    const [referenceZoom, setReferenceZoom] = React.useState(false);
    // the 'transform' state should be removed once the following firefox bug
    // got resolved https://bugzilla.mozilla.org/show_bug.cgi?id=1610093
    const [transform, setTransform] = React.useState({});

    useEffect(() => {
        // reset zoom level and transform when current task changes
        setZoomLevel(1);
        setTransform({});
        setBackgroundImageKey(BACKGROUND_IMAGE_KEYS.IMAGE_URL);
    }, [currentTaskIdx]);

    const guiObject = taskUIContext.getCurrentGuiObject();
    const taskInput = taskUIContext.getCurrentTaskInput();
    const svgRef = React.useRef(null);

    // Wait for input before doing anything.
    if (!guiObject.taskInputResourcesInCache(currentTaskIdx)) {
        return <LoadingAnimation />;
    }

    taskUIContext.onViewReady();
    const img = resourceCache[taskInput.image_url];
    const labels = guiSettings.labels;

    const unknownLabel = "unknown";
    const colors = labels.reduce(
        (agg, { name, color }) => {
            agg[name] = color;
            return agg;
        },
        { [unknownLabel]: "#999999" }
    );

    // Set initial currentTaskOutput from existing polygons in taskInput.
    // Collision checking is already handled by existing code.
    if (
        guiObject.isInitialCurrentTaskOutput(currentTaskOutput) &&
        Array.isArray(taskInput.initial_polygons)
    ) {
        // make sure that only polygons with at least 3 points are used as input polygons
        taskInput.initial_polygons = taskInput.initial_polygons.filter(
            polygon =>
                Array.isArray(polygon.points) && polygon.points.length >= 3
        );

        // further assumptions:
        // - label has to be set to one in gui_settings, otherwise unknownLabel as default
        // - polygon is assumed to be closed
        for (const polygon of taskInput.initial_polygons) {
            if (
                !polygon.label ||
                !labels.find(({ name }) => name === polygon.label)
            ) {
                polygon.label = unknownLabel;
            }
            polygon.valid_selection = polygon.label !== unknownLabel;
            polygon.closed = true;
        }
        setCurrentTaskOutput(
            guiObject.makeTaskOutputForCurrentTask({
                polygons: taskInput.initial_polygons
            })
        );
    }

    // If there is only a single label defined, use that one by default, always.
    const defaultLabel = labels.length > 1 ? unknownLabel : labels[0].name;
    if (defaultLabel !== unknownLabel && activeLabel === null) {
        setActiveLabel(defaultLabel);
    }

    // Keep track of the current's image src in order to reset the state when
    // it changes.
    if (img.src !== latestImage) {
        setActivePolygonIx(-1);
        setActivePointIx(-1);
        setDragging(null);
        setActiveLabel(defaultLabel);
        // setBrightness(100);
        setDrawOnMouseMove(false);
        setLatestImage(img.src);
    }

    // The following calculations are essential for mapping between image
    // pixel coordinates and visualization pixel coordinates.
    // TODO: These values should be set one level higher
    const maxImageWidth = 1000;
    const maxImageHeight = 600;
    const imageMargin = 10;

    const height_tmp = Math.min(maxImageHeight, img.height);
    const width_tmp = img.width * (height_tmp / img.height);
    const imageWidth = Math.min(maxImageWidth, width_tmp);
    const imageHeight = height_tmp * (imageWidth / width_tmp);
    const viewWidth = imageWidth + imageMargin * 2;
    const viewHeight = imageHeight + imageMargin * 2;

    const pointToImageCooridnates = ({ x, y }) => ({
        x: (x - imageMargin) * (img.width / imageWidth),
        y: (y - imageMargin) * (img.height / imageHeight)
    });

    const pointToVisualizationCooridnates = ({ x, y }) => ({
        x: x / (img.width / imageWidth) + imageMargin,
        y: y / (img.height / imageHeight) + imageMargin
    });

    // Map from image coordinates to visualization coordinates.
    const polygons = currentTaskOutput.polygons.map(({ points, ...attrs }) => ({
        ...attrs,
        points: points.map(pointToVisualizationCooridnates)
    }));

    // Find collisions of segments within individual polygons.
    const collisions = polygons.map(({ points, closed }, ix) => {
        if (points.length <= 3) return { 0: false, 1: false };
        return findCollisions(zip(points, closed));
    });
    const hasCollisions = pgix =>
        collisions[pgix] && Object.values(collisions[pgix]).some(v => !!v);

    const activePolygon = polygons[activePolygonIx]; // may be `undefined`
    const drawing =
        activePolygonIx !== -1 &&
        typeof activePolygon !== "undefined" &&
        !activePolygon.closed;

    /**
     *
     * @param {MouseEvent} event
     */
    const getCurrentSvgPoint = event => {
        let { x, y } = locInSvg(svgRef.current, event);
        x = clip(x, imageMargin, imageWidth + imageMargin);
        y = clip(y, imageMargin, imageHeight + imageMargin);
        return { x, y };
    };

    /**
     * Creates a polygon and make it the active one
     * @param {*} point coordinates
     */
    const createPolygon = point => {
        const pgix = updatePolygon(null, {
            label: activeLabel,
            points: [point]
        });
        setActivePolygonIx(polygons.length);
        setActivePointIx(-1);
        setDragging({ pgix, ptix: 0 });
    };

    /**
     * Updates a polygon. If pgix == null a new one is created
     * @param {number} pgix polygon index
     * @param {*} attrs polygon attributes
     */
    const updatePolygon = (pgix, attrs) => {
        if (attrs["points"]) {
            attrs["points"] = attrs["points"].map(pointToImageCooridnates);
        }
        const polygons = currentTaskOutput.polygons;
        if (pgix === null) {
            pgix = polygons.length;
            polygons.push({
                points: [],
                closed: false,
                label: defaultLabel,
                ...attrs
            });
        } else {
            polygons[pgix] = {
                ...polygons[pgix],
                ...attrs
            };
        }
        polygons[pgix].valid_selection = polygons[pgix].label !== unknownLabel;
        polygons[pgix].has_collisions = hasCollisions(pgix);
        setCurrentTaskOutput(
            guiObject.makeTaskOutputForCurrentTask({
                polygons
            })
        );
        return pgix;
    };

    /**
     * Deletes a polygon
     * @param {number} pgix polygon index
     */
    const deletePolygon = pgix => {
        currentTaskOutput.polygons.splice(pgix, 1);
        setActivePointIx(-1);
        setActivePolygonIx(-1);
        setActiveLabel(defaultLabel);
        setCurrentTaskOutput(
            guiObject.makeTaskOutputForCurrentTask({
                polygons: currentTaskOutput.polygons
            })
        );
    };

    /**
     * Selects a polygon as currently active
     * @param {number} pgix index of polygon to be selected
     * @param {string} label label to be selected
     */
    const selectActivePolygon = (pgix, label) => {
        setActivePointIx(-1);
        setActivePolygonIx(pgix);
        setActiveLabel(label);
    };

    /**
     * Deselects the currently active poylgon
     */
    const deselectPoylgon = () => {
        setActivePolygonIx(-1);
        setActiveLabel(defaultLabel);
    };

    /**
     * Creates a point
     * @param {number} pgix polygon index
     * @param {number} point coordinates
     */
    const createPoint = (pgix, point) => {
        updatePoint(pgix, null, point);
        if (activePointIx > -1) {
            setActivePointIx(-1);
        }
    };

    /**
     * Updates a point. If ptix == null a new one gets created
     * @param {number} pgix polygon index
     * @param {number} ptix point index
     * @param {*} point coordinates
     */
    const updatePoint = (pgix, ptix, point) => {
        point = pointToImageCooridnates(point);
        const polygon = currentTaskOutput.polygons[pgix];
        if (ptix === null) {
            ptix = polygon.points.length;
            polygon.points.push(point);
        } else {
            polygon.points[ptix] = point;
        }
        polygon.has_collisions = hasCollisions(pgix);
        setCurrentTaskOutput(
            guiObject.makeTaskOutputForCurrentTask({
                polygons: currentTaskOutput.polygons
            })
        );
        return ptix;
    };

    /**
     * Inserts a point on polygons line
     * @param {MouseEvent} event
     * @param {number} pgix polygon index
     * @param {number} ptix point index
     * @param {{x: number, y: number}[]} points
     */
    const insertPointOnLine = (event, pgix, ptix, points) => {
        const newPoint = locInSvg(svgRef.current, event);
        ptix += 1;
        points.splice(ptix, 0, newPoint);
        // Added points can directly be dragged.
        setActivePointIx(ptix);
        setDragging({ pgix, ptix });
        updatePolygon(pgix, { points });
    };

    /**
     * Deletes a point
     * @param {number} pgix polygon index
     * @param {number} ptix point index
     */
    const deletePoint = (pgix, ptix) => {
        const polygon = currentTaskOutput.polygons[pgix];
        const points = polygon.points;
        if (points.length === 1) {
            deletePolygon(pgix);
        } else {
            points.splice(ptix >= 0 ? ptix : points.length + ptix, 1);
            setActivePointIx(ptix % points.length);
            polygon.points = points;
            polygon.closed = ptix === -1 ? false : polygon.closed;
            setCurrentTaskOutput(
                guiObject.makeTaskOutputForCurrentTask({
                    polygons: currentTaskOutput.polygons
                })
            );
        }
    };

    /**
     * Sets the active label and updates selected polygon (if any)
     * @param {string} label
     */
    const updateActiveLabel = label => {
        setActiveLabel(label);
        if (activePolygonIx > -1) {
            updatePolygon(activePolygonIx, { label });
        }
    };

    /**
     * Set the currently selected polygon's label given a key press.
     *
     * @param {KeyboardEvent} event
     */
    const setPolygonLabel = event => {
        const labelIx = event.key !== "0" ? parseInt(event.key) - 1 : 9;
        if (labelIx >= labels.length) return;
        const label = labels[labelIx].name;
        updateActiveLabel(label);
    };

    /**
     * Close currently active polygon
     */
    const closePolygon = () => {
        if (activePolygonIx < 0) return;
        updatePolygon(activePolygonIx, {
            closed: true
        });
        setActiveLabel(defaultLabel);
        setActivePolygonIx(-1);
        setActivePointIx(-1);
        setPreviewLinePoint(null);
    };

    const getReferenceImageUrl = () => {
        if (!activeLabel || activeLabel === unknownLabel) {
            return null;
        }
        const reference_image_url = labels.find(
            label => label.name === activeLabel
        ).reference_image_url;
        return reference_image_url;
    };

    const zoomMax = guiSettings.zoom_max || 6;
    const zoomSpeed = guiSettings.zoom_speed || 5;
    const drawDist = guiSettings.draw_distance || 10;
    const radius = guiSettings.point_radius || 3;

    const referenceZoomLevel = 1.5;

    const zoomReferenceImage = () => {
        setReferenceZoom(isZoomed => !isZoomed);
    };

    // Keyboard shortcuts
    const keyMap = {
        DELETE_POINT: ["del", "backspace"],
        UNDO: ["ctrl+z", "cmd+z"],
        SET_LABEL: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
        TOGGLE_HIDE_POLYGON: ["h"],
        TOGGLE_BACKGROUND_IMAGE: ["t"]
    };

    const handlers = {
        DELETE_POINT: () => {
            if (activePolygonIx === -1 || activePointIx === -1) return;
            deletePoint(activePolygonIx, activePointIx);
        },
        UNDO: () => {
            if (activePolygonIx === -1) return;
            deletePoint(activePolygonIx, -1);
        },
        SET_LABEL: setPolygonLabel,
        TOGGLE_HIDE_POLYGON: () => setPolygonSVGHidden(!polygonSVGHidden),
        TOGGLE_BACKGROUND_IMAGE: () => {
            if (taskInput.alt_image_url) {
                if (backgroundImageKey === BACKGROUND_IMAGE_KEYS.IMAGE_URL) {
                    setBackgroundImageKey(BACKGROUND_IMAGE_KEYS.ALT_IMAGE_URL);
                } else if (
                    backgroundImageKey === BACKGROUND_IMAGE_KEYS.ALT_IMAGE_URL
                ) {
                    setBackgroundImageKey(BACKGROUND_IMAGE_KEYS.IMAGE_URL);
                }
            }
        }
    };

    return (
        <div className="polygonsCanvas">
            <GlobalHotKeys
                keyMap={keyMap}
                handlers={handlers}
                allowChanges={true}
            />
            {guiSettings.hide_buttons !== true ? (
                <div id="menu" className="btn-group-vertical">
                    {labels.length === 1 ? null : (
                        <LabelRadios
                            value={activeLabel}
                            values={labels}
                            onChange={updateActiveLabel}
                        />
                    )}
                    <ButtonGroup vertical style={{ width: "100%" }}>
                        {!guiSettings.undo_button ? null : (
                            <Button
                                id="undo_button"
                                disabled={
                                    !activePolygon ||
                                    !activePolygon.points.length
                                }
                                onClick={() => deletePoint(activePolygonIx, -1)}
                            >
                                Undo Point
                            </Button>
                        )}
                        {!guiSettings.delete_button ? null : (
                            <Button
                                id="delete_button"
                                disabled={
                                    !activePolygon || activePointIx === -1
                                }
                                onClick={() =>
                                    deletePoint(activePolygonIx, activePointIx)
                                }
                            >
                                Delete Point
                            </Button>
                        )}
                        {!guiSettings.close_polygon_button ? null : (
                            <Button
                                id="close_polygon_button"
                                disabled={
                                    !activePolygon ||
                                    activePolygon.closed ||
                                    activePolygon.points.length < 3
                                }
                                onClick={closePolygon}
                            >
                                Close Polygon
                            </Button>
                        )}
                    </ButtonGroup>
                    {!guiSettings.brightness_switch ? null : (
                        <BrightnessSwitch
                            value={brightness}
                            setter={setBrightness}
                        />
                    )}
                    {(() => {
                        if (polygonSVGHidden) {
                            return (
                                <div
                                    id="polygon_hidden"
                                    style={{
                                        position: "absolute",
                                        float: "left",
                                        bottom: "5px",
                                        color: "#F00",
                                        fontSize: "80%",
                                        left: 0
                                    }}
                                >
                                    Polygon hidden
                                </div>
                            );
                        }
                    })()}
                </div>
            ) : null}
            <ZoomableContainer
                height={viewHeight}
                max={zoomMax}
                onZoom={(scale, style) => {
                    setZoomLevel(scale);
                    setTransform(style);
                }}
                scale={zoomLevel}
                speed={zoomSpeed}
                width={viewWidth}
            >
                {/* div and svg with absolute position to overlay on one
                    another while only applying the brightness filter to the div */}
                <div id="transformable_div" style={transform}>
                    <div
                        id="image_div"
                        style={{
                            position: "absolute",
                            top: imageMargin,
                            left: imageMargin,
                            width: imageWidth,
                            height: imageHeight,
                            backgroundImage: `url('${taskInput[backgroundImageKey]}')`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            filter: `brightness(${brightness}%)`
                        }}
                    />
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={`0 0 ${viewWidth} ${viewHeight}`}
                    ref={svgRef}
                    style={{
                        visibility: polygonSVGHidden ? "hidden" : "visible",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: viewWidth,
                        height: viewHeight,
                        ...transform
                    }}
                    className={classNames({
                        drawing: drawing,
                        dragging: dragging,
                        continuousDrawing: drawOnMouseMove,
                        activePolygon: activePolygonIx > -1,
                        qm_svg: true
                    })}
                    onMouseOut={() => setDrawOnMouseMove(false)}
                >
                    {/* Transparent background rectangle for capturing events */}
                    <rect
                        className="interactionArea"
                        x={0}
                        y={0}
                        width={viewWidth}
                        height={viewHeight}
                        onMouseDown={event => {
                            const point = getCurrentSvgPoint(event);
                            if (!activePolygon) {
                                createPolygon(point);
                            } else if (activePolygon.closed) {
                                deselectPoylgon();
                            } else {
                                createPoint(activePolygonIx, point);
                            }
                            if (!dragging) {
                                setDrawOnMouseMove(true);
                            }
                        }}
                        onMouseMove={event => {
                            const point = getCurrentSvgPoint(event);
                            if (drawing) {
                                if (drawOnMouseMove) {
                                    const points = activePolygon.points;
                                    const final = points[points.length - 1];
                                    if (euclidean(final, point) > drawDist) {
                                        updatePoint(
                                            activePolygonIx,
                                            null,
                                            point
                                        );
                                    }
                                } else {
                                    setPreviewLinePoint(point);
                                }
                            } else if (dragging) {
                                const { pgix, ptix } = dragging;
                                updatePoint(pgix, ptix, point);
                            }
                        }}
                        onMouseUp={() => {
                            setDragging(null);
                            setDrawOnMouseMove(false);
                            setPreviewLinePoint(null);
                        }}
                        onMouseOut={() => setDragging(null)}
                    />
                    {/* maybe have a label-colored circle move below the mouse? */}
                    {polygons.map(({ label, points, closed }, pgix) => (
                        <g
                            key={pgix}
                            className={classNames({
                                active: activePolygonIx === pgix,
                                inactive: activePolygonIx !== pgix,
                                closed: closed,
                                unclosed: !closed
                            })}
                        >
                            <polygon
                                id={"poligon_" + pgix}
                                className="qm_polygon"
                                points={points
                                    .map(({ x, y }) => `${x},${y}`)
                                    .join(" ")}
                                fill={colors[label]}
                                onClick={() => {
                                    selectActivePolygon(pgix, label);
                                }}
                            />
                            {zip(points, closed).map(([a, b], ptix) => (
                                <line
                                    key={ptix}
                                    x1={a.x}
                                    y1={a.y}
                                    x2={b.x}
                                    y2={b.y}
                                    id={"poligon_" + pgix + "_qm_line_" + ptix}
                                    className={classNames({
                                        intersectioned: collisions[pgix][ptix],
                                        qm_line: true
                                    })}
                                    stroke={colors[label]}
                                    onMouseDown={event => {
                                        insertPointOnLine(
                                            event,
                                            pgix,
                                            ptix,
                                            points
                                        );
                                    }}
                                />
                            ))}
                            {/* Note that only the very first point in the
                            currently active polygon is click-able. */}
                            {points.map((point, ptix) => (
                                <circle
                                    id={"poligon_" + pgix + "_circle_" + ptix}
                                    className={classNames({
                                        selected:
                                            activePolygonIx === pgix &&
                                            activePointIx === ptix,
                                        start: ptix === 0,
                                        qm_circle: true
                                    })}
                                    key={ptix}
                                    cx={point.x}
                                    cy={point.y}
                                    r={clip(radius / zoomLevel, 1, radius)}
                                    fill={colors[label]}
                                    stroke={colors[label]}
                                    strokeWidth={clip(2 / zoomLevel, 1, 2)}
                                    onClick={() => {
                                        // On polygon final/initial node click.
                                        if (
                                            drawing &&
                                            ptix === 0 &&
                                            points.length > 2
                                        ) {
                                            closePolygon();
                                        }
                                    }}
                                    onMouseEnter={() => {
                                        if (
                                            drawing &&
                                            ptix === 0 &&
                                            points.length > 2 &&
                                            drawOnMouseMove
                                        ) {
                                            closePolygon();
                                            setDrawOnMouseMove(false);
                                        }
                                    }}
                                    onMouseDown={() => {
                                        if (
                                            !drawing ||
                                            ptix !== 0 ||
                                            points.length < 3
                                        ) {
                                            setActivePointIx(ptix);
                                            setDragging({ pgix, ptix });
                                        }
                                    }}
                                    onMouseUp={() => {
                                        setDragging(null);
                                        setDrawOnMouseMove(false);
                                    }}
                                />
                            ))}
                            {activePolygonIx === pgix &&
                                drawing &&
                                !drawOnMouseMove &&
                                previewLinePoint && (
                                    <line
                                        className="qm_line"
                                        x1={points[points.length - 1].x}
                                        y1={points[points.length - 1].y}
                                        x2={previewLinePoint.x}
                                        y2={previewLinePoint.y}
                                        stroke={colors[label]}
                                        strokeWidth={1}
                                    />
                                )}
                        </g>
                    ))}
                </svg>
            </ZoomableContainer>

            {guiSettings.hide_buttons === true ? null : (
                <div className="reference_image">
                    {!activeLabel || activeLabel === unknownLabel ? null : (
                        <>
                            <p>Reference</p>
                            <div onClick={() => zoomReferenceImage()}>
                                <div
                                    className="referenceZoom"
                                    style={{
                                        transform: `scale(${
                                            referenceZoom
                                                ? referenceZoomLevel
                                                : 1
                                        })`
                                    }}
                                >
                                    <img src={getReferenceImageUrl()} alt="" />
                                </div>
                                <div id="zoom_icon">
                                    {referenceZoom ? (
                                        <IoIosContract size={30} />
                                    ) : (
                                        <IoIosExpand size={30} />
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
