import React from "react";
import ReactHtmlParser from "react-html-parser";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const Question = ({ taskUIContext, guiSettings, currentTaskIdx }) => {
    const makeProgressInfo = () => {
        const taskInputs = taskUIContext.getTaskInputs();
        if (
            taskInputs &&
            taskInputs.length > 0 &&
            !guiSettings.binary_question_mode
        ) {
            return ` (${currentTaskIdx + 1}/${taskInputs.length})`;
        }
        return null;
    };
    return (
        <h1
            id="question"
            style={{
                textAlign: "center",
                marginBottom: "5px",
                fontSize: "25px",
                margin: "auto",
                maxWidth: "90%"
            }}
        >
            {ReactHtmlParser(guiSettings.question)}
            {makeProgressInfo()}
        </h1>
    );
};
