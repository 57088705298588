import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getCurrentTaskIdx } from "../../store/reducers/currentTaskIdx";
import { getNextGuiType } from "../../store/reducers/nextGuiType";
import {
    getTaskFetchRequest,
    getTaskFetchResult
} from "../../store/reducers/taskFetchRequest";
import { setNextGuiType } from "../../store/actions";
import { WIDGETS } from "../../guiFactory";

import Timer from "react-compound-timer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getGuiSettings } from "../../store/reducers/guiSettings";

const TaskTimer = props => {
    const startTimeFunc = useRef();
    const stopTimeFunc = useRef();

    const [taskTime, setTaskTime] = useState(null);
    const [timerEnabled, setTimerEnabled] = useState(false);
    const [countdownStyle, setCountdownStyle] = useState({ color: "black" });

    useEffect(() => {
        if (props.guiSettings && props.taskData) {
            if (
                !props.guiSettings.timer_disabled &&
                props.taskData.submit_deadline &&
                !props.queryParams.get("timer_disabled")
            ) {
                setTimerEnabled(true);
            }
        }
    }, [props.guiSettings, props.taskData, props.queryParams]);

    useEffect(() => {
        if (timerEnabled) {
            const now = new Date();
            const end = new Date(props.taskData.submit_deadline);
            if (end - now > 0) {
                setTaskTime(end - now);
            } else {
                setTaskTime(1);
            }
        }
        // eslint-disable-next-line
    }, [timerEnabled]);

    useEffect(() => {
        if (taskTime) {
            startTimeFunc.current();
        }
    }, [taskTime]);

    useEffect(() => {
        if (props.nextGui === "thank_you" && timerEnabled) {
            stopTimeFunc.current();
            setTimerEnabled(false);
        }
        // eslint-disable-next-line
    }, [props.nextGui]);

    if (taskTime && timerEnabled) {
        return (
            <div
                id="timer"
                style={{
                    visibility: props.queryParams.get("timer_hidden")
                        ? "hidden"
                        : "visible"
                }}
            >
                <ToastContainer />
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "fit-content",
                        height: "fit-content",
                        fontSize: "xx-large",
                        paddingTop: "15px"
                    }}
                >
                    <Timer
                        formatValue={value =>
                            `${value < 10 ? `0${value}` : value}`
                        }
                        initialTime={taskTime}
                        direction={"backward"}
                        lastUnit="m"
                        startImmediately={false}
                        timeToUpdate={100}
                        style={{ color: "red" }}
                        checkpoints={[
                            {
                                time: 61000,
                                callback: () => {
                                    toast.info("You have 1 minute left", {
                                        position: "bottom-left",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined
                                    });
                                }
                            },
                            {
                                time: 11000,
                                callback: () => {
                                    setCountdownStyle({
                                        color: "red"
                                    });
                                }
                            },
                            {
                                time: 0,
                                callback: () => {
                                    props.dispatch(
                                        setNextGuiType(WIDGETS.TIME_OUT),
                                        setTimerEnabled(false)
                                    );
                                }
                            }
                        ]}
                    >
                        {({
                            start,
                            resume,
                            pause,
                            stop,
                            reset,
                            timerState
                        }) => {
                            startTimeFunc.current = start;
                            stopTimeFunc.current = stop;

                            return (
                                <div>
                                    <p id="time">
                                        <span style={countdownStyle}>
                                            <Timer.Minutes />:
                                            <Timer.Seconds />
                                        </span>
                                    </p>
                                </div>
                            );
                        }}
                    </Timer>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const mapStateToProps = state => ({
    idx: getCurrentTaskIdx(state),
    tasksLoaded: !getTaskFetchRequest(state).pending,
    taskData: getTaskFetchResult(state),
    nextGui: getNextGuiType(state),
    guiSettings: getGuiSettings(state)
});

export default connect(mapStateToProps)(TaskTimer);
