import React from "react";

/**
 * Simply renders all children in a div with a
 * single flex column that's centered horizontally
 */
export const SimpleLayout = ({ children, style }) => {
    /**
     * @type {React.CSSProperties}
     */
    const overriddenDefaultStyle = {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        ...style
    };
    return (
        <div className="simple_layout" style={overriddenDefaultStyle}>
            {children}
        </div>
    );
};

export default SimpleLayout;
