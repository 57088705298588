import React from "react";

import "./CameraViewZoom.css";

/**
 * @param {Object} props
 * @param {Object} props.zoomParameters
 * @param {Number} props.zoomParameters.scale
 * @param {Object} props.zoomParameters.translation
 * @param {Number} props.zoomParameters.translation.x
 * @param {Number} props.zoomParameters.translation.y
 * @param {React.CSSProperties} props.outerStyle
 * @param {React.CSSProperties} props.innerStyle
 */
export const CameraViewZoom = ({
    children,
    zoomParameters,
    outerStyle,
    innerStyle
}) => {
    const scaleStr = `scale(${zoomParameters.scale})`;
    const translateStr = ` translate(${zoomParameters.translation.x}px, ${zoomParameters.translation.y}px)`;
    const transitionDuration = "100ms";
    return (
        <div
            className="zoomableContainerCutoff"
            style={{
                ...outerStyle
            }}
        >
            <div
                className="zoomableContainer"
                style={{
                    transform: scaleStr + translateStr,
                    transitionDuration: transitionDuration,
                    ...innerStyle
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default CameraViewZoom;
