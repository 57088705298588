import { JSONSerializable } from "../JSONSerializable";

/**
 * @class
 * @augments {JSONSerializable}
 */
export default class Ack extends JSONSerializable {
    /**
     *
     * @param {string} ack_key
     * @param {string} input_id
     */
    constructor(ack_key, input_id) {
        super();
        this.setAckKey(ack_key).setInputId(input_id);
    }

    /**
     *
     * @param {string} ack_key
     * @returns {Ack}
     */
    setAckKey(ack_key) {
        this.ack_key = ack_key;
        return this;
    }

    /**
     *
     * @param {string} input_id
     * @returns {Ack}
     */
    setInputId(input_id) {
        this.input_id = input_id;
        return this;
    }
}
