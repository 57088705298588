import React from "react";
import { connect } from "react-redux";
import { GrDocumentDownload } from "react-icons/gr";
import ReactJson from "react-json-view";

import { triggerJsonObjectDownload } from "../../lib/qm_cs_lib";
import { getCurrentTaskIdx } from "../../store/reducers/currentTaskIdx";
import { getNextGuiType } from "../../store/reducers/nextGuiType";
import { getGuiType } from "../../store/reducers/guiType";
import { getTaskFetchRequest } from "../../store/reducers/taskFetchRequest";
import DraggableWindow from "./DraggableWindow";

const DebugInfo = props => {
    const downloadSelectedSamplesJsonFile = () => {
        const filename = "storeData_" + props.guiType;
        triggerJsonObjectDownload(props.store, filename, 4);
    };

    if (props.queryParams) {
        if (
            props.queryParams.get("show_debug_info") ||
            props.queryParams.get("vendor_id") === "qm"
        ) {
            return (
                <DraggableWindow
                    heading={"debug information:"}
                    extendable={true}
                    containerWidth={320}
                >
                    <div
                        id="debug_info"
                        style={{
                            paddingTop: "10px"
                        }}
                    >
                        <button
                            id="json_download"
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "0px",
                                border: "none",
                                background: "white",
                                hover: {
                                    border: "1px solid #888",
                                    background: "#ddd"
                                }
                            }}
                            onClick={() => downloadSelectedSamplesJsonFile()}
                            title="Download the current state of the Redux-Store as JSON-file"
                        >
                            <GrDocumentDownload /> Store
                        </button>
                        <p>guiType: {props.guiType}</p>
                        <p>currentTaskIdx: {props.currentTaskIdx}</p>
                        <p>next view type: {props.nextGuiType}</p>
                        <p>taskInput:</p>
                        {
                            <ReactJson
                                src={props.taskInput}
                                name={false}
                                style={{ color: "dark-grey" }}
                                collapsed={1}
                                collapseStringsAfterLength={20}
                                displayObjectSize={true}
                                displayDataTypes={false}
                            />
                        }
                    </div>
                </DraggableWindow>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
};

const mapStateToProps = state => ({
    currentTaskIdx: getCurrentTaskIdx(state),
    nextGuiType: getNextGuiType(state),
    guiType: getGuiType(state),
    taskInput: getTaskFetchRequest(state).result
        ? getTaskFetchRequest(state).result.task_inputs
            ? getTaskFetchRequest(state).result.task_inputs[
                  getCurrentTaskIdx(state)
              ]
            : {}
        : {},
    store: state
});

export default connect(mapStateToProps)(DebugInfo);
