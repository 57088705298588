import DiscreteAnswer from "./DiscreteAnswer/DiscreteAnswer";
import KeypointSelection from "./KeypointSelection/KeypointSelection";
import Polygon from "./Polygon/Polygon";
import { BinaryComparison } from "./BinaryComparison/BinaryComparison";
import BoundingBox2D from "./BoundingBox2D/BoundingBox2D";
import BoundingBox2DLabeled from "./BoundingBox2DLabeled/BoundingBox2DLabeled";

import { ImageSelection } from "./ImageSelection/ImageSelection";
import { PointcloudCog } from "./PointcloudProjection/PointcloudCog";
import AudioSimilarity from "./AudioSimilarity/AudioSimilarity";
import PixelBrush from "./PixelBrush/PixelBrush";
import Pointcloud3DBB from "./PointcloudProjection/Pointcloud3DBB";
import MultimediaQuestion from "./MultimediaQuestion/MultimediaQuestion";
import TiledKeypoint3D from "./Coconut/TiledKeypoint3D";
import DiscreteAnswerGaming from "./DiscreteAnswerGaming/DiscreteAnswerGaming";
import TiledBoundingBox3D from "./Coconut/TiledBoundingBox3D";
import TiledOrientationAdjustment3D from "./Coconut/OrientationAdjustment/TiledOrientationAdjustment3D";
import TiledBoundingBoxFaceAdjustment3D from "./Coconut/BBox3DFaceAdjustment/TiledBoundingBoxFaceAdjustment3D";

/**
 * A Map entry references the class of a guitype itself with
 * the guitype's name as key
 *
 * @type {Map<String, typeof import("./BaseGuiType").BaseGuiType>}
 */
export const GUITYPE_REGISTRY = new Map([
    [DiscreteAnswer.getName(), DiscreteAnswer],
    [DiscreteAnswerGaming.getName(), DiscreteAnswerGaming],
    [KeypointSelection.getName(), KeypointSelection],
    [BinaryComparison.getName(), BinaryComparison],
    [Polygon.getName(), Polygon],
    [ImageSelection.getName(), ImageSelection],
    [Polygon.getName(), Polygon],
    [PointcloudCog.getName(), PointcloudCog],
    [Pointcloud3DBB.getName(), Pointcloud3DBB],
    [BoundingBox2D.getName(), BoundingBox2D],
    [BoundingBox2DLabeled.getName(), BoundingBox2DLabeled],
    [AudioSimilarity.getName(), AudioSimilarity],
    [PixelBrush.getName(), PixelBrush],
    [MultimediaQuestion.getName(), MultimediaQuestion],
    [TiledKeypoint3D.getName(), TiledKeypoint3D],
    [TiledBoundingBox3D.getName(), TiledBoundingBox3D],
    [TiledOrientationAdjustment3D.getName(), TiledOrientationAdjustment3D],
    [
        TiledBoundingBoxFaceAdjustment3D.getName(),
        TiledBoundingBoxFaceAdjustment3D
    ]
]);

export const throwGuiTypeUnknownError = guiType => {
    throw new Error(
        `guitype "${guiType}" is not known to the guitype registry.`
    );
};

export const throwGuiTypeUnknownErrorIfNecessary = guiType => {
    if (!GUITYPE_REGISTRY.has(guiType)) {
        throwGuiTypeUnknownError(guiType);
    }
};
