import React from "react";

import BaseGuiType from "../BaseGuiType";
import MockApiClient from "../MockData/MockApiClient";
import MockData from "../MockData/MockData";
import ResourceLoaderService from "../ResourceLoaderService";
import {
    makeImageLoader,
    Loader,
    fetchLoad
} from "../../lib/DataLoader/loaders";
import { decodeProtobufFromResponseCreator } from "../../lib/DataLoader/decoders";
import { RGBAndBEVView } from "./RGBAndBEVView";
import { WIDGETS } from "../../guiFactory";
import { ActionButtonGroup } from "./ActionButtonGroup";
import { Question } from "./Question";
import { pointcloudCarTaskInputs } from "./MockTaskInputs";

const PointsTable = require("../../lib/proto/points_table_pb");

/**
 * PointcloudProjection UI for choosing a center of gravity in a point cloud.
 */
export class PointcloudCog extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return WIDGETS.POINTCLOUD_COG;
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const taskInputs = pointcloudCarTaskInputs;
        const mockApiClient = new MockApiClient(
            {
                instructions: [],

                // general required settings
                question: {
                    text:
                        "In the 3D map: Find & Mark the centre of the highlighted vehicle."
                },

                // general optional settings
                required_actions: {
                    select_cog: "select a center"
                },

                // always required
                max_points_cnt: 25000,
                // required for cog only
                cross_dims: { a_line_len: 3, b_line_len: 3 },
                // others that are optional
                bg_color: "#000",
                cog_line_width: 4,
                cog_color: "#F0F",
                color_map: "turbo_light",
                transform_fit: "FIT_ALL_POINTS",
                initial_zoom: 83,
                mouse_point_highlight_dist: 50,
                point_radius: 1.5,
                highlighted_point_radius: 1.5,
                point_opacity: 0.3,
                highlighted_point_opactiy: 1,
                rgb_mouse_point_highlight_canvas_dist: 20,
                rgb_image_bbox2d_line_width: 2,
                rgb_image_bbox2d_color: "#FFF",
                rgb_point_radius: 1.5,
                rgb_highlighted_point_radius: 3,
                rgb_point_opacity: 0.0,
                rgb_highlighted_point_opacity: 0.6
            },
            PointcloudCog.getName(),
            taskInputs
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeCustomLoaderService(
                new Map([
                    ["image_url", makeImageLoader(null)],
                    [
                        "pb_data_url",
                        new Loader(
                            null,
                            fetchLoad,
                            decodeProtobufFromResponseCreator(PointsTable.Table)
                        )
                    ]
                ])
            )
        );
    }

    /**
     * @override
     */
    getHeading() {
        return Question;
    }
    /**
     * @override
     */
    getDataVisualization() {
        return props => <RGBAndBEVView {...props} type="cog" />;
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return props => <ActionButtonGroup {...props} type="cog" />;
    }

    /**
     * Empty MetaActionButtonGroup: merged into ActionButtonGroup
     * @override
     */
    getMetaActionButtonGroup() {
        return () => null;
    }

    /**
     * @override
     */
    getInitialCurrentTaskOutput() {
        return {
            ...super.makeTaskOutputForCurrentTask(),
            cog: { x: null, y: null, z: null }
        };
    }

    /**
     * @override
     */
    makeTaskOutputForCurrentTask(overrides) {
        return {
            ...this.getInitialCurrentTaskOutput(),
            ...overrides
        };
    }
}

export default PointcloudCog;
