import React from "react";
import { connect, Provider } from "react-redux";

import TaskUIContext from "../../lib/TaskUIStrategy/taskUIContext";
import guiFactory from "../../guiFactory";
import { getNextGuiType } from "../../store/reducers/nextGuiType";
import { getGuiSettings } from "../../store/reducers/guiSettings";
import MockDataForm from "../../components/MockDataForm/MockDataForm";
import MockData from "../../guitypes/MockData/MockData";
import MockApiClient from "../../guitypes/MockData/MockApiClient";
import TaskTimer from "../../components/Timer/TaskTimer";
import DebugInfo from "../../components/DebugInfo/DebugInfo.jsx";
import { SubmitIndication } from "../../components/SubmitIndication/SubmitIndication";

/**
 * @augments {React.Component<Props, State>}
 * @class
 */
class App extends React.Component {
    /**
     * @param {AppProps} props
     */
    constructor(props) {
        super(props);

        /**
         * @typedef {Object} AppProps
         * @property {URLSearchParams} actualQueryParams
         * @property {Boolean} isDebugMode
         * @property {import("../../guitypes/MockData/MockData").MockData} mockData
         * @property {import("redux").Store} store
         * @property {String} nextGuiType redux state
         * @property {Object} guiSettings redux state
         */
        /**
         * @type {AppProps}
         */
        // eslint-disable-next-line
        this.props;

        /**
         * @type {TaskUIContext}
         */
        this.taskUIContext = null;

        /**
         * @type {import("../../guitypes/MockData/MockData").MockData}
         */
        this.editedMockData = null;

        this.gui = null;
        this.state = {
            guiContainer: null,
            manuallyOverriddenMockData: false
        };
    }

    /**
     * Initializes taskUIContext and fetches tasks if not in debug mode.
     */
    componentDidMount() {
        if (this.props.isDebugMode) {
            return;
        }

        // if there's mockData it will be used
        if (this.props.mockData) {
            this.initTaskUIContext(
                this.props.mockData.mockQueryParams,
                this.props.mockData.mockApiClient
            );
        } else {
            this.initTaskUIContext(this.props.actualQueryParams, null);
        }
        this.gui = guiFactory(this.taskUIContext, this.props.nextGuiType);
        this.fetchTasks();
    }

    componentDidUpdate() {
        if (
            this.gui === null ||
            (this.props.nextGuiType !== null &&
                this.gui.name !== this.props.nextGuiType)
        ) {
            const newGui = guiFactory(
                this.taskUIContext,
                this.props.nextGuiType
            );

            if (newGui !== null) {
                this.gui = newGui;
                this.setState({
                    guiContainer: this.gui.container
                });
            }
        }
    }

    initTaskUIContext(queryParams, apiClient) {
        // if apiClient is null, it will be created by taskUIContext
        this.taskUIContext = new TaskUIContext(
            queryParams,
            this.props.store,
            apiClient
        );
        this.taskUIContext.init();
    }

    fetchTasks() {
        if (!this.taskUIContext.shouldFetchTasks()) {
            return;
        }

        this.taskUIContext
            .fetchTasks()
            .then(success => {
                if (!success) {
                    console.error("detected problem with data");
                    return;
                }
            })
            .catch(err => {
                console.error("error was thrown while fetching tasks:", err);
            });
    }

    /**
     * Receives new guiSettings and taskInputs from MockDataForm and resets taskUIContext
     * with them and begins task fetching.
     *
     * @param {Object} guiSettings
     * @param {Object[]} taskInputs
     */
    resetTaskUIContextWithMockData(guiSettings, taskInputs) {
        this.editedMockData = new MockData(
            new MockApiClient(
                guiSettings,
                this.props.mockData.mockApiClient.getTaskGroupResult.gui_type,
                taskInputs
            )
        );
        // preserve mockQueryParams from the guitype's class
        this.editedMockData.setMockQueryParams(
            this.props.mockData.mockQueryParams
        );

        this.initTaskUIContext(
            this.editedMockData.mockQueryParams,
            this.editedMockData.mockApiClient
        );
        this.setState({ manuallyOverriddenMockData: true });
        this.fetchTasks();
    }

    /**
     * allow resetting the mock data again if in debug mode
     */
    makeLoadMockDataFormButton() {
        if (!this.props.isDebugMode) {
            return null;
        }

        return (
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    right: 0
                }}
            >
                <button
                    className="btn btn-sm btn-secondary"
                    style={{ opacity: 0.7 }}
                    onClick={() =>
                        this.setState({ manuallyOverriddenMockData: false })
                    }
                >
                    Edit MockData
                </button>
            </div>
        );
    }

    render() {
        const { isDebugMode, mockData, actualQueryParams, store } = this.props;

        if (isDebugMode && !this.state.manuallyOverriddenMockData) {
            // when taskUIContext is already initialized, we already have manual
            // mock data. We want to edit the data we already updated.
            const usedMockData = this.editedMockData
                ? this.editedMockData
                : mockData;

            return (
                <MockDataForm
                    mockData={usedMockData}
                    onDoneChangingMockData={this.resetTaskUIContextWithMockData.bind(
                        this
                    )}
                    actualQueryParams={actualQueryParams}
                />
            );
        }

        // TODO: this is where the react router switch should be placed
        //       in order to replace our own guiType switching implementation!
        return (
            <>
                {this.makeLoadMockDataFormButton()}
                <Provider store={store}>
                    {this.state.guiContainer}
                    <TaskTimer queryParams={this.taskUIContext?.queryParams} />
                    <DebugInfo queryParams={this.taskUIContext?.queryParams} />
                    <SubmitIndication toast={false} />
                </Provider>
            </>
        );
    }
}

const mapStateToProps = state => ({
    nextGuiType: getNextGuiType(state),
    guiSettings: getGuiSettings(state)
});

App = connect(mapStateToProps)(App);
export default App;
