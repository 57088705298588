export const spotifyAudioPlayerRecords = [
    {
        combined_id: "0DSIv9DJruGLaRRLKKgit3:5ruEHBPfx7huUb7wLgOzXS",
        record_0: {
            start: 0,
            end: 5,
            record_id: "0DSIv9DJruGLaRRLKKgit3",
            spotify_url:
                "https://p.scdn.co/mp3-preview/08255dbd6f04a251701a137e702cfcecbfb301ff"
        },
        record_1: {
            start: 0,
            end: 5,
            record_id: "5ruEHBPfx7huUb7wLgOzXS",
            spotify_url:
                "https://p.scdn.co/mp3-preview/74628bf68567e7171f3029b6c96794921b6e8c69"
        }
    },
    {
        combined_id: "5BncQXZqKNmcoy6TRN0IYi:1DGqMak9pgdBGWqjrdaNhw",
        record_0: {
            start: 0,
            end: 5,
            record_id: "5BncQXZqKNmcoy6TRN0IYi",
            spotify_url:
                "https://p.scdn.co/mp3-preview/99bdd1667bb4e68c19946b7739ee2213a47760b8"
        },
        record_1: {
            start: 0,
            end: 5,
            record_id: "1DGqMak9pgdBGWqjrdaNhw",
            spotify_url:
                "https://p.scdn.co/mp3-preview/5e66b50fc92c597f3c5ed831e22910f050bb122b"
        }
    },
    {
        combined_id: "5D2mYZuzcgjpchVY1pmTPh:33GZdfVQ5YJ4FZ0tVRNJxT",
        record_0: {
            start: 0,
            end: 5,
            record_id: "5D2mYZuzcgjpchVY1pmTPh",
            spotify_url:
                "https://p.scdn.co/mp3-preview/266a580f4d72fe71c885b862396d52ce30644c77"
        },
        record_1: {
            start: 0,
            end: 5,
            record_id: "33GZdfVQ5YJ4FZ0tVRNJxT",
            spotify_url:
                "https://p.scdn.co/mp3-preview/2e821c4fa22ecf8a97f6c5d1c3248dc48f82fd7f"
        }
    }
];
