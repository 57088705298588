import React from "react";

import ImageWithMagnifyingTool from "../../components/ImageWithMagnifyingTool/ImageWithMagnifyingTool";
import ScaledImage from "../../components/ScaledImage/ScaledImage";
import { LoadingAnimation } from "../../components/LoadingAnimation/LoadingAnimation";

/**
 * Loads an image through the cache and displays it.
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const ImageVisualization = ({
    resourceCache,
    taskUIContext,
    currentTaskIdx,
    guiSettings
}) => {
    if (
        !taskUIContext
            .getCurrentGuiObject()
            .taskInputResourcesInCache(currentTaskIdx)
    ) {
        return <LoadingAnimation />;
    }

    taskUIContext.onViewReady();
    const taskInput = taskUIContext.getCurrentTaskInput();
    const image = resourceCache[taskInput.image_url];
    if (guiSettings.use_magnifying_tool) {
        return (
            <ImageWithMagnifyingTool
                imageSrc={image.src}
                imageHeight={image.height}
                imageWidth={image.width}
                zoomScale={guiSettings.magnifying_tool_zoom_scale}
                initialScale={guiSettings.image_scale}
            />
        );
    }
    return (
        <ScaledImage
            imageSrc={image.src}
            imageHeight={image.height}
            imageWidth={image.width}
            scale={guiSettings.image_scale}
        />
    );
};

export default ImageVisualization;
