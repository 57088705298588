import { Container } from "react-bootstrap";

import BaseGuiType from "../../BaseGuiType";
import MockApiClient from "../../MockData/MockApiClient";
import MockData from "../../MockData/MockData";
import ResourceLoaderService from "../../ResourceLoaderService";
import { makeImageLoader } from "../../../lib/DataLoader/loaders";
import { WIDGETS } from "../../../guiFactory";
import withWaitForResourcesWrapper from "../../util/withWaitForResourcesWrapper";
import BBox3DModel from "../../../lib/DataModels/BBox3DModel";
import OrientationAdjustmentInCameraViewsWidget from "./OrientationAdjustmentInCameraViewsWidget";
import { TaskInputsForSequenceOrientation2 } from "../tiledUIMockInputs";

export class TiledOrientationAdjustment3D extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return WIDGETS.TILED_ORIENTATION_ADJUSTMENT_3D;
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const mockApiClient = new MockApiClient(
            {
                instructions: [],
                question: {
                    text: "Adjust the rotation to fit the image on the left"
                },
                reference_image_url:
                    "https://qm-coconut.s3.eu-central-1.amazonaws.com/pre-processing/objects/0/categories/book.png",
                slider_ranges: {
                    rotation: [-Math.PI, Math.PI]
                },
                slider_steps: {
                    rotation: 0.01
                },
                coordinate_system_line_width: 4.0,
                zoom_level: 1.5,
                disabled_coordinate_axes: []
            },
            TiledOrientationAdjustment3D.getName(),
            // TaskInputsForSequenceOrientation
            TaskInputsForSequenceOrientation2
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeCustomLoaderService(
                new Map([
                    [
                        "all_image_urls",
                        taskInput => {
                            let loaders = [];
                            for (const cameraViewParams of Object.values(
                                taskInput.timestamps
                            )) {
                                loaders = [
                                    ...loaders,
                                    makeImageLoader(
                                        cameraViewParams.tl.image_url
                                    ),
                                    makeImageLoader(
                                        cameraViewParams.tr.image_url
                                    ),
                                    makeImageLoader(
                                        cameraViewParams.bl.image_url
                                    ),
                                    makeImageLoader(
                                        cameraViewParams.br.image_url
                                    )
                                ];
                            }
                            return loaders;
                        }
                    ]
                ])
            )
        );
    }

    /**
     * @override
     * @returns {LayoutCreator}
     */
    getLayoutCreator() {
        return (
            heading,
            dataVis,
            progressBar,
            actionButtonGroup,
            metaActionButtonGroup
        ) => {
            const components = [
                heading,
                dataVis,
                progressBar,
                actionButtonGroup,
                metaActionButtonGroup
            ];
            const instantiatedComponents = this.connectAndInstantiateComponents(
                components
            );
            return (
                <Container
                    style={{
                        backgroundColor: "#f5f5f5",
                        textAlign: "center",
                        borderRadius: "15px"
                    }}
                >
                    {instantiatedComponents}
                </Container>
            );
        };
    }

    /**
     * @override
     */
    getHeading() {
        return () => null;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return withWaitForResourcesWrapper(
            OrientationAdjustmentInCameraViewsWidget
        );
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return () => null;
    }

    /**
     * @override
     */
    getInitialCurrentTaskOutput() {
        const taskInput = this.taskUIContext.getCurrentTaskInput();
        return {
            ...super.makeTaskOutputForCurrentTask(),
            box: BBox3DModel.parseJSONObject(taskInput.box)
        };
    }

    /**
     * @override
     */
    makeTaskOutputForCurrentTask(overrides) {
        return {
            ...this.getInitialCurrentTaskOutput(),
            ...overrides
        };
    }
}

export default TiledOrientationAdjustment3D;
