/**
 * An actionCreator generator
 * source: https://redux.js.org/recipes/reducing-boilerplate#generating-action-creators
 * @param {*} type
 * @param  {...any} argNames
 */
const generateActionCreator = (type, ...argNames) => {
    return (...args) => {
        const action = { type };
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index];
        });
        return action;
    };
};

export default generateActionCreator;
