/**
 * Represents the Reward for a Result for the tasks-sdk
 * @class
 */
export class TasksSDKReward {
    /**
     * @param {String} rewardId
     * @returns {TasksSDKReward}
     */
    setRewardId(rewardId) {
        this.rewardid = rewardId;
        return this;
    }

    /**
     * @param {Number} amount
     * @returns {TasksSDKReward}
     */
    setAmount(amount) {
        this.amount = amount;
        return this;
    }
}
