export const TaskInputsWithoutBox = [
    {
        tl: {
            K: [
                [242.8091692567697, 0.0, 317.2366331153609],
                [0.0, 242.7895361377498, 241.6603612979785],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.08533388327548001,
                0.2000234333754919,
                -0.133497816325338,
                0.02623245244332399
            ],
            E: [
                [
                    0.6324484604758533,
                    0.1197138885135941,
                    -0.765295713916312,
                    -0.110305
                ],
                [
                    0.7063605065813238,
                    -0.4946128606338188,
                    0.5063723460437052,
                    -0.00118475
                ],
                [
                    -0.31790529971030457,
                    -0.8608290788493295,
                    -0.39737830517469896,
                    0.00406542
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129619532000_0.png"
        },
        bl: {
            K: [
                [243.0235310503018, 0.0, 323.1638668630977],
                [0.0, 243.0204876786458, 238.6886932217666],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.08826280561739863,
                0.2077959516874352,
                -0.1410407299261771,
                0.02831680648249815
            ],
            E: [
                [
                    0.21212374488129393,
                    0.9629672618191474,
                    -0.16642586794747213,
                    -0.0863347
                ],
                [
                    0.7340940696358063,
                    -0.2694311176538794,
                    -0.623307925318876,
                    -0.0673385
                ],
                [
                    -0.645065433722096,
                    0.010046168638598807,
                    -0.7640612938191602,
                    -0.000733435
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129619532000_1.png"
        },
        br: {
            K: [
                [243.2063956810964, 0.0, 323.0063767503353],
                [0.0, 243.181840403977, 243.2985248458211],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.087083553858119,
                0.2072211127936109,
                -0.1403827877739204,
                0.02815061650486664
            ],
            E: [
                [
                    -0.21136949664121446,
                    0.9633268882917987,
                    0.16530046032507115,
                    0.0094672
                ],
                [
                    0.7324814403951447,
                    0.2680971525242804,
                    -0.6257754040268978,
                    -0.0675828
                ],
                [
                    -0.6471428554548788,
                    -0.011190312882729314,
                    -0.7622866268873494,
                    -0.000703804
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129619532000_2.png"
        },
        tr: {
            K: [
                [243.5217352982409, 0.0, 319.1102745075865],
                [0.0, 243.5141069183304, 238.0920258917096],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.07914947029169274,
                0.1910611756026264,
                -0.128428553365766,
                0.02533828470979081
            ],
            E: [
                [
                    -0.632500452995559,
                    0.12480358846344708,
                    0.7644391678001978,
                    0.0333589
                ],
                [
                    0.7063369311489145,
                    0.49794263157840535,
                    0.5031314692522284,
                    -0.00176709
                ],
                [
                    -0.31785423806447205,
                    0.8581824980523776,
                    -0.40310232371078536,
                    0.00422241
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129619532000_3.png"
        },
        hmd_world_pose: [
            [
                0.9837005752497238,
                -0.17973084276340406,
                -0.005477445838972417,
                -0.0304964
            ],
            [
                0.08695128395233959,
                0.4487956466125878,
                0.8893941431112693,
                -0.149579
            ],
            [
                -0.15739330504313698,
                -0.8753738011507688,
                0.45710836328648846,
                -0.129026
            ],
            [0.0, 0.0, 0.0, 1.0]
        ],
        pcl: null
    },
    {
        tl: {
            K: [
                [242.8091692567697, 0.0, 317.2366331153609],
                [0.0, 242.7895361377498, 241.6603612979785],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.08533388327548001,
                0.2000234333754919,
                -0.133497816325338,
                0.02623245244332399
            ],
            E: [
                [
                    0.6324484604758533,
                    0.1197138885135941,
                    -0.765295713916312,
                    -0.110305
                ],
                [
                    0.7063605065813238,
                    -0.4946128606338188,
                    0.5063723460437052,
                    -0.00118475
                ],
                [
                    -0.31790529971030457,
                    -0.8608290788493295,
                    -0.39737830517469896,
                    0.00406542
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129659532000_0.png"
        },
        bl: {
            K: [
                [243.0235310503018, 0.0, 323.1638668630977],
                [0.0, 243.0204876786458, 238.6886932217666],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.08826280561739863,
                0.2077959516874352,
                -0.1410407299261771,
                0.02831680648249815
            ],
            E: [
                [
                    0.21212374488129393,
                    0.9629672618191474,
                    -0.16642586794747213,
                    -0.0863347
                ],
                [
                    0.7340940696358063,
                    -0.2694311176538794,
                    -0.623307925318876,
                    -0.0673385
                ],
                [
                    -0.645065433722096,
                    0.010046168638598807,
                    -0.7640612938191602,
                    -0.000733435
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129659532000_1.png"
        },
        br: {
            K: [
                [243.2063956810964, 0.0, 323.0063767503353],
                [0.0, 243.181840403977, 243.2985248458211],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.087083553858119,
                0.2072211127936109,
                -0.1403827877739204,
                0.02815061650486664
            ],
            E: [
                [
                    -0.21136949664121446,
                    0.9633268882917987,
                    0.16530046032507115,
                    0.0094672
                ],
                [
                    0.7324814403951447,
                    0.2680971525242804,
                    -0.6257754040268978,
                    -0.0675828
                ],
                [
                    -0.6471428554548788,
                    -0.011190312882729314,
                    -0.7622866268873494,
                    -0.000703804
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129659532000_2.png"
        },
        tr: {
            K: [
                [243.5217352982409, 0.0, 319.1102745075865],
                [0.0, 243.5141069183304, 238.0920258917096],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.07914947029169274,
                0.1910611756026264,
                -0.128428553365766,
                0.02533828470979081
            ],
            E: [
                [
                    -0.632500452995559,
                    0.12480358846344708,
                    0.7644391678001978,
                    0.0333589
                ],
                [
                    0.7063369311489145,
                    0.49794263157840535,
                    0.5031314692522284,
                    -0.00176709
                ],
                [
                    -0.31785423806447205,
                    0.8581824980523776,
                    -0.40310232371078536,
                    0.00422241
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129659532000_3.png"
        },
        hmd_world_pose: [
            [
                0.9735548446990783,
                -0.2238058080730543,
                0.04584675163760882,
                -0.041843
            ],
            [
                0.06344868063052997,
                0.4576740564523036,
                0.8868532702633161,
                -0.150001
            ],
            [
                -0.21946578159065805,
                -0.8604913818994706,
                0.45977097819190454,
                -0.127863
            ],
            [0.0, 0.0, 0.0, 1.0]
        ],
        pcl: null
    },
    {
        tl: {
            K: [
                [244.9300194561764, 0, 319.5121577632451],
                [0, 244.9085434190387, 242.0281879542291],
                [0, 0, 1]
            ],
            distortion_coeffs: [
                -0.07242848640096457,
                0.180251632096771,
                -0.1219743915109378,
                0.02393593016860696
            ],
            E: [
                [
                    0.6341761710963473,
                    0.11934201452529375,
                    -0.7639228151996909,
                    -0.110061
                ],
                [
                    0.7052423974655473,
                    -0.49428963287588357,
                    0.5082429730438693,
                    -0.00108387
                ],
                [
                    -0.3169443875991868,
                    -0.8610663403016452,
                    -0.39763175523203337,
                    0.00439455
                ],
                [0, 0, 0, 1]
            ],
            image_url:
                "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/67414355000_0.png"
        },
        tr: {
            image_url:
                "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/67414355000_3.png",
            K: [
                [243.9401207278099, 0, 319.6090193880545],
                [0, 243.9221438235569, 237.0273000983839],
                [0, 0, 1]
            ],
            distortion_coeffs: [
                -0.07361957754210073,
                0.1822761110924879,
                -0.1230923351975567,
                0.02419393433306516
            ],
            E: [
                [
                    -0.6347645626109721,
                    0.11293383743450403,
                    0.7644082014314196,
                    0.0333112
                ],
                [
                    0.6984118965306659,
                    0.507103265152419,
                    0.5050416826918285,
                    -0.00163134
                ],
                [
                    -0.3305975995643936,
                    0.8544543444995012,
                    -0.4007655178881889,
                    0.00473993
                ],
                [0, 0, 0, 1]
            ]
        },
        bl: {
            E: [
                [
                    0.19808789628111992,
                    0.9651877063418727,
                    -0.17080362078549563,
                    -0.0861027
                ],
                [
                    0.7369075057527189,
                    -0.2615472872436039,
                    -0.6233460872587704,
                    -0.0674486
                ],
                [
                    -0.646319203886305,
                    -0.0023891550864155175,
                    -0.7630634171716962,
                    -0.000947885
                ],
                [0, 0, 0, 1]
            ],
            image_url:
                "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/67414355000_1.png",
            K: [
                [244.1961118221905, 0, 318.9127640979312],
                [0, 244.1920219795713, 240.420848303092],
                [0, 0, 1]
            ],
            distortion_coeffs: [
                -0.07125261862251461,
                0.1804347595497648,
                -0.121838750093778,
                0.02389344238641367
            ]
        },
        br: {
            image_url:
                "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/67414355000_2.png",
            K: [
                [244.8857731044274, 0, 319.3284329125421],
                [0, 244.8855693505955, 239.8679190888073],
                [0, 0, 1]
            ],
            distortion_coeffs: [
                -0.07460629414954237,
                0.1845795707564548,
                -0.1244992963641546,
                0.0244355886085463
            ],
            E: [
                [
                    -0.20877982378147736,
                    0.962576207466482,
                    0.1727947626556423,
                    0.00934055
                ],
                [
                    0.7303115883552828,
                    0.27096994028385507,
                    -0.627072783157225,
                    -0.0672867
                ],
                [
                    -0.6504275279350956,
                    -0.004726127591209717,
                    -0.7595536153703913,
                    -0.000323825
                ],
                [0, 0, 0, 1]
            ]
        },
        hmd_world_pose: [
            [
                0.9451908613043294,
                0.08636201614446481,
                -0.3148901997113331,
                0.104007
            ],
            [
                0.006290347628216293,
                0.9593922939570322,
                0.2820050670157892,
                0.0521826
            ],
            [
                0.32645775719608233,
                -0.26852938100574164,
                0.9062655815505612,
                -0.0171958
            ],
            [0, 0, 0, 1]
        ],
        pcl: null
    }
];

export const TaskInputsWithBox = [
    {
        tl: {
            K: [
                [242.8091692567697, 0.0, 317.2366331153609],
                [0.0, 242.7895361377498, 241.6603612979785],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.08533388327548001,
                0.2000234333754919,
                -0.133497816325338,
                0.02623245244332399
            ],
            E: [
                [
                    0.6324484604758533,
                    0.1197138885135941,
                    -0.765295713916312,
                    -0.110305
                ],
                [
                    0.7063605065813238,
                    -0.4946128606338188,
                    0.5063723460437052,
                    -0.00118475
                ],
                [
                    -0.31790529971030457,
                    -0.8608290788493295,
                    -0.39737830517469896,
                    0.00406542
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129619532000_0.png"
        },
        bl: {
            K: [
                [243.0235310503018, 0.0, 323.1638668630977],
                [0.0, 243.0204876786458, 238.6886932217666],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.08826280561739863,
                0.2077959516874352,
                -0.1410407299261771,
                0.02831680648249815
            ],
            E: [
                [
                    0.21212374488129393,
                    0.9629672618191474,
                    -0.16642586794747213,
                    -0.0863347
                ],
                [
                    0.7340940696358063,
                    -0.2694311176538794,
                    -0.623307925318876,
                    -0.0673385
                ],
                [
                    -0.645065433722096,
                    0.010046168638598807,
                    -0.7640612938191602,
                    -0.000733435
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129619532000_1.png"
        },
        br: {
            K: [
                [243.2063956810964, 0.0, 323.0063767503353],
                [0.0, 243.181840403977, 243.2985248458211],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.087083553858119,
                0.2072211127936109,
                -0.1403827877739204,
                0.02815061650486664
            ],
            E: [
                [
                    -0.21136949664121446,
                    0.9633268882917987,
                    0.16530046032507115,
                    0.0094672
                ],
                [
                    0.7324814403951447,
                    0.2680971525242804,
                    -0.6257754040268978,
                    -0.0675828
                ],
                [
                    -0.6471428554548788,
                    -0.011190312882729314,
                    -0.7622866268873494,
                    -0.000703804
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129619532000_2.png"
        },
        tr: {
            K: [
                [243.5217352982409, 0.0, 319.1102745075865],
                [0.0, 243.5141069183304, 238.0920258917096],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.07914947029169274,
                0.1910611756026264,
                -0.128428553365766,
                0.02533828470979081
            ],
            E: [
                [
                    -0.632500452995559,
                    0.12480358846344708,
                    0.7644391678001978,
                    0.0333589
                ],
                [
                    0.7063369311489145,
                    0.49794263157840535,
                    0.5031314692522284,
                    -0.00176709
                ],
                [
                    -0.31785423806447205,
                    0.8581824980523776,
                    -0.40310232371078536,
                    0.00422241
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129619532000_3.png"
        },
        hmd_world_pose: [
            [
                0.9837005752497238,
                -0.17973084276340406,
                -0.005477445838972417,
                -0.0304964
            ],
            [
                0.08695128395233959,
                0.4487956466125878,
                0.8893941431112693,
                -0.149579
            ],
            [
                -0.15739330504313698,
                -0.8753738011507688,
                0.45710836328648846,
                -0.129026
            ],
            [0.0, 0.0, 0.0, 1.0]
        ],
        pcl: null,
        box: {
            position: {
                x: -0.6984371446915127,
                y: -0.9362154885083529,
                z: -0.4787683174740781
            },
            dimensions: { width: 0.25, length: 0.25, height: 0.25 },
            rotation: { x: 0, y: 0, z: 0 }
        }
    },
    {
        tl: {
            K: [
                [242.8091692567697, 0.0, 317.2366331153609],
                [0.0, 242.7895361377498, 241.6603612979785],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.08533388327548001,
                0.2000234333754919,
                -0.133497816325338,
                0.02623245244332399
            ],
            E: [
                [
                    0.6324484604758533,
                    0.1197138885135941,
                    -0.765295713916312,
                    -0.110305
                ],
                [
                    0.7063605065813238,
                    -0.4946128606338188,
                    0.5063723460437052,
                    -0.00118475
                ],
                [
                    -0.31790529971030457,
                    -0.8608290788493295,
                    -0.39737830517469896,
                    0.00406542
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129659532000_0.png"
        },
        bl: {
            K: [
                [243.0235310503018, 0.0, 323.1638668630977],
                [0.0, 243.0204876786458, 238.6886932217666],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.08826280561739863,
                0.2077959516874352,
                -0.1410407299261771,
                0.02831680648249815
            ],
            E: [
                [
                    0.21212374488129393,
                    0.9629672618191474,
                    -0.16642586794747213,
                    -0.0863347
                ],
                [
                    0.7340940696358063,
                    -0.2694311176538794,
                    -0.623307925318876,
                    -0.0673385
                ],
                [
                    -0.645065433722096,
                    0.010046168638598807,
                    -0.7640612938191602,
                    -0.000733435
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129659532000_1.png"
        },
        br: {
            K: [
                [243.2063956810964, 0.0, 323.0063767503353],
                [0.0, 243.181840403977, 243.2985248458211],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.087083553858119,
                0.2072211127936109,
                -0.1403827877739204,
                0.02815061650486664
            ],
            E: [
                [
                    -0.21136949664121446,
                    0.9633268882917987,
                    0.16530046032507115,
                    0.0094672
                ],
                [
                    0.7324814403951447,
                    0.2680971525242804,
                    -0.6257754040268978,
                    -0.0675828
                ],
                [
                    -0.6471428554548788,
                    -0.011190312882729314,
                    -0.7622866268873494,
                    -0.000703804
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129659532000_2.png"
        },
        tr: {
            K: [
                [243.5217352982409, 0.0, 319.1102745075865],
                [0.0, 243.5141069183304, 238.0920258917096],
                [0.0, 0.0, 1.0]
            ],
            distortion_coeffs: [
                -0.07914947029169274,
                0.1910611756026264,
                -0.128428553365766,
                0.02533828470979081
            ],
            E: [
                [
                    -0.632500452995559,
                    0.12480358846344708,
                    0.7644391678001978,
                    0.0333589
                ],
                [
                    0.7063369311489145,
                    0.49794263157840535,
                    0.5031314692522284,
                    -0.00176709
                ],
                [
                    -0.31785423806447205,
                    0.8581824980523776,
                    -0.40310232371078536,
                    0.00422241
                ],
                [0.0, 0.0, 0.0, 1.0]
            ],
            image_url:
                "https://qm-coconut.s3.eu-central-1.amazonaws.com/2021-02-11%20data_examples/frames/129659532000_3.png"
        },
        hmd_world_pose: [
            [
                0.9735548446990783,
                -0.2238058080730543,
                0.04584675163760882,
                -0.041843
            ],
            [
                0.06344868063052997,
                0.4576740564523036,
                0.8868532702633161,
                -0.150001
            ],
            [
                -0.21946578159065805,
                -0.8604913818994706,
                0.45977097819190454,
                -0.127863
            ],
            [0.0, 0.0, 0.0, 1.0]
        ],
        pcl: null,
        box: {
            position: {
                x: -0.635583815991992,
                y: -0.8550099280470678,
                z: -0.4417691239381163
            },
            dimensions: { width: 0.25, length: 0.25, height: 0.25 },
            rotation: { x: 0, y: 0, z: 0 }
        }
    }
];

export const TaskInputsForSequenceKeypoint = [
    {
        recording_id: "121114456609530",
        timestamps: {
            28948073000: {
                tl: {
                    K: [
                        [244.9300194561764, 0.0, 319.5121577632451],
                        [0.0, 244.9085434190387, 242.0281879542291],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07242848640096457,
                        0.180251632096771,
                        -0.1219743915109378,
                        0.02393593016860696
                    ],
                    E: [
                        [
                            0.6341761710963473,
                            0.11934201452529375,
                            -0.7639228151996909,
                            -0.110061
                        ],
                        [
                            0.7052423974655473,
                            -0.49428963287588357,
                            0.5082429730438693,
                            -0.00108387
                        ],
                        [
                            -0.3169443875991868,
                            -0.8610663403016452,
                            -0.39763175523203337,
                            0.00439455
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/28948073000_0.png"
                },
                bl: {
                    K: [
                        [244.1961118221905, 0.0, 318.9127640979312],
                        [0.0, 244.1920219795713, 240.420848303092],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07125261862251461,
                        0.1804347595497648,
                        -0.121838750093778,
                        0.02389344238641367
                    ],
                    E: [
                        [
                            0.19808789628111992,
                            0.9651877063418727,
                            -0.17080362078549563,
                            -0.0861027
                        ],
                        [
                            0.7369075057527189,
                            -0.2615472872436039,
                            -0.6233460872587704,
                            -0.0674486
                        ],
                        [
                            -0.646319203886305,
                            -0.0023891550864155175,
                            -0.7630634171716962,
                            -0.000947885
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/28948073000_1.png"
                },
                br: {
                    K: [
                        [244.8857731044274, 0.0, 319.3284329125421],
                        [0.0, 244.8855693505955, 239.8679190888073],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07460629414954237,
                        0.1845795707564548,
                        -0.1244992963641546,
                        0.0244355886085463
                    ],
                    E: [
                        [
                            -0.20877982378147736,
                            0.962576207466482,
                            0.1727947626556423,
                            0.00934055
                        ],
                        [
                            0.7303115883552828,
                            0.27096994028385507,
                            -0.627072783157225,
                            -0.0672867
                        ],
                        [
                            -0.6504275279350956,
                            -0.004726127591209717,
                            -0.7595536153703913,
                            -0.000323825
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/28948073000_2.png"
                },
                tr: {
                    K: [
                        [243.9401207278099, 0.0, 319.6090193880545],
                        [0.0, 243.9221438235569, 237.0273000983839],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07361957754210073,
                        0.1822761110924879,
                        -0.1230923351975567,
                        0.02419393433306516
                    ],
                    E: [
                        [
                            -0.6347645626109721,
                            0.11293383743450403,
                            0.7644082014314196,
                            0.0333112
                        ],
                        [
                            0.6984118965306659,
                            0.507103265152419,
                            0.5050416826918285,
                            -0.00163134
                        ],
                        [
                            -0.3305975995643936,
                            0.8544543444995012,
                            -0.4007655178881889,
                            0.00473993
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/28948073000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.6627215925519904,
                        0.3502013596605039,
                        -0.6619358718616842,
                        -0.00308942
                    ],
                    [
                        0.05494839497337381,
                        0.858794814625847,
                        0.509364447386748,
                        -0.1158
                    ],
                    [
                        0.7468472164072139,
                        -0.37393913149561053,
                        0.5498988645933947,
                        -0.242819
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.46345312636247127,
                        -0.693235134587028,
                        -0.5519386268770798,
                        0.248829
                    ],
                    [
                        -0.014281525435817621,
                        -0.6286328070998726,
                        0.7775711104901987,
                        -0.409689
                    ],
                    [
                        -0.8860063417922455,
                        -0.352485236587066,
                        -0.30124229499205946,
                        -0.395622
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        0.01694317152800396,
                        -0.4015539697993471,
                        0.9156786217210477,
                        0.897106
                    ],
                    [
                        -0.776842792941693,
                        0.5712677797769917,
                        0.264893183836837,
                        -1.13366
                    ],
                    [
                        -0.629466602762309,
                        -0.7158264685851254,
                        -0.3022652194345655,
                        0.321331
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                pcl: null,
                selected_trackable: "right"
            },
            87614153000: {
                tl: {
                    K: [
                        [244.9300194561764, 0.0, 319.5121577632451],
                        [0.0, 244.9085434190387, 242.0281879542291],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07242848640096457,
                        0.180251632096771,
                        -0.1219743915109378,
                        0.02393593016860696
                    ],
                    E: [
                        [
                            0.6341761710963473,
                            0.11934201452529375,
                            -0.7639228151996909,
                            -0.110061
                        ],
                        [
                            0.7052423974655473,
                            -0.49428963287588357,
                            0.5082429730438693,
                            -0.00108387
                        ],
                        [
                            -0.3169443875991868,
                            -0.8610663403016452,
                            -0.39763175523203337,
                            0.00439455
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/87614153000_0.png"
                },
                bl: {
                    K: [
                        [244.1961118221905, 0.0, 318.9127640979312],
                        [0.0, 244.1920219795713, 240.420848303092],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07125261862251461,
                        0.1804347595497648,
                        -0.121838750093778,
                        0.02389344238641367
                    ],
                    E: [
                        [
                            0.19808789628111992,
                            0.9651877063418727,
                            -0.17080362078549563,
                            -0.0861027
                        ],
                        [
                            0.7369075057527189,
                            -0.2615472872436039,
                            -0.6233460872587704,
                            -0.0674486
                        ],
                        [
                            -0.646319203886305,
                            -0.0023891550864155175,
                            -0.7630634171716962,
                            -0.000947885
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/87614153000_1.png"
                },
                br: {
                    K: [
                        [244.8857731044274, 0.0, 319.3284329125421],
                        [0.0, 244.8855693505955, 239.8679190888073],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07460629414954237,
                        0.1845795707564548,
                        -0.1244992963641546,
                        0.0244355886085463
                    ],
                    E: [
                        [
                            -0.20877982378147736,
                            0.962576207466482,
                            0.1727947626556423,
                            0.00934055
                        ],
                        [
                            0.7303115883552828,
                            0.27096994028385507,
                            -0.627072783157225,
                            -0.0672867
                        ],
                        [
                            -0.6504275279350956,
                            -0.004726127591209717,
                            -0.7595536153703913,
                            -0.000323825
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/87614153000_2.png"
                },
                tr: {
                    K: [
                        [243.9401207278099, 0.0, 319.6090193880545],
                        [0.0, 243.9221438235569, 237.0273000983839],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07361957754210073,
                        0.1822761110924879,
                        -0.1230923351975567,
                        0.02419393433306516
                    ],
                    E: [
                        [
                            -0.6347645626109721,
                            0.11293383743450403,
                            0.7644082014314196,
                            0.0333112
                        ],
                        [
                            0.6984118965306659,
                            0.507103265152419,
                            0.5050416826918285,
                            -0.00163134
                        ],
                        [
                            -0.3305975995643936,
                            0.8544543444995012,
                            -0.4007655178881889,
                            0.00473993
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/87614153000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.8639501302570299,
                        0.10962504180080462,
                        -0.4915002773539734,
                        0.110734
                    ],
                    [
                        0.06570264432028952,
                        0.9431358698491744,
                        0.32584949521698303,
                        0.0408723
                    ],
                    [
                        0.499272806147283,
                        -0.31381058174321175,
                        0.8076197024762364,
                        -0.00591543
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.9167611525198586,
                        -0.3347635899205919,
                        -0.21790440127252683,
                        0.476425
                    ],
                    [
                        -0.18157643186224076,
                        -0.8351753901852661,
                        0.5191455162293769,
                        -0.220476
                    ],
                    [
                        -0.35577941005999436,
                        -0.4363661381138172,
                        -0.8264415314376427,
                        -0.200359
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        0.01717530643518269,
                        -0.4016779407608596,
                        0.9156199215585983,
                        0.897105
                    ],
                    [
                        -0.7768218009739337,
                        0.57117936587621,
                        0.2651452838141102,
                        -1.13367
                    ],
                    [
                        -0.6294862177843707,
                        -0.7158274679721128,
                        -0.30222200071497485,
                        0.321331
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "right",
                pcl: null
            },
            86880827000: {
                tl: {
                    K: [
                        [244.9300194561764, 0.0, 319.5121577632451],
                        [0.0, 244.9085434190387, 242.0281879542291],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07242848640096457,
                        0.180251632096771,
                        -0.1219743915109378,
                        0.02393593016860696
                    ],
                    E: [
                        [
                            0.6341761710963473,
                            0.11934201452529375,
                            -0.7639228151996909,
                            -0.110061
                        ],
                        [
                            0.7052423974655473,
                            -0.49428963287588357,
                            0.5082429730438693,
                            -0.00108387
                        ],
                        [
                            -0.3169443875991868,
                            -0.8610663403016452,
                            -0.39763175523203337,
                            0.00439455
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/86880827000_0.png"
                },
                bl: {
                    K: [
                        [244.1961118221905, 0.0, 318.9127640979312],
                        [0.0, 244.1920219795713, 240.420848303092],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07125261862251461,
                        0.1804347595497648,
                        -0.121838750093778,
                        0.02389344238641367
                    ],
                    E: [
                        [
                            0.19808789628111992,
                            0.9651877063418727,
                            -0.17080362078549563,
                            -0.0861027
                        ],
                        [
                            0.7369075057527189,
                            -0.2615472872436039,
                            -0.6233460872587704,
                            -0.0674486
                        ],
                        [
                            -0.646319203886305,
                            -0.0023891550864155175,
                            -0.7630634171716962,
                            -0.000947885
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/86880827000_1.png"
                },
                br: {
                    K: [
                        [244.8857731044274, 0.0, 319.3284329125421],
                        [0.0, 244.8855693505955, 239.8679190888073],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07460629414954237,
                        0.1845795707564548,
                        -0.1244992963641546,
                        0.0244355886085463
                    ],
                    E: [
                        [
                            -0.20877982378147736,
                            0.962576207466482,
                            0.1727947626556423,
                            0.00934055
                        ],
                        [
                            0.7303115883552828,
                            0.27096994028385507,
                            -0.627072783157225,
                            -0.0672867
                        ],
                        [
                            -0.6504275279350956,
                            -0.004726127591209717,
                            -0.7595536153703913,
                            -0.000323825
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/86880827000_2.png"
                },
                tr: {
                    K: [
                        [243.9401207278099, 0.0, 319.6090193880545],
                        [0.0, 243.9221438235569, 237.0273000983839],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07361957754210073,
                        0.1822761110924879,
                        -0.1230923351975567,
                        0.02419393433306516
                    ],
                    E: [
                        [
                            -0.6347645626109721,
                            0.11293383743450403,
                            0.7644082014314196,
                            0.0333112
                        ],
                        [
                            0.6984118965306659,
                            0.507103265152419,
                            0.5050416826918285,
                            -0.00163134
                        ],
                        [
                            -0.3305975995643936,
                            0.8544543444995012,
                            -0.4007655178881889,
                            0.00473993
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/86880827000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.8938983604420391,
                        0.12867295496476835,
                        -0.4294053933751521,
                        0.113995
                    ],
                    [
                        0.0334438468892126,
                        0.9361047174190623,
                        0.35012778685935303,
                        0.0367605
                    ],
                    [
                        0.4470203913741356,
                        -0.3273396228482161,
                        0.8324791534983338,
                        -0.011091
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.9929326252775189,
                        -0.10464020145735409,
                        -0.055993123671202655,
                        0.483784
                    ],
                    [
                        -0.039667558597844384,
                        -0.737286423688559,
                        0.6744147197677567,
                        -0.286281
                    ],
                    [
                        -0.11185386204499523,
                        -0.667427267710497,
                        -0.7362265655775538,
                        -0.213144
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        0.017184429027587822,
                        -0.4017198403629717,
                        0.9156013680951685,
                        0.897106
                    ],
                    [
                        -0.7769018814475565,
                        0.5710731526805661,
                        0.26513943669459217,
                        -1.13367
                    ],
                    [
                        -0.6293871320596272,
                        -0.715888695361386,
                        -0.3022833337310048,
                        0.32133
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            22748135000: {
                tl: {
                    K: [
                        [244.9300194561764, 0.0, 319.5121577632451],
                        [0.0, 244.9085434190387, 242.0281879542291],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07242848640096457,
                        0.180251632096771,
                        -0.1219743915109378,
                        0.02393593016860696
                    ],
                    E: [
                        [
                            0.6341761710963473,
                            0.11934201452529375,
                            -0.7639228151996909,
                            -0.110061
                        ],
                        [
                            0.7052423974655473,
                            -0.49428963287588357,
                            0.5082429730438693,
                            -0.00108387
                        ],
                        [
                            -0.3169443875991868,
                            -0.8610663403016452,
                            -0.39763175523203337,
                            0.00439455
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/22748135000_0.png"
                },
                bl: {
                    K: [
                        [244.1961118221905, 0.0, 318.9127640979312],
                        [0.0, 244.1920219795713, 240.420848303092],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07125261862251461,
                        0.1804347595497648,
                        -0.121838750093778,
                        0.02389344238641367
                    ],
                    E: [
                        [
                            0.19808789628111992,
                            0.9651877063418727,
                            -0.17080362078549563,
                            -0.0861027
                        ],
                        [
                            0.7369075057527189,
                            -0.2615472872436039,
                            -0.6233460872587704,
                            -0.0674486
                        ],
                        [
                            -0.646319203886305,
                            -0.0023891550864155175,
                            -0.7630634171716962,
                            -0.000947885
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/22748135000_1.png"
                },
                br: {
                    K: [
                        [244.8857731044274, 0.0, 319.3284329125421],
                        [0.0, 244.8855693505955, 239.8679190888073],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07460629414954237,
                        0.1845795707564548,
                        -0.1244992963641546,
                        0.0244355886085463
                    ],
                    E: [
                        [
                            -0.20877982378147736,
                            0.962576207466482,
                            0.1727947626556423,
                            0.00934055
                        ],
                        [
                            0.7303115883552828,
                            0.27096994028385507,
                            -0.627072783157225,
                            -0.0672867
                        ],
                        [
                            -0.6504275279350956,
                            -0.004726127591209717,
                            -0.7595536153703913,
                            -0.000323825
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/22748135000_2.png"
                },
                tr: {
                    K: [
                        [243.9401207278099, 0.0, 319.6090193880545],
                        [0.0, 243.9221438235569, 237.0273000983839],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07361957754210073,
                        0.1822761110924879,
                        -0.1230923351975567,
                        0.02419393433306516
                    ],
                    E: [
                        [
                            -0.6347645626109721,
                            0.11293383743450403,
                            0.7644082014314196,
                            0.0333112
                        ],
                        [
                            0.6984118965306659,
                            0.507103265152419,
                            0.5050416826918285,
                            -0.00163134
                        ],
                        [
                            -0.3305975995643936,
                            0.8544543444995012,
                            -0.4007655178881889,
                            0.00473993
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/22748135000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.644334022271886,
                        0.42408658102329244,
                        -0.6363837203597427,
                        0.0458594
                    ],
                    [
                        0.046287511215523686,
                        0.8089996860736937,
                        0.5859837661899328,
                        -0.0846676
                    ],
                    [
                        0.763342081932082,
                        -0.40702589564871605,
                        0.5016361093690874,
                        -0.329615
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.4634144961339489,
                        -0.6932720825870302,
                        -0.5519246545302718,
                        0.248836
                    ],
                    [
                        -0.014277390714211247,
                        -0.6286007593930841,
                        0.7775970945192833,
                        -0.40969
                    ],
                    [
                        -0.8860266140965021,
                        -0.35246972181434144,
                        -0.30120082057791353,
                        -0.39562
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        0.01682040970653432,
                        -0.40146079798937345,
                        0.9157217380269181,
                        0.897109
                    ],
                    [
                        -0.7768255959165085,
                        0.5713546541537557,
                        0.26475621372463354,
                        -1.13367
                    ],
                    [
                        -0.6294911177659824,
                        -0.7158093928236606,
                        -0.3022546042646106,
                        0.321337
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            23681459000: {
                tl: {
                    K: [
                        [244.9300194561764, 0.0, 319.5121577632451],
                        [0.0, 244.9085434190387, 242.0281879542291],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07242848640096457,
                        0.180251632096771,
                        -0.1219743915109378,
                        0.02393593016860696
                    ],
                    E: [
                        [
                            0.6341761710963473,
                            0.11934201452529375,
                            -0.7639228151996909,
                            -0.110061
                        ],
                        [
                            0.7052423974655473,
                            -0.49428963287588357,
                            0.5082429730438693,
                            -0.00108387
                        ],
                        [
                            -0.3169443875991868,
                            -0.8610663403016452,
                            -0.39763175523203337,
                            0.00439455
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/23681459000_0.png"
                },
                bl: {
                    K: [
                        [244.1961118221905, 0.0, 318.9127640979312],
                        [0.0, 244.1920219795713, 240.420848303092],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07125261862251461,
                        0.1804347595497648,
                        -0.121838750093778,
                        0.02389344238641367
                    ],
                    E: [
                        [
                            0.19808789628111992,
                            0.9651877063418727,
                            -0.17080362078549563,
                            -0.0861027
                        ],
                        [
                            0.7369075057527189,
                            -0.2615472872436039,
                            -0.6233460872587704,
                            -0.0674486
                        ],
                        [
                            -0.646319203886305,
                            -0.0023891550864155175,
                            -0.7630634171716962,
                            -0.000947885
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/23681459000_1.png"
                },
                br: {
                    K: [
                        [244.8857731044274, 0.0, 319.3284329125421],
                        [0.0, 244.8855693505955, 239.8679190888073],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07460629414954237,
                        0.1845795707564548,
                        -0.1244992963641546,
                        0.0244355886085463
                    ],
                    E: [
                        [
                            -0.20877982378147736,
                            0.962576207466482,
                            0.1727947626556423,
                            0.00934055
                        ],
                        [
                            0.7303115883552828,
                            0.27096994028385507,
                            -0.627072783157225,
                            -0.0672867
                        ],
                        [
                            -0.6504275279350956,
                            -0.004726127591209717,
                            -0.7595536153703913,
                            -0.000323825
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/23681459000_2.png"
                },
                tr: {
                    K: [
                        [243.9401207278099, 0.0, 319.6090193880545],
                        [0.0, 243.9221438235569, 237.0273000983839],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07361957754210073,
                        0.1822761110924879,
                        -0.1230923351975567,
                        0.02419393433306516
                    ],
                    E: [
                        [
                            -0.6347645626109721,
                            0.11293383743450403,
                            0.7644082014314196,
                            0.0333112
                        ],
                        [
                            0.6984118965306659,
                            0.507103265152419,
                            0.5050416826918285,
                            -0.00163134
                        ],
                        [
                            -0.3305975995643936,
                            0.8544543444995012,
                            -0.4007655178881889,
                            0.00473993
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/23681459000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.6949910543921097,
                        0.19098007336250175,
                        -0.6931912044258761,
                        -0.0190472
                    ],
                    [
                        0.18550326935834974,
                        0.8838167606228945,
                        0.4294839586054601,
                        -0.135468
                    ],
                    [
                        0.694676882710449,
                        -0.42707674394715817,
                        0.5788173143636828,
                        -0.314719
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.4634815749046655,
                        -0.693238832773145,
                        -0.5519100927317946,
                        0.248831
                    ],
                    [
                        -0.014286968654045218,
                        -0.6286138166199033,
                        0.7775863631013832,
                        -0.409693
                    ],
                    [
                        -0.8859913725598962,
                        -0.3525118299999098,
                        -0.3012552032074221,
                        -0.395622
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        0.016809373805553647,
                        -0.4015644661461345,
                        0.9156764846172666,
                        0.897109
                    ],
                    [
                        -0.7767750577453764,
                        0.5713857664570183,
                        0.264837337917056,
                        -1.13366
                    ],
                    [
                        -0.6295537742059311,
                        -0.715726404025376,
                        -0.3023206244436061,
                        0.321335
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            }
        }
    }
];

export const TaskInputsForSequenceOrientation = [
    {
        recording_id: "121114456609530",
        // this box is relative to the selected trackable
        box: {
            position: {
                x: 0.6522732124561244,
                y: -0.8229714124780796,
                z: 0.7859126106713304
            },
            dimensions: { width: 0.25, length: 0.25, height: 0.25 },
            rotation: { x: 0, y: 0, z: 0 }
        },
        timestamps: {
            28948073000: {
                tl: {
                    K: [
                        [244.9300194561764, 0.0, 319.5121577632451],
                        [0.0, 244.9085434190387, 242.0281879542291],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07242848640096457,
                        0.180251632096771,
                        -0.1219743915109378,
                        0.02393593016860696
                    ],
                    E: [
                        [
                            0.6341761710963473,
                            0.11934201452529375,
                            -0.7639228151996909,
                            -0.110061
                        ],
                        [
                            0.7052423974655473,
                            -0.49428963287588357,
                            0.5082429730438693,
                            -0.00108387
                        ],
                        [
                            -0.3169443875991868,
                            -0.8610663403016452,
                            -0.39763175523203337,
                            0.00439455
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/28948073000_0.png"
                },
                bl: {
                    K: [
                        [244.1961118221905, 0.0, 318.9127640979312],
                        [0.0, 244.1920219795713, 240.420848303092],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07125261862251461,
                        0.1804347595497648,
                        -0.121838750093778,
                        0.02389344238641367
                    ],
                    E: [
                        [
                            0.19808789628111992,
                            0.9651877063418727,
                            -0.17080362078549563,
                            -0.0861027
                        ],
                        [
                            0.7369075057527189,
                            -0.2615472872436039,
                            -0.6233460872587704,
                            -0.0674486
                        ],
                        [
                            -0.646319203886305,
                            -0.0023891550864155175,
                            -0.7630634171716962,
                            -0.000947885
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/28948073000_1.png"
                },
                br: {
                    K: [
                        [244.8857731044274, 0.0, 319.3284329125421],
                        [0.0, 244.8855693505955, 239.8679190888073],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07460629414954237,
                        0.1845795707564548,
                        -0.1244992963641546,
                        0.0244355886085463
                    ],
                    E: [
                        [
                            -0.20877982378147736,
                            0.962576207466482,
                            0.1727947626556423,
                            0.00934055
                        ],
                        [
                            0.7303115883552828,
                            0.27096994028385507,
                            -0.627072783157225,
                            -0.0672867
                        ],
                        [
                            -0.6504275279350956,
                            -0.004726127591209717,
                            -0.7595536153703913,
                            -0.000323825
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/28948073000_2.png"
                },
                tr: {
                    K: [
                        [243.9401207278099, 0.0, 319.6090193880545],
                        [0.0, 243.9221438235569, 237.0273000983839],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07361957754210073,
                        0.1822761110924879,
                        -0.1230923351975567,
                        0.02419393433306516
                    ],
                    E: [
                        [
                            -0.6347645626109721,
                            0.11293383743450403,
                            0.7644082014314196,
                            0.0333112
                        ],
                        [
                            0.6984118965306659,
                            0.507103265152419,
                            0.5050416826918285,
                            -0.00163134
                        ],
                        [
                            -0.3305975995643936,
                            0.8544543444995012,
                            -0.4007655178881889,
                            0.00473993
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/28948073000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.6627215925519904,
                        0.3502013596605039,
                        -0.6619358718616842,
                        -0.00308942
                    ],
                    [
                        0.05494839497337381,
                        0.858794814625847,
                        0.509364447386748,
                        -0.1158
                    ],
                    [
                        0.7468472164072139,
                        -0.37393913149561053,
                        0.5498988645933947,
                        -0.242819
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.46345312636247127,
                        -0.693235134587028,
                        -0.5519386268770798,
                        0.248829
                    ],
                    [
                        -0.014281525435817621,
                        -0.6286328070998726,
                        0.7775711104901987,
                        -0.409689
                    ],
                    [
                        -0.8860063417922455,
                        -0.352485236587066,
                        -0.30124229499205946,
                        -0.395622
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        0.01694317152800396,
                        -0.4015539697993471,
                        0.9156786217210477,
                        0.897106
                    ],
                    [
                        -0.776842792941693,
                        0.5712677797769917,
                        0.264893183836837,
                        -1.13366
                    ],
                    [
                        -0.629466602762309,
                        -0.7158264685851254,
                        -0.3022652194345655,
                        0.321331
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                pcl: null,
                selected_trackable: "right"
            },
            87614153000: {
                tl: {
                    K: [
                        [244.9300194561764, 0.0, 319.5121577632451],
                        [0.0, 244.9085434190387, 242.0281879542291],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07242848640096457,
                        0.180251632096771,
                        -0.1219743915109378,
                        0.02393593016860696
                    ],
                    E: [
                        [
                            0.6341761710963473,
                            0.11934201452529375,
                            -0.7639228151996909,
                            -0.110061
                        ],
                        [
                            0.7052423974655473,
                            -0.49428963287588357,
                            0.5082429730438693,
                            -0.00108387
                        ],
                        [
                            -0.3169443875991868,
                            -0.8610663403016452,
                            -0.39763175523203337,
                            0.00439455
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/87614153000_0.png"
                },
                bl: {
                    K: [
                        [244.1961118221905, 0.0, 318.9127640979312],
                        [0.0, 244.1920219795713, 240.420848303092],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07125261862251461,
                        0.1804347595497648,
                        -0.121838750093778,
                        0.02389344238641367
                    ],
                    E: [
                        [
                            0.19808789628111992,
                            0.9651877063418727,
                            -0.17080362078549563,
                            -0.0861027
                        ],
                        [
                            0.7369075057527189,
                            -0.2615472872436039,
                            -0.6233460872587704,
                            -0.0674486
                        ],
                        [
                            -0.646319203886305,
                            -0.0023891550864155175,
                            -0.7630634171716962,
                            -0.000947885
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/87614153000_1.png"
                },
                br: {
                    K: [
                        [244.8857731044274, 0.0, 319.3284329125421],
                        [0.0, 244.8855693505955, 239.8679190888073],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07460629414954237,
                        0.1845795707564548,
                        -0.1244992963641546,
                        0.0244355886085463
                    ],
                    E: [
                        [
                            -0.20877982378147736,
                            0.962576207466482,
                            0.1727947626556423,
                            0.00934055
                        ],
                        [
                            0.7303115883552828,
                            0.27096994028385507,
                            -0.627072783157225,
                            -0.0672867
                        ],
                        [
                            -0.6504275279350956,
                            -0.004726127591209717,
                            -0.7595536153703913,
                            -0.000323825
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/87614153000_2.png"
                },
                tr: {
                    K: [
                        [243.9401207278099, 0.0, 319.6090193880545],
                        [0.0, 243.9221438235569, 237.0273000983839],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07361957754210073,
                        0.1822761110924879,
                        -0.1230923351975567,
                        0.02419393433306516
                    ],
                    E: [
                        [
                            -0.6347645626109721,
                            0.11293383743450403,
                            0.7644082014314196,
                            0.0333112
                        ],
                        [
                            0.6984118965306659,
                            0.507103265152419,
                            0.5050416826918285,
                            -0.00163134
                        ],
                        [
                            -0.3305975995643936,
                            0.8544543444995012,
                            -0.4007655178881889,
                            0.00473993
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/87614153000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.8639501302570299,
                        0.10962504180080462,
                        -0.4915002773539734,
                        0.110734
                    ],
                    [
                        0.06570264432028952,
                        0.9431358698491744,
                        0.32584949521698303,
                        0.0408723
                    ],
                    [
                        0.499272806147283,
                        -0.31381058174321175,
                        0.8076197024762364,
                        -0.00591543
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.9167611525198586,
                        -0.3347635899205919,
                        -0.21790440127252683,
                        0.476425
                    ],
                    [
                        -0.18157643186224076,
                        -0.8351753901852661,
                        0.5191455162293769,
                        -0.220476
                    ],
                    [
                        -0.35577941005999436,
                        -0.4363661381138172,
                        -0.8264415314376427,
                        -0.200359
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        0.01717530643518269,
                        -0.4016779407608596,
                        0.9156199215585983,
                        0.897105
                    ],
                    [
                        -0.7768218009739337,
                        0.57117936587621,
                        0.2651452838141102,
                        -1.13367
                    ],
                    [
                        -0.6294862177843707,
                        -0.7158274679721128,
                        -0.30222200071497485,
                        0.321331
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "right",
                pcl: null
            },
            86880827000: {
                tl: {
                    K: [
                        [244.9300194561764, 0.0, 319.5121577632451],
                        [0.0, 244.9085434190387, 242.0281879542291],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07242848640096457,
                        0.180251632096771,
                        -0.1219743915109378,
                        0.02393593016860696
                    ],
                    E: [
                        [
                            0.6341761710963473,
                            0.11934201452529375,
                            -0.7639228151996909,
                            -0.110061
                        ],
                        [
                            0.7052423974655473,
                            -0.49428963287588357,
                            0.5082429730438693,
                            -0.00108387
                        ],
                        [
                            -0.3169443875991868,
                            -0.8610663403016452,
                            -0.39763175523203337,
                            0.00439455
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/86880827000_0.png"
                },
                bl: {
                    K: [
                        [244.1961118221905, 0.0, 318.9127640979312],
                        [0.0, 244.1920219795713, 240.420848303092],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07125261862251461,
                        0.1804347595497648,
                        -0.121838750093778,
                        0.02389344238641367
                    ],
                    E: [
                        [
                            0.19808789628111992,
                            0.9651877063418727,
                            -0.17080362078549563,
                            -0.0861027
                        ],
                        [
                            0.7369075057527189,
                            -0.2615472872436039,
                            -0.6233460872587704,
                            -0.0674486
                        ],
                        [
                            -0.646319203886305,
                            -0.0023891550864155175,
                            -0.7630634171716962,
                            -0.000947885
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/86880827000_1.png"
                },
                br: {
                    K: [
                        [244.8857731044274, 0.0, 319.3284329125421],
                        [0.0, 244.8855693505955, 239.8679190888073],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07460629414954237,
                        0.1845795707564548,
                        -0.1244992963641546,
                        0.0244355886085463
                    ],
                    E: [
                        [
                            -0.20877982378147736,
                            0.962576207466482,
                            0.1727947626556423,
                            0.00934055
                        ],
                        [
                            0.7303115883552828,
                            0.27096994028385507,
                            -0.627072783157225,
                            -0.0672867
                        ],
                        [
                            -0.6504275279350956,
                            -0.004726127591209717,
                            -0.7595536153703913,
                            -0.000323825
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/86880827000_2.png"
                },
                tr: {
                    K: [
                        [243.9401207278099, 0.0, 319.6090193880545],
                        [0.0, 243.9221438235569, 237.0273000983839],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07361957754210073,
                        0.1822761110924879,
                        -0.1230923351975567,
                        0.02419393433306516
                    ],
                    E: [
                        [
                            -0.6347645626109721,
                            0.11293383743450403,
                            0.7644082014314196,
                            0.0333112
                        ],
                        [
                            0.6984118965306659,
                            0.507103265152419,
                            0.5050416826918285,
                            -0.00163134
                        ],
                        [
                            -0.3305975995643936,
                            0.8544543444995012,
                            -0.4007655178881889,
                            0.00473993
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/86880827000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.8938983604420391,
                        0.12867295496476835,
                        -0.4294053933751521,
                        0.113995
                    ],
                    [
                        0.0334438468892126,
                        0.9361047174190623,
                        0.35012778685935303,
                        0.0367605
                    ],
                    [
                        0.4470203913741356,
                        -0.3273396228482161,
                        0.8324791534983338,
                        -0.011091
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.9929326252775189,
                        -0.10464020145735409,
                        -0.055993123671202655,
                        0.483784
                    ],
                    [
                        -0.039667558597844384,
                        -0.737286423688559,
                        0.6744147197677567,
                        -0.286281
                    ],
                    [
                        -0.11185386204499523,
                        -0.667427267710497,
                        -0.7362265655775538,
                        -0.213144
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        0.017184429027587822,
                        -0.4017198403629717,
                        0.9156013680951685,
                        0.897106
                    ],
                    [
                        -0.7769018814475565,
                        0.5710731526805661,
                        0.26513943669459217,
                        -1.13367
                    ],
                    [
                        -0.6293871320596272,
                        -0.715888695361386,
                        -0.3022833337310048,
                        0.32133
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "right",
                pcl: null
            },
            22748135000: {
                tl: {
                    K: [
                        [244.9300194561764, 0.0, 319.5121577632451],
                        [0.0, 244.9085434190387, 242.0281879542291],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07242848640096457,
                        0.180251632096771,
                        -0.1219743915109378,
                        0.02393593016860696
                    ],
                    E: [
                        [
                            0.6341761710963473,
                            0.11934201452529375,
                            -0.7639228151996909,
                            -0.110061
                        ],
                        [
                            0.7052423974655473,
                            -0.49428963287588357,
                            0.5082429730438693,
                            -0.00108387
                        ],
                        [
                            -0.3169443875991868,
                            -0.8610663403016452,
                            -0.39763175523203337,
                            0.00439455
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/22748135000_0.png"
                },
                bl: {
                    K: [
                        [244.1961118221905, 0.0, 318.9127640979312],
                        [0.0, 244.1920219795713, 240.420848303092],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07125261862251461,
                        0.1804347595497648,
                        -0.121838750093778,
                        0.02389344238641367
                    ],
                    E: [
                        [
                            0.19808789628111992,
                            0.9651877063418727,
                            -0.17080362078549563,
                            -0.0861027
                        ],
                        [
                            0.7369075057527189,
                            -0.2615472872436039,
                            -0.6233460872587704,
                            -0.0674486
                        ],
                        [
                            -0.646319203886305,
                            -0.0023891550864155175,
                            -0.7630634171716962,
                            -0.000947885
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/22748135000_1.png"
                },
                br: {
                    K: [
                        [244.8857731044274, 0.0, 319.3284329125421],
                        [0.0, 244.8855693505955, 239.8679190888073],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07460629414954237,
                        0.1845795707564548,
                        -0.1244992963641546,
                        0.0244355886085463
                    ],
                    E: [
                        [
                            -0.20877982378147736,
                            0.962576207466482,
                            0.1727947626556423,
                            0.00934055
                        ],
                        [
                            0.7303115883552828,
                            0.27096994028385507,
                            -0.627072783157225,
                            -0.0672867
                        ],
                        [
                            -0.6504275279350956,
                            -0.004726127591209717,
                            -0.7595536153703913,
                            -0.000323825
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/22748135000_2.png"
                },
                tr: {
                    K: [
                        [243.9401207278099, 0.0, 319.6090193880545],
                        [0.0, 243.9221438235569, 237.0273000983839],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07361957754210073,
                        0.1822761110924879,
                        -0.1230923351975567,
                        0.02419393433306516
                    ],
                    E: [
                        [
                            -0.6347645626109721,
                            0.11293383743450403,
                            0.7644082014314196,
                            0.0333112
                        ],
                        [
                            0.6984118965306659,
                            0.507103265152419,
                            0.5050416826918285,
                            -0.00163134
                        ],
                        [
                            -0.3305975995643936,
                            0.8544543444995012,
                            -0.4007655178881889,
                            0.00473993
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/22748135000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.644334022271886,
                        0.42408658102329244,
                        -0.6363837203597427,
                        0.0458594
                    ],
                    [
                        0.046287511215523686,
                        0.8089996860736937,
                        0.5859837661899328,
                        -0.0846676
                    ],
                    [
                        0.763342081932082,
                        -0.40702589564871605,
                        0.5016361093690874,
                        -0.329615
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.4634144961339489,
                        -0.6932720825870302,
                        -0.5519246545302718,
                        0.248836
                    ],
                    [
                        -0.014277390714211247,
                        -0.6286007593930841,
                        0.7775970945192833,
                        -0.40969
                    ],
                    [
                        -0.8860266140965021,
                        -0.35246972181434144,
                        -0.30120082057791353,
                        -0.39562
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        0.01682040970653432,
                        -0.40146079798937345,
                        0.9157217380269181,
                        0.897109
                    ],
                    [
                        -0.7768255959165085,
                        0.5713546541537557,
                        0.26475621372463354,
                        -1.13367
                    ],
                    [
                        -0.6294911177659824,
                        -0.7158093928236606,
                        -0.3022546042646106,
                        0.321337
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "right",
                pcl: null
            },
            23681459000: {
                tl: {
                    K: [
                        [244.9300194561764, 0.0, 319.5121577632451],
                        [0.0, 244.9085434190387, 242.0281879542291],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07242848640096457,
                        0.180251632096771,
                        -0.1219743915109378,
                        0.02393593016860696
                    ],
                    E: [
                        [
                            0.6341761710963473,
                            0.11934201452529375,
                            -0.7639228151996909,
                            -0.110061
                        ],
                        [
                            0.7052423974655473,
                            -0.49428963287588357,
                            0.5082429730438693,
                            -0.00108387
                        ],
                        [
                            -0.3169443875991868,
                            -0.8610663403016452,
                            -0.39763175523203337,
                            0.00439455
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/23681459000_0.png"
                },
                bl: {
                    K: [
                        [244.1961118221905, 0.0, 318.9127640979312],
                        [0.0, 244.1920219795713, 240.420848303092],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07125261862251461,
                        0.1804347595497648,
                        -0.121838750093778,
                        0.02389344238641367
                    ],
                    E: [
                        [
                            0.19808789628111992,
                            0.9651877063418727,
                            -0.17080362078549563,
                            -0.0861027
                        ],
                        [
                            0.7369075057527189,
                            -0.2615472872436039,
                            -0.6233460872587704,
                            -0.0674486
                        ],
                        [
                            -0.646319203886305,
                            -0.0023891550864155175,
                            -0.7630634171716962,
                            -0.000947885
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/23681459000_1.png"
                },
                br: {
                    K: [
                        [244.8857731044274, 0.0, 319.3284329125421],
                        [0.0, 244.8855693505955, 239.8679190888073],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07460629414954237,
                        0.1845795707564548,
                        -0.1244992963641546,
                        0.0244355886085463
                    ],
                    E: [
                        [
                            -0.20877982378147736,
                            0.962576207466482,
                            0.1727947626556423,
                            0.00934055
                        ],
                        [
                            0.7303115883552828,
                            0.27096994028385507,
                            -0.627072783157225,
                            -0.0672867
                        ],
                        [
                            -0.6504275279350956,
                            -0.004726127591209717,
                            -0.7595536153703913,
                            -0.000323825
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/23681459000_2.png"
                },
                tr: {
                    K: [
                        [243.9401207278099, 0.0, 319.6090193880545],
                        [0.0, 243.9221438235569, 237.0273000983839],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07361957754210073,
                        0.1822761110924879,
                        -0.1230923351975567,
                        0.02419393433306516
                    ],
                    E: [
                        [
                            -0.6347645626109721,
                            0.11293383743450403,
                            0.7644082014314196,
                            0.0333112
                        ],
                        [
                            0.6984118965306659,
                            0.507103265152419,
                            0.5050416826918285,
                            -0.00163134
                        ],
                        [
                            -0.3305975995643936,
                            0.8544543444995012,
                            -0.4007655178881889,
                            0.00473993
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/121114456609530/frames/23681459000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.6949910543921097,
                        0.19098007336250175,
                        -0.6931912044258761,
                        -0.0190472
                    ],
                    [
                        0.18550326935834974,
                        0.8838167606228945,
                        0.4294839586054601,
                        -0.135468
                    ],
                    [
                        0.694676882710449,
                        -0.42707674394715817,
                        0.5788173143636828,
                        -0.314719
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.4634815749046655,
                        -0.693238832773145,
                        -0.5519100927317946,
                        0.248831
                    ],
                    [
                        -0.014286968654045218,
                        -0.6286138166199033,
                        0.7775863631013832,
                        -0.409693
                    ],
                    [
                        -0.8859913725598962,
                        -0.3525118299999098,
                        -0.3012552032074221,
                        -0.395622
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        0.016809373805553647,
                        -0.4015644661461345,
                        0.9156764846172666,
                        0.897109
                    ],
                    [
                        -0.7767750577453764,
                        0.5713857664570183,
                        0.264837337917056,
                        -1.13366
                    ],
                    [
                        -0.6295537742059311,
                        -0.715726404025376,
                        -0.3023206244436061,
                        0.321335
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "right",
                pcl: null
            }
        }
    }
];

export const TaskInputsForSequenceKeypoint2 = [
    {
        timestamps: {
            27514754000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/27514754000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/27514754000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/27514754000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/27514754000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.5143407074639018,
                        0.10492099496696655,
                        -0.8511434787746863,
                        -0.45679
                    ],
                    [
                        0.0833390131628367,
                        0.9816750582978755,
                        0.17137295236094677,
                        0.0246071
                    ],
                    [
                        0.8535269448181337,
                        -0.1590775431385731,
                        0.4961714318039863,
                        -0.16985
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7011590476984304,
                        -0.5573569417866819,
                        -0.44466754915647655,
                        -0.12096
                    ],
                    [
                        -0.02331515939755885,
                        -0.6412420400932812,
                        0.7669843866463473,
                        -0.307429
                    ],
                    [
                        -0.7126235985237205,
                        -0.5274105473530124,
                        -0.46260752411509626,
                        -0.42457
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15321681506409168,
                        -0.7633032778545886,
                        -0.62760872651522,
                        -0.517226
                    ],
                    [
                        0.034103084334802025,
                        -0.6388136528486146,
                        0.7686052930946174,
                        -0.303473
                    ],
                    [
                        -0.9876039627403633,
                        0.09635986171975375,
                        0.123907989366629,
                        -0.724747
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            33848024000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/33848024000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/33848024000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/33848024000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/33848024000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.963442246311106,
                        0.18174814371278492,
                        0.19684168836902216,
                        0.103832
                    ],
                    [
                        -0.21118324599720978,
                        0.9672938976410878,
                        0.1405138861337016,
                        -0.00313025
                    ],
                    [
                        -0.16486562599005394,
                        -0.17694668079189776,
                        0.9703139685295878,
                        -0.021575
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7011238415548551,
                        -0.5573685725989179,
                        -0.44470848101020866,
                        -0.120957
                    ],
                    [
                        -0.023286489564564417,
                        -0.6412457006889711,
                        0.7669821971543209,
                        -0.307429
                    ],
                    [
                        -0.712659173944405,
                        -0.5273938050707032,
                        -0.4625718064970271,
                        -0.424567
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15320264671627548,
                        -0.7633082933645374,
                        -0.6276060853115157,
                        -0.517228
                    ],
                    [
                        0.03411702562622848,
                        -0.6388105634511434,
                        0.7686072420850927,
                        -0.303471
                    ],
                    [
                        -0.9876056792068115,
                        0.09634061087698326,
                        0.12390927767646095,
                        -0.724742
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            69081005000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/69081005000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/69081005000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/69081005000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/69081005000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.8098646906371959,
                        0.14982405116717468,
                        -0.5671612967674847,
                        -0.249561
                    ],
                    [
                        -0.01614069495864509,
                        0.9721597100486506,
                        0.23376264912165837,
                        0.0884021
                    ],
                    [
                        0.5863946289192722,
                        -0.18016173802996968,
                        0.7897360871358543,
                        -0.112736
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.5204533789403187,
                        -0.42592696425174664,
                        -0.7400773618162471,
                        -0.142798
                    ],
                    [
                        0.5697205722367888,
                        -0.4723803166923958,
                        0.6725141678595162,
                        -0.199038
                    ],
                    [
                        -0.636039896484325,
                        -0.7716495691211672,
                        -0.003192578162871767,
                        -0.315343
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.1531966824265506,
                        -0.7632920548229393,
                        -0.627627290306654,
                        -0.517229
                    ],
                    [
                        0.0343256893577899,
                        -0.6388515013459533,
                        0.768563924653072,
                        -0.303472
                    ],
                    [
                        -0.987599374009325,
                        0.09619770409004275,
                        0.12407045653254309,
                        -0.724738
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            22048142000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/22048142000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/22048142000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/22048142000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/22048142000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.961246854514845,
                        0.19253556408155809,
                        -0.19731837534581692,
                        -0.0776198
                    ],
                    [
                        -0.04850144033298061,
                        0.822667777581701,
                        0.5664497665410512,
                        0.138169
                    ],
                    [
                        0.27138919464662326,
                        -0.5349278309198179,
                        0.8001250656844066,
                        -0.322826
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7011437179721607,
                        -0.5573685449562256,
                        -0.44467717710891724,
                        -0.120957
                    ],
                    [
                        -0.023322486887290272,
                        -0.641247017988504,
                        0.7669800020380221,
                        -0.307426
                    ],
                    [
                        -0.7126384415350939,
                        -0.5273922326070343,
                        -0.4626055389121505,
                        -0.42457
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15324504401138062,
                        -0.7633571034282682,
                        -0.6275363647881724,
                        -0.517228
                    ],
                    [
                        0.034069760144695704,
                        -0.6387411018625052,
                        0.7686670646223603,
                        -0.303469
                    ],
                    [
                        -0.9876007330544228,
                        0.09641440471774504,
                        0.12389130168534793,
                        -0.724741
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            72080975000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/72080975000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/72080975000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/72080975000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/72080975000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.8666023822376901,
                        0.10664399526538607,
                        -0.48747037794495446,
                        -0.271471
                    ],
                    [
                        -0.05689692217419129,
                        0.9916424256341674,
                        0.11579309102657592,
                        0.108026
                    ],
                    [
                        0.4957449458613415,
                        -0.0726110043741387,
                        0.8654274034814905,
                        -0.108263
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7495270447359507,
                        -0.5165082434911108,
                        -0.41403918125597683,
                        -0.0575046
                    ],
                    [
                        -0.0335645867276243,
                        -0.6543098558910713,
                        0.7554813240587815,
                        -0.241163
                    ],
                    [
                        -0.6611222487007952,
                        -0.5523566301670809,
                        -0.5077593183618307,
                        -0.479326
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15320178587277045,
                        -0.7633326902899918,
                        -0.6275766222064344,
                        -0.517229
                    ],
                    [
                        0.03440326144287431,
                        -0.6388086837697448,
                        0.768596045489736,
                        -0.303469
                    ],
                    [
                        -0.9875958831462835,
                        0.09615960417457248,
                        0.12412776529649741,
                        -0.724742
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            36248000000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/36248000000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/36248000000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/36248000000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/36248000000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.9943966802279873,
                        0.0814820504190053,
                        0.06734922279486573,
                        0.00531668
                    ],
                    [
                        -0.095600689983807,
                        0.9650655156123829,
                        0.24394478606935951,
                        0.0364333
                    ],
                    [
                        -0.04511929106466332,
                        -0.24901651759536,
                        0.9674476851688167,
                        -0.0350978
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7011335903006428,
                        -0.5573758682159207,
                        -0.4446839665230566,
                        -0.120955
                    ],
                    [
                        -0.0233152730825123,
                        -0.6412419755939583,
                        0.7669844371155419,
                        -0.307433
                    ],
                    [
                        -0.7126486417535769,
                        -0.5273906239846375,
                        -0.4625916591767257,
                        -0.424573
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15321510962812168,
                        -0.7633100558097137,
                        -0.627600899363134,
                        -0.51723
                    ],
                    [
                        0.034172857210479635,
                        -0.6388123619790013,
                        0.7686032669803595,
                        -0.30347
                    ],
                    [
                        -0.9876018155166154,
                        0.09631471789182322,
                        0.12396019161692046,
                        -0.724744
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            52947833000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/52947833000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/52947833000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/52947833000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/52947833000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.7577328469356428,
                        0.1616055389458734,
                        -0.6322377578544479,
                        -0.252526
                    ],
                    [
                        -0.048279021040025294,
                        0.9800782444768703,
                        0.19265453752910341,
                        0.0976819
                    ],
                    [
                        0.6507765121777388,
                        -0.11545685118324386,
                        0.7504396356220984,
                        -0.066396
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.6263700564818018,
                        -0.6121999789242278,
                        -0.4825678585941664,
                        -0.0903541
                    ],
                    [
                        -0.05214153456178583,
                        -0.6505705221463265,
                        0.7576537837876863,
                        -0.249842
                    ],
                    [
                        -0.7777800542033246,
                        -0.4494098146674491,
                        -0.43941894106199186,
                        -0.338374
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15321461941589898,
                        -0.7633080505963663,
                        -0.6276034578394354,
                        -0.517226
                    ],
                    [
                        0.03433494514098706,
                        -0.6388306749887867,
                        0.7685808221882304,
                        -0.303471
                    ],
                    [
                        -0.9875962697071139,
                        0.09620908786671772,
                        0.12408633878250908,
                        -0.724739
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            }
        },
        recording_id: "128157989133717"
    }
];

export const TaskInputsForSequenceOrientation2 = [
    {
        // this box is relative to the selected trackable
        box: {
            position: {
                // world coords
                // x: -0.13407524825668968,
                // y: -0.1812384258636629,
                // z: -0.43915076828355465
                // relative to selected trackable
                // x: 0.013118248256689682,
                // y: -0.12618757413633708,
                // z: 0.014580768283554646
                x: 0,
                y: 0,
                z: 0
            },
            dimensions: { width: 0.25, length: 0.25, height: 0.25 },
            rotation: { x: 0, y: 0, z: 0 }
        },
        recording_id: "128157989133717",
        timestamps: {
            27514754000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/27514754000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/27514754000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/27514754000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/27514754000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.5143407074639018,
                        0.10492099496696655,
                        -0.8511434787746863,
                        -0.45679
                    ],
                    [
                        0.0833390131628367,
                        0.9816750582978755,
                        0.17137295236094677,
                        0.0246071
                    ],
                    [
                        0.8535269448181337,
                        -0.1590775431385731,
                        0.4961714318039863,
                        -0.16985
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7011590476984304,
                        -0.5573569417866819,
                        -0.44466754915647655,
                        -0.12096
                    ],
                    [
                        -0.02331515939755885,
                        -0.6412420400932812,
                        0.7669843866463473,
                        -0.307429
                    ],
                    [
                        -0.7126235985237205,
                        -0.5274105473530124,
                        -0.46260752411509626,
                        -0.42457
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15321681506409168,
                        -0.7633032778545886,
                        -0.62760872651522,
                        -0.517226
                    ],
                    [
                        0.034103084334802025,
                        -0.6388136528486146,
                        0.7686052930946174,
                        -0.303473
                    ],
                    [
                        -0.9876039627403633,
                        0.09635986171975375,
                        0.123907989366629,
                        -0.724747
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            33848024000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/33848024000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/33848024000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/33848024000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/33848024000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.963442246311106,
                        0.18174814371278492,
                        0.19684168836902216,
                        0.103832
                    ],
                    [
                        -0.21118324599720978,
                        0.9672938976410878,
                        0.1405138861337016,
                        -0.00313025
                    ],
                    [
                        -0.16486562599005394,
                        -0.17694668079189776,
                        0.9703139685295878,
                        -0.021575
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7011238415548551,
                        -0.5573685725989179,
                        -0.44470848101020866,
                        -0.120957
                    ],
                    [
                        -0.023286489564564417,
                        -0.6412457006889711,
                        0.7669821971543209,
                        -0.307429
                    ],
                    [
                        -0.712659173944405,
                        -0.5273938050707032,
                        -0.4625718064970271,
                        -0.424567
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15320264671627548,
                        -0.7633082933645374,
                        -0.6276060853115157,
                        -0.517228
                    ],
                    [
                        0.03411702562622848,
                        -0.6388105634511434,
                        0.7686072420850927,
                        -0.303471
                    ],
                    [
                        -0.9876056792068115,
                        0.09634061087698326,
                        0.12390927767646095,
                        -0.724742
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            69081005000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/69081005000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/69081005000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/69081005000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/69081005000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.8098646906371959,
                        0.14982405116717468,
                        -0.5671612967674847,
                        -0.249561
                    ],
                    [
                        -0.01614069495864509,
                        0.9721597100486506,
                        0.23376264912165837,
                        0.0884021
                    ],
                    [
                        0.5863946289192722,
                        -0.18016173802996968,
                        0.7897360871358543,
                        -0.112736
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.5204533789403187,
                        -0.42592696425174664,
                        -0.7400773618162471,
                        -0.142798
                    ],
                    [
                        0.5697205722367888,
                        -0.4723803166923958,
                        0.6725141678595162,
                        -0.199038
                    ],
                    [
                        -0.636039896484325,
                        -0.7716495691211672,
                        -0.003192578162871767,
                        -0.315343
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.1531966824265506,
                        -0.7632920548229393,
                        -0.627627290306654,
                        -0.517229
                    ],
                    [
                        0.0343256893577899,
                        -0.6388515013459533,
                        0.768563924653072,
                        -0.303472
                    ],
                    [
                        -0.987599374009325,
                        0.09619770409004275,
                        0.12407045653254309,
                        -0.724738
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            22048142000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/22048142000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/22048142000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/22048142000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/22048142000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.961246854514845,
                        0.19253556408155809,
                        -0.19731837534581692,
                        -0.0776198
                    ],
                    [
                        -0.04850144033298061,
                        0.822667777581701,
                        0.5664497665410512,
                        0.138169
                    ],
                    [
                        0.27138919464662326,
                        -0.5349278309198179,
                        0.8001250656844066,
                        -0.322826
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7011437179721607,
                        -0.5573685449562256,
                        -0.44467717710891724,
                        -0.120957
                    ],
                    [
                        -0.023322486887290272,
                        -0.641247017988504,
                        0.7669800020380221,
                        -0.307426
                    ],
                    [
                        -0.7126384415350939,
                        -0.5273922326070343,
                        -0.4626055389121505,
                        -0.42457
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15324504401138062,
                        -0.7633571034282682,
                        -0.6275363647881724,
                        -0.517228
                    ],
                    [
                        0.034069760144695704,
                        -0.6387411018625052,
                        0.7686670646223603,
                        -0.303469
                    ],
                    [
                        -0.9876007330544228,
                        0.09641440471774504,
                        0.12389130168534793,
                        -0.724741
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            72080975000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/72080975000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/72080975000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/72080975000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/72080975000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.8666023822376901,
                        0.10664399526538607,
                        -0.48747037794495446,
                        -0.271471
                    ],
                    [
                        -0.05689692217419129,
                        0.9916424256341674,
                        0.11579309102657592,
                        0.108026
                    ],
                    [
                        0.4957449458613415,
                        -0.0726110043741387,
                        0.8654274034814905,
                        -0.108263
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7495270447359507,
                        -0.5165082434911108,
                        -0.41403918125597683,
                        -0.0575046
                    ],
                    [
                        -0.0335645867276243,
                        -0.6543098558910713,
                        0.7554813240587815,
                        -0.241163
                    ],
                    [
                        -0.6611222487007952,
                        -0.5523566301670809,
                        -0.5077593183618307,
                        -0.479326
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15320178587277045,
                        -0.7633326902899918,
                        -0.6275766222064344,
                        -0.517229
                    ],
                    [
                        0.03440326144287431,
                        -0.6388086837697448,
                        0.768596045489736,
                        -0.303469
                    ],
                    [
                        -0.9875958831462835,
                        0.09615960417457248,
                        0.12412776529649741,
                        -0.724742
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            36248000000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/36248000000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/36248000000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/36248000000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/36248000000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.9943966802279873,
                        0.0814820504190053,
                        0.06734922279486573,
                        0.00531668
                    ],
                    [
                        -0.095600689983807,
                        0.9650655156123829,
                        0.24394478606935951,
                        0.0364333
                    ],
                    [
                        -0.04511929106466332,
                        -0.24901651759536,
                        0.9674476851688167,
                        -0.0350978
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7011335903006428,
                        -0.5573758682159207,
                        -0.4446839665230566,
                        -0.120955
                    ],
                    [
                        -0.0233152730825123,
                        -0.6412419755939583,
                        0.7669844371155419,
                        -0.307433
                    ],
                    [
                        -0.7126486417535769,
                        -0.5273906239846375,
                        -0.4625916591767257,
                        -0.424573
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15321510962812168,
                        -0.7633100558097137,
                        -0.627600899363134,
                        -0.51723
                    ],
                    [
                        0.034172857210479635,
                        -0.6388123619790013,
                        0.7686032669803595,
                        -0.30347
                    ],
                    [
                        -0.9876018155166154,
                        0.09631471789182322,
                        0.12396019161692046,
                        -0.724744
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            52947833000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/52947833000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/52947833000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/52947833000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/52947833000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.7577328469356428,
                        0.1616055389458734,
                        -0.6322377578544479,
                        -0.252526
                    ],
                    [
                        -0.048279021040025294,
                        0.9800782444768703,
                        0.19265453752910341,
                        0.0976819
                    ],
                    [
                        0.6507765121777388,
                        -0.11545685118324386,
                        0.7504396356220984,
                        -0.066396
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.6263700564818018,
                        -0.6121999789242278,
                        -0.4825678585941664,
                        -0.0903541
                    ],
                    [
                        -0.05214153456178583,
                        -0.6505705221463265,
                        0.7576537837876863,
                        -0.249842
                    ],
                    [
                        -0.7777800542033246,
                        -0.4494098146674491,
                        -0.43941894106199186,
                        -0.338374
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15321461941589898,
                        -0.7633080505963663,
                        -0.6276034578394354,
                        -0.517226
                    ],
                    [
                        0.03433494514098706,
                        -0.6388306749887867,
                        0.7685808221882304,
                        -0.303471
                    ],
                    [
                        -0.9875962697071139,
                        0.09620908786671772,
                        0.12408633878250908,
                        -0.724739
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            }
        }
    }
];

export const TaskInputsForSequenceBBoxFaceAdjustment = [
    {
        // this box is relative to the selected trackable
        box: {
            position: {
                // world coords
                // x: -0.13407524825668968,
                // y: -0.1812384258636629,
                // z: -0.43915076828355465
                // relative to selected trackable
                // x: 0.013118248256689682,
                // y: -0.12618757413633708,
                // z: 0.014580768283554646
                x: 0,
                y: 0,
                z: 0
            },
            dimensions: { width: 0.1, length: 0.1, height: 0.1 },
            // rotation: { x: 0, y: 0, z: Math.PI / 2 }
            rotation: { x: 0, y: 0, z: 0 }
        },
        recording_id: "128157989133717",
        timestamps: {
            27514754000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/27514754000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/27514754000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/27514754000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/27514754000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.5143407074639018,
                        0.10492099496696655,
                        -0.8511434787746863,
                        -0.45679
                    ],
                    [
                        0.0833390131628367,
                        0.9816750582978755,
                        0.17137295236094677,
                        0.0246071
                    ],
                    [
                        0.8535269448181337,
                        -0.1590775431385731,
                        0.4961714318039863,
                        -0.16985
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7011590476984304,
                        -0.5573569417866819,
                        -0.44466754915647655,
                        -0.12096
                    ],
                    [
                        -0.02331515939755885,
                        -0.6412420400932812,
                        0.7669843866463473,
                        -0.307429
                    ],
                    [
                        -0.7126235985237205,
                        -0.5274105473530124,
                        -0.46260752411509626,
                        -0.42457
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15321681506409168,
                        -0.7633032778545886,
                        -0.62760872651522,
                        -0.517226
                    ],
                    [
                        0.034103084334802025,
                        -0.6388136528486146,
                        0.7686052930946174,
                        -0.303473
                    ],
                    [
                        -0.9876039627403633,
                        0.09635986171975375,
                        0.123907989366629,
                        -0.724747
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            33848024000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/33848024000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/33848024000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/33848024000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/33848024000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.963442246311106,
                        0.18174814371278492,
                        0.19684168836902216,
                        0.103832
                    ],
                    [
                        -0.21118324599720978,
                        0.9672938976410878,
                        0.1405138861337016,
                        -0.00313025
                    ],
                    [
                        -0.16486562599005394,
                        -0.17694668079189776,
                        0.9703139685295878,
                        -0.021575
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7011238415548551,
                        -0.5573685725989179,
                        -0.44470848101020866,
                        -0.120957
                    ],
                    [
                        -0.023286489564564417,
                        -0.6412457006889711,
                        0.7669821971543209,
                        -0.307429
                    ],
                    [
                        -0.712659173944405,
                        -0.5273938050707032,
                        -0.4625718064970271,
                        -0.424567
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15320264671627548,
                        -0.7633082933645374,
                        -0.6276060853115157,
                        -0.517228
                    ],
                    [
                        0.03411702562622848,
                        -0.6388105634511434,
                        0.7686072420850927,
                        -0.303471
                    ],
                    [
                        -0.9876056792068115,
                        0.09634061087698326,
                        0.12390927767646095,
                        -0.724742
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            69081005000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/69081005000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/69081005000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/69081005000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/69081005000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.8098646906371959,
                        0.14982405116717468,
                        -0.5671612967674847,
                        -0.249561
                    ],
                    [
                        -0.01614069495864509,
                        0.9721597100486506,
                        0.23376264912165837,
                        0.0884021
                    ],
                    [
                        0.5863946289192722,
                        -0.18016173802996968,
                        0.7897360871358543,
                        -0.112736
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.5204533789403187,
                        -0.42592696425174664,
                        -0.7400773618162471,
                        -0.142798
                    ],
                    [
                        0.5697205722367888,
                        -0.4723803166923958,
                        0.6725141678595162,
                        -0.199038
                    ],
                    [
                        -0.636039896484325,
                        -0.7716495691211672,
                        -0.003192578162871767,
                        -0.315343
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.1531966824265506,
                        -0.7632920548229393,
                        -0.627627290306654,
                        -0.517229
                    ],
                    [
                        0.0343256893577899,
                        -0.6388515013459533,
                        0.768563924653072,
                        -0.303472
                    ],
                    [
                        -0.987599374009325,
                        0.09619770409004275,
                        0.12407045653254309,
                        -0.724738
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            22048142000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/22048142000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/22048142000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/22048142000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/22048142000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.961246854514845,
                        0.19253556408155809,
                        -0.19731837534581692,
                        -0.0776198
                    ],
                    [
                        -0.04850144033298061,
                        0.822667777581701,
                        0.5664497665410512,
                        0.138169
                    ],
                    [
                        0.27138919464662326,
                        -0.5349278309198179,
                        0.8001250656844066,
                        -0.322826
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7011437179721607,
                        -0.5573685449562256,
                        -0.44467717710891724,
                        -0.120957
                    ],
                    [
                        -0.023322486887290272,
                        -0.641247017988504,
                        0.7669800020380221,
                        -0.307426
                    ],
                    [
                        -0.7126384415350939,
                        -0.5273922326070343,
                        -0.4626055389121505,
                        -0.42457
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15324504401138062,
                        -0.7633571034282682,
                        -0.6275363647881724,
                        -0.517228
                    ],
                    [
                        0.034069760144695704,
                        -0.6387411018625052,
                        0.7686670646223603,
                        -0.303469
                    ],
                    [
                        -0.9876007330544228,
                        0.09641440471774504,
                        0.12389130168534793,
                        -0.724741
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            72080975000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/72080975000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/72080975000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/72080975000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/72080975000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.8666023822376901,
                        0.10664399526538607,
                        -0.48747037794495446,
                        -0.271471
                    ],
                    [
                        -0.05689692217419129,
                        0.9916424256341674,
                        0.11579309102657592,
                        0.108026
                    ],
                    [
                        0.4957449458613415,
                        -0.0726110043741387,
                        0.8654274034814905,
                        -0.108263
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7495270447359507,
                        -0.5165082434911108,
                        -0.41403918125597683,
                        -0.0575046
                    ],
                    [
                        -0.0335645867276243,
                        -0.6543098558910713,
                        0.7554813240587815,
                        -0.241163
                    ],
                    [
                        -0.6611222487007952,
                        -0.5523566301670809,
                        -0.5077593183618307,
                        -0.479326
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15320178587277045,
                        -0.7633326902899918,
                        -0.6275766222064344,
                        -0.517229
                    ],
                    [
                        0.03440326144287431,
                        -0.6388086837697448,
                        0.768596045489736,
                        -0.303469
                    ],
                    [
                        -0.9875958831462835,
                        0.09615960417457248,
                        0.12412776529649741,
                        -0.724742
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            36248000000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/36248000000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/36248000000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/36248000000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/36248000000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.9943966802279873,
                        0.0814820504190053,
                        0.06734922279486573,
                        0.00531668
                    ],
                    [
                        -0.095600689983807,
                        0.9650655156123829,
                        0.24394478606935951,
                        0.0364333
                    ],
                    [
                        -0.04511929106466332,
                        -0.24901651759536,
                        0.9674476851688167,
                        -0.0350978
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.7011335903006428,
                        -0.5573758682159207,
                        -0.4446839665230566,
                        -0.120955
                    ],
                    [
                        -0.0233152730825123,
                        -0.6412419755939583,
                        0.7669844371155419,
                        -0.307433
                    ],
                    [
                        -0.7126486417535769,
                        -0.5273906239846375,
                        -0.4625916591767257,
                        -0.424573
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15321510962812168,
                        -0.7633100558097137,
                        -0.627600899363134,
                        -0.51723
                    ],
                    [
                        0.034172857210479635,
                        -0.6388123619790013,
                        0.7686032669803595,
                        -0.30347
                    ],
                    [
                        -0.9876018155166154,
                        0.09631471789182322,
                        0.12396019161692046,
                        -0.724744
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            },
            52947833000: {
                tl: {
                    K: [
                        [244.0788615983771, 0.0, 320.5369990806678],
                        [0.0, 244.0686898724674, 241.3590001831418],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07202081665111447,
                        0.1800621723117508,
                        -0.1207721125516862,
                        0.02351091176495826
                    ],
                    E: [
                        [
                            0.6339628346138673,
                            0.13045861372076706,
                            -0.7622805746143649,
                            -0.109797
                        ],
                        [
                            0.7097760816012728,
                            -0.48955306088740375,
                            0.506513291595115,
                            -0.00104031
                        ],
                        [
                            -0.3070977667048297,
                            -0.86215912143979,
                            -0.4029548498319338,
                            0.00422974
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/52947833000_0.png"
                },
                bl: {
                    K: [
                        [244.0108138818068, 0.0, 321.3957962202377],
                        [0.0, 243.9940614150138, 241.9400842890371],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.07940825994075977,
                        0.1929374085998266,
                        -0.1300542246564828,
                        0.02568870175383954
                    ],
                    E: [
                        [
                            0.20171986233284755,
                            0.9641787239133524,
                            -0.17224542227077183,
                            -0.0861685
                        ],
                        [
                            0.7367344743138786,
                            -0.2652495737606989,
                            -0.621984708796945,
                            -0.0673141
                        ],
                        [
                            -0.6453924476610102,
                            -0.0014324707979730955,
                            -0.7638498128097847,
                            -0.00114628
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/52947833000_1.png"
                },
                br: {
                    K: [
                        [244.8624208208541, 0.0, 321.5839103570886],
                        [0.0, 244.8343880636953, 240.4972851593755],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06989016009086384,
                        0.1790530722881813,
                        -0.1217480435036743,
                        0.02399377763829464
                    ],
                    E: [
                        [
                            -0.21666757079064447,
                            0.9614522852719438,
                            0.1693064290363428,
                            0.00926378
                        ],
                        [
                            0.7287054232128094,
                            0.2746854395288659,
                            -0.6273247289012878,
                            -0.0675618
                        ],
                        [
                            -0.6496488050846563,
                            -0.012546412084363856,
                            -0.7601309213522945,
                            -0.000752226
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/52947833000_2.png"
                },
                tr: {
                    K: [
                        [244.4403698338929, 0.0, 319.021769919017],
                        [0.0, 244.4422325184629, 238.6202621026204],
                        [0.0, 0.0, 1.0]
                    ],
                    distortion_coeffs: [
                        -0.06890516623980088,
                        0.1763736760827882,
                        -0.1190091360833066,
                        0.02321443047187005
                    ],
                    E: [
                        [
                            -0.6335541226691856,
                            0.11998850618997225,
                            0.7643375772727505,
                            0.033501
                        ],
                        [
                            0.70318197087083,
                            0.5013583937387129,
                            0.5041575913044978,
                            -0.00147449
                        ],
                        [
                            -0.3227139437506444,
                            0.8568795244432188,
                            -0.4020114315524741,
                            0.00403197
                        ],
                        [0.0, 0.0, 0.0, 1.0]
                    ],
                    image_url:
                        "https://qm-coconut-dataset-extern.s3.eu-central-1.amazonaws.com/2021-05-31_test/128157989133717/frames/52947833000_3.png"
                },
                hmd_world_pose: [
                    [
                        0.7577328469356428,
                        0.1616055389458734,
                        -0.6322377578544479,
                        -0.252526
                    ],
                    [
                        -0.048279021040025294,
                        0.9800782444768703,
                        0.19265453752910341,
                        0.0976819
                    ],
                    [
                        0.6507765121777388,
                        -0.11545685118324386,
                        0.7504396356220984,
                        -0.066396
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                left_trackable_world_pose: [
                    [
                        0.6263700564818018,
                        -0.6121999789242278,
                        -0.4825678585941664,
                        -0.0903541
                    ],
                    [
                        -0.05214153456178583,
                        -0.6505705221463265,
                        0.7576537837876863,
                        -0.249842
                    ],
                    [
                        -0.7777800542033246,
                        -0.4494098146674491,
                        -0.43941894106199186,
                        -0.338374
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                right_trackable_world_pose: [
                    [
                        -0.15321461941589898,
                        -0.7633080505963663,
                        -0.6276034578394354,
                        -0.517226
                    ],
                    [
                        0.03433494514098706,
                        -0.6388306749887867,
                        0.7685808221882304,
                        -0.303471
                    ],
                    [
                        -0.9875962697071139,
                        0.09620908786671772,
                        0.12408633878250908,
                        -0.724739
                    ],
                    [0.0, 0.0, 0.0, 1.0]
                ],
                selected_trackable: "left",
                pcl: null
            }
        }
    }
];
