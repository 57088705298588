import React from "react";
import ReactHtmlParser from "react-html-parser";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const Question = ({ taskUIContext, guiSettings, currentTaskIdx }) => {
    const makeProgressInfo = () => {
        const taskInputs = taskUIContext.getTaskInputs();
        if (taskInputs && taskInputs.length > 0) {
            return `(${currentTaskIdx + 1}/${taskInputs.length})`;
        }
        return null;
    };
    return (
        <h1 id="question">
            {ReactHtmlParser(guiSettings.question.text)}
            {makeProgressInfo()}
        </h1>
    );
};
