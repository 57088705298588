import React, { useEffect, useState } from "react";

import CameraViewZoom from "../CameraViewZoom";
import CanvasScene from "../../../lib/NRL/CanvasScene";
import { LayeredScene } from "../../../lib/NRL/CanvasScene";
import { matMultMat, Pose3D } from "../../../lib/NRL/Pose3D";
import { makeInterpolatedLine } from "../../../lib/NRL/RenderableFactory";
import { RENDERERS } from "../../../lib/NRL/Renderer";

/**
 * @param {Object} props
 * @param {String} props.name
 * @param {Number[][]} props.E
 * @param {Number[][]} props.K
 * @param {Number[]} props.distortionCoeffs
 * @param {HTMLImageElement} props.image
 * @param {Number[][]} props.hmdWorldPose
 * @param {Object} props.boxUI object returned by BBox3DModel::makeUITypes()
 * @param {Pose3D} props.trackablePose pose of the trackable that should be attached
 * @param {Number} props.coordinateSystemLineWidth
 * @param {Boolean} props.withZoom
 * @param {Number} props.zoomLevel
 */
export const OrientationAdjustmentCameraView = ({
    name,
    E,
    K,
    distortionCoeffs,
    image,
    hmdWorldPose,
    boxUI,
    trackablePose = null,
    coordinateSystemLineWidth,
    withZoom,
    zoomLevel,
    disabledCoordinateAxes,
    disabledCoordinateAxisColor
}) => {
    const [sceneMap, setSceneMap] = useState(null);
    // eslint-disable-next-line
    const [originalCanvasDimensions, setOriginalCanvasDimensions] = useState({
        width: image.width,
        height: image.height
    });
    // eslint-disable-next-line
    const [resizedCanvasDimensions, setResizedCanvasDimensions] = useState(
        null
    );
    const [zoomTranslation, setZoomTranslation] = useState(null);

    const makeCoordinateSystemRenderables = () => {
        // when no trackable is given we expect boxPose to be in the world coordinate system
        let boxPose = new Pose3D(boxUI.position, boxUI.rotation);
        if (trackablePose) {
            // we expect that the boxPose is meant to be in the coordinate system of the trackable
            boxPose = trackablePose.applyLocalTransformation(boxPose);
        }

        const x = makeInterpolatedLine(boxPose, [0, 0, 0], [0.5, 0, 0], 100, {
            lineWidth: coordinateSystemLineWidth,
            strokeStyle: disabledCoordinateAxes.includes("x")
                ? disabledCoordinateAxisColor
                : "#F00"
        });
        const y = makeInterpolatedLine(boxPose, [0, 0, 0], [0, 0.5, 0], 100, {
            lineWidth: coordinateSystemLineWidth,
            strokeStyle: disabledCoordinateAxes.includes("y")
                ? disabledCoordinateAxisColor
                : "#0F0"
        });
        const z = makeInterpolatedLine(boxPose, [0, 0, 0], [0, 0, 0.5], 100, {
            lineWidth: coordinateSystemLineWidth,
            strokeStyle: disabledCoordinateAxes.includes("z")
                ? disabledCoordinateAxisColor
                : "#00F"
        });

        // // test area
        // // recording_id: 128157989133717
        // // timestamp: 69081005000
        // // trackablePose contains the homogeneous transformation matrix for the left trackable

        // // literally the left trackable position from taskInput
        // const trackablePositionWorld = [-0.142798, -0.199038, -0.315343];
        // const trackablePositionTrackable = trackablePose.applyInverseTo(
        //     trackablePositionWorld
        // );
        // console.log({ trackablePositionWorld, trackablePositionTrackable });
        // // expected: [-0.142798, -0.199038, -0.315343] [zero-like, zero-like, zero-like]

        // // taken from taskOutput of keypoint UI for this timestamp
        // const keypointPositionWorld = [
        //     -0.20243419533389195, -0.08416675324582353, -0.2359197586471563
        // ];
        // const keypointPositionTrackable = trackablePose.applyInverseTo(
        //     keypointPositionWorld
        // );
        // console.log({ keypointPositionWorld, keypointPositionTrackable });
        // // expected: [-0.20243419533389195, -0.08416675324582353, -0.2359197586471563] [-0.016109697142838345, -0.09014916225062769, 0.12113437412736767]
        // // test area

        return { x, y, z };
    };

    // recreate the box scene object on every react update of the boxUI prop
    // and update the actionIndicator
    useEffect(() => {
        if (!sceneMap) {
            return;
        }
        const scene = sceneMap.get("coordinate_system");
        for (const objectName of ["x_axis", "y_axis", "z_axis"]) {
            if (scene.objects.has(objectName)) {
                scene.deleteObject(objectName);
            }
        }

        const { x, y, z } = makeCoordinateSystemRenderables();
        scene.addObject(x, "x_axis");
        scene.addObject(y, "y_axis");
        scene.addObject(z, "z_axis");
        scene.render(true);

        // update zoom to box center
        let canvasBoxCenter = scene.renderer.worldPointToCanvas(
            scene.cameraPoseWorld,
            boxUI.position
        );
        if (!canvasBoxCenter) {
            setZoomTranslation(null);
            return;
        }
        canvasBoxCenter = { x: canvasBoxCenter[0], y: canvasBoxCenter[1] };
        // top right camera view is rotated by 180°
        if (name === "top_right") {
            canvasBoxCenter.x =
                resizedCanvasDimensions.width - canvasBoxCenter.x;
            canvasBoxCenter.y =
                resizedCanvasDimensions.height - canvasBoxCenter.y;
        }
        const translation = calculateTranslation(canvasBoxCenter);
        setZoomTranslation(translation);

        // eslint-disable-next-line
    }, [sceneMap, boxUI]);

    /**
     * Calculate translation coordinates for zoom on resized canvas for projectedPoint.
     */
    const calculateTranslation = projectedPoint => {
        const translation = {
            x: resizedCanvasDimensions.width / 2 - projectedPoint.x,
            y: resizedCanvasDimensions.height / 2 - projectedPoint.y
        };

        // max-translation to ensure that the translation does not push the image out of frame
        const maxXtranslation =
            ((zoomLevel - 1) / zoomLevel) * (resizedCanvasDimensions.width / 2);
        const maxYtranslation =
            ((zoomLevel - 1) / zoomLevel) *
            (resizedCanvasDimensions.height / 2);

        if (Math.abs(translation.x) > maxXtranslation) {
            translation.x = Math.sign(translation.x) * maxXtranslation;
        }
        if (Math.abs(translation.y) > maxYtranslation) {
            translation.y = Math.sign(translation.y) * maxYtranslation;
        }
        return translation;
    };

    const layeredScene = (
        <LayeredScene
            style={{
                transform: name === "top_right" ? "rotate(180deg)" : "none",
                margin: "auto"
            }}
            setSceneMap={sceneMapFromLayeredScene => {
                // TODO: this throws a warning because the render func of LayeredScene calls this function
                //       and this function updates the state of CanvasScene
                setSceneMap(sceneMapFromLayeredScene);
            }}
        >
            <CanvasScene
                name="bg_image"
                width={image.width}
                height={image.height}
                renderer={RENDERERS.FISHEYE_CANVAS}
                rendererSettings={{
                    distortionCoeffs: distortionCoeffs,
                    K: K
                }}
                init={scene => {
                    /**
                     * @type {CanvasRenderingContext2D}
                     */
                    const ctx = scene.renderer.ctx;
                    setResizedCanvasDimensions({
                        width: ctx.canvas.offsetWidth,
                        height: ctx.canvas.offsetHeight
                    });
                    scene.cameraPoseWorld = Pose3D.makePoseFromHtm(
                        matMultMat(hmdWorldPose, E)
                    );

                    // draw image scaled to the canvas
                    ctx.drawImage(
                        image,
                        0,
                        0,
                        image.naturalWidth,
                        image.naturalHeight,
                        0,
                        0,
                        ctx.width,
                        ctx.height
                    );
                }}
                canvasProps={{
                    style: { display: "block" },
                    className:
                        "canvas_scene orientation_adjustment_canvas_scene"
                }}
            />
            <CanvasScene
                name="coordinate_system"
                width={image.width}
                height={image.height}
                renderer={RENDERERS.FISHEYE_CANVAS}
                rendererSettings={{
                    distortionCoeffs: distortionCoeffs,
                    K: K
                }}
                init={scene => {
                    scene.cameraPoseWorld = Pose3D.makePoseFromHtm(
                        matMultMat(hmdWorldPose, E)
                    );
                }}
                canvasProps={{
                    style: { display: "block" },
                    className:
                        "canvas_scene orientation_adjustment_canvas_scene"
                }}
            />
        </LayeredScene>
    );

    if (withZoom && zoomTranslation) {
        return (
            <CameraViewZoom
                zoomParameters={{
                    scale: zoomLevel,
                    translation: zoomTranslation
                }}
            >
                {layeredScene}
            </CameraViewZoom>
        );
    }
    return layeredScene;
};
export default OrientationAdjustmentCameraView;
