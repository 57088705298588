import React, { useState, useEffect } from "react";
import { add, subtract } from "mathjs";
import PubSub from "pubsub-js";
import {
    BsArrowRepeat,
    BsArrowCounterclockwise,
    BsArrowsMove,
    BsArrowsExpand
} from "react-icons/bs";

import { LayeredScene } from "../../lib/NRL/CanvasScene";
import CanvasScene from "../../lib/NRL/CanvasScene";
import { Pose3D } from "../../lib/NRL/Pose3D";
import { PointcloudRenderable } from "../../lib/NRL/Renderable";
import { ColorMap, COLORMAPS, getColorMap } from "../../lib/NRL/ColorMap";
import { transformPoint2DFromDimensions } from "../../lib/qm_cs_lib";
import { BoxDimensions } from "../../lib/NRL/RenderableFactory";
import { AnnotationContext } from "./AnnotationContext";
import CustomMouseCursor from "../../components/CustomMouseCursor/CustomMouseCursor";

/**
 * How to initialize to camera of the scenes.
 */
export const TRANSFORM_FIT = {
    FIT_ALL_POINTS: "FIT_ALL_POINTS",
    ZOOM_TO_INITIAL_COG: "ZOOM_TO_INITIAL_COG",
    ZOOM_TO_ORIGIN: "ZOOM_TO_ORIGIN" // default
};

const HOVERED_ACTION = {
    FLIP_ORIENTATION: "FLIP_ORIENTATION",
    MOVE: "MOVE",
    ROTATE: "ROTATE",
    RESIZE_FRONT: "RESIZE_FRONT",
    RESIZE_BACK: "RESIZE_BACK",
    RESIZE_LEFT: "RESIZE_LEFT",
    RESIZE_RIGHT: "RESIZE_RIGHT",
    RESIZE_FRONT_LEFT: "RESIZE_FRONT_LEFT",
    RESIZE_FRONT_RIGHT: "RESIZE_FRONT_RIGHT",
    NONE: "NONE"
};

const META_ACTION = {
    MOVE_CAMERA: "MOVE_CAMERA",
    NONE: "NONE"
};

/**
 * Shared data between scenes
 */
const shared = {
    cam: new Pose3D([0, 0, 0], [0, 0, 0], 1),
    sceneMap: null,
    originalCanvasDimensions: null,
    resizedCanvasDimensions: null,
    selectedBoxDims: [], // keep track of the lines grabbed for adjusting the box
    lastClickWorldPos: null,
    lastMouseMoveWorldPos: null
};

/**
 * Setup camera, create pointcloud scene object and shared camera.
 *
 * @param {import("../../lib/NRL/Scene3D").Scene3D} scene
 * @param {Number[][]} points
 * @param {String} transformFit
 * @param {Number} initialZoom
 * @param {Number[]} initialCogPos
 * @param {String} bgColor
 * @param {Number} pointRadius
 * @param {String} colormap
 * @param {Number} pointOpacity
 */
const initPointcloudScene = (
    scene,
    points,
    transformFit,
    initialZoom,
    initialCogPos,
    bgColor,
    pointRadius,
    colormap,
    pointOpacity
) => {
    const ctx = scene.renderer.ctx;
    shared.originalCanvasDimensions = {
        width: ctx.canvas.width,
        height: ctx.canvas.height
    };
    shared.resizedCanvasDimensions = {
        width: ctx.canvas.offsetWidth,
        height: ctx.canvas.offsetHeight
    };

    if (transformFit === TRANSFORM_FIT.FIT_ALL_POINTS) {
        shared.cam = scene.renderer.makeCameraCenterPoseForPointsXY(
            points,
            ctx.width,
            ctx.height
        );
    } else if (transformFit === TRANSFORM_FIT.ZOOM_TO_ORIGIN) {
        // cam is already at origin by default
        shared.cam.setZoom(initialZoom);
    } else if (transformFit === TRANSFORM_FIT.ZOOM_TO_INITIAL_COG) {
        shared.cam.setZoom(initialZoom);
        shared.cam.setPosition(initialCogPos);
    }
    // shared.cam = new Pose3D([0, 0, 0], [Math.PI / 2, 0, Math.PI / 2], 40);
    scene.cameraPoseWorld = shared.cam;

    scene.renderer.settings.bgClearFunc = ctx => {
        ctx.clearRect(0, 0, ctx.width, ctx.height);
        ctx.save();
        ctx.fillStyle = bgColor;
        ctx.fillRect(0, 0, ctx.width, ctx.height);
        ctx.restore();
    };

    if (!Object.values(COLORMAPS).includes(colormap)) {
        colormap = COLORMAPS.TURBO_LIGHT;
    }
    const initPointOpacity = Math.min(scene.cameraPoseWorld.zoom * 0.005, 1.0);
    scene.addObject(
        new PointcloudRenderable(new Pose3D(), points, {
            radius: pointRadius,
            useColorMap: true,
            colorMap: new ColorMap(
                "z",
                getColorMap(colormap),
                initPointOpacity,
                true,
                true,
                [0.05, 0.95]
            )
        }),
        "pointcloud"
    );
    scene.render(true);
};

/**
 * @param {Object} props
 * @param {(cogWorldPos: Number[]) => {}} props.setCogPos
 * @param {(boxPoseWorld: Pose3D, boxDims: BoxDimensions) => {}} props.setBox
 * @param {() => {}} props.onAdjustBoxSides
 * @param {() => {}} props.onAdjustBoxOrientation
 * @param {() => {}} props.onAdjustBoxTopBottom
 * @param {AnnotationContext} props.annotationContext
 * @param {Number[][]} props.points
 * @param {Number} width pixel width of the canvas. The actual displayed width is determined by CSS.
 * @param {Number} height pixel height of the canvas. The actual displayed height is determined by CSS.
 * @param {String=} props.cogColor default pink
 * @param {Number=} props.cogLineWidth default 4
 * @param {String=} props.bgColor default #000
 * @param {String=} props.transformFit default ZOOM_TO_ORIGIN
 * @param {Number=} props.initialZoom default 20
 * @param {Number=} props.pointRadius default 1.5
 * @param {String=} props.colormap default turbo_light
 * @param {String=} props.mousePointHighlightDist default 50px in canvas pixels
 * @param {Number=} props.pointOpacity default 0.3
 * @param {Number=} props.highlightedPointRadius default 1.5
 * @param {Number=} props.highlightedPointOpacity default 0.6
 * @param {Boolean=} props.withBox default false
 * @param {Number=} props.boxLineWidth default 2
 * @param {String=} props.boxColor default pink
 * @param {Boolean=} props.arrowVisible default true
 * @param {Number=} props.arrowLineWidth default 2
 * @param {String=} props.arrowColor default pink
 * @param {Number=} props.cursorSize default 40
 * @param {String=} props.cursorColor default red
 * @param {Number=} props.boxIntersectionLineWidth default 2
 * @param {String=} props.boxIntersectionColor default green
 * @param {Number=} props.existingBoxLineWidth default 2
 * @param {String=} props.existingBoxColor default dark pink
 */
export const PointcloudBEV = ({
    setCogPos,
    setBox,
    onAdjustBoxSides,
    onAdjustBoxOrientation,
    onAdjustBoxTopBottom,
    annotationContext,
    points,
    width,
    height,
    cogColor = "#F0F",
    cogLineWidth = 4,
    bgColor = "#000",
    transformFit = TRANSFORM_FIT.ZOOM_TO_ORIGIN,
    initialZoom = 20,
    pointRadius = 1.5,
    colormap = COLORMAPS.TURBO_LIGHT,
    mousePointHighlightDist = 50,
    pointOpacity = 0.3,
    highlightedPointRadius = 1.5,
    highlightedPointOpacity = 0.6,
    withBox = false,
    boxLineWidth = 2,
    boxColor = "#F0F",
    arrowVisible = true,
    arrowLineWidth = 2,
    arrowColor = "#F0F",
    cursorSize = 40,
    cursorColor = "#F00",
    boxIntersectionLineWidth = 2,
    boxIntersectionColor = "#0F0",
    existingBoxLineWidth = 2,
    existingBoxColor = "#A0A"
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [showCustomMouseCursor, setShowCustomMouseCursor] = useState(false);
    const [customMouseCursorIcon, setCustomMouseCursorIcon] = useState(null);
    const [
        customMouseCursorRotationRad,
        setCustomMouseCursorRotationRad
    ] = useState(-annotationContext.initialBoxOrientation);
    const [hoveredAction, setHoveredAction] = useState(HOVERED_ACTION.NONE);
    const [metaAction, setMetaAction] = useState(META_ACTION.NONE);

    // init shared callbacks
    useEffect(() => {
        PubSub.subscribe(
            AnnotationContext.TOPICS.BOX_DIMENSIONS.RGB,
            /**
             * This callback receives box dimension updates from RGB view,
             * updates the box center, sends the update back to the RGB view
             * and calls the parent callbacks.
             */
            (msg, data) => {
                const scene = shared.sceneMap.get("box");
                const box = scene.getObject("box");
                const arrow = scene.getObject("cog_arrow");
                annotationContext.centerCogInBoxDims(box, arrow);
                annotationContext.handleBoxIntersectsExistingBoxes(
                    scene,
                    box,
                    boxLineWidth,
                    boxColor,
                    boxIntersectionLineWidth,
                    boxIntersectionColor
                );
                scene.render(true);

                PubSub.publish(
                    AnnotationContext.TOPICS.BOX_DIMENSIONS.BEV,
                    null
                );
                updateBox();
                onAdjustBoxTopBottom();
            }
        );
        PubSub.subscribe(
            AnnotationContext.TOPICS.MOUSE_POINTS_HIGHLIGHT.RGB,
            (msg, { closePointIdxs }) => {
                const scene = shared.sceneMap.get("mouse_hover");
                const pointcloudRenderable = shared.sceneMap
                    .get("pointcloud")
                    .getObject("pointcloud");
                AnnotationContext.highlightPointIdxs(
                    scene,
                    pointcloudRenderable,
                    closePointIdxs,
                    highlightedPointRadius,
                    highlightedPointOpacity
                );
            }
        );
        PubSub.subscribe(AnnotationContext.TOPICS.COG.RGB, () => {
            const scene = shared.sceneMap.get("box");
            const box = scene.getObject("box");
            const arrow = scene.getObject("cog_arrow");
            box.poseWorld.setPosition(annotationContext.cogPos);
            arrow.poseWorld.setPosition(annotationContext.cogPos);
            scene.render(true);
        });
        // eslint-disable-next-line
    }, []);

    /**
     * Get cogPos from canvasPos and send cogPos to parent.
     * @param {import("../../lib/qm_cs_lib").Point2D} canvasPos
     */
    const updateCogPos = canvasPos => {
        // resize necessary due to canvas scaling
        const originalDimensionsCanvasPos = transformPoint2DFromDimensions(
            canvasPos,
            shared.resizedCanvasDimensions,
            shared.originalCanvasDimensions
        );

        const scene = shared.sceneMap.get("cog");
        const cross = scene.getObject("cog_cross");
        const worldPos = scene.renderer.canvasPointToWorldXY(
            scene.cameraPoseWorld,
            [originalDimensionsCanvasPos.x, originalDimensionsCanvasPos.y]
        );
        // keep initial height of cog
        worldPos[2] = annotationContext.initialCogPos[2];
        annotationContext.cogPos = worldPos;
        cross.poseWorld.setPosition(worldPos);
        scene.render(true);
        setCogPos(worldPos);
        PubSub.publish(AnnotationContext.TOPICS.COG.BEV, null);
    };

    /**
     * Send current box to parent.
     */
    const updateBox = () => {
        setBox(
            annotationContext.cogPos,
            annotationContext.boxOrientation,
            annotationContext.boxDims
        );
    };

    const makeInteractiveCanvasScene = () => {
        if (withBox) {
            return (
                <CanvasScene
                    name="box"
                    width={width}
                    height={height}
                    init={(scene, name) => {
                        scene.cameraPoseWorld = shared.cam;
                        const {
                            box,
                            arrow
                        } = annotationContext.makeInitialBoxAndArrowRenderables(
                            boxLineWidth,
                            boxColor,
                            arrowLineWidth,
                            arrowColor
                        );
                        arrow.setShouldRender(arrowVisible);
                        // add existing boxes
                        annotationContext.addExistingBoxesToScene(
                            scene,
                            existingBoxLineWidth,
                            existingBoxColor
                        );
                        // add adjustable box later so it's render on top
                        scene.addObject(box, "box");
                        scene.addObject(arrow, "cog_arrow");

                        annotationContext.handleBoxIntersectsExistingBoxes(
                            scene,
                            box,
                            boxLineWidth,
                            boxColor,
                            boxIntersectionLineWidth,
                            boxIntersectionColor
                        );

                        scene.render(true);
                    }}
                    canvasProps={{
                        onMouseDown: e => {
                            setIsDragging(true);
                            // remember latest click position in world
                            const scene = shared.sceneMap.get("box");
                            const mouseCanvas = {
                                x: e.nativeEvent.offsetX,
                                y: e.nativeEvent.offsetY
                            };
                            const originalDimensionsCanvasPos = transformPoint2DFromDimensions(
                                mouseCanvas,
                                shared.resizedCanvasDimensions,
                                shared.originalCanvasDimensions
                            );
                            shared.lastClickWorldPos = scene.renderer.canvasPointToWorldXY(
                                scene.cameraPoseWorld,
                                [
                                    originalDimensionsCanvasPos.x,
                                    originalDimensionsCanvasPos.y
                                ]
                            );

                            if (e.button === 2) {
                                // right mouse button
                                setMetaAction(META_ACTION.MOVE_CAMERA);
                            } else if (e.button === 0) {
                                // left mouse button
                                if (
                                    hoveredAction ===
                                    HOVERED_ACTION.FLIP_ORIENTATION
                                ) {
                                    const box = scene.getObject("box");
                                    const arrow = scene.getObject("cog_arrow");

                                    box.poseWorld.rotateZ(Math.PI);
                                    arrow.poseWorld.setRotation(
                                        box.poseWorld.rot
                                    );
                                    annotationContext.boxOrientation =
                                        box.poseWorld.rot[2];

                                    annotationContext.handleBoxIntersectsExistingBoxes(
                                        scene,
                                        box,
                                        boxLineWidth,
                                        boxColor,
                                        boxIntersectionLineWidth,
                                        boxIntersectionColor
                                    );

                                    scene.render(true);
                                    PubSub.publish(
                                        AnnotationContext.TOPICS.BOX_ORIENTATION
                                            .BEV,
                                        null
                                    );
                                    updateBox();
                                }
                            }
                        },
                        onMouseUp: e => {
                            shared.selectedBoxDims = [];
                            setIsDragging(false);
                            setMetaAction(META_ACTION.NONE);
                        },
                        onMouseLeave: e => {
                            shared.selectedBoxDims = [];
                            setIsDragging(false);
                            setHoveredAction(HOVERED_ACTION.NONE);
                            setMetaAction(META_ACTION.NONE);
                            setShowCustomMouseCursor(false);
                            setCustomMouseCursorIcon(null);
                        },
                        onMouseMove: e => {
                            // TODO: getting mouse in box coordinates is a duplicate of the beginning of the onMouseDown method
                            const scene = shared.sceneMap.get("box");
                            const mouseCanvas = {
                                x: e.nativeEvent.offsetX,
                                y: e.nativeEvent.offsetY
                            };
                            const originalDimensionsCanvasPos = transformPoint2DFromDimensions(
                                mouseCanvas,
                                shared.resizedCanvasDimensions,
                                shared.originalCanvasDimensions
                            );
                            const mouseWorld = scene.renderer.canvasPointToWorldXY(
                                scene.cameraPoseWorld,
                                [
                                    originalDimensionsCanvasPos.x,
                                    originalDimensionsCanvasPos.y
                                ]
                            );
                            // set latest mouse move position in world if it doesn't exist yet
                            if (!shared.lastMouseMoveWorldPos) {
                                shared.lastMouseMoveWorldPos = mouseWorld;
                            }
                            const box = scene.getObject("box");
                            const mouseBox = box.poseWorld.applyInverseTo(
                                mouseWorld
                            );
                            const [x, y] = mouseBox;
                            const arrow = scene.getObject("cog_arrow");

                            if (!isDragging) {
                                // hovering

                                shared.selectedBoxDims = [];
                                const maxDistPixels = 20;
                                const marginBoxLines = 0.5;
                                // Copy and add box margin to increase ease of use when checking whether mouse
                                // is in box bounds. This extends the clickable line (not visible) by marginBoxLines.
                                let {
                                    front,
                                    back,
                                    left,
                                    right
                                } = annotationContext.boxDims;
                                front += marginBoxLines;
                                back += marginBoxLines;
                                left += marginBoxLines;
                                right += marginBoxLines;
                                // Distance has to be calculated without marginBoxLines
                                const frontDist =
                                    Math.abs(
                                        annotationContext.boxDims.front - y
                                    ) * scene.cameraPoseWorld.zoom;
                                if (
                                    -left <= x &&
                                    right >= x &&
                                    frontDist <= maxDistPixels
                                ) {
                                    shared.selectedBoxDims.push("front");
                                }
                                const leftDist =
                                    Math.abs(
                                        -annotationContext.boxDims.left - x
                                    ) * scene.cameraPoseWorld.zoom;
                                if (
                                    -back <= y &&
                                    front >= y &&
                                    leftDist <= maxDistPixels
                                ) {
                                    shared.selectedBoxDims.push("left");
                                }
                                const rightDist =
                                    Math.abs(
                                        annotationContext.boxDims.right - x
                                    ) * scene.cameraPoseWorld.zoom;
                                if (
                                    -back <= y &&
                                    front >= y &&
                                    rightDist <= maxDistPixels
                                ) {
                                    shared.selectedBoxDims.push("right");
                                }
                                const backDist =
                                    Math.abs(
                                        -annotationContext.boxDims.back - y
                                    ) * scene.cameraPoseWorld.zoom;
                                if (
                                    -left <= x &&
                                    right >= x &&
                                    backDist <= maxDistPixels
                                ) {
                                    shared.selectedBoxDims.push("back");
                                }

                                // change cursor depending on hovered area
                                if (
                                    arrowVisible &&
                                    x >=
                                        -annotationContext.arrowDims
                                            .arrowSpanLen /
                                            2 &&
                                    x <=
                                        annotationContext.arrowDims
                                            .arrowSpanLen /
                                            2 &&
                                    y <=
                                        annotationContext.arrowDims
                                            .centerLineLen /
                                            2 &&
                                    y >=
                                        -annotationContext.arrowDims
                                            .centerLineLen /
                                            2
                                ) {
                                    // hover on arrow
                                    setHoveredAction(
                                        HOVERED_ACTION.FLIP_ORIENTATION
                                    );
                                    setCustomMouseCursorIcon(
                                        <BsArrowRepeat size={cursorSize} />
                                    );
                                } else if (
                                    BoxDimensions.BOX_DIMENSION_NAMES.filter(
                                        dimName =>
                                            shared.selectedBoxDims.includes(
                                                dimName
                                            )
                                    ).length > 0
                                ) {
                                    // any side adjustment
                                    if (shared.selectedBoxDims.length === 1) {
                                        // single side adjustment
                                        setHoveredAction(
                                            "RESIZE_" +
                                                shared.selectedBoxDims[0].toUpperCase()
                                        );
                                    } else if (
                                        ["front", "left"].every(dimName =>
                                            shared.selectedBoxDims.includes(
                                                dimName
                                            )
                                        ) ||
                                        ["back", "right"].every(dimName =>
                                            shared.selectedBoxDims.includes(
                                                dimName
                                            )
                                        )
                                    ) {
                                        // two side adjustment front left
                                        setHoveredAction(
                                            HOVERED_ACTION.RESIZE_FRONT_LEFT
                                        );
                                    } else if (
                                        ["front", "right"].every(dimName =>
                                            shared.selectedBoxDims.includes(
                                                dimName
                                            )
                                        ) ||
                                        ["back", "left"].every(dimName =>
                                            shared.selectedBoxDims.includes(
                                                dimName
                                            )
                                        )
                                    ) {
                                        // two side adjustment front right
                                        setHoveredAction(
                                            HOVERED_ACTION.RESIZE_FRONT_RIGHT
                                        );
                                    }
                                    setCustomMouseCursorIcon(
                                        <BsArrowsExpand size={cursorSize} />
                                    );
                                } else if (
                                    x >= -annotationContext.boxDims.left &&
                                    x <= annotationContext.boxDims.right &&
                                    y <= annotationContext.boxDims.front &&
                                    y >= -annotationContext.boxDims.back
                                ) {
                                    // hover inside box
                                    setHoveredAction(HOVERED_ACTION.MOVE);
                                    setCustomMouseCursorIcon(
                                        <BsArrowsMove size={cursorSize} />
                                    );
                                } else {
                                    // hover outside of box
                                    setHoveredAction(HOVERED_ACTION.ROTATE);
                                    setCustomMouseCursorIcon(
                                        <BsArrowCounterclockwise
                                            size={cursorSize}
                                        />
                                    );
                                }
                            } else if (metaAction === META_ACTION.NONE) {
                                // dragging

                                if (
                                    hoveredAction !==
                                        HOVERED_ACTION.MOVE_CAMERA &&
                                    shared.selectedBoxDims.length > 0
                                ) {
                                    if (shared.selectedBoxDims.length > 2) {
                                        // This case only happens when multiple corners are being dragged.
                                        // The order of precedence comes from the order of if statements in the hovering:
                                        // --> front left right back --> enforce front-left, front-right, left-back, right-back
                                        // (left-right can also happen but that's handled further down)
                                        shared.selectedBoxDims = shared.selectedBoxDims.slice(
                                            0,
                                            2
                                        );
                                    }

                                    // when opposing sides are grabbed simultaneously choose only one
                                    if (
                                        ["front", "back"].every(side =>
                                            shared.selectedBoxDims.includes(
                                                side
                                            )
                                        )
                                    ) {
                                        shared.selectedBoxDims = ["front"];
                                    } else if (
                                        ["left", "right"].every(side =>
                                            shared.selectedBoxDims.includes(
                                                side
                                            )
                                        )
                                    ) {
                                        shared.selectedBoxDims = ["left"];
                                    }

                                    // update box dims. x and y is mouse in box coordinates
                                    if (
                                        shared.selectedBoxDims.includes("front")
                                    ) {
                                        annotationContext.boxDims.front = y;
                                    }
                                    if (
                                        shared.selectedBoxDims.includes("back")
                                    ) {
                                        // makes the negative mouse coordinate positive
                                        annotationContext.boxDims.back = -y;
                                    }
                                    if (
                                        shared.selectedBoxDims.includes("left")
                                    ) {
                                        // makes the negative mouse coordinate positive
                                        annotationContext.boxDims.left = -x;
                                    }
                                    if (
                                        shared.selectedBoxDims.includes("right")
                                    ) {
                                        annotationContext.boxDims.right = x;
                                    }

                                    annotationContext.enforceMinimalBoxDims();
                                    annotationContext.centerCogInBoxDims(
                                        box,
                                        arrow
                                    );

                                    annotationContext.handleBoxIntersectsExistingBoxes(
                                        scene,
                                        box,
                                        boxLineWidth,
                                        boxColor,
                                        boxIntersectionLineWidth,
                                        boxIntersectionColor
                                    );

                                    scene.render(true);
                                    PubSub.publish(
                                        AnnotationContext.TOPICS.BOX_DIMENSIONS
                                            .BEV,
                                        null
                                    );
                                    updateBox();
                                    onAdjustBoxSides();
                                } else if (
                                    hoveredAction === HOVERED_ACTION.MOVE
                                ) {
                                    const translationInWorldCoords = [
                                        mouseWorld[0] -
                                            shared.lastMouseMoveWorldPos[0],
                                        mouseWorld[1] -
                                            shared.lastMouseMoveWorldPos[1],
                                        0
                                    ];
                                    const boxPos = box.poseWorld.pos;
                                    box.poseWorld.setPosition([
                                        boxPos[0] + translationInWorldCoords[0],
                                        boxPos[1] + translationInWorldCoords[1],
                                        boxPos[2]
                                    ]);
                                    arrow.poseWorld.setPosition(
                                        box.poseWorld.pos
                                    );

                                    annotationContext.handleBoxIntersectsExistingBoxes(
                                        scene,
                                        box,
                                        boxLineWidth,
                                        boxColor,
                                        boxIntersectionLineWidth,
                                        boxIntersectionColor
                                    );

                                    scene.render(true);
                                    annotationContext.cogPos =
                                        box.poseWorld.pos;
                                    PubSub.publish(
                                        AnnotationContext.TOPICS.COG.BEV,
                                        null
                                    );
                                    updateBox();
                                } else if (
                                    hoveredAction === HOVERED_ACTION.ROTATE
                                ) {
                                    const dotProduct = (a, b) => {
                                        let res = 0;
                                        for (const key in a) {
                                            res += a[key] * b[key];
                                        }
                                        return res;
                                    };
                                    const boxToMouse = [
                                        mouseWorld[0] - box.poseWorld.pos[0],
                                        mouseWorld[1] - box.poseWorld.pos[1]
                                    ];

                                    const boxToPrevMouse = [
                                        shared.lastMouseMoveWorldPos[0] -
                                            box.poseWorld.pos[0],
                                        shared.lastMouseMoveWorldPos[1] -
                                            box.poseWorld.pos[1]
                                    ];

                                    const dot = dotProduct(
                                        boxToPrevMouse,
                                        boxToMouse
                                    );
                                    const cross =
                                        boxToPrevMouse[0] * boxToMouse[1] -
                                        boxToPrevMouse[1] * boxToMouse[0];
                                    const atan2AngleRad = Math.atan2(
                                        cross,
                                        dot
                                    );

                                    box.poseWorld.rotateZ(atan2AngleRad);
                                    arrow.poseWorld.setRotation(
                                        box.poseWorld.rot
                                    );

                                    annotationContext.handleBoxIntersectsExistingBoxes(
                                        scene,
                                        box,
                                        boxLineWidth,
                                        boxColor,
                                        boxIntersectionLineWidth,
                                        boxIntersectionColor
                                    );

                                    scene.render(true);
                                    annotationContext.boxOrientation =
                                        box.poseWorld.rot[2];
                                    PubSub.publish(
                                        AnnotationContext.TOPICS.BOX_ORIENTATION
                                            .BEV,
                                        null
                                    );
                                    updateBox();
                                    setCustomMouseCursorRotationRad(
                                        -annotationContext.boxOrientation
                                    );
                                    onAdjustBoxOrientation();
                                }
                            } else if (metaAction === META_ACTION.MOVE_CAMERA) {
                                const translationInWorldCoords = [
                                    mouseWorld[0] - shared.lastClickWorldPos[0],
                                    mouseWorld[1] - shared.lastClickWorldPos[1],
                                    0
                                ];
                                // this shared cam object instance is used by both the box and the pointcloud scenes
                                shared.cam.setPosition([
                                    shared.cam.pos[0] -
                                        translationInWorldCoords[0],
                                    shared.cam.pos[1] -
                                        translationInWorldCoords[1],
                                    shared.cam.pos[2]
                                ]);
                                scene.render(true);
                                shared.sceneMap.get("pointcloud").render(true);
                            }

                            // remember latest mouse move position in world
                            shared.lastMouseMoveWorldPos = mouseWorld;

                            if (hoveredAction !== HOVERED_ACTION.NONE) {
                                setShowCustomMouseCursor(true);
                            }
                        }
                    }}
                />
            );
        } else {
            return (
                <CanvasScene
                    name="cog"
                    width={width}
                    height={height}
                    init={(scene, name) => {
                        scene.cameraPoseWorld = shared.cam;
                        scene.addObject(
                            annotationContext.crossDims.makeLinesRenderable(
                                new Pose3D(annotationContext.initialCogPos),
                                {
                                    lineWidth: cogLineWidth,
                                    strokeStyle: cogColor
                                }
                            ),
                            "cog_cross"
                        );
                        scene.render(true);
                    }}
                    canvasProps={{
                        onMouseDown: e => {
                            setIsDragging(true);
                            // remember latest click position in world
                            const scene = shared.sceneMap.get("cog");
                            const mouseCanvas = {
                                x: e.nativeEvent.offsetX,
                                y: e.nativeEvent.offsetY
                            };
                            const originalDimensionsCanvasPos = transformPoint2DFromDimensions(
                                mouseCanvas,
                                shared.resizedCanvasDimensions,
                                shared.originalCanvasDimensions
                            );
                            shared.lastClickWorldPos = scene.renderer.canvasPointToWorldXY(
                                scene.cameraPoseWorld,
                                [
                                    originalDimensionsCanvasPos.x,
                                    originalDimensionsCanvasPos.y
                                ]
                            );

                            if (e.button === 2) {
                                // right mouse button
                                setMetaAction(META_ACTION.MOVE_CAMERA);
                            } else if (e.button === 0) {
                                // left mouse button
                                updateCogPos(mouseCanvas);
                            }
                        },
                        onMouseUp: e => {
                            setIsDragging(false);
                            setMetaAction(META_ACTION.NONE);
                        },
                        onMouseLeave: e => {
                            setIsDragging(false);
                            setMetaAction(META_ACTION.NONE);
                        },
                        onMouseMove: e => {
                            const scene = shared.sceneMap.get("cog");
                            const mouseCanvas = {
                                x: e.nativeEvent.offsetX,
                                y: e.nativeEvent.offsetY
                            };
                            const originalDimensionsCanvasPos = transformPoint2DFromDimensions(
                                mouseCanvas,
                                shared.resizedCanvasDimensions,
                                shared.originalCanvasDimensions
                            );
                            const mouseWorld = scene.renderer.canvasPointToWorldXY(
                                scene.cameraPoseWorld,
                                [
                                    originalDimensionsCanvasPos.x,
                                    originalDimensionsCanvasPos.y
                                ]
                            );
                            // set latest mouse move position in world if it doesn't exist yet
                            if (!shared.lastMouseMoveWorldPos) {
                                shared.lastMouseMoveWorldPos = mouseWorld;
                            }

                            if (isDragging && metaAction === META_ACTION.NONE) {
                                updateCogPos({
                                    x: e.nativeEvent.offsetX,
                                    y: e.nativeEvent.offsetY
                                });
                            } else if (
                                isDragging &&
                                metaAction === META_ACTION.MOVE_CAMERA
                            ) {
                                const translationInWorldCoords = [
                                    mouseWorld[0] - shared.lastClickWorldPos[0],
                                    mouseWorld[1] - shared.lastClickWorldPos[1],
                                    0
                                ];
                                // this shared cam object instance is used by both the box and the pointcloud scenes
                                shared.cam.setPosition([
                                    shared.cam.pos[0] -
                                        translationInWorldCoords[0],
                                    shared.cam.pos[1] -
                                        translationInWorldCoords[1],
                                    shared.cam.pos[2]
                                ]);
                                scene.render(true);
                                shared.sceneMap.get("pointcloud").render(true);
                            }
                        }
                    }}
                />
            );
        }
    };

    const getAdjustedCustomMouseCursorResizeRotationRad = () => {
        switch (hoveredAction) {
            case HOVERED_ACTION.RESIZE_BACK:
                return customMouseCursorRotationRad + Math.PI;
            case HOVERED_ACTION.RESIZE_LEFT:
                return customMouseCursorRotationRad + Math.PI / 2;
            case HOVERED_ACTION.RESIZE_RIGHT:
                return customMouseCursorRotationRad - Math.PI / 2;
            case HOVERED_ACTION.RESIZE_FRONT_LEFT:
                return customMouseCursorRotationRad - Math.PI / 4;
            case HOVERED_ACTION.RESIZE_FRONT_RIGHT:
                return customMouseCursorRotationRad + Math.PI / 4;
            default:
                return customMouseCursorRotationRad;
        }
    };

    const layeredScene = (
        <LayeredScene
            setSceneMap={sceneMap => {
                shared.sceneMap = sceneMap;
            }}
        >
            <CanvasScene
                name="pointcloud"
                width={width}
                height={height}
                withZoom // not functional yet: zoom on wheel should be a generic feature
                init={(scene, name) => {
                    initPointcloudScene(
                        scene,
                        points,
                        transformFit,
                        initialZoom,
                        annotationContext.initialCogPos,
                        bgColor,
                        Math.max(1.5 * initialZoom * 0.01, 6), //pointRadius,
                        colormap,
                        pointOpacity
                    );
                }}
                canvasProps={{
                    onWheel: e => {
                        // zoom behaviour: the cursor stays at the same world point
                        const scene = shared.sceneMap.get("pointcloud");
                        const inc = -e.deltaY / 25;
                        const originalDimensionsCanvasPos = transformPoint2DFromDimensions(
                            {
                                x: e.nativeEvent.offsetX,
                                y: e.nativeEvent.offsetY
                            },
                            shared.resizedCanvasDimensions,
                            shared.originalCanvasDimensions
                        );
                        const zoomPosCanvas = [
                            originalDimensionsCanvasPos.x,
                            originalDimensionsCanvasPos.y
                        ];
                        const zoomPosWorld = scene.renderer.canvasPointToWorldXY(
                            scene.cameraPoseWorld,
                            zoomPosCanvas
                        );

                        if (scene.cameraPoseWorld.zoom + inc < 0.5) {
                            return;
                        }

                        scene.cameraPoseWorld.setZoom(
                            scene.cameraPoseWorld.zoom +
                                (inc * scene.cameraPoseWorld.zoom) / 100
                        );
                        const zoomPosWorldAfterZoom = scene.renderer.canvasPointToWorldXY(
                            scene.cameraPoseWorld,
                            zoomPosCanvas
                        );
                        const cameraMovementAfterZoom = subtract(
                            zoomPosWorld,
                            zoomPosWorldAfterZoom
                        );
                        scene.cameraPoseWorld.setPosition(
                            add(
                                scene.cameraPoseWorld.pos,
                                cameraMovementAfterZoom
                            )
                        );

                        // render all necessary scenes: pointcloud, cog, box
                        scene.render(true);
                        if (withBox) {
                            shared.sceneMap.get("box").render(true);
                        } else {
                            shared.sceneMap.get("cog").render(true);
                        }
                        const pointcloudRenderable = scene.getObject(
                            "pointcloud"
                        );
                        AnnotationContext.fitPointStyleToZoom(
                            pointcloudRenderable,
                            scene.cameraPoseWorld
                        );

                        // also clear the mouse_hover canvas
                        const mouseHoverScene = shared.sceneMap.get(
                            "mouse_hover"
                        );
                        mouseHoverScene.renderer.settings.bgClearFunc(
                            mouseHoverScene.renderer.ctx
                        );
                    }
                }}
            />
            <CanvasScene
                name="mouse_hover"
                width={width}
                height={height}
                init={(scene, name) => {
                    scene.cameraPoseWorld = shared.cam;
                }}
                canvasProps={{
                    onMouseMove: e => {
                        const scene = shared.sceneMap.get("mouse_hover");
                        const mouseCanvas = AnnotationContext.scaleCanvasMousePos(
                            e,
                            shared.originalCanvasDimensions,
                            shared.resizedCanvasDimensions
                        );
                        const pointcloudRenderable = shared.sceneMap
                            .get("pointcloud")
                            .getObject("pointcloud");
                        const closePointIdxs = AnnotationContext.findClosePointIdxs(
                            pointcloudRenderable,
                            mouseCanvas,
                            mousePointHighlightDist
                        );
                        const zoomHighlightedPointRadius =
                            scene.cameraPoseWorld.zoom * 0.02;
                        const zoomHighlightedPointOpacity =
                            scene.cameraPoseWorld.zoom * 0.01;
                        AnnotationContext.highlightPointIdxs(
                            scene,
                            pointcloudRenderable,
                            closePointIdxs,
                            zoomHighlightedPointRadius,
                            zoomHighlightedPointOpacity
                        );

                        PubSub.publish(
                            AnnotationContext.TOPICS.MOUSE_POINTS_HIGHLIGHT.BEV,
                            { closePointIdxs }
                        );
                    }
                }}
            />
            {makeInteractiveCanvasScene()}
        </LayeredScene>
    );
    return (
        <>
            {layeredScene}
            <CustomMouseCursor
                id="bev_custom_mouse_cursor"
                show={showCustomMouseCursor}
                icon={customMouseCursorIcon}
                color={cursorColor}
                useRotation={hoveredAction !== HOVERED_ACTION.ROTATE}
                rotationRad={getAdjustedCustomMouseCursorResizeRotationRad()}
            />
        </>
    );
};
