import React, { useEffect } from "react";
import ContinueButton from "../../components/ActionButton/ContinueButton/ContinueButton";
import { sleep } from "../../lib/qm_cs_lib";

import "./styles/ThankYouView.css";

/**
 * @param {Object} props
 * @param {import("../../lib/TaskUIStrategy/taskUIContext").TaskUIContext} props.taskUIContext
 */
export const ThankYouView = props => {
    useEffect(() => {
        sleep(2000).then(() => {
            props.taskUIContext.handleTasksSDKResult();
        });
        props.taskUIContext.emitSubmissionDone();
    }, [props.taskUIContext]);

    return (
        <div className="container-qm thankyouContainer">
            <div>
                <div className="thankyou-icon"></div>
                <p className="header">Thanks for your submission!</p>
                <ContinueButton />
            </div>
        </div>
    );
};

export default ThankYouView;
