import React from "react";
import PropTypes from "prop-types";

const RawHTML = props => {
    const getHTML = () => {
        if (props.className !== undefined) {
            return (
                <div
                    className={props.className}
                    dangerouslySetInnerHTML={{
                        __html: props.htmlString
                    }}
                />
            );
        } else {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: props.htmlString
                    }}
                />
            );
        }
    };

    return <>{getHTML()}</>;
};

RawHTML.propTypes = {
    className: PropTypes.string,
    htmlString: PropTypes.string.isRequired
};

export default RawHTML;
