import { BoxDimensions } from "../NRL/RenderableFactory";

export class BBox3DModel {
    /**
     * @typedef {Object} BBox3DDimensions
     * @property {Number} width
     * @property {Number} length
     * @property {Number} height
     */

    /**
     * Constructor interface is verbose on purpose.
     * Use helper method makeBBox3DModelFromUITypes when converting UI types to model.
     *
     * @param {Number} width
     * @param {Number} length
     * @param {Number} height
     * @param {Number} posX
     * @param {Number} posY
     * @param {Number} posZ
     * @param {Number} rotX
     * @param {Number} rotY
     * @param {Number} rotZ
     * @param {String=} rotationOrder optional. the UI only supports zxy extrinsic anyway
     */
    constructor(
        width,
        length,
        height,
        posX,
        posY,
        posZ,
        rotX,
        rotY,
        rotZ,
        rotationOrder = "zxy"
    ) {
        this.dimensions = {
            width: width,
            length: length,
            height: height
        };
        this.position = {
            x: posX,
            y: posY,
            z: posZ
        };
        this.rotation = {
            x: rotX,
            y: rotY,
            z: rotZ
        };
        this.rotation_order = rotationOrder;
    }

    /**
     * @param {BBox3DModel} box
     */
    static copy(box) {
        return new BBox3DModel(
            box.dimensions.width,
            box.dimensions.length,
            box.dimensions.height,
            box.position.x,
            box.position.y,
            box.position.z,
            box.rotation.x,
            box.rotation.y,
            box.rotation.z,
            box.rotation_order
        );
    }

    /**
     * Create a BBox3DModel from a plain json object which is expected to follow the
     * @param {BBox3DModel} jsonBox
     * @returns {BBox3DModel}
     */
    static parseJSONObject(jsonBox) {
        const box = new BBox3DModel();
        box.dimensions.width = jsonBox.dimensions?.width || 0;
        box.dimensions.length = jsonBox.dimensions?.length || 0;
        box.dimensions.height = jsonBox.dimensions?.height || 0;
        box.position.x = jsonBox.position?.x || 0;
        box.position.y = jsonBox.position?.y || 0;
        box.position.z = jsonBox.position?.z || 0;
        box.rotation.x = jsonBox.rotation?.x || 0;
        box.rotation.y = jsonBox.rotation?.y || 0;
        box.rotation.z = jsonBox.rotation?.z || 0;
        box.rotation_order = jsonBox.rotation_order || "zxy";
        return box;
    }

    /**
     * Turn frontend usable 3DBBox into backend 3DBBox.
     *
     * @param {BoxDimensions} boxDims
     * @param {Number} position
     * @param {Number} rotation
     * @param {String=} rotationOrder default zxy
     */
    static makeBBox3DModelFromUITypes(
        boxDims,
        position,
        rotation,
        rotationOrder = "zxy"
    ) {
        return new BBox3DModel(
            boxDims.left + boxDims.right,
            boxDims.front + boxDims.back,
            boxDims.top + boxDims.bottom,
            position[0],
            position[1],
            position[2],
            rotation[1],
            rotation[0],
            rotation[2],
            rotationOrder
        );
    }

    /**
     * @param {Object} boxUI the same object that was returned from BBox3DModel::makeUITypes()
     * @returns {BBox3DModel}
     */
    static makeBBox3DModelFromBoxUI(boxUI) {
        return BBox3DModel.makeBBox3DModelFromUITypes(
            boxUI.boxDims,
            boxUI.position,
            boxUI.rotation,
            boxUI.rotationOrder
        );
    }

    /**
     * Turn backend 3DBBox into frontend usable 3DBBox.
     * The UI Datatypes specify each dimension with two separate fields
     * and the position and rotation are expected to be arrays instead of objects.
     */
    makeUITypes() {
        const frontBack = this.dimensions.width / 2;
        const leftRight = this.dimensions.length / 2;
        const topBottom = this.dimensions.height / 2;
        const boxDims = new BoxDimensions(
            frontBack,
            frontBack,
            leftRight,
            leftRight,
            topBottom,
            topBottom
        );

        return {
            dimensions: { ...this.dimensions },
            boxDims: boxDims,
            position: [this.position.x, this.position.y, this.position.z],
            rotation: [this.rotation.y, this.rotation.x, this.rotation.z],
            rortationOrder: this.rotation_order
        };
    }
}

export default BBox3DModel;
