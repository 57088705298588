import React from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { ActionButtonNextOrSubmit } from "../../components/ActionButton/ActionButton";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const ActionButtonGroup = ({
    taskUIContext,
    currentTaskOutput,
    tasksAmount
}) => {
    const disabledActionButtons = taskUIContext.shouldActionButtonsBeDisabled();
    const taskOutputDoesntExists = currentTaskOutput
        ? taskUIContext
              .getCurrentGuiObject()
              .isInitialCurrentTaskOutput(currentTaskOutput)
        : true;

    return (
        <div>
            {/* allow spacebar as hotkey to set task output and go to next task */}
            <GlobalHotKeys
                keyMap={{ SET_TASKOUTPUT: "space" }}
                handlers={{
                    SET_TASKOUTPUT: () =>
                        document.querySelector("#next_or_submit_button").click()
                }}
            />
            <ActionButtonNextOrSubmit
                taskUIContext={taskUIContext}
                actionSuffix="set_taskoutput"
                disabled={disabledActionButtons || taskOutputDoesntExists}
                createTaskOutput={() => currentTaskOutput}
                className="is-primary"
            >
                {tasksAmount === 1 ? "Submit" : "Next"}
            </ActionButtonNextOrSubmit>
        </div>
    );
};
