/**
 * Represents the Result for the tasks-sdk
 * @class
 */
export class TasksSDKResult {
    /**
     * @param {String} taskId
     * @returns {TasksSDKResult}
     */
    setTaskId(taskId) {
        this.taskid = taskId;
        return this;
    }

    /**
     * @param {String} userId
     * @returns {TasksSDKResult}
     */
    setUserId(userId) {
        this.userid = userId;
        return this;
    }

    /**
     * @param {import("./TasksSDKReward").TasksSDKReward[]} rewards
     * @returns {TasksSDKResult}
     */
    setRewards(rewards) {
        this.rewards = rewards;
        return this;
    }
}
