// Helpers for working with geometric objects, specifically currently with
// points and line segments.

/**
 * Checks whether point c lies on the line segment defined by a and b.
 *
 * Note that this method does not return a boolean but a 'score'. When the score
 * is 0, the point lies exactly on the segment defined by a and b. The larger
 * that score value gets, the further away the a lies from that segmet. This
 * is useful for handling continuous numeric spaces.
 *
 * @param {x: number, y: number} a
 * @param {x: number, y: number} b
 * @param {x: number, y: number} c
 * @returns number Score value, the closer to the the better.
 */
const onSegment = (a, b, c) => {
    const dxc = c.x - a.x;
    const dyc = c.y - a.y;
    const dxl = b.x - a.x;
    const dyl = b.y - a.y;
    const score = Math.abs(dxc * dyl - dyc * dxl);
    let between = dyl > 0 ? a.y <= c.y && c.y <= b.y : b.y <= c.y && c.y <= a.y;
    if (Math.abs(dxl) >= Math.abs(dyl)) {
        between = dxl > 0 ? a.x <= c.x && c.x <= b.x : b.x <= c.x && c.x <= a.x;
    }
    if (between) {
        return score;
    }
    return 9999999;
};

/**
 * Get the index at which to insert a point onto a polygon on an existing line.
 *
 * Based on https://stackoverflow.com/a/11908158/1447384
 *
 * Now unused method, as lines are handled as individual svg elements for the
 * Polygon UI currently. Method is relevant for cases where the matching line
 * of a click within a larger polygon is to be determined.
 *
 * @param {x: number, y: number} point
 * @param {x: number, y: number}[] points
 * @returns number
 */
export const onSegmentIndex = (point, points) => {
    let best = -1;
    let bestScore = 500;
    for (let score, ix = 0; ix < points.length; ix++) {
        const a = points[ix];
        const b = points[(ix + 1) % points.length];
        score = onSegment(a, b, point);
        if (score < bestScore) {
            bestScore = score;
            best = ix + 1;
        }
    }
    return best;
};

/**
 * Calculate euclidean distance between two points.
 *
 * @param {x: number, y: number} a
 * @param {x: number, y: number} b
 */
export const euclidean = (a, b) => {
    return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
};

/**
 * Find the orientation of order of three points.
 *
 * @param {x: number, y: number} a
 * @param {x: number, y: number} b
 * @param {x: number, y: number} c
 * @returns number one of
 *
 *     - ``0`` -> colinear
 *     - ``1`` -> clockwise
 *     - ``2`` -> counterclockwise
 */
const orientation = (a, b, c) => {
    const val = (b.y - a.y) * (c.x - b.x) - (b.x - a.x) * (c.y - b.y);
    if (val === 0) return 0; // colinear
    return val > 0 ? 1 : 2; // clock or counterclock wise
};

/**
 * Check whether two line segments (a1->b1 and a2->b2) intersect.
 *
 * @param {x: number, y: number} a1
 * @param {x: number, y: number} b1
 * @param {x: number, y: number} a2
 * @param {x: number, y: number} b2
 * @returns bool
 */
const doIntersect = (a1, b1, a2, b2, mayTouch) => {
    const o1 = orientation(a1, b1, a2);
    const o2 = orientation(a1, b1, b2);
    const o3 = orientation(a2, b2, a1);
    const o4 = orientation(a2, b2, b1);

    mayTouch = typeof mayTouch !== "undefined" ? mayTouch : false;
    if (mayTouch) {
        if (a1.x === a2.x && a1.y === a2.y) return false;
        if (a1.x === b2.x && a1.y === b2.y) return false;
        if (b1.x === a2.x && b1.y === a2.y) return false;
        if (b1.x === b2.x && b1.y === b2.y) return false;
    }

    if (o1 !== o2 && o3 !== o4) return true;

    // a1, b1 and a2 are colinear and a2 lies on segment a1b1
    if (o1 === 0 && onSegment(a1, b1, a2) === 0) return true;

    // a1, b1 and b2 are colinear and b2 lies on segment a1b1
    if (o2 === 0 && onSegment(a1, b1, b2) === 0) return true;

    // a2, b2 and a1 are colinear and a1 lies on segment a2b2
    if (o3 === 0 && onSegment(a2, b2, a1) === 0) return true;

    // a2, b2 and b1 are colinear and b1 lies on segment a2b2
    if (o4 === 0 && onSegment(a2, b2, b1) === 0) return true;

    return false;
};

/**
 * Find segment collisions.
 *
 * Collisions are calculated by running doIntersect on each possible pair
 * of segments within one polygon. To reduce the total number of
 * calculations performed, intersectipon checks are bidirectional.
 * If segment a intersects with segment b, segment b also intersects with
 * segment a. Similar, if both segment a and segment b already intersect
 * with any segment at all, they do not need to be checked for intersecting
 * with one another anymore.
 *
 * @params {{x: number, y: number}[2][]} List of pairs of points.
 * @return {number: bool} Mapping of segment indices to collision bools.
 */
export const findCollisions = segments => {
    return segments.reduce((agg, [a, b], outer) => {
        for (let inner = outer + 1; inner < segments.length; inner++) {
            const [c, d] = segments[inner];
            if (agg[inner] && agg[outer]) continue;
            const intersect = doIntersect(a, b, c, d, true);
            agg[outer] = agg[outer] || intersect;
            agg[inner] = agg[inner] || intersect;
        }
        return agg;
    }, {});
};
