import { ActionTypes } from "../actions";
import generateReducer from "../generateReducer";

const initialGuiType = null;

/**
 * The gui type that will be used in order to solve the task
 */
export const guiTypeReducer = generateReducer(initialGuiType, {
    [ActionTypes.SET_GUI_TYPE]: (guiType, action) => {
        return action.guiType;
    }
});

export const getGuiType = state => state.guiType;
