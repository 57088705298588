import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Scene3D } from "./Scene3D";
import {
    FisheyeCameraCanvasRenderer,
    OrthographicCanvasRenderer,
    SphericalCanvasRenderer,
    RENDERERS
} from "./Renderer";
import { Pose3D } from "./Pose3D";

import "./CanvasScene.css";

/**
 * @param {Object} props
 * @param {(scene: Scene3D, name: String) => {}} props.init
 * @param {Pose3D} props.cameraPose
 * @param {String} props.renderer
 * @param {Object} props.rendererSettings
 * @param {import("./Scene3D").Scene3DSettings} props.sceneSettings
 * @param {Map<String, Scene3D>} props.sceneMap
 * @param {String} props.name
 * @param {Number} props.width
 * @param {Number} props.height
 * @param {React.CanvasHTMLAttributes} props.canvasProps
 */
export const CanvasScene = ({
    init,
    sceneMap,
    name,
    width,
    height,
    canvasProps,
    renderer = RENDERERS.ORTHOGRAPHIC_CANVAS,
    rendererSettings = {},
    cameraPose = new Pose3D(),
    sceneSettings = {}
}) => {
    const canvasRef = useRef();

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        ctx.width = canvas.width;
        ctx.height = canvas.height;

        let usedRenderer = null;
        switch (renderer) {
            case RENDERERS.ORTHOGRAPHIC_CANVAS:
                usedRenderer = new OrthographicCanvasRenderer(
                    ctx,
                    rendererSettings
                );
                break;
            case RENDERERS.SPHERICAL_CANVAS:
                usedRenderer = new SphericalCanvasRenderer(
                    ctx,
                    rendererSettings
                );
                break;
            case RENDERERS.FISHEYE_CANVAS:
                usedRenderer = new FisheyeCameraCanvasRenderer(
                    ctx,
                    rendererSettings
                );
                break;
            default:
                usedRenderer = new OrthographicCanvasRenderer(
                    ctx,
                    rendererSettings
                );
                break;
        }
        const scene = new Scene3D(cameraPose, usedRenderer, sceneSettings);
        if (sceneMap) {
            sceneMap.set(name, scene);
        }
        init(scene, name);
        // eslint-disable-next-line
    }, []);
    return (
        <canvas
            ref={canvasRef}
            id={name}
            className="canvas_scene"
            width={width}
            height={height}
            {...canvasProps}
        ></canvas>
    );
};

CanvasScene.propTypes = {
    cameraPose: PropTypes.instanceOf(Pose3D),
    sceneSettings: PropTypes.object,
    name: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    init: PropTypes.func,
    canvasProps: PropTypes.object,
    sceneMap: PropTypes.instanceOf(Map),
    noLayeredCanvasStyle: PropTypes.bool
};

export const LayeredScene = props => {
    const sceneMap = useState(new Map())[0];

    const eventSubscribersMap = {};
    // find all callbacks
    React.Children.forEach(props.children, child => {
        for (const propName in child.props.canvasProps) {
            if (propName.startsWith("on") && !eventSubscribersMap[propName]) {
                eventSubscribersMap[propName] = [];
            }
        }
    });

    // update CanvasScenes with merged styles and propagate event callbacks
    // to all children
    const children = React.Children.map(props.children, (child, idx) => {
        const wrappedCallbackProps = {};
        const childProps = child.props.canvasProps;
        for (const eventName in eventSubscribersMap) {
            wrappedCallbackProps[eventName] = e => {
                // skip the callback while not yet initialized
                const isInitialized =
                    sceneMap.get(child.props.name) !== undefined;
                if (!isInitialized) {
                    return;
                }

                // will call all callbacks from highest layer to lowest layer
                for (const callback of eventSubscribersMap[eventName]) {
                    callback(e);
                }
            };

            if (childProps && eventName in childProps) {
                eventSubscribersMap[eventName].unshift(childProps[eventName]);
            }
        }

        let layeredCanvasStyle = {};
        // merge user defined styles with the defaults. user defined styles take precedence.
        if (childProps) {
            layeredCanvasStyle = {
                ...layeredCanvasStyle,
                ...childProps.style
            };
        }
        // styles for all canvases above the first base one
        if (!child.props.noLayeredCanvasStyle && idx > 0) {
            layeredCanvasStyle = {
                objectFit: "contain",
                position: "absolute",
                left: "0px",
                top: "0px",
                ...layeredCanvasStyle
            };
        }
        return React.cloneElement(child, {
            sceneMap: sceneMap,
            canvasProps: {
                ...childProps,
                ...wrappedCallbackProps,
                style: {
                    ...layeredCanvasStyle
                }
            }
        });
    });

    props.setSceneMap(sceneMap);

    return (
        <div
            className="layered_canvas_scene"
            // defaults that can be overridden by using css on this class
            style={{
                ...props.style
            }}
            onContextMenu={e => {
                if (!props.withContextMenu) {
                    e.preventDefault();
                }
            }}
        >
            {children}
        </div>
    );
};
LayeredScene.propTypes = {
    setSceneMap: PropTypes.func,
    withContextMenu: PropTypes.bool,
    style: PropTypes.object
};
LayeredScene.defaultProps = {
    setSceneMap: sceneMap => {},
    withContextMenu: false
};

export default CanvasScene;
