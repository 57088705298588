import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";

import ZoomableContainer from "../../components/ZoomableContainer/ZoomableContainer";
import { useContainerQMDimensions } from "../../customHooks/useContainerQMDimensions";
import { LoadingAnimation } from "../../components/LoadingAnimation/LoadingAnimation";
import {
    adjustDimensionsToMaxConstraint,
    checkPointInSquare,
    transformPoint2DFromDimensions
} from "../../lib/qm_cs_lib";
import { makeSVGMarkers } from "./svgMarker";

import "./SelectKeypointWidget.css";

const CLICK_MODE = {
    ZOOM: "zoom",
    SET_KEYPOINT: "set_keypoint"
};

/**
 * Captures coordinates on an image in onClick.
 * When a keypoint was selected, it will be drawn to the canvas.
 * Further clicks update and redraw the selected keypoint.
 *
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const SelectKeypointWidget = ({
    resourceCache,
    taskUIContext,
    currentTaskOutput,
    currentTaskIdx,
    setCurrentTaskOutput,
    guiSettings
}) => {
    const [shouldScaleImage, setShouldScaleImage] = useState(false);
    const [clickMode, setClickMode] = useState(CLICK_MODE.ZOOM);
    const [scaleToPoint, setScaleToPoint] = useState({ x: 0, y: 0 });
    const [keypoints, setKeypoints] = useState([]);
    const [
        containerQMDimensions,
        viewPortDimensions
    ] = useContainerQMDimensions(currentTaskIdx);
    const zoomableContainerRef = useRef();
    const imgRef = useRef();
    const taskInput = taskUIContext.getCurrentTaskInput();

    // force reset the ZoomableContainer's scale/translate
    // and reset current keypoints
    useEffect(() => {
        if (zoomableContainerRef.current) {
            zoomableContainerRef.current.classList.add("notransition");
            setShouldScaleImage(false);
        }
        setClickMode(CLICK_MODE.ZOOM);
        setKeypoints([]);
    }, [currentTaskIdx]);

    if (
        !taskUIContext
            .getCurrentGuiObject()
            .taskInputResourcesInCache(currentTaskIdx)
    ) {
        return <LoadingAnimation />;
    }

    taskUIContext.onViewReady();
    const image = resourceCache[taskInput.image_url];
    let imageCenterPoint = { x: 0, y: 0 };
    // get image resized dimensions so that the UI with large image fits into the current viewPort
    const imageOriginalDimensions = {
        width: image.width,
        height: image.height
    };
    const resizedDims = adjustDimensionsToMaxConstraint(
        imageOriginalDimensions,
        containerQMDimensions,
        viewPortDimensions
    );
    const imageResizedDimensions = resizedDims
        ? resizedDims
        : imageOriginalDimensions;
    const getKeypointsInResizedCoordinates = points =>
        points.map(point =>
            transformPoint2DFromDimensions(
                point,
                imageOriginalDimensions,
                imageResizedDimensions
            )
        );

    if (imgRef.current) {
        imageCenterPoint = {
            x: imgRef.current.width / 2,
            y: imgRef.current.height / 2
        };
    }
    const {
        zoom = { scale: null, timeout_ms: null },
        with_multiple_keypoints: withMultipleKeypoints,
        marker = {
            outer_width: 20,
            inner_width: 5,
            set_color: "#F00",
            cursor_color: "#F00"
        }
    } = guiSettings;

    /**
     * Make sure that click mode is reset to zoom properly.
     */
    const handleZoomOut = () => {
        setShouldScaleImage(false);
        if (zoom?.scale) {
            setClickMode(CLICK_MODE.ZOOM);
        } else {
            setClickMode(CLICK_MODE.SET_KEYPOINT);
        }
        // this makes sure that the transitions doesn't get lost!
        zoomableContainerRef.current.classList.remove("notransition");
    };

    /**
     * @param {import("react").MouseEvent} event react event
     */
    const triggerZoom = event => {
        setShouldScaleImage(true);
        setClickMode(CLICK_MODE.SET_KEYPOINT);
        setScaleToPoint({
            x: event.nativeEvent.offsetX,
            y: event.nativeEvent.offsetY
        });
        taskUIContext.getCurrentGuiObject().onAction("zoom_in");
    };

    /**
     * Adds a keypoint to currentTaskOutput
     * @param {import("react").MouseEvent} event react event
     */
    const triggerSetKeypoint = event => {
        // transform the clicked point in resizedDimension to original image dimensions
        const newPointResized = {
            x: event.nativeEvent.offsetX,
            y: event.nativeEvent.offsetY
        };
        const newPoint = transformPoint2DFromDimensions(
            newPointResized,
            imageResizedDimensions,
            imageOriginalDimensions
        );

        let newKeypoints = [...keypoints];
        // update a keypoint or set a new keypoint?
        // we have to check resized coordinates here!
        const clickedKeypointIdx = findIntersectingKeypointIdx(
            newPointResized,
            getKeypointsInResizedCoordinates(newKeypoints),
            marker.outer_width
        );
        if (clickedKeypointIdx !== null) {
            newKeypoints[clickedKeypointIdx] = newPoint;
        } else {
            // keep track of all points or just the latest point depending on the setting with_multiple_keypoints
            newKeypoints = withMultipleKeypoints
                ? [...keypoints, newPoint]
                : [newPoint];
        }

        setScaleToPoint(newPointResized);
        setCurrentTaskOutput(
            taskUIContext
                .getCurrentGuiObject()
                .makeTaskOutputForCurrentTask({ keypoints: newKeypoints })
        );
        setKeypoints(newKeypoints);
        setShouldScaleImage(true);
        zoomableContainerRef.current.classList.remove("notransition");
        taskUIContext.getCurrentGuiObject().onAction("add_keypoint");
    };

    /**
     * @param {import("react").MouseEvent} event react event
     */
    const handleClick = event => {
        if (clickMode === CLICK_MODE.SET_KEYPOINT) {
            triggerSetKeypoint(event);
        } else if (clickMode === CLICK_MODE.ZOOM) {
            triggerZoom(event);
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "10px"
                }}
            >
                <Button
                    id="undo_last_point"
                    disabled={keypoints.length === 0}
                    onClick={() => {
                        const newKeypoints = [...keypoints];
                        newKeypoints.pop();
                        setKeypoints(newKeypoints);
                        setCurrentTaskOutput(
                            taskUIContext
                                .getCurrentGuiObject()
                                .makeTaskOutputForCurrentTask({
                                    keypoints: newKeypoints
                                })
                        );
                    }}
                >
                    Undo last point
                </Button>
            </div>
            <ZoomableContainer
                outerRef={zoomableContainerRef}
                targetScale={zoom?.scale}
                shouldScale={shouldScaleImage}
                onZoomOut={handleZoomOut}
                scaleToCenterPoint={scaleToPoint}
                originalCenterPoint={imageCenterPoint}
                timeoutMillis={zoom?.timeout_ms}
                contained
                containedStyle={{
                    border: "5px solid #DE6588",
                    margin: "auto",
                    marginBottom: "20px",
                    position: "relative",
                    backgroundColor: "pink"
                }}
                zoomableStyle={{ transitionTimingFunction: "ease-in" }}
            >
                <img
                    id="image"
                    ref={imgRef}
                    src={image.src}
                    style={{
                        userSelect: "none",
                        width: imageResizedDimensions.width,
                        height: imageResizedDimensions.height,
                        cursor:
                            clickMode === CLICK_MODE.ZOOM
                                ? "zoom-in"
                                : "default"
                    }}
                    draggable={false}
                    alt=""
                    onClick={handleClick}
                    onWheel={event => {
                        if (event.deltaY < 0) {
                            // zoom into the point where mouse was on wheel event
                            setShouldScaleImage(true);
                            setClickMode(CLICK_MODE.SET_KEYPOINT);
                            setScaleToPoint({
                                x: event.nativeEvent.offsetX,
                                y: event.nativeEvent.offsetY
                            });
                        } else {
                            handleZoomOut();
                        }
                    }}
                />
                {currentTaskOutput
                    ? makeSVGMarkers(
                          imageResizedDimensions,
                          getKeypointsInResizedCoordinates(keypoints),
                          marker.outer_width,
                          marker.inner_width,
                          marker.set_color
                      )
                    : null}
            </ZoomableContainer>
        </div>
    );
};

/**
 * @param {import("../../lib/qm_cs_lib").Point2D} point the point to find in any of the squares
 * @param {import("../../lib/qm_cs_lib").Point2D[]} squares center points of squares
 * @param {Number} squareWidth width of all squares
 */
const findIntersectingKeypointIdx = (point, squares, squareWidth) => {
    const idx = squares.findIndex(square =>
        checkPointInSquare(point, square, squareWidth)
    );
    if (idx === -1) {
        return null;
    }
    return idx;
};
