import React, { useLayoutEffect } from "react";
import { RiThumbUpLine } from "react-icons/ri";
import { RiThumbDownLine } from "react-icons/ri";
import { BsQuestion } from "react-icons/bs";
import CustomTooltip from "./CustomTooltip";

import addTaskUIProps from "../../containers/TaskUI/addTaskUIProps";
import { ActionButtonNextOrSubmit } from "../../components/ActionButton/ActionButton";
import CantSolveDialog from "./CantSolveDialog";
import { MetaActionButtonGroup } from "./MetaActionButtonGroup";

import "./DiscreteAnswerGaming.css";

// use a connected version of the MetaActionButtonGroup
const ConnectedMetaActionButtonGroup = addTaskUIProps(MetaActionButtonGroup);

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const ActionButtonGroup = ({ taskUIContext, currentTaskIdx }) => {
    const [shortcutsEnabled, setShortcutsEnabled] = React.useState(true);
    const disabledActionButtons = taskUIContext.shouldActionButtonsBeDisabled();

    // this resets the button focus for all buttons with the class "dag_button" when the task has changed.
    // it's hacky because this also applies to the meta action buttons.
    // default chrome behaviour is to keep the focus: https://github.com/react-bootstrap/react-bootstrap/issues/1300#issuecomment-138422171
    // A hacky fix is global css "outline: none;"
    // --> don't do a global reset of the outline property: http://outlinenone.com
    useLayoutEffect(() => {
        const discreteAnswerGamingButtons =
            document.querySelectorAll(".dag_button");
        discreteAnswerGamingButtons.forEach(btn => btn && btn.blur());
    }, [currentTaskIdx]);

    const [showCantSolveModal, setShowCantSolveModal] = React.useState(false);

    const onOpenFeedbackModal = () => {
        setShortcutsEnabled(false);
    };

    const onCloseFeedbackModal = () => {
        setShortcutsEnabled(true);
    };

    const onCloseCantSolveModal = () => {
        setShortcutsEnabled(true);
        setShowCantSolveModal(false);
    };

    const onGetCantSolveReason = reason => {
        onCantSolveClicked(reason);
        setShortcutsEnabled(true);
        setShowCantSolveModal(false);
    };

    const onCantSolveClicked = reason => {
        // TODO: subject to change. the structure of cant_solve/corrupt_data should be more open in the future
        const msg = {
            answer: reason,
            corrupt_data: reason === "corrupt_data",
            cant_solve: reason === "cant_solve"
        };
        taskUIContext.getCurrentGuiObject().onAction("next_cant_solve");
        taskUIContext.pushTaskOutput(msg);
        taskUIContext.dispatchNextTaskOrSubmit();
    };

    return (
        <>
            <div style={{ backgroundColor: "#eee" }}>
                <div id="action-button-box">
                    <ActionButtonNextOrSubmit
                        id="no"
                        actionKey="n"
                        actionKeyEnabled={shortcutsEnabled}
                        taskUIContext={taskUIContext}
                        actionSuffix="set_discrete_answer"
                        disabled={disabledActionButtons}
                        createTaskOutput={() =>
                            taskUIContext
                                .getCurrentGuiObject()
                                .makeTaskOutputForCurrentTask({
                                    answer: "no"
                                })
                        }
                        className="is-primary dag_button"
                    >
                        <div>
                            <RiThumbDownLine />
                        </div>
                    </ActionButtonNextOrSubmit>
                    <CustomTooltip text="No idea if yes or no? Click here!">
                        <button
                            id="cant-solve"
                            className="is-primary dag_button action_button button is-sm"
                            onClick={() => {
                                setShortcutsEnabled(false);
                                setShowCantSolveModal(true);
                            }}
                        >
                            <div>
                                <BsQuestion />
                            </div>
                        </button>
                    </CustomTooltip>
                    <ActionButtonNextOrSubmit
                        id="yes"
                        actionKey="y"
                        actionKeyEnabled={shortcutsEnabled}
                        taskUIContext={taskUIContext}
                        actionSuffix="set_discrete_answer"
                        disabled={disabledActionButtons}
                        createTaskOutput={() =>
                            taskUIContext
                                .getCurrentGuiObject()
                                .makeTaskOutputForCurrentTask({
                                    answer: "yes"
                                })
                        }
                        className="is-primary dag_button"
                    >
                        <div>
                            <RiThumbUpLine />
                        </div>
                    </ActionButtonNextOrSubmit>
                </div>
                <CantSolveDialog
                    open={showCantSolveModal}
                    onClose={onCloseCantSolveModal}
                    onSend={onGetCantSolveReason}
                />
            </div>
            {/* embed MetaActionButtonGroup in ActionButtonGroup */}
            <ConnectedMetaActionButtonGroup
                taskUIContext={taskUIContext}
                className="meta_action_button_group"
                onOpenFeedbackModal={onOpenFeedbackModal}
                onCloseFeedbackModal={onCloseFeedbackModal}
            />
        </>
    );
};
