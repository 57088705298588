import React from "react";

/**
 * @param {import("../../lib/qm_cs_lib").Dimension2D} imageDimensions
 * @param {import("../../lib/qm_cs_lib").Point2D[]} points
 * @param {Number} outerSquareWidth
 * @param {Number} innerSquareWidth
 * @param {String} color
 */
export const makeSVGMarkers = (
    imageDimensions,
    points,
    outerSquareWidth,
    innerSquareWidth,
    color
) => {
    const markers = [];
    let i = 0;
    for (const point of points) {
        markers.push(
            makeSVGMarker(point, outerSquareWidth, innerSquareWidth, color, i)
        );
        i++;
    }

    return (
        <svg
            id="marker_svg_area"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={
                "0 0 " + imageDimensions.width + " " + imageDimensions.height
            }
            width={imageDimensions.width}
            height={imageDimensions.height}
            style={{
                position: "absolute",
                top: 0,
                left: 0
            }}
        >
            {markers}
        </svg>
    );
};

/**
 * @param {import("../../lib/qm_cs_lib").Point2D} point
 * @param {Number} outerSquareWidth
 * @param {Number} innerSquareWidth
 * @param {String} color
 * @param {Number|String=} key react element key of the marker group
 */
export const makeSVGMarker = (
    point,
    outerSquareWidth,
    innerSquareWidth,
    color,
    key = 0
) => {
    return (
        <g id={"marker_" + key} key={key}>
            <rect
                id={"outer_square_" + key}
                x={point.x - outerSquareWidth / 2}
                y={point.y - outerSquareWidth / 2}
                width={outerSquareWidth}
                height={outerSquareWidth}
                stroke={color}
                fill="transparent"
                strokeWidth="1"
            />
            <rect
                id={"inner_square_" + key}
                x={point.x - innerSquareWidth / 2}
                y={point.y - innerSquareWidth / 2}
                width={innerSquareWidth}
                height={innerSquareWidth}
                stroke={color}
                fill="transparent"
                strokeWidth="1"
            />
        </g>
    );
};
