import React, { useEffect, useState } from "react";

import CameraViewZoom from "./CameraViewZoom";
import CanvasScene from "../../lib/NRL/CanvasScene";
import { LayeredScene } from "../../lib/NRL/CanvasScene";
import { matMultMat, Pose3D } from "../../lib/NRL/Pose3D";
import { makeInterpolatedLine } from "../../lib/NRL/RenderableFactory";
import { RENDERERS } from "../../lib/NRL/Renderer";

/**
 * enum to discern action indicators for adjusting the box.
 */
export const ACTION_INDICATOR = {
    // dimension for the UI representation of the box.
    // width: x axis
    // length: y axis
    // height: z axis
    DIMENSION: {
        WIDTH: "DIMENSION.WIDTH",
        LENGTH: "DIMENSION.LENGTH",
        HEIGHT: "DIMENSION.HEIGHT"
    },
    ROTATION: { X: "ROTATION.X", Y: "ROTATION.Y", Z: "ROTATION.Z" },
    POSITION: { X: "POSITION.X", Y: "POSITION.Y", Z: "POSITION.Z" }
};

/**
 * @param {import("../../lib/NRL/Renderable").Renderable} box
 * @param {Object} settings
 * @param {String} settings.actionIndicatorName
 * @param {Number} settings.actionIndicatorLineLength
 * @param {String} settings.actionIndicatorLineColor
 * @param {Number} settings.actionIndicatorLineWidth
 */
export const makeActionIndicatorForBox = (box, settings) => {
    const {
        actionIndicatorName,
        actionIndicatorLineLength,
        actionIndicatorLineColor,
        actionIndicatorLineWidth
    } = settings;

    let from = [0, 0, 0];
    let to = [0, 0, 0];
    let rotation = box.poseWorld.rot;
    const lengthHalf = actionIndicatorLineLength;

    switch (actionIndicatorName) {
        case ACTION_INDICATOR.DIMENSION.WIDTH:
        case ACTION_INDICATOR.POSITION.X:
        case ACTION_INDICATOR.ROTATION.X:
            from = [-lengthHalf, 0, 0];
            to = [lengthHalf, 0, 0];
            break;
        case ACTION_INDICATOR.DIMENSION.LENGTH:
        case ACTION_INDICATOR.POSITION.Y:
        case ACTION_INDICATOR.ROTATION.Y:
            from = [0, -lengthHalf, 0];
            to = [0, lengthHalf, 0];
            break;
        case ACTION_INDICATOR.DIMENSION.HEIGHT:
        case ACTION_INDICATOR.POSITION.Z:
        case ACTION_INDICATOR.ROTATION.Z:
            from = [0, 0, 0];
            to = [0, 0, lengthHalf];
            break;
        default:
            throw new Error(
                `The specified actionIndicatorName ${actionIndicatorName} is not supported`
            );
    }

    // position is adjusted along global axis --> no rotation
    if (actionIndicatorName.includes("POSITION")) {
        rotation = [0, 0, 0];
    }

    return makeInterpolatedLine(
        new Pose3D(box.poseWorld.pos, rotation),
        from,
        to,
        200,
        {
            lineWidth: actionIndicatorLineWidth,
            strokeStyle: actionIndicatorLineColor
        }
    );
};

/**
 * @param {Object} props
 * @param {String} props.name
 * @param {Number[][]} props.E
 * @param {Number[][]} props.K
 * @param {Number[]} props.distortionCoeffs
 * @param {HTMLImageElement} props.image
 * @param {Number[][]} props.hmdWorldPose
 * @param {Object} props.boxUI object returned by BBox3DModel::makeUITypes()
 * @param {String} props.boxLineColor
 * @param {Number} props.boxLineWidth
 * @param {Number} props.actionIndicatorLineWidth
 * @param {String} props.actionIndicatorLineColor
 * @param {Number} props.actionIndicatorLineLength world length of actionIndicatorLine
 * @param {String} props.actionIndicatorName
 * @param {Boolean} props.withZoom
 * @param {Number} props.zoomLevel
 */
export const BoundingBoxCameraView = ({
    name,
    E,
    K,
    distortionCoeffs,
    image,
    hmdWorldPose,
    boxUI,
    boxLineColor,
    boxLineWidth,
    actionIndicatorName,
    actionIndicatorLineWidth,
    actionIndicatorLineColor,
    actionIndicatorLineLength,
    withZoom,
    zoomLevel
}) => {
    const [sceneMap, setSceneMap] = useState(null);
    // eslint-disable-next-line
    const [originalCanvasDimensions, setOriginalCanvasDimensions] = useState({
        width: image.width,
        height: image.height
    });
    // eslint-disable-next-line
    const [resizedCanvasDimensions, setResizedCanvasDimensions] = useState(
        null
    );
    const [zoomTranslation, setZoomTranslation] = useState(null);

    // recreate the box scene object on every react update of the boxUI prop
    // and update the actionIndicator
    useEffect(() => {
        if (!sceneMap) {
            return;
        }
        const scene = sceneMap.get("box");
        if (scene.objects.has("box")) {
            scene.deleteObject("box");
        }

        const box = boxUI.boxDims.makeCurvesRenderable(
            new Pose3D(boxUI.position, boxUI.rotation),
            {
                lineWidth: boxLineWidth,
                strokeStyle: boxLineColor
            }
        );
        scene.addObject(box, "box");

        if (scene.objects.has("actionIndicator")) {
            scene.deleteObject("actionIndicator");
        }
        if (actionIndicatorName) {
            const line = makeActionIndicatorForBox(box, {
                actionIndicatorName,
                actionIndicatorLineWidth,
                actionIndicatorLineColor,
                actionIndicatorLineLength
            });
            scene.addObject(line, "actionIndicator");
        }

        scene.render(true);

        // update zoom to box center
        let canvasBoxCenter = scene.renderer.worldPointToCanvas(
            scene.cameraPoseWorld,
            boxUI.position
        );
        if (!canvasBoxCenter) {
            setZoomTranslation(null);
            return;
        }
        canvasBoxCenter = { x: canvasBoxCenter[0], y: canvasBoxCenter[1] };
        // top right camera view is rotated by 180°
        if (name === "top_right") {
            canvasBoxCenter.x =
                resizedCanvasDimensions.width - canvasBoxCenter.x;
            canvasBoxCenter.y =
                resizedCanvasDimensions.height - canvasBoxCenter.y;
        }
        const translation = calculateTranslation(canvasBoxCenter);
        setZoomTranslation(translation);

        // eslint-disable-next-line
    }, [sceneMap, boxUI, actionIndicatorName]);

    /**
     * Calculate translation coordinates for zoom on resized canvas for projectedPoint.
     */
    const calculateTranslation = projectedPoint => {
        const translation = {
            x: resizedCanvasDimensions.width / 2 - projectedPoint.x,
            y: resizedCanvasDimensions.height / 2 - projectedPoint.y
        };

        // max-translation to ensure that the translation does not push the image out of frame
        const maxXtranslation =
            ((zoomLevel - 1) / zoomLevel) * (resizedCanvasDimensions.width / 2);
        const maxYtranslation =
            ((zoomLevel - 1) / zoomLevel) *
            (resizedCanvasDimensions.height / 2);

        if (Math.abs(translation.x) > maxXtranslation) {
            translation.x = Math.sign(translation.x) * maxXtranslation;
        }
        if (Math.abs(translation.y) > maxYtranslation) {
            translation.y = Math.sign(translation.y) * maxYtranslation;
        }
        return translation;
    };

    const layeredScene = (
        <LayeredScene
            style={{
                transform: name === "top_right" ? "rotate(180deg)" : "none"
            }}
            setSceneMap={sceneMapFromLayeredScene => {
                // TODO: this throws a warning because the render func of LayeredScene calls this function
                //       and this function updates the state of CanvasScene
                setSceneMap(sceneMapFromLayeredScene);
            }}
        >
            <CanvasScene
                name="bg_image"
                width={image.width}
                height={image.height}
                renderer={RENDERERS.FISHEYE_CANVAS}
                rendererSettings={{
                    distortionCoeffs: distortionCoeffs,
                    K: K
                }}
                init={scene => {
                    /**
                     * @type {CanvasRenderingContext2D}
                     */
                    const ctx = scene.renderer.ctx;
                    setResizedCanvasDimensions({
                        width: ctx.canvas.offsetWidth,
                        height: ctx.canvas.offsetHeight
                    });
                    scene.cameraPoseWorld = Pose3D.makePoseFromHtm(
                        matMultMat(hmdWorldPose, E)
                    );

                    // draw image scaled to the canvas
                    ctx.drawImage(
                        image,
                        0,
                        0,
                        image.naturalWidth,
                        image.naturalHeight,
                        0,
                        0,
                        ctx.width,
                        ctx.height
                    );
                }}
                canvasProps={{
                    style: { display: "block" },
                    className: "canvas_scene bbox_canvas_scene"
                }}
            />
            <CanvasScene
                name="box"
                width={image.width}
                height={image.height}
                renderer={RENDERERS.FISHEYE_CANVAS}
                rendererSettings={{
                    distortionCoeffs: distortionCoeffs,
                    K: K
                }}
                init={scene => {
                    scene.cameraPoseWorld = Pose3D.makePoseFromHtm(
                        matMultMat(hmdWorldPose, E)
                    );
                }}
                canvasProps={{
                    style: { display: "block" },
                    className: "canvas_scene bbox_canvas_scene"
                }}
            />
        </LayeredScene>
    );

    if (withZoom && zoomTranslation) {
        return (
            <CameraViewZoom
                zoomParameters={{
                    scale: zoomLevel,
                    translation: zoomTranslation
                }}
            >
                {layeredScene}
            </CameraViewZoom>
        );
    }
    return layeredScene;
};
export default BoundingBoxCameraView;
