import React from "react";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const ImageVisualization = ({ taskInput, resourceCache }) => {
    const image = resourceCache[taskInput.image_url];
    return (
        <div id="dag_image_container">
            <img id="dag_image" src={image.src} alt="" />
        </div>
    );
};

export default ImageVisualization;
