import { JSONSerializable } from "../JSONSerializable";
// eslint-disable-next-line
import Ack from "./Ack";

/**
 * @class
 * @augments {JSONSerializable}
 */
export default class GroupAck extends JSONSerializable {
    /**
     *
     * @param {Ack[]} ack
     * @param {string} project_node_id
     * @param {string} user_id
     */
    constructor(ack, project_node_id, user_id) {
        super();
        this.setAck(ack).setProjectNodeId(project_node_id).setUser(user_id);
    }

    /**
     *
     * @param {Ack[]} ack
     * @returns {GroupAck}
     */
    setAck(ack) {
        this.ack = ack;
        return this;
    }

    /**
     *
     * @param {string} project_node_id
     * @returns {GroupAck}
     */
    setProjectNodeId(project_node_id) {
        this.project_node_id = project_node_id;
        return this;
    }

    /**
     *
     * @param {string} user_id
     * @returns {GroupAck}
     */
    setUser(user_id) {
        // the naming is intentional: backend expects the field to be called "user"
        this.user = user_id;
        return this;
    }
}
