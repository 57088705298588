import { ActionTypes } from "../actions";
import generateReducer from "../generateReducer";

// Whether the submit of a single task should be enabled (NOT whether the submission to the backend should be enabled).
// - used as an override for when a guitype might decide to disable the submit of the current task
//   by some action that's outside of the scope of the TaskUIContext or the defaults of the BaseGuiType class.
const initialTaskSubmitEnabled = false;

export const taskSubmitEnabledReducer = generateReducer(
    initialTaskSubmitEnabled,
    {
        [ActionTypes.SET_TASK_SUBMIT_ENABLED]: (taskSubmitEnabled, action) => {
            return action.taskSubmitEnabled;
        }
    }
);

export const getTaskSubmitEnabled = state => state.taskSubmitEnabled;
