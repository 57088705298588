import React from "react";
import PropTypes from "prop-types";
import Rating from "@material-ui/lab/Rating";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: "Very Dissatisfied"
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: "Dissatisfied"
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: "Neutral"
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: "Satisfied"
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: "Very Satisfied"
    }
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired
};

const FeedbackSurvey = ({ onRatingChange }) => {
    return (
        <div style={{ margin: "25px 0 0 25px" }}>
            <Box component="fieldset" mb={3} borderColor="transparent">
                <Typography component="legend" style={{ marginBottom: "10px" }}>
                    How do you like this app?
                </Typography>
                <Rating
                    name="customized-icons"
                    defaultValue={null}
                    getLabelText={value => customIcons[value].label}
                    IconContainerComponent={IconContainer}
                    onChange={(event, newValue) => {
                        onRatingChange(newValue);
                    }}
                />
            </Box>
        </div>
    );
};

export default FeedbackSurvey;
