import React, { useState, useEffect } from "react";

export const ZoomableContainer = ({
    children,
    initialScale = 1,
    targetScale = null,
    shouldScale = false, // toggle for scaling
    timeoutMillis = null, // auto toggle off scaling after this timeout, null to not reset anything
    onZoomOut = () => {}, // onZoomOut: caller should handle resetting "shouldScale"
    scaleToCenterPoint = { x: 0, y: 0 },
    originalCenterPoint = { x: 0, y: 0 },
    contained = false,
    containedStyle = {},
    zoomableStyle = {},
    transitionDuration = "150ms",
    outerRef
}) => {
    const [currentScale, setCurrentScale] = useState(initialScale);
    const [currentTranslate, setCurrentTranslate] = useState({
        x: 0,
        y: 0
    });

    useEffect(() => {
        const reset = () => {
            setCurrentScale(initialScale);
            setCurrentTranslate({ x: 0, y: 0 });
            onZoomOut();
        };

        if (shouldScale) {
            setCurrentScale(targetScale);
            setCurrentTranslate({
                x: originalCenterPoint.x - scaleToCenterPoint.x,
                y: originalCenterPoint.y - scaleToCenterPoint.y
            });
        } else {
            reset();
        }

        if (timeoutMillis !== null) {
            const timeout = window.setTimeout(reset, timeoutMillis);
            return () => {
                window.clearTimeout(timeout);
            };
        }
    }, [
        initialScale,
        targetScale,
        shouldScale,
        onZoomOut,
        timeoutMillis,
        scaleToCenterPoint,
        originalCenterPoint
    ]);

    const scaleStr = `scale(${currentScale})`;
    const translateStr = ` translate(${currentTranslate.x}px, ${currentTranslate.y}px)`;
    const zoomable = (
        <div
            className="zoomableContainer"
            ref={outerRef}
            style={{
                transform: scaleStr + translateStr,
                transitionDuration: transitionDuration,
                ...zoomableStyle
            }}
        >
            {children}
        </div>
    );

    if (contained) {
        return (
            <div
                style={{
                    overflow: "hidden",
                    ...containedStyle
                }}
            >
                {zoomable}
            </div>
        );
    }
    return zoomable;
};

export default ZoomableContainer;
