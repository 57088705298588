import React from "react";
import Magnifier from "react-magnifier";

/**
 * Uses react-magnifier to display a magnifying glass (square).
 * ZoomScale is the zoom factor for the magnifying glass.
 * ImageWidth and height are numbers in pixels.
 */
export const ImageWithMagnifyingTool = ({
    imageSrc,
    imageHeight, // px
    imageWidth, // px
    zoomScale,
    initialScale = 1
}) => {
    imageWidth *= initialScale;
    imageHeight *= initialScale;
    const mgWidth = imageWidth * 0.2;
    const mgHeight = imageHeight * 0.3;
    return (
        <div
            style={{
                width: "fit-content",
                margin: "auto"
            }}
        >
            <Magnifier
                src={imageSrc}
                style={{
                    width: "auto",
                    height: "auto",
                    maxWidth: "100%",
                    maxHeight: "76vh"
                }}
                mgShape="square"
                mgWidth={mgWidth < 150 ? 150 : mgWidth}
                mgHeight={mgHeight < 150 ? 150 : mgHeight}
                zoomFactor={zoomScale}
            />
        </div>
    );
};

export default ImageWithMagnifyingTool;
