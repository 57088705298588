import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";

import FeedbackSurvey from "./FeedbackSurvey";

/**
 * @typedef {Object} FeedbackDialogOutput
 * @property {String} msg
 * @property {Number} rating
 */

/**
 * @param {Object} props
 * @param {Boolean} props.isOpen
 * @param {(output: FeedbackDialogOutput) => {}} props.onClose
 * @param {(output: FeedbackDialogOutput) => {}} props.onDeleteUserModal
 * @param {Number=} props.maxMsgLength
 */
const FeedbackDialog = ({
    isOpen,
    onClose,
    onDeleteUserProfile,
    maxMsgLength = 280
}) => {
    const [msg, setMsg] = React.useState("");
    const [rating, setRating] = React.useState(null);
    const [curLength, setCurLength] = React.useState(0);

    useEffect(() => {
        // reset state of the feedback dialog every time it's opened
        if (isOpen) {
            setMsg("");
            setRating(null);
            setCurLength(0);
        }
    }, [isOpen]);

    const onTextChange = e => {
        setMsg(e.target.value);
        setCurLength(e.target.value.length);
    };

    const onRatingChange = value => {
        setRating(value);
    };

    const onCancelClicked = () => {
        onClose();
    };

    const onSendClicked = () => {
        const content = {
            msg,
            rating
        };
        onClose(content);
    };

    const onDeleteProfile = () => {
        onDeleteUserProfile();
    };

    /**
     * Sending form should be disabled when rating or msg are empty.
     */
    const isSendDisabled = () => {
        return curLength === 0 || msg.trim() === "" || rating === null;
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Feedback</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Have you seen any inappropriate content or encountered
                        problems with our app? Please let us know!
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        multiline
                        rowsMax={8}
                        inputProps={{ maxLength: maxMsgLength }}
                        fullWidth
                        onChange={onTextChange}
                    />
                </DialogContent>
                <div style={{ textAlign: "right", marginRight: "25px" }}>
                    {curLength} / {maxMsgLength}
                </div>
                <FeedbackSurvey onRatingChange={onRatingChange} />
                <DialogActions>
                    <Button onClick={onDeleteProfile} color="secondary">
                        Delete Profile
                    </Button>
                    <div style={{ flex: "1 0 0" }} />
                    <Button onClick={onCancelClicked} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={onSendClicked}
                        disabled={isSendDisabled()}
                        color="primary"
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

FeedbackDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    maxMsgLength: PropTypes.number
};

export default FeedbackDialog;
