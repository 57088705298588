import React from "react";
import ContinueButton from "../ActionButton/ContinueButton/ContinueButton";

import "./TimeoutPage.css";

export const TimeoutPage = () => {
    const AlertTriangle = ({ size = 44, color = "#e31b1b" }) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
            <line x1="12" y1="9" x2="12" y2="13"></line>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
        </svg>
    );

    return (
        <div className="container-qm timeoutContainer">
            <div>
                <div id="alert_triangle" style={{ paddingBottom: 20 }}>
                    <AlertTriangle />
                </div>
                <p className="header">Sorry you took too long!</p>
                <ContinueButton />
            </div>
        </div>
    );
};

export default TimeoutPage;
