/**
 * Base class representing a Serializable Object
 * @class
 */
export class JSONSerializable {
    /**
     * @returns {Object} - a json stringifiable js object
     */
    toJSON() {
        return Object.getOwnPropertyNames(this).reduce((a, b) => {
            a[b] = this[b];
            return a;
        }, {});
    }
}
