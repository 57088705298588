import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";

/**
 * @param {Object} props
 * @param {Boolean} props.isOpen
 * @param {(output: DeleteProfileDialogOutput) => {}} props.onClose
 */
const DeleteProfileDialog = ({ isOpen, onClose }) => {
    const onCancelClicked = () => {
        onClose(false);
    };

    const onSendClicked = () => {
        onClose(true);
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Delete User Profile
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete your data? This process
                        is irreversible!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onSendClicked} color="secondary">
                        Delete Data!
                    </Button>
                    <div style={{ flex: "1 0 0" }} />
                    <Button onClick={onCancelClicked} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

DeleteProfileDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default DeleteProfileDialog;
