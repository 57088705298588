export class BBox2DModelLabeled {
    /**
     * @param {Number} left
     * @param {Number} top
     * @param {Number} right
     * @param {Number} bottom
     * @param {String} label
     */
    constructor(left, top, right, bottom, label = "") {
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
        this.label = label;
    }

    setTopLeft({ x, y }) {
        this.top = y;
        this.left = x;
    }

    setBottomRight({ x, y }) {
        this.bottom = y;
        this.right = x;
    }

    setLabel(label) {
        this.label = label;
    }
}

export default BBox2DModelLabeled;
