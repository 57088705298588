/**
 * Sample usage:
 *
 *   const imgContainer = document.getElementById("imgContainer");
 *   const img = new Image();
 *   img.crossOrigin = "Anonymous";
 *   img.onload = () => {
 *       imgContainer.appendChild(img);
 *       const pixels = convertImageToPixel3DArray(img);
 *       for (let y = 0; y < pixels.length; y++) {
 *           for (let x = 0; x < pixels[y].length; x++) {
 *               pixels[y][x][0] = 0; // red
 *               pixels[y][x][1] -= 30; // green
 *               pixels[y][x][2] = 0; // blue
 *           }
 *       }
 *       const newCanvas = drawPixelsOnCanvas(pixels);
 *       imgContainer.appendChild(newCanvas);
 *   }
 *   img.src = "https://placekitten.com/600/400";
 */

import { memoize, runLengthEncoding } from "./qm_cs_lib";

/**
 *
 * @param {HTMLImageElement} img
 * @returns {Uint8ClampedArray}
 */
export const getImageData = img => {
    return getImageDataObject(img).data;
};

/**
 *
 * @param {HTMLImageElement} img
 * @returns {ImageData}
 */
export const getImageDataObject = img => {
    const width = img.width;
    const height = img.height;
    const { ctx } = drawImageOnNewCanvas(img);
    return ctx.getImageData(0, 0, width, height);
};

/**
 * @param {HTMLImageElement} img
 */
export const drawImageOnNewCanvas = img => {
    const width = img.width;
    const height = img.height;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;
    ctx.width = width;
    ctx.height = height;
    ctx.drawImage(img, 0, 0);
    return { canvas, ctx };
};

/**
 * @callback getCachedImageDataObject
 * @param {Image} img
 * @returns {ImageData} getCopy
 */

/**
 * Returns a function that caches the image objects ImageData with
 * their src attribute as key.
 *
 * @default makeCopy=false
 * @param {Boolean=} makeCopy whether to create a copy of the ImageData object from the cache.
 *                            default behaviour false: don't create copy of the ImageData object in cache
 *                            and return the same object every time
 * @returns {getCachedImageDataObject}
 */
export const getCachedImageDataObjectCreator = (makeCopy = false) => {
    const f = memoize(getImageDataObject, img => img.src);
    if (makeCopy) {
        return img => {
            const imgData = f(img);
            return new ImageData(
                new Uint8ClampedArray(imgData.data), // constructor handles copying
                imgData.width
            );
        };
    }
    return f;
};

/**
 *
 * @param {HTMLImageElement} img
 * @returns {Number[][][]} dimensions: y, x, rgb
 */
export const convertImageToPixel3DArray = img => {
    const width = img.width;
    const height = img.height;
    const ctxImgData = getImageData(img);

    const yxrgb = new Array(height);
    const getRedIdxFor = (x, y) => y * (width * 4) + x * 4;
    for (let y = 0; y < height; y++) {
        yxrgb[y] = new Array(width);
        for (let x = 0; x < width; x++) {
            yxrgb[y][x] = new Array(3);
            const redIdx = getRedIdxFor(x, y);
            yxrgb[y][x][0] = ctxImgData[redIdx];
            yxrgb[y][x][1] = ctxImgData[redIdx + 1];
            yxrgb[y][x][2] = ctxImgData[redIdx + 2];
        }
    }
    return yxrgb;
};

/**
 * Set the whole alpha channel on 1D imageData array to a fixed value (in-place).
 * @param {Uint8ClampedArray} imageData
 * @param {Number} value value for the whole alpha channel
 */
export const setAlphaChannel = (imageData, value) => {
    if (value < 0 || value > 255) {
        throw new Error(`value: '${value}' out of range`);
    }

    for (let i = 3; i < imageData.length; i += 4) {
        imageData[i] = value;
    }
};

/**
 * Set any pixel that is set on 1D imageData array to a fixed value in the chosen channel and to 0 in the others, if that pixel has any rgb value greater than 0 (in-place).
 * Ignores the alpha channel.
 * @param {Uint8ClampedArray} imageData
 * @param {Number} channel 0: red, 1: green, 2: blue
 * @param {Number} value value for the channel of the set pixel
 */
export const setSingleChannelIfPixelSet = (imageData, channel, value) => {
    if (value < 0 || value > 255 || channel < 0 || channel > 2) {
        throw new Error(
            `value: '${value}' or channel: '${channel}' out of range`
        );
    }

    const channels = [0, 1, 2];
    for (let i = 0; i < imageData.length; i += 4) {
        let pixelSet = false;
        for (const c of channels) {
            if (imageData[i + c] > 0) {
                pixelSet = true;
            }
            if (pixelSet && c === channel) {
                imageData[i + c] = value;
            } else {
                imageData[i + c] = 0;
            }
        }
    }
};

/**
 * Return a new 1D array of only a single channel per pixel.
 * @param {Uint8ClampedArray} imageData
 * @param {Number} channel
 * @returns {Number[]}
 */
export const getSingleChannel = (imageData, channel) => {
    if (channel < 0 || channel > 3) {
        throw new Error(`channel: '${channel}' out of range`);
    }
    const newImageData = [];
    for (let i = 0; i < imageData.length; i += 4) {
        newImageData.push(imageData[i + channel]);
    }
    return newImageData;
};

/**
 * Get a binary run-length-encoding of for all set pixels, ignoring the alpha channel.
 * The image data that is actually being encoded is a 1D array representing a black&white (or monochrome, binary) image.
 * @param {Uint8ClampedArray} imageData
 * @returns {String}
 */
export const getSingleChannelBinaryRunLengthEncoded = imageData => {
    // alpha channel to 0
    setAlphaChannel(imageData, 0);
    // if pixel set, set red channel to 1 (range 0-255) and other channels to 0
    setSingleChannelIfPixelSet(imageData, 0, 1);
    const singleChannel = getSingleChannel(imageData, 0);
    return runLengthEncoding(singleChannel);
};

/**
 *
 * @param {Number[][][]} pixels dimensions: y, x, rgb
 * @returns {HTMLCanvasElement}
 */
export const drawPixelsOnCanvas = pixels => {
    const height = pixels.length;
    const width = pixels[0].length;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;
    ctx.width = width;
    ctx.height = height;

    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            const r = pixels[y][x][0];
            const g = pixels[y][x][1];
            const b = pixels[y][x][2];
            ctx.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
            ctx.fillRect(x, y, 1, 1);
        }
    }

    return canvas;
};

/**
 * Converts a RGB [0, 255] triplet to a grayscale [0, 1] scalar.
 * @param {Number} r [0, 255]
 * @param {Number} g [0, 255]
 * @param {Number} b [0, 255]
 */
export const rgb2gray = (r, g, b) => {
    return 0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);
};

/**
 * Gets a pixel's grayscale color from within a context's imageData.
 *
 * @param {Uint8ClampedArray} pixel rgb values as flat array
 * @param {Number} x
 * @param {Number} y
 */
export const getGrayscaleColor = (imageData, x, y) => {
    const ix = (x + y * imageData.width) * 4;
    return rgb2gray(
        imageData.data[ix],
        imageData.data[ix + 1],
        imageData.data[ix + 2]
    );
};

/**
 * Transform imageData to black&white image data.
 * @param {Uint8ClampedArray} imageData
 * @param {Uint8ClampedArray} threshold
 * @returns {Uint8ClampedArray}
 */
export const imageDataToBlackWhite = (imageData, threshold) => {
    const bwData = [];
    for (let i = 0; i < imageData.length; i += 4) {
        let color = 0;
        if (
            (imageData[i] + imageData[i + 1] + imageData[i + 2]) / 3 >=
            threshold
        ) {
            color = 255;
        }
        bwData.push(color);
        bwData.push(color);
        bwData.push(color);
        bwData.push(255);
    }
    return new Uint8ClampedArray(bwData);
};

/**
 * Gets the neighboring pixels of a single pixel considering a 4-neighborhood.
 * Returned is a list of triplets of [x, y, grayscale] values.
 */
export const getNeighbors = (imageData, x, y) => {
    return [
        [x, y - 1, getGrayscaleColor(imageData, x, y - 1)], // up
        [x + 1, y, getGrayscaleColor(imageData, x + 1, y)], // right
        [x, y + 1, getGrayscaleColor(imageData, x, y + 1)], // down
        [x - 1, y, getGrayscaleColor(imageData, x - 1, y)] // left
    ];
};

/**
 * Scales imageData by drawing the data to a new invisible canvas with dimensions toDims, scaling by the appropriate amount and reading back into a new array that'll be returned.
 * If width and height of toDims are equal to or smaller than fromDims, then no upscaling is performed and the original imageData is returned.
 * @param {Uint8ClampedArray} imageData
 * @param {import("./qm_cs_lib").Dimension2D} fromDims
 * @param {import("./qm_cs_lib").Dimension2D} toDims
 * @returns {Promise<Uint8ClampedArray>}
 */
export const upscaleImageData = async (imageData, fromDims, toDims) => {
    // sanity check
    if (fromDims.width >= toDims.width && fromDims.height >= toDims.height) {
        return imageData;
    }
    // ratios
    const widthRatio = toDims.width / fromDims.width;
    const heightRatio = toDims.height / fromDims.height;

    // create a new canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = toDims.width;
    canvas.height = toDims.height;
    ctx.width = toDims.width;
    ctx.height = toDims.height;

    // create a bitmap from imageData
    const bitmap = await window.createImageBitmap(
        new ImageData(imageData, fromDims.width),
        0,
        0,
        fromDims.width,
        fromDims.height
    );

    // scale the new canvas and draw bitmap to it
    ctx.scale(widthRatio, heightRatio);
    ctx.drawImage(bitmap, 0, 0);

    // read back from new canvas
    const newImageData = ctx.getImageData(0, 0, toDims.width, toDims.height)
        .data;
    return newImageData;
};
