import React from "react";

export const ContainerLayoutComponent = ({
    render: InnerComponent,
    innerProps,
    style
}) => {
    const overriddenDefaultStyle = {
        ...style
    };
    return (
        <div className="container-qm" style={overriddenDefaultStyle}>
            <InnerComponent {...innerProps} />
        </div>
    );
};

/**
 * Wrap a Component with a centered container
 *
 * @param {React.Component} WrappedComponent
 * @param {React.CSSProperties} style
 */
export const withContainerLayout = (WrappedComponent, style) => {
    return props => (
        <ContainerLayoutComponent
            render={WrappedComponent}
            innerProps={{ ...props }}
            style={style}
        />
    );
};

export default ContainerLayoutComponent;
