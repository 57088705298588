import React from "react";
import ReactDOM from "react-dom";

import { getQueryParams, isProdStage, stringTruthiness } from "./lib/qm_cs_lib";
import configureStore from "./store/configureStore";
import App from "./containers/TaskUI/App";
import {
    GUITYPE_REGISTRY,
    throwGuiTypeUnknownError
} from "./guitypes/GuiTypeRegistry";

import "./index.css";
import "./font.css";

// handle environment
const { REACT_APP_USE_MOCK_DATA, REACT_APP_MOCK_GUI_TYPE } = process.env;
const USE_MOCK_DATA = stringTruthiness(REACT_APP_USE_MOCK_DATA);

const actualQueryParams = getQueryParams();
let isDebugMode = false;
let mockData = null;

// handle mock data
const makeMockDataForGuiType = guiType => {
    if (!GUITYPE_REGISTRY.has(guiType)) {
        throwGuiTypeUnknownError(guiType);
    }
    mockData = GUITYPE_REGISTRY.get(guiType).makeMockData();
};

if (USE_MOCK_DATA) {
    makeMockDataForGuiType(REACT_APP_MOCK_GUI_TYPE);
} else {
    if (!isProdStage() && actualQueryParams.has("debug_mode")) {
        if (!actualQueryParams.has("gui_type")) {
            throw new Error("debug_mode requires gui_type!");
        }
        isDebugMode = true;
        makeMockDataForGuiType(actualQueryParams.get("gui_type"));
    }
}

// create app
const store = configureStore();
const app = (
    <App
        actualQueryParams={actualQueryParams}
        isDebugMode={isDebugMode}
        mockData={mockData}
        store={store}
    />
);
ReactDOM.render(app, document.getElementById("root"));
