import { Container } from "react-bootstrap";

import BaseGuiType from "../BaseGuiType";
import MockApiClient from "../MockData/MockApiClient";
import MockData from "../MockData/MockData";
import ResourceLoaderService from "../ResourceLoaderService";
import { makeImageLoader } from "../../lib/DataLoader/loaders";
import { WIDGETS } from "../../guiFactory";
import withWaitForResourcesWrapper from "../util/withWaitForResourcesWrapper";
import KeypointInCameraViewsWidget from "./KeypointInCameraViewsWidget";
import { TaskInputsForSequenceKeypoint2 } from "./tiledUIMockInputs";

export class TiledKeypoint3D extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return WIDGETS.TILED_KEYPOINT_3D;
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const mockApiClient = new MockApiClient(
            {
                instructions: [],
                question: { text: "click any view to set the keypoint" },
                keypoint_initial_distance_m: 1.0,
                keypoint_color: "rgba(255, 255, 255, 0)",
                keypoint_outline_color: "yellow",
                keypoint_outline_width: 2.0,
                keypoint_radius_px: 8.0,
                line_width: 2.0,
                line_color: "#F00",
                zoom_level: 1.5,
                reference_image_url:
                    "https://qm-coconut.s3.eu-central-1.amazonaws.com/pre-processing/objects/0/dynamic/xri-bbox3-book-high-texture-1.jpg"
            },
            TiledKeypoint3D.getName(),
            TaskInputsForSequenceKeypoint2
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeCustomLoaderService(
                new Map([
                    [
                        "all_image_urls",
                        taskInput => {
                            let loaders = [];
                            for (const cameraViewParams of Object.values(
                                taskInput.timestamps
                            )) {
                                loaders = [
                                    ...loaders,
                                    makeImageLoader(
                                        cameraViewParams.tl.image_url
                                    ),
                                    makeImageLoader(
                                        cameraViewParams.tr.image_url
                                    ),
                                    makeImageLoader(
                                        cameraViewParams.bl.image_url
                                    ),
                                    makeImageLoader(
                                        cameraViewParams.br.image_url
                                    )
                                ];
                            }
                            return loaders;
                        }
                    ]
                ])
            )
        );
    }

    /**
     * @override
     * @returns {LayoutCreator}
     */
    getLayoutCreator() {
        return (
            heading,
            dataVis,
            progressBar,
            actionButtonGroup,
            metaActionButtonGroup
        ) => {
            const components = [
                heading,
                dataVis,
                progressBar,
                actionButtonGroup,
                metaActionButtonGroup
            ];
            const instantiatedComponents = this.connectAndInstantiateComponents(
                components
            );
            return (
                <Container
                    style={{
                        backgroundColor: "#f5f5f5",
                        textAlign: "center",
                        borderRadius: "15px"
                    }}
                >
                    {instantiatedComponents}
                </Container>
            );
        };
    }

    /**
     * @override
     */
    getHeading() {
        return () => null;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return withWaitForResourcesWrapper(KeypointInCameraViewsWidget);
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return () => null;
    }

    /**
     * @override
     */
    getMetaActionButtonGroup() {
        return () => null;
    }

    /**
     * @override
     */
    getInitialCurrentTaskOutput() {
        return {
            ...super.makeTaskOutputForCurrentTask(),
            timestamps: {}
        };
    }

    /**
     * @override
     */
    makeTaskOutputForCurrentTask(overrides) {
        return {
            ...this.getInitialCurrentTaskOutput(),
            ...overrides
        };
    }
}

export default TiledKeypoint3D;
