import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Slider from "rc-slider";

import { ActionButtonNextOrSubmit } from "../../../components/ActionButton/ActionButton";
import BBox3DModel from "../../../lib/DataModels/BBox3DModel";
import BoundingBoxFaceAdjustmentCameraView from "./BoundingBoxFaceAdjustmentCameraView";
import { Pose3D } from "../../../lib/NRL/Pose3D";
import { BoxDimensions } from "../../../lib/NRL/RenderableFactory";
import { MetaActionButtons } from "./MetaActionButtons";
import { ACTION_INDICATOR } from "../BoundingBoxCameraView";
import { TimelineSlider } from "../TimelineSlider";
import {
    AnnotationReviewDialog,
    REVIEW_DIALOG_TYPE
} from "../AnnotationReviewDialog";
import { FinishConfirmDialog } from "../FinishConfirmDialog";

import "../CoconutCanvasScene.css";
import "./BoundingBoxFaceAdjustmentInCameraViewsWidget.css";

const DEFAULT_GUI_SETTINGS = {
    questionText: "Adjust the faces of the box to fit the object",
    boxLineColor: "#F00",
    boxLineWidth: 2.0,
    sliderRangeDimension: [0, 0.2],
    sliderStepDimension: 0.001,
    sliderRangeRotation: [-Math.PI / 4, Math.PI / 4],
    sliderStepRotation: 0.01,
    disabledCoordinateAxes: [],
    disabledCoordinateAxisColor: "#333",
    zoomLevel: 1.5,
    reference_image_url: null,
    worldCoordinates: false
};

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const BoundingBoxFaceAdjustmentInCameraViewsWidget = ({
    taskInput,
    guiSettings,
    resourceCache,
    currentTaskOutput,
    setCurrentTaskOutput,
    taskUIContext,
    currentTaskIdx
}) => {
    const [initialCurrentTaskOutput, setInitialCurrentTaskOutput] = useState(
        taskUIContext.getCurrentGuiObject().getInitialCurrentTaskOutput()
    );
    // The BBox3D data in a representation that's usable by the UI
    // See how TiledBoundingBox3D::getInitialCurrentTaskOutput() deals with the input data for more details.
    const [boxUI, setBoxUI] = useState(
        initialCurrentTaskOutput.box.makeUITypes()
    );
    // const [initialBoxUI, setInitialBoxUI], useState(boxUI);

    const [rotationDeltas, setRotationDeltas] = useState([0, 0, 0]);

    const [zoomEnabled, setZoomEnabled] = useState(false);

    // eslint-disable-next-line
    const [timestamps, setTimestamps] = useState(
        Object.keys(taskInput.timestamps).sort((a, b) => Number(a) - Number(b))
    );
    // idx in the timestamp selection
    const [timestampIdx, setTimestampIdx] = useState(0);
    const [trackablePose, setTrackablePose] = useState(null);

    // used to tell BoundingBoxCameraView which actionIndicator it should display
    const [actionIndicatorName, setActionIndicatorName] = useState(null);
    // used to handle this case:
    //  - Moving mouse over slider A while dragging slider B triggering mouseup.
    //    Without this the actionIndicator would be gone even though the mouse hovers slider A.
    const [nextActionIndicatorName, setNextActionIndicatorName] = useState(
        null
    );
    // don't change the actionIndicatorName while dragging a slider
    const [mouseDown, setMouseDown] = useState(false);
    // the actionIndicator should disappear when mouseup happens when mouse isn't over a slider
    const [mouseOverSlider, setMouseOverSlider] = useState(false);

    // states to manage the review of annotations
    const [isReviewDialogOpen, setIsReviewDialogOpen] = useState(false);
    const [isReviewDone, setIsReviewDone] = useState(false);
    const [isReviewStarted, setIsReviewStarted] = useState(false);
    const onOpenReviewDialog = () => {
        setIsReviewDialogOpen(true);
    };
    const onCloseReviewDialog = () => {
        setIsReviewDialogOpen(false);
    };
    const onStartReviewPhase = () => {
        setIsReviewStarted(true);
        setTimestampIdx(0);
    };

    // state for finish confirm dialog --> a confirm will trigger a submission
    const [isFinishConfirmDialogOpen, setIsFinishConfirmDialogOpen] = useState(
        false
    );
    const onCloseFinishConfirmDialog = isFinishConfirmed => {
        if (isFinishConfirmed) {
            const finalTaskOutput = { ...currentTaskOutput };
            // do the box re-centering only when finished, so that the user doesn't get confused
            // update the box center: subtract because the dimensions are
            const boxDims = BoxDimensions.copy(boxUI.boxDims);
            // always positive
            const boxCenterInBoxCoords = [
                (boxDims.right - boxDims.left) / 2,
                (boxDims.front - boxDims.back) / 2,
                (boxDims.top - boxDims.bottom) / 2
            ];

            // we need to take the current rotation into account as well to update the position
            const boxPose = new Pose3D(boxUI.position, boxUI.rotation);
            boxUI.position = boxPose.applyTo(boxCenterInBoxCoords);

            // update box dimensions because they should be relative to the new box center
            const halfWidth = (boxDims.left + boxDims.right) / 2;
            const halfLength = (boxDims.front + boxDims.back) / 2;
            const halfHeight = (boxDims.top + boxDims.bottom) / 2;
            boxUI.boxDims = new BoxDimensions(
                halfLength,
                halfLength,
                halfWidth,
                halfWidth,
                halfHeight,
                halfHeight
            );
            finalTaskOutput.box = BBox3DModel.makeBBox3DModelFromBoxUI(boxUI);

            // trigger submit
            taskUIContext.pushTaskOutput(finalTaskOutput);
            taskUIContext.dispatchNextTaskOrSubmit();
        } else {
        }
        setIsFinishConfirmDialogOpen(false);
    };

    // default gui settings. this syntax ensures that defaults are picked
    const {
        question: { text: questionText = DEFAULT_GUI_SETTINGS.questionText } = {
            text: DEFAULT_GUI_SETTINGS.questionText
        },
        box_line_color: boxLineColor = DEFAULT_GUI_SETTINGS.boxLineColor,
        box_line_width: boxLineWidth = DEFAULT_GUI_SETTINGS.boxLineWidth,
        zoom_level: zoomLevel = DEFAULT_GUI_SETTINGS.zoomLevel,
        slider_ranges: {
            dimension: sliderRangeDimension = DEFAULT_GUI_SETTINGS.sliderRangeDimension,
            rotation: sliderRangeRotation = DEFAULT_GUI_SETTINGS.sliderRangeRotation
        } = {
            dimension: DEFAULT_GUI_SETTINGS.sliderRangeDimension,
            rotation: DEFAULT_GUI_SETTINGS.sliderRangeRotation
        },
        slider_steps: {
            dimension: sliderStepDimension = DEFAULT_GUI_SETTINGS.sliderStepDimension,
            rotation: sliderStepRotation = DEFAULT_GUI_SETTINGS.sliderStepRotation
        } = {
            dimension: DEFAULT_GUI_SETTINGS.sliderStepDimension,
            rotation: DEFAULT_GUI_SETTINGS.sliderStepRotation
        },
        disabled_coordinate_axes: disabledCoordinateAxes = DEFAULT_GUI_SETTINGS.disabledCoordinateAxes,
        disabled_coordinate_axis_color: disabledCoordinateAxisColor = DEFAULT_GUI_SETTINGS.disabledCoordinateAxisColor,
        world_coordinates: worldCoordinates = DEFAULT_GUI_SETTINGS.worldCoordinates
    } = guiSettings;

    useEffect(() => {
        // reset between timestamps
        setZoomEnabled(false);

        // update trackablePose
        const timestamp = timestamps[timestampIdx];
        const timestampData = taskInput.timestamps[timestamp];
        let newTrackablePose = null;
        switch (timestampData.selected_trackable) {
            case "left":
                newTrackablePose = Pose3D.makePoseFromHtm(
                    timestampData.left_trackable_world_pose
                );
                break;
            case "right":
                newTrackablePose = Pose3D.makePoseFromHtm(
                    timestampData.right_trackable_world_pose
                );
                break;
            default:
                break;
        }
        setTrackablePose(newTrackablePose);

        // when in review phase:
        // - check whether the last timestamp has been reached
        // - if yes and output has changed: the review phase is done
        // - else: the review phase is not done
        if (
            isReviewStarted &&
            !isReviewDone &&
            timestampIdx === timestamps.length - 1 &&
            !taskUIContext
                .getCurrentGuiObject()
                .isInitialCurrentTaskOutput(currentTaskOutput)
        ) {
            setIsReviewDone(true);
        } else {
            setIsReviewDone(false);
        }

        // eslint-disable-next-line
    }, [currentTaskIdx, timestampIdx]);

    // events to handle whether the action indicator should be shown
    useEffect(() => {
        if (!mouseDown) {
            return;
        }
        const onMouseUp = () => {
            setMouseDown(false);
            if (mouseOverSlider && nextActionIndicatorName) {
                setActionIndicatorName(nextActionIndicatorName);
            } else {
                setActionIndicatorName(null);
            }
        };
        document.addEventListener("mouseup", onMouseUp);
        return () => document.removeEventListener("mouseup", onMouseUp);
    }, [mouseDown, mouseOverSlider, nextActionIndicatorName]);

    useEffect(() => {
        // handle the review is done state

        // when in review phase:
        // - check whether the last timestamp has been reached
        // - if yes and output has changed: the review phase is done
        // - else: the review phase is not done
        if (
            isReviewStarted &&
            !isReviewDone &&
            timestampIdx === timestamps.length - 1 &&
            !taskUIContext
                .getCurrentGuiObject()
                .isInitialCurrentTaskOutput(currentTaskOutput)
        ) {
            setIsReviewDone(true);
        } else {
            setIsReviewDone(false);
        }
    }, [currentTaskOutput]);

    const makeCameraView = (name, abbreviation) => {
        const timestamp = timestamps[timestampIdx];
        const timestampData = taskInput.timestamps[timestamp];
        const cameraViewParams = timestampData[abbreviation];
        const hmdWorldPose = timestampData.hmd_world_pose;

        return (
            <Col>
                <BoundingBoxFaceAdjustmentCameraView
                    key={cameraViewParams.image_url + "_" + name}
                    name={name}
                    hmdWorldPose={hmdWorldPose}
                    E={cameraViewParams.E}
                    K={cameraViewParams.K}
                    distortionCoeffs={cameraViewParams.distortion_coeffs}
                    image={resourceCache[cameraViewParams.image_url]}
                    boxUI={boxUI}
                    trackablePose={worldCoordinates ? null : trackablePose}
                    boxLineColor={boxLineColor}
                    boxLineWidth={boxLineWidth}
                    withZoom={zoomEnabled}
                    zoomLevel={zoomLevel}
                    actionIndicatorLineWidth={2}
                    actionIndicatorLineColor={"#F0F"}
                    actionIndicatorLineLength={10}
                    actionIndicatorName={actionIndicatorName}
                />
            </Col>
        );
    };

    const updateTaskOutput = () => {
        setCurrentTaskOutput(
            taskUIContext.getCurrentGuiObject().makeTaskOutputForCurrentTask({
                ...currentTaskOutput,
                box: BBox3DModel.makeBBox3DModelFromBoxUI(boxUI)
            })
        );
    };

    /**
     * Create slider to adjust one of the rotations of boxUI.
     * @param {String} dimension x, y or z
     */
    const makeDimensionSlider = dimension => {
        if (
            !["front", "back", "left", "right", "top", "bottom"].includes(
                dimension
            )
        ) {
            return null;
        }

        return (
            <Slider
                className="dimension_slider"
                min={sliderRangeDimension[0]}
                max={sliderRangeDimension[1]}
                step={sliderStepDimension}
                value={boxUI.boxDims[dimension]}
                marks={{
                    [sliderRangeDimension[0]]: "",
                    0: "",
                    [sliderRangeDimension[1]]: ""
                }}
                onChange={v => {
                    const boxDims = BoxDimensions.copy(boxUI.boxDims);
                    boxDims[dimension] = v;
                    setBoxUI({
                        ...boxUI,
                        boxDims: boxDims
                    });
                }}
                onAfterChange={() => {
                    updateTaskOutput();
                }}
            />
        );
    };

    const makeActionIndicatorEventHandlers = name => {
        return {
            onMouseDown: () => setMouseDown(true),
            onMouseEnter: () => {
                setMouseOverSlider(true);
                setNextActionIndicatorName(name);
                if (!mouseDown) {
                    setActionIndicatorName(name);
                }
            },
            onMouseLeave: () => {
                setMouseOverSlider(false);
                if (!mouseDown) {
                    setActionIndicatorName(null);
                }
            }
        };
    };

    /**
     * Create slider to adjust one of the rotations of boxUI.
     * @param {String} axis x, y or z
     */
    const makeRotationSlider = (axis, color) => {
        // same order as in boxUI.rotation
        const rotIdx = ["y", "x", "z"].indexOf(axis);
        if (rotIdx === -1) {
            return null;
        }
        let disabledAxis = false;
        if (disabledCoordinateAxes.includes(axis)) {
            disabledAxis = true;
        }
        const axisIdx = ["x", "y", "z"].indexOf(axis);
        return (
            <Slider
                className="rotation_slider"
                min={sliderRangeRotation[0]}
                max={sliderRangeRotation[1]}
                step={sliderStepRotation}
                value={rotationDeltas[axisIdx]}
                disabled={disabledAxis}
                trackStyle={{
                    backgroundColor: color
                }}
                onChange={v => {
                    const theta = v - rotationDeltas[axisIdx];

                    // the axis in box coordinates we want to rotate around
                    const u = [0, 0, 0];
                    u[axisIdx] = 1;
                    const boxPose = new Pose3D(
                        [...boxUI.position],
                        [...boxUI.rotation]
                    );
                    const rotatedBoxPose = boxPose.rotateAroundLocalVector(
                        u,
                        theta
                    );

                    setBoxUI({
                        ...boxUI,
                        rotation: [...rotatedBoxPose.rot]
                    });
                    const newDelta = [...rotationDeltas];
                    newDelta[axisIdx] = v;
                    setRotationDeltas(newDelta);
                }}
                onAfterChange={() => {
                    updateTaskOutput();
                }}
            />
        );
    };

    return (
        <>
            {/* hidden until necessary */}
            <AnnotationReviewDialog
                type={REVIEW_DIALOG_TYPE.BBOX_FACE_ADJUSTMENT_UI}
                taskInput={taskInput}
                currentTaskOutput={currentTaskOutput}
                // only allow to open when we haven't already started the review phase
                allowOpen={!isReviewStarted}
                onClose={onCloseReviewDialog}
                onStartReviewPhase={onStartReviewPhase}
                isOpen={isReviewDialogOpen}
            />
            {/* hidden until necessary */}
            <FinishConfirmDialog
                shouldShow={isFinishConfirmDialogOpen}
                onClose={onCloseFinishConfirmDialog}
            />
            <Row>
                <Col>
                    <h1 style={{ width: 0, minWidth: "100%" }}>
                        {questionText}
                    </h1>
                </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "center" }}>
                <TimelineSlider
                    timestampIdx={timestampIdx}
                    setTimestampIdx={setTimestampIdx}
                    timestampsLen={timestamps.length}
                    disabled={isReviewDialogOpen || isFinishConfirmDialogOpen}
                    withHotKeys
                >
                    <Row>
                        <Col>
                            <Row>
                                {makeCameraView("top_left", "tl")}
                                {makeCameraView("top_right", "tr")}
                            </Row>
                            <Row style={{ marginTop: "0.6rem" }}>
                                {makeCameraView("bottom_left", "bl")}
                                {makeCameraView("bottom_right", "br")}
                            </Row>
                        </Col>
                    </Row>
                </TimelineSlider>
            </Row>
            <Row style={{ marginBottom: "0.3rem" }}>
                <Col
                    {...makeActionIndicatorEventHandlers(
                        ACTION_INDICATOR.DIMENSION.HEIGHT
                    )}
                >
                    <Row style={{ marginTop: "0.1rem" }}>
                        <Col>
                            <h6 className="bbox_face_adjustment_slider_heading">
                                Top / Bottom
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>{makeDimensionSlider("top", "#0F0")}</Col>
                    </Row>
                    <Row>
                        <Col>{makeDimensionSlider("bottom", "#F00")}</Col>
                    </Row>
                </Col>
                <Col
                    {...makeActionIndicatorEventHandlers(
                        ACTION_INDICATOR.DIMENSION.LENGTH
                    )}
                >
                    <Row style={{ marginTop: "0.1rem" }}>
                        <Col>
                            <h6 className="bbox_face_adjustment_slider_heading">
                                Front / Back
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>{makeDimensionSlider("front")}</Col>
                    </Row>
                    <Row>
                        <Col>{makeDimensionSlider("back")}</Col>
                    </Row>
                </Col>
                <Col
                    {...makeActionIndicatorEventHandlers(
                        ACTION_INDICATOR.DIMENSION.WIDTH
                    )}
                >
                    <Row style={{ marginTop: "0.1rem" }}>
                        <Col>
                            <h6 className="bbox_face_adjustment_slider_heading">
                                Right / Left
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>{makeDimensionSlider("right")}</Col>
                    </Row>
                    <Row>
                        <Col>{makeDimensionSlider("left")}</Col>
                    </Row>
                </Col>
            </Row>
            <Row
                style={{
                    borderTop: "1px solid #96dbfa",
                    paddingTop: "5px",
                    marginTop: "10px"
                }}
            >
                <Col>
                    <div className="rotation_slider_description">
                        Rotate around the{" "}
                        <span style={{ color: "#00F" }}>blue line</span>
                    </div>
                    {makeRotationSlider("z", "#00F")}
                </Col>
                <Col>
                    <div className="rotation_slider_description">
                        Rotate around the{" "}
                        <span style={{ color: "#080" }}>green line</span>
                    </div>
                    {makeRotationSlider("y", "#0F0")}
                </Col>
                <Col>
                    <div className="rotation_slider_description">
                        Rotate around the{" "}
                        <span style={{ color: "#F00" }}>red line</span>
                    </div>
                    {makeRotationSlider("x", "#F00")}
                </Col>
            </Row>
            <Row style={{ marginTop: "1.6rem" }}>
                <MetaActionButtons
                    taskUIContext={taskUIContext}
                    currentTaskIdx={currentTaskIdx}
                />
                <Col
                    style={{ justifyContent: "flex-end" }}
                    className="button_col"
                >
                    <Button
                        className="button is-sm no_margin"
                        onClick={() => {
                            const newBoxUI = initialCurrentTaskOutput.box.makeUITypes();
                            setBoxUI(newBoxUI);
                            setCurrentTaskOutput(
                                taskUIContext
                                    .getCurrentGuiObject()
                                    .makeTaskOutputForCurrentTask({
                                        ...currentTaskOutput,
                                        box: BBox3DModel.makeBBox3DModelFromBoxUI(
                                            newBoxUI
                                        )
                                    })
                            );
                            setRotationDeltas([0, 0, 0]);
                        }}
                    >
                        Reset
                    </Button>
                </Col>
                <Col
                    style={{ justifyContent: "flex-start" }}
                    className="button_col"
                >
                    <ActionButtonNextOrSubmit
                        taskUIContext={taskUIContext}
                        disabled={
                            // button should be disabled:
                            // - before review phase: as long as nothing was changed
                            // - in review phase: until review phase is done
                            isReviewStarted
                                ? !isReviewDone
                                : taskUIContext
                                      .getCurrentGuiObject()
                                      .isInitialCurrentTaskOutput(
                                          currentTaskOutput
                                      )
                        }
                        actionSuffix="finish"
                        className="is-primary"
                        onClick={() => {
                            if (isReviewStarted) {
                                setIsFinishConfirmDialogOpen(true);
                            } else {
                                onOpenReviewDialog(true);
                            }
                        }}
                    >
                        Finish
                    </ActionButtonNextOrSubmit>
                </Col>
            </Row>
        </>
    );
};

export default BoundingBoxFaceAdjustmentInCameraViewsWidget;
