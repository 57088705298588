import Ack from "../../lib/ApiClient/models/Ack";
import GroupAck from "../../lib/ApiClient/models/GroupAck";

export class MockApiClient {
    /**
     * Sets up the backend response for getTaskGroup and submitTaskGroupResults.
     *
     * @param {Object} guiSettings
     * @param {String} guiType
     * @param {Object[]} taskInputs
     * @param {Boolean=} shouldFail default false
     */
    constructor(guiSettings, guiType, taskInputs, shouldFail = false) {
        this.shouldFail = shouldFail;

        const acks = taskInputs.map(
            (ti, i) => new Ack("ACK_KEY_" + i, "TASK_INPUT_ID_" + i)
        );
        const hasTasks = acks.length > 0;
        const groupAck = new GroupAck(acks, "PROJECT_NODE_ID", "USER_ID");
        this.getTaskGroupResult = {
            group_ack: groupAck,
            gui_settings: guiSettings,
            gui_type: guiType,
            task_inputs: taskInputs,
            has_tasks: hasTasks,
            user_is_blocked: false,
            submit_deadline: null,
            blocked_until: "2020-08-12T11:17:53.668015741Z",
            blocking_reason: ""
        };

        this.submitResult = {
            n_acknowledged: taskInputs.length,
            n_not_acknowledged: 0
        };
    }

    /**
     * @param {Boolean} shouldFail
     */
    setShouldFail(shouldFail) {
        this.shouldFail = shouldFail;
    }

    failedMockRequest() {
        throw new Error("mock request failed");
    }

    /**
     * @param {Object} data
     * @returns {Promise<Object>}
     */
    returnPromisedDataOrThrowErrorInPromise(data) {
        return new Promise(resolve => {
            if (this.shouldFail) {
                this.failedMockRequest();
            }
            resolve(data);
        });
    }

    /**
     * @returns {Promise<Object>}
     */
    getTaskGroup() {
        return this.returnPromisedDataOrThrowErrorInPromise(
            this.getTaskGroupResult
        );
    }

    /**
     * @param {import("../../lib/ApiClient/models/SubmitTaskGroupRequest").default} submitTaskGroupRequest
     * @returns {Promise<Object>}
     */
    submitTaskGroupResults(submitTaskGroupRequest) {
        return this.returnPromisedDataOrThrowErrorInPromise(this.submitResult);
    }
}

export default MockApiClient;
