// eslint-disable-next-line
import { Scene3D } from "./Scene3D";
// eslint-disable-next-line
import { Pose3D } from "./Pose3D";

export class Renderable {
    /**
     * @param {Pose3D} poseWorld
     */
    constructor(poseWorld) {
        this.poseWorld = poseWorld;
        this.points = [];
        this.projectedPoints = [];
        this.shouldRender = true;
        this.shouldReProject = true;
        this.scene = null;
    }

    /**
     * If true: the object will enter the reproject and render phase
     * If false: the object's points won't be reprojected or rendered
     * @param {Boolean} shouldRender
     */
    setShouldRender(shouldRender) {
        this.shouldRender = shouldRender;
    }

    /**
     * If true: the object's points will be reprojected on next render and
     * then rendered
     * If false: the object's points won't be reprojected but rendered
     * @param {Boolean} shouldReProject
     */
    setShouldReProject(shouldReProject) {
        this.shouldReProject = shouldReProject;
    }

    /**
     *
     * @param {Array} projectedPoints
     */
    setProjectedPoints(projectedPoints) {
        this.projectedPoints = projectedPoints;
    }

    /**
     * @param {Scene3D} scene
     */
    setScene(scene) {
        this.scene = scene;
    }

    /**
     * @returns {Number[][]}
     */
    getPoints() {
        return this.getObjectPoints();
    }

    /**
     * Default implementation.
     *
     * The implementing class should know how to update its points.
     * The implementing class shouldn't just update its point array reference but
     * should update the points in the array!
     * The ordering of the points is the same that is returned by getObjectPoints.
     * It's also up to the implementing class to decide whether or
     * not the points need reprojection or not.
     * @param {Number[][]} updatedPoints
     */
    updatePoints(updatedPoints) {
        // keeps the old points array reference and
        // only switches out the point arrays themselves
        for (let i = 0; i < this.points.length; i++) {
            this.points[i] = updatedPoints[i];
        }
        this.setShouldReProject(true);
    }

    /**
     * Applies a Pose to the points in their local coordinate system.
     * --> Will edit the points in-place!
     * The world Pose of the Renderable isn't changed, but its points are translated
     * and rotated with respect to its world Pose.
     * @param {Pose3D} pose create the pose with translationFirst = true for expected behaviour
     */
    applyLocalTransformation(pose) {
        const updatedPoints = [];
        for (const point of this.getPoints()) {
            updatedPoints.push(pose.applyTo(point));
        }
        this.updatePoints(updatedPoints);
    }

    /**
     * Abstract
     * The implementing class should only return a 1-D array of all the points
     * that need to be projected. The implementing class should know how to transform
     * that 1-D array back to a structure that it can use.
     * @returns {Number[][]}
     */
    getObjectPoints() {
        throw new Error("Method needs implementation");
    }
}

export class CanvasRenderable extends Renderable {
    /**
     * Abstract
     * @param {CanvasRenderingContext2D} ctx
     */
    renderToCanvas(ctx) {
        throw new Error("Method needs implementation");
    }
}

/**
 * @typedef {Object} PointcloudRenderableRenderSettings
 * @property {Number} radius
 * @property {Boolean=} useColorMap default false
 * @property {ColorMap=} colorMap
 * @property {String=} fillStyle only when no color map is being used
 * @property {String=} strokeStyle only when no color map is being used and lineWidth is also specified. stroke/outline color
 * @property {Number=} lineWidth only when no color map is being used and strokeStyle. stroke/outline line width
 */

export class PointcloudRenderable extends CanvasRenderable {
    /**
     * @param {Pose3D} poseWorld
     * @param {Number[][]} points
     * @param {PointcloudRenderableRenderSettings} renderSettings
     */
    constructor(poseWorld, points, renderSettings) {
        super(poseWorld);
        this.points = points;
        this.renderSettings = renderSettings;
        this.mappedColors = [];

        if (this.renderSettings.useColorMap) {
            this.initColorMap();
        }
    }

    static PI_2 = 2 * Math.PI;

    /**
     * @param {CanvasRenderingContext2D} ctx
     * @param {Number[]} points
     * @param {Number} radius
     * @param {String} fillStyle
     */
    static drawCirclesWithMappedColors(ctx, points, radius, colors) {
        for (let i = 0; i < points.length; i++) {
            const point = points[i];
            if (
                point === null ||
                point[0] < 0 ||
                point[0] > ctx.width ||
                point[1] < 0 ||
                point[1] > ctx.height
            ) {
                continue;
            }
            ctx.fillStyle = colors[i];
            ctx.beginPath();
            ctx.arc(point[0], point[1], radius, 0, PointcloudRenderable.PI_2);
            ctx.fill();
        }
    }

    /**
     * @param {CanvasRenderingContext2D} ctx
     * @param {Number[]} points
     * @param {Number} radius
     * @param {String} fillStyle
     */
    static drawCirclesWithOneColor(ctx, points, radius, fillStyle) {
        ctx.beginPath();
        ctx.fillStyle = fillStyle;
        for (const point of points) {
            if (
                point === null ||
                point[0] < 0 ||
                point[0] > ctx.width ||
                point[1] < 0 ||
                point[1] > ctx.height
            ) {
                continue;
            }
            ctx.moveTo(point[0], point[1]);
            ctx.arc(point[0], point[1], radius, 0, PointcloudRenderable.PI_2);
        }
        ctx.fill();
    }

    /**
     * @param {CanvasRenderingContext2D} ctx
     * @param {Number[]} points
     * @param {Number} radius
     * @param {String} fillStyle
     * @param {String} strokeStyle
     * @param {Number} lineWidth
     */
    static drawCirclesWithOneColorAndOutline(
        ctx,
        points,
        radius,
        fillStyle,
        strokeStyle,
        lineWidth
    ) {
        ctx.fillStyle = fillStyle;
        ctx.strokeStyle = strokeStyle;
        ctx.lineWidth = lineWidth;
        for (const point of points) {
            if (
                point === null ||
                point[0] < 0 ||
                point[0] > ctx.width ||
                point[1] < 0 ||
                point[1] > ctx.height
            ) {
                continue;
            }
            ctx.beginPath();
            ctx.arc(point[0], point[1], radius, 0, PointcloudRenderable.PI_2);
            ctx.fill();
            ctx.stroke();
        }
    }

    initColorMap() {
        console.time("calc_color_map");
        this.mappedColors = this.renderSettings.colorMap.getColorsForPoints(
            this.points
        );
        console.timeEnd("calc_color_map");
    }

    /**
     * Implements an abstract method
     */
    getObjectPoints() {
        return this.points;
    }

    /**
     * Implements an abstract method
     * @param {CanvasRenderingContext2D} ctx
     */
    renderToCanvas(ctx) {
        const drawMethod = this.getDrawMethod(ctx);
        ctx.save();
        drawMethod();
        ctx.restore();
    }

    /**
     * @param {CanvasRenderingContext2D} ctx
     * @returns {() => {}} A wrapped draw method that contains all dependencies.
     */
    getDrawMethod(ctx) {
        if (this.renderSettings.useColorMap) {
            return () =>
                PointcloudRenderable.drawCirclesWithMappedColors(
                    ctx,
                    this.projectedPoints,
                    this.renderSettings.radius,
                    this.mappedColors
                );
        }

        if (this.renderSettings.strokeStyle && this.renderSettings.lineWidth) {
            return () =>
                PointcloudRenderable.drawCirclesWithOneColorAndOutline(
                    ctx,
                    this.projectedPoints,
                    this.renderSettings.radius,
                    this.renderSettings.fillStyle,
                    this.renderSettings.strokeStyle,
                    this.renderSettings.lineWidth
                );
        }

        return () =>
            PointcloudRenderable.drawCirclesWithOneColor(
                ctx,
                this.projectedPoints,
                this.renderSettings.radius,
                this.renderSettings.fillStyle
            );
    }
}

/**
 * @typedef {Object} LinesRenderableRenderSettings
 * @property {Number} lineWidth
 * @property {String} strokeStyle
 */

export class LinesRenderable extends CanvasRenderable {
    /**
     * If there's only on render setting, it will be applied to all lines
     *
     * @param {Pose3D} poseWorld
     * @param {Number[][][]} lines array of lines. each line consists of two points
     * @param {LinesRenderableRenderSettings[]} renderSettings one setting or array of settings. one setting per line
     */
    constructor(poseWorld, lines, renderSettings) {
        super(poseWorld);
        this.init(lines, renderSettings, true);
    }

    /**
     * @param {Number[][][]} lines
     * @returns {Number[][]}
     */
    static getPointsFromLines(lines) {
        const points = [];
        for (const line of lines) {
            points.push(line[0], line[1]);
        }
        return points;
    }

    /**
     * @param {Number[][]} points
     * @returns {Number[][][]}
     */
    static getLinesFromPoints(points) {
        const projectedLines = [];
        for (let i = 0; i < points.length; i += 2) {
            projectedLines.push([points[i], points[i + 1]]);
        }
        return projectedLines;
    }

    /**
     * @param {CanvasRenderingContext2D} ctx
     * @param {Number[][][]} lines
     * @param {LinesRenderableRenderSettings[]} renderSettings
     */
    static drawLines(ctx, lines, renderSettings) {
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            // don't draw when the renderer didn't project the line
            if (line[0] === null || line[1] === null) {
                continue;
            }
            const settings = renderSettings[i];
            ctx.beginPath();
            ctx.lineWidth = settings.lineWidth;
            ctx.strokeStyle = settings.strokeStyle;
            ctx.moveTo(line[0][0], line[0][1]);
            ctx.lineTo(line[1][0], line[1][1]);
            ctx.stroke();
        }
    }

    init(lines, renderSettings, internal = false) {
        this.lines = lines;
        this.points = LinesRenderable.getPointsFromLines(this.lines);

        // can omit renderSettings when calling init from outside
        if (!internal && renderSettings === undefined) {
            renderSettings = this.renderSettings;
        }
        this.initRenderSettings(renderSettings);

        if (this.lines.length !== this.renderSettings.length) {
            console.error(
                "Mismatch between amount of lines and line render settings!" +
                    " Provide exactly one setting that will be applied to all lines or one explicit setting per line!",
                this.lines.length,
                this.renderSettings.length
            );
        }
    }

    /**
     * @param {LinesRenderableRenderSettings} renderSettings
     */
    initRenderSettings(renderSettings) {
        if (!Array.isArray(renderSettings)) {
            this.renderSettings = [renderSettings];
        } else {
            this.renderSettings = renderSettings;
        }

        if (this.renderSettings.length === 1) {
            this.renderSettings = [];
            this.lines.forEach(() => {
                this.renderSettings.push(renderSettings);
            });
        }
    }

    /**
     * Implements an abstract method
     */
    getObjectPoints() {
        return this.points;
    }

    /**
     * Override
     * @param {Number[][]} updatedPoints
     */
    updatePoints(updatedPoints) {
        super.updatePoints(updatedPoints);

        // also update the lines array
        // keeps the old lines array reference and
        // only switches out the line arrays themselves
        const updatedLines = LinesRenderable.getLinesFromPoints(this.points);
        for (let i = 0; i < updatedLines.length; i++) {
            this.lines[i] = updatedLines[i];
        }
    }

    /**
     * Implements an abstract method
     * @param {CanvasRenderingContext2D} ctx
     */
    renderToCanvas(ctx) {
        ctx.save();
        const projectedLines = LinesRenderable.getLinesFromPoints(
            this.projectedPoints
        );
        LinesRenderable.drawLines(ctx, projectedLines, this.renderSettings);
        ctx.restore();
    }
}

/**
 * Draws bounding box with approximated view-dependent distortion
 * @typedef {Object} LinesRenderableRenderSettings
 * @property {Number} lineWidth
 * @property {String} strokeStyle
 */

export class CurvesRenderable extends CanvasRenderable {
    /**
     * If there's only on render setting, it will be applied to all lines
     *
     * @param {Pose3D} poseWorld
     * @param {Number[][][]} curves array of curves. each curve consists of four points
     * @param {LinesRenderableRenderSettings[]} renderSettings one setting or array of settings. one setting per curve
     */
    constructor(poseWorld, curves, renderSettings) {
        super(poseWorld);
        this.init(curves, renderSettings, true);
    }

    /**
     * @param {Number[][][]} curves
     * @returns {Number[][]}
     */
    static getPointsFromCurves(curves) {
        const points = [];
        for (const curve of curves) {
            for (let i = 0; i < curve.length; i++) {
                points.push(curve[i]);
            }
        }
        return points;
    }

    /**
     * @param {Number[][]} points
     * @returns {Number[][][]}
     */
    static getCurvesFromPoints(points) {
        const projectedCurves = [];
        for (let i = 0; i < points.length; i += 9) {
            projectedCurves.push([
                points[i],
                points[i + 1],
                points[i + 2],
                points[i + 3],
                points[i + 4],
                points[i + 5],
                points[i + 6],
                points[i + 7],
                points[i + 8]
            ]);
        }
        return projectedCurves;
    }

    /**
     * Adapted from https://stackoverflow.com/questions/7054272/how-to-draw-smooth-curve-through-n-points-using-javascript-html5-canvas
     * See "Foundation ActionScript 3.0 Animation: Making things move". p.95  for detailed explanation
     * Expects that
     * @param {CanvasRenderingContext2D} ctx
     * @param {Number[][][]} curves
     * @param {LinesRenderableRenderSettings[]} renderSettings
     */
    static drawCurves(ctx, curves, renderSettings) {
        for (let i = 0; i < curves.length; i++) {
            const curve = curves[i];

            // Don't try to render when projection didn't project the line.
            // This can happen e.g. when a point of the line is behind the camera.
            if (!curve[0]) {
                return;
            }

            const settings = renderSettings[i];
            ctx.beginPath();
            ctx.lineWidth = settings.lineWidth;
            ctx.strokeStyle = settings.strokeStyle;

            // move to the first point
            ctx.moveTo(curve[0][0], curve[0][1]);
            let j;
            for (j = 1; j < curve.length - 2; j++) {
                if (!curve[j] || !curve[j + 1]) {
                    continue;
                }
                const xc = (curve[j][0] + curve[j + 1][0]) / 2;
                const yc = (curve[j][1] + curve[j + 1][1]) / 2;
                ctx.quadraticCurveTo(curve[j][0], curve[j][1], xc, yc);
            }

            // curve through the last two points
            if (!curve[j] || !curve[j + 1]) {
                break;
            }
            ctx.quadraticCurveTo(
                curve[j][0],
                curve[j][1],
                curve[j + 1][0],
                curve[j + 1][1]
            );
            ctx.stroke();
        }
    }

    init(curves, renderSettings, internal = false) {
        this.curves = curves;
        this.points = CurvesRenderable.getPointsFromCurves(this.curves);

        // can omit renderSettings when calling init from outside
        if (!internal && renderSettings === undefined) {
            renderSettings = this.renderSettings;
        }
        this.initRenderSettings(renderSettings);

        if (this.curves.length !== this.renderSettings.length) {
            console.error(
                "Mismatch between amount of curves and line render settings!" +
                    " Provide exactly one setting that will be applied to all curves or one explicit setting per curve!",
                this.curves.length,
                this.renderSettings.length
            );
        }
    }

    /**
     * @param {LinesRenderableRenderSettings} renderSettings
     */
    initRenderSettings(renderSettings) {
        if (!Array.isArray(renderSettings)) {
            this.renderSettings = [renderSettings];
        } else {
            this.renderSettings = renderSettings;
        }

        if (this.renderSettings.length === 1) {
            this.renderSettings = [];
            this.curves.forEach(() => {
                this.renderSettings.push(renderSettings);
            });
        }
    }

    /**
     * Implements an abstract method
     */
    getObjectPoints() {
        return this.points;
    }

    /**
     * Override
     * @param {Number[][]} updatedPoints
     */
    updatePoints(updatedPoints) {
        super.updatePoints(updatedPoints);

        // also update the curves array
        // keeps the old curves array reference and
        // only switches out the curve arrays themselves
        const updatedCurves = CurvesRenderable.getCurvesFromPoints(this.points);
        for (let i = 0; i < updatedCurves.length; i++) {
            this.curves[i] = updatedCurves[i];
        }
    }

    /**
     * Implements an abstract method
     * @param {CanvasRenderingContext2D} ctx
     */
    renderToCanvas(ctx) {
        ctx.save();
        const projectedCurves = CurvesRenderable.getCurvesFromPoints(
            this.projectedPoints
        );
        CurvesRenderable.drawCurves(ctx, projectedCurves, this.renderSettings);
        ctx.restore();
    }
}
