import React from "react";
import { useState, useEffect } from "react";
import { LoadingAnimation } from "../../components/LoadingAnimation/LoadingAnimation";

import SpotifyAudioPlayer from "../../components/SpotifyAudioPlayer/SpotifyAudioPlayer";

/**
 *
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const AudioPlayers = ({
    taskUIContext,
    currentTaskIdx,
    currentTaskOutput,
    setCurrentTaskOutput
}) => {
    // important: don't use record_id for discerning records here,
    // because it could happen that both record_0 and record_1 have the same record_id!
    // --> use record_0 and record_1 as strings
    const [currentlyPlayingRecord, setCurrentlyPlayingRecord] = useState(null);

    /**
     * @type {import("../../lib/DataModels/AudioSimilarityTaskInput").AudioSimilarityTaskInput}
     */
    const taskInput = taskUIContext.getCurrentTaskInput();
    const guiObject = taskUIContext.getCurrentGuiObject();

    // reset currently playing state on task change
    useEffect(() => {
        setCurrentlyPlayingRecord(null);
    }, [currentTaskIdx]);

    const handlePlaybackStarted = recordNumber => {
        setCurrentlyPlayingRecord(recordNumber);
        const newCurrentTaskOutput = { ...currentTaskOutput };
        newCurrentTaskOutput[recordNumber].played_cnt++;
        setCurrentTaskOutput(
            guiObject.makeTaskOutputForCurrentTask(newCurrentTaskOutput)
        );
    };
    const handlePlayBackStopped = recordNumber => {
        setCurrentlyPlayingRecord(null);
        const newCurrentTaskOutput = { ...currentTaskOutput };
        newCurrentTaskOutput[recordNumber].stopped_cnt++;
        setCurrentTaskOutput(
            guiObject.makeTaskOutputForCurrentTask(newCurrentTaskOutput)
        );
    };
    const handleRecordFullyPlayed = recordNumber => {
        setCurrentlyPlayingRecord(null);
        const newCurrentTaskOutput = { ...currentTaskOutput };
        newCurrentTaskOutput[recordNumber].fully_played_cnt++;
        setCurrentTaskOutput(
            guiObject.makeTaskOutputForCurrentTask(newCurrentTaskOutput)
        );
    };

    if (
        !taskUIContext
            .getCurrentGuiObject()
            .taskInputResourcesInCache(currentTaskIdx)
    ) {
        return <LoadingAnimation />;
    }

    taskUIContext.onViewReady();
    return (
        <div style={{ display: "flex", margin: "20px", gap: "50px" }}>
            <div style={{ width: "100%" }}>
                <h3>Track A</h3>
                <SpotifyAudioPlayer
                    id="spotify_audioplayer_0"
                    key={"record_0_" + currentTaskIdx}
                    onPlaybackStarted={() => handlePlaybackStarted("record_0")}
                    onPlayBackStopped={() => handlePlayBackStopped("record_0")}
                    onRecordFullyPlayed={() =>
                        handleRecordFullyPlayed("record_0")
                    }
                    record={taskInput.record_0}
                    // disabled while the other is playing
                    disabledPlayButton={currentlyPlayingRecord === "record_1"}
                />
            </div>
            <div style={{ width: "100%" }}>
                <h3>Track B</h3>
                <SpotifyAudioPlayer
                    id="spotify_audioplayer_1"
                    key={"record_1_" + currentTaskIdx}
                    onPlaybackStarted={() => handlePlaybackStarted("record_1")}
                    onPlayBackStopped={() => handlePlayBackStopped("record_1")}
                    onRecordFullyPlayed={() =>
                        handleRecordFullyPlayed("record_1")
                    }
                    record={taskInput.record_1}
                    // disabled while the other is playing
                    disabledPlayButton={currentlyPlayingRecord === "record_0"}
                />
            </div>
        </div>
    );
};

export default AudioPlayers;
