import React from "react";

/**
 * Draws a rectangle from the topLeftPos to the botRightPos.
 * Styles like background color or opacity can be overridden.
 */
export const RectDiv = ({ topLeftPos, botRightPos, style = {}, ...rest }) => {
    const overriddenStyle = {
        position: "absolute",
        left: topLeftPos.x,
        top: topLeftPos.y,
        width: botRightPos.x - topLeftPos.x,
        height: botRightPos.y - topLeftPos.y,
        background: "#F00",
        opacity: 0.2,
        border: "2px solid black",
        ...style
    };
    return <div {...rest} style={overriddenStyle}></div>;
};

export default RectDiv;
