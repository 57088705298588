import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getTaskFetchRequest } from "../../store/reducers/taskFetchRequest";
import { getCurrentTaskIdx } from "../../store/reducers/currentTaskIdx";
import { getGuiSettings } from "../../store/reducers/guiSettings";
import { getTasksAmount } from "../../store/reducers/tasksAmount";
import { getTimestamp } from "../../store/reducers/timestamp";
import { getTaskOutputs } from "../../store/reducers/answers";
import {
    getResourceCache,
    getResourceLoadingErrors
} from "../../store/reducers/resourceCache";
import {
    captureTimestamp,
    setNextGuiType,
    pushTaskOutput,
    nextTask,
    setTaskResult,
    popTaskOutput,
    previousTask,
    addResourceToCache,
    setCurrentTaskOutput,
    addResourceLoadingError,
    setTaskSubmitEnabled
} from "../../store/actions";
import { getTaskResult } from "../../store/reducers/taskResult";
import { getGuiType } from "../../store/reducers/guiType";
import { getTaskSubmitRequest } from "../../store/reducers/taskSubmitRequest";
import { getCurrentTaskOutput } from "../../store/reducers/currentTaskOutput";
import { getTaskSubmitEnabled } from "../../store/reducers/taskSubmitEnabled";

const TaskUIViewWrapper = props => {
    const { render: InnerComponent, innerProps, ...outerProps } = props;
    return <InnerComponent {...outerProps} {...innerProps} />;
};

const mapStateToProps = state => ({
    taskFetchRequest: getTaskFetchRequest(state),
    taskResult: getTaskResult(state),
    currentTaskIdx: getCurrentTaskIdx(state),
    tasksAmount: getTasksAmount(state),
    guiSettings: getGuiSettings(state),
    timestamp: getTimestamp(state),
    taskOutputs: getTaskOutputs(state),
    currentTaskOutput: getCurrentTaskOutput(state),
    resourceCache: getResourceCache(state),
    resourceLoadingErrors: getResourceLoadingErrors(state),
    guiType: getGuiType(state),
    taskSubmitRequest: getTaskSubmitRequest(state),
    taskSubmitEnabled: getTaskSubmitEnabled(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            captureTimestamp: captureTimestamp,
            setCurrentTaskOutput: setCurrentTaskOutput,
            pushTaskOutput: pushTaskOutput,
            popTaskOutput: popTaskOutput,
            nextTask: nextTask,
            previousTask: previousTask,
            setSubmitResultsObject: setTaskResult,
            setNextGuiType: setNextGuiType,
            addResourceToCache: addResourceToCache,
            addResourceLoadingError: addResourceLoadingError,
            setTaskSubmitEnabled: setTaskSubmitEnabled
        },
        dispatch
    );

const ConnectedTaskUIViewWrapper = connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskUIViewWrapper);

/**
 * @typedef ConnectedTaskUIViewProps
 * @property {import("../../lib/TaskUIStrategy/taskUIContext").default} taskUIContext can be set but not always
 * @property {Object} taskFetchRequest
 * @property {Object} taskResult
 * @property {number} currentTaskIdx
 * @property {number} tasksAmount
 * @property {number} timestamp
 * @property {Object} guiSettings
 * @property {Array} taskOutputs
 * @property {Object | Null} currentTaskOutput
 * @property {Object} globalState
 * @property {Object} resourceCache
 * @property {string} guiType
 * @property {Object} taskSubmitRequest
 * @property {Boolean} taskSubmitEnabled
 * @property {Function} captureTimestamp
 * @property {Function} setCurrentTaskOutput
 * @property {Function} pushTaskOutput
 * @property {Function} popTaskOutput
 * @property {Function} nextTask
 * @property {Function} previousTask
 * @property {Function} setSubmitResultsObject
 * @property {Function} setNextGuiType
 * @property {Function} addResourceToCache
 * @property {Function} addResourceLoadingError
 * @property {Function} setTaskSubmitEnabled
 */

const addTaskUIProps = WrappedComponent => {
    return props => (
        <ConnectedTaskUIViewWrapper
            render={WrappedComponent}
            innerProps={{ ...props }}
        />
    );
};

export default addTaskUIProps;
