class TimeKeeper {
    constructor() {
        /**
         * @type {Map<String, Number>}
         */
        this.startTimes = new Map();
        /**
         * @type {Map<String, Number>}
         */
        this.endTimes = new Map();
    }

    getTimestampMillis() {
        return Date.now();
    }

    /**
     * @param {String} name
     * @returns {Number}
     */
    startFor(name) {
        const ts = this.getTimestampMillis();
        this.startTimes.set(name, ts);
        return ts;
    }

    /**
     * @param {String} name
     * @returns {Number}
     */
    endFor(name) {
        const ts = this.getTimestampMillis();
        this.endTimes.set(name, ts);
        if (!this.startTimes.has(name)) {
            console.warn(`No start timestamp exists for "${name}"`);
        }
        return ts;
    }

    /**
     * @param {String} name
     * @returns {Number}
     */
    endForAndMeasure(name) {
        const ts = this.getTimestampMillis();
        this.endTimes.set(name, ts);
        return this.measure(name);
    }

    /**
     * @param {String} name
     * @returns {Number}
     */
    measure(name) {
        if (!this.startTimes.has(name)) {
            throw new Error(`No start timestamp exists for "${name}"`);
        }
        if (!this.endTimes.has(name)) {
            throw new Error(`No end timestamp exists for "${name}"`);
        }
        return this.endTimes.get(name) - this.startTimes.get(name);
    }
}

export default TimeKeeper;
