import { ActionTypes } from "../actions";
import generateReducer from "../generateReducer";

export const taskResultReducer = generateReducer(null, {
    [ActionTypes.SET_TASK_RESULT]: (taskResult, action) => {
        return action.taskResult;
    }
});

export const getTaskResult = state => state.taskResult;
