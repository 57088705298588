/**
 * Clips a value
 * @param {number} value initial value
 * @param {number} lower lower border
 * @param {number} upper upper border
 * @returns {number} clipped value
 */
export const clip = (value, lower, upper) =>
    Math.min(Math.max(value, lower), upper);

/**
 * Zip adjacent elements of an array.
 *
 * @param {any[]} lst Array of elements, commonly a flat list of elements.
 * @param {bool}  warparound Whether to wrap around when zipping elements. `true` results
 *                in the final value of the result being the combination of the
 *                input's last and the input's first value.
 * @param {number} end last index (optional)
 * @returns {any[]} zipped list
 */
export const zip = (lst, wraparound, end) => {
    if (lst.length < 2) return [];
    const result = [];
    wraparound = typeof wraparound === "undefined" ? false : wraparound;
    end = typeof end !== "undefined" ? end : lst.length - 1;
    if (end < 0) {
        end = lst.length - 1 + end;
    }
    let ix;
    for (ix = 0; ix < end; ix++) {
        result.push([lst[ix], lst[ix + 1]]);
    }
    if (wraparound) {
        result.push([lst[ix], lst[0]]);
    }
    return result;
};

/**
 * Get the click or touch location within the SVG which fired the event.
 *
 * @param {SVGElement} svg
 * @param {MouseEvent} event
 * @returns {{x: number, y: number}}
 */
export const locInSvg = (svg, event) => {
    const pos = event.touches
        ? { x: event.touches[0].clientX, y: event.touches[0].clientY }
        : { x: event.clientX, y: event.clientY };
    let pt = svg.createSVGPoint();
    pt.x = pos.x;
    pt.y = pos.y;
    const { x, y } = pt.matrixTransform(svg.getScreenCTM().inverse());
    return { x, y };
};

/**
 * Get the click or touch location within the given html element.
 *
 * @param {HTMLDivElement} elem
 * @param {MouseEvent} event
 * @returns {{x: number, y: number}}
 */
export const locInElem = (elem, event) => {
    const pos = event.touches
        ? { x: event.touches[0].clientX, y: event.touches[0].clientY }
        : { x: event.clientX, y: event.clientY };
    const rect = elem.getBoundingClientRect();
    const x = pos.x - rect.left;
    const y = pos.y - rect.top;
    return { x, y };
};
