import { Col } from "react-bootstrap";
import {
    ActionButtonCantSolve,
    ActionButtonCorruptData,
    ActionButtonOpenInstructions
} from "../../../components/ActionButton/ActionButton";

export const MetaActionButtons = ({ taskUIContext, currentTaskIdx }) => {
    // same disabled button behavior like DefaultMetaActionButtonGroup
    const disabledMetaActionButtons = taskUIContext.shouldActionButtonsBeDisabled();
    const disabledCorruptData = !taskUIContext
        .getCurrentGuiObject()
        .taskInputResourcesLoadedWithError(currentTaskIdx);

    return (
        <>
            <Col className="button_col">
                <ActionButtonCantSolve
                    taskUIContext={taskUIContext}
                    disabled={disabledMetaActionButtons}
                    className="no_margin"
                />
            </Col>
            <Col className="button_col">
                <ActionButtonCorruptData
                    taskUIContext={taskUIContext}
                    disabled={disabledMetaActionButtons && disabledCorruptData}
                    className="no_margin"
                />
            </Col>
            {taskUIContext.hasInstructions() ? (
                <Col className="button_col">
                    <ActionButtonOpenInstructions
                        taskUIContext={taskUIContext}
                        disabled={disabledMetaActionButtons}
                        className="no_margin"
                    />
                </Col>
            ) : null}
        </>
    );
};
