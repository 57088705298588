import React from "react";

export const BBoxLabel = props => {
    const box = props.box;
    const labelHeight = props.labelHeight;
    const bboxLineWidth = props.bboxLineWidth;
    const labelTextColor = props.labelTextColor;

    return box.label !== "" ? (
        <div
            id="bbox_label"
            style={{
                position: "relative",
                float: "left",
                height: `${labelHeight}px`,
                whiteSpace: "nowrap",
                fontSize: "12px",
                color: labelTextColor,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                paddingLeft: "2px",
                paddingRight: "2px",
                top:
                    box.bottom - box.top > labelHeight
                        ? `${box.bottom - box.top - labelHeight + 1}px`
                        : `${box.bottom - box.top + bboxLineWidth}px`,
                zIndex: 1
            }}
        >
            {box.label}
        </div>
    ) : null;
};

export default BBoxLabel;
