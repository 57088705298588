import React from "react";

/**
 * Simply renders all children in a div that doesn't behave like the default
 * app root's flex style but is a regular block with reset height and width.
 * --> This fixes the sometimes weird overflow hidden behaviour.
 */
export const ClearPageLayout = ({ children, style }) => {
    /**
     * @type {React.CSSProperties}
     */
    const overriddenDefaultStyle = {
        width: "100%",
        height: "inherit",
        overflow: "auto",
        backgroundColor: "#FFF",
        ...style
    };
    return (
        <div className="clear_page_layout" style={overriddenDefaultStyle}>
            {children}
        </div>
    );
};

export default ClearPageLayout;
