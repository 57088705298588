import React from "react";
import { RiRefreshLine } from "react-icons/ri";
import { AiOutlineInfo, AiOutlineExclamationCircle } from "react-icons/ai";
import Tooltip from "@material-ui/core/Tooltip";
import emailjs from "emailjs-com";

import {
    ActionButton,
    ActionButtonUndo,
    ActionButtonOpenInstructions
} from "../../components/ActionButton/ActionButton";
import FeedbackDialog from "./FeedbackDialog";
import DeleteProfileDialog from "./DeleteProfileDialog";
import { getTaskInputIds } from "../../store/reducers/taskFetchRequest";
import FeedbackEmailParams from "../../lib/ApiClient/models/FeedbackEmailParams";

const TOOLTIPS = {
    feedback: "Send us feedback",
    info: "Task instructions",
    instructions: "Video instructions",
    undo: "Undo"
};

// send user feedback via EmailJS
// TODO: should be a utility outside of this Component
/**
 *
 * @param {FeedbackEmailParams} templateParams
 */
const sendFeedback = templateParams => {
    emailjs
        .send(
            // TODO: read credentials from env file
            "service_2roea7n",
            "template_n7903rw",
            templateParams,
            "user_L2LUTygC3MG3sMNuY2ReQ"
        )
        .then(
            response => {
                console.log("feedback was sent successfully");
            },
            err => {
                console.error("failed to send feedback", err);
            }
        );
};

/**
 * Copied and adapted from DefaultMetaActionButtonGroup
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 * @param {import("react").CSSProperties} props.style
 * @param {String} props.className
 * @param {() => {}=} props.onOpenFeedbackModal parent can hook into opening of the modal
 * @param {() => {}=} props.onCloseFeedbackModal parent can hook into closing of the modal
 */
export const MetaActionButtonGroup = ({
    taskUIContext,
    style,
    className,
    onOpenFeedbackModal = () => {},
    onCloseFeedbackModal = () => {}
}) => {
    const with_feedback =
        taskUIContext.queryParams.get("vendor_id") === "redmoon";
    const overriddenStyle = { ...style };
    const disabledMetaActionButtons =
        taskUIContext.shouldActionButtonsBeDisabled();
    const [shouldShowFeedbackModal, setShouldShowFeedbackModal] =
        React.useState(false);

    const openFeedbackModal = () => {
        setShouldShowFeedbackModal(true);
        onOpenFeedbackModal();
    };

    const [shouldShowDeleteProfileModal, setShouldShowDeleteProfileModal] =
        React.useState(false);

    const openDeleteUserModal = () => {
        setShouldShowDeleteProfileModal(true);
    };

    const onDeleteUserData = shouldDelete => {
        if (shouldDelete) {
            //TODO Here deleting the tracked user data must be triggered
        }
        setShouldShowDeleteProfileModal(false);
    };

    /**
     * @param {import("./FeedbackDialog").FeedbackDialogOutput} output
     */
    const closeFeedbackModal = output => {
        if (output) {
            // prepare data for feedback
            const feedbackParams = new FeedbackEmailParams();
            feedbackParams.date = new Date().toUTCString();
            feedbackParams.msg = output.msg;
            feedbackParams.rating = output.rating;
            feedbackParams.vendor_id =
                taskUIContext.queryParams.get("vendor_id");
            feedbackParams.task_input_ids = getTaskInputIds(
                taskUIContext.store.getState()
            );
            feedbackParams.current_task_idx = taskUIContext.getCurrentTaskIdx();

            const projectId = taskUIContext.queryParams.get("project_id");
            const projectNodeId =
                taskUIContext.queryParams.get("project_node_id");
            const projectIds = taskUIContext.queryParams.get("project_ids");
            const projectNodeIds =
                taskUIContext.queryParams.get("project_node_ids");
            if (projectId) {
                feedbackParams.project_id = projectId;
            } else if (projectIds) {
                feedbackParams.project_id = projectIds;
            } else if (projectNodeId) {
                feedbackParams.project_node_id = projectNodeId;
            } else if (projectNodeIds) {
                feedbackParams.project_node_id = projectNodeIds;
            }

            // actually sends the feedback
            sendFeedback(feedbackParams);
        }
        setShouldShowFeedbackModal(false);
        onCloseFeedbackModal();
    };

    let feedback_button = null;
    if (with_feedback) {
        feedback_button = (
            <Tooltip
                title={
                    <div
                        style={{
                            color: "#eee",
                            fontSize: "0.8rem",
                            padding: "3px"
                        }}
                    >
                        {TOOLTIPS["feedback"]}
                    </div>
                }
                placement={"top"}
                enterDelay={500}
                enterNextDelay={500}
                leaveDelay={200}
                arrow
            >
                <div>
                    <ActionButton
                        id="feedback"
                        taskUIContext={taskUIContext}
                        disabled={disabledMetaActionButtons}
                        className="dag_button"
                        onClick={openFeedbackModal}
                    >
                        <div>
                            <AiOutlineExclamationCircle />
                        </div>
                    </ActionButton>
                </div>
            </Tooltip>
        );
    }

    return (
        <div
            style={overriddenStyle}
            className={`${className} meta-action-button-box`}
        >
            <div
                style={{
                    margin: "0 auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    maxWidth: "250px",
                    width: "100px",
                    marginTop: "20px"
                }}
            >
                <Tooltip
                    title={
                        <div
                            style={{
                                color: "#eee",
                                fontSize: "0.8rem",
                                padding: "3px"
                            }}
                        >
                            {TOOLTIPS["undo"]}
                        </div>
                    }
                    placement={"top"}
                    enterDelay={500}
                    enterNextDelay={500}
                    leaveDelay={200}
                    arrow
                >
                    <div>
                        <ActionButtonUndo
                            id="undo"
                            taskUIContext={taskUIContext}
                            disabled={disabledMetaActionButtons}
                            className="dag_button"
                        >
                            <div>
                                <RiRefreshLine />
                            </div>
                        </ActionButtonUndo>
                    </div>
                </Tooltip>
                <Tooltip
                    title={
                        <div
                            style={{
                                color: "#eee",
                                fontSize: "0.8rem",
                                padding: "3px"
                            }}
                        >
                            {TOOLTIPS["info"]}
                        </div>
                    }
                    placement={"top"}
                    enterDelay={500}
                    enterNextDelay={500}
                    arrow
                >
                    <div>
                        <ActionButtonOpenInstructions
                            id="info"
                            taskUIContext={taskUIContext}
                            disabled={disabledMetaActionButtons}
                            className="dag_button"
                        >
                            <div>
                                <AiOutlineInfo />
                            </div>
                        </ActionButtonOpenInstructions>
                    </div>
                </Tooltip>
                {feedback_button}
            </div>
            <FeedbackDialog
                isOpen={shouldShowFeedbackModal}
                onClose={closeFeedbackModal}
                onDeleteUserProfile={openDeleteUserModal}
            />
            <DeleteProfileDialog
                isOpen={shouldShowDeleteProfileModal}
                onClose={onDeleteUserData}
            />
        </div>
    );
};
