import React from "react";
import { IoMdTrash } from "react-icons/io";

export const BBoxMenuItem = props => {
    const index = props.index;
    const value = props.value;
    const currentBoxIndex = props.currentBoxIndex;
    const disableLabels = props.disableLabels;
    const boundingboxesColor = props.boundingboxesColor;
    const currentBoxColor = props.currentBoxColor;

    const removeBoundingBox = props.removeBoundingBox;
    const selectBBoxToAdjust = props.selectBBoxToAdjust;
    const updateLabel = props.updateLabel;
    const hoverBBox = props.hoverBBox;
    const deHoverBBox = props.deHoverBBox;

    const handleChange = event => {
        updateLabel(index, event.target.value);
    };

    return (
        <div
            id="bbox_menu_item"
            style={{
                border: `1px solid black`,
                width: "auto",
                height: "50px",
                display: "flex",
                marginTop: "1px",
                marginLeft: "1px",
                marginRight: "1px",
                borderRadius: "5px",
                background:
                    currentBoxIndex === index
                        ? currentBoxColor
                        : boundingboxesColor
            }}
            // move red box to hovered Index
            onMouseOver={() => hoverBBox(index)}
            // move red box to currentBoxIndex or if null remove red box
            onMouseLeave={() => deHoverBBox()}
        >
            <div
                style={{ width: "85%", borderRadius: "5px 0px 0px 5px" }}
                onClick={() => selectBBoxToAdjust(index)}
            >
                {disableLabels ? null : (
                    <input
                        inputMode="textbox"
                        style={{ width: "150px", margin: "9px" }}
                        placeholder={value.label}
                        value={value.label}
                        onChange={handleChange}
                    ></input>
                )}
            </div>
            <div
                style={{
                    width: "15%",
                    borderRadius: "0px 5px 5px 0px",
                    borderLeft: "1px black solid"
                }}
            >
                <button
                    style={{
                        position: "relative",
                        height: "100%",
                        width: "100%",
                        border: "none",
                        borderRadius: "0px 5px 5px 0px"
                    }}
                    onClick={() => removeBoundingBox(index)}
                >
                    <IoMdTrash />
                </button>
            </div>
        </div>
    );
};

export default BBoxMenuItem;
