import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { GlobalHotKeys } from "react-hotkeys";

import CanvasImage from "../../components/CanvasImage/CanvasImage";
import { LoadingAnimation } from "../../components/LoadingAnimation/LoadingAnimation";
import { useContainerQMDimensions } from "../../customHooks/useContainerQMDimensions";
import {
    getSingleChannelBinaryRunLengthEncoded,
    upscaleImageData
} from "../../lib/imageDataUtils";
import { adjustDimensionsToMaxConstraint } from "../../lib/qm_cs_lib";
import { makeSVGBrushIndicator } from "./svgIndicator";

const BACKGROUND_IMAGE_KEYS = {
    IMAGE_URL: "image_url",
    ALT_IMAGE_URL: "alt_image_url"
};

/**
 * Select marker to draw while mouse is clicked & dragged (default)
 * Select eraser to delete drawings
 * Set eraser and marker sizes with sliders
 * TaskOutput: Canvas layer with the drawings as base64 png
 *
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const PixelBrushCanvas = ({
    resourceCache,
    taskUIContext,
    currentTaskIdx,
    guiSettings,
    currentTaskOutput,
    setCurrentTaskOutput
}) => {
    const {
        brush_color: brushColor = "rgba(255, 0, 0, 1)",
        initial_marker_radius: initialMarkerRadius = 15,
        initial_eraser_size: initialEraserSize = 20,
        reference_image_url: referenceImageUrl,
        resize_image: resizeImage = true
    } = guiSettings;

    const [
        containerQMDimensions,
        viewPortDimensions
    ] = useContainerQMDimensions(currentTaskIdx);
    const [outputCanvas, setOutputCanvas] = useState(null);
    const [canvasCtx, setCanvasCtx] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [useEraser, setUseEraser] = useState(false);
    const [markerRadius, setMarkerRadius] = useState(initialMarkerRadius);
    const [eraserSize, setEraserSize] = useState(initialEraserSize);
    const [xPos, setXPos] = useState();
    const [yPos, setYPos] = useState();
    const [backgroundImageKey, setBackgroundImageKey] = useState(
        BACKGROUND_IMAGE_KEYS.IMAGE_URL
    );

    if (
        !taskUIContext
            .getCurrentGuiObject()
            .taskInputResourcesInCache(currentTaskIdx)
    ) {
        return <LoadingAnimation />;
    }
    taskUIContext.onViewReady();

    const taskInput = taskUIContext.getCurrentTaskInput();
    const image = resourceCache[taskInput.image_url];
    // get image resized dimensions so that the UI with large image fits into the current viewPort
    const imageOriginalDimensions = {
        width: image.width,
        height: image.height
    };

    const resizedDims = adjustDimensionsToMaxConstraint(
        imageOriginalDimensions,
        containerQMDimensions,
        {
            width: viewPortDimensions.width - 30,
            height: viewPortDimensions.height - 30
        }
    );

    let imageResizedDimensions = imageOriginalDimensions;

    if (resizeImage) {
        imageResizedDimensions = resizedDims
            ? resizedDims
            : imageOriginalDimensions;
    }

    /**
     * Update line by connecting last point to current point
     * Draw Point if single click
     * @param {number} x x-coordinate of mouse
     * @param {number} y y-coordinate of mouse
     */
    const drawPoint = (x, y, click) => {
        if (useEraser && (isDragging || click)) {
            canvasCtx.clearRect(
                x - eraserSize / 2,
                y - eraserSize / 2,
                eraserSize,
                eraserSize
            );
        } else {
            canvasCtx.save();
            canvasCtx.strokeStyle = brushColor;
            canvasCtx.fillStyle = brushColor;
            canvasCtx.beginPath();
            if (click) {
                canvasCtx.arc(x, y, markerRadius / 2, 0, 2 * Math.PI);
                canvasCtx.fill();
            } else if (isDragging) {
                canvasCtx.lineWidth = markerRadius;
                canvasCtx.lineJoin = "round";
                canvasCtx.moveTo(xPos, yPos);
                canvasCtx.lineTo(x, y);
                canvasCtx.closePath();
                canvasCtx.stroke();
            }
            canvasCtx.restore();
        }
    };

    const clearCanvas = () => {
        canvasCtx.clearRect(0, 0, outputCanvas.width, outputCanvas.height);
    };

    /**
     * Stop dragging, upscale canvas brush layer, apply binary run-length-encoding and remember as currentTaskOutput.
     */
    const updateCurrentTaskOutput = (explicitRLE = null) => {
        setIsDragging(false);
        const doUpdate = rle => {
            // check if empty output and set to initialCurrentTaskOutput if so
            if (
                rle ===
                imageOriginalDimensions.width * imageOriginalDimensions.height +
                    "<0>"
            ) {
                rle = "";
            }
            setCurrentTaskOutput(
                taskUIContext
                    .getCurrentGuiObject()
                    .makeTaskOutputForCurrentTask({
                        encoded_pixel_data: rle
                    })
            );
        };

        // either calculate new rle
        if (explicitRLE === null) {
            const imageData = canvasCtx.getImageData(
                0,
                0,
                outputCanvas.width,
                outputCanvas.height
            ).data;
            upscaleImageData(
                imageData,
                imageResizedDimensions,
                imageOriginalDimensions
            ).then(newImageData => {
                let newRLE = getSingleChannelBinaryRunLengthEncoded(
                    newImageData
                );
                doUpdate(newRLE);
            });
        } else {
            // or use the given value
            doUpdate(explicitRLE);
        }
    };

    const makeMenu = () => {
        return (
            <div
                id="menu"
                style={{
                    display: "flex",
                    margin: "auto",
                    alignItems: "baseline",
                    width: "80%",
                    marginBottom: "20px",
                    marginTop: "20px",
                    backgroundColor: "#CCC",
                    padding: "5px"
                }}
            >
                {referenceImageUrl ? (
                    <div // Reference Image
                        style={{ flex: 2, marginRight: "10px" }}
                    >
                        {" "}
                        <img
                            id="reference_image"
                            src={referenceImageUrl}
                            alt=""
                            style={{
                                width: "100%",
                                height: "100%",
                                border: "5px solid black"
                            }}
                        />
                    </div>
                ) : null}
                <div // Clear the whole canvas
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        paddingRight: "10px"
                    }}
                >
                    <Button
                        id="clear_canvas_button"
                        disabled={currentTaskOutput.encoded_pixel_data === ""}
                        onClick={() => {
                            // clear canvas and reset current task output so that submit button is disabled
                            clearCanvas();
                            updateCurrentTaskOutput("");
                        }}
                    >
                        Clear
                    </Button>
                </div>
                <div // Eraser Button & Size Slider
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        paddingRight: "10px"
                    }}
                >
                    <Button
                        id={"select_eraser_button"}
                        disabled={useEraser}
                        onClick={() => setUseEraser(true)}
                        style={{
                            marginBottom: "10px"
                        }}
                    >
                        Select Eraser
                    </Button>
                    <span
                        style={{
                            fontSize: "16px",
                            marginBottom: "6px"
                        }}
                    >
                        {" "}
                        {"Eraser Size: "} {eraserSize}
                    </span>
                    <input
                        id={"eraser_size_slider"}
                        type="range"
                        min="1"
                        max="100"
                        value={eraserSize}
                        onChange={event => setEraserSize(event.target.value)}
                        step="1"
                        style={{}}
                    />
                </div>
                <div // Marker Button and Size Slider
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <Button
                        id={"select_marker_button"}
                        disabled={!useEraser}
                        onClick={() => setUseEraser(false)}
                        style={{
                            marginBottom: "10px"
                        }}
                    >
                        Select Marker
                    </Button>
                    <span
                        style={{
                            fontSize: "16px",
                            marginBottom: "6px"
                        }}
                    >
                        {" "}
                        {"Marker Size: "} {markerRadius}
                    </span>
                    <input
                        id={"marker_radius_slider"}
                        type="range"
                        min="1"
                        max="100"
                        value={markerRadius}
                        onChange={event => setMarkerRadius(event.target.value)}
                        step="1"
                        style={{}}
                    />
                </div>
            </div>
        );
    };

    return (
        <div>
            <GlobalHotKeys
                keyMap={{ TOGGLE_BACKGROUND_IMAGE: ["t"] }}
                handlers={{
                    TOGGLE_BACKGROUND_IMAGE: () => {
                        if (taskInput.alt_image_url) {
                            if (
                                backgroundImageKey ===
                                BACKGROUND_IMAGE_KEYS.IMAGE_URL
                            ) {
                                setBackgroundImageKey(
                                    BACKGROUND_IMAGE_KEYS.ALT_IMAGE_URL
                                );
                            } else if (
                                backgroundImageKey ===
                                BACKGROUND_IMAGE_KEYS.ALT_IMAGE_URL
                            ) {
                                setBackgroundImageKey(
                                    BACKGROUND_IMAGE_KEYS.IMAGE_URL
                                );
                            }
                        }
                    }
                }}
                allowChanges={true}
            />
            <div
                style={{
                    position: "relative",
                    width: "fit-content",
                    margin: "auto"
                }}
            >
                <img
                    id="image"
                    src={taskInput[backgroundImageKey]}
                    alt=""
                    style={{
                        cursor: "crosshair",
                        position: "absolute",
                        userSelect: "none",
                        width: imageResizedDimensions.width,
                        height: imageResizedDimensions.height
                    }}
                />
                <CanvasImage
                    // Second canvas layer on top of original image canvas to apply drawings on
                    key={image.src + "_layer"}
                    canvas_width={imageResizedDimensions.width}
                    canvas_height={imageResizedDimensions.height}
                    style={{
                        width: imageResizedDimensions.width,
                        height: imageResizedDimensions.height,
                        cursor: "crosshair",
                        position: "relative"
                    }}
                    /**
                     * get canvas context of layer when loaded
                     */
                    callback_canvas_draw={(canvas, ctx) => {
                        setCanvasCtx(ctx);
                        setOutputCanvas(canvas);
                    }}
                    /**
                     * update mouse cursor arc via redraw trigger of original canvas
                     * draw new point
                     */
                    onMouseMove={event => {
                        if (canvasCtx == null) {
                            return;
                        }
                        setXPos(event.nativeEvent.offsetX);
                        setYPos(event.nativeEvent.offsetY);
                        drawPoint(
                            event.nativeEvent.offsetX,
                            event.nativeEvent.offsetY
                        );
                    }}
                    /**
                     * initialise draw start coordinates / dragging
                     */
                    onMouseDown={event => {
                        drawPoint(
                            event.nativeEvent.offsetX,
                            event.nativeEvent.offsetY,
                            true
                        );
                        setIsDragging(true);
                    }}
                    onMouseUp={() => updateCurrentTaskOutput()}
                    onMouseOut={() => updateCurrentTaskOutput()}
                />
                {makeSVGBrushIndicator(
                    outputCanvas ? outputCanvas.width : null,
                    outputCanvas ? outputCanvas.height : null,
                    xPos,
                    yPos,
                    useEraser,
                    eraserSize,
                    markerRadius
                )}
            </div>
            {makeMenu()}
        </div>
    );
};

export default PixelBrushCanvas;
