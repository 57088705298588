import React from "react";

import { ActionButtonNextOrSubmit } from "../../components/ActionButton/ActionButton";
import LikertScale from "../../components/LikertScale/LikertScale";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const ActionButtonGroup = ({
    taskUIContext,
    currentTaskOutput,
    setCurrentTaskOutput,
    guiSettings
}) => {
    const { action_button_group: inputType } = guiSettings;
    const guiObject = taskUIContext.getCurrentGuiObject();
    const disabledActionButtons = taskUIContext.shouldActionButtonsBeDisabled();
    let taskOutputDoesntExist =
        currentTaskOutput === null || currentTaskOutput.similarity === null;
    if (inputType === "discrete_answer") {
        taskOutputDoesntExist = false;
    }

    /**
     * Set similarity in the current task output.
     * Both input controls use the same taskOutput structure but with different types.
     * @param {String|Number} similarity string for discrete_answer and number for likert_scale.
     * @param {Boolean=} withSetCurrentTAskOutput default true. Whether redux state for currentTaskOutput should be updated.
     */
    const setSimilarityInCurrentTaskOutput = (
        similarity,
        withSetCurrentTaskOutput = true
    ) => {
        const newCurrentTaskOutput = {
            ...currentTaskOutput
        };
        newCurrentTaskOutput.similarity = similarity;

        if (withSetCurrentTaskOutput) {
            setCurrentTaskOutput(
                guiObject.makeTaskOutputForCurrentTask(newCurrentTaskOutput)
            );
        }
        return newCurrentTaskOutput;
    };

    // get input controls depending on guiSettings
    const getInputControls = () => {
        if (inputType === "discrete_answer") {
            const buttons = [
                { answer_key: "not_similar", text: "not similar" },
                { answer_key: "similar", text: "similar" }
            ];
            return (
                <div style={{ fontSize: "1.2rem" }}>
                    {buttons.map((button, idx) => (
                        <ActionButtonNextOrSubmit
                            key={idx}
                            id={button.answer_key}
                            taskUIContext={taskUIContext}
                            actionSuffix="set_discrete_answer"
                            disabled={
                                disabledActionButtons || taskOutputDoesntExist
                            }
                            createTaskOutput={() =>
                                setSimilarityInCurrentTaskOutput(
                                    button.answer_key
                                )
                            }
                            className="is-primary"
                        >
                            {button.text}
                        </ActionButtonNextOrSubmit>
                    ))}
                </div>
            );
        } else if (inputType === "likert_scale") {
            const likertScaleOptions = {};
            new Array(10)
                .fill(0)
                .forEach((_, idx) => (likertScaleOptions[idx + 1] = idx + 1));
            return (
                <div>
                    <LikertScale
                        options={likertScaleOptions}
                        superLabels={{
                            [likertScaleOptions["1"]]: "not similar",
                            [likertScaleOptions["10"]]: "very similar"
                        }}
                        superLabelPosition="above"
                        selectedOptionValue={
                            currentTaskOutput
                                ? currentTaskOutput.similarity
                                : null
                        }
                        onSelectOption={(_, optionValue) =>
                            setSimilarityInCurrentTaskOutput(optionValue)
                        }
                    />
                    <ActionButtonNextOrSubmit
                        taskUIContext={taskUIContext}
                        actionSuffix="submit"
                        disabled={
                            disabledActionButtons || taskOutputDoesntExist
                        }
                        createTaskOutput={() => currentTaskOutput}
                        className="is-primary"
                    >
                        Next
                    </ActionButtonNextOrSubmit>
                </div>
            );
        } else {
            return null;
        }
    };

    return getInputControls();
};
