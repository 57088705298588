import React, { useEffect, useState } from "react";

import CameraViewZoom from "../CameraViewZoom";
import CanvasScene from "../../../lib/NRL/CanvasScene";
import { LayeredScene } from "../../../lib/NRL/CanvasScene";
import { matMultMat, Pose3D } from "../../../lib/NRL/Pose3D";
import { RENDERERS } from "../../../lib/NRL/Renderer";
import { makeActionIndicatorForBox } from "../BoundingBoxCameraView";
import { ACTION_INDICATOR } from "../BoundingBoxCameraView";
import { makeInterpolatedLine } from "../../../lib/NRL/RenderableFactory";

/**
 * @param {Object} props
 * @param {String} props.name
 * @param {Number[][]} props.E
 * @param {Number[][]} props.K
 * @param {Number[]} props.distortionCoeffs
 * @param {HTMLImageElement} props.image
 * @param {Number[][]} props.hmdWorldPose
 * @param {Object} props.boxUI object returned by BBox3DModel::makeUITypes()
 * @param {Pose3D} props.trackablePose pose of the trackable that should be attached
 * @param {String} props.boxLineColor
 * @param {Number} props.boxLineWidth
 * @param {Boolean} props.withZoom
 * @param {Number} props.zoomLevel
 * @param {Number} props.actionIndicatorLineWidth
 * @param {String} props.actionIndicatorLineColor
 * @param {Number} props.actionIndicatorLineLength world length of actionIndicatorLine
 * @param {String} props.actionIndicatorName
 */
export const BoundingBoxFaceAdjustmentCameraView = ({
    name,
    E,
    K,
    distortionCoeffs,
    image,
    hmdWorldPose,
    boxUI,
    trackablePose = null,
    boxLineColor,
    boxLineWidth,
    withZoom,
    zoomLevel,
    actionIndicatorLineWidth,
    actionIndicatorLineColor,
    actionIndicatorLineLength,
    actionIndicatorName
}) => {
    const [sceneMap, setSceneMap] = useState(null);
    // eslint-disable-next-line
    const [originalCanvasDimensions, setOriginalCanvasDimensions] = useState({
        width: image.width,
        height: image.height
    });
    // eslint-disable-next-line
    const [resizedCanvasDimensions, setResizedCanvasDimensions] = useState(
        null
    );
    const [zoomTranslation, setZoomTranslation] = useState(null);

    // recreate the box scene object on every react update of the boxUI prop
    // and update the actionIndicator
    useEffect(() => {
        if (!sceneMap) {
            return;
        }
        const scene = sceneMap.get("box");

        // handle the box renderable
        if (scene.objects.has("box")) {
            scene.deleteObject("box");
        }
        // when no trackable is given we expect boxPose to be in the world coordinate system
        let boxPose = new Pose3D(boxUI.position, boxUI.rotation);
        if (trackablePose) {
            // we expect that the boxPose is meant to be in the coordinate system of the trackable
            boxPose = trackablePose.applyLocalTransformation(boxPose);
        }

        const box = boxUI.boxDims.makeCurvesRenderable(boxPose, {
            lineWidth: boxLineWidth,
            strokeStyle: boxLineColor
        });
        scene.addObject(box, "box");

        // handle the actionIndicator renderable
        if (scene.objects.has("actionIndicator")) {
            scene.deleteObject("actionIndicator");
        }
        let actionIndicator = null;
        const halfLength = actionIndicatorLineLength / 2;
        const actionIndicatorSettings = {
            lineWidth: actionIndicatorLineWidth,
            strokeStyle: actionIndicatorLineColor
        };
        switch (actionIndicatorName) {
            case ACTION_INDICATOR.DIMENSION.WIDTH:
                actionIndicator = makeInterpolatedLine(
                    boxPose,
                    [-halfLength, 0, 0],
                    [halfLength, 0, 0],
                    200,
                    actionIndicatorSettings
                );
                break;
            case ACTION_INDICATOR.DIMENSION.LENGTH:
                actionIndicator = makeInterpolatedLine(
                    boxPose,
                    [0, -halfLength, 0],
                    [0, halfLength, 0],
                    200,
                    actionIndicatorSettings
                );
                break;
            case ACTION_INDICATOR.DIMENSION.HEIGHT:
                actionIndicator = makeInterpolatedLine(
                    boxPose,
                    [0, 0, -halfLength],
                    [0, 0, halfLength],
                    200,
                    actionIndicatorSettings
                );
                break;
            default:
                break;
        }
        if (actionIndicator) {
            scene.addObject(actionIndicator, "actionIndicator");
        }

        // handle the coordinate system of the box
        for (const objectName of ["x_axis", "y_axis", "z_axis"]) {
            if (scene.objects.has(objectName)) {
                scene.deleteObject(objectName);
            }
        }
        const x = makeInterpolatedLine(
            boxPose,
            [0, 0, 0],
            [boxUI.boxDims.right, 0, 0],
            100,
            {
                lineWidth: 2,
                strokeStyle: "#F00"
            }
        );
        const y = makeInterpolatedLine(
            boxPose,
            [0, 0, 0],
            [0, boxUI.boxDims.front, 0],
            100,
            {
                lineWidth: 2,
                strokeStyle: "#0F0"
            }
        );
        const z = makeInterpolatedLine(
            boxPose,
            [0, 0, 0],
            [0, 0, boxUI.boxDims.top],
            100,
            {
                lineWidth: 2,
                strokeStyle: "#00F"
            }
        );
        scene.addObject(x, "x_axis");
        scene.addObject(y, "y_axis");
        scene.addObject(z, "z_axis");

        scene.render(true);

        // update zoom to box center
        // boxUI.position is in the coordinate system of the trackable.
        let canvasBoxCenter = scene.renderer.worldPointToCanvas(
            scene.cameraPoseWorld,
            boxUI.position
        );
        if (!canvasBoxCenter) {
            setZoomTranslation(null);
            return;
        }
        canvasBoxCenter = { x: canvasBoxCenter[0], y: canvasBoxCenter[1] };
        // top right camera view is rotated by 180°
        if (name === "top_right") {
            canvasBoxCenter.x =
                resizedCanvasDimensions.width - canvasBoxCenter.x;
            canvasBoxCenter.y =
                resizedCanvasDimensions.height - canvasBoxCenter.y;
        }
        const translation = calculateTranslation(canvasBoxCenter);
        setZoomTranslation(translation);

        // eslint-disable-next-line
    }, [sceneMap, boxUI, actionIndicatorName]);

    /**
     * Calculate translation coordinates for zoom on resized canvas for projectedPoint.
     */
    const calculateTranslation = projectedPoint => {
        const translation = {
            x: resizedCanvasDimensions.width / 2 - projectedPoint.x,
            y: resizedCanvasDimensions.height / 2 - projectedPoint.y
        };

        // max-translation to ensure that the translation does not push the image out of frame
        const maxXtranslation =
            ((zoomLevel - 1) / zoomLevel) * (resizedCanvasDimensions.width / 2);
        const maxYtranslation =
            ((zoomLevel - 1) / zoomLevel) *
            (resizedCanvasDimensions.height / 2);

        if (Math.abs(translation.x) > maxXtranslation) {
            translation.x = Math.sign(translation.x) * maxXtranslation;
        }
        if (Math.abs(translation.y) > maxYtranslation) {
            translation.y = Math.sign(translation.y) * maxYtranslation;
        }
        return translation;
    };

    const layeredScene = (
        <LayeredScene
            style={{
                transform: name === "top_right" ? "rotate(180deg)" : "none",
                margin: "auto"
            }}
            setSceneMap={sceneMapFromLayeredScene => {
                // TODO: this throws a warning because the render func of LayeredScene calls this function
                //       and this function updates the state of CanvasScene
                setSceneMap(sceneMapFromLayeredScene);
            }}
        >
            <CanvasScene
                name="bg_image"
                width={image.width}
                height={image.height}
                renderer={RENDERERS.FISHEYE_CANVAS}
                rendererSettings={{
                    distortionCoeffs: distortionCoeffs,
                    K: K
                }}
                init={scene => {
                    /**
                     * @type {CanvasRenderingContext2D}
                     */
                    const ctx = scene.renderer.ctx;
                    setResizedCanvasDimensions({
                        width: ctx.canvas.offsetWidth,
                        height: ctx.canvas.offsetHeight
                    });
                    scene.cameraPoseWorld = Pose3D.makePoseFromHtm(
                        matMultMat(hmdWorldPose, E)
                    );

                    // draw image scaled to the canvas
                    ctx.drawImage(
                        image,
                        0,
                        0,
                        image.naturalWidth,
                        image.naturalHeight,
                        0,
                        0,
                        ctx.width,
                        ctx.height
                    );
                }}
                canvasProps={{
                    style: { display: "block" },
                    className: "canvas_scene bbox_face_adjustment_canvas_scene"
                }}
            />
            <CanvasScene
                name="box"
                width={image.width}
                height={image.height}
                renderer={RENDERERS.FISHEYE_CANVAS}
                rendererSettings={{
                    distortionCoeffs: distortionCoeffs,
                    K: K
                }}
                init={scene => {
                    scene.cameraPoseWorld = Pose3D.makePoseFromHtm(
                        matMultMat(hmdWorldPose, E)
                    );
                }}
                canvasProps={{
                    style: { display: "block" },
                    className: "canvas_scene bbox_face_adjustment_canvas_scene"
                }}
            />
        </LayeredScene>
    );

    if (withZoom && zoomTranslation) {
        return (
            <CameraViewZoom
                zoomParameters={{
                    scale: zoomLevel,
                    translation: zoomTranslation
                }}
            >
                {layeredScene}
            </CameraViewZoom>
        );
    }
    return layeredScene;
};
export default BoundingBoxFaceAdjustmentCameraView;
