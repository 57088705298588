import React from "react";

/**
 * Scales an image without css transform so that the container can grow with the image.
 */
export const ScaledImage = ({
    imageSrc,
    imageWidth,
    imageHeight,
    scale = 1
}) => {
    const style =
        scale === 1
            ? {
                  width: scale !== 1 ? scale * imageWidth : "auto",
                  height: scale !== 1 ? scale * imageHeight : "auto",
                  maxWidth: "100%",
                  maxHeight: "76vh"
              }
            : { width: scale * imageWidth, height: scale * imageHeight };
    return (
        <div>
            <img id="scaled_image" src={imageSrc} alt="" style={style} />
        </div>
    );
};

export default ScaledImage;
