export const pointcloudCarTaskInputs = [
    {
        // support pole example
        // from my manual annotation
        image_url:
            "https://qm-rumanesco-output.s3.eu-central-1.amazonaws.com/tasks/2021-01-29_14-24-29/image_url/12a51633614347738486e13499e202d6.png",
        pb_data_url:
            "https://qm-rumanesco-output.s3.eu-central-1.amazonaws.com/tasks/2021-01-29_14-24-29/pb_data_url/12a51633614347738486e13499e202d6.pb",
        boxes: [
            // the existing locked boxes
            {
                cog: {
                    x: 5.488137281926934,
                    y: 11.998924448852122,
                    z: 1.8146427711622037
                },
                orientation: -0.0007251484276345303,
                //orientation: { y: 0, x: 0, z: -0.0007251484276345303 },
                box: {
                    back: 0.3262596324836995,
                    bottom: 4.508876047076988,
                    front: 0.3262596324836995,
                    left: 0.39201738786800977,
                    right: 0.39201738786800977,
                    top: 4.508876047076988
                }
            },
            // second existing box to show that we can handle multiple ones
            {
                cog: {
                    x: 1.3999676693244036,
                    y: 8.72397470897794,
                    z: 3.5556912082622105
                },
                //orientation:  2.266578302405754,
                orientation: { y: 0, x: 0, z: 2.266578302405754 },
                box: {
                    back: 5.017384251700852,
                    bottom: 0.45990529139952563,
                    front: 5.017384251700852,
                    left: 0.4085306390214474,
                    right: 0.4085306390214474,
                    top: 0.45990529139952563
                }
            }
        ],
        // the adjustable box
        initial_cog_pos: {
            x: 5.488137281926934,
            y: 11.998924448852122,
            z: 1.8146427711622037
        },
        initial_box_orientation: -0.0007251484276345303,
        initial_box_dims: {
            back: 1.5,
            bottom: 0.3,
            front: 1.5,
            left: 0.3,
            right: 0.3,
            top: 0.3
        }
    },
    {
        // support pole example
        // from my manual annotation
        image_url:
            "https://qm-rumanesco-output.s3.eu-central-1.amazonaws.com/tasks/2021-01-29_14-24-29/image_url/e8e3d3e1e14644dbbb4349ffcd5a4ecb.png",
        pb_data_url:
            "https://qm-rumanesco-output.s3.eu-central-1.amazonaws.com/tasks/2021-01-29_14-24-29/pb_data_url/e8e3d3e1e14644dbbb4349ffcd5a4ecb.pb",
        initial_cog_pos: {
            x: 0.013022856070168828,
            y: 25.140263119317297,
            z: -1.2179369914217673
        },
        initial_box_orientation: 0,
        initial_box_dims: {
            back: 0.15729976962601433,
            bottom: 1.303875211573125,
            front: 0.15729976962601433,
            left: 0.1044163997608237,
            right: 0.1044163997608237,
            top: 1.303875211573125
        }
    },
    {
        image_url:
            "https://cd873718-34ad-4b23-aceb-66e59ae1ef12.s3.eu-central-1.amazonaws.com/image_url/000bfd04df9f47d6bc586754d1e8e138.png",
        pb_data_url:
            "https://cd873718-34ad-4b23-aceb-66e59ae1ef12.s3.eu-central-1.amazonaws.com/pb_data_url_light/000bfd04df9f47d6bc586754d1e8e138_light.pb",
        initial_cog_pos: {
            x: 0.2,
            y: 22.5,
            z: -2
        },
        // only needed for the bbox task
        initial_box_dims: {
            front: 2.5,
            back: 2.5,
            left: 1.5,
            right: 1.5,
            top: 0.8,
            bottom: 0.8
        },
        initial_box_orientation: 0
    },
    {
        // Junction Box example
        image_url:
            "https://qm-rumanesco-output.s3.eu-central-1.amazonaws.com/tasks/2021-01-14_12-22-54/image_url/ee75d8f99e534bc5a4693d98431e1d96.png",
        pb_data_url:
            "https://qm-rumanesco-output.s3.eu-central-1.amazonaws.com/tasks/2021-01-14_12-22-54/pb_data_url/ee75d8f99e534bc5a4693d98431e1d96.pb",
        initial_cog_pos: {
            x: 0,
            y: 3,
            z: -1
        },
        // only needed for the bbox task
        initial_box_dims: {
            front: 1,
            back: 1,
            left: 0.5,
            right: 0.5,
            top: 0.4,
            bottom: 0.4
        },
        initial_box_orientation: 0
    },
    {
        // support pole for animation
        image_url:
            "https://qm-rumanesco-output.s3.eu-central-1.amazonaws.com/tasks/2021-01-29_14-24-29/image_url/12a51633614347738486e13499e202d6.png",
        pb_data_url:
            "https://qm-rumanesco-output.s3.eu-central-1.amazonaws.com/tasks/2021-01-29_14-24-29/pb_data_url/12a51633614347738486e13499e202d6.pb",
        initial_cog_pos: {
            x: 0,
            y: 3,
            z: -1
        },
        // only needed for the bbox task
        initial_box_dims: {
            front: 0.5,
            back: 0.5,
            left: 0.5,
            right: 0.5,
            top: 0.4,
            bottom: 0.4
        },
        initial_box_orientation: 0
    }
];
