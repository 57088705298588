import deepmerge from "deepmerge";
import { norm, subtract } from "mathjs";

// build time env
export const isDevEnv = () => process.env.NODE_ENV === "development";
export const isProdEnv = () => process.env.NODE_ENV === "production";
export const getClientVersion = () => process.env.REACT_APP_VERSION;
// run time env
export const isDevStage = () => window.__env.REACT_APP_STAGE === "dev";
export const isStageStage = () => window.__env.REACT_APP_STAGE === "stage";
export const isProdStage = () => window.__env.REACT_APP_STAGE === "prod";

export const tryCatchFinally = (
    tryCallback,
    catchCallback = err => console.error(err),
    finallyCallback = () => {}
) => {
    try {
        tryCallback();
    } catch (err) {
        catchCallback(err);
    } finally {
        finallyCallback();
    }
};

export const drawAndLoadImageOnCanvas = (
    canvas,
    ctx,
    img_url,
    resize,
    imageWasDrawnCallback = img => {}
) => {
    const img = new Image();
    img.onload = () => {
        drawPreloadedImageOnCanvas(
            canvas,
            ctx,
            img,
            resize,
            imageWasDrawnCallback
        );
    };
    img.crossOrigin = "anonymous";
    img.src = img_url;
};

export const drawPreloadedImageOnCanvas = (
    canvas,
    ctx,
    img,
    resize,
    imageWasDrawnCallback = img => {}
) => {
    if (resize) {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    } else {
        ctx.drawImage(img, 0, 0);
    }
    imageWasDrawnCallback(img);
};

/**
 * @throws {Error}
 */
export const throwAbstractError = () => {
    throw new Error("Method needs implementation");
};

/**
 * @returns {URLSearchParams}
 */
export const getQueryParams = () => {
    return new URLSearchParams(decodeURI(window.location.search));
};

/**
 * @param {String} jsonString
 */
export const parseObjectFromJsonString = jsonString => {
    try {
        return JSON.parse(jsonString);
    } catch (err) {
        console.error("Parsing JSON String threw an error", jsonString, err);
        return null;
    }
};

/**
 * Use deepmerge only on top-level keys that exist in a.
 * Top-level keys in b that don't exist in a are ignored.
 * @param {Object} a
 * @param {Object} b
 * @returns {Object}
 */
export const deepMergeSharedKeys = (a, b) => {
    const bKeysInA = Object.keys(b).filter(key => key in a);
    const bCopy = {};
    for (const key of bKeysInA) {
        bCopy[key] = b[key];
    }
    return deepmerge(a, bCopy);
};

/**
 * @param {Map<String, any>} map
 * @param {String[]} requiredKeys
 */
export const checkMapHasKeys = (map, requiredKeys) => {
    for (const key of requiredKeys) {
        if (!map.has(key)) {
            return false;
        }
    }
    return true;
};

export const sleep = async ms =>
    new Promise(resolve => setTimeout(resolve, ms));

export const stringTruthiness = str => {
    return (
        str !== undefined &&
        str !== null &&
        (str.toLowerCase() === "true" || str === "1")
    );
};

export const memoize = (f, keyAccessor = o => o.key) => {
    const cache = {};
    return (...args) => {
        const key = keyAccessor(args[0]);
        if (!(key in cache)) {
            cache[key] = f(args[0]);
        }
        return cache[key];
    };
};

/**
 * Returns the current date in YYYY-MM-DD format
 *
 * @returns {String}
 */
export const getFormattedTodayDate = () => {
    const d = new Date();
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) {
        month = "0" + month;
    }
    if (day.length < 2) {
        day = "0" + day;
    }

    return [year, month, day].join("-");
};

/**
 * @param {String} timeString a UTC time string
 * @returns {String}
 */
export const formatUTCTimeStringWithLocale = timeString => {
    const d = new Date(timeString);
    return d.toLocaleString();
};

/**
 * Converts a json object to a string and triggers the download.
 * Pretty print with indentation is optional.
 *
 * @param {Object} jsonData json data object
 * @param {String} filename only the filename without the .json extension
 * @param {Number=} indent with how many spaces to pretty print the file. Default is false (no pretty print)
 */
export const triggerJsonObjectDownload = (
    jsonData,
    filename,
    indent = false
) => {
    let jsonString = "";
    if (indent === false) {
        jsonString = JSON.stringify(jsonData);
    } else {
        jsonString = JSON.stringify(jsonData, null, indent);
    }
    const data = "text/json;charset=utf-8," + encodeURIComponent(jsonString);
    const a = document.createElement("a");
    a.href = "data:" + data;
    a.download = filename + ".json";
    a.innerHTML = "download JSON";
    a.click();
};

/**
 * Copy text to clipboard when navigator clipboard api is supported by the browser
 *
 * @param {String} text
 */
export const copyTextToClipboard = text => {
    if (navigator !== undefined && navigator.clipboard !== undefined) {
        navigator.clipboard
            .writeText(text)
            .then(() => console.log("copied text to clipboard:", text))
            .catch(err =>
                console.err("error trying to copy text to clipboard", text)
            );
    } else {
        console.warn("clipboard api not implemented in this browser", text);
    }
};

/**
 * @param {MouseEvent} originalEvent
 * @param {HTMLElement} target
 */
export const propagateMouseEventToTarget = (originalEvent, target) => {
    const event = new MouseEvent(originalEvent.type, originalEvent);
    target.dispatchEvent(event);
};

/**
 * @param {Number} colorVal single color value between 0 and 255
 * @param {Number} percent
 */
export const adjustColorValToHexString = (colorVal, percent) => {
    if (colorVal > 0) {
        return (0 | ((1 << 8) + colorVal + ((256 - colorVal) * percent) / 100))
            .toString(16)
            .substr(1);
    }
    return "00";
};

/**
 * adjusted and corrected from src: https://stackoverflow.com/a/6444043
 *
 * @param {String} hexString
 * @param {Number} percent
 */
export const adjustHexColorBrightness = (hexString, percent) => {
    // strip the leading # if it's there
    let hex = hexString.replace(/^\s*#|\s*$/g, "");
    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length === 3) {
        hex = hex.replace(/(.)/g, "$1$1");
    }

    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    const rHex = adjustColorValToHexString(r, percent);
    const gHex = adjustColorValToHexString(g, percent);
    const bHex = adjustColorValToHexString(b, percent);
    return "#" + rHex + gHex + bHex;
};

/**
 * Create a random alphanumeric string (36 different characters) with specified length.
 *
 * @param {Number} len
 */
export const generateRandomString = len => {
    return [...new Array(len)]
        .map(i => (~~(Math.random() * 36)).toString(36))
        .join("");
};

/**
 * @param {Number} value
 * @param {Number} min
 * @param {Number} max
 */
export const clamp = (value, min, max) => {
    return Math.max(min, Math.min(max, value));
};

/**
 * Helper class that tracks mouse movement with timestamp and information on which DOM element is hovered at each sample.
 */
export class MouseTracker {
    /**
     * @typedef {Object} MouseTrackingEntry
     * @property {Number} MouseTrackingEntry.x relative to window
     * @property {Number} MouseTrackingEntry.y relative to window
     * @property {Number} MouseTrackingEntry.ex relative to target DOM element
     * @property {Number} MouseTrackingEntry.ey relative to target DOM element
     * @property {String} MouseTrackingEntry.target_id target_id is comprised of target DOM element's [id][class][tag]
     * @property {Number} MouseTrackingEntry.ts_ms timestamp milliseconds
     * @property {Object} MouseTrackingEntry.target_rect bounding rect of target DOME element
     * @property {Number} MouseTrackingEntry.target_rect.x anchor relative to window
     * @property {Number} MouseTrackingEntry.target_rect.y anchor relative to window
     * @property {Number} MouseTrackingEntry.target_rect.width
     * @property {Number} MouseTrackingEntry.target_rect.height
     */

    /**
     *
     */
    constructor() {
        /**
         * @type {Map<String, (mouseEvent: MouseEvent) => {}>} maps eventName to listener
         */
        this.trackingListeners = new Map([
            ["mousemove", this.newEntryFromMouseEvent.bind(this)],
            ["click", this.newEntryFromMouseEvent.bind(this)]
        ]);

        this.mouseTrackingData = this.makeEmptyMouseTrackingData();

        /**
         * @type {HTMLElement}
         */
        this.trackedElement = null;
        this.freqHz = -1;
    }

    /**
     * @param {HTMLElement} element
     * @param {Number} freqHz frequency for all listeners: -1 for no limit, > 0 for other frequency (per second)
     * @throws {Error}
     */
    initElementWithListeners(element, freqHz) {
        if (freqHz > 0 || freqHz === -1) {
            this.freqHz = freqHz;
        } else {
            throw new Error("freqHz must be -1 or larger than 0!");
        }
        this.trackedElement = element;

        for (const [eventName, func] of this.trackingListeners) {
            let throttledTrackingListener = func;
            // some events don't need throttling
            if (!["click"].includes(eventName) && this.freqHz !== -1) {
                throttledTrackingListener = throttle(
                    throttledTrackingListener,
                    this.freqHz
                );
                this.trackingListeners.set(
                    eventName,
                    throttledTrackingListener
                );
            }
            element.addEventListener(eventName, throttledTrackingListener);
        }
    }

    getTimestampMillis() {
        return Date.now();
    }

    /**
     * Remove event listeners
     */
    cleanupTrackedElement() {
        if (this.trackedElement) {
            for (const [eventName, func] of this.trackingListeners) {
                this.trackedElement.removeEventListener(eventName, func);
            }
        }
    }

    /**
     * Creates and appends a new entry from a vanilla JS MouseEvent
     * @param {MouseEvent} mouseEvent
     */
    newEntryFromMouseEvent(mouseEvent) {
        // targetId comprised of [id][class][tag]
        // TODO: some better way to identify the element. Maybe add ids to more elements of interest?
        const t = mouseEvent.target;
        const targetId = `[${t.id}][${t.className}][${t.localName}]`;
        const targetRect = t.getBoundingClientRect();
        const entry = {
            x: mouseEvent.clientX,
            y: mouseEvent.clientY,
            target_rect: {
                x: targetRect.x,
                y: targetRect.y,
                width: targetRect.width,
                height: targetRect.height
            },
            ex: mouseEvent.offsetX,
            ey: mouseEvent.offsetY,
            target_id: targetId,
            ts_ms: this.getTimestampMillis()
        };

        const trackingDataArray = this.mouseTrackingData.get(mouseEvent.type);
        if (trackingDataArray) {
            trackingDataArray.push(entry);
        } else {
            console.warn(
                "Mouse event data can't be stored, because data array isn't initialized."
            );
        }
    }

    /**
     * reinitializes the mouseTrackingData map as new empty map
     */
    clear() {
        this.mouseTrackingData = this.makeEmptyMouseTrackingData();
    }

    /**
     * Serializable mouseTrackingData can be obtained with getSerializableMouseTrackingData
     * @returns {Map<String, MouseTrackingEntry[]>} maps EventName to array of captured entries
     */
    makeEmptyMouseTrackingData() {
        return new Map(
            [...this.trackingListeners.keys()].map(key => [key, []])
        );
    }

    /**
     * @returns {Object}
     */
    getSerializableMouseTrackingData() {
        return Object.fromEntries(this.mouseTrackingData);
    }
}

/**
 * Throttles down a function so that it can be called at a maximum frequency of freqHz.
 * @param {() => {}} func
 * @param {Number} freqHz
 */
export const throttle = (func, freqHz) => {
    let lastUpdate = null;
    const minPauseMillis = 1000 / freqHz;
    return (...args) => {
        const now = Date.now();
        if (!lastUpdate || now - lastUpdate >= minPauseMillis) {
            lastUpdate = now;
            func(...args);
        }
    };
};

/**
 * @typedef {Object} Dimension2D
 * @property {Number} width
 * @property {Number} height
 *
 * @typedef {Object} Point2D
 * @property {Number} x
 * @property {Number} y
 */

/**
 * Adjust a dimension dim that is part of a current dimensions so that
 * current fits the maxConstraint dimension.
 * Dim keeps its aspect ratio.
 * Returns new dimension dim or null if maxConstraint not solvable.
 * Returns the input dim when constraint already solved.
 * @param {Dimension2D} dim
 * @param {Dimension2D} current
 * @param {Dimension2D} maxConstraint
 * @return {Dimension2D|null}
 */
export const adjustDimensionsToMaxConstraint = (
    dim,
    current,
    maxConstraint
) => {
    const newDim = { ...dim };

    // not solvable due to dim being larger than container? (misuse of function: dim has to be part of current)
    if (dim.width > current.width || dim.height > current.height) {
        return null;
    }

    const currentWithoutDim = {
        width: current.width - dim.width,
        height: current.height - dim.height
    };
    // not solvable due to containerWithoutDim already out of constraint?
    if (
        currentWithoutDim.width > maxConstraint.width ||
        currentWithoutDim.height > maxConstraint.height
    ) {
        return null;
    }

    let widthDiff = current.width - maxConstraint.width;
    let heightDiff = current.height - maxConstraint.height;
    const dimRatio = newDim.width / newDim.height;

    // multiple iterations possible when one dimensions was adjusted,
    // but the other is still out of constraint
    while (widthDiff > 0 || heightDiff > 0) {
        let widthAdjust = 0;
        let heightAdjust = 0;

        // adjust the dimension that's out of constraint more
        if (widthDiff >= heightDiff) {
            widthAdjust = widthDiff;
            heightAdjust = widthDiff / dimRatio;
        } else {
            heightAdjust = heightDiff;
            widthAdjust = heightAdjust * dimRatio;
        }

        newDim.width -= widthAdjust;
        newDim.height -= heightAdjust;
        widthDiff =
            newDim.width + currentWithoutDim.width - maxConstraint.width;
        heightDiff =
            newDim.height + currentWithoutDim.height - maxConstraint.height;
    }

    return newDim;
};

/**
 * Transforms a point in dimension dimA to dimension dimB.
 * dimA and dimB have to be positive!
 * Transformed point x,y components below 0 are be clamped to 0.
 * Returns the same point if dimensions are equal.
 * @param {Point2D} point
 * @param {Dimension2D} dimA
 * @param {Dimension2D} dimB
 * @param {Boolean} clampSubPixelToDimB clamp transformed point to dimB if x,y components come out subpixel-larger
 *                                      than dimB (e.g. 50.35 but dimB.width is 50. Anything >=51 wouldn't be clamped).
 * @returns {Point2D}
 */
export const transformPoint2DFromDimensions = (
    point,
    dimA,
    dimB,
    clampSubPixelToDimB = true
) => {
    const newPoint = { ...point };
    // no transform necessary
    if (dimA.width === dimB.width && dimA.height === dimB.height) {
        return newPoint;
    }
    newPoint.x *= dimB.width / dimA.width;
    newPoint.y *= dimB.height / dimA.height;

    // clamp negatives to 0
    if (newPoint.x < 0) {
        newPoint.x = 0;
    }
    if (newPoint.y < 0) {
        newPoint.y = 0;
    }

    if (clampSubPixelToDimB) {
        const xDiff = newPoint.x - dimB.width;
        const yDiff = newPoint.y - dimB.height;
        // clamp only when transformed point is larger by <1 pixel
        if (xDiff > 0 && xDiff < 1) {
            newPoint.x = dimB.width;
        }
        if (yDiff > 0 && yDiff < 1) {
            newPoint.y = dimB.height;
        }
    }
    return newPoint;
};

/**
 * Get the ratio of the resized dimensions to its original dimensions
 * for an HTMLImageElement.
 * @param {HTMLImageElement} image
 */
export const getHTMLImageElementResizedToOriginalRatio = image => {
    return {
        x: image.width / image.naturalWidth,
        y: image.height / image.naturalHeight
    };
};

/**
 *
 * @param {Point2D} point
 * @param {Point2D} squarePosition
 * @param {Number} squareWidth
 */
export const checkPointInSquare = (point, squarePosition, squareWidth) => {
    const halfWidth = squareWidth / 2;
    return (
        point.x >= squarePosition.x - halfWidth &&
        point.x <= squarePosition.x + halfWidth &&
        point.y >= squarePosition.y - halfWidth &&
        point.y <= squarePosition.y + halfWidth
    );
};

/**
 * Returns run-length-encoded string of a string or an array of numbers.
 * Examples with default groupBegin, groupEnd:
 * WWWBEEW --> 3<W>1<B>2<E>1<W>
 * 000101111011 --> 3<0>1<1>1<0>4<1>1<0>2<1>
 * @param {Iterable<Number|String>} iterableData string or array of numbers
 * @param {String=} groupBegin default: <. Set to "" when you're sure there are no numbers in the iterableData
 * @param {String=} groupEnd default: <. Set to "" when you're sure there are no numbers in the iterableData
 * @returns {String}
 */
export const runLengthEncoding = (
    iterableData,
    groupBegin = "<",
    groupEnd = ">"
) => {
    const formatGroup = (cnt, token) =>
        cnt + "" + groupBegin + (token + "") + groupEnd;

    let out = "";
    let currentGroupCnt = 0;
    let currentToken = null;
    for (const val of iterableData) {
        // init
        if (currentToken === null) {
            currentToken = val;
        }

        if (val === currentToken) {
            currentGroupCnt++;
        } else {
            // remember current group count
            out += formatGroup(currentGroupCnt, currentToken);

            // next group starts and has already 1 token
            currentToken = val;
            currentGroupCnt = 1;
        }
    }

    // the loop doesn't consider the very last group of the iterableData
    if (currentGroupCnt > 0) {
        out += formatGroup(currentGroupCnt, currentToken);
    }
    return out;
};

/**
 * @param {Number[]} fromPoint point as array x, y [, z]
 * @param {Number[]} toPoint point as array x, y [, z]
 * @return {Number[]} normed direction vector x, y [, z]
 */
export const getDirectionNormedFromPointToPoint = (fromPoint, toPoint) => {
    const direction = subtract(toPoint, fromPoint);
    const distance = norm(direction);
    return direction.map(val => val / distance);
};

/**
 * @param {Number[]} referencePoint point as array x, y [, z]
 * @param {Number[]} directionNormed point as array x, y [, z]
 * @param {Number} distance
 * @returns {Number[]} a point on the line at the specified distance from the reference point
 */
export const getPointOnLineAtDistance = (
    referencePoint,
    directionNormed,
    distance
) => {
    return referencePoint.map(
        (val, idx) => val + directionNormed[idx] * distance
    );
};
