import MockData from "../MockData/MockData";
import MockApiClient from "../MockData/MockApiClient";
import ResourceLoaderService from "../ResourceLoaderService";

import BaseGuiType from "../BaseGuiType";
import BBox2DLabeledWidget from "./Components/BBox2DLabeledWidget";
import { WIDGETS } from "../../guiFactory";
import { ActionButtonGroup } from "./Components/ActionButtonGroup.jsx";
import { Question } from "./Components/Question";
import BBox2DModelLabeled from "../../lib/DataModels/BBox2DModelLabeled";

import { demoInsectImageObjects } from "../sharedMockTaskInputs";

export class BoundingBox2DLabeled extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return WIDGETS.BOUNDING_BOX_2D_LABELED;
    }

    static makeMockData() {
        const bb1 = new BBox2DModelLabeled(20, 20, 50, 50);
        const bb2 = new BBox2DModelLabeled(550, 300, 800, 700);
        const bb3 = new BBox2DModelLabeled(
            200,
            20,
            400,
            40,
            "Preselected BBox 1"
        );
        const taskInputs = demoInsectImageObjects.slice(0, 4);
        taskInputs[0].initial_boxes = [bb2, bb3];
        taskInputs[2].initial_boxes = [bb1, bb3];
        taskInputs[0].image_url =
            "https://autocranedevdata.blob.core.windows.net/qm-2020-10-30-minicrane/left_trolley_cam/2020-10-30T11_51_14_579Z.jpg?st=2020-10-29T15%3A53%3A11Z&se=2021-10-30T15%3A53%3A11Z&sp=r&sv=2019-07-07&sr=b&sig=k3W/BMyXSiLWWqa73yPnMisMVa6hoDxq5iFcwW3WR3o%3D";
        const mockApiClient = new MockApiClient(
            {
                instructions: [],
                question: {
                    text: "Match the box to the insect's head."
                }
                //current_bbox_color: "blue",
                //bbox_color: "black",
                //crosshair_line_width: 3,
                //bbox_line_width: 3,
                //crosshair_color: "red",
                //label_text_color: "red",
                //disable_labels: true
            },
            WIDGETS.BOUNDING_BOX_2D_LABELED,
            taskInputs
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeImageLoaderService(["image_url"])
        );
    }

    /**
     * @override
     */
    getHeading() {
        return Question;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return BBox2DLabeledWidget;
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return ActionButtonGroup;
    }

    /**
     * @override
     */
    getInitialCurrentTaskOutput() {
        let boundingBoxes = [];
        if (this.taskUIContext.getCurrentTaskInput().initial_boxes) {
            boundingBoxes = this.taskUIContext.getCurrentTaskInput().initial_boxes;
        }
        return {
            ...super.makeTaskOutputForCurrentTask(),
            bounding_boxes: boundingBoxes
        };
    }

    /**
     * @inheritdoc
     */
    makeTaskOutputForCurrentTask(boundingBoxes) {
        let taskOutput = super.makeTaskOutputForCurrentTask();
        taskOutput = {
            bounding_boxes: boundingBoxes,
            ...taskOutput
        };
        return taskOutput;
    }
}

export default BoundingBox2DLabeled;
