import React from "react";
import { ActionButtonNextOrSubmit } from "../../components/ActionButton/ActionButton";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const ActionButtonGroup = ({ taskUIContext, currentTaskOutput }) => {
    const disabledActionButtons = taskUIContext.shouldActionButtonsBeDisabled();
    const taskOutputDoesntExists = currentTaskOutput
        ? taskUIContext
              .getCurrentGuiObject()
              .isInitialCurrentTaskOutput(currentTaskOutput)
        : true;

    return (
        <div>
            <ActionButtonNextOrSubmit
                taskUIContext={taskUIContext}
                actionSuffix="set_pixels"
                disabled={disabledActionButtons || taskOutputDoesntExists}
                createTaskOutput={() => currentTaskOutput}
                className="is-primary"
            >
                Next
            </ActionButtonNextOrSubmit>
        </div>
    );
};
