import React from "react";

/**
 *  svg indicator at cursor for current brush tool
 * @param {Number} width
 * @param {Number} height
 * @param {Number} xPos
 * @param {Number} yPos
 * @param {Boolean} useEraser
 * @param {Number} eraserSize
 * @param {Number} markerRadius
 */
export const makeSVGBrushIndicator = (
    width,
    height,
    xPos,
    yPos,
    useEraser,
    eraserSize,
    markerRadius
) => {
    if (!width || !height || !xPos || !yPos) {
        return null;
    }

    const makeEraserIndicator = () => {
        const x = xPos - eraserSize / 2;
        const y = yPos - eraserSize / 2;
        return (
            <rect
                id="eraser_indicator"
                x={x}
                y={y}
                width={eraserSize}
                height={eraserSize}
                stroke="#F00"
                fill="transparent"
                strokeWidth="1"
            />
        );
    };
    const makeMarkerIndicator = () => {
        return (
            <circle
                id="marker_indicator"
                cx={xPos}
                cy={yPos}
                r={markerRadius / 2}
                stroke="#F00"
                fill="transparent"
                strokeWidth="1"
            />
        );
    };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={"0 0 " + width + " " + height}
            width={width}
            height={height}
            style={{
                position: "absolute",
                top: 0,
                left: 0
            }}
        >
            {useEraser ? makeEraserIndicator() : makeMarkerIndicator()}
        </svg>
    );
};
