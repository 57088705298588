import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Carousel } from "react-bootstrap";
import Button from "@material-ui/core/Button";

import { getInstructions } from "../../store/reducers/guiSettings";
import ActionButton from "../ActionButton/ActionButton";
import RawHTML from "../RawHTML/RawHTML";

import "bootstrap/dist/css/bootstrap.min.css";
import "./InstructionsGaming.css";

/**
 * if you want no instructions either omit instructions from guiSettings or:
 *   instructions: "", // no instructions
 *   instructions: [], // no instructions
 *
 * @param {Object} props
 * @param {import("../../lib/TaskUIStrategy/taskUIContext").TaskUIContext} props.taskUIContext
 * @param {String|String[]} props.instructions
 */
export let InstructionsGaming = ({ taskUIContext, instructions }) => {
    const onCloseInstructions = () => {
        taskUIContext.closeInstructions();
    };

    useEffect(() => {
        const eventName = "closeInstructions";
        document.addEventListener(eventName, onCloseInstructions);
        return () =>
            document.removeEventListener(eventName, onCloseInstructions);
        // eslint-disable-next-line
    }, []);

    let res = null;
    if (Array.isArray(instructions)) {
        if (instructions.length === 1) {
            // we expect an instruction video url
            res = createVideoInstructionsPage(instructions);
        } else {
            res = createCarousel(instructions);
        }
    }
    return res;
};

const mapStateToProps = state => ({
    instructions: getInstructions(state)
});

InstructionsGaming = connect(mapStateToProps)(InstructionsGaming);

// const createRawHTMLNode = (htmlString, className) => {
//     return <RawHTML htmlString={htmlString} className={className} />;
// };

const createCarousel = htmlStrings => {
    const slideNodes = htmlStrings.map(htmlString => (
        <RawHTML
            htmlString={htmlString}
            className={"instructionsCarouselSlide image"}
        />
    ));
    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "#ebeae8",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                color: "#666",
                fontSize: "1.5rem",
                fontFamily: "Oswald"
            }}
        >
            <div className="instructionsCarouselContainer new-container-qm">
                <Carousel interval={null} indicators={false}>
                    {slideNodes.map((slide, idx) => (
                        <Carousel.Item key={idx}>{slide}</Carousel.Item>
                    ))}
                </Carousel>

                <ActionButton
                    id="understood-button"
                    className="dag_button"
                    onClick={e =>
                        document.dispatchEvent(new Event("closeInstructions"))
                    }
                >
                    Understood
                </ActionButton>
            </div>
        </div>
    );
};

const createVideoInstructionsPage = videoUrl => {
    return (
        <div
            id="ivc"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "#f5f5f5",
                width: "100%",
                height: "100%"
            }}
        >
            <h3 style={{ textAlign: "center" }}>Instructions</h3>
            <video controls style={{ maxHeight: "50vh", marginBottom: "20px" }}>
                <source src={videoUrl} />
            </video>
            <Button
                onClick={() =>
                    document.dispatchEvent(new Event("closeInstructions"))
                }
                color="primary"
            >
                Understood
            </Button>
        </div>
    );
};

export default InstructionsGaming;
