import MockData from "../MockData/MockData";
import MockApiClient from "../MockData/MockApiClient";
import BaseGuiType from "../BaseGuiType";
import { WIDGETS } from "../../guiFactory";
import { demoInsectImageObjects } from "../sharedMockTaskInputs";
import { SelectKeypointWidget } from "./SelectKeypointWidget";
import { ActionButtonGroup } from "./ActionButtonGroup";
import { Question } from "./Question";
import ResourceLoaderService from "../ResourceLoaderService";

export class KeypointSelection extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return "keypoint_selection";
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const taskInputs = demoInsectImageObjects.slice(0, 4);
        const mockApiClient = new MockApiClient(
            {
                instructions: [],
                question: { text: "Please select the insect's eye." },
                zoom: { scale: 2, timeout_ms: 1500 },
                with_multiple_keypoints: true, // optional
                marker: {
                    outer_width: 20,
                    inner_width: 5,
                    set_color: "#F00",
                    cursor_color: "#F00"
                } // optional, but if set all properties are required
            },
            WIDGETS.KEYPOINT_SELECTION,
            taskInputs
        );
        const mockData = new MockData(mockApiClient);
        // if set: existing instructions won't be shown on initial render, but can be opened with the button
        // mockData.mockQueryParams.set("disable_instructions");
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeImageLoaderService(["image_url"])
        );
    }

    /**
     * @override
     */
    getHeading() {
        return Question;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return SelectKeypointWidget;
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return ActionButtonGroup;
    }

    /**
     *
     * @override
     */
    getInitialCurrentTaskOutput() {
        let taskOutput = super.makeTaskOutputForCurrentTask();
        taskOutput = { keypoints: [], ...taskOutput };
        return taskOutput;
    }

    /**
     * @inheritdoc
     */
    makeTaskOutputForCurrentTask(overrides) {
        let taskOutput = super.makeTaskOutputForCurrentTask(overrides);
        taskOutput = { keypoints: [], ...taskOutput };
        return taskOutput;
    }
}

export default KeypointSelection;
