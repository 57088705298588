import React from "react";

import BaseGuiType from "../BaseGuiType";
import MockApiClient from "../MockData/MockApiClient";
import MockData from "../MockData/MockData";
import ResourceLoaderService from "../ResourceLoaderService";
import {
    makeImageLoader,
    Loader,
    fetchLoad
} from "../../lib/DataLoader/loaders";
import { decodeProtobufFromResponseCreator } from "../../lib/DataLoader/decoders";
import { RGBAndBEVView } from "./RGBAndBEVView";
import { WIDGETS } from "../../guiFactory";
import { ActionButtonGroup } from "./ActionButtonGroup";
import { Question } from "./Question";
import { pointcloudCarTaskInputs } from "./MockTaskInputs";

const PointsTable = require("../../lib/proto/points_table_pb");

/**
 * PointcloudProjection UI for choosing a center of gravity in a point cloud.
 */
export class Pointcloud3DBB extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return WIDGETS.POINTCLOUD_BOUNDING_BOX_3D;
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const taskInputs = pointcloudCarTaskInputs;
        const mockApiClient = new MockApiClient(
            {
                instructions: [],
                // instructions: [
                //     `
                //     <div id="ivc" style="display: flex; flex-direction: column; align-items: center;">
                //         <video controls style="width: 960px; margin-bottom: 20px;">

                //             <source src="https://qm-instructions.s3.eu-central-1.amazonaws.com/romanesco/RM_PARKEDCAR_COG_INSTRUCTIONS.mp4" />
                //         </video>
                //         <button class="button is-sm is-green" style="width: 160px;" onclick="document.dispatchEvent(new Event('closeInstructions'))">I understand</button>
                //     </div>
                //     `
                // ],

                // general required settings
                question: {
                    text: "Adjust the cube to fit the highlighted object."
                },

                // general optional settings
                required_actions: {
                    adjust_orientation: "adjust orientation",
                    adjust_box_sides: "adjust box sides",
                    adjust_box_top_bottom: "adjust box top or bottom"
                },

                // always required
                max_points_cnt: 25000,
                // required for box only
                minimal_box_dims: {
                    front: 0.1,
                    back: 0.1,
                    left: 0.1,
                    right: 0.1,
                    top: 0.1,
                    bottom: 0.1
                },
                // box optional
                box_line_width: 4,
                box_color: "#F0F",
                arrow_visible: true,
                arrow_line_width: 4,
                arrow_color: "#F0F",
                // others that are optional
                bg_color: "#000",
                cog_line_width: 4,
                cog_color: "#F0F",
                color_map: "turbo_light",
                transform_fit: "ZOOM_TO_INITIAL_COG",
                initial_zoom: 83,
                mouse_point_highlight_dist: 50,
                point_radius: 1.5,
                highlighted_point_radius: 1.5,
                point_opacity: 0.3,
                highlighted_point_opactiy: 1,
                rgb_mouse_point_highlight_canvas_dist: 20,
                rgb_image_bbox2d_line_width: 2,
                rgb_image_bbox2d_color: "#0F0",
                rgb_point_radius: 1.5,
                rgb_highlighted_point_radius: 3,
                rgb_point_opacity: 0.0,
                rgb_highlighted_point_opacity: 0.6,
                cursor_size: 40,
                cursor_color: "#F00",
                box_intersection_line_width: 4,
                box_intersection_color: "#0F0",
                existing_box_line_width: 4,
                existing_box_color: "#A0A"
            },
            Pointcloud3DBB.getName(),
            taskInputs
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeCustomLoaderService(
                new Map([
                    ["image_url", makeImageLoader(null)],
                    [
                        "pb_data_url",
                        new Loader(
                            null,
                            fetchLoad,
                            decodeProtobufFromResponseCreator(PointsTable.Table)
                        )
                    ]
                ])
            )
        );
    }

    /**
     * @override
     */
    getHeading() {
        return Question;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return props => <RGBAndBEVView {...props} type="box" />;
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return ActionButtonGroup;
    }

    /**
     * Empty MetaActionButtonGroup: merged into ActionButtonGroup
     * @override
     */
    getMetaActionButtonGroup() {
        return () => null;
    }

    /**
     * @override
     */
    getInitialCurrentTaskOutput() {
        return {
            ...super.makeTaskOutputForCurrentTask(),
            cog: { x: null, y: null, z: null },
            box: {
                front: null,
                back: null,
                left: null,
                right: null,
                top: null,
                bottom: null
            },
            orientation: null
        };
    }

    /**
     * @override
     */
    makeTaskOutputForCurrentTask(overrides) {
        return {
            ...this.getInitialCurrentTaskOutput(),
            ...overrides
        };
    }
}

export default Pointcloud3DBB;
