import React from "react";
import { ActionButtonNextOrSubmit } from "../../components/ActionButton/ActionButton";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const ActionButtonGroup = ({ taskUIContext, currentTaskOutput }) => {
    const disabledActionButtons = taskUIContext.shouldActionButtonsBeDisabled();
    const taskOutputDoesntExists = currentTaskOutput === null;
    return (
        <div>
            <ActionButtonNextOrSubmit
                taskUIContext={taskUIContext}
                actionSuffix="set_keypoint"
                disabled={
                    disabledActionButtons ||
                    taskOutputDoesntExists ||
                    taskUIContext
                        .getCurrentGuiObject()
                        .isInitialCurrentTaskOutput(currentTaskOutput)
                }
                createTaskOutput={() => currentTaskOutput}
                className="is-primary"
            >
                Set Keypoint
            </ActionButtonNextOrSubmit>
        </div>
    );
};
