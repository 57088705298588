import React from "react";
import { IoIosArrowDropdownCircle, IoIosArrowDropup } from "react-icons/io";

export class DraggableWindow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            grabbing: false,
            clickOffset: null,
            pos: { x: 0, y: 50 },
            height: 100,
            width: 300,
            extended: false
        };
        this.boundHandleMouseMove = this.handleMouseMove.bind(this);
        this.boundHandleMouseUp = this.handleMouseUp.bind(this);
        this.boundHandleMouseDown = this.handleMouseDown.bind(this);
        this.extend = this.extend.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousemove", this.boundHandleMouseMove);
        document.addEventListener("mouseup", this.boundHandleMouseUp);
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.boundHandleMouseMove);
        document.removeEventListener("mouseup", this.boundHandleMouseUp);
    }

    /**
     * @param {MouseEvent} e already native event because handler is directly on DOM
     */
    handleMouseMove(e) {
        if (!this.state.grabbing) {
            return;
        }
        const client = { x: e.clientX, y: e.clientY };
        const { clickOffset } = this.state;

        // transform mouse screen position (e.clientX) to
        const newPos = {
            x: client.x - clickOffset.x,
            y: client.y - clickOffset.y
        };

        const inRangePos = { ...newPos };
        if (newPos.x < 0) {
            inRangePos.x = 0;
        }
        if (newPos.y < 0) {
            inRangePos.y = 0;
        }

        this.setState({ pos: inRangePos });
    }

    /**
     * @param {MouseEvent} e already native event because handler is directly on DOM
     */
    handleMouseUp(e) {
        if (this.state.grabbing) {
            this.setState({ grabbing: false });
        }
    }

    /**
     * @param {import("react").MouseEvent} e react event because handler is on react element
     */
    handleMouseDown(e) {
        this.setState({
            grabbing: true,
            clickOffset: { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }
        });
    }

    extend() {
        this.setState({ extended: !this.state.extended });
    }

    render() {
        const { pos, grabbing } = this.state;
        const containerWidth = this.props.containerWidth
            ? this.props.containerWidth
            : 400;
        const buttonWidth = 32;
        const style = { color: "dark-grey", background: "white" };

        return (
            <div
                id="draggable_window"
                style={{
                    position: "absolute",
                    top: pos.y,
                    left: pos.x,
                    zIndex: "3",
                    pointerEvents:
                        this.props.extendable && this.state.extended
                            ? "auto"
                            : "none"
                }}
            >
                <div
                    id="draggable_window_draggable_bar"
                    style={{
                        position: "relative",
                        top: 0,
                        left: 0,
                        width: "auto",
                        height: "35px",
                        cursor: grabbing ? "grabbing" : "grab",
                        padding: "5px",
                        borderBottom: "2px gray solid",
                        pointerEvents: "auto",
                        ...style
                    }}
                    onMouseDown={this.boundHandleMouseDown}
                >
                    <h5
                        id="draggable_window_heading"
                        style={{
                            WebkitUserSelect: "none",
                            khtmlUserSelect: "none",
                            MozUserSelect: "none",
                            msUserSelect: "none",
                            OUserSelect: "none",
                            userSelect: "none"
                        }}
                    >
                        {this.props.heading}
                    </h5>
                </div>
                {this.props.extendable ? (
                    <button
                        id="draggable_window_extend_button"
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            height: "35px",
                            width: buttonWidth,
                            border: "none",
                            borderBottom: "2px gray solid",
                            pointerEvents: "auto",
                            ...style
                        }}
                        onClick={this.extend}
                    >
                        {this.state.extended ? (
                            <IoIosArrowDropup />
                        ) : (
                            <IoIosArrowDropdownCircle />
                        )}
                    </button>
                ) : null}

                <div
                    style={{
                        visibility:
                            this.props.extendable && !this.state.extended
                                ? "hidden"
                                : "visible",
                        position: "relative",
                        overflowY: "scroll",
                        overflowX: "auto",
                        width: containerWidth,
                        height: "300px",
                        resize: "both",
                        minHeight: 50,
                        minWidth: 250,
                        ...style
                    }}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default DraggableWindow;
