import React from "react";
import deepEqual from "deep-equal";

import ActionTelemetry from "./ActionTelemetry";
import { throwAbstractError } from "../lib/qm_cs_lib";
import { ConnectedProgressBar } from "../components/ProgressBar/ProgressBar";
import SimpleLayout from "./Layouts/SimpleLayout";
import addTaskUIProps from "../containers/TaskUI/addTaskUIProps";
import { withContainerLayout } from "./Layouts/ContainerLayout";
import DefaultMetaActionButtonGroup from "../components/ActionButton/DefaultMetaActionButtonGroup";
import RequiredActionDefinition from "./RequiredActionDefinition";
import { WIDGETS } from "../guiFactory";

/**
 * Takes React Components and returns an instantiated Layout that makes use of the
 * parameters.
 * @callback LayoutCreator
 * @param {typeof React.Component} heading
 * @param {typeof React.Component} dataVisualization
 * @param {typeof React.Component} progressBar
 * @param {typeof React.Component} actionButtonGroup
 * @param {typeof React.Component} metaActionButtonGroup
 * @returns {React.Element}
 */

export class BaseGuiType {
    /**
     *
     * @param {import("../lib/TaskUIStrategy/taskUIContext").TaskUIContext} taskUIContext
     */
    constructor(taskUIContext) {
        this.taskUIContext = taskUIContext;

        /**
         * @type {Map<String, import("./ActionTelemetry").ActionTelemetry}
         */
        this.actionTelemetries = new Map();

        /**
         * @type {import("./ResourceLoaderService").ResourceLoaderService}
         */
        this.resourceLoaderService = null;

        this.init();
    }

    /**
     * @returns {String}
     */
    static getName() {
        throwAbstractError();
    }

    /**
     * @returns {import("./MockData/MockData").MockData}
     */
    static makeMockData() {
        throwAbstractError();
    }

    init() {
        this.initResourceLoaderService();
    }

    initResourceLoaderService() {
        throwAbstractError();
    }

    /**
     * @returns {import("./ResourceLoaderService").ResourceLoaderService}
     */
    getResourceLoaderService() {
        if (!this.resourceLoaderService) {
            throw new Error("ResourceLoaderService not set!");
        }
        return this.resourceLoaderService;
    }

    /**
     * Creates a serializable task output object.
     * @returns {Object}
     */
    createTaskOutput() {
        throwAbstractError();
    }

    /**
     * Should return a function that creates a layout component using the react components
     * that the guitype object provides
     * @returns {LayoutCreator}
     */
    getLayoutCreator() {
        return this.getDefaultLayoutCreator();
    }

    /**
     * Should return Component classes or functions and not an instantiated Component!
     * @returns {typeof React.Component}
     */
    getHeading() {
        throwAbstractError();
    }

    /**
     * Should return Component classes or functions and not an instantiated Component!
     * @returns {typeof React.Component}
     */
    getDataVisualization() {
        throwAbstractError();
    }

    /**
     * Should return Component classes or functions and not an instantiated Component!
     * @returns {typeof React.Component}
     */
    getActionButtonGroup() {
        throwAbstractError();
    }

    /**
     * @returns {typeof React.Component}
     */
    getProgressBar() {
        return ConnectedProgressBar;
    }

    /**
     * @returns {typeof React.Component}
     */
    getMetaActionButtonGroup() {
        return DefaultMetaActionButtonGroup;
    }

    /**
     * @returns {String}
     */
    getInstructionsViewName() {
        return WIDGETS.INSTRUCTIONS;
    }

    /**
     * @returns {String}
     */
    getThankYouViewName() {
        return WIDGETS.THANK_YOU;
    }

    /**
     * The basic taskOutput for a task contains cant_solve, and corrupt_data
     * @param {Object} overrides
     * @param {Boolean} overrides.cant_solve
     * @param {Boolean} overrides.corrupt_data
     * @returns {Object}
     */
    makeTaskOutputForCurrentTask(overrides = {}) {
        return { cant_solve: false, corrupt_data: false, ...overrides };
    }

    /**
     * @param {import("./ResourceLoaderService").ResourceLoaderService} resourceLoaderService
     */
    setResourceLoaderService(resourceLoaderService) {
        this.resourceLoaderService = resourceLoaderService;
    }

    /**
     * Checks whether every resource url of task with taskInputIdx is in resource cache.
     * A no-op ResourceLoaderService creates an empty map for a taskInput.
     * @param {Number} taskInputIdx
     */
    taskInputResourcesInCache(taskInputIdx) {
        if (taskInputIdx === undefined) {
            throw new Error("missing parameter: taskInputIdx");
        }

        const resourceCache = this.taskUIContext.getResourceCache();
        const taskInputLoaderMaps = this.resourceLoaderService.getTaskInputLoaderMaps();
        for (const resourceUrl of taskInputLoaderMaps[taskInputIdx].keys()) {
            if (!(resourceUrl in resourceCache)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Checks whether every resource url of task with taskInputIdx was loaded without error.
     * @param {Number} taskInputIdx
     */
    taskInputResourcesLoadedWithError(taskInputIdx) {
        const resourceLoadingErrors = this.taskUIContext.getResourceLoadingErrors();
        const taskInputLoaderMaps = this.resourceLoaderService.getTaskInputLoaderMaps();
        for (const resourceUrl of taskInputLoaderMaps[taskInputIdx].keys()) {
            if (resourceUrl in resourceLoadingErrors) {
                return true;
            }
        }
        return false;
    }

    /**
     * @param {typeof React.Element[]} components
     * @returns {React.Element[]}
     */
    connectAndInstantiateComponents(components) {
        return components
            .map(a => addTaskUIProps(a))
            .map((A, i) => <A key={i} taskUIContext={this.taskUIContext} />);
    }

    /**
     * Returns a LayoutCreator
     *
     * @param {React.CSSProperties} containerLayoutStyle
     * @returns {LayoutCreator}
     */
    getDefaultLayoutCreator(containerLayoutStyle) {
        return (
            heading,
            dataVis,
            progressBar,
            actionButtonGroup,
            metaActionButtonGroup
        ) => {
            const Layout = withContainerLayout(
                SimpleLayout,
                containerLayoutStyle
            );
            const components = [
                heading,
                dataVis,
                progressBar,
                actionButtonGroup,
                metaActionButtonGroup
            ];
            const instantiatedComponents = this.connectAndInstantiateComponents(
                components
            );
            return <Layout>{instantiatedComponents}</Layout>;
        };
    }

    /**
     * Returns the initial value for the currentTaskOutput redux state.
     * If overridden: make sure to override isInitialCurrentTaskOutput accordingly!
     */
    getInitialCurrentTaskOutput() {
        return null;
    }

    /**
     * Determines whether the given taskOutput matches the initialCurrentTaskOutput that is returned from
     * getInitialCurrentTaskOutput.
     * @param {any} taskOutput
     */
    isInitialCurrentTaskOutput(taskOutput) {
        return deepEqual(this.getInitialCurrentTaskOutput(), taskOutput);
    }

    /**
     * Create or increment an action telemetry and log it.
     * TODO: callbacks for training: preWorkpackage, onAction, postTask, postWorkpackage
     * @param {String} actionName
     * @param {Number=} stopCnt default null. If set then the action counter won't be incremented higher than this value.
     */
    onAction(actionName, stopCnt = null) {
        if (!this.actionTelemetries.has(actionName)) {
            this.actionTelemetries.set(actionName, new ActionTelemetry());
        }
        const actionTelemetry = this.actionTelemetries.get(actionName);
        if (stopCnt === null || actionTelemetry.cnt < stopCnt) {
            actionTelemetry.incCnt();
            console.log(
                "%conAction: " + actionName,
                "background-color: beige; color: #1f2d3d;",
                actionTelemetry
            );
        }
    }

    /**
     * Is called by TaskUIContext after the action to switch to previous task was dispatched
     */
    onDispatchPreviousTask() {
        this.deleteRequiredActionsFromActionTelemetries();
    }

    /**
     * Is called by TaskUIContext after the action to switch to next task was dispatched.
     * This isn't called on submit
     */
    onDispatchNextTask() {
        this.deleteRequiredActionsFromActionTelemetries();
    }

    /**
     * Should return an array of required action definitions that are used to check
     * whether those actions have happened in the UI.
     * Tries to read required_actions from gui_settings and returns them in the correct format.
     * If they don't exist in gui_settings: return an empty array.
     * --> Returning an empty array is necessary because this method is used in the BaseGuiType onDispatch callbacks.
     * @returns {import("./RequiredActionDefinition").RequiredActionDefinition[]}
     */
    getRequiredActions() {
        const guiSettings = this.taskUIContext.getGuiSettings();
        if (
            typeof guiSettings.required_actions === "object" &&
            guiSettings.required_actions !== null
        ) {
            const requiredActions = [];
            for (const [key, displayName] of Object.entries(
                guiSettings.required_actions
            )) {
                requiredActions.push(
                    new RequiredActionDefinition(key, displayName)
                );
            }
            return requiredActions;
        }
        return [];
    }

    /**
     * Checks whether the conditions for required actions are met using the action telemetries and required action definitions.
     * Returns those required action definitions where the condition is not met.
     * @returns {import("./RequiredActionDefinition").RequiredActionDefinition[]}
     */
    checkRequiredActions() {
        const requiredActions = this.getRequiredActions();
        const unfulfilledActions = requiredActions.filter(requiredAction => {
            const action = this.actionTelemetries.get(requiredAction.key);
            return !requiredAction.check(action);
        });
        return unfulfilledActions;
    }

    /**
     * Deletes all action telemetries that are defined in getRequiredActions from the actionTelemetries map.
     */
    deleteRequiredActionsFromActionTelemetries() {
        const requiredActions = this.getRequiredActions();
        for (const requiredAction of requiredActions) {
            this.actionTelemetries.delete(requiredAction.key);
        }
    }
}

export default BaseGuiType;
