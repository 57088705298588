import React from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";

/**
 * A button that can trigger its onClick by global keyup event.
 * If the onClick event doesn't receive an event argument, then it was fired
 * via keyup.
 *
 * @typedef {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} ButtonProps
 *
 * @typedef {Object} ActionButtonProps
 * @property {String} ActionButtonProps.actionKey
 *
 * @typedef {Object} ActionButtonPropsWithTaskUIContext
 * @property {import("../../lib/TaskUIStrategy/taskUIContext").TaskUIContext} ActionButtonProps.taskUIContext
 *
 * @param {ButtonProps & ActionButtonProps} props everything except "actionKey" and "actionKeyEnabled" are props from the regular button element
 */
export const ActionButton = ({
    actionKey,
    onClick,
    className,
    children,
    actionKeyEnabled = true,
    ...buttonProps
}) => {
    useEffect(() => {
        const onKeyUp = event => {
            if (actionKey === event.key) {
                onClick();
            }
        };
        if (
            // do explicit checks so that e.g. "0" is an allowed actionKey
            actionKey !== "" &&
            actionKey !== undefined &&
            actionKey !== null &&
            actionKeyEnabled
        ) {
            window.addEventListener("keyup", onKeyUp);
            return () => window.removeEventListener("keyup", onKeyUp);
        }
    }, [onClick, actionKey, actionKeyEnabled]);

    let combinedClassName = "action_button button is-sm";
    if (className) {
        combinedClassName = className + " " + combinedClassName;
    }

    return (
        <button
            onClick={onClick}
            className={combinedClassName}
            {...buttonProps}
        >
            {children}
        </button>
    );
};

ActionButton.defaultProps = {
    children: "Button",
    onClick: () => {},
    actionKey: null
};

ActionButton.propTypes = {
    actionKey: PropTypes.string
};

/**
 * ActionButton that uses the taskUIContext to call onAction, add taskOutput and dispatch next or submit actions
 *
 * @typedef {Object} ActionButtonNextOrSubmitProps
 * @property {String} actionSuffix
 * @property {() => any} createTaskOutput creates and returns the task_output that should be added
 *
 * @param {ButtonProps & ActionButtonPropsWithTaskUIContext & ActionButtonNextOrSubmitProps} props
 */
export const ActionButtonNextOrSubmit = ({
    taskUIContext,
    actionSuffix,
    createTaskOutput,
    children,
    ...buttonProps
}) => {
    return (
        <ActionButton
            id="next_or_submit_button"
            onClick={() => {
                taskUIContext
                    .getCurrentGuiObject()
                    .onAction("next_" + actionSuffix);
                taskUIContext.pushTaskOutput(createTaskOutput());
                taskUIContext.dispatchNextTaskOrSubmit();
            }}
            {...buttonProps}
        >
            {children}
        </ActionButton>
    );
};
ActionButtonNextOrSubmit.defaultProps = {
    children: "Next or Submit",
    actionSuffix: "",
    createTaskOutput: () => {
        throw new Error("createTaskOutput must be defined!");
    }
};

/**
 * ActionButton that pops the latest taskOutput with taskUIContext and dispatches previous task action
 *
 * @param {ButtonProps & ActionButtonPropsWithTaskUIContext} props
 */
export const ActionButtonUndo = ({
    taskUIContext,
    children,
    ...buttonProps
}) => {
    if (taskUIContext.getTaskInputs().length === 1) {
        return null;
    }
    return (
        <ActionButton
            id="undo_previous_task_button"
            onClick={() => {
                taskUIContext.getCurrentGuiObject().onAction("previous");
                taskUIContext.popTaskOutput();
                taskUIContext.dispatchPreviousTask();
            }}
            {...buttonProps}
            disabled={
                taskUIContext.getCurrentTaskIdx() === 0 || buttonProps.disabled
            }
        >
            {children}
        </ActionButton>
    );
};
ActionButtonUndo.defaultProps = {
    children: "Undo"
};

/**
 * ActionButton that uses the taskUIContext to call onAction, add taskOutput and dispatch next or submit actions
 *
 * @param {ButtonProps & ActionButtonPropsWithTaskUIContext} props
 */
export const ActionButtonOpenInstructions = ({
    taskUIContext,
    children,
    ...buttonProps
}) => {
    if (taskUIContext.hasInstructions()) {
        return (
            <ActionButton
                id="open_instructions_button"
                onClick={() => {
                    taskUIContext
                        .getCurrentGuiObject()
                        .onAction("open_instructions");
                    taskUIContext.openInstructions();
                }}
                {...buttonProps}
            >
                {children}
            </ActionButton>
        );
    }
    return null;
};
ActionButtonOpenInstructions.defaultProps = {
    children: "Instructions"
};

/**
 * An ActionButtonNextOrSubmit for the "Can't Solve" taskOutput
 *
 * @param {ButtonProps & ActionButtonPropsWithTaskUIContext} props
 */
export const ActionButtonCantSolve = ({
    taskUIContext,
    children,
    ...buttonProps
}) => {
    return (
        <ActionButtonNextOrSubmit
            id="cant_solve_button"
            taskUIContext={taskUIContext}
            actionSuffix="cant_solve"
            createTaskOutput={() =>
                taskUIContext
                    .getCurrentGuiObject()
                    .makeTaskOutputForCurrentTask({ cant_solve: true })
            }
            {...buttonProps}
        >
            {children}
        </ActionButtonNextOrSubmit>
    );
};
ActionButtonCantSolve.defaultProps = {
    children: "Can't Solve"
};

/**
 * An ActionButtonNextOrSubmit for the "Corrupt" taskOutput
 *
 * @param {ButtonProps & ActionButtonPropsWithTaskUIContext} props
 */
export const ActionButtonCorruptData = ({
    taskUIContext,
    children,
    ...buttonProps
}) => {
    return (
        <ActionButtonNextOrSubmit
            id="corrupt_data_button"
            taskUIContext={taskUIContext}
            actionSuffix="corrupt_data"
            createTaskOutput={() =>
                taskUIContext
                    .getCurrentGuiObject()
                    .makeTaskOutputForCurrentTask({ corrupt_data: true })
            }
            {...buttonProps}
        >
            {children}
        </ActionButtonNextOrSubmit>
    );
};
ActionButtonCorruptData.defaultProps = {
    children: "Corrupt Data"
};

export default ActionButton;
