import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

const CustomTooltip = props => {
    return (
        <Tooltip
            title={
                <div
                    style={{
                        color: "#eee",
                        fontSize: "0.8rem",
                        padding: "3px",
                        textAlign: "justify"
                    }}
                >
                    {props.text}
                </div>
            }
            placement={"top"}
            enterDelay={500}
            enterNextDelay={500}
            arrow
        >
            {props.children}
        </Tooltip>
    );
};

export default CustomTooltip;
