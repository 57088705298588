import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

import {
    adjustDimensionsToMaxConstraint,
    transformPoint2DFromDimensions
} from "../../../lib/qm_cs_lib";
import { useCustomContainerQMResizedCallback } from "../../../customHooks/useContainerQMDimensions";

import RectDiv from "./RectDiv";
import LoadingAnimation from "../../../components/LoadingAnimation/LoadingAnimation";
import BBox2DModelLabeled from "../../../lib/DataModels/BBox2DModelLabeled";
import CrosshairCursor from "./CrosshairCursor";
import BBoxMenu from "./BBoxMenu";
import BBoxLabel from "./BBoxLabel";
import ResizeMarker from "./ResizeMarker";

export const BBox2DLabeledWidget = ({
    guiSettings,
    resourceCache,
    taskUIContext,
    currentTaskIdx,
    currentTaskOutput,
    setCurrentTaskOutput
}) => {
    // position for custom cursor
    const [crosshairPos, setCrosshairPos] = useState({ x: 0, y: 0 });

    // position of mousedown for fixed corner
    const [initialPoint, setInitialPoint] = useState({ x: 0, y: 0 });

    // topLeftPos is the bottom-right corner of the current bbox
    // in relative image coordinates
    const [topLeftPos, setTopLeftPos] = useState({ x: 0, y: 0 });

    // botRightPos is the top-left corner of the current bbox
    // in relative image coordinates
    const [botRightPos, setBotRightPos] = useState({ x: 0, y: 0 });

    const [currentImage, setCurrentImage] = useState(null);

    const [containerQMDimensions, setContainerQMDimensions] = useState({
        width: 0,
        height: 0
    });

    const [viewPortDimensions, setViewPortDimensions] = useState({
        width: 0,
        height: 0
    });

    const [imageOriginalDimensions, setImageOriginalDimensions] = useState({
        width: 0,
        height: 0
    });

    const [imageResizedDimensions, setImageResizedDimensions] = useState({
        width: 0,
        height: 0
    });

    const [dragging, setDragging] = useState(false);

    // currently selected Box for resizing
    const [currentBoxIndex, setCurrentBoxIndex] = useState(null);

    // contains all set boundingboxes as BBox2DModelLabeled
    const [boundingBoxes, setBBoxes] = useState([]);

    // set a cursor defined in CrosshairCursor.jsx
    const [currentCursor, setCurrentCursor] = useState(null);

    // collection of state-Ref's to ensure consistency in the mouseevents
    const draggingRef = useRef(dragging);

    const resizedDimRef = useRef(imageResizedDimensions);

    const initialPointRef = useRef(initialPoint);

    const topLeftPosRef = useRef(topLeftPos);

    const botRightPosRef = useRef(botRightPos);

    const currentBoxIndexRef = useRef(currentBoxIndex);

    // ref in order to get the offset to window of the tight layout div and borderWidth
    const frameRef = useRef();

    // setter for state-Ref's
    const setIsDragging = dragging => {
        draggingRef.current = dragging;
        setDragging(dragging);
    };

    const setResizedDim = imageResizedDimensions => {
        resizedDimRef.current = imageResizedDimensions;
        setImageResizedDimensions(imageResizedDimensions);
    };

    const setInitialPosition = initialPoint => {
        initialPointRef.current = initialPoint;
        setInitialPoint(initialPoint);
    };

    const setTopLeftPosition = topLeftPos => {
        topLeftPosRef.current = topLeftPos;
        setTopLeftPos(topLeftPos);
    };

    const setBotRightPosition = botRightPos => {
        botRightPosRef.current = botRightPos;
        setBotRightPos(botRightPos);
    };

    const setCurrentBoundingBoxIndex = currentBoxIndex => {
        currentBoxIndexRef.current = currentBoxIndex;
        setCurrentBoxIndex(currentBoxIndex);
    };

    //helper functions
    const addBBox = bbox => {
        setBBoxes(oldBoxes => [...oldBoxes, bbox]);
    };

    const deleteBBox = index => {
        setBBoxes(oldBoxes =>
            oldBoxes.filter(function (box, i, arr) {
                return i !== index;
            })
        );
    };

    const removeBBox = index => {
        if (currentBoxIndexRef.current || currentBoxIndexRef.current === 0) {
            // currentlly in adjustment mode
            if (currentBoxIndexRef.current === index) {
                cancelAdjustment();
            } else if (currentBoxIndexRef.current >= index) {
                setCurrentBoundingBoxIndex(currentBoxIndexRef.current - 1);
            } else {
                deHoverBBox();
            }
        } else {
            deHoverBBox();
        }

        // highlight the box your cursor is on after delting a box
        if (index < boundingBoxes.length - 1) {
            hoverBBox(index + 1);
        }

        deleteBBox(index);

        guiObject.onAction("remove_bounding_box");
    };

    // the stored BBox will not be displayed (while it is adjusted)
    const disableBBox = index => {
        setBBoxes(oldBoxes =>
            oldBoxes.map((box, i) => {
                if (index === i) {
                    box.left = false;
                    return box;
                } else {
                    return box;
                }
            })
        );
    };

    // the stored BBox will be displayed (after resizing)
    const enableBBox = index => {
        const bbox = new BBox2DModelLabeled(
            topLeftPosRef.current.x,
            topLeftPosRef.current.y,
            botRightPosRef.current.x,
            botRightPosRef.current.y
        );
        setBBoxes(oldBoxes =>
            oldBoxes.map((box, i) => {
                if (index === i) {
                    bbox.setLabel(box.label);
                    return bbox;
                } else {
                    return box;
                }
            })
        );
    };

    const updateLabel = (index, label) => {
        setBBoxes(oldBoxes =>
            oldBoxes.map((box, i) => {
                if (index === i) {
                    box.setLabel(label);
                    return box;
                } else {
                    return box;
                }
            })
        );
    };

    const selectBBoxToAdjust = index => {
        const box = boundingBoxes[index];
        setTopLeftPosition({ x: box.left, y: box.top });
        setBotRightPosition({ x: box.right, y: box.bottom });
        setCurrentBoundingBoxIndex(index);
    };

    const cancelAdjustment = () => {
        setTopLeftPosition(false);
        setBotRightPosition(false);
        setCurrentBoundingBoxIndex(null);
        setCurrentCursor(null);
    };

    const hoverBBox = index => {
        const box = boundingBoxes[index];
        setTopLeftPosition({ x: box.left, y: box.top });
        setBotRightPosition({ x: box.right, y: box.bottom });
    };

    const deHoverBBox = () => {
        if (currentBoxIndex || currentBoxIndex === 0) {
            selectBBoxToAdjust(currentBoxIndex);
        } else {
            setTopLeftPosition(false);
            setBotRightPosition(false);
        }
    };

    const selectMouseCursor = mousePos => {
        if (
            Math.abs(mousePos.x - topLeftPosRef.current.x) < resizingRange &&
            Math.abs(mousePos.y - botRightPosRef.current.y) < resizingRange
        ) {
            setCurrentCursor("botLeft");
        } else if (
            Math.abs(mousePos.x - topLeftPosRef.current.x) < resizingRange &&
            Math.abs(mousePos.y - topLeftPosRef.current.y) < resizingRange
        ) {
            setCurrentCursor("topLeft");
        } else if (
            Math.abs(mousePos.x - botRightPosRef.current.x) < resizingRange &&
            Math.abs(mousePos.y - botRightPosRef.current.y) < resizingRange
        ) {
            setCurrentCursor("botRight");
        } else if (
            Math.abs(mousePos.x - botRightPosRef.current.x) < resizingRange &&
            Math.abs(mousePos.y - topLeftPosRef.current.y) < resizingRange
        ) {
            setCurrentCursor("topRight");
        } else {
            setCurrentCursor("end_adjustment");
        }
    };

    const getFrameOffset = () => {
        if (!frameRef.current) {
            return { x: 0, y: 0 };
        }

        const borderWidth = parseFloat(frameRef.current.style.borderWidth);
        return {
            x: frameRef.current.getBoundingClientRect().left,
            y: frameRef.current.getBoundingClientRect().top,
            borderWidth: isNaN(borderWidth) ? 0 : borderWidth
        };
    };

    /**
     * Checks whether position is in allowed range. If it exceeds a boundary for an axis,
     * the boundary value will also be returned so the pos can be snapped to that.
     */
    const isInRange = pos => {
        const { width: xRight, height: yBot } = resizedDimRef.current;
        const { x: xLeft, y: yTop } = { x: 0, y: 0 };
        let xBoundary = null;
        let yBoundary = null;
        let xOnImage = true;
        let yOnImage = true;

        // check if x outside of range
        if (pos.x < xLeft) {
            xOnImage = false;
            xBoundary = xLeft;
        }
        if (pos.x > xRight) {
            xOnImage = false;
            xBoundary = xRight;
        }
        // check if y outside of range
        if (pos.y < yTop) {
            yOnImage = false;
            yBoundary = yTop;
        }
        if (pos.y > yBot) {
            yOnImage = false;
            yBoundary = yBot;
        }

        return { xOnImage, yOnImage, xBoundary, yBoundary };
    };

    const resizeBBoxToOriginalImageDimension = bbox => {
        let topLeftPos = { x: bbox.left, y: bbox.top };
        let botRightPos = { x: bbox.right, y: bbox.bottom };
        topLeftPos = transformPoint2DFromDimensions(
            topLeftPos,
            imageResizedDimensions,
            imageOriginalDimensions
        );
        botRightPos = transformPoint2DFromDimensions(
            botRightPos,
            imageResizedDimensions,
            imageOriginalDimensions
        );
        const resizedBox = new BBox2DModelLabeled(
            topLeftPos.x,
            topLeftPos.y,
            botRightPos.x,
            botRightPos.y
        );
        resizedBox.setLabel(bbox.label);

        return resizedBox;
    };

    const resizeBBoxToResizedImageDimension = bbox => {
        let topLeftPos = { x: bbox.left, y: bbox.top };
        let botRightPos = { x: bbox.right, y: bbox.bottom };
        topLeftPos = transformPoint2DFromDimensions(
            topLeftPos,
            imageOriginalDimensions,
            imageResizedDimensions
        );
        botRightPos = transformPoint2DFromDimensions(
            botRightPos,
            imageOriginalDimensions,
            imageResizedDimensions
        );
        const resizedBox = new BBox2DModelLabeled(
            topLeftPos.x,
            topLeftPos.y,
            botRightPos.x,
            botRightPos.y
        );
        resizedBox.setLabel(bbox.label);

        return resizedBox;
    };

    const guiObject = taskUIContext.getCurrentGuiObject();
    const makeAndSetCurrentTaskOutput = () => {
        const boxes = [...boundingBoxes];
        const resizedBoxes = [];
        for (const bbox of boxes) {
            const resizedBox = resizeBBoxToOriginalImageDimension(bbox);
            resizedBoxes.push(resizedBox);
        }

        setCurrentTaskOutput(
            guiObject.makeTaskOutputForCurrentTask(resizedBoxes)
        );
    };

    // get guiSettings and set default values
    const {
        current_bbox_color: currentBoxColor = "#E6194B",
        bbox_color: boundingboxesColor = "#36a143",
        crosshair_line_width: crosshairWidth = 3,
        bbox_line_width: bboxLineWidth = 3,
        crosshair_color: crosshairColor = "black",
        label_text_color: labelTextColor = "white",
        disable_labels: disableLabels = false,
        borderLineWidth = Math.max(crosshairWidth, bboxLineWidth, 2),
        labelHeight = 18,
        resizingRange = 10
    } = guiSettings;

    useCustomContainerQMResizedCallback(
        currentTaskIdx,
        function (
            containerQMDimensions,
            viewPortDimensions,
            containerQm,
            resizeObserver
        ) {
            setContainerQMDimensions(containerQMDimensions);
            setViewPortDimensions(viewPortDimensions);

            // stop observing as soon as resizing has reached viewPort's width or height
            if (
                viewPortDimensions.width !== 0 &&
                viewPortDimensions.height !== 0 &&
                (containerQm.offsetHeight === viewPortDimensions.height ||
                    containerQm.offsetWidth === viewPortDimensions.width)
            ) {
                resizeObserver.unobserve(containerQm);
                return;
            }
        }
    );

    useEffect(() => {
        setTopLeftPosition(false);
        setBotRightPosition(false);
    }, []);

    useEffect(() => {
        makeAndSetCurrentTaskOutput(true);
        // eslint-disable-next-line
    }, [boundingBoxes]);

    // react to changing image here in order to reset positions and currentTaskOutput between tasks
    useEffect(() => {
        if (!currentImage) {
            return;
        }

        const originalDim = {
            width: image.width,
            height: image.height
        };
        const resizedDim = adjustDimensionsToMaxConstraint(
            originalDim,
            containerQMDimensions,
            viewPortDimensions
        );
        // resizedDim is null while image isn't part of container, yet
        const usedImgDim = resizedDim ? resizedDim : originalDim;

        setImageOriginalDimensions(originalDim);
        setResizedDim(usedImgDim);

        const preBoxes = taskUIContext.getCurrentTaskInput().initial_boxes
            ? taskUIContext.getCurrentTaskInput().initial_boxes
            : [];

        const resizedBoxes = [];
        for (const bbox of preBoxes) {
            const resizedBox = resizeBBoxToResizedImageDimension(bbox);
            resizedBoxes.push(resizedBox);
        }

        setBBoxes([...resizedBoxes]);
        setTopLeftPos(false);
        setBotRightPos(false);
        setInitialPoint(false);
        setCrosshairPos(false);
        setCurrentBoundingBoxIndex(null);

        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);

        // eslint-disable-next-line
    }, [currentImage, containerQMDimensions, viewPortDimensions]);

    const handleMouseDown = e => {
        const offset = getFrameOffset();
        const clickPos = {
            x: e.clientX - offset.x - offset.borderWidth,
            y: e.clientY - offset.y - offset.borderWidth
        };

        if (currentBoxIndexRef.current || currentBoxIndexRef.current === 0) {
            // adjustment mode
            if (
                Math.abs(clickPos.x - topLeftPosRef.current.x) <
                    resizingRange &&
                Math.abs(clickPos.y - botRightPosRef.current.y) < resizingRange
            ) {
                // grab bot-left corner
                setTopLeftPosition({
                    x: clickPos.x,
                    y: topLeftPosRef.current.y
                });
                setBotRightPosition({
                    x: botRightPosRef.current.x,
                    y: clickPos.y
                });
                setInitialPosition({
                    x: botRightPosRef.current.x,
                    y: topLeftPosRef.current.y
                });
                setIsDragging(true);
                disableBBox(currentBoxIndexRef.current);
            } else if (
                Math.abs(clickPos.x - topLeftPosRef.current.x) <
                    resizingRange &&
                Math.abs(clickPos.y - topLeftPosRef.current.y) < resizingRange
            ) {
                // grab top-left corner
                setTopLeftPosition(clickPos);
                setInitialPosition(botRightPosRef.current);
                setIsDragging(true);
                disableBBox(currentBoxIndexRef.current);
            } else if (
                Math.abs(clickPos.x - botRightPosRef.current.x) <
                    resizingRange &&
                Math.abs(clickPos.y - botRightPosRef.current.y) < resizingRange
            ) {
                // grab bot-right corner
                setBotRightPosition(clickPos);
                setInitialPosition(topLeftPosRef.current);
                setIsDragging(true);
                disableBBox(currentBoxIndexRef.current);
            } else if (
                Math.abs(clickPos.x - botRightPosRef.current.x) <
                    resizingRange &&
                Math.abs(clickPos.y - topLeftPosRef.current.y) < resizingRange
            ) {
                // grab top-right corner
                setTopLeftPosition({
                    x: topLeftPosRef.current.x,
                    y: clickPos.y
                });
                setBotRightPosition({
                    x: clickPos.x,
                    y: botRightPosRef.current.y
                });
                setInitialPosition({
                    x: topLeftPosRef.current.x,
                    y: botRightPosRef.current.y
                });
                setIsDragging(true);
                disableBBox(currentBoxIndexRef.current);
            } else {
                cancelAdjustment();
            }
        } else {
            // new Box
            setTopLeftPosition(clickPos);
            setBotRightPosition(clickPos);
            setInitialPosition(clickPos);
            setIsDragging(true);
        }
    };

    const handleMouseUp = e => {
        if (!draggingRef.current) {
            return;
        }
        setIsDragging(false);

        if (currentBoxIndexRef.current || currentBoxIndexRef.current === 0) {
            // adjustment mode
            enableBBox(currentBoxIndexRef.current);
            guiObject.onAction("adjust_bounding_box");
        } else {
            // save new BBox
            const bbox = new BBox2DModelLabeled(
                topLeftPosRef.current.x,
                topLeftPosRef.current.y,
                botRightPosRef.current.x,
                botRightPosRef.current.y
            );
            addBBox(bbox);

            setTopLeftPosition(false);
            setBotRightPosition(false);

            guiObject.onAction("create_bounding_box");
        }
    };

    const handleMouseMove = e => {
        const offset = getFrameOffset();
        const mousePos = {
            x: e.clientX - offset.x - offset.borderWidth,
            y: e.clientY - offset.y - offset.borderWidth
        };

        const { xOnImage, yOnImage, xBoundary, yBoundary } = isInRange(
            mousePos
        );

        if (xOnImage && yOnImage) {
            const crosshairPosInRange = { ...mousePos };
            setCrosshairPos(crosshairPosInRange);

            if (
                currentBoxIndexRef.current ||
                currentBoxIndexRef.current === 0
            ) {
                // select cursor for adjustment-mode
                selectMouseCursor(mousePos);
            } else {
                setCurrentCursor("none");
            }
        } else {
            setCrosshairPos(false);
        }

        if (!draggingRef.current) {
            return;
        }

        const inRangePos = { ...mousePos };
        if (!xOnImage) {
            inRangePos.x = xBoundary;
        }
        if (!yOnImage) {
            inRangePos.y = yBoundary;
        }

        const initialPos = { ...initialPointRef.current };

        // make adjustment in every direction possible
        switch (true) {
            // botom right of initial point
            case initialPos.x <= inRangePos.x && initialPos.y <= inRangePos.y:
                setTopLeftPosition(initialPos);
                setBotRightPosition(inRangePos);
                break;
            // top right of initial point
            case initialPos.x <= inRangePos.x && initialPos.y > inRangePos.y:
                setTopLeftPosition({ x: initialPos.x, y: inRangePos.y });
                setBotRightPosition({ x: inRangePos.x, y: initialPos.y });
                break;
            // top left of initial point
            case initialPos.x > inRangePos.x && initialPos.y > inRangePos.y:
                setTopLeftPosition(inRangePos);
                setBotRightPosition(initialPos);
                break;
            // bootom left of initial point
            case initialPos.x > inRangePos.x && initialPos.y <= inRangePos.y:
                setTopLeftPosition({ x: inRangePos.x, y: initialPos.y });
                setBotRightPosition({ x: initialPos.x, y: inRangePos.y });
                break;
            default:
                break;
        }
    };

    if (
        !taskUIContext
            .getCurrentGuiObject()
            .taskInputResourcesInCache(currentTaskIdx)
    ) {
        return <LoadingAnimation />;
    }

    const taskInput = taskUIContext.getCurrentTaskInput();
    taskUIContext.onViewReady();
    const image = resourceCache[taskInput.image_url];

    // change image via state, because the image loading can fail and
    // useEffect mustn't be called conditionally. The image is only available after checking
    // whether all resources were loaded
    if (!currentImage || currentImage.src !== image.src) {
        setCurrentImage(image);
    }

    return (
        // Loose layout div that can be bigger than the image dimensions
        <div
            id="loose_layout"
            style={{
                margin: "auto",
                marginBottom: "20px"
            }}
        >
            {/* Tight layout div that fit's exactly around the image dimensions.
            Bounding Boxes are oriented relative to this div */}
            <div
                id="tight_layout"
                ref={frameRef}
                style={{
                    position: "relative",
                    display: "inline-block",
                    float: "left",
                    width: "fit-content",
                    height: "fit-content",
                    userSelect: "none",
                    border: `${borderLineWidth}px solid rgba(0, 0, 0, 100)`,
                    cursor: "none"
                }}
                onMouseDown={handleMouseDown}
            >
                <img
                    id="image"
                    style={{
                        width: imageResizedDimensions.width,
                        height: imageResizedDimensions.height,
                        userSelect: "none",
                        pointerEvents: "none"
                    }}
                    src={image.src}
                    alt=""
                />

                <CrosshairCursor
                    crosshairPos={crosshairPos}
                    crosshairWidth={crosshairWidth}
                    crosshairColor={crosshairColor}
                    dimensions={imageResizedDimensions}
                    currentCursor={currentCursor}
                    disableCrosshair={
                        currentBoxIndexRef.current ||
                        currentBoxIndexRef.current === 0
                    }
                />

                {topLeftPos ? (
                    <RectDiv
                        id="bounding_box_area"
                        topLeftPos={{
                            x: topLeftPos.x - bboxLineWidth,
                            y: topLeftPos.y - bboxLineWidth
                        }}
                        botRightPos={{
                            x: botRightPos.x + bboxLineWidth,
                            y: botRightPos.y + bboxLineWidth
                        }}
                        style={{
                            border: `${bboxLineWidth}px solid ${currentBoxColor}`,
                            opacity: 1,
                            background: "none",
                            zIndex: 3
                        }}
                    >
                        {(currentBoxIndexRef.current ||
                            currentBoxIndexRef.current === 0) &&
                        !dragging ? (
                            <>
                                <ResizeMarker
                                    left={`-${5 + bboxLineWidth / 2}px`}
                                    top={`-${5 + bboxLineWidth / 2}px`}
                                />
                                <ResizeMarker
                                    right={`-${5 + bboxLineWidth / 2}px`}
                                    top={`-${5 + bboxLineWidth / 2}px`}
                                />
                                <ResizeMarker
                                    left={`-${5 + bboxLineWidth / 2}px`}
                                    bottom={`-${5 + bboxLineWidth / 2}px`}
                                />
                                <ResizeMarker
                                    right={`-${5 + bboxLineWidth / 2}px`}
                                    bottom={`-${5 + bboxLineWidth / 2}px`}
                                />
                            </>
                        ) : null}
                    </RectDiv>
                ) : null}

                {boundingBoxes.map((box, index) =>
                    box.left || box.left === 0 ? (
                        <RectDiv
                            id="stored_bounding_box"
                            key={"bbox_area_" + index}
                            topLeftPos={{
                                x: box.left - bboxLineWidth,
                                y: box.top - bboxLineWidth
                            }}
                            botRightPos={{
                                x: box.right + bboxLineWidth,
                                y: box.bottom + bboxLineWidth
                            }}
                            style={{
                                border: `${bboxLineWidth}px solid ${boundingboxesColor}`,
                                opacity: 1,
                                background: "none",
                                zIndex: 2
                            }}
                        >
                            {disableLabels ? null : currentBoxIndex ===
                              index ? null : (
                                <BBoxLabel
                                    key={"bbox_label_" + index}
                                    box={box}
                                    labelHeight={labelHeight}
                                    bboxLineWidth={bboxLineWidth}
                                    labelTextColor={labelTextColor}
                                />
                            )}
                        </RectDiv>
                    ) : null
                )}
            </div>
            <BBoxMenu
                boundingBoxes={boundingBoxes}
                currentBoxIndex={currentBoxIndex}
                dimensions={imageResizedDimensions}
                borderLineWidth={borderLineWidth}
                boundingboxesColor={boundingboxesColor}
                currentBoxColor={currentBoxColor}
                disableLabels={disableLabels}
                removeBoundingBox={removeBBox}
                selectBBoxToAdjust={selectBBoxToAdjust}
                updateLabel={updateLabel}
                hoverBBox={hoverBBox}
                deHoverBBox={deHoverBBox}
            />
        </div>
    );
};

export default BBox2DLabeledWidget;
