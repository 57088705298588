import React from "react";

/**
 * A horizontal Likert-Scale. Places options in order from left to right into buttons.
 * OptionValues must be unique.
 * SuperLabels are possible and will be added to the horizontal position of the specified optionLabels.
 */
export const LikertScale = ({
    options,
    superLabels,
    selectedOptionValue = null, // will be highlighted
    superLabelPosition = "above", // "below"
    onSelectOption = () => {}
}) => {
    const makeSuperLabelElementAtOptionElement = optionLabel => {
        if (superLabels && optionLabel in superLabels) {
            return (
                <div
                    style={{
                        marginTop: "20px",
                        marginBottom: "10px"
                    }}
                >
                    {superLabels[optionLabel]}
                </div>
            );
        }
        return null;
    };
    const makeOptionElement = (optionLabel, optionValue, key) => {
        return (
            <div
                key={optionValue}
                style={{
                    width: `${100 / Object.keys(options).length}%`,
                    overflowWrap: "anywhere",
                    fontWeight: "bold",
                    fontSize: "1.2rem"
                }}
            >
                {superLabelPosition === "above"
                    ? makeSuperLabelElementAtOptionElement(optionLabel)
                    : null}
                <div
                    className="button"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow:
                            selectedOptionValue === optionValue
                                ? "0 0 2px 2px #f77197"
                                : "",
                        padding: "10px",
                        overflowWrap: "anywhere",
                        cursor: "pointer",
                        userSelect: "none"
                    }}
                    onClick={() => {
                        // deselect
                        if (selectedOptionValue === optionValue) {
                            onSelectOption(null, null);
                        } else {
                            onSelectOption(optionLabel, optionValue);
                        }
                    }}
                >
                    {optionLabel}
                </div>
                {superLabelPosition === "below"
                    ? makeSuperLabelElementAtOptionElement(optionLabel)
                    : null}
            </div>
        );
    };
    const optionElements = [];
    for (const [optionLabel, optionValue] of Object.entries(options)) {
        optionElements.push(makeOptionElement(optionLabel, optionValue));
    }

    return (
        <div
            style={{
                display: "flex",
                gap: "10px",
                alignItems:
                    superLabelPosition === "above" ? "flex-end" : "flex-start"
            }}
        >
            {optionElements}
        </div>
    );
};

export default LikertScale;
