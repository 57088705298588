import { multiply, inv, size, column, flatten, norm } from "mathjs";
import { Quaternion, Euler } from "three";
import { clamp, getDirectionNormedFromPointToPoint } from "../qm_cs_lib";

/**
 * all coordinates are represented as arrays: Number[]
 * Vectors and Matrices (from the outside) are also just arrays (of arrays): Number[][].
 * Vectors and Matrices are converted to mathjs matrices for internal calculations.
 */
export class Pose3D {
    /**
     * Position and rotation in world
     *
     * @param {Number[]=} pos
     * @param {Number[]=} rot euler angles with order: [roll, pitch, yaw]
     * @param {Number=} zoom zoom factor > 1 increases the zoom. default: 1
     * @param {Boolean=} translationFirst default false
     */
    constructor(
        pos = [0, 0, 0],
        rot = [0, 0, 0],
        zoom = 1,
        translationFirst = false
    ) {
        if (pos === null || rot === null || zoom === null) {
            throw new Error(
                "Tried to use a pos, rot, or zoom from a Pose3D that was created" +
                    "from 4x4 homogeneous transformation matrix: pos, rot or zoom are null!"
            );
        }

        this.pos = [...pos];
        this.rot = [...rot];
        this.zoom = zoom;
        this.translationFirst = translationFirst;

        /**
         * @type {Number[][]} homogeneous transformation matrix
         */
        this.htm = null;
        /**
         * @type {Number[][]} inverse of the homogeneous transformation matrix
         */
        this.htmInv = null;
        this.calcHomogenousMatrices();
    }

    /**
     * @param {Number[][]} htm homogeneous transformation matrix
     * @param {Boolean=} translationFirst default false
     * @returns {Pose3D}
     */
    static makePoseFromHtm(htm, translationFirst = false) {
        const pose = new Pose3D();
        pose.htm = htm;
        pose.translationFirst = translationFirst;
        pose.calcInvHomogenousMatrix();
        pose.setPosRotAndZoomFromHtm();
        return pose;
    }

    /**
     * @param {Number[]} pos
     * @param {Number[]} rot euler angles with order: [roll (y-axis), pitch (x-axis), yaw (z-axis)]
     * @param {Number=} zoom zoom factor > 1 increases the zoom. default: 1
     * @param {Boolean=} translationFirst default false
     * @returns {Number[][]}
     */
    static makeHomogenousTransformationMatrix(
        pos,
        rot,
        zoom = 1,
        translationFirst = false
    ) {
        const translation = Pose3D.makeHomogenousTranslationMatrix(pos, zoom);
        const rotation = Pose3D.makeHomogenousRotationMatrix(rot);
        if (translationFirst) {
            // first rotation then translation
            return matMultMat(rotation, translation);
        } else {
            // first translation then rotation
            return matMultMat(translation, rotation);
        }
    }

    /**
     * Scale is not applied to the translation itself
     *
     * @param {Number[]} pos
     * @param {Number=} zoom zoom factor > 1 increases the zoom. default: 1
     * @returns {Number[][]}
     */
    static makeHomogenousTranslationMatrix(pos, zoom = 1) {
        const s = 1 / zoom;
        return [
            [s, 0, 0, pos[0]],
            [0, s, 0, pos[1]],
            [0, 0, s, pos[2]],
            [0, 0, 0, 1]
        ];
    }

    /**
     * @param {Number[]} rot [roll (y-axis), pitch (x-axis), yaw (z-axis)]
     * @returns {Number[][]}
     */
    static makeHomogenousRotationMatrix(rot) {
        const rotX = [
            [1, 0, 0, 0],
            [0, Math.cos(rot[1]), -Math.sin(rot[1]), 0],
            [0, Math.sin(rot[1]), Math.cos(rot[1]), 0],
            [0, 0, 0, 1]
        ];
        const rotY = [
            [Math.cos(rot[0]), 0, Math.sin(rot[0]), 0],
            [0, 1, 0, 0],
            [-Math.sin(rot[0]), 0, Math.cos(rot[0]), 0],
            [0, 0, 0, 1]
        ];
        const rotZ = [
            [Math.cos(rot[2]), -Math.sin(rot[2]), 0, 0],
            [Math.sin(rot[2]), Math.cos(rot[2]), 0, 0],
            [0, 0, 1, 0],
            [0, 0, 0, 1]
        ];
        // rotation order is: zxy (extrinsic)
        return matMultMat(matMultMat(rotY, rotX), rotZ);
    }

    asQuaternion() {
        // ThreeJs uses intrinsic rotations, so we have to specify YXZ here.
        // reason: our Pose3D extrinsic ZXY is equivalent to ThreeJs' intrinsic YXZ
        const euler = new Euler(this.rot[1], this.rot[0], this.rot[2], "YXZ");
        const quat = new Quaternion();
        quat.setFromEuler(euler);
        quat.normalize();
        return quat;
    }

    /**
     * Decomposes htm into
     * - translation
     * - rotation
     * - scale
     */
    setPosRotAndZoomFromHtm() {
        let colX = flatten(column(this.htm, 0)).slice(0, 3);
        let colY = flatten(column(this.htm, 1)).slice(0, 3);
        let colZ = flatten(column(this.htm, 2)).slice(0, 3);

        // translation is the right most column
        this.pos = flatten(column(this.htm, 3)).slice(0, 3);

        // we always use uniform scale for all axes,
        // so the length of one is the scale
        const scale = [norm(colX), norm(colY), norm(colZ)];
        this.zoom = scale[0];

        colX = multiply(colX, 1 / scale[0]);
        colY = multiply(colY, 1 / scale[1]);
        colZ = multiply(colZ, 1 / scale[2]);

        // assumes rotation order YXZ intrinsic
        // source: https://github.com/mrdoob/three.js/blob/master/src/math/Euler.js
        const rot = [0, 0, 0];
        rot[1] = Math.asin(-clamp(colY[2], -1, 1));
        if (Math.abs(colY[2] < 0.9999999)) {
            rot[0] = Math.atan2(colX[2], colZ[2]);
            rot[2] = Math.atan2(colY[0], colY[1]);
        } else {
            rot[0] = Math.atan2(-colZ[0], colX[0]);
            rot[2] = 0;
        }
        this.rot = rot;

        // assumes rotation order ZXY
        // rot[1] = Math.asin(-clamp(colZ[1], -1, 1));
        // if (Math.abs(colZ[1] < 0.9999999)) {
        //     rot[0] = Math.atan2(-colZ[0], colZ[2]);
        //     rot[2] = Math.atan2(-colX[1], colY[1]);
        // } else {
        //     rot[0] = 0;
        //     rot[2] = Math.atan2(colY[0], colX[0]);
        // }
        // this.rot = rot;
    }

    calcHomogenousMatrices() {
        if (this.pos === null || this.rot === null || this.zoom === null) {
            throw new Error(
                "Tried to use a pos, rot, or zoom from a Pose3D that was created" +
                    "from 4x4 homogeneous transformation matrix: pos, rot or zoom are null!"
            );
        }

        // console.time("calc_htm");
        this.htm = Pose3D.makeHomogenousTransformationMatrix(
            this.pos,
            this.rot,
            this.zoom,
            this.translationFirst
        );
        // console.timeEnd("calc_htm");
        this.calcInvHomogenousMatrix();
    }

    calcInvHomogenousMatrix() {
        // console.time("calc_htm_inv");
        this.htmInv = inv(this.htm);
        // console.timeEnd("calc_htm_inv");
    }

    /**
     * @param {Number[]|Number[][]} vec
     * @returns {Number[][]}
     */
    applyTo(vec) {
        const vecSize = size(vec);
        if (!Array.isArray(vecSize) || vecSize.length === 1) {
            // simple point or vector
            if (vec.length < 4) {
                vec = [...vec];
                vec.push(1);
            }
            return matMultVec(this.htm, vec);
        } else if (vecSize[0] === 4 && vecSize[1] === 4) {
            // 4x4 matrix
            return matMultMat(this.htm, vec);
        }

        throw new Error("size mismatch for " + vec + " with size " + vecSize);
    }

    /**
     * @param {Number[]|Number[][]} vec
     * @returns {Number[][]}
     */
    applyInverseTo(vec) {
        const vecSize = size(vec);
        if (!Array.isArray(vecSize) || vecSize.length === 1) {
            // simple point or vector
            if (vec.length < 4) {
                vec = [...vec];
                vec.push(1);
            }
            return matMultVec(this.htmInv, vec);
        } else if (vecSize[0] === 4 && vecSize[1] === 4) {
            return matMultMat(this.htmInv, vec);
        }
        throw new Error("size mismatch for " + vec + " with size " + vecSize);
    }

    /**
     * Applies a Pose as local transformation and returns a new Pose. This Pose won't
     * be changed. Translation will be relative to the coordinate system defined by
     * this Pose and rotations will basically rotate the coordinate system of this Pose.
     * @param {Pose3D} pose
     * @return {Pose3D}
     */
    applyLocalTransformation(pose) {
        // keep the translationFirst information from this Pose
        return Pose3D.makePoseFromHtm(
            this.applyTo(pose.htm),
            this.translationFirst
        );
    }

    /**
     * @param {Number[]} rot rotation with order YXZ
     */
    applyRotation(rot) {
        return Pose3D.makePoseFromHtm(
            new Pose3D([0, 0, 0], [...rot]).applyTo(this.htm),
            this.translationFirst
        );
    }

    /**
     * Applies the rotation matrix for arbitrary rotation axis from: https://en.wikipedia.org/wiki/Rotation_matrix#Rotation_matrix_from_axis_and_angle
     * @param {Number[]|Number[][]} vec the vector in the local coordinate system to rotate around. It's relative to the origin of this pose.
     * @param {Number} theta
     * @returns {Pose3D} a new pose rotated around vec by theta
     */
    rotateAroundLocalVector(vec, theta) {
        // transform vec to world coords
        const vecWorld = this.applyTo(vec).slice(0, 3);
        // get direction vector u with length 1
        const u = getDirectionNormedFromPointToPoint(
            this.pos.slice(0, 3),
            vecWorld
        );

        const [ux, uy, uz] = u;
        const rotateAroundMatrix = [
            [
                Math.cos(theta) + ux * ux * (1 - Math.cos(theta)),
                ux * uy * (1 - Math.cos(theta)) - uz * Math.sin(theta),
                ux * uz * (1 - Math.cos(theta)) + uy * Math.sin(theta),
                0
            ],
            [
                uy * ux * (1 - Math.cos(theta)) + uz * Math.sin(theta),
                Math.cos(theta) + uy * uy * (1 - Math.cos(theta)),
                uy * uz * (1 - Math.cos(theta)) - ux * Math.sin(theta),
                0
            ],
            [
                uz * ux * (1 - Math.cos(theta)) - uy * Math.sin(theta),
                uz * uy * (1 - Math.cos(theta)) + ux * Math.sin(theta),
                Math.cos(theta) + uz * uz * (1 - Math.cos(theta)),
                0
            ],
            [0, 0, 0, 1]
        ];

        // go back from world to local frame
        const newHtm = matMultMat(this.htmInv, rotateAroundMatrix);
        return Pose3D.makePoseFromHtm(newHtm);
    }

    /**
     * @param {Number[]} rot
     */
    setRotation(rot) {
        this.rot = [...rot];
        this.calcHomogenousMatrices();
    }

    /**
     * @param {Number[]} pos
     */
    setPosition(pos) {
        this.pos = pos;
        this.calcHomogenousMatrices();
    }

    /**
     * @param {Number} zoom
     */
    setZoom(zoom) {
        this.zoom = zoom;
        this.calcHomogenousMatrices();
    }

    /**
     * @param {Number} diff diff angle in rad
     */
    rotateY(diff) {
        this.rot[0] += diff;
        this.calcHomogenousMatrices();
    }

    /**
     * @param {Number} diff diff angle in rad
     */
    rotateX(diff) {
        this.rot[1] += diff;
        this.calcHomogenousMatrices();
    }

    /**
     * @param {Number} diff diff angle in rad
     */
    rotateZ(diff) {
        this.rot[2] += diff;
        this.calcHomogenousMatrices();
    }
}

/**
 * @param {Number[][]} mat
 * @param {Number[]} vec
 */
export const matMultVec = (mat, vec) => {
    if (mat[0].length !== vec.length) {
        throw new Error(
            "size mismatch in matMultVec: mat(" +
                mat[0].length +
                "), vec(" +
                vec.length +
                ")"
        );
    }

    const res = [];
    for (let row = 0; row < mat.length; row++) {
        let sum = 0;
        for (let col = 0; col < mat[0].length; col++) {
            sum += mat[row][col] * vec[col];
        }
        res.push(sum);
    }
    return res;
};

/**
 * @param {Number[][]} a
 * @param {Number[][]} b
 */
export const matMultMat = (a, b) => {
    if (a[0].length !== b.length) {
        throw new Error(
            "size mismatch in matMultMat: a(" +
                a[0].length +
                "), b(" +
                b.length +
                ")"
        );
    }

    const res = [];
    for (let rowA = 0; rowA < a.length; rowA++) {
        res.push([]);
        for (let colB = 0; colB < b[0].length; colB++) {
            let sum = 0;
            for (let k = 0; k < a[0].length; k++) {
                sum += a[rowA][k] * b[k][colB];
            }
            res[rowA].push(sum);
        }
    }
    return res;
};

/**
 * @param {Number[][]} m
 */
export const logMatrix = m => {
    for (let i = 0; i < m.length; i++) {
        let row = " | ";
        for (let k = 0; k < m[i].length; k++) {
            row += m[i][k] + " | ";
        }
        console.log("row", row);
    }
};
