export const dummyImageBaseUrl = "https://dummyimage.com";
export const makeDummyImageList = (
    width,
    height,
    amount,
    bgColor = "ccc",
    fgColor = "000",
    format = "png",
    text = null
) => {
    const list = [];
    for (let i = 0; i < amount; i++) {
        list.push(
            dummyImageBaseUrl +
                `/${width}x${height}/${bgColor}/${fgColor}.${format}${
                    text ? "?text=" + text : ""
                }`
        );
    }
    return list;
};

// Public images for testing. Might be offline. Don't use for live demos.
export const antImageObjects = [
    {
        image_url:
            "https://live.staticflickr.com/7069/6879619433_cb442740d7_b.jpg"
    },
    {
        image_url:
            "https://p0.pikist.com/photos/860/33/ant-queen-ant-queens-female-insect.jpg"
    },
    {
        image_url:
            "https://c1.wallpaperflare.com/preview/531/707/816/ant-ants-animals-insects.jpg"
    },
    {
        image_url:
            "https://c1.wallpaperflare.com/preview/827/769/257/insects-ant-hymenoptera-macro.jpg"
    }
];

// Our S3 demo image bucket containing images suitable for public demos.
export const demoInsectImageObjects = [
    {
        image_url:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/insect_0.jpg"
    },
    {
        image_url:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/insect_1.jpg"
    },
    {
        image_url:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/insect_2.jpg"
    },
    {
        image_url:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/insect_3.jpg"
    },
    {
        image_url:
            "https://qm-demo-images.s3.eu-central-1.amazonaws.com/insect_4.jpg"
    }
];

export const covidImagesObjects = [
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/0719ba5ffac530c6802ebf812c3e2a691f01a71d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/12dab82fd2bc2f9ab6a1dbf80ce2595030734d72.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/1e7083a0b6a1c13da3a60c3a92c98f343f66dad7.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/2cf2d0bb709bced72c43ca47b41561513fde386c.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/3f59694f8ea2bba1bf1deb05a1fbc077e030c41b.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/41d91855d99120eab21ae47e87950181cd578a05.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/488957f5c45bd4031ef489b7683b77832069467e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/4dc55d2d9eb588c3e1e81ca1abbf1310b9981fd6.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/52de476a0c01c45a56108e6b111361f4306d09eb.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/57b70f09df86f59b3663e42c539c764be4a4a502.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/65306044eb32b3be53a26255def402bf19066dd3.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/6c1a087308a6c1ae46795b58fbdafc8ebf9ad0c2.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/6dca53602f34a131419dd3689bed9302f38e18e1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/742ba608218437dad2daf541834b473b34081069.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/75ac74741363896810b4b7c2275035693f63727e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/92df17dd4f67b2ee19c592556dbce714d760ea9d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/9b14a6d2bbc596cfb954b5cef364ffb6ee5a9015.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/9d7a6933b9eff02ba09f5eebc93042c3fe49d078.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/a3bb4c2c9a1b53f47f7c4c631042fc65bf2d1bb0.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/a52a7906b6b05f19e2afa2042cc4717ba091f973.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/a7f3d80bc7949f86a53240a7753ff06fd49cb842.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/b43dd83b4545c5434d6ec8ea94a950acc4573646.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/b4dc53fb22350ead050dace9f4117128fd5d0568.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/d10bb16cfc3ebf656c22b7f0f4c6ad05466b271d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/d111adc405fd97649b4c2c859c5f92e4c2c6d8a1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/e64b9b38f63cd21bb14104f635cc7badacaf7abd.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/f6aa7d1f867ea4796e93cd3441e7dc7fcaecea5e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/f931115caafad6254ba3751308b1ad1defcd2577.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/f9e12beaffb208673a350409265886b557eb8ffc.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/feeec093978568463b761cd5fba53c501ab2d59d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/0719ba5ffac530c6802ebf812c3e2a691f01a71d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/12dab82fd2bc2f9ab6a1dbf80ce2595030734d72.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/1e7083a0b6a1c13da3a60c3a92c98f343f66dad7.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/2cf2d0bb709bced72c43ca47b41561513fde386c.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/3f59694f8ea2bba1bf1deb05a1fbc077e030c41b.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/41d91855d99120eab21ae47e87950181cd578a05.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/488957f5c45bd4031ef489b7683b77832069467e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/4dc55d2d9eb588c3e1e81ca1abbf1310b9981fd6.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/52de476a0c01c45a56108e6b111361f4306d09eb.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/57b70f09df86f59b3663e42c539c764be4a4a502.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/65306044eb32b3be53a26255def402bf19066dd3.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/6c1a087308a6c1ae46795b58fbdafc8ebf9ad0c2.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/6dca53602f34a131419dd3689bed9302f38e18e1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/742ba608218437dad2daf541834b473b34081069.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/75ac74741363896810b4b7c2275035693f63727e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/92df17dd4f67b2ee19c592556dbce714d760ea9d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/9b14a6d2bbc596cfb954b5cef364ffb6ee5a9015.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/9d7a6933b9eff02ba09f5eebc93042c3fe49d078.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/a3bb4c2c9a1b53f47f7c4c631042fc65bf2d1bb0.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/a52a7906b6b05f19e2afa2042cc4717ba091f973.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/a7f3d80bc7949f86a53240a7753ff06fd49cb842.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/b43dd83b4545c5434d6ec8ea94a950acc4573646.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/b4dc53fb22350ead050dace9f4117128fd5d0568.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/d10bb16cfc3ebf656c22b7f0f4c6ad05466b271d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/d111adc405fd97649b4c2c859c5f92e4c2c6d8a1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/e64b9b38f63cd21bb14104f635cc7badacaf7abd.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/f6aa7d1f867ea4796e93cd3441e7dc7fcaecea5e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/f931115caafad6254ba3751308b1ad1defcd2577.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/f9e12beaffb208673a350409265886b557eb8ffc.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/feeec093978568463b761cd5fba53c501ab2d59d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/01b4fe98b2e8c07bce04b9c55e19d0c53d544f4d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/0f8e2558598414c8400046badd8f5f517308a404.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/140593f51a50c9338ed5a5626abee804f3c5f198.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/26f3cb5ac8c85e8483959185a26dcaa6887454f0.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/2b5f39e25757915f37eac34c614132524fd13a76.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/2dd4b6673f55bfcc2a627f9b081ff0baef4391ab.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/30fb48e0e72a3aff4156afc980422be150d9c410.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/445e9d2d27c79285321be41d4c0f0b64e731a110.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/4635b0933c7c8ac9db8933d8b2e9c24953966d38.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/5294827da50e7bfa4023340790d203ffbb2181d6.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/52a6e36e2f40ea0a30ba1e7b7fce01a99ecc22d1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/600a6cc90acb34f3f9d5712244b1a9082c0965c8.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/76930afb1b8e3b008fdbb94856565bf9327b63ad.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/7c22533a83250b1261fbec2ccde95b3217c028fa.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/7c6fa6b1bf5b2661a4c2c97b99f6adb8bfcf3a44.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/7db2a18c1247cc544306e932db5fa0336cb24de6.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/7f15545fe9ad5cb2476a18949624d14a3f5b5725.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/8714b51c13c423e0311a4a7b37f0001b3e2109e7.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/882fa57097d26c971ccafaa98c68ad2e753e5bae.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/8ac26fc35515be5c4aeb2fc9d7534b6d6f9a4292.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/8fbe9bde3c2430cc3e41364947f921237b78c107.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/94f037815d410d83e0fffbf75114b02478befc2e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/98e5eab59f002a818998af327b2ec7cbabff9520.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/a3f1cc05d54baf211bcd2d7c48d1718dc2fa28ce.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/a779d518b7c5d99b961d2555b1782bd7eb3bc2f1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/bacf9823dff12c3a90d148e3bd38375f8050fd3c.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/d09b9f9d645b501305878a497a41ccf2defd5b66.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/d8d7ca941887b88db47ba090ed85164f8f1a3795.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/dcf8110f4facca21b0ffc1b5938e41097fcd77fd.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/ffd564c9125a0ddfe443cab0e0aaee7920e4a0b7.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/01b4fe98b2e8c07bce04b9c55e19d0c53d544f4d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/0f8e2558598414c8400046badd8f5f517308a404.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/140593f51a50c9338ed5a5626abee804f3c5f198.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/26f3cb5ac8c85e8483959185a26dcaa6887454f0.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/2b5f39e25757915f37eac34c614132524fd13a76.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/2dd4b6673f55bfcc2a627f9b081ff0baef4391ab.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/30fb48e0e72a3aff4156afc980422be150d9c410.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/445e9d2d27c79285321be41d4c0f0b64e731a110.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/4635b0933c7c8ac9db8933d8b2e9c24953966d38.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/5294827da50e7bfa4023340790d203ffbb2181d6.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/52a6e36e2f40ea0a30ba1e7b7fce01a99ecc22d1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/600a6cc90acb34f3f9d5712244b1a9082c0965c8.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/76930afb1b8e3b008fdbb94856565bf9327b63ad.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/7c22533a83250b1261fbec2ccde95b3217c028fa.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/7c6fa6b1bf5b2661a4c2c97b99f6adb8bfcf3a44.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/7db2a18c1247cc544306e932db5fa0336cb24de6.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/7f15545fe9ad5cb2476a18949624d14a3f5b5725.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/8714b51c13c423e0311a4a7b37f0001b3e2109e7.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/882fa57097d26c971ccafaa98c68ad2e753e5bae.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/8ac26fc35515be5c4aeb2fc9d7534b6d6f9a4292.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/8fbe9bde3c2430cc3e41364947f921237b78c107.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/94f037815d410d83e0fffbf75114b02478befc2e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/98e5eab59f002a818998af327b2ec7cbabff9520.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/a3f1cc05d54baf211bcd2d7c48d1718dc2fa28ce.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/a779d518b7c5d99b961d2555b1782bd7eb3bc2f1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/bacf9823dff12c3a90d148e3bd38375f8050fd3c.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/d09b9f9d645b501305878a497a41ccf2defd5b66.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/d8d7ca941887b88db47ba090ed85164f8f1a3795.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/dcf8110f4facca21b0ffc1b5938e41097fcd77fd.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/ffd564c9125a0ddfe443cab0e0aaee7920e4a0b7.png"
    }
];

export const craneSillbeamGrappleImagesObjects = [
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/a6d1ffe1-b15a-4ecd-ac25-cc5eeb2fc9b8.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/619f0c83-0890-4162-ad9f-a64f097326d2.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/c9c2172f-1599-4cad-a4b4-394f91ea1535.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/c51a59af-cf8d-4521-99ba-4fa306c3a66c.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/2bbfd051-66db-477a-911d-9e7f9bd033cb.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/fe19e04b-8b65-47a5-9c64-a25062012dfb.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/8bf901cf-79fa-4cb9-a033-d3c120541cd1.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/00e31141-f03b-4380-af42-be118e0f7c45.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/bcaf2222-314a-47e3-a934-57e83498bde5.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/c84bb063-3c41-4945-a97c-9ec880499397.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/d0b9ee4c-aef6-48f3-b988-27df8ad3d583.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/4adb696b-ad33-4fb7-ade4-a52b935914c9.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/4a35a2d5-1fe7-4dcf-b19d-c35458a4d341.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/cbf2b08a-978d-472e-af67-58f2711d3c1f.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/b2e215f6-f1bc-44ec-b292-c83d1487f722.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/30cfea62-0fe4-4182-820f-507889e1ce1f.jpeg"
    },
    {
        image_url:
            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/ffd4138d-4472-4791-bd2c-879bf40d905d.jpeg"
    }
];

export const covidLungImagesObjects = [
    {
        image_url: "https://i.ibb.co/DMfjPjw/1148106405-7399574401367034-ct.png"
    },
    {
        image_url:
            "https://i.ibb.co/cv6khqy/1148106405-920161185417410341-ct.png"
    },
    {
        image_url:
            "https://i.ibb.co/5GSKtFt/1148106405-862373416686868589-ct.png"
    },
    {
        image_url:
            "https://i.ibb.co/YR1cFvs/1148106405-789508684833113016-ct.png"
    },
    {
        image_url:
            "https://i.ibb.co/4ZrpXhg/1148106405-710733538822512649-ct.png"
    }
];
