import { taskFetchRequestReducer } from "./taskFetchRequest";
import currentTaskIdxReducer from "./currentTaskIdx";
import tasksAmountReducer from "./tasksAmount";
import { taskOutputsReducer } from "./answers";
import { timestampReducer } from "./timestamp";
import { nextGuiTypeReducer } from "./nextGuiType";
import { guiSettingsReducer } from "./guiSettings";
import { guiTypeReducer } from "./guiType";
import { taskSubmitRequestReducer } from "./taskSubmitRequest";
import { thankYouReachedReducer } from "./thankYouReached";
import { taskResultReducer } from "./taskResult";
import {
    resourceCacheReducer,
    resourceLoadingErrorsReducer
} from "./resourceCache";
import { currentTaskOutputReducer } from "./currentTaskOutput";
import { taskSubmitEnabledReducer } from "./taskSubmitEnabled";

export default function rootReducer(state = {}, action) {
    return {
        taskFetchRequest: taskFetchRequestReducer(
            state.taskFetchRequest,
            action
        ),
        tasksAmount: tasksAmountReducer(state.tasksAmount, action),
        currentTaskIdx: currentTaskIdxReducer(state.currentTaskIdx, action),
        currentTaskOutput: currentTaskOutputReducer(
            state.currentTaskOutput,
            action
        ),
        taskOutputs: taskOutputsReducer(state.taskOutputs, action),
        timestamp: timestampReducer(state.timestamp, action),
        taskResult: taskResultReducer(state.taskResult, action),
        guiType: guiTypeReducer(state.guiType, action),
        guiSettings: guiSettingsReducer(state.guiSettings, action),
        nextGuiType: nextGuiTypeReducer(state.nextGuiType, action),
        taskSubmitRequest: taskSubmitRequestReducer(
            state.taskSubmitRequest,
            action
        ),
        thankYouReached: thankYouReachedReducer(state.thankYouReached, action),
        resourceCache: resourceCacheReducer(state.resourceCache, action),
        resourceLoadingErrors: resourceLoadingErrorsReducer(
            state.resourceLoadingErrors,
            action
        ),
        taskSubmitEnabled: taskSubmitEnabledReducer(
            state.taskSubmitEnabled,
            action
        )
    };
}
