import React, { useEffect } from "react";
import { ReactComponent as SvgThankYouScreenAsset } from "../../guitypes/DiscreteAnswerGaming/thank_you_gaming.svg";
import { sleep } from "../../lib/qm_cs_lib";

import "./styles/ThankYouViewGaming.css";

/**
 * @param {Object} props
 * @param {import("../../lib/TaskUIStrategy/taskUIContext").TaskUIContext} props.taskUIContext
 */
export const ThankYouViewGaming = props => {
    const [isBytro, setIsBytro] = React.useState(false);

    useEffect(() => {
        const is_bytro =
            props.taskUIContext.queryParams.get("vendor_id") === "bytro";
        setIsBytro(is_bytro);
        sleep(2000).then(() => {
            props.taskUIContext.handleTasksSDKResult();
        });
        props.taskUIContext.emitSubmissionDone();
    }, [props.taskUIContext]);

    let continue_btn = null;
    if (!isBytro) {
        continue_btn = (
            <button
                id="continue-button"
                className="dag_button"
                type="button"
                onClick={() => {
                    window.location.reload();
                }}
            >
                {" "}
                <span>Continue</span>{" "}
            </button>
        );
    }

    return (
        <div className="thankyou-container">
            <div
                style={{
                    marginTop: "20px",
                    fontSize: "3rem",
                    padding: "20px"
                }}
            >
                <SvgThankYouScreenAsset />
            </div>
            {continue_btn}
        </div>
    );
};

export default ThankYouViewGaming;
