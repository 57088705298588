/* eslint-disable */
// source: points_table.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

goog.exportSymbol("proto.BevTransformation", null, global);
goog.exportSymbol("proto.CameraIntrinsicsProto", null, global);
goog.exportSymbol("proto.GeometryTransformationProto", null, global);
goog.exportSymbol("proto.MatrixProto", null, global);
goog.exportSymbol("proto.MetaData", null, global);
goog.exportSymbol("proto.PointsTableRowProto", null, global);
goog.exportSymbol("proto.PointsTagProto", null, global);
goog.exportSymbol("proto.QuaternionProto", null, global);
goog.exportSymbol("proto.SphericalParameters", null, global);
goog.exportSymbol("proto.Table", null, global);
goog.exportSymbol("proto.Vector3dProto", null, global);
goog.exportSymbol("proto.VectorProto", null, global);
goog.exportSymbol("proto.VisualizationInfo", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PointsTableRowProto = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PointsTableRowProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.PointsTableRowProto.displayName = "proto.PointsTableRowProto";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CameraIntrinsicsProto = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CameraIntrinsicsProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.CameraIntrinsicsProto.displayName = "proto.CameraIntrinsicsProto";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SphericalParameters = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SphericalParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.SphericalParameters.displayName = "proto.SphericalParameters";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.VectorProto = function (opt_data) {
    jspb.Message.initialize(
        this,
        opt_data,
        0,
        -1,
        proto.VectorProto.repeatedFields_,
        null
    );
};
goog.inherits(proto.VectorProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.VectorProto.displayName = "proto.VectorProto";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MatrixProto = function (opt_data) {
    jspb.Message.initialize(
        this,
        opt_data,
        0,
        -1,
        proto.MatrixProto.repeatedFields_,
        null
    );
};
goog.inherits(proto.MatrixProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.MatrixProto.displayName = "proto.MatrixProto";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.QuaternionProto = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.QuaternionProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.QuaternionProto.displayName = "proto.QuaternionProto";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Vector3dProto = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Vector3dProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.Vector3dProto.displayName = "proto.Vector3dProto";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GeometryTransformationProto = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GeometryTransformationProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.GeometryTransformationProto.displayName =
        "proto.GeometryTransformationProto";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BevTransformation = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BevTransformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.BevTransformation.displayName = "proto.BevTransformation";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.VisualizationInfo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.VisualizationInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.VisualizationInfo.displayName = "proto.VisualizationInfo";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MetaData = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.MetaData.displayName = "proto.MetaData";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Table = function (opt_data) {
    jspb.Message.initialize(
        this,
        opt_data,
        0,
        -1,
        proto.Table.repeatedFields_,
        null
    );
};
goog.inherits(proto.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.Table.displayName = "proto.Table";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.PointsTableRowProto.prototype.toObject = function (
        opt_includeInstance
    ) {
        return proto.PointsTableRowProto.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.PointsTableRowProto} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.PointsTableRowProto.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                uRgb: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    1,
                    0.0
                ),
                vRgb: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    2,
                    0.0
                ),
                uBev: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    3,
                    0.0
                ),
                vBev: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    4,
                    0.0
                ),
                colorIdx: jspb.Message.getFieldWithDefault(msg, 5, 0),
                pointsTag: jspb.Message.getFieldWithDefault(msg, 6, 0),
                x: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
                y: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
                z: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PointsTableRowProto}
 */
proto.PointsTableRowProto.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.PointsTableRowProto();
    return proto.PointsTableRowProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PointsTableRowProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PointsTableRowProto}
 */
proto.PointsTableRowProto.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setURgb(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setVRgb(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setUBev(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setVBev(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setColorIdx(value);
                break;
            case 6:
                var value = /** @type {!proto.PointsTagProto} */ (reader.readEnum());
                msg.setPointsTag(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setX(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setY(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setZ(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PointsTableRowProto.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.PointsTableRowProto.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PointsTableRowProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PointsTableRowProto.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getURgb();
    if (f !== 0.0) {
        writer.writeFloat(1, f);
    }
    f = message.getVRgb();
    if (f !== 0.0) {
        writer.writeFloat(2, f);
    }
    f = message.getUBev();
    if (f !== 0.0) {
        writer.writeFloat(3, f);
    }
    f = message.getVBev();
    if (f !== 0.0) {
        writer.writeFloat(4, f);
    }
    f = message.getColorIdx();
    if (f !== 0) {
        writer.writeInt32(5, f);
    }
    f = message.getPointsTag();
    if (f !== 0.0) {
        writer.writeEnum(6, f);
    }
    f = message.getX();
    if (f !== 0.0) {
        writer.writeFloat(7, f);
    }
    f = message.getY();
    if (f !== 0.0) {
        writer.writeFloat(8, f);
    }
    f = message.getZ();
    if (f !== 0.0) {
        writer.writeFloat(9, f);
    }
};

/**
 * optional float u_rgb = 1;
 * @return {number}
 */
proto.PointsTableRowProto.prototype.getURgb = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        1,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.PointsTableRowProto} returns this
 */
proto.PointsTableRowProto.prototype.setURgb = function (value) {
    return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional float v_rgb = 2;
 * @return {number}
 */
proto.PointsTableRowProto.prototype.getVRgb = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        2,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.PointsTableRowProto} returns this
 */
proto.PointsTableRowProto.prototype.setVRgb = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * optional float u_bev = 3;
 * @return {number}
 */
proto.PointsTableRowProto.prototype.getUBev = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        3,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.PointsTableRowProto} returns this
 */
proto.PointsTableRowProto.prototype.setUBev = function (value) {
    return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * optional float v_bev = 4;
 * @return {number}
 */
proto.PointsTableRowProto.prototype.getVBev = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        4,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.PointsTableRowProto} returns this
 */
proto.PointsTableRowProto.prototype.setVBev = function (value) {
    return jspb.Message.setProto3FloatField(this, 4, value);
};

/**
 * optional int32 color_idx = 5;
 * @return {number}
 */
proto.PointsTableRowProto.prototype.getColorIdx = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.PointsTableRowProto} returns this
 */
proto.PointsTableRowProto.prototype.setColorIdx = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional PointsTagProto points_tag = 6;
 * @return {!proto.PointsTagProto}
 */
proto.PointsTableRowProto.prototype.getPointsTag = function () {
    return /** @type {!proto.PointsTagProto} */ (jspb.Message.getFieldWithDefault(
        this,
        6,
        0
    ));
};

/**
 * @param {!proto.PointsTagProto} value
 * @return {!proto.PointsTableRowProto} returns this
 */
proto.PointsTableRowProto.prototype.setPointsTag = function (value) {
    return jspb.Message.setProto3EnumField(this, 6, value);
};

/**
 * optional float x = 7;
 * @return {number}
 */
proto.PointsTableRowProto.prototype.getX = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        7,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.PointsTableRowProto} returns this
 */
proto.PointsTableRowProto.prototype.setX = function (value) {
    return jspb.Message.setProto3FloatField(this, 7, value);
};

/**
 * optional float y = 8;
 * @return {number}
 */
proto.PointsTableRowProto.prototype.getY = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        8,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.PointsTableRowProto} returns this
 */
proto.PointsTableRowProto.prototype.setY = function (value) {
    return jspb.Message.setProto3FloatField(this, 8, value);
};

/**
 * optional float z = 9;
 * @return {number}
 */
proto.PointsTableRowProto.prototype.getZ = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        9,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.PointsTableRowProto} returns this
 */
proto.PointsTableRowProto.prototype.setZ = function (value) {
    return jspb.Message.setProto3FloatField(this, 9, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.CameraIntrinsicsProto.prototype.toObject = function (
        opt_includeInstance
    ) {
        return proto.CameraIntrinsicsProto.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.CameraIntrinsicsProto} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.CameraIntrinsicsProto.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                fX: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
                fY: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
                cX: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
                cY: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
                s: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CameraIntrinsicsProto}
 */
proto.CameraIntrinsicsProto.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.CameraIntrinsicsProto();
    return proto.CameraIntrinsicsProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CameraIntrinsicsProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CameraIntrinsicsProto}
 */
proto.CameraIntrinsicsProto.deserializeBinaryFromReader = function (
    msg,
    reader
) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setFX(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setFY(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setCX(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setCY(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setS(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CameraIntrinsicsProto.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.CameraIntrinsicsProto.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CameraIntrinsicsProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CameraIntrinsicsProto.serializeBinaryToWriter = function (
    message,
    writer
) {
    var f = undefined;
    f = message.getFX();
    if (f !== 0.0) {
        writer.writeDouble(1, f);
    }
    f = message.getFY();
    if (f !== 0.0) {
        writer.writeDouble(2, f);
    }
    f = message.getCX();
    if (f !== 0.0) {
        writer.writeDouble(3, f);
    }
    f = message.getCY();
    if (f !== 0.0) {
        writer.writeDouble(4, f);
    }
    f = message.getS();
    if (f !== 0.0) {
        writer.writeDouble(5, f);
    }
};

/**
 * optional double f_x = 1;
 * @return {number}
 */
proto.CameraIntrinsicsProto.prototype.getFX = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        1,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.CameraIntrinsicsProto} returns this
 */
proto.CameraIntrinsicsProto.prototype.setFX = function (value) {
    return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional double f_y = 2;
 * @return {number}
 */
proto.CameraIntrinsicsProto.prototype.getFY = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        2,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.CameraIntrinsicsProto} returns this
 */
proto.CameraIntrinsicsProto.prototype.setFY = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * optional double c_x = 3;
 * @return {number}
 */
proto.CameraIntrinsicsProto.prototype.getCX = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        3,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.CameraIntrinsicsProto} returns this
 */
proto.CameraIntrinsicsProto.prototype.setCX = function (value) {
    return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * optional double c_y = 4;
 * @return {number}
 */
proto.CameraIntrinsicsProto.prototype.getCY = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        4,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.CameraIntrinsicsProto} returns this
 */
proto.CameraIntrinsicsProto.prototype.setCY = function (value) {
    return jspb.Message.setProto3FloatField(this, 4, value);
};

/**
 * optional double s = 5;
 * @return {number}
 */
proto.CameraIntrinsicsProto.prototype.getS = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        5,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.CameraIntrinsicsProto} returns this
 */
proto.CameraIntrinsicsProto.prototype.setS = function (value) {
    return jspb.Message.setProto3FloatField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.SphericalParameters.prototype.toObject = function (
        opt_includeInstance
    ) {
        return proto.SphericalParameters.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SphericalParameters} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.SphericalParameters.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                alphaOffset: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    1,
                    0.0
                ),
                betaOffset: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    2,
                    0.0
                ),
                pxPerRadAlpha: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    3,
                    0.0
                ),
                pxPerRadBeta: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    4,
                    0.0
                )
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SphericalParameters}
 */
proto.SphericalParameters.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.SphericalParameters();
    return proto.SphericalParameters.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SphericalParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SphericalParameters}
 */
proto.SphericalParameters.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setAlphaOffset(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setBetaOffset(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setPxPerRadAlpha(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setPxPerRadBeta(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SphericalParameters.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.SphericalParameters.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SphericalParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SphericalParameters.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAlphaOffset();
    if (f !== 0.0) {
        writer.writeDouble(1, f);
    }
    f = message.getBetaOffset();
    if (f !== 0.0) {
        writer.writeDouble(2, f);
    }
    f = message.getPxPerRadAlpha();
    if (f !== 0.0) {
        writer.writeDouble(3, f);
    }
    f = message.getPxPerRadBeta();
    if (f !== 0.0) {
        writer.writeDouble(4, f);
    }
};

/**
 * optional double alpha_offset = 1;
 * @return {number}
 */
proto.SphericalParameters.prototype.getAlphaOffset = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        1,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.SphericalParameters} returns this
 */
proto.SphericalParameters.prototype.setAlphaOffset = function (value) {
    return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional double beta_offset = 2;
 * @return {number}
 */
proto.SphericalParameters.prototype.getBetaOffset = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        2,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.SphericalParameters} returns this
 */
proto.SphericalParameters.prototype.setBetaOffset = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * optional double px_per_rad_alpha = 3;
 * @return {number}
 */
proto.SphericalParameters.prototype.getPxPerRadAlpha = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        3,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.SphericalParameters} returns this
 */
proto.SphericalParameters.prototype.setPxPerRadAlpha = function (value) {
    return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * optional double px_per_rad_beta = 4;
 * @return {number}
 */
proto.SphericalParameters.prototype.getPxPerRadBeta = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        4,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.SphericalParameters} returns this
 */
proto.SphericalParameters.prototype.setPxPerRadBeta = function (value) {
    return jspb.Message.setProto3FloatField(this, 4, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.VectorProto.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.VectorProto.prototype.toObject = function (opt_includeInstance) {
        return proto.VectorProto.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.VectorProto} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.VectorProto.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                mList:
                    (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) ==
                    null
                        ? undefined
                        : f
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.VectorProto}
 */
proto.VectorProto.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.VectorProto();
    return proto.VectorProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.VectorProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.VectorProto}
 */
proto.VectorProto.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Array<number>} */ (reader.readPackedDouble());
                msg.setMList(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.VectorProto.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.VectorProto.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.VectorProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VectorProto.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMList();
    if (f.length > 0) {
        writer.writePackedDouble(1, f);
    }
};

/**
 * repeated double m = 1;
 * @return {!Array<number>}
 */
proto.VectorProto.prototype.getMList = function () {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(
        this,
        1
    ));
};

/**
 * @param {!Array<number>} value
 * @return {!proto.VectorProto} returns this
 */
proto.VectorProto.prototype.setMList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.VectorProto} returns this
 */
proto.VectorProto.prototype.addM = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.VectorProto} returns this
 */
proto.VectorProto.prototype.clearMList = function () {
    return this.setMList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MatrixProto.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.MatrixProto.prototype.toObject = function (opt_includeInstance) {
        return proto.MatrixProto.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.MatrixProto} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.MatrixProto.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                matrixRowList: jspb.Message.toObjectList(
                    msg.getMatrixRowList(),
                    proto.VectorProto.toObject,
                    includeInstance
                )
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MatrixProto}
 */
proto.MatrixProto.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.MatrixProto();
    return proto.MatrixProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MatrixProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MatrixProto}
 */
proto.MatrixProto.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.VectorProto();
                reader.readMessage(
                    value,
                    proto.VectorProto.deserializeBinaryFromReader
                );
                msg.addMatrixRow(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MatrixProto.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.MatrixProto.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MatrixProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MatrixProto.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMatrixRowList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.VectorProto.serializeBinaryToWriter
        );
    }
};

/**
 * repeated VectorProto matrix_row = 1;
 * @return {!Array<!proto.VectorProto>}
 */
proto.MatrixProto.prototype.getMatrixRowList = function () {
    return /** @type{!Array<!proto.VectorProto>} */ (jspb.Message.getRepeatedWrapperField(
        this,
        proto.VectorProto,
        1
    ));
};

/**
 * @param {!Array<!proto.VectorProto>} value
 * @return {!proto.MatrixProto} returns this
 */
proto.MatrixProto.prototype.setMatrixRowList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.VectorProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.VectorProto}
 */
proto.MatrixProto.prototype.addMatrixRow = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
        this,
        1,
        opt_value,
        proto.VectorProto,
        opt_index
    );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MatrixProto} returns this
 */
proto.MatrixProto.prototype.clearMatrixRowList = function () {
    return this.setMatrixRowList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.QuaternionProto.prototype.toObject = function (opt_includeInstance) {
        return proto.QuaternionProto.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.QuaternionProto} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.QuaternionProto.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
                y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
                z: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
                w: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.QuaternionProto}
 */
proto.QuaternionProto.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.QuaternionProto();
    return proto.QuaternionProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.QuaternionProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.QuaternionProto}
 */
proto.QuaternionProto.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setX(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setY(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setZ(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setW(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.QuaternionProto.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.QuaternionProto.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.QuaternionProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuaternionProto.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getX();
    if (f !== 0.0) {
        writer.writeDouble(1, f);
    }
    f = message.getY();
    if (f !== 0.0) {
        writer.writeDouble(2, f);
    }
    f = message.getZ();
    if (f !== 0.0) {
        writer.writeDouble(3, f);
    }
    f = message.getW();
    if (f !== 0.0) {
        writer.writeDouble(4, f);
    }
};

/**
 * optional double x = 1;
 * @return {number}
 */
proto.QuaternionProto.prototype.getX = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        1,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.QuaternionProto} returns this
 */
proto.QuaternionProto.prototype.setX = function (value) {
    return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional double y = 2;
 * @return {number}
 */
proto.QuaternionProto.prototype.getY = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        2,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.QuaternionProto} returns this
 */
proto.QuaternionProto.prototype.setY = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * optional double z = 3;
 * @return {number}
 */
proto.QuaternionProto.prototype.getZ = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        3,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.QuaternionProto} returns this
 */
proto.QuaternionProto.prototype.setZ = function (value) {
    return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * optional double w = 4;
 * @return {number}
 */
proto.QuaternionProto.prototype.getW = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        4,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.QuaternionProto} returns this
 */
proto.QuaternionProto.prototype.setW = function (value) {
    return jspb.Message.setProto3FloatField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.Vector3dProto.prototype.toObject = function (opt_includeInstance) {
        return proto.Vector3dProto.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.Vector3dProto} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.Vector3dProto.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
                y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
                z: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Vector3dProto}
 */
proto.Vector3dProto.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.Vector3dProto();
    return proto.Vector3dProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Vector3dProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Vector3dProto}
 */
proto.Vector3dProto.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setX(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setY(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setZ(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Vector3dProto.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.Vector3dProto.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Vector3dProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Vector3dProto.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getX();
    if (f !== 0.0) {
        writer.writeDouble(1, f);
    }
    f = message.getY();
    if (f !== 0.0) {
        writer.writeDouble(2, f);
    }
    f = message.getZ();
    if (f !== 0.0) {
        writer.writeDouble(3, f);
    }
};

/**
 * optional double x = 1;
 * @return {number}
 */
proto.Vector3dProto.prototype.getX = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        1,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.Vector3dProto} returns this
 */
proto.Vector3dProto.prototype.setX = function (value) {
    return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional double y = 2;
 * @return {number}
 */
proto.Vector3dProto.prototype.getY = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        2,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.Vector3dProto} returns this
 */
proto.Vector3dProto.prototype.setY = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * optional double z = 3;
 * @return {number}
 */
proto.Vector3dProto.prototype.getZ = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        3,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.Vector3dProto} returns this
 */
proto.Vector3dProto.prototype.setZ = function (value) {
    return jspb.Message.setProto3FloatField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.GeometryTransformationProto.prototype.toObject = function (
        opt_includeInstance
    ) {
        return proto.GeometryTransformationProto.toObject(
            opt_includeInstance,
            this
        );
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.GeometryTransformationProto} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.GeometryTransformationProto.toObject = function (
        includeInstance,
        msg
    ) {
        var f,
            obj = {
                q:
                    (f = msg.getQ()) &&
                    proto.QuaternionProto.toObject(includeInstance, f),
                p:
                    (f = msg.getP()) &&
                    proto.Vector3dProto.toObject(includeInstance, f),
                t:
                    (f = msg.getT()) &&
                    proto.MatrixProto.toObject(includeInstance, f)
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GeometryTransformationProto}
 */
proto.GeometryTransformationProto.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.GeometryTransformationProto();
    return proto.GeometryTransformationProto.deserializeBinaryFromReader(
        msg,
        reader
    );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GeometryTransformationProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GeometryTransformationProto}
 */
proto.GeometryTransformationProto.deserializeBinaryFromReader = function (
    msg,
    reader
) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.QuaternionProto();
                reader.readMessage(
                    value,
                    proto.QuaternionProto.deserializeBinaryFromReader
                );
                msg.setQ(value);
                break;
            case 2:
                var value = new proto.Vector3dProto();
                reader.readMessage(
                    value,
                    proto.Vector3dProto.deserializeBinaryFromReader
                );
                msg.setP(value);
                break;
            case 3:
                var value = new proto.MatrixProto();
                reader.readMessage(
                    value,
                    proto.MatrixProto.deserializeBinaryFromReader
                );
                msg.setT(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GeometryTransformationProto.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.GeometryTransformationProto.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GeometryTransformationProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GeometryTransformationProto.serializeBinaryToWriter = function (
    message,
    writer
) {
    var f = undefined;
    f = message.getQ();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            proto.QuaternionProto.serializeBinaryToWriter
        );
    }
    f = message.getP();
    if (f != null) {
        writer.writeMessage(2, f, proto.Vector3dProto.serializeBinaryToWriter);
    }
    f = message.getT();
    if (f != null) {
        writer.writeMessage(3, f, proto.MatrixProto.serializeBinaryToWriter);
    }
};

/**
 * optional QuaternionProto q = 1;
 * @return {?proto.QuaternionProto}
 */
proto.GeometryTransformationProto.prototype.getQ = function () {
    return /** @type{?proto.QuaternionProto} */ (jspb.Message.getWrapperField(
        this,
        proto.QuaternionProto,
        1
    ));
};

/**
 * @param {?proto.QuaternionProto|undefined} value
 * @return {!proto.GeometryTransformationProto} returns this
 */
proto.GeometryTransformationProto.prototype.setQ = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.GeometryTransformationProto} returns this
 */
proto.GeometryTransformationProto.prototype.clearQ = function () {
    return this.setQ(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GeometryTransformationProto.prototype.hasQ = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Vector3dProto p = 2;
 * @return {?proto.Vector3dProto}
 */
proto.GeometryTransformationProto.prototype.getP = function () {
    return /** @type{?proto.Vector3dProto} */ (jspb.Message.getWrapperField(
        this,
        proto.Vector3dProto,
        2
    ));
};

/**
 * @param {?proto.Vector3dProto|undefined} value
 * @return {!proto.GeometryTransformationProto} returns this
 */
proto.GeometryTransformationProto.prototype.setP = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.GeometryTransformationProto} returns this
 */
proto.GeometryTransformationProto.prototype.clearP = function () {
    return this.setP(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GeometryTransformationProto.prototype.hasP = function () {
    return jspb.Message.getField(this, 2) != null;
};

/**
 * optional MatrixProto T = 3;
 * @return {?proto.MatrixProto}
 */
proto.GeometryTransformationProto.prototype.getT = function () {
    return /** @type{?proto.MatrixProto} */ (jspb.Message.getWrapperField(
        this,
        proto.MatrixProto,
        3
    ));
};

/**
 * @param {?proto.MatrixProto|undefined} value
 * @return {!proto.GeometryTransformationProto} returns this
 */
proto.GeometryTransformationProto.prototype.setT = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.GeometryTransformationProto} returns this
 */
proto.GeometryTransformationProto.prototype.clearT = function () {
    return this.setT(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GeometryTransformationProto.prototype.hasT = function () {
    return jspb.Message.getField(this, 3) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.BevTransformation.prototype.toObject = function (
        opt_includeInstance
    ) {
        return proto.BevTransformation.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.BevTransformation} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.BevTransformation.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                lidarTGlobal:
                    (f = msg.getLidarTGlobal()) &&
                    proto.MatrixProto.toObject(includeInstance, f),
                lidarZRot: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    2,
                    0.0
                ),
                mvsTGlobal:
                    (f = msg.getMvsTGlobal()) &&
                    proto.MatrixProto.toObject(includeInstance, f),
                mvsZRot: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    7,
                    0.0
                )
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BevTransformation}
 */
proto.BevTransformation.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.BevTransformation();
    return proto.BevTransformation.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BevTransformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BevTransformation}
 */
proto.BevTransformation.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.MatrixProto();
                reader.readMessage(
                    value,
                    proto.MatrixProto.deserializeBinaryFromReader
                );
                msg.setLidarTGlobal(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setLidarZRot(value);
                break;
            case 6:
                var value = new proto.MatrixProto();
                reader.readMessage(
                    value,
                    proto.MatrixProto.deserializeBinaryFromReader
                );
                msg.setMvsTGlobal(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setMvsZRot(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BevTransformation.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.BevTransformation.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BevTransformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BevTransformation.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLidarTGlobal();
    if (f != null) {
        writer.writeMessage(1, f, proto.MatrixProto.serializeBinaryToWriter);
    }
    f = message.getLidarZRot();
    if (f !== 0.0) {
        writer.writeFloat(2, f);
    }
    f = message.getMvsTGlobal();
    if (f != null) {
        writer.writeMessage(6, f, proto.MatrixProto.serializeBinaryToWriter);
    }
    f = message.getMvsZRot();
    if (f !== 0.0) {
        writer.writeFloat(7, f);
    }
};

/**
 * optional MatrixProto lidar_T_global = 1;
 * @return {?proto.MatrixProto}
 */
proto.BevTransformation.prototype.getLidarTGlobal = function () {
    return /** @type{?proto.MatrixProto} */ (jspb.Message.getWrapperField(
        this,
        proto.MatrixProto,
        1
    ));
};

/**
 * @param {?proto.MatrixProto|undefined} value
 * @return {!proto.BevTransformation} returns this
 */
proto.BevTransformation.prototype.setLidarTGlobal = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.BevTransformation} returns this
 */
proto.BevTransformation.prototype.clearLidarTGlobal = function () {
    return this.setLidarTGlobal(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.BevTransformation.prototype.hasLidarTGlobal = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * optional float lidar_z_rot = 2;
 * @return {number}
 */
proto.BevTransformation.prototype.getLidarZRot = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        2,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.BevTransformation} returns this
 */
proto.BevTransformation.prototype.setLidarZRot = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * optional MatrixProto mvs_T_global = 6;
 * @return {?proto.MatrixProto}
 */
proto.BevTransformation.prototype.getMvsTGlobal = function () {
    return /** @type{?proto.MatrixProto} */ (jspb.Message.getWrapperField(
        this,
        proto.MatrixProto,
        6
    ));
};

/**
 * @param {?proto.MatrixProto|undefined} value
 * @return {!proto.BevTransformation} returns this
 */
proto.BevTransformation.prototype.setMvsTGlobal = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.BevTransformation} returns this
 */
proto.BevTransformation.prototype.clearMvsTGlobal = function () {
    return this.setMvsTGlobal(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.BevTransformation.prototype.hasMvsTGlobal = function () {
    return jspb.Message.getField(this, 6) != null;
};

/**
 * optional float mvs_z_rot = 7;
 * @return {number}
 */
proto.BevTransformation.prototype.getMvsZRot = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        7,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.BevTransformation} returns this
 */
proto.BevTransformation.prototype.setMvsZRot = function (value) {
    return jspb.Message.setProto3FloatField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.VisualizationInfo.prototype.toObject = function (
        opt_includeInstance
    ) {
        return proto.VisualizationInfo.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.VisualizationInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.VisualizationInfo.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                refPlaneCartesianCoefficients:
                    (f = msg.getRefPlaneCartesianCoefficients()) &&
                    proto.VectorProto.toObject(includeInstance, f),
                zMinBev: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    2,
                    0.0
                ),
                zMaxBev: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    3,
                    0.0
                ),
                zMinBevCor: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    4,
                    0.0
                ),
                zMaxBevCor: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    5,
                    0.0
                )
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.VisualizationInfo}
 */
proto.VisualizationInfo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.VisualizationInfo();
    return proto.VisualizationInfo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.VisualizationInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.VisualizationInfo}
 */
proto.VisualizationInfo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.VectorProto();
                reader.readMessage(
                    value,
                    proto.VectorProto.deserializeBinaryFromReader
                );
                msg.setRefPlaneCartesianCoefficients(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setZMinBev(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setZMaxBev(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setZMinBevCor(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setZMaxBevCor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.VisualizationInfo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.VisualizationInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.VisualizationInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VisualizationInfo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRefPlaneCartesianCoefficients();
    if (f != null) {
        writer.writeMessage(1, f, proto.VectorProto.serializeBinaryToWriter);
    }
    f = message.getZMinBev();
    if (f !== 0.0) {
        writer.writeFloat(2, f);
    }
    f = message.getZMaxBev();
    if (f !== 0.0) {
        writer.writeFloat(3, f);
    }
    f = message.getZMinBevCor();
    if (f !== 0.0) {
        writer.writeFloat(4, f);
    }
    f = message.getZMaxBevCor();
    if (f !== 0.0) {
        writer.writeFloat(5, f);
    }
};

/**
 * optional VectorProto ref_plane_cartesian_coefficients = 1;
 * @return {?proto.VectorProto}
 */
proto.VisualizationInfo.prototype.getRefPlaneCartesianCoefficients = function () {
    return /** @type{?proto.VectorProto} */ (jspb.Message.getWrapperField(
        this,
        proto.VectorProto,
        1
    ));
};

/**
 * @param {?proto.VectorProto|undefined} value
 * @return {!proto.VisualizationInfo} returns this
 */
proto.VisualizationInfo.prototype.setRefPlaneCartesianCoefficients = function (
    value
) {
    return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.VisualizationInfo} returns this
 */
proto.VisualizationInfo.prototype.clearRefPlaneCartesianCoefficients = function () {
    return this.setRefPlaneCartesianCoefficients(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.VisualizationInfo.prototype.hasRefPlaneCartesianCoefficients = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * optional float z_min_bev = 2;
 * @return {number}
 */
proto.VisualizationInfo.prototype.getZMinBev = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        2,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.VisualizationInfo} returns this
 */
proto.VisualizationInfo.prototype.setZMinBev = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * optional float z_max_bev = 3;
 * @return {number}
 */
proto.VisualizationInfo.prototype.getZMaxBev = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        3,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.VisualizationInfo} returns this
 */
proto.VisualizationInfo.prototype.setZMaxBev = function (value) {
    return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * optional float z_min_bev_cor = 4;
 * @return {number}
 */
proto.VisualizationInfo.prototype.getZMinBevCor = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        4,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.VisualizationInfo} returns this
 */
proto.VisualizationInfo.prototype.setZMinBevCor = function (value) {
    return jspb.Message.setProto3FloatField(this, 4, value);
};

/**
 * optional float z_max_bev_cor = 5;
 * @return {number}
 */
proto.VisualizationInfo.prototype.getZMaxBevCor = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        5,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.VisualizationInfo} returns this
 */
proto.VisualizationInfo.prototype.setZMaxBevCor = function (value) {
    return jspb.Message.setProto3FloatField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.MetaData.prototype.toObject = function (opt_includeInstance) {
        return proto.MetaData.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.MetaData} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.MetaData.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                categoryName: jspb.Message.getFieldWithDefault(msg, 1, ""),
                instanceToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
                sampleToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
                area: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    4,
                    0.0
                ),
                numPointsBeforeServerFiltering: jspb.Message.getFieldWithDefault(
                    msg,
                    5,
                    0
                ),
                relativeBoxTop: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    6,
                    0.0
                ),
                relativeBoxBottom: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    7,
                    0.0
                ),
                relativeBoxLeft: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    8,
                    0.0
                ),
                relativeBoxRight: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    9,
                    0.0
                ),
                boxTop: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    10,
                    0.0
                ),
                boxBottom: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    11,
                    0.0
                ),
                boxLeft: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    12,
                    0.0
                ),
                boxRight: jspb.Message.getFloatingPointFieldWithDefault(
                    msg,
                    13,
                    0.0
                ),
                rgbImageWidth: jspb.Message.getFieldWithDefault(msg, 14, 0),
                rgbImageHeight: jspb.Message.getFieldWithDefault(msg, 15, 0),
                outOfFrame: jspb.Message.getBooleanFieldWithDefault(
                    msg,
                    16,
                    false
                ),
                ambiguous: jspb.Message.getBooleanFieldWithDefault(
                    msg,
                    17,
                    false
                ),
                occluded: jspb.Message.getFieldWithDefault(msg, 18, ""),
                frontBack: jspb.Message.getFieldWithDefault(msg, 19, ""),
                vehicleState: jspb.Message.getFieldWithDefault(msg, 20, ""),
                bevTransform:
                    (f = msg.getBevTransform()) &&
                    proto.BevTransformation.toObject(includeInstance, f),
                visualizationInfo:
                    (f = msg.getVisualizationInfo()) &&
                    proto.VisualizationInfo.toObject(includeInstance, f)
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MetaData}
 */
proto.MetaData.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.MetaData();
    return proto.MetaData.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MetaData}
 */
proto.MetaData.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setCategoryName(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setInstanceToken(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setSampleToken(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setArea(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setNumPointsBeforeServerFiltering(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setRelativeBoxTop(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setRelativeBoxBottom(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setRelativeBoxLeft(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setRelativeBoxRight(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setBoxTop(value);
                break;
            case 11:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setBoxBottom(value);
                break;
            case 12:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setBoxLeft(value);
                break;
            case 13:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setBoxRight(value);
                break;
            case 14:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setRgbImageWidth(value);
                break;
            case 15:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setRgbImageHeight(value);
                break;
            case 16:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setOutOfFrame(value);
                break;
            case 17:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setAmbiguous(value);
                break;
            case 18:
                var value = /** @type {string} */ (reader.readString());
                msg.setOccluded(value);
                break;
            case 19:
                var value = /** @type {string} */ (reader.readString());
                msg.setFrontBack(value);
                break;
            case 20:
                var value = /** @type {string} */ (reader.readString());
                msg.setVehicleState(value);
                break;
            case 21:
                var value = new proto.BevTransformation();
                reader.readMessage(
                    value,
                    proto.BevTransformation.deserializeBinaryFromReader
                );
                msg.setBevTransform(value);
                break;
            case 22:
                var value = new proto.VisualizationInfo();
                reader.readMessage(
                    value,
                    proto.VisualizationInfo.deserializeBinaryFromReader
                );
                msg.setVisualizationInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MetaData.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.MetaData.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MetaData.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCategoryName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getInstanceToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getSampleToken();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getArea();
    if (f !== 0.0) {
        writer.writeFloat(4, f);
    }
    f = message.getNumPointsBeforeServerFiltering();
    if (f !== 0) {
        writer.writeUint32(5, f);
    }
    f = message.getRelativeBoxTop();
    if (f !== 0.0) {
        writer.writeFloat(6, f);
    }
    f = message.getRelativeBoxBottom();
    if (f !== 0.0) {
        writer.writeFloat(7, f);
    }
    f = message.getRelativeBoxLeft();
    if (f !== 0.0) {
        writer.writeFloat(8, f);
    }
    f = message.getRelativeBoxRight();
    if (f !== 0.0) {
        writer.writeFloat(9, f);
    }
    f = message.getBoxTop();
    if (f !== 0.0) {
        writer.writeFloat(10, f);
    }
    f = message.getBoxBottom();
    if (f !== 0.0) {
        writer.writeFloat(11, f);
    }
    f = message.getBoxLeft();
    if (f !== 0.0) {
        writer.writeFloat(12, f);
    }
    f = message.getBoxRight();
    if (f !== 0.0) {
        writer.writeFloat(13, f);
    }
    f = message.getRgbImageWidth();
    if (f !== 0) {
        writer.writeUint32(14, f);
    }
    f = message.getRgbImageHeight();
    if (f !== 0) {
        writer.writeUint32(15, f);
    }
    f = message.getOutOfFrame();
    if (f) {
        writer.writeBool(16, f);
    }
    f = message.getAmbiguous();
    if (f) {
        writer.writeBool(17, f);
    }
    f = message.getOccluded();
    if (f.length > 0) {
        writer.writeString(18, f);
    }
    f = message.getFrontBack();
    if (f.length > 0) {
        writer.writeString(19, f);
    }
    f = message.getVehicleState();
    if (f.length > 0) {
        writer.writeString(20, f);
    }
    f = message.getBevTransform();
    if (f != null) {
        writer.writeMessage(
            21,
            f,
            proto.BevTransformation.serializeBinaryToWriter
        );
    }
    f = message.getVisualizationInfo();
    if (f != null) {
        writer.writeMessage(
            22,
            f,
            proto.VisualizationInfo.serializeBinaryToWriter
        );
    }
};

/**
 * optional string category_name = 1;
 * @return {string}
 */
proto.MetaData.prototype.getCategoryName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(
        this,
        1,
        ""
    ));
};

/**
 * @param {string} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setCategoryName = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string instance_token = 2;
 * @return {string}
 */
proto.MetaData.prototype.getInstanceToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(
        this,
        2,
        ""
    ));
};

/**
 * @param {string} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setInstanceToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string sample_token = 3;
 * @return {string}
 */
proto.MetaData.prototype.getSampleToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(
        this,
        3,
        ""
    ));
};

/**
 * @param {string} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setSampleToken = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional float area = 4;
 * @return {number}
 */
proto.MetaData.prototype.getArea = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        4,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setArea = function (value) {
    return jspb.Message.setProto3FloatField(this, 4, value);
};

/**
 * optional uint32 num_points_before_server_filtering = 5;
 * @return {number}
 */
proto.MetaData.prototype.getNumPointsBeforeServerFiltering = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setNumPointsBeforeServerFiltering = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional float relative_box_top = 6;
 * @return {number}
 */
proto.MetaData.prototype.getRelativeBoxTop = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        6,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setRelativeBoxTop = function (value) {
    return jspb.Message.setProto3FloatField(this, 6, value);
};

/**
 * optional float relative_box_bottom = 7;
 * @return {number}
 */
proto.MetaData.prototype.getRelativeBoxBottom = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        7,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setRelativeBoxBottom = function (value) {
    return jspb.Message.setProto3FloatField(this, 7, value);
};

/**
 * optional float relative_box_left = 8;
 * @return {number}
 */
proto.MetaData.prototype.getRelativeBoxLeft = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        8,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setRelativeBoxLeft = function (value) {
    return jspb.Message.setProto3FloatField(this, 8, value);
};

/**
 * optional float relative_box_right = 9;
 * @return {number}
 */
proto.MetaData.prototype.getRelativeBoxRight = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        9,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setRelativeBoxRight = function (value) {
    return jspb.Message.setProto3FloatField(this, 9, value);
};

/**
 * optional float box_top = 10;
 * @return {number}
 */
proto.MetaData.prototype.getBoxTop = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        10,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setBoxTop = function (value) {
    return jspb.Message.setProto3FloatField(this, 10, value);
};

/**
 * optional float box_bottom = 11;
 * @return {number}
 */
proto.MetaData.prototype.getBoxBottom = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        11,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setBoxBottom = function (value) {
    return jspb.Message.setProto3FloatField(this, 11, value);
};

/**
 * optional float box_left = 12;
 * @return {number}
 */
proto.MetaData.prototype.getBoxLeft = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        12,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setBoxLeft = function (value) {
    return jspb.Message.setProto3FloatField(this, 12, value);
};

/**
 * optional float box_right = 13;
 * @return {number}
 */
proto.MetaData.prototype.getBoxRight = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
        this,
        13,
        0.0
    ));
};

/**
 * @param {number} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setBoxRight = function (value) {
    return jspb.Message.setProto3FloatField(this, 13, value);
};

/**
 * optional uint32 rgb_image_width = 14;
 * @return {number}
 */
proto.MetaData.prototype.getRgbImageWidth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(
        this,
        14,
        0
    ));
};

/**
 * @param {number} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setRgbImageWidth = function (value) {
    return jspb.Message.setProto3IntField(this, 14, value);
};

/**
 * optional uint32 rgb_image_height = 15;
 * @return {number}
 */
proto.MetaData.prototype.getRgbImageHeight = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(
        this,
        15,
        0
    ));
};

/**
 * @param {number} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setRgbImageHeight = function (value) {
    return jspb.Message.setProto3IntField(this, 15, value);
};

/**
 * optional bool out_of_frame = 16;
 * @return {boolean}
 */
proto.MetaData.prototype.getOutOfFrame = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
        this,
        16,
        false
    ));
};

/**
 * @param {boolean} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setOutOfFrame = function (value) {
    return jspb.Message.setProto3BooleanField(this, 16, value);
};

/**
 * optional bool ambiguous = 17;
 * @return {boolean}
 */
proto.MetaData.prototype.getAmbiguous = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
        this,
        17,
        false
    ));
};

/**
 * @param {boolean} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setAmbiguous = function (value) {
    return jspb.Message.setProto3BooleanField(this, 17, value);
};

/**
 * optional string occluded = 18;
 * @return {string}
 */
proto.MetaData.prototype.getOccluded = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(
        this,
        18,
        ""
    ));
};

/**
 * @param {string} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setOccluded = function (value) {
    return jspb.Message.setProto3StringField(this, 18, value);
};

/**
 * optional string front_back = 19;
 * @return {string}
 */
proto.MetaData.prototype.getFrontBack = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(
        this,
        19,
        ""
    ));
};

/**
 * @param {string} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setFrontBack = function (value) {
    return jspb.Message.setProto3StringField(this, 19, value);
};

/**
 * optional string vehicle_state = 20;
 * @return {string}
 */
proto.MetaData.prototype.getVehicleState = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(
        this,
        20,
        ""
    ));
};

/**
 * @param {string} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setVehicleState = function (value) {
    return jspb.Message.setProto3StringField(this, 20, value);
};

/**
 * optional BevTransformation bev_transform = 21;
 * @return {?proto.BevTransformation}
 */
proto.MetaData.prototype.getBevTransform = function () {
    return /** @type{?proto.BevTransformation} */ (jspb.Message.getWrapperField(
        this,
        proto.BevTransformation,
        21
    ));
};

/**
 * @param {?proto.BevTransformation|undefined} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setBevTransform = function (value) {
    return jspb.Message.setWrapperField(this, 21, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.clearBevTransform = function () {
    return this.setBevTransform(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MetaData.prototype.hasBevTransform = function () {
    return jspb.Message.getField(this, 21) != null;
};

/**
 * optional VisualizationInfo visualization_info = 22;
 * @return {?proto.VisualizationInfo}
 */
proto.MetaData.prototype.getVisualizationInfo = function () {
    return /** @type{?proto.VisualizationInfo} */ (jspb.Message.getWrapperField(
        this,
        proto.VisualizationInfo,
        22
    ));
};

/**
 * @param {?proto.VisualizationInfo|undefined} value
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.setVisualizationInfo = function (value) {
    return jspb.Message.setWrapperField(this, 22, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.MetaData} returns this
 */
proto.MetaData.prototype.clearVisualizationInfo = function () {
    return this.setVisualizationInfo(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MetaData.prototype.hasVisualizationInfo = function () {
    return jspb.Message.getField(this, 22) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Table.repeatedFields_ = [10];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.Table.prototype.toObject = function (opt_includeInstance) {
        return proto.Table.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.Table} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.Table.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                cameraIntrinsics:
                    (f = msg.getCameraIntrinsics()) &&
                    proto.CameraIntrinsicsProto.toObject(includeInstance, f),
                camTPc:
                    (f = msg.getCamTPc()) &&
                    proto.GeometryTransformationProto.toObject(
                        includeInstance,
                        f
                    ),
                projectionMatrixBev:
                    (f = msg.getProjectionMatrixBev()) &&
                    proto.MatrixProto.toObject(includeInstance, f),
                numPoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
                rgbSphericalParams:
                    (f = msg.getRgbSphericalParams()) &&
                    proto.SphericalParameters.toObject(includeInstance, f),
                metaData:
                    (f = msg.getMetaData()) &&
                    proto.MetaData.toObject(includeInstance, f),
                numPointsInAnnotation: jspb.Message.getFieldWithDefault(
                    msg,
                    7,
                    0
                ),
                timestampCreated: jspb.Message.getFieldWithDefault(msg, 8, ""),
                pointsTableRowList: jspb.Message.toObjectList(
                    msg.getPointsTableRowList(),
                    proto.PointsTableRowProto.toObject,
                    includeInstance
                )
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Table}
 */
proto.Table.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.Table();
    return proto.Table.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Table}
 */
proto.Table.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.CameraIntrinsicsProto();
                reader.readMessage(
                    value,
                    proto.CameraIntrinsicsProto.deserializeBinaryFromReader
                );
                msg.setCameraIntrinsics(value);
                break;
            case 2:
                var value = new proto.GeometryTransformationProto();
                reader.readMessage(
                    value,
                    proto.GeometryTransformationProto
                        .deserializeBinaryFromReader
                );
                msg.setCamTPc(value);
                break;
            case 3:
                var value = new proto.MatrixProto();
                reader.readMessage(
                    value,
                    proto.MatrixProto.deserializeBinaryFromReader
                );
                msg.setProjectionMatrixBev(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setNumPoints(value);
                break;
            case 5:
                var value = new proto.SphericalParameters();
                reader.readMessage(
                    value,
                    proto.SphericalParameters.deserializeBinaryFromReader
                );
                msg.setRgbSphericalParams(value);
                break;
            case 6:
                var value = new proto.MetaData();
                reader.readMessage(
                    value,
                    proto.MetaData.deserializeBinaryFromReader
                );
                msg.setMetaData(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setNumPointsInAnnotation(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setTimestampCreated(value);
                break;
            case 10:
                var value = new proto.PointsTableRowProto();
                reader.readMessage(
                    value,
                    proto.PointsTableRowProto.deserializeBinaryFromReader
                );
                msg.addPointsTableRow(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Table.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.Table.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Table.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCameraIntrinsics();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            proto.CameraIntrinsicsProto.serializeBinaryToWriter
        );
    }
    f = message.getCamTPc();
    if (f != null) {
        writer.writeMessage(
            2,
            f,
            proto.GeometryTransformationProto.serializeBinaryToWriter
        );
    }
    f = message.getProjectionMatrixBev();
    if (f != null) {
        writer.writeMessage(3, f, proto.MatrixProto.serializeBinaryToWriter);
    }
    f = message.getNumPoints();
    if (f !== 0) {
        writer.writeUint32(4, f);
    }
    f = message.getRgbSphericalParams();
    if (f != null) {
        writer.writeMessage(
            5,
            f,
            proto.SphericalParameters.serializeBinaryToWriter
        );
    }
    f = message.getMetaData();
    if (f != null) {
        writer.writeMessage(6, f, proto.MetaData.serializeBinaryToWriter);
    }
    f = message.getNumPointsInAnnotation();
    if (f !== 0) {
        writer.writeUint32(7, f);
    }
    f = message.getTimestampCreated();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
    f = message.getPointsTableRowList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            10,
            f,
            proto.PointsTableRowProto.serializeBinaryToWriter
        );
    }
};

/**
 * optional CameraIntrinsicsProto camera_intrinsics = 1;
 * @return {?proto.CameraIntrinsicsProto}
 */
proto.Table.prototype.getCameraIntrinsics = function () {
    return /** @type{?proto.CameraIntrinsicsProto} */ (jspb.Message.getWrapperField(
        this,
        proto.CameraIntrinsicsProto,
        1
    ));
};

/**
 * @param {?proto.CameraIntrinsicsProto|undefined} value
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.setCameraIntrinsics = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.clearCameraIntrinsics = function () {
    return this.setCameraIntrinsics(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Table.prototype.hasCameraIntrinsics = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * optional GeometryTransformationProto cam_T_pc = 2;
 * @return {?proto.GeometryTransformationProto}
 */
proto.Table.prototype.getCamTPc = function () {
    return /** @type{?proto.GeometryTransformationProto} */ (jspb.Message.getWrapperField(
        this,
        proto.GeometryTransformationProto,
        2
    ));
};

/**
 * @param {?proto.GeometryTransformationProto|undefined} value
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.setCamTPc = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.clearCamTPc = function () {
    return this.setCamTPc(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Table.prototype.hasCamTPc = function () {
    return jspb.Message.getField(this, 2) != null;
};

/**
 * optional MatrixProto projection_matrix_bev = 3;
 * @return {?proto.MatrixProto}
 */
proto.Table.prototype.getProjectionMatrixBev = function () {
    return /** @type{?proto.MatrixProto} */ (jspb.Message.getWrapperField(
        this,
        proto.MatrixProto,
        3
    ));
};

/**
 * @param {?proto.MatrixProto|undefined} value
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.setProjectionMatrixBev = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.clearProjectionMatrixBev = function () {
    return this.setProjectionMatrixBev(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Table.prototype.hasProjectionMatrixBev = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * optional uint32 num_points = 4;
 * @return {number}
 */
proto.Table.prototype.getNumPoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.setNumPoints = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional SphericalParameters rgb_spherical_params = 5;
 * @return {?proto.SphericalParameters}
 */
proto.Table.prototype.getRgbSphericalParams = function () {
    return /** @type{?proto.SphericalParameters} */ (jspb.Message.getWrapperField(
        this,
        proto.SphericalParameters,
        5
    ));
};

/**
 * @param {?proto.SphericalParameters|undefined} value
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.setRgbSphericalParams = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.clearRgbSphericalParams = function () {
    return this.setRgbSphericalParams(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Table.prototype.hasRgbSphericalParams = function () {
    return jspb.Message.getField(this, 5) != null;
};

/**
 * optional MetaData meta_data = 6;
 * @return {?proto.MetaData}
 */
proto.Table.prototype.getMetaData = function () {
    return /** @type{?proto.MetaData} */ (jspb.Message.getWrapperField(
        this,
        proto.MetaData,
        6
    ));
};

/**
 * @param {?proto.MetaData|undefined} value
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.setMetaData = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.clearMetaData = function () {
    return this.setMetaData(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Table.prototype.hasMetaData = function () {
    return jspb.Message.getField(this, 6) != null;
};

/**
 * optional uint32 num_points_in_annotation = 7;
 * @return {number}
 */
proto.Table.prototype.getNumPointsInAnnotation = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};

/**
 * @param {number} value
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.setNumPointsInAnnotation = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};

/**
 * optional string timestamp_created = 8;
 * @return {string}
 */
proto.Table.prototype.getTimestampCreated = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(
        this,
        8,
        ""
    ));
};

/**
 * @param {string} value
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.setTimestampCreated = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * repeated PointsTableRowProto points_table_row = 10;
 * @return {!Array<!proto.PointsTableRowProto>}
 */
proto.Table.prototype.getPointsTableRowList = function () {
    return /** @type{!Array<!proto.PointsTableRowProto>} */ (jspb.Message.getRepeatedWrapperField(
        this,
        proto.PointsTableRowProto,
        10
    ));
};

/**
 * @param {!Array<!proto.PointsTableRowProto>} value
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.setPointsTableRowList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 10, value);
};

/**
 * @param {!proto.PointsTableRowProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PointsTableRowProto}
 */
proto.Table.prototype.addPointsTableRow = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
        this,
        10,
        opt_value,
        proto.PointsTableRowProto,
        opt_index
    );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Table} returns this
 */
proto.Table.prototype.clearPointsTableRowList = function () {
    return this.setPointsTableRowList([]);
};

/**
 * @enum {number}
 */
proto.PointsTagProto = {
    UNKNOWN_TAG: 0,
    ENVIRONMENT: 1,
    IN_2D_BOUNDING_BOX: 2,
    MVS_ENVIRONMENT: 3,
    MVS_IN_2D_BOUNDING_BOX: 4
};

goog.object.extend(exports, proto);
