/**
 * A general purpose reducer generator for simple independent reducers
 * source: https://redux.js.org/recipes/reducing-boilerplate#generating-reducers
 */
const generateReducer = (initialState, handlers) => {
    return (state = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action);
        } else {
            return state;
        }
    };
};

export default generateReducer;
