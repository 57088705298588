import React from "react";
import { Container } from "react-bootstrap";

import { LoadingAnimation } from "../../components/LoadingAnimation/LoadingAnimation";
import "./ImageSelectionMatrix.css";

export const ImageSelectionMatrix = ({
    taskUIContext,
    currentTaskIdx,
    currentTaskOutput,
    setCurrentTaskOutput
}) => {
    const taskInput = taskUIContext.getCurrentTaskInput();

    if (
        !taskUIContext
            .getCurrentGuiObject()
            .taskInputResourcesInCache(currentTaskIdx)
    ) {
        return <LoadingAnimation />;
    }

    taskUIContext.onViewReady();
    // selected images of current task
    const selected = currentTaskOutput ? currentTaskOutput.selection : [];
    const taskInputImageKeys = Object.keys(taskInput);

    // grids depending on number of images in each task
    let grid = "four_by_four_grid";
    if (taskInputImageKeys.length < 10 && taskInputImageKeys.length !== 8) {
        grid = "three_by_three_grid";
    }
    if (taskInputImageKeys.length === 4 || taskInputImageKeys.length === 2) {
        grid = "two_by_two_grid";
    }

    return (
        <Container className={grid} key={currentTaskIdx}>
            {taskInputImageKeys.map((imageKey, i) => {
                let imgClassName = "selectableImage";
                if (selected.includes(imageKey)) {
                    imgClassName += " selected";
                }

                return (
                    <div key={i}>
                        <img
                            className={imgClassName}
                            id={imageKey}
                            src={taskInput[imageKey]}
                            alt=""
                            // image is selected and unselected onClick, style changes accordingly
                            onClick={event => {
                                let newSelection = [];
                                if (!selected.includes(imageKey)) {
                                    newSelection = [...selected, imageKey];
                                } else {
                                    newSelection = selected.filter(
                                        selectedImageKey =>
                                            selectedImageKey !== imageKey
                                    );
                                }

                                if (newSelection.length === 0) {
                                    setCurrentTaskOutput(null);
                                } else {
                                    setCurrentTaskOutput(
                                        taskUIContext
                                            .getCurrentGuiObject()
                                            .makeTaskOutputForCurrentTask({
                                                selection: newSelection
                                            })
                                    );
                                }

                                taskUIContext
                                    .getCurrentGuiObject()
                                    .onAction("select_image");
                            }}
                        ></img>
                    </div>
                );
            })}
        </Container>
    );
};

export default ImageSelectionMatrix;
