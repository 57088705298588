import { ActionTypes } from "../actions";
import generateReducer from "../generateReducer";

export const tasksAmountReducer = generateReducer(0, {
    [ActionTypes.SET_TASKS_AMOUNT]: (tasksAmount, action) => {
        return action.tasksAmount;
    }
});

export const getTasksAmount = state => state.tasksAmount;

export default tasksAmountReducer;
