import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

import RectDiv from "./RectDiv";
import { BBox2DModel } from "../../../lib/DataModels/BBox2DModel";
import Draggable from "./Draggable";
import {
    adjustDimensionsToMaxConstraint,
    transformPoint2DFromDimensions
} from "../../../lib/qm_cs_lib";
import { useCustomContainerQMResizedCallback } from "../../../customHooks/useContainerQMDimensions";
import { LoadingAnimation } from "../../../components/LoadingAnimation/LoadingAnimation";

export const BBox2DWidget = ({
    guiSettings,
    resourceCache,
    taskUIContext,
    currentTaskIdx,
    currentTaskOutput,
    setCurrentTaskOutput
}) => {
    // topLeftPos is the bottom-right corner the draggable square
    // in relative image coordinates
    const [topLeftPos, setTopLeftPos] = useState({ x: 0, y: 0 });
    // botRightPos is the top-left corner the draggable square
    // in relative image coordinates
    const [botRightPos, setBotRightPos] = useState({ x: 0, y: 0 });
    const [currentImage, setCurrentImage] = useState(null);
    const [containerQMDimensions, setContainerQMDimensions] = useState({
        width: 0,
        height: 0
    });
    const [viewPortDimensions, setViewPortDimensions] = useState({
        width: 0,
        height: 0
    });
    const [imageOriginalDimensions, setImageOriginalDimensions] = useState({
        width: 0,
        height: 0
    });
    const [imageResizedDimensions, setImageResizedDimensions] = useState({
        width: 0,
        height: 0
    });
    useCustomContainerQMResizedCallback(currentTaskIdx, function (
        containerQMDimensions,
        viewPortDimensions,
        containerQm,
        resizeObserver
    ) {
        setContainerQMDimensions(containerQMDimensions);
        setViewPortDimensions(viewPortDimensions);

        // stop observing as soon as resizing has reached viewPort's width or height
        if (
            viewPortDimensions.width !== 0 &&
            viewPortDimensions.height !== 0 &&
            (containerQm.offsetHeight === viewPortDimensions.height ||
                containerQm.offsetWidth === viewPortDimensions.width)
        ) {
            resizeObserver.unobserve(containerQm);
            return;
        }
    });

    // get guiSettings and set default values
    const {
        draggable_side_len: draggableSideLen = 25,
        pre_select_side_len: preSelectSideLen = 100
    } = guiSettings;

    // ref in order to get the offset to window of the tight layout div and borderWidth
    const frameRef = useRef();
    const getFrameOffset = () => {
        if (!frameRef.current) {
            return { x: 0, y: 0 };
        }

        const borderWidth = parseFloat(frameRef.current.style.borderWidth);
        return {
            x: frameRef.current.offsetLeft,
            y: frameRef.current.offsetTop,
            borderWidth: isNaN(borderWidth) ? 0 : borderWidth
        };
    };

    const guiObject = taskUIContext.getCurrentGuiObject();
    const makeAndSetCurrentTaskOutput = (
        topLeftPos,
        botRightPos,
        withAdjustment = true
    ) => {
        topLeftPos = transformPoint2DFromDimensions(
            topLeftPos,
            imageResizedDimensions,
            imageOriginalDimensions
        );
        botRightPos = transformPoint2DFromDimensions(
            botRightPos,
            imageResizedDimensions,
            imageOriginalDimensions
        );
        const bbox = new BBox2DModel(
            topLeftPos.x,
            topLeftPos.y,
            botRightPos.x,
            botRightPos.y
        );
        setCurrentTaskOutput(guiObject.makeTaskOutputForCurrentTask(bbox));

        if (withAdjustment) {
            guiObject.onAction("adjust_bounding_box");
        }
    };

    // react to changing image here in order to reset positions and currentTaskOutput between tasks
    useEffect(() => {
        if (!currentImage) {
            return;
        }

        const origninalDim = {
            width: image.width,
            height: image.height
        };
        const resizedDim = adjustDimensionsToMaxConstraint(
            origninalDim,
            containerQMDimensions,
            viewPortDimensions
        );
        // resizedDim is null while image isn't part of container, yet
        const usedImgDim = resizedDim ? resizedDim : origninalDim;

        setImageOriginalDimensions(origninalDim);
        setImageResizedDimensions(usedImgDim);

        // initialize draggable squares positions but don't update current TaskOutput
        const newTL = { x: 0, y: 0 };
        const newBR = {
            x: usedImgDim.width,
            y: usedImgDim.height
        };
        setTopLeftPos(newTL);
        setBotRightPos(newBR);

        // eslint-disable-next-line
    }, [currentImage, containerQMDimensions, viewPortDimensions]);

    if (
        !taskUIContext
            .getCurrentGuiObject()
            .taskInputResourcesInCache(currentTaskIdx)
    ) {
        return <LoadingAnimation />;
    }

    const taskInput = taskUIContext.getCurrentTaskInput();
    taskUIContext.onViewReady();
    const image = resourceCache[taskInput.image_url];

    // change image via state, because the image loading can fail and
    // useEffect mustn't be called conditionally. The image is only available after checking
    // whether all resources were loaded
    if (!currentImage || currentImage.src !== image.src) {
        setCurrentImage(image);
    }

    /**
     * @param {import("react").MouseEvent} e
     */
    const doPreSelection = e => {
        // make sure preSelectSideLen is smaller than frame
        if (
            preSelectSideLen <= 0 ||
            preSelectSideLen > imageResizedDimensions.width ||
            preSelectSideLen > imageResizedDimensions.height
        ) {
            return;
        }

        // calc new positions assuming everything's inside the frame
        const preSelectHalf = preSelectSideLen / 2;
        const offset = getFrameOffset();
        const clickPos = {
            x: e.clientX - offset.x - offset.borderWidth,
            y: e.clientY - offset.y - offset.borderWidth
        };
        const newTopLeftPos = {
            x: clickPos.x - preSelectHalf,
            y: clickPos.y - preSelectHalf
        };
        const newBotRightPos = {
            x: clickPos.x + preSelectHalf,
            y: clickPos.y + preSelectHalf
        };

        // adjust new positions so everything stays inside the frame
        // while keeping the clickPos the same
        if (newBotRightPos.x > imageResizedDimensions.width) {
            newBotRightPos.x = imageResizedDimensions.width;
        }
        if (newBotRightPos.y > imageResizedDimensions.height) {
            newBotRightPos.y = imageResizedDimensions.height;
        }
        if (newTopLeftPos.x < 0) {
            newTopLeftPos.x = 0;
        }
        if (newTopLeftPos.y < 0) {
            newTopLeftPos.y = 0;
        }

        setTopLeftPos(newTopLeftPos);
        setBotRightPos(newBotRightPos);
        makeAndSetCurrentTaskOutput(newTopLeftPos, newBotRightPos);
    };

    return (
        // Loose layout div that can be bigger than the image dimensions
        <div
            id="loose_layout"
            style={{
                margin: "auto",
                marginTop: draggableSideLen + 10,
                marginBottom: draggableSideLen + 10
            }}
        >
            {/* Tight layout div that fit's exactly around the image dimensions.
            Draggable elements are oriented relative to this div */}
            <div
                id="tight_layout"
                ref={frameRef}
                style={{
                    position: "relative",
                    width: "fit-content",
                    height: "fit-content",
                    userSelect: "none",
                    border: `${draggableSideLen}px solid rgba(0, 0, 0, 0)`
                }}
                onDoubleClick={doPreSelection}
            >
                <img
                    id="image"
                    style={{
                        width: imageResizedDimensions.width,
                        height: imageResizedDimensions.height,
                        userSelect: "none",
                        pointerEvents: "none"
                    }}
                    src={image.src}
                    alt=""
                />
                <Draggable
                    id="top_left_draggable"
                    key={"top_left: " + image.src}
                    style={{ background: "#F00", border: "2px solid black" }}
                    sideLen={draggableSideLen}
                    // Dragster has no pos state. it's fully controlled
                    pos={topLeftPos}
                    // define range of acceptable movement
                    posRange={{
                        x: [0, botRightPos.x],
                        y: [0, botRightPos.y]
                    }}
                    frameOffset={getFrameOffset()}
                    onMouseMove={pos => {
                        setTopLeftPos(pos);
                    }}
                    innerOffset={{ x: -draggableSideLen, y: -draggableSideLen }}
                    onDragFinished={() => {
                        makeAndSetCurrentTaskOutput(topLeftPos, botRightPos);
                    }}
                />
                <Draggable
                    id="bot_right_draggable"
                    key={"bot_right: " + image.src}
                    style={{ background: "#00F", border: "2px solid black" }}
                    sideLen={draggableSideLen}
                    pos={botRightPos}
                    posRange={{
                        x: [topLeftPos.x, imageResizedDimensions.width],
                        y: [topLeftPos.y, imageResizedDimensions.height]
                    }}
                    frameOffset={getFrameOffset()}
                    onMouseMove={pos => {
                        setBotRightPos(pos);
                    }}
                    innerOffset={{ x: 0, y: 0 }}
                    onDragFinished={() => {
                        makeAndSetCurrentTaskOutput(topLeftPos, botRightPos);
                    }}
                />
                <RectDiv
                    id="bounding_box_area"
                    key={"bbox_area: " + image.src}
                    topLeftPos={topLeftPos}
                    botRightPos={botRightPos}
                    style={{
                        border: "3px solid red",
                        opacity: 1,
                        background: "none"
                    }}
                />
            </div>
        </div>
    );
};
