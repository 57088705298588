import React from "react";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";

import MockData from "../MockData/MockData";
import MockApiClient from "../MockData/MockApiClient";
import BaseGuiType from "../BaseGuiType";
import { WIDGETS } from "../../guiFactory";
import {
    ActionButtonNextOrSubmit,
    ActionButtonUndo,
    ActionButtonCorruptData,
    ActionButtonOpenInstructions,
    ActionButtonCantSolve
} from "../../components/ActionButton/ActionButton";
import ResourceLoaderService from "../ResourceLoaderService";
import { Question } from "./Question";
import { ConnectedProgressBar } from "../../components/ProgressBar/ProgressBar";

/**
 * @class
 * @augments BaseGuiType
 */
export class BinaryComparison extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return WIDGETS.BINARY_COMPARISON;
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const mockApiClient = new MockApiClient(
            {
                question:
                    "Imagine you are driving from the perspective of the camera. Select the scene which would require more of your concentration, to safely drive through.",
                media_type: "video", // video | image | audio
                instructions: [
                    `<div id="ivc" style="display: flex; flex-direction: column; align-items: center;">
                        <video controls style="width: 960px; margin-bottom: 20px;">
                            <source src='https://qm-instructions.s3.eu-central-1.amazonaws.com/tme/TME_POC_SCENECOMPARISON_INSTRUCTIONSv1_DOWNSCALED.mp4' />
                        </video>
                        <button class="button is-sm is-green" style="width: 160px;" onclick="document.dispatchEvent(new Event('closeInstructions'))">I understand</button>
                    </div>`
                ],
                // with_mouse_tracking: true, // default: false
                // mouse_tracking_throttle_hz: 5 // -1 for no limit
                binary_question_mode: false
            },
            BinaryComparison.getName(),
            [
                {
                    media_url_0:
                        "https://qm-romanesco.s3.eu-central-1.amazonaws.com/okra_test/vcjFCuDJlyJecHjSHBDjjn.mp4",
                    media_url_1:
                        "https://qm-romanesco.s3.eu-central-1.amazonaws.com/okra_test/z51jg0uUYHSb4h4G24kb9V.mp4"
                },
                {
                    media_url_0:
                        "https://qm-romanesco.s3.eu-central-1.amazonaws.com/okra_test/soVErNWDzheDgBkVgwFMHZ.mp4",
                    media_url_1:
                        "https://qm-romanesco.s3.eu-central-1.amazonaws.com/okra_test/b6BMgJcKpiNryh3aoloKiy.mp4"
                },
                {
                    media_url_0:
                        "https://qm-asparagus.s3.eu-central-1.amazonaws.com/pre-processing/mock-tasks/front_video_rescaled.mp4",
                    media_url_1:
                        "https://qm-asparagus.s3.eu-central-1.amazonaws.com/pre-processing/mock-tasks/front_video_rescaled.mp4"
                }
            ]
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeNoOpResourceLoaderService()
        );
    }

    /**
     * @override
     * @returns {import("../BaseGuiType").LayoutCreator}
     */
    getLayoutCreator() {
        return (
            heading,
            dataVis,
            progressBar,
            actionButtonGroup,
            metaActionButtonGroup
        ) => {
            const components = [
                heading,
                dataVis,
                progressBar,
                metaActionButtonGroup
            ];
            const [
                Heading,
                DataVis,
                // eslint-disable-next-line
                ProgressBar,
                MetaActionButtonGroup
            ] = this.connectAndInstantiateComponents(components);
            return (
                <Container
                    style={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: "25px",
                        width: "unset",
                        height: "fit-content",
                        margin: "auto"
                    }}
                    fluid={true}
                >
                    <Row>
                        <Col>
                            {Heading}
                            <Row
                                style={{
                                    justifyContent: "center",
                                    marginBottom: "5px"
                                }}
                            ></Row>
                        </Col>
                    </Row>
                    {DataVis}
                    {/* DataVis is a Bootstrap Row and contains the action Buttons */}
                    <Row style={{ textAlign: "center" }}>
                        <Col>{MetaActionButtonGroup}</Col>
                    </Row>
                </Container>
            );
        };
    }

    /**
     * @override
     */
    getMetaActionButtonGroup() {
        /**
         * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
         */
        const MetaActionButtonGroup = ({ taskUIContext }) => {
            const disabledActionButtons =
                taskUIContext.shouldActionButtonsBeDisabled();
            return (
                <div className="meta_action_button_group">
                    {[
                        ActionButtonUndo,
                        ActionButtonCorruptData,
                        ActionButtonCantSolve,
                        ActionButtonOpenInstructions
                    ].map((A, i) => (
                        <A
                            key={i}
                            taskUIContext={taskUIContext}
                            disabled={disabledActionButtons}
                        />
                    ))}
                </div>
            );
        };
        return MetaActionButtonGroup;
    }

    /**
     * @override
     */
    getHeading() {
        return Question;
    }

    /**
     * @override
     */
    getDataVisualization() {
        /**
         * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
         */
        const dataVis = ({
            guiSettings,
            taskUIContext,
            setNextGuiType,
            currentTaskIdx,
            currentTaskOutput,
            setCurrentTaskOutput
        }) => {
            if (guiSettings.media_type !== "video") {
                setNextGuiType(WIDGETS.GENERIC_ERROR);
                console.error(
                    `media_type is ${guiSettings.media_type}, but only media_type 'video' is currently supported!`
                );
                return null;
            }

            const taskInput = taskUIContext.getCurrentTaskInput();
            taskUIContext.onViewReady();

            const onVideoPlay = videoId => e => {
                this.onAction("played_" + videoId);
            };
            const onVideoEnded = videoId => e => {
                this.onAction("ended_" + videoId);
                // triggers re-render
                setCurrentTaskOutput(currentTaskOutput);
                if (
                    (this.actionTelemetries.get("playback")
                        ? this.actionTelemetries.get("playback").cnt
                        : 0) >
                    (this.actionTelemetries.get("playback_ended")
                        ? this.actionTelemetries.get("playback_ended").cnt
                        : 0)
                ) {
                    if (guiSettings.binary_question_mode) {
                        const video = document.getElementById(videoId);
                        video.play();
                    } else {
                        let nextVideoId = "";
                        if (videoId === "media_url_0") {
                            nextVideoId = "media_url_1";
                        } else if (videoId === "media_url_1") {
                            nextVideoId = "media_url_0";
                        }
                        const nextVideo = document.getElementById(nextVideoId);
                        nextVideo.play();
                    }
                }
            };

            const playbackHandler = () => {
                if (
                    (this.actionTelemetries.get("playback")
                        ? this.actionTelemetries.get("playback").cnt
                        : 0) >
                    (this.actionTelemetries.get("playback_ended")
                        ? this.actionTelemetries.get("playback_ended").cnt
                        : 0)
                ) {
                    stopPlayback();
                } else {
                    startPlayback();
                }
            };

            const startPlayback = () => {
                this.onAction("playback");
                const firstVideo = document.getElementById("media_url_0");
                firstVideo.play();
                if (guiSettings.binary_question_mode) {
                    const secondVideo = document.getElementById("media_url_1");
                    secondVideo.play();
                }
                setCurrentTaskOutput(currentTaskOutput);
            };

            const stopPlayback = () => {
                this.onAction("playback_ended");
                const vid1 = document.getElementById("media_url_0");
                const vid2 = document.getElementById("media_url_1");
                vid1.pause();
                vid2.pause();
                setCurrentTaskOutput(currentTaskOutput);
            };

            const PlaybackButton = () => {
                return (
                    <button
                        className="button"
                        style={{
                            fontSize: "1.25rem",
                            margin: "15px",
                            fontWeight: "500",
                            background: "#e39817",
                            boxShadow: "0 0.5rem 1rem rgba(240, 181, 67, 0.32)"
                        }}
                        onClick={() => playbackHandler()}
                    >
                        {(this.actionTelemetries.get("playback")
                            ? this.actionTelemetries.get("playback").cnt
                            : 0) >
                        (this.actionTelemetries.get("playback_ended")
                            ? this.actionTelemetries.get("playback_ended").cnt
                            : 0)
                            ? "Stop Playback"
                            : "Start Playback"}
                    </button>
                );
            };

            const disabledActionButtons =
                taskUIContext.shouldActionButtonsBeDisabled();
            const bothVideosWatched =
                this.actionTelemetries.has("ended_media_url_0") &&
                this.actionTelemetries.has("ended_media_url_1");

            if (!guiSettings.binary_question_mode) {
                return (
                    <>
                        <Row
                            key={currentTaskIdx}
                            style={{ textAlign: "center" }}
                        >
                            {(keys =>
                                keys.map((key, i) => {
                                    return (
                                        <Col
                                            key={i}
                                            style={{ textAlign: "center" }}
                                        >
                                            <Row>
                                                <Col>
                                                    <video
                                                        id={key}
                                                        controls={true}
                                                        preload={"auto"}
                                                        style={{
                                                            marginBottom:
                                                                "10px",
                                                            marginRight: "5px",
                                                            maxHeight: "35vh",
                                                            maxWidth: "90vw"
                                                        }}
                                                        onPlay={onVideoPlay(
                                                            key
                                                        )}
                                                        onEnded={onVideoEnded(
                                                            key
                                                        )}
                                                    >
                                                        <source
                                                            src={taskInput[key]}
                                                        />
                                                    </video>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <ActionButtonNextOrSubmit
                                                        id={key}
                                                        taskUIContext={
                                                            taskUIContext
                                                        }
                                                        actionSuffix="set_selected_media_url"
                                                        disabled={
                                                            disabledActionButtons ||
                                                            !bothVideosWatched
                                                        }
                                                        createTaskOutput={() =>
                                                            this.makeTaskOutputForCurrentTask(
                                                                {
                                                                    selection:
                                                                        key
                                                                }
                                                            )
                                                        }
                                                        className="is-primary btn-lg"
                                                    >
                                                        Select
                                                    </ActionButtonNextOrSubmit>
                                                </Col>
                                            </Row>
                                        </Col>
                                    );
                                }))(["media_url_0", "media_url_1"])}
                        </Row>
                        <Row
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <PlaybackButton />
                        </Row>
                    </>
                );
            } else {
                return (
                    <>
                        <Row
                            key={currentTaskIdx}
                            style={{ textAlign: "center" }}
                        >
                            {(keys =>
                                keys.map((key, i) => {
                                    return (
                                        <Col
                                            key={i}
                                            style={{ textAlign: "center" }}
                                        >
                                            <Row>
                                                <Col>
                                                    <video
                                                        id={key}
                                                        controls={true}
                                                        preload={"auto"}
                                                        style={{
                                                            marginBottom:
                                                                "10px",
                                                            marginRight: "5px",
                                                            maxHeight: "50vh",
                                                            maxWidth: "90vw"
                                                        }}
                                                        onPlay={onVideoPlay(
                                                            key
                                                        )}
                                                        onEnded={onVideoEnded(
                                                            key
                                                        )}
                                                    >
                                                        <source
                                                            src={taskInput[key]}
                                                        />
                                                    </video>
                                                </Col>
                                            </Row>
                                        </Col>
                                    );
                                }))(["media_url_0", "media_url_1"])}
                        </Row>
                        <Row
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <PlaybackButton />
                        </Row>
                        <Row
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <ActionButtonNextOrSubmit
                                taskUIContext={taskUIContext}
                                actionSuffix="set_polar_answer"
                                disabled={disabledActionButtons}
                                createTaskOutput={() =>
                                    this.makeTaskOutputForCurrentTask({
                                        selection: "yes"
                                    })
                                }
                                className="is-primary btn-lg"
                            >
                                Yes
                            </ActionButtonNextOrSubmit>
                            <ActionButtonNextOrSubmit
                                taskUIContext={taskUIContext}
                                actionSuffix="set_polar_answer"
                                disabled={disabledActionButtons}
                                createTaskOutput={() =>
                                    this.makeTaskOutputForCurrentTask({
                                        selection: "no"
                                    })
                                }
                                className="is-primary btn-lg"
                            >
                                No
                            </ActionButtonNextOrSubmit>
                        </Row>
                        <Row>
                            <ConnectedProgressBar
                                style={{
                                    width: "96%",
                                    margin: "auto",
                                    textAlign: "center"
                                }}
                            />
                        </Row>
                    </>
                );
            }
        };
        return dataVis;
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return null;
    }

    /**
     * @inheritdoc
     */
    makeTaskOutputForCurrentTask(overrides) {
        let taskOutput = super.makeTaskOutputForCurrentTask(overrides);

        if (!("selection" in taskOutput)) {
            taskOutput.selection = "invalid";
        }

        const actionTelemetryToCnt = name =>
            this.actionTelemetries.has(name)
                ? this.actionTelemetries.get(name).cnt
                : 0;

        taskOutput = {
            ...taskOutput,
            played_media_url_0_cnt: actionTelemetryToCnt("played_media_url_0"),
            played_media_url_1_cnt: actionTelemetryToCnt("played_media_url_1"),
            ended_media_url_0_cnt: actionTelemetryToCnt("ended_media_url_0"),
            ended_media_url_1_cnt: actionTelemetryToCnt("ended_media_url_1")
        };
        return taskOutput;
    }

    /**
     * @override
     */
    onDispatchNextTask() {
        this.cleanUpPerTaskActionTelemetries();
    }

    /**
     * @override
     */
    onDispatchPreviousTask() {
        this.cleanUpPerTaskActionTelemetries();
    }

    cleanUpPerTaskActionTelemetries() {
        this.actionTelemetries.delete("played_media_url_0");
        this.actionTelemetries.delete("played_media_url_1");
        this.actionTelemetries.delete("ended_media_url_0");
        this.actionTelemetries.delete("ended_media_url_1");
        this.actionTelemetries.delete("playback");
        this.actionTelemetries.delete("playback_ended");
    }
}

export default BinaryComparison;
