import generateActionCreator from "../generateActionCreator";

export const ActionTypes = {
    TASK_FETCH: "TASK_FETCH",
    TASK_FETCH_SUCCESS: "TASK_FETCH_SUCCESS",
    TASK_FETCH_ERROR: "TASK_FETCH_ERROR",
    SET_TASKS_AMOUNT: "SET_TASKS_AMOUNT",
    NEXT_TASK: "NEXT_TASK",
    PREVIOUS_TASK: "PREVIOUS_TASK",
    ALL_IMAGES_LOADED: "ALL_IMAGES_LOADED",
    SET_TASK_OUTPUTS: "SET_TASK_OUTPUTS",
    SET_CURRENT_TASK_OUTPUT: "SET_CURRENT_TASK_OUTPUT",
    PUSH_TASK_OUTPUT: "PUSH_TASK_OUTPUT",
    POP_TASK_OUTPUT: "POP_TASK_OUTPUT",
    CAPTURE_TIMESTAMP: "CAPTURE_TIMESTAMP",
    SET_GUI_SETTINGS: "SET_GUI_SETTINGS",
    SET_GUI_TYPE: "SET_GUI_TYPE",
    SET_NEXT_GUI_TYPE: "SET_NEXT_GUI_TYPE",
    SET_TASK_RESULT: "SET_TASK_RESULT",
    TASK_SUBMIT: "TASK_SUBMIT",
    TASK_SUBMIT_SUCCESS: "TASK_SUBMIT_SUCCESS",
    TASK_SUBMIT_ERROR: "TASK_SUBMIT_ERROR",
    THANK_YOU_REACHED: "THANK_YOU_REACHED",
    ADD_RESOURCE_TO_CACHE: "ADD_RESOURCE_TO_CACHE",
    ADD_RESOURCE_LOADING_ERROR: "ADD_RESOURCE_LOADING_ERROR",
    SET_TASK_SUBMIT_ENABLED: "SET_TASK_SUBMIT_ENABLED"
};

export const taskFetch = generateActionCreator(ActionTypes.TASK_FETCH);
export const taskReceived = generateActionCreator(
    ActionTypes.TASK_FETCH_SUCCESS,
    "taskFetchResult"
);
export const taskFetchError = generateActionCreator(
    ActionTypes.TASK_FETCH_ERROR,
    "error"
);

export const setTasksAmount = generateActionCreator(
    ActionTypes.SET_TASKS_AMOUNT,
    "tasksAmount"
);

export const nextTask = generateActionCreator(ActionTypes.NEXT_TASK);
export const previousTask = generateActionCreator(ActionTypes.PREVIOUS_TASK);

export const allImagesLoaded = generateActionCreator(
    ActionTypes.ALL_IMAGES_LOADED,
    "imgCache"
);

export const setTaskOutputs = generateActionCreator(
    ActionTypes.SET_TASK_OUTPUTS,
    "taskOutputs"
);
export const setCurrentTaskOutput = generateActionCreator(
    ActionTypes.SET_CURRENT_TASK_OUTPUT,
    "currentTaskOutput"
);
export const pushTaskOutput = generateActionCreator(
    ActionTypes.PUSH_TASK_OUTPUT,
    "taskOutputs"
);
export const popTaskOutput = generateActionCreator(ActionTypes.POP_TASK_OUTPUT);

export const captureTimestamp = generateActionCreator(
    ActionTypes.CAPTURE_TIMESTAMP,
    "timestamp"
);

export const setNextGuiType = generateActionCreator(
    ActionTypes.SET_NEXT_GUI_TYPE,
    "nextGuiType"
);

export const setGuiSettings = generateActionCreator(
    ActionTypes.SET_GUI_SETTINGS,
    "guiSettings"
);

export const setGuiType = generateActionCreator(
    ActionTypes.SET_GUI_TYPE,
    "guiType"
);

export const setTaskResult = generateActionCreator(
    ActionTypes.SET_TASK_RESULT,
    "taskResult"
);

export const taskSubmit = generateActionCreator(ActionTypes.TASK_SUBMIT);
export const taskSubmitSuccess = generateActionCreator(
    ActionTypes.TASK_SUBMIT_SUCCESS,
    "result"
);
export const taskSubmitError = generateActionCreator(
    ActionTypes.TASK_SUBMIT_ERROR,
    "error"
);

export const thankYouReached = generateActionCreator(
    ActionTypes.THANK_YOU_REACHED
);

export const addResourceToCache = generateActionCreator(
    ActionTypes.ADD_RESOURCE_TO_CACHE,
    "url",
    "resource"
);
export const addResourceLoadingError = generateActionCreator(
    ActionTypes.ADD_RESOURCE_LOADING_ERROR,
    "url",
    "reason"
);

export const setTaskSubmitEnabled = generateActionCreator(
    ActionTypes.SET_TASK_SUBMIT_ENABLED,
    "taskSubmitEnabled"
);
