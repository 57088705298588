import React from "react";
import { ActionButtonNextOrSubmit } from "../../components/ActionButton/ActionButton";
import { setCurrentTaskOutput } from "../../store/actions";

/**
 * @param {import("../../containers/TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
export const ActionButtonGroup = ({ taskUIContext, currentTaskOutput }) => {
    const disabledActionButtons = taskUIContext.shouldActionButtonsBeDisabled();
    const taskOutputDoesntExist = taskUIContext
        .getCurrentGuiObject()
        .isInitialCurrentTaskOutput(currentTaskOutput);

    return (
        <div>
            <ActionButtonNextOrSubmit
                taskUIContext={taskUIContext}
                actionSuffix="submit_selection"
                disabled={disabledActionButtons || taskOutputDoesntExist}
                createTaskOutput={() => {
                    setCurrentTaskOutput(
                        taskUIContext
                            .getCurrentGuiObject()
                            .getInitialCurrentTaskOutput()
                    );
                    return currentTaskOutput;
                }}
                className="is-primary"
            >
                Submit
            </ActionButtonNextOrSubmit>
        </div>
    );
};
