import deepEqual from "deep-equal";

import { craneSillbeamGrappleImagesObjects } from "../sharedMockTaskInputs";
import { DrawPolygonsCanvas } from "./DrawPolygonsCanvas";
import { Question } from "./Question";
import { ActionButtonGroup } from "./ActionButtonGroup";
import { WIDGETS } from "../../guiFactory";
import BaseGuiType from "../BaseGuiType";
import MockApiClient from "../MockData/MockApiClient";
import MockData from "../MockData/MockData";
import ResourceLoaderService from "../ResourceLoaderService";
import { samplePolygonsFromTaskOutput } from "./samplePolygonsFromTaskOutput";

/*
Expected input and output formats.

Specs here https://github.com/quality-match/goliat/pull/369/files#diff-195855481d75b1c55370189cb3675dc1R30-R99

taskSettings = {
        labels: [
            { name: "grapple", color: "#ff0000", reference_image_url: "https://.." },
            { name: "truck", color: "#00ff00", reference_image_url: "https://.." },
            { name: "stakes", color: "#0000ff", reference_image_url: "https://.." },
            { name: "logs", color: "#ffff00", reference_image_url: "https://.." }
        ]
}

taskInput = {
    { image_url: "https://.." },
    { image_url: "https://..", alt_image_url: "https://.." }, // optional alt_image_url. tasks can allow to toggle the background image
    {
        image_url: "https://..",
        initial_polygons: [         // optional
            id: "triangle-002",     // optional. will be part of the output if given
            points: [               // required
                { x: 300, y: 100 },
                { x: 1000, y: 100 },
                { x: 650, y: 800 }
            ],
            label: "truck"          // optional
        ]
    },
}

taskOutput = {
    polygons: [
        {
            label:  "grapple",
            points: [ { x: 1, y: 2 }, { x: 3, y: 4 }],
            valid_selection: true
        },
        {
            label: "truck",
            points: [ { x: 1, y: 2 }, { x: 3, y: 4 }],
            valid_selection: true
        },
        {
            label: "unknown",
            points: [ { x: 1, y: 2 }, { x: 3, y: 4 }],
            valid_selection: false
        },
        {
            id: "id_in_order_to_remember_adjusted_polygon",
            label: "logs",
            points: [ { x: 1, y: 2 }, { x: 3, y: 4 }],
            valid_selection: true
        }
    ],
    [... super() defaults]
};
*/

export class Polygon extends BaseGuiType {
    /**
     * @override
     * @returns {String}
     */
    static getName() {
        return "polygon";
    }

    /**
     * @override
     * @returns {MockData}
     */
    static makeMockData() {
        const taskInputs = craneSillbeamGrappleImagesObjects.slice(0, 5);
        taskInputs[0] = {
            image_url: taskInputs[0].image_url,
            alt_image_url:
                "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/4a35a2d5-1fe7-4dcf-b19d-c35458a4d341.jpeg",
            initial_polygons: samplePolygonsFromTaskOutput
        };
        taskInputs[1] = {
            image_url: taskInputs[1].image_url,
            initial_polygons: [
                {}, // invalid because missing points. will be removed
                { points: [] }, // invalid because missing points. will be removed
                {
                    points: [
                        // invalid because missing points. will be removed
                        { x: 0, y: 0 },
                        { x: 500, y: 500 }
                    ]
                },
                {
                    id: "triangle-002",
                    points: [
                        { x: 300, y: 100 },
                        { x: 1000, y: 100 },
                        { x: 650, y: 800 }
                    ],
                    label: "truck"
                }
            ]
        };

        const mockApiClient = new MockApiClient(
            {
                instructions: [],
                question: "Create a polygon per class",
                labels: [
                    {
                        name: "grapple",
                        color: "#ffa500",
                        reference_image_url:
                            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/cbf2b08a-978d-472e-af67-58f2711d3c1f.jpeg"
                    },
                    {
                        name: "truck",
                        color: "#00ff00",
                        reference_image_url:
                            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/30cfea62-0fe4-4182-820f-507889e1ce1f.jpeg"
                    },
                    {
                        name: "stakes",
                        color: "#0000ff",
                        reference_image_url:
                            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/ffd4138d-4472-4791-bd2c-879bf40d905d.jpeg"
                    },
                    {
                        name: "logs",
                        color: "#ffff00",
                        reference_image_url:
                            "https://autocranedevdata.blob.core.windows.net/qm-sillbeam-label-20200715/d0b9ee4c-aef6-48f3-b988-27df8ad3d583.jpeg"
                    }
                ],
                zoom_max: 6,
                zoom_speed: 5,
                draw_distance: 10,
                point_radius: 3,
                hide_buttons: false,
                undo_button: true,
                delete_button: true,
                close_polygon_button: false,
                brightness_switch: true,
                // mouse tracking
                with_mouse_tracking: true, // default: false
                mouse_tracking_throttle_hz: -1 // -1 for no limit
            },
            WIDGETS.POLYGON,
            taskInputs
        );
        const mockData = new MockData(mockApiClient);
        return mockData;
    }

    /**
     * @override
     */
    initResourceLoaderService() {
        this.setResourceLoaderService(
            ResourceLoaderService.makeImageLoaderService(["image_url"])
        );
    }

    /**
     * @override
     */
    getHeading() {
        return Question;
    }

    /**
     * @override
     */
    getActionButtonGroup() {
        return ActionButtonGroup;
    }

    /**
     * @override
     */
    getDataVisualization() {
        return DrawPolygonsCanvas;
    }

    /**
     * @override
     */
    getInitialCurrentTaskOutput() {
        return { polygons: [] };
    }

    /**
     * @override
     */
    isInitialCurrentTaskOutput(taskOutput) {
        return deepEqual(this.getInitialCurrentTaskOutput(), taskOutput);
    }

    /**
     * @inheritdoc
     */
    makeTaskOutputForCurrentTask(overrides) {
        let taskOutput = super.makeTaskOutputForCurrentTask(overrides);
        taskOutput = {
            polygons: [
                { label: "unknown", points: [], valid_selection: false }
            ],
            ...taskOutput
        };
        return taskOutput;
    }
}

export default Polygon;
