export class MockData {
    /**
     * @param {import("./MockApiClient").MockApiClient} mockApiClient
     */
    constructor(mockApiClient) {
        this.mockApiClient = mockApiClient;
        /**
         * @type {Map<String, String>}
         */
        this.mockQueryParams = new Map();
        this.setDefaultQueryParams();
    }

    setDefaultQueryParams() {
        this.mockQueryParams.set("task_endpoint", "http://localhost:8080");
        this.mockQueryParams.set("vendor_id", "SOME_VENDOR_A");
        this.mockQueryParams.set("vendor_user_id", "SOME_USER_OF_VENDOR_A");
        this.mockQueryParams.set("project_id", "AAAA_SOME_PROJECT_UUID");
    }

    /**
     * @param {Map<String, String>} mockQueryParams
     */
    setMockQueryParams(mockQueryParams) {
        this.mockQueryParams = mockQueryParams;
    }
}

export default MockData;
