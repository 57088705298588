import React, { useState, useEffect } from "react";
import PubSub from "pubsub-js";
import { BsArrowBarUp, BsArrowBarDown } from "react-icons/bs";

import CanvasScene from "../../lib/NRL/CanvasScene";
import { LayeredScene } from "../../lib/NRL/CanvasScene";
import { ColorMap, COLORMAPS, getColorMap } from "../../lib/NRL/ColorMap";
import { Pose3D } from "../../lib/NRL/Pose3D";
import { PointcloudRenderable } from "../../lib/NRL/Renderable";
import { RENDERERS } from "../../lib/NRL/Renderer";
import { transformPoint2DFromDimensions } from "../../lib/qm_cs_lib";
import { AnnotationContext } from "./AnnotationContext";
import CustomMouseCursor from "../../components/CustomMouseCursor/CustomMouseCursor";

const shared = {
    cam: new Pose3D([0, 0, 0], [0, 0, 0], 1),
    sceneMap: null,
    selectedDim: "",
    originalCanvasDimensions: null,
    resizedCanvasDimensions: null,
    lastMouseMoveCanvasPos: null
};

/**
 * @param {Object} props
 * @param {AnnotationContext} props.annotationContext
 * @param {import("../../lib/NRL/Renderer").SphericalCanvasRendererSettings} props.sphericalParams contains only the spherical params part of the renderer settings
 * @param {Number[][]} props.points
 * @param {HTMLImageElement} props.image
 * @param {Number} width pixel width of the canvas. The actual displayed width is determined by CSS.
 * @param {Number} height pixel height of the canvas. The actual displayed height is determined by CSS.
 * @param {import("../../lib/DataModels/BBox2DModel").BBox2DModel} props.imageBoundingBox2D
 * @param {String=} props.cogColor default pink
 * @param {Number=} props.cogLineWidth default 4
 * @param {Number=} props.pointRadius default 1.5
 * @param {Number=} props.pointOpacity default 0.3
 * @param {Number=} props.mousePointHighlightCanvasDist default 20 important! in pixel space on canvas
 * @param {Number=} props.highlightedPointRadius default 4
 * @param {Number=} props.highlightedPointOpacity default 0.6
 * @param {String=} props.colormap default turbo_light
 * @param {Boolean=} props.withBox default false
 * @param {Number=} props.boxLineWidth default 2
 * @param {String=} props.boxColor default pink
 * @param {Boolean=} props.arrowVisible default true
 * @param {Number=} props.arrowLineWidth default 2
 * @param {String=} props.arrowColor default pink
 * @param {Number=} props.imageBoundingBox2DLineWidth default 2
 * @param {String=} props.imageBoundingBox2DColor default white,
 * @param {Number=} props.cursorSize default 40
 * @param {String=} props.cursorColor default red
 * @param {Number=} props.boxIntersectionLineWidth default 2
 * @param {String=} props.boxIntersectionColor default green
 * @param {Number=} props.existingBoxLineWidth default 2
 * @param {String=} props.existingBoxColor default dark pink
 */
export const PointcloudRGB = ({
    annotationContext,
    sphericalParams,
    points,
    image,
    width,
    height,
    imageBoundingBox2D,
    pointRadius = 1.5,
    cogColor = "#F0F",
    cogLineWidth = 4,
    pointOpacity = 0.3,
    mousePointHighlightCanvasDist = 20,
    highlightedPointRadius = 4,
    highlightedPointOpacity = 0.6,
    colormap = COLORMAPS.TURBO_LIGHT,
    withBox = false,
    boxLineWidth = 2,
    boxColor = "#F0F",
    arrowVisible = true,
    arrowLineWidth = 2,
    arrowColor = "#F0F",
    imageBoundingBox2DLineWidth = 2,
    imageBoundingBox2DColor = "#FFF",
    cursorSize = 40,
    cursorColor = "#F00",
    boxIntersectionLineWidth = 2,
    boxIntersectionColor = "#0F0",
    existingBoxLineWidth = 2,
    existingBoxColor = "#A0A"
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [showCustomMouseCursor, setShowCustomMouseCursor] = useState(false);
    const [customMouseCursorIcon, setCustomMouseCursorIcon] = useState(null);

    useEffect(() => {
        PubSub.subscribe(AnnotationContext.TOPICS.COG.BEV, (msg, data) => {
            let scene = null;
            if (withBox) {
                scene = shared.sceneMap.get("box");
                const box = scene.getObject("box");
                const arrow = scene.getObject("cog_arrow");
                box.poseWorld.setPosition(annotationContext.cogPos);
                arrow.poseWorld.setPosition(annotationContext.cogPos);
                annotationContext.handleBoxIntersectsExistingBoxes(
                    scene,
                    box,
                    boxLineWidth,
                    boxColor,
                    boxIntersectionLineWidth,
                    boxIntersectionColor
                );
            } else {
                scene = shared.sceneMap.get("cog");
                const cross = scene.getObject("cog_cross");
                cross.poseWorld.setPosition(annotationContext.cogPos);
            }
            scene.render(true);
        });
        PubSub.subscribe(
            AnnotationContext.TOPICS.BOX_ORIENTATION.BEV,
            (msg, data) => {
                const scene = shared.sceneMap.get("box");
                const box = scene.getObject("box");
                const arrow = scene.getObject("cog_arrow");
                const newRot = [
                    box.poseWorld.rot[0],
                    box.poseWorld.rot[1],
                    annotationContext.boxOrientation
                ];
                box.poseWorld.setRotation(newRot);
                arrow.poseWorld.setRotation(newRot);
                annotationContext.handleBoxIntersectsExistingBoxes(
                    scene,
                    box,
                    boxLineWidth,
                    boxColor,
                    boxIntersectionLineWidth,
                    boxIntersectionColor
                );
                scene.render(true);
            }
        );
        PubSub.subscribe(
            AnnotationContext.TOPICS.BOX_DIMENSIONS.BEV,
            (msg, data) => {
                const scene = shared.sceneMap.get("box");
                const box = scene.getObject("box");
                const arrow = scene.getObject("cog_arrow");
                const boxPose = box.poseWorld;
                boxPose.setPosition(annotationContext.cogPos);
                boxPose.setRotation([
                    boxPose.rot[0],
                    boxPose.rot[1],
                    annotationContext.boxOrientation
                ]);
                box.init(annotationContext.boxDims.makeBoxCurves());
                arrow.poseWorld.setPosition(boxPose.pos);
                arrow.init(annotationContext.arrowDims.makeArrowLines());
                annotationContext.handleBoxIntersectsExistingBoxes(
                    scene,
                    box,
                    boxLineWidth,
                    boxColor,
                    boxIntersectionLineWidth,
                    boxIntersectionColor
                );
                scene.render(true);
            }
        );
        PubSub.subscribe(
            AnnotationContext.TOPICS.MOUSE_POINTS_HIGHLIGHT.BEV,
            (msg, { closePointIdxs }) => {
                const scene = shared.sceneMap.get("mouse_hover");
                const pointcloudRenderable = shared.sceneMap
                    .get("pointcloud")
                    .getObject("pointcloud");
                AnnotationContext.highlightPointIdxs(
                    scene,
                    pointcloudRenderable,
                    closePointIdxs,
                    highlightedPointRadius,
                    highlightedPointOpacity
                );
            }
        );
        // eslint-disable-next-line
    }, []);

    const makeInteractiveCanvasScene = () => {
        if (withBox) {
            return (
                <CanvasScene
                    name="box"
                    width={width}
                    height={height}
                    renderer={RENDERERS.SPHERICAL_CANVAS}
                    rendererSettings={{
                        sphericalParams: sphericalParams,
                        image: image
                    }}
                    init={(scene, name) => {
                        scene.cameraPoseWorld = shared.cam;
                        const {
                            box,
                            arrow
                        } = annotationContext.makeInitialBoxAndArrowRenderables(
                            boxLineWidth,
                            boxColor,
                            arrowLineWidth,
                            arrowColor
                        );
                        arrow.setShouldRender(arrowVisible);
                        // add existing boxes
                        annotationContext.addExistingBoxesToScene(
                            scene,
                            existingBoxLineWidth,
                            existingBoxColor
                        );
                        // add adjustable box later so it's render on top
                        scene.addObject(box, "box");
                        scene.addObject(arrow, "cog_arrow");

                        annotationContext.handleBoxIntersectsExistingBoxes(
                            scene,
                            box,
                            boxLineWidth,
                            boxColor,
                            boxIntersectionLineWidth,
                            boxIntersectionColor
                        );

                        scene.render(true);

                        // check if any box point is rendered outside of the visible area of the canvas
                        // if so: get world height at center of canvas with initialCogPos reference world point
                        //        then set box height to that height
                        let allVisible = true;
                        const canvasWidth = scene.renderer.ctx.width;
                        const canvasHeight = scene.renderer.ctx.height;
                        for (const p of box.projectedPoints) {
                            if (
                                p[0] < 0 ||
                                p[0] > canvasWidth ||
                                p[1] < 0 ||
                                p[1] > canvasHeight
                            ) {
                                allVisible = false;
                                break;
                            }
                        }
                        if (!allVisible) {
                            const canvasCenterWorld = scene.renderer.canvasPosToWorldPosAtReference(
                                [canvasWidth / 2, canvasHeight / 2],
                                annotationContext.initialCogPos
                            );
                            const arrowPos = arrow.poseWorld.pos;
                            const boxPos = box.poseWorld.pos;

                            // TODO: This timeout is used because PointcloudBEV isn't initialized, yet.
                            //       Should be updated along with the other state management fixes.
                            window.setTimeout(() => {
                                console.log(
                                    "updated height because box wasn't fully visible:",
                                    canvasCenterWorld[2],
                                    "(initialCog height:",
                                    annotationContext.initialCogPos[2],
                                    ")"
                                );
                                arrow.poseWorld.setPosition([
                                    arrowPos[0],
                                    arrowPos[1],
                                    canvasCenterWorld[2]
                                ]);
                                box.poseWorld.setPosition([
                                    boxPos[0],
                                    boxPos[1],
                                    canvasCenterWorld[2]
                                ]);

                                annotationContext.handleBoxIntersectsExistingBoxes(
                                    scene,
                                    box,
                                    boxLineWidth,
                                    boxColor,
                                    boxIntersectionLineWidth,
                                    boxIntersectionColor
                                );

                                scene.render(true);
                                annotationContext.cogPos = box.poseWorld.pos;
                                PubSub.publish(
                                    AnnotationContext.TOPICS.COG.RGB,
                                    null
                                );
                            }, 150);
                        }
                    }}
                    canvasProps={{
                        onMouseDown: e => {
                            setIsDragging(true);
                            const scene = shared.sceneMap.get("box");
                            const box = scene.getObject("box");
                            const mouseCanvasScaled = {
                                x: e.nativeEvent.offsetX,
                                y: e.nativeEvent.offsetY
                            };
                            let mouseCanvas = transformPoint2DFromDimensions(
                                mouseCanvasScaled,
                                shared.resizedCanvasDimensions,
                                shared.originalCanvasDimensions
                            );
                            mouseCanvas = [mouseCanvas.x, mouseCanvas.y];
                            if (!shared.lastMouseMoveCanvasPos) {
                                shared.lastMouseMoveCanvasPos = mouseCanvas;
                            }
                            const mouseWorld = scene.renderer.canvasPosToWorldPosAtReference(
                                shared.lastMouseMoveCanvasPos,
                                box.poseWorld.pos
                            );
                            const mouseBox = box.poseWorld.applyInverseTo(
                                mouseWorld
                            );
                            // selection shouldn't change during drag, therefore done in mouse down event
                            shared.selectedDim =
                                mouseBox[2] >= 0 ? "top" : "bottom";
                        },
                        onMouseUp: e => {
                            setIsDragging(false);
                        },
                        onMouseLeave: e => {
                            setIsDragging(false);
                            setShowCustomMouseCursor(false);
                        },
                        onMouseMove: e => {
                            const scene = shared.sceneMap.get("box");
                            const box = scene.getObject("box");
                            const mouseCanvasScaled = {
                                x: e.nativeEvent.offsetX,
                                y: e.nativeEvent.offsetY
                            };
                            let mouseCanvas = transformPoint2DFromDimensions(
                                mouseCanvasScaled,
                                shared.resizedCanvasDimensions,
                                shared.originalCanvasDimensions
                            );
                            mouseCanvas = [mouseCanvas.x, mouseCanvas.y];
                            if (!shared.lastMouseMoveCanvasPos) {
                                shared.lastMouseMoveCanvasPos = mouseCanvas;
                            }
                            const lastMouseWorld = scene.renderer.canvasPosToWorldPosAtReference(
                                shared.lastMouseMoveCanvasPos,
                                box.poseWorld.pos
                            );
                            const lastMouseBox = box.poseWorld.applyInverseTo(
                                lastMouseWorld
                            );

                            // check whether the mouse is inside the top or bottom polygon
                            if (isDragging) {
                                const mouseWorld = scene.renderer.canvasPosToWorldPosAtReference(
                                    mouseCanvas,
                                    box.poseWorld.pos
                                );
                                const heightDiff =
                                    mouseWorld[2] - lastMouseWorld[2];

                                if (shared.selectedDim === "top") {
                                    annotationContext.boxDims.top += heightDiff;
                                } else if (shared.selectedDim === "bottom") {
                                    annotationContext.boxDims.bottom -= heightDiff;
                                }

                                // make sure minimalBoxDims constraint is satisfied
                                if (
                                    annotationContext.boxDims[
                                        shared.selectedDim
                                    ] <
                                    annotationContext.minimalBoxDims[
                                        shared.selectedDim
                                    ]
                                ) {
                                    annotationContext.boxDims[
                                        shared.selectedDim
                                    ] =
                                        annotationContext.minimalBoxDims[
                                            shared.selectedDim
                                        ];
                                }

                                PubSub.publish(
                                    AnnotationContext.TOPICS.BOX_DIMENSIONS.RGB,
                                    null
                                );
                            } else {
                                // only update the custom mouse cursor if not dragging
                                if (lastMouseBox[2] >= 0) {
                                    // hovered top
                                    setCustomMouseCursorIcon(
                                        <BsArrowBarUp size={cursorSize} />
                                    );
                                } else if (lastMouseBox[2] < 0) {
                                    // hovered bottom
                                    setCustomMouseCursorIcon(
                                        <BsArrowBarDown size={cursorSize} />
                                    );
                                }
                                setShowCustomMouseCursor(true);
                            }
                            shared.lastMouseMoveCanvasPos = mouseCanvas;
                        }
                    }}
                />
            );
        } else {
            return (
                <CanvasScene
                    name="cog"
                    width={width}
                    height={height}
                    renderer={RENDERERS.SPHERICAL_CANVAS}
                    rendererSettings={{
                        sphericalParams: sphericalParams,
                        image: image
                    }}
                    init={(scene, name) => {
                        scene.cameraPoseWorld = shared.cam;
                        scene.addObject(
                            annotationContext.crossDims.makeLinesRenderable(
                                new Pose3D(annotationContext.initialCogPos),
                                {
                                    lineWidth: cogLineWidth,
                                    strokeStyle: cogColor
                                }
                            ),
                            "cog_cross"
                        );
                        scene.render(true);
                    }}
                />
            );
        }
    };

    const layeredScene = (
        <LayeredScene
            setSceneMap={sceneMap => {
                shared.sceneMap = sceneMap;
            }}
        >
            <CanvasScene
                name="bg_image"
                width={width}
                height={height}
                init={(scene, name) => {
                    /**
                     * @type {CanvasRenderingContext2D}
                     */
                    const ctx = scene.renderer.ctx;
                    // draw image scaled to the canvas
                    ctx.drawImage(
                        image,
                        0,
                        0,
                        image.naturalWidth,
                        image.naturalHeight,
                        0,
                        0,
                        ctx.width,
                        ctx.height
                    );

                    // draw 2D bounding box on the image
                    if (!imageBoundingBox2D) {
                        return;
                    }
                    ctx.save();
                    ctx.beginPath();
                    ctx.strokeStyle = imageBoundingBox2DColor;
                    ctx.lineWidth = imageBoundingBox2DLineWidth;
                    const [xScale, yScale] = [
                        image.naturalWidth / ctx.width,
                        image.naturalHeight / ctx.height
                    ];
                    ctx.rect(
                        imageBoundingBox2D.left / xScale,
                        imageBoundingBox2D.top / yScale,
                        (imageBoundingBox2D.right - imageBoundingBox2D.left) /
                            xScale,
                        (imageBoundingBox2D.bottom - imageBoundingBox2D.top) /
                            yScale
                    );
                    ctx.stroke();
                    ctx.restore();
                }}
            />
            <CanvasScene
                name="pointcloud"
                width={width}
                height={height}
                renderer={RENDERERS.SPHERICAL_CANVAS}
                rendererSettings={{
                    sphericalParams: sphericalParams,
                    image: image
                }}
                init={(scene, name) => {
                    const ctx = scene.renderer.ctx;
                    shared.originalCanvasDimensions = {
                        width: ctx.canvas.width,
                        height: ctx.canvas.height
                    };
                    shared.resizedCanvasDimensions = {
                        width: ctx.canvas.offsetWidth,
                        height: ctx.canvas.offsetHeight
                    };

                    scene.cameraPoseWorld = shared.cam;

                    if (!Object.values(COLORMAPS).includes(colormap)) {
                        colormap = COLORMAPS.TURBO_LIGHT;
                    }
                    const pointcloud = new PointcloudRenderable(
                        new Pose3D([0, 0, 0]),
                        points,
                        {
                            radius: pointRadius,
                            useColorMap: true,
                            colorMap: new ColorMap(
                                "z",
                                getColorMap(colormap),
                                pointOpacity,
                                true,
                                true,
                                [0.05, 0.95]
                            )
                        }
                    );
                    scene.addObject(pointcloud, "pointcloud");
                    scene.render(true);
                }}
            />
            <CanvasScene
                name="mouse_hover"
                width={width}
                height={height}
                renderer={RENDERERS.SPHERICAL_CANVAS}
                rendererSettings={{
                    sphericalParams: sphericalParams,
                    image: image
                }}
                // empty init still necessary
                init={(scene, name) => {}}
                canvasProps={{
                    onMouseMove: e => {
                        const scene = shared.sceneMap.get("mouse_hover");
                        const mouseCanvas = AnnotationContext.scaleCanvasMousePos(
                            e,
                            shared.originalCanvasDimensions,
                            shared.resizedCanvasDimensions
                        );
                        const pointcloudRenderable = shared.sceneMap
                            .get("pointcloud")
                            .getObject("pointcloud");
                        const closePointIdxs = AnnotationContext.findClosePointIdxs(
                            pointcloudRenderable,
                            mouseCanvas,
                            mousePointHighlightCanvasDist
                        );
                        AnnotationContext.highlightPointIdxs(
                            scene,
                            pointcloudRenderable,
                            closePointIdxs,
                            highlightedPointRadius,
                            highlightedPointOpacity
                        );

                        PubSub.publish(
                            AnnotationContext.TOPICS.MOUSE_POINTS_HIGHLIGHT.RGB,
                            {
                                closePointIdxs
                            }
                        );
                    }
                }}
            />
            {makeInteractiveCanvasScene()}
        </LayeredScene>
    );
    return (
        <>
            {layeredScene}
            <CustomMouseCursor
                id="rgb_custom_mouse_cursor"
                show={showCustomMouseCursor}
                icon={customMouseCursorIcon}
                color={cursorColor}
            />
        </>
    );
};
